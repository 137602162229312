import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="">

      
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M980.291382,1080.639160 
	C655.367126,1081.000000 330.734283,1080.992310 6.101449,1081.089600 
	C2.141624,1081.090820 0.866577,1080.608276 0.868387,1076.102417 
	C1.011666,719.306274 1.009364,362.510101 0.884970,5.713974 
	C0.883530,1.584183 1.805515,0.893199 5.785111,0.894468 
	C362.581238,1.008278 719.377441,1.007651 1076.173584,0.900327 
	C1080.067017,0.899155 1081.121826,1.454703 1081.120239,5.672661 
	C1080.989868,358.135986 1081.000000,710.599365 1081.000000,1063.531372 
	C1079.290771,1065.651001 1077.114258,1065.790649 1074.939453,1065.774048 
	C1046.827148,1065.560669 1018.708923,1066.396606 990.601624,1065.322266 
	C986.763489,1065.175537 983.040222,1068.868408 982.044983,1074.036987 
	C981.639832,1076.140625 981.875366,1078.384277 980.291382,1080.639160 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M980.468628,1081.000000 
	C981.416748,1076.230469 980.737549,1070.874390 984.615051,1066.751831 
	C985.962646,1065.319214 986.831482,1064.193848 988.971985,1064.307251 
	C1016.912781,1065.787598 1044.873657,1064.658691 1072.824951,1064.870239 
	C1075.315430,1064.889038 1077.806885,1064.768799 1080.648926,1064.356445 
	C1081.000000,1068.483276 1080.812012,1072.977173 1081.068481,1077.445801 
	C1081.230103,1080.260620 1080.730347,1081.123047 1077.575562,1081.099854 
	C1053.002075,1080.919067 1028.426514,1081.000000 1003.851685,1081.000000 
	C996.213562,1081.000000 988.575439,1081.000000 980.468628,1081.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M865.224365,281.615845 
	C865.002441,282.001221 865.000671,282.500610 865.000366,282.750305 
	C864.053650,284.073578 862.776978,284.341187 861.378723,284.460236 
	C862.481689,284.751709 863.628540,284.745972 864.749268,285.765442 
	C867.653625,287.374023 870.386658,287.271332 873.161743,286.446411 
	C875.264343,285.821411 877.403137,285.748962 879.490173,286.382935 
	C880.195129,286.597076 881.046570,287.181793 881.306396,287.813141 
	C881.719238,288.816437 880.532288,289.036926 879.936523,289.522491 
	C878.394226,290.779572 874.898560,290.705597 875.621033,293.379547 
	C876.266785,295.769562 879.369568,296.259369 881.740417,296.933319 
	C882.202087,297.064575 882.724487,296.982330 883.609436,296.999878 
	C885.199890,298.370605 885.389343,299.897156 884.434998,301.958191 
	C878.768555,305.250519 873.025940,303.014404 867.020752,303.497131 
	C861.833984,303.934418 857.077881,303.505219 852.339844,303.778717 
	C850.588135,303.879822 848.692322,303.619659 846.874878,305.222290 
	C843.875549,307.038605 840.641602,306.242340 837.534851,307.680542 
	C834.297852,308.412567 832.050049,307.842346 831.966125,304.422272 
	C831.892334,301.416931 833.802612,299.978302 836.629944,299.665222 
	C838.605347,299.446533 840.611084,299.714752 842.577515,299.471680 
	C844.861267,299.189423 845.606140,298.255280 843.086914,296.469788 
	C840.246399,295.709167 837.674561,295.666840 834.814331,295.081360 
	C831.572632,292.477539 828.630798,290.858337 824.589844,292.698090 
	C821.925781,293.910980 820.134888,290.989288 817.436462,290.337830 
	C816.868958,290.182770 816.647583,290.103668 816.111084,289.871643 
	C813.386353,287.895935 810.971069,288.130127 809.679688,290.746033 
	C808.134644,293.876007 810.579468,295.001923 813.488647,295.780579 
	C814.313232,296.051544 814.747559,296.295410 815.400574,296.894318 
	C816.324158,302.717194 818.113098,307.528229 822.338013,311.621613 
	C826.027466,315.196198 828.447266,316.377838 833.277100,314.279083 
	C835.826355,313.171326 838.341309,311.747223 839.976807,315.345459 
	C841.389099,318.452728 839.802185,322.207092 836.183716,322.584534 
	C830.523376,323.174957 824.788330,327.277069 818.994507,322.671661 
	C817.384155,321.391663 816.220642,323.183319 815.320679,324.254761 
	C812.596985,327.497437 809.571777,328.581909 805.685242,326.269318 
	C803.888794,325.200409 801.917236,325.321045 799.871033,325.756195 
	C788.869324,328.095856 777.803406,327.891907 766.644592,326.906464 
	C757.191589,326.071686 747.676147,326.652802 738.179382,327.028015 
	C743.081970,327.404266 747.995117,327.382324 752.907471,327.393555 
	C755.406372,327.399261 757.912720,327.270142 760.401917,327.476349 
	C762.326660,327.635803 764.969299,327.380341 765.251404,329.918457 
	C765.575562,332.835266 762.970764,333.851135 760.638916,334.188843 
	C755.352966,334.954437 750.001709,334.478943 744.679382,334.610687 
	C742.688293,334.659943 740.687317,334.451355 738.703735,335.091492 
	C754.949829,335.027893 771.190735,335.280029 787.462708,335.250244 
	C783.939636,334.448639 780.333130,334.685883 776.753967,334.601990 
	C774.594788,334.551331 772.380615,334.746613 770.383972,333.704071 
	C768.950378,332.955536 768.056885,331.767120 768.426270,330.041321 
	C768.760193,328.481567 769.989685,327.980408 771.406982,327.875214 
	C778.382751,327.357544 785.377808,327.263031 792.349609,327.656555 
	C796.376221,327.883820 798.111084,330.286713 798.037292,334.393494 
	C797.905396,341.739227 801.059937,343.572449 808.347595,342.518005 
	C815.690369,341.455658 822.914917,339.221832 830.422058,339.465210 
	C835.952026,339.644501 837.292419,341.124329 836.455688,346.524475 
	C835.990601,349.525696 837.137085,350.820587 839.735474,351.653168 
	C843.839478,352.968170 848.185913,354.030884 849.480408,359.099976 
	C849.636292,359.710480 850.239136,360.335480 850.867554,360.468781 
	C858.593872,362.107330 861.078674,369.355988 865.793640,374.233521 
	C868.758362,377.300415 872.305664,379.715607 874.482971,383.632416 
	C875.565430,385.579651 878.304810,385.343262 880.481018,385.370392 
	C886.311951,385.443024 892.144775,385.294434 897.974060,385.451202 
	C900.486389,385.518799 903.298401,385.558441 904.047485,388.665833 
	C904.868652,392.072571 905.409912,395.714722 903.268616,398.807251 
	C902.031921,400.593414 900.043884,400.252563 898.504456,398.724457 
	C897.669067,397.895203 897.358948,396.698730 896.695129,395.745117 
	C894.337036,392.357086 891.587646,392.415985 889.242615,395.876770 
	C885.603638,401.247131 877.604126,404.439056 871.477356,403.004669 
	C868.732483,402.362091 867.643677,400.350983 867.567688,397.871796 
	C867.428345,393.321014 865.322388,391.220306 860.796387,390.147217 
	C856.337341,389.090027 852.279053,386.930115 847.367371,387.103088 
	C843.425232,387.241943 842.216431,384.030029 844.223267,380.362701 
	C844.940125,379.052673 845.996704,377.940491 846.508301,376.503510 
	C846.984497,375.165802 847.119080,373.841156 845.899902,372.844025 
	C844.679504,371.845886 843.406860,372.190613 842.224976,373.016357 
	C840.531494,374.199463 839.194458,375.689575 838.881104,377.792511 
	C838.459839,380.619324 837.868591,383.319611 836.101440,385.702576 
	C834.704407,387.586456 835.571350,388.800568 837.684143,389.251465 
	C840.469910,389.845917 843.201721,390.568359 845.563538,392.255432 
	C848.087585,394.058411 848.567932,396.239075 846.382568,398.564880 
	C843.695007,401.425201 841.670715,405.139526 837.246338,405.895905 
	C836.263000,406.063965 835.323853,406.691681 834.959351,407.678284 
	C833.726135,411.016571 830.954651,412.721313 828.066406,414.568329 
	C832.496399,411.105652 837.559814,410.247314 842.881958,409.993256 
	C845.230835,409.881104 846.679138,408.137939 847.477234,405.923340 
	C848.409729,403.336029 850.122131,401.710327 853.026978,401.718842 
	C855.935425,401.727356 857.768494,403.259796 858.421814,405.995850 
	C859.129883,408.961365 861.270935,409.340637 863.741882,409.366608 
	C869.406494,409.426117 875.071350,409.358795 880.735840,409.405457 
	C882.400208,409.419159 884.077454,409.390808 885.672180,409.987701 
	C887.059875,410.507050 887.843079,411.485657 887.828491,413.007782 
	C887.813965,414.529724 887.001831,415.474243 885.619019,415.997101 
	C884.990356,416.234802 884.334839,416.340881 883.677673,416.452911 
	C875.291504,417.882324 874.174500,419.259583 873.716125,428.016174 
	C873.618164,429.887207 874.316528,430.786835 875.920776,431.366089 
	C878.738403,432.383484 881.510315,431.795532 884.291565,431.229156 
	C885.517212,430.979553 885.877747,430.212036 885.672180,429.037109 
	C884.598267,422.899353 889.169006,419.686584 893.200195,417.530426 
	C897.267822,415.354858 899.872925,412.991608 900.862366,408.607544 
	C901.189148,407.159454 901.817810,405.769073 902.347534,404.365875 
	C903.539612,401.208130 905.567688,400.705902 908.140198,402.708466 
	C910.763855,404.750824 913.421997,406.767517 915.960754,408.897339 
	C919.514893,411.878815 919.627991,418.065186 915.908508,421.378967 
	C912.310120,424.584839 909.284790,428.421478 905.388855,431.227448 
	C902.529846,433.286621 902.466370,435.885284 902.943665,438.726593 
	C903.468323,441.849854 906.114563,441.070709 908.152466,441.152374 
	C910.215637,441.235016 911.808289,441.794037 912.013184,444.126221 
	C912.225464,446.542114 910.936462,448.134918 908.650940,448.245941 
	C904.765381,448.434723 903.376465,450.367584 901.805786,453.918488 
	C899.737427,458.594543 894.161621,460.803894 891.292542,465.439240 
	C890.199036,467.205902 886.566833,465.632141 884.095276,465.527893 
	C881.384094,465.413574 878.580872,464.579102 875.472656,467.053619 
	C879.101501,467.628052 882.100952,467.116333 884.978943,467.674652 
	C887.334473,468.131561 889.821411,468.830261 889.629639,471.877594 
	C889.459778,474.577698 887.169434,475.277618 884.921631,475.425079 
	C880.770447,475.697449 876.624329,475.856567 872.505310,474.793823 
	C868.230896,473.690979 867.103821,474.534241 866.712280,478.925049 
	C866.430176,482.087311 866.669861,485.243561 867.179810,488.386261 
	C867.747009,491.881927 867.848083,495.370941 866.238342,498.669861 
	C865.544800,500.091217 864.883240,501.548981 866.564087,502.633545 
	C868.672241,503.993774 871.181396,504.044556 873.268799,503.039368 
	C875.433655,501.996826 873.512329,500.636230 872.907104,499.400238 
	C871.740173,497.017303 873.175232,495.580688 875.043640,494.557770 
	C877.516479,493.203918 880.197693,492.440369 883.756531,492.543793 
	C880.369080,491.612122 878.647583,490.052734 879.003235,487.055084 
	C879.329712,484.303040 880.934875,482.517120 883.600891,481.728302 
	C887.589111,480.548279 891.586243,478.937714 895.424316,482.190186 
	C896.196106,482.844238 897.237183,483.203186 898.224426,483.135406 
	C908.528992,482.427795 918.421997,487.616699 928.823120,485.281677 
	C933.372253,484.260437 937.692200,486.213684 942.179688,486.185333 
	C944.374207,486.171448 945.906189,487.722443 945.798950,490.135803 
	C945.691711,492.548553 943.939270,493.007233 941.916504,492.990906 
	C940.583923,492.980133 939.249023,493.043121 937.918274,492.990479 
	C933.236877,492.805267 928.560364,492.495331 925.311096,496.976349 
	C924.756897,497.740692 923.610840,497.983490 922.257812,498.263245 
	C920.010864,498.478607 918.249573,498.058228 916.861389,496.788513 
	C914.522522,494.649292 912.022400,494.592590 908.690979,495.972504 
	C913.761475,497.426178 917.787659,499.982605 922.667725,501.322205 
	C925.751099,501.089935 927.150330,497.255432 930.735352,498.768066 
	C932.716064,498.996063 934.429749,498.997009 936.571716,498.997742 
	C937.595764,499.018768 938.131226,499.202545 938.796021,499.793274 
	C939.000000,499.998779 939.000000,500.000000 939.000000,499.999756 
	C939.832153,500.974579 939.514099,501.916138 938.659546,503.121643 
	C932.645935,504.095734 927.494263,506.620575 923.128967,508.811676 
	C927.749573,508.158325 933.090088,505.130707 939.214661,507.462769 
	C941.056580,507.460602 942.535767,507.479095 944.336121,507.998840 
	C945.843323,508.544952 946.725342,509.475067 948.217896,510.024658 
	C949.213867,510.666229 949.656555,511.352142 950.176392,511.979187 
	C954.034851,516.633301 958.281982,515.983398 960.734619,510.212036 
	C965.563782,510.768799 969.420776,509.168518 972.920715,506.415649 
	C973.695496,505.806274 974.687805,505.128174 974.431335,504.041016 
	C974.137146,502.794525 972.876099,503.028229 971.921814,503.017181 
	C968.425842,502.976624 964.928772,503.013336 961.433044,502.962891 
	C959.827759,502.939758 958.206055,502.486511 958.328430,500.548279 
	C958.446838,498.672729 960.216370,499.099884 961.473633,499.008972 
	C965.924194,498.687225 970.796387,500.190582 974.466919,496.162445 
	C973.720703,494.543579 972.377869,495.204193 971.337158,494.981842 
	C969.255127,494.536957 966.569946,494.642487 965.646118,492.544983 
	C964.610657,490.194092 967.731384,490.065857 968.744934,488.714020 
	C970.690063,486.119659 970.263794,483.399231 969.279846,480.677063 
	C968.610779,478.826141 967.151062,476.966309 968.766052,475.101135 
	C970.319702,473.306824 972.370667,474.144928 974.348694,474.586182 
	C979.732300,475.787140 982.233398,474.443146 983.992188,469.091034 
	C984.640015,467.119781 985.841431,467.016602 987.374023,467.010742 
	C991.036987,466.996735 994.700012,467.016113 998.362915,466.996185 
	C1002.165466,466.975464 1005.939270,467.279236 1009.596436,465.449738 
	C1011.063110,464.716034 1013.170227,465.158600 1014.973633,465.267609 
	C1015.488831,465.298737 1016.223633,465.949951 1016.397522,466.470551 
	C1016.739746,467.495300 1015.872498,468.023926 1015.033447,468.083923 
	C1005.928650,468.734985 1006.842041,475.506439 1007.072205,481.505066 
	C1007.307861,487.644562 1006.326233,494.176727 1011.418640,499.232758 
	C1012.391418,500.198578 1012.233887,501.813416 1011.333740,502.958679 
	C1010.244934,504.343964 1010.099243,505.546875 1011.276917,506.953003 
	C1011.959473,507.767914 1012.176086,508.842316 1011.141113,509.569824 
	C1010.259827,510.189178 1009.201538,510.035400 1008.314148,509.478455 
	C1007.339294,508.866547 1007.042480,507.904510 1007.618896,506.891296 
	C1009.239441,504.042908 1009.001465,502.182709 1005.244751,501.839722 
	C1004.191467,501.743530 1002.897949,500.604065 1003.450623,499.937347 
	C1006.668091,496.056061 1002.103333,494.073364 1001.291138,491.338806 
	C1000.253723,487.845947 995.587708,487.606384 993.187622,490.397400 
	C991.588074,492.257690 991.767029,495.003693 989.708862,496.823578 
	C988.761780,497.660980 990.427124,498.768188 991.628418,498.884491 
	C993.280029,499.044403 994.953735,498.960938 996.616089,499.028534 
	C997.996948,499.084686 1000.106384,500.385742 999.494202,500.839874 
	C994.974915,504.192688 1006.994019,508.014526 999.137390,510.673859 
	C996.702759,511.497986 993.692078,512.069885 991.333313,511.396820 
	C984.256592,509.377563 978.027039,511.644653 971.713135,514.124146 
	C970.943420,514.426392 970.167969,514.759460 969.363525,514.917969 
	C967.817322,515.222656 965.376892,514.722839 965.549438,517.018311 
	C965.738770,519.538208 968.597290,519.781067 970.268433,519.501648 
	C974.486877,518.796387 979.466309,519.922302 982.402466,515.250916 
	C982.832581,514.566711 984.095032,514.158997 985.015747,514.069336 
	C991.781860,513.410156 998.549133,513.993530 1005.302429,514.374329 
	C1006.530457,514.443542 1008.778381,514.626099 1008.609131,516.639160 
	C1008.461304,518.398193 1006.544434,518.117615 1005.330139,517.955811 
	C997.687317,516.937195 990.859680,519.425415 984.167053,522.628723 
	C983.220459,523.081787 982.097473,523.137024 981.301208,522.478027 
	C978.156494,519.875244 976.005066,521.470337 973.604370,523.797852 
	C970.888550,526.430786 970.392578,528.387512 974.367065,529.990234 
	C975.195496,530.324341 975.892395,530.751465 975.688660,531.764221 
	C975.486389,532.769470 974.518433,532.717224 973.778687,533.047607 
	C969.751526,534.846436 965.584229,531.706055 961.289062,533.726562 
	C959.131409,535.882385 956.679932,535.485718 954.398376,535.612610 
	C951.732117,535.760803 950.304382,536.947632 949.446899,539.594666 
	C946.524292,548.617432 940.192749,551.656189 931.124939,548.604309 
	C928.919983,547.862305 926.803650,546.872498 924.476318,546.499878 
	C920.654968,545.887939 917.928833,544.118774 917.627502,539.847656 
	C917.569214,539.020813 917.444275,538.163940 916.869385,537.509705 
	C913.094604,533.214783 897.555847,529.648376 891.542297,531.791382 
	C892.644104,533.771057 894.672791,533.367676 896.310608,533.765564 
	C898.726013,534.352356 901.327087,534.273315 903.549500,535.532349 
	C906.334717,537.110229 906.379517,539.716064 903.748535,541.408081 
	C899.844666,543.918701 888.605286,542.643982 884.881592,538.845215 
	C885.625977,544.353760 883.101746,546.607361 878.207886,546.695312 
	C876.642944,546.723450 875.190613,547.385132 874.873901,549.153320 
	C874.471008,551.402466 876.451355,551.273926 877.745544,551.793274 
	C879.425720,552.467468 881.676575,552.949219 881.210632,555.292236 
	C880.766724,557.524597 878.447205,557.288086 876.669006,557.496643 
	C875.678772,557.612854 874.671326,557.606323 873.675476,557.554626 
	C870.237122,557.375977 866.169434,558.595703 863.674438,555.752930 
	C861.111816,552.833313 862.754395,548.432190 861.501587,544.822021 
	C860.918823,543.142883 862.546753,542.224976 864.099915,541.767273 
	C865.532959,541.344971 867.072632,541.296753 868.419189,540.579285 
	C869.462769,540.023254 870.517151,539.396606 870.477112,538.017639 
	C870.437622,536.661255 869.395569,536.018311 868.327637,535.515320 
	C864.080383,533.515198 858.970581,536.406189 858.613342,541.117859 
	C858.398865,543.946472 857.792847,546.724121 857.638489,549.544861 
	C857.348877,554.836121 854.622009,558.803406 850.387390,561.417297 
	C847.507690,563.194885 846.969666,565.094482 847.899780,568.323364 
	C847.728088,569.774170 847.173340,570.575867 845.878906,571.209717 
	C842.967041,569.686218 840.527405,569.445496 838.010864,572.090332 
	C834.629822,571.630493 831.644104,570.882812 828.577576,572.636719 
	C827.196350,573.426758 825.589722,572.675354 824.811218,571.128723 
	C824.045837,569.608154 824.991150,568.623657 826.166321,567.823975 
	C827.132568,567.166504 828.072266,566.484253 828.736389,565.498474 
	C831.112122,561.971741 833.941589,561.086487 837.542847,563.838013 
	C838.394043,564.488342 839.450378,565.312195 841.002869,563.797058 
	C835.943481,561.387329 834.634033,554.447815 828.037170,554.091187 
	C827.724304,554.074280 827.302429,553.897095 827.166626,553.646667 
	C824.846252,549.367798 821.129517,547.441711 816.514587,546.125488 
	C813.621643,545.300354 813.820251,542.095886 816.489319,540.351257 
	C818.518799,539.024597 820.931519,538.126282 823.067078,539.511841 
	C826.119080,541.491943 826.663818,539.716125 827.339661,537.060181 
	C831.122620,531.186646 834.815796,526.203186 842.358398,525.067505 
	C845.666199,524.569397 848.267151,520.634521 851.183655,519.914795 
	C845.011169,524.023071 837.749329,522.952332 830.547913,520.564758 
	C832.998596,531.060120 823.223206,528.569336 818.641052,531.785400 
	C819.811829,534.994812 825.484802,532.072449 825.248901,537.033325 
	C821.573669,539.205688 817.950562,538.851440 814.464722,537.571411 
	C810.676086,536.180115 809.658203,533.749451 811.652893,530.269104 
	C813.096130,527.751038 811.890503,527.513672 809.985413,527.166931 
	C807.685669,526.748413 805.870483,527.896912 803.846619,528.684509 
	C795.730042,531.843079 787.482666,533.721375 779.035095,529.708313 
	C778.280579,529.349915 777.437134,528.883179 776.653625,529.104004 
	C769.712708,531.060120 764.562317,526.808167 758.997498,524.056396 
	C756.844788,522.991882 755.266357,521.549194 757.822388,518.898438 
	C758.754517,517.649292 760.342651,520.568054 760.529846,517.790283 
	C761.966736,514.484619 763.184387,511.163666 767.673828,511.540131 
	C768.563232,513.271667 769.784668,513.515137 771.215271,513.510498 
	C776.693787,513.492676 782.197632,513.138000 787.523987,515.005310 
	C788.331482,515.288391 789.549927,515.352661 790.142944,513.850464 
	C788.499878,512.250305 786.527222,513.148376 784.663513,513.170776 
	C779.422485,513.233826 774.082886,514.722961 768.822876,511.869415 
	C768.619507,508.568298 770.995667,508.865326 772.843445,508.587006 
	C775.409546,508.200470 778.063477,508.867157 780.492188,508.149567 
	C776.144531,503.571838 771.019409,501.726807 764.488708,503.909302 
	C763.668152,503.711578 763.241699,503.493744 762.618530,502.924072 
	C761.629944,501.449677 761.381714,500.018524 760.468262,498.506592 
	C758.718140,493.608551 755.874634,494.725616 752.516479,496.629761 
	C751.524353,496.500671 752.280518,496.930298 751.663574,496.679077 
	C748.466797,495.377472 744.194214,494.284302 744.651428,490.186615 
	C745.074402,486.396271 749.498413,487.807251 752.185791,487.024536 
	C754.558899,486.333313 757.146362,486.757721 760.450867,485.404327 
	C755.952332,484.323303 752.197083,483.752319 748.576721,482.695221 
	C746.172180,481.993103 743.106140,481.805023 743.453979,478.467773 
	C743.809570,475.056244 747.046143,475.714294 749.411194,475.441742 
	C752.396240,475.097778 755.615051,476.067200 758.496826,474.128021 
	C757.387146,472.289978 755.652161,472.674561 754.375122,472.054840 
	C753.259460,471.513428 752.344177,470.856964 752.350098,469.501709 
	C752.357239,467.877655 753.493835,467.231537 754.846497,466.828125 
	C755.324890,466.685455 755.822021,466.630096 756.312927,466.544800 
	C763.139893,465.359283 763.139709,465.358215 762.473572,458.259949 
	C762.839722,456.037598 761.167786,455.089966 760.485413,453.327576 
	C760.765015,451.008514 759.836731,450.425323 757.952087,450.437469 
	C755.526428,450.453156 752.995483,450.835266 750.569824,449.020935 
	C749.871887,447.794250 749.849792,446.817413 750.266113,445.477112 
	C752.786377,442.237183 757.087463,444.774048 759.555908,441.582977 
	C759.220886,437.798462 756.965698,436.559509 753.706787,437.951050 
	C752.261597,438.568115 751.497620,439.850708 750.285645,441.179657 
	C748.033936,441.876221 747.164124,440.767059 747.076111,439.071747 
	C746.929932,436.255219 748.083923,433.981323 750.414856,432.318512 
	C751.491943,431.550079 753.639832,432.102234 753.479980,429.500275 
	C753.455811,428.512085 753.890747,427.375916 753.696289,427.318726 
	C749.923706,426.208984 747.973572,422.824127 745.080994,420.657562 
	C742.484192,418.712494 739.938477,422.334656 736.829712,421.289978 
	C736.261597,420.909119 736.045288,420.595642 735.759155,420.502899 
	C728.561523,418.169769 727.667236,412.464142 728.131714,405.743378 
	C728.558838,404.828033 728.981506,404.361298 729.515076,403.985107 
	C730.566528,403.243805 731.730103,402.531769 731.203613,400.944489 
	C731.048462,400.476654 730.555115,400.186615 730.060852,400.257843 
	C728.303284,400.511139 728.955688,402.369354 727.825500,403.463409 
	C724.569519,404.571808 721.628418,403.239166 718.319763,403.621002 
	C716.035217,403.705750 714.441589,404.027069 715.920349,406.510468 
	C716.173584,406.935730 716.549255,407.287140 716.785583,407.721405 
	C717.851501,409.680603 720.234497,411.950256 717.948486,413.866699 
	C715.535095,415.889954 714.573059,412.306396 712.905334,411.324738 
	C712.177612,410.896423 711.483704,410.498749 710.292114,410.462738 
	C709.074402,410.480408 708.190735,410.448822 706.979980,410.481079 
	C704.937256,410.327393 703.884033,411.595276 702.284058,412.509521 
	C701.505737,412.771820 701.035828,412.818542 700.196777,412.748840 
	C699.112427,412.468170 698.469543,412.099945 697.610291,411.370728 
	C696.581299,410.051483 696.146301,408.911194 696.554626,407.256470 
	C698.278442,405.268890 701.022949,407.271942 702.474121,405.126587 
	C698.258606,402.156219 696.507690,402.405243 693.809326,406.472260 
	C690.556274,407.431335 688.796021,405.852325 687.395142,403.592651 
	C686.426270,402.029938 685.896484,400.303314 685.473511,398.520874 
	C683.556152,390.440857 683.542053,390.444244 691.663879,389.307739 
	C696.096924,388.687408 697.146423,386.870392 695.807678,380.796143 
	C695.022949,377.235901 695.236877,374.561218 699.583618,373.344696 
	C701.406189,373.186920 702.380371,375.003906 704.263367,374.192780 
	C703.926697,372.428741 702.364075,373.106140 701.073364,372.653015 
	C696.936890,370.312317 695.173340,365.929749 691.179016,363.368805 
	C690.398987,361.546936 689.966492,360.036102 690.652527,358.073730 
	C695.159302,356.009186 697.404358,358.777374 699.750488,362.040649 
	C700.454224,363.174500 700.387329,365.157532 702.139343,363.667542 
	C705.444763,360.856354 709.260742,361.194397 712.980713,362.094788 
	C716.076782,362.844177 719.295227,361.904175 722.598083,363.291504 
	C725.826538,368.296753 727.609741,368.696777 732.185547,365.239807 
	C734.182617,363.731018 736.226196,362.526367 739.240112,362.462372 
	C737.151855,361.342804 735.560791,360.435272 736.773560,357.897461 
	C742.974548,356.750793 748.868164,355.375854 754.848267,354.682617 
	C759.094116,354.190399 762.506348,356.991791 766.125061,358.776978 
	C762.581665,355.106171 759.004211,352.701019 753.230530,354.179291 
	C748.066833,355.501343 742.310120,354.211700 737.018799,357.315948 
	C731.684753,356.671631 727.171936,353.766388 721.581055,353.870178 
	C717.838623,350.041779 710.769165,348.085419 705.242798,349.496948 
	C707.448059,351.342224 710.831482,349.770294 712.396423,352.743530 
	C708.472595,357.066956 702.048645,357.422516 698.211548,353.742676 
	C697.208191,352.780487 696.205566,352.103699 694.520386,351.780334 
	C690.532410,348.978729 683.252991,349.662170 679.329956,353.238251 
	C674.239624,353.882080 671.724304,350.127045 668.842407,347.492432 
	C664.388611,343.420776 663.945312,343.063324 658.652893,345.971710 
	C654.388916,348.314880 650.589294,346.760773 646.967041,344.525421 
	C643.630920,342.466705 640.310364,340.766022 635.905701,342.160553 
	C635.934998,344.385712 639.521667,346.577057 636.206482,348.973114 
	C633.097534,351.220123 627.725525,351.685059 624.418884,349.880402 
	C620.667603,347.833160 618.119080,343.921112 613.302490,342.980835 
	C610.849792,342.535522 608.747192,342.124939 607.526245,339.678009 
	C607.374207,338.934021 607.912781,338.654297 607.802246,338.276001 
	C607.708862,337.956360 607.420654,338.061157 606.980896,338.628571 
	C601.195679,337.203125 595.927307,335.253326 592.406555,329.982727 
	C592.497437,329.394531 592.582764,329.169373 592.929443,328.697021 
	C594.373169,327.675598 595.671082,328.188873 597.167358,328.162659 
	C593.273926,326.739777 589.810059,324.890045 585.597046,325.833588 
	C583.017761,326.411255 580.953064,324.965393 580.337769,322.354980 
	C579.347107,318.152405 576.807068,317.224701 572.900024,317.998840 
	C569.557983,318.661072 566.343567,317.894104 563.214905,316.501678 
	C561.416260,315.701172 559.468079,315.116943 557.056763,315.121674 
	C556.445557,315.005981 556.201904,314.941895 555.603333,314.731995 
	C555.025879,314.454590 554.803223,314.322937 554.282593,313.943542 
	C553.826965,313.493744 553.669373,313.291718 553.370300,312.735992 
	C552.774170,309.027191 555.279907,308.232819 557.671204,307.111237 
	C552.728882,308.914642 548.311890,308.344391 544.343384,304.763672 
	C542.786987,303.359344 540.592773,303.606110 538.602844,303.581635 
	C534.783569,303.534668 530.944031,303.780426 527.153748,303.296997 
	C524.778503,302.994049 522.006897,302.882935 521.672058,299.190338 
	C526.524231,295.740051 531.755371,295.583313 537.086975,296.232300 
	C539.567200,296.534180 542.018005,296.649902 544.876648,296.163940 
	C547.638916,296.071472 549.838440,296.925140 552.092773,297.458740 
	C555.419922,298.246246 558.601746,298.261536 561.839050,296.866547 
	C566.648376,294.794067 571.527710,294.903168 576.367188,296.988281 
	C580.220337,298.648468 584.235413,298.979492 589.005615,298.693787 
	C587.876526,296.458893 586.310242,296.377319 584.980225,296.204468 
	C573.943726,294.770538 562.897095,293.095093 551.858032,296.194763 
	C549.862488,296.755096 547.902832,296.355469 545.559326,296.051392 
	C534.001953,296.300507 522.768616,296.970490 512.241943,291.861023 
	C514.668091,292.602936 517.246582,292.996185 517.461182,296.627563 
	C511.550995,301.441254 505.856110,298.723541 500.775452,295.950378 
	C495.473419,293.056427 490.445526,290.894104 484.387115,292.788666 
	C483.251587,293.143738 481.837830,293.129608 481.090607,292.256226 
	C477.905121,288.532928 474.269135,289.905823 470.244690,291.147827 
	C466.050964,291.701904 463.707489,290.446350 464.169067,285.774384 
	C461.280182,286.576874 460.280334,289.755737 457.205444,290.216370 
	C454.762848,290.454681 452.802032,290.005951 451.514679,288.279022 
	C449.275513,285.275238 446.320282,285.190491 442.993225,286.035248 
	C445.605621,286.790741 447.817017,288.354309 450.298859,289.355774 
	C451.831238,289.973999 452.869537,290.978760 452.653778,292.714539 
	C452.429474,294.519409 450.988922,295.038055 449.436737,295.272858 
	C446.214996,295.760284 443.216644,294.993317 440.254272,293.756165 
	C437.416748,292.571198 433.956116,292.260040 434.130463,287.836945 
	C434.176971,286.656128 432.711945,286.414307 431.797821,286.910065 
	C430.388489,287.674438 431.431091,288.616455 431.989594,289.324768 
	C433.526550,291.274017 435.067261,293.154968 431.436584,294.992188 
	C429.282471,295.203339 427.607147,294.715363 425.639465,293.880249 
	C425.139252,293.525482 424.963409,293.355682 424.661377,292.847656 
	C424.470001,291.057983 425.076843,289.495392 423.860596,288.232178 
	C423.080994,287.422424 422.386230,286.042786 421.124298,286.507843 
	C419.727234,287.022644 420.668335,288.577728 420.250183,289.951385 
	C417.787994,292.975098 413.326538,291.121155 411.338745,294.698029 
	C411.753052,295.843018 412.562469,296.000244 413.607880,296.399994 
	C414.085175,296.694641 414.257263,296.849884 414.589844,297.323730 
	C414.949463,298.374664 414.916870,299.101746 414.533325,300.168945 
	C413.017822,302.248627 411.162476,302.983704 408.683929,303.213074 
	C407.168793,303.118713 406.153870,302.685760 405.334106,301.880829 
	C402.472382,299.070892 402.561371,301.786835 402.052246,303.899231 
	C401.863007,304.437134 401.767181,304.644775 401.471130,305.130310 
	C400.205780,306.230682 399.222961,307.137909 398.105377,308.392090 
	C395.029785,309.084778 392.397400,308.057251 389.394409,307.561646 
	C388.570953,306.768951 388.659912,306.112244 389.112946,305.153748 
	C389.920654,304.048492 390.875580,303.434357 390.976196,301.938416 
	C391.286621,298.635895 388.821686,298.916473 386.830933,298.088989 
	C386.199341,297.229279 386.092560,296.551636 386.249023,295.510223 
	C387.751801,293.205505 386.320221,292.273743 384.732483,291.038574 
	C384.194763,289.346283 384.928833,288.453705 386.282837,287.529480 
	C390.956940,287.594604 391.553528,284.953400 391.168640,281.145142 
	C391.513336,280.100647 391.911407,279.495605 392.723511,278.754700 
	C395.998779,277.730225 398.862610,279.610046 401.895599,278.829285 
	C404.576202,278.139160 407.359192,278.722565 410.973877,279.325684 
	C407.753204,276.675171 404.959015,276.694824 402.168182,277.222504 
	C396.273285,278.337067 391.878479,275.012939 387.539398,272.073700 
	C384.684601,270.139893 385.751617,266.766632 389.138367,265.774567 
	C390.685822,265.321320 392.517731,265.978821 393.961365,264.484100 
	C393.026459,262.334442 391.913544,261.319366 389.425781,262.769592 
	C382.863739,266.594849 375.848877,264.801483 369.227295,263.353271 
	C366.562164,262.770386 363.484711,261.903351 360.650269,260.067017 
	C359.542938,258.797211 358.993988,257.566467 357.861206,256.793396 
	C356.178223,255.644897 354.585052,254.359589 355.320557,251.985382 
	C355.979340,249.858780 357.882294,249.683258 360.097229,249.508545 
	C364.330231,249.389359 367.529572,250.633163 370.409576,253.327408 
	C373.561615,256.276093 378.206451,256.201813 383.113678,252.936584 
	C374.873688,253.061951 368.373901,250.311615 361.565125,247.300018 
	C358.638245,241.291061 359.904816,239.195129 366.715698,238.700150 
	C372.803864,243.518066 380.774384,242.604889 386.802765,246.621811 
	C388.028168,247.438354 389.670319,247.123703 391.278137,246.105286 
	C392.189911,245.314804 392.393707,244.585999 392.086456,243.378510 
	C391.994324,242.771423 391.977600,242.515930 392.010101,241.860565 
	C392.839233,238.798416 395.028168,238.581070 397.241760,238.585632 
	C402.390167,238.596237 407.530762,238.597290 412.580109,237.377609 
	C412.902100,237.299820 413.246643,237.255524 413.571381,237.305969 
	C421.050262,238.467194 428.466492,240.123734 436.120117,239.839111 
	C437.266388,239.796509 438.231842,240.737274 438.680511,242.365234 
	C438.777679,244.076691 437.507019,244.631668 436.877106,245.997726 
	C440.266174,247.092285 443.818268,245.977554 447.387817,247.149918 
	C449.660278,248.580963 448.710754,250.881577 449.743073,252.782990 
	C451.217896,253.634033 452.486511,254.509918 453.728180,252.470215 
	C454.342316,251.105057 454.723419,249.992004 455.739380,248.826614 
	C456.476685,248.410828 456.950867,248.331604 457.790588,248.450500 
	C459.328552,249.211029 460.097198,250.350510 461.003479,251.291107 
	C464.855408,255.288864 464.785675,258.456818 460.972504,262.564209 
	C460.632355,262.930573 460.168854,263.222443 459.938354,263.642975 
	C458.834686,265.656525 454.930634,266.004578 456.041321,269.124573 
	C456.889862,271.508087 464.979675,274.016907 467.303162,272.647369 
	C471.263977,270.312775 475.118347,269.035980 479.562378,271.111542 
	C480.475372,271.537933 481.555389,271.499542 482.099396,270.320068 
	C482.391663,269.686401 482.395416,268.995178 482.073059,268.389160 
	C481.446472,267.211212 480.370453,267.580353 479.425842,267.787567 
	C477.778137,268.149017 476.138092,268.389282 474.484802,267.908234 
	C471.912201,267.159729 470.038177,265.607452 469.301575,262.972290 
	C468.551025,260.287292 470.023438,258.531006 472.022675,257.063293 
	C472.560028,256.668793 473.131073,256.319916 473.650360,255.901886 
	C474.707855,255.050537 475.812500,254.139923 475.391663,252.593430 
	C474.899719,250.785721 473.277985,250.679245 471.772247,250.539871 
	C470.442444,250.416763 469.044159,250.259674 467.789246,250.629715 
	C463.831177,251.796890 460.706696,250.695419 457.828125,247.462982 
	C452.209137,245.821991 446.620575,246.294724 441.042297,244.445145 
	C439.334259,240.832184 441.530823,239.699081 444.057587,238.822220 
	C446.468170,237.985657 449.009460,238.699448 451.873444,238.175903 
	C453.691956,237.833023 455.035339,238.149323 456.748901,238.645599 
	C459.642517,239.900131 462.676208,239.578903 465.260742,241.636932 
	C468.426636,242.770416 471.545471,242.286407 474.649414,243.663040 
	C476.736542,244.731659 478.372101,246.146912 480.251770,246.517776 
	C485.411926,247.535858 487.274231,250.567505 486.858276,255.762085 
	C491.349945,262.422729 499.083008,262.267395 505.062134,265.510345 
	C502.678284,264.856140 499.941833,264.771667 499.788269,261.317322 
	C499.621002,257.554596 501.829498,255.955795 505.145325,255.111542 
	C500.846863,255.034576 497.508026,259.811310 492.573853,257.230408 
	C491.059235,255.440567 490.579895,253.724792 491.279022,251.438324 
	C494.307587,248.810318 497.948181,248.660660 501.264587,247.899719 
	C506.164703,246.775406 510.897766,245.526016 514.902161,242.200012 
	C509.058228,246.925659 500.981262,244.415039 494.925537,248.721603 
	C493.327759,249.857864 491.074463,249.605530 488.897247,248.326126 
	C487.917419,246.808182 488.076233,245.588257 489.053345,244.107788 
	C491.246643,242.107666 494.604523,243.133774 496.231384,240.073059 
	C497.493317,238.659225 498.850372,238.028503 500.534332,237.224426 
	C504.166687,236.109726 507.536316,235.320312 510.438263,232.629532 
	C513.714050,230.742386 516.860168,231.445251 519.953857,231.853836 
	C526.691650,232.743698 533.427002,232.248947 540.176270,232.031250 
	C532.129272,231.659256 524.147339,230.744461 516.242554,229.341217 
	C513.062012,228.776627 511.773743,230.637177 510.034485,232.946320 
	C507.226227,235.194794 504.347992,234.670410 501.101379,234.322662 
	C496.482941,230.541443 491.285400,229.358917 485.642029,228.315033 
	C484.147003,227.439346 483.017517,226.641495 481.253326,226.495758 
	C478.015564,226.937943 475.802124,228.944809 472.759521,230.004333 
	C470.536774,230.553162 468.683167,230.577164 466.461853,229.907318 
	C465.386414,229.344528 464.871796,228.759689 464.373901,227.694672 
	C464.210327,222.695786 468.308167,224.268539 470.810455,223.085083 
	C468.339294,222.057846 465.829376,222.757401 463.172089,222.008377 
	C459.546967,220.408951 456.481567,218.417252 452.692383,218.134903 
	C450.963287,218.006058 449.501770,216.880524 449.555786,214.894287 
	C449.602295,213.183975 450.920624,212.070206 452.446808,211.939423 
	C457.907837,211.471375 459.650269,208.173325 459.776276,203.225540 
	C459.825195,201.303833 460.962891,199.845398 462.982819,199.261002 
	C464.567261,198.802597 467.089111,199.438385 466.881927,196.600372 
	C466.696747,194.063690 464.301575,193.930954 462.165222,193.061340 
	C456.765900,189.221619 457.144196,187.437668 464.014099,184.011322 
	C466.144012,183.693359 466.825897,182.523926 467.211395,180.932617 
	C467.763702,178.652893 469.328369,177.209961 471.485901,176.442078 
	C474.275696,175.449158 476.357910,176.573013 477.875702,178.880096 
	C479.385803,181.175430 478.261261,182.943253 476.549652,184.546646 
	C475.259888,185.754883 473.440033,186.461960 472.708313,188.340317 
	C474.674744,190.158752 476.875641,189.344604 478.939331,189.340042 
	C480.831970,189.335846 483.394806,188.132233 484.193665,190.607178 
	C484.999725,193.104492 482.409271,193.520966 480.694794,194.524841 
	C478.148621,196.015747 477.811981,197.922165 481.231262,199.480438 
	C482.858063,199.402893 484.084137,199.609528 485.638672,200.123352 
	C487.979950,201.955063 490.961517,203.516357 488.845825,206.657990 
	C486.951324,209.471268 483.927643,210.623398 480.484863,209.324570 
	C478.307922,208.503281 476.263794,207.307571 473.901062,207.036438 
	C471.831146,206.798904 470.112823,207.425003 469.773651,209.655502 
	C469.398590,212.121979 471.368500,213.236404 473.183990,212.872559 
	C480.173615,211.471771 487.283173,211.458069 494.249939,211.292374 
	C494.523315,209.216049 493.297089,208.645248 492.602661,207.801453 
	C490.731049,205.527191 491.037537,203.302582 493.207001,201.708145 
	C495.668274,199.899200 496.553986,197.925339 495.957184,194.888382 
	C495.271637,191.399658 497.196991,189.524338 500.599487,189.220810 
	C504.021179,188.915573 505.800629,190.662445 505.649048,194.739044 
	C505.532623,197.870407 507.121582,199.065216 509.725189,199.251404 
	C514.501343,199.592972 519.283081,200.438156 522.263000,194.730011 
	C524.115540,191.181473 527.927795,193.121185 530.950073,193.496429 
	C533.642029,193.830658 535.679199,195.062637 536.236084,197.988083 
	C536.486633,199.304565 536.517212,200.638657 536.613098,201.966873 
	C536.855530,205.326736 538.655396,207.142319 541.996643,207.376587 
	C549.135193,207.877106 555.584473,210.188187 561.567261,214.159637 
	C565.450562,216.737396 567.418640,216.130188 570.101562,212.428223 
	C572.387634,209.273712 574.245056,209.216904 578.874023,212.331757 
	C580.123291,212.764801 581.012451,213.155853 582.337280,213.335907 
	C593.890930,216.110931 604.605835,209.482178 616.141602,211.757690 
	C617.516602,212.039810 618.470886,212.387085 619.798584,212.748688 
	C624.260498,214.078659 628.273499,213.724442 633.206909,212.542938 
	C629.785767,209.751617 626.967651,210.504333 623.805542,211.159470 
	C621.833374,211.541031 620.225830,211.514374 618.208130,211.242996 
	C616.378418,210.602936 614.830688,210.824829 613.374146,210.460678 
	C611.507019,209.993851 609.606323,209.284912 609.635559,207.078461 
	C609.664856,204.874893 611.657471,204.260498 613.460510,203.739563 
	C613.941101,203.600723 614.440308,203.492371 614.936951,203.493164 
	C621.276917,203.503235 627.526428,202.183029 633.880371,202.277237 
	C637.945068,202.337494 641.896973,202.313034 644.809937,205.884064 
	C645.964417,207.299408 647.822388,207.224045 649.470276,207.234924 
	C652.937012,207.257843 656.397217,207.616074 660.575134,207.830307 
	C656.706726,204.283508 653.143860,202.153442 648.442505,202.657440 
	C647.292236,202.780762 646.105469,202.662338 644.959534,202.420792 
	C643.290894,202.069107 641.549316,201.651077 641.435974,199.528534 
	C641.316711,197.294601 642.847046,196.175446 644.760620,195.784836 
	C649.380493,194.841843 654.056091,195.406769 659.127686,195.529419 
	C662.108093,195.330368 664.551941,195.605453 666.915527,197.599762 
	C670.345459,200.510147 669.367371,205.327545 672.809692,208.200974 
	C673.231018,208.639465 673.374207,208.837219 673.639526,209.378311 
	C673.988892,212.811371 671.755798,213.556290 669.458496,214.269135 
	C668.229553,214.650436 666.088501,214.612808 666.140259,215.948349 
	C666.322449,220.648865 663.336304,222.158813 659.717346,223.405182 
	C658.948181,223.670090 658.706238,224.690598 658.447571,225.472336 
	C657.396790,228.648804 655.340637,230.406845 651.888794,230.569656 
	C650.487183,230.635757 648.500916,230.535431 648.343384,232.499268 
	C648.167114,234.695724 650.178223,234.943527 651.791809,235.290878 
	C653.240784,235.602768 654.784851,235.467255 656.408081,236.449814 
	C657.883362,237.619537 658.302856,239.111130 659.187317,240.275391 
	C659.864929,241.167572 660.842346,241.269623 661.844666,240.955887 
	C662.348328,240.798248 662.638977,240.335236 662.489197,239.813232 
	C662.221252,238.879807 661.345764,238.735413 660.563965,238.558060 
	C659.281494,238.267105 657.984497,238.039276 656.768799,236.746567 
	C654.659058,230.888138 655.244812,229.256714 660.253784,227.392075 
	C661.993164,226.744583 663.045288,225.837402 663.497192,224.083313 
	C663.966248,222.262436 664.994385,220.799622 666.916809,219.549194 
	C667.746765,219.158691 668.240784,219.003464 669.113525,218.822998 
	C674.397705,215.492249 679.928467,215.013107 685.246948,215.440292 
	C686.008179,214.947998 685.997375,214.717087 685.558655,214.558990 
	C683.450439,213.799210 680.115234,213.641846 680.756470,210.618927 
	C681.578003,206.746536 685.361511,207.690735 688.624451,207.596680 
	C689.465088,207.692062 689.935364,207.795578 690.728271,208.077789 
	C696.249756,210.534988 696.677124,214.973328 696.616211,219.897064 
	C696.595703,221.558960 694.760986,225.012192 699.444946,224.490662 
	C702.363525,223.114502 705.271362,223.915787 708.088379,223.181671 
	C707.422302,221.925949 706.023804,222.342865 704.858093,221.512924 
	C703.032898,219.545486 701.238037,217.691696 703.194397,215.314926 
	C704.856873,213.295273 707.012329,214.169739 709.081970,214.901901 
	C711.719849,215.835083 714.176575,217.123367 717.023804,218.507416 
	C722.191589,220.693283 726.705078,223.448257 732.195557,224.795319 
	C733.510437,229.266968 731.009460,230.642807 727.195190,231.293671 
	C723.187195,230.726166 719.596008,229.919983 716.146912,228.751297 
	C713.176575,227.744873 710.944214,228.302170 709.052795,230.623901 
	C707.476074,232.559326 705.419617,233.872681 703.259521,235.609329 
	C710.857300,231.192810 718.274170,232.893677 725.767639,235.258194 
	C727.606445,235.838409 729.708618,234.634583 732.026367,235.500916 
	C734.612976,238.059296 735.945618,240.496307 732.447388,243.380951 
	C730.763000,244.021225 729.405090,243.836929 728.231628,243.234741 
	C722.501587,240.294373 716.773743,240.620346 710.663818,243.176331 
	C707.287354,245.612198 703.974060,247.589142 702.035034,251.077972 
	C701.227173,252.531555 699.874512,253.136093 698.216187,252.743713 
	C696.670105,252.377884 696.143005,251.150299 696.042236,249.745209 
	C695.756531,245.762665 693.018433,246.486923 690.562073,246.942841 
	C688.979004,247.236633 688.159363,248.405060 689.135803,249.882874 
	C690.773865,252.361969 692.102051,255.220230 694.791687,256.745178 
	C697.407959,258.228546 700.197937,259.354858 701.003967,254.699097 
	C701.378357,252.536285 703.089905,251.863327 705.034729,251.612274 
	C711.021362,250.839401 716.020447,253.718521 721.061768,256.165833 
	C722.943970,257.079498 723.304443,259.350616 723.421448,261.417297 
	C723.554810,263.772430 722.959717,266.044281 722.690125,268.354828 
	C722.267273,271.977936 720.416992,274.061432 716.776123,274.722656 
	C713.868896,275.250641 711.002991,276.020874 707.294739,276.842407 
	C710.698425,277.768982 713.330505,277.897308 715.900940,278.361206 
	C717.046082,278.567871 718.389160,278.374054 719.314026,278.996063 
	C724.144043,282.244263 728.773315,285.769501 733.869873,288.649170 
	C735.608643,289.631592 736.464478,291.884033 734.835022,293.868988 
	C733.123535,295.953888 730.943726,296.257568 728.598267,294.811707 
	C727.600891,294.196899 726.755615,293.387299 725.845642,292.660522 
	C722.785278,290.216187 719.564087,290.022614 716.034912,292.446136 
	C721.340088,293.727325 726.523499,296.428406 731.863464,296.242157 
	C744.357239,295.806396 756.718201,297.540955 769.158264,297.596863 
	C770.325500,297.602112 771.487244,297.863037 772.562744,298.325531 
	C775.025024,299.384399 777.886169,300.385864 777.657532,303.677856 
	C777.453491,306.616272 774.824585,307.475891 772.383606,308.273621 
	C770.983337,308.731201 768.532532,308.333435 769.322388,311.041168 
	C770.714539,315.813538 767.784058,316.884308 764.116699,317.462982 
	C763.376892,317.579712 762.359375,317.277191 762.477295,318.769562 
	C772.551697,320.715485 792.467468,319.561707 795.487061,316.495453 
	C787.952393,319.350006 780.947510,318.992889 773.580078,317.869263 
	C770.166626,313.090302 771.024841,309.971649 776.061157,308.970367 
	C780.020386,308.183258 783.320984,307.905701 785.115356,302.982910 
	C786.663330,298.736115 796.212036,300.325684 798.283447,305.067993 
	C799.665405,308.232117 801.911255,309.223480 804.947998,309.081451 
	C806.672241,309.000854 807.376953,308.063568 806.199646,306.428528 
	C806.005554,306.158966 805.805664,305.887360 805.564209,305.661621 
	C802.412170,302.714935 803.508789,296.518799 799.581482,295.225037 
	C796.260559,294.131073 792.065308,296.617188 788.021240,296.623444 
	C782.616272,296.631836 777.432312,296.019531 772.445923,293.959595 
	C770.223083,293.041321 768.644531,291.758545 770.164856,289.028198 
	C770.888123,287.729156 772.962036,286.438141 770.782227,284.857544 
	C768.878662,283.477234 766.175415,281.996094 764.329163,283.496918 
	C759.594849,287.345459 755.742310,285.235626 751.628723,282.722870 
	C749.299072,281.299744 746.472839,281.540497 743.573486,280.701965 
	C741.397156,280.693420 739.594604,280.669220 737.408569,280.497253 
	C736.194153,280.484314 735.363037,280.568787 734.542542,280.408844 
	C732.675232,280.044861 730.815247,279.444763 730.616455,277.252441 
	C730.411865,274.996460 732.196472,274.012299 734.023682,273.624847 
	C741.869995,271.961029 749.759583,271.419830 757.579346,273.720367 
	C760.254272,274.507324 762.789490,275.851746 765.804749,274.768982 
	C764.904663,272.983093 762.905457,271.866394 763.156250,269.226929 
	C763.330750,268.554047 763.445374,268.293732 763.859619,267.719543 
	C764.655640,267.084991 765.191711,266.857086 766.203796,266.637787 
	C773.057922,266.417236 779.408569,265.252228 785.834106,265.293488 
	C788.291443,265.309265 790.823730,265.206177 793.028931,267.464630 
	C792.814636,271.929810 789.572144,274.270599 784.536377,274.125122 
	C782.574707,274.068451 780.694702,273.063385 778.278870,273.557648 
	C779.171448,275.443787 780.730652,275.434204 782.020203,275.258240 
	C786.610413,274.631775 791.272034,274.557404 795.734375,272.943878 
	C801.988037,270.682648 805.650574,272.220581 808.814026,278.013031 
	C810.742249,281.543701 815.640625,283.436676 819.439209,281.975861 
	C821.004456,281.373962 822.504578,280.699280 824.591919,280.874634 
	C826.649841,281.227173 828.355469,281.442261 830.435913,281.628601 
	C833.558655,282.083466 834.965881,284.934052 838.017395,285.505280 
	C839.125549,285.583466 839.839722,285.767487 840.888184,286.147278 
	C843.403076,288.142975 846.564453,287.275116 849.052734,289.252899 
	C850.834229,292.199310 853.761414,292.363525 856.508057,293.547974 
	C857.676453,294.789062 858.656128,295.662201 860.278320,295.501434 
	C859.236816,295.145264 858.151550,295.147980 857.140381,294.129700 
	C856.242371,293.179047 855.772888,292.376404 855.475830,291.094391 
	C854.391663,287.386566 850.447083,288.367859 848.442139,285.852570 
	C847.829224,284.443726 847.870850,283.373077 848.492310,281.930573 
	C852.148560,279.212524 856.199890,278.510986 860.109680,277.704163 
	C862.506409,277.209503 865.122620,277.862915 865.224365,281.615845 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M847.317017,569.234253 
	C844.735291,564.873535 844.331604,562.584717 849.985107,559.841370 
	C856.883362,556.494019 856.516479,548.289734 856.977844,541.372131 
	C857.152405,538.754639 856.353882,535.217346 859.598083,534.581726 
	C862.914551,533.932068 866.497742,531.576843 870.038147,534.377258 
	C871.332336,535.400879 873.027039,535.999756 872.919739,538.073059 
	C872.809387,540.205383 871.327637,541.223755 869.675659,542.143188 
	C868.305725,542.905701 866.836548,543.085205 865.324585,542.993652 
	C862.172791,542.802917 862.155273,544.125366 863.201538,546.613159 
	C864.097412,548.743408 865.584717,550.769226 863.881653,553.584961 
	C862.883118,555.235901 865.437561,555.822205 866.741516,555.871521 
	C871.202881,556.040100 875.674500,555.936096 880.528381,555.936096 
	C879.794800,553.196899 877.968384,553.235168 876.242920,552.985413 
	C874.663818,552.756775 871.824585,553.819458 872.400879,550.769287 
	C872.842163,548.433533 872.897339,545.097595 876.728394,545.018066 
	C877.727661,544.997314 878.727661,544.989380 879.727112,544.998962 
	C882.526428,545.025757 884.407043,544.378967 883.189758,540.907043 
	C882.750366,539.653809 882.908325,538.258118 883.556030,537.015137 
	C884.014587,536.135254 884.442749,535.120911 885.697449,535.307861 
	C886.717224,535.459839 886.917297,536.328857 886.957214,537.184509 
	C887.153076,541.374573 889.423706,541.857788 893.055237,541.132446 
	C895.785889,540.587097 898.709106,541.086060 901.522034,540.839355 
	C902.928833,540.715881 904.769348,540.306763 904.662964,538.339905 
	C904.577087,536.752991 902.966675,536.360779 901.685669,536.157471 
	C898.580750,535.664673 895.453918,535.276611 892.322632,534.994324 
	C890.073303,534.791687 889.014343,533.697876 889.045837,531.471619 
	C889.079956,529.055359 890.808472,528.757446 892.515808,529.045959 
	C899.359924,530.202148 907.000122,528.411316 912.599426,534.388184 
	C913.064575,534.884766 914.155151,534.996094 914.939331,534.958374 
	C918.456116,534.789001 920.498657,535.583923 919.119690,539.799683 
	C918.497070,541.703125 919.397156,543.903015 921.476379,544.100403 
	C927.264832,544.650146 932.166138,548.374329 938.237976,548.118896 
	C943.493774,547.897888 947.180542,544.981262 947.568970,540.751892 
	C948.171143,534.194702 951.566345,533.561340 956.588989,533.985596 
	C957.747009,534.083435 958.920227,534.000000 960.543213,534.000000 
	C961.000061,534.164246 961.032227,534.336060 960.995483,534.491638 
	C960.252747,537.637878 963.658997,541.089111 960.756531,543.893860 
	C958.274719,546.292053 954.787842,544.563965 951.783630,545.001465 
	C950.813782,545.142639 949.516846,545.004639 949.373840,546.195496 
	C949.092834,548.535278 951.526978,547.591003 952.748535,548.739197 
	C953.608643,550.142029 952.459778,551.540100 953.729309,552.880981 
	C957.779968,556.671936 964.955688,556.454834 968.972473,552.499329 
	C970.353699,551.895752 971.389404,551.925842 972.703369,552.717651 
	C973.005066,560.856140 973.005127,560.854370 980.866821,561.001343 
	C983.922363,561.058411 986.874023,561.685974 989.994263,560.216675 
	C991.739319,559.394897 994.685364,559.194885 995.918152,562.215820 
	C996.813293,564.409302 996.936707,566.417786 995.122864,568.172668 
	C993.101013,570.128723 990.951355,570.026550 988.884949,568.277588 
	C987.185852,566.839600 985.747925,566.886108 984.061646,568.402954 
	C982.069397,570.195068 981.652710,572.321472 982.192505,574.740845 
	C982.660156,576.836670 984.376953,576.984802 986.114380,576.993652 
	C987.280945,576.999573 988.448181,576.990601 989.613892,577.027222 
	C990.811035,577.064819 992.235962,577.222107 992.385376,578.585083 
	C992.577637,580.339172 990.796143,579.815491 989.831726,580.196594 
	C988.749817,580.624084 987.572998,580.855713 986.558350,581.398499 
	C985.145081,582.154358 984.288269,583.541626 984.426880,585.083679 
	C984.575500,586.736206 985.932007,587.647949 987.518494,587.880005 
	C989.888672,588.226807 992.843262,587.499756 992.969971,591.352844 
	C993.100464,595.320007 990.326233,596.176331 987.256348,597.000000 
	C981.377563,598.577271 979.554077,601.369751 981.020142,606.113770 
	C982.010925,609.319824 987.001160,611.731140 990.900085,610.811035 
	C991.858887,610.584778 992.742371,610.052551 993.674866,609.699036 
	C996.084900,608.785583 998.753418,608.209351 1000.832947,609.822998 
	C1002.926392,611.447327 1002.244141,614.195557 1001.643433,616.378601 
	C1000.399170,620.900940 1001.145508,625.256165 1001.994263,629.684204 
	C1002.309814,631.330200 1003.121399,631.981384 1004.669556,631.967285 
	C1006.002808,631.955200 1007.751465,632.104919 1007.815063,630.340149 
	C1008.010315,624.923584 1012.319946,622.411133 1015.457886,619.110413 
	C1017.449158,617.015808 1019.123413,615.116760 1019.150940,611.867981 
	C1019.188477,607.435547 1022.759949,607.652039 1025.743652,608.114136 
	C1029.017334,608.621155 1029.778320,611.358582 1028.463623,613.774170 
	C1024.791260,620.521729 1026.221069,627.718811 1026.055176,634.761414 
	C1025.961060,638.751648 1025.051880,641.724243 1020.449585,642.011719 
	C1018.468994,642.135376 1017.989563,643.337952 1018.003723,645.047485 
	C1018.024475,647.547119 1018.026672,650.048096 1017.969238,652.546875 
	C1017.909302,655.151733 1016.748413,657.296692 1014.133362,657.691589 
	C1011.761536,658.049683 1009.761963,659.044189 1007.624695,659.910583 
	C1003.222229,661.695068 999.957153,659.912354 1000.145630,655.305298 
	C1000.338989,650.579224 998.391357,649.653076 994.272095,649.923096 
	C990.753235,650.153809 987.212280,650.720886 984.580322,646.666016 
	C982.162170,642.940674 962.521545,638.980225 958.923645,641.475098 
	C956.435852,643.200195 953.639648,644.804199 951.577942,647.056519 
	C949.294739,649.550659 946.720154,650.366272 943.555725,649.998413 
	C939.553894,649.533203 937.658081,651.040283 937.946777,655.320862 
	C938.232666,659.558472 936.964294,664.519165 943.928223,664.262878 
	C944.936401,664.225830 945.861206,665.257507 945.799377,666.304688 
	C945.318665,674.437805 952.373047,673.258362 956.781067,674.800598 
	C959.142273,675.626648 961.585266,675.294678 962.442871,678.419617 
	C962.843323,679.878784 964.444336,679.986450 965.823364,679.989319 
	C968.156677,679.994141 970.519958,679.767822 972.814209,680.068665 
	C974.837158,680.333984 978.485840,678.552856 978.486633,681.849243 
	C978.487366,684.962463 978.946045,689.766418 973.386597,690.010864 
	C973.220154,690.018188 973.025574,689.984009 972.892273,690.056946 
	C968.899841,692.243530 969.478394,699.587952 962.922668,698.888123 
	C961.554504,703.593384 961.189697,708.127014 963.743958,712.719116 
	C966.225525,717.180481 964.983521,722.178955 964.693909,726.931702 
	C964.587708,728.674194 962.984619,730.406128 961.031189,730.527466 
	C959.130432,730.645630 958.343811,728.781494 957.692749,727.257690 
	C956.327026,724.061340 957.156860,720.671570 957.032166,717.371460 
	C956.939514,714.919861 956.453125,713.116821 953.508789,713.000244 
	C950.746826,712.890869 949.139343,713.704468 948.920471,716.830566 
	C948.664551,720.485657 945.758606,723.350586 945.610840,727.204102 
	C945.547791,728.848633 943.763000,729.966797 941.897827,729.954041 
	C938.355713,729.929810 937.853882,732.144043 937.991577,734.952148 
	C938.048645,736.116028 938.099792,737.295105 937.981934,738.449768 
	C937.614075,742.052917 938.739441,743.974182 942.790771,744.255981 
	C946.152039,744.489929 948.549438,744.881104 946.385071,750.284729 
	C944.399536,755.242004 944.895142,761.851379 946.897461,767.228394 
	C948.407410,771.282959 949.079224,775.141846 949.019348,779.329041 
	C948.940674,784.827881 948.862305,790.333191 949.042419,795.827271 
	C949.190308,800.339966 947.254944,802.346130 942.781555,802.006836 
	C941.125366,801.881165 939.409729,802.067261 937.817078,801.697876 
	C935.930237,801.260193 933.933716,800.552856 933.295776,803.017578 
	C932.735413,805.182373 932.207581,807.794067 935.326233,808.867493 
	C936.216675,809.173950 937.580322,809.009399 937.447693,810.403564 
	C937.345703,811.475342 936.254517,812.065491 935.298279,812.274170 
	C930.729736,813.271179 926.099365,813.983826 921.741516,811.621948 
	C920.544617,810.973267 919.631775,809.747192 918.378906,811.191406 
	C913.598633,816.702148 906.614807,815.511230 900.553284,816.833191 
	C897.067261,817.593445 894.905640,818.459045 894.993530,822.288635 
	C895.036377,824.155090 894.379211,825.525879 892.421814,826.031433 
	C890.966553,826.407227 890.084045,827.364868 890.060547,828.874207 
	C889.988098,833.542358 886.959412,834.417542 883.258362,833.956421 
	C877.842712,833.281677 873.603699,834.443237 871.557617,841.003784 
	C874.737854,841.003784 877.806152,840.980164 880.873718,841.017944 
	C881.822205,841.029602 883.069092,840.763123 883.415894,841.997986 
	C883.724731,843.097717 882.690918,843.747864 882.021851,844.450073 
	C879.886536,846.691040 877.179932,847.185974 874.232239,847.013306 
	C872.903503,846.935425 871.532043,846.812378 870.241760,847.047974 
	C868.656738,847.337402 865.890686,845.839478 865.765076,848.420776 
	C865.667480,850.426453 868.337708,849.796082 869.899292,850.041809 
	C870.544861,850.143372 871.394226,850.176208 871.768555,850.587952 
	C875.810913,855.033752 879.604797,853.728394 883.326233,850.190979 
	C884.547241,849.030334 885.587402,849.217529 886.792297,850.283264 
	C888.576294,851.861389 889.481079,853.686035 888.760132,856.040405 
	C888.379822,857.282471 887.372681,858.134949 886.096619,857.751648 
	C880.288147,856.006897 874.569275,857.578064 868.804626,857.963806 
	C862.489075,858.386536 856.271851,856.334473 849.462524,857.000000 
	C845.870300,853.255249 841.991577,852.809814 837.014221,854.264221 
	C840.963257,856.634949 845.157654,854.338257 848.739746,856.764465 
	C849.241455,860.482178 846.367798,861.302551 844.130066,861.660767 
	C832.885132,863.460449 821.737732,866.325684 810.157227,865.039062 
	C805.063538,864.473083 797.621582,856.727112 797.984497,852.058533 
	C798.162415,849.770325 798.511108,848.822571 801.319580,849.753174 
	C807.200806,851.701843 811.797729,856.016113 817.593323,858.029907 
	C821.291260,859.314880 824.912781,860.858154 828.674927,861.902344 
	C830.409180,862.383728 832.685669,862.028625 832.745972,859.494873 
	C832.798035,857.307861 830.908752,857.046021 829.141052,857.004578 
	C827.158020,856.958069 824.891541,857.328796 825.051941,854.159180 
	C825.189331,851.444214 826.980652,850.985046 829.192993,850.996094 
	C831.065613,851.005371 833.127380,851.115601 832.909485,848.316101 
	C832.660828,845.121338 830.026184,845.808777 828.325317,846.081543 
	C816.891785,847.915527 805.394836,846.581299 793.470947,847.000000 
	C790.884644,843.951843 792.440918,842.074036 795.201904,840.957397 
	C797.634460,839.973572 799.897766,839.154541 799.034546,835.838013 
	C798.051758,832.062134 795.218018,833.902466 792.667786,833.802734 
	C790.168701,832.776794 787.988525,831.186523 785.700562,834.220093 
	C773.475952,840.261047 761.913208,837.011353 755.568298,827.372681 
	C753.897034,824.833862 753.784790,821.171997 750.072021,820.080444 
	C745.060303,818.606995 739.987610,817.662598 734.849854,818.934265 
	C732.033813,819.631287 729.288208,820.883606 727.088379,818.064636 
	C724.752686,815.071472 725.262085,811.840515 726.909851,808.679382 
	C727.217651,808.088867 727.593140,807.536255 727.934753,806.964661 
	C731.794556,800.506165 733.474792,800.528259 737.197266,807.104614 
	C737.697937,807.989075 738.239624,808.807312 739.608643,809.285645 
	C740.755127,809.383789 741.525208,809.353027 742.668762,809.461670 
	C745.850769,809.076721 748.055664,807.826660 749.214417,805.112183 
	C750.556946,801.966980 753.212524,801.185913 756.205322,801.606995 
	C761.330811,802.328308 766.473083,802.437805 771.625977,802.521545 
	C771.958374,802.526917 772.294617,802.546326 772.618713,802.616150 
	C775.886780,803.319092 780.140747,803.853516 780.250000,807.721680 
	C780.367493,811.882874 776.114258,812.194580 772.779602,812.553467 
	C770.304810,812.819763 767.852112,813.037598 766.047363,815.977173 
	C769.760498,816.518677 772.886597,815.341309 776.053040,815.268127 
	C780.190308,815.172546 784.308533,814.480286 788.470764,814.301270 
	C793.176636,814.098877 794.263916,812.432373 793.305908,807.872009 
	C791.584290,799.677307 793.177124,797.659485 801.562195,797.424011 
	C802.891785,797.386597 804.234070,797.457397 805.540100,797.178711 
	C807.008301,796.865356 808.702393,796.571350 808.678528,794.618408 
	C808.655273,792.717651 806.963745,792.272705 805.520935,791.992065 
	C801.833374,791.274902 798.231689,791.345337 795.052429,793.791077 
	C793.597656,794.910217 792.092468,795.993713 790.193298,796.192322 
	C787.501892,796.473755 785.062866,795.956726 784.153442,793.042114 
	C783.271973,790.216858 784.472900,788.210205 787.206299,787.092651 
	C787.952759,786.787476 788.855042,786.848328 789.497131,786.112244 
	C787.304199,785.544250 785.233337,786.351624 783.132690,786.477295 
	C780.069397,786.660706 777.188843,786.155457 774.437988,784.760498 
	C769.502869,782.257874 769.320496,780.576538 773.708130,776.992249 
	C774.611877,776.253967 775.668823,775.618164 776.235474,774.608704 
	C779.564331,768.678772 785.506592,767.131531 791.458130,765.092102 
	C791.620789,764.262146 791.695740,763.779968 791.804565,762.918701 
	C792.198547,756.095337 793.825684,754.609436 800.297974,754.417114 
	C802.973328,754.337646 805.959290,755.556091 808.491394,752.700928 
	C809.682861,751.959839 810.661011,751.775208 811.635315,751.556030 
	C816.976196,750.354797 817.072449,750.236755 813.549744,746.113159 
	C811.630676,743.866760 812.388733,741.977295 813.496216,739.754211 
	C816.645569,733.432190 815.830139,732.034912 809.098938,729.907532 
	C804.698303,728.516785 800.267090,728.591675 795.789734,728.565735 
	C793.798706,728.554199 791.779297,728.717163 789.836365,728.202759 
	C786.095886,727.212524 785.392700,724.750488 788.275208,722.196838 
	C789.833191,720.816528 791.378784,719.539246 791.299866,717.344910 
	C791.118530,712.304382 793.619019,709.664185 798.691895,708.648804 
	C799.318176,708.054138 799.691223,707.731873 800.361938,707.192139 
	C803.856750,704.448547 802.886353,702.784058 799.421875,702.243591 
	C795.159912,701.578613 791.687195,699.912231 788.947571,696.478027 
	C786.406982,693.293335 786.748169,690.594116 790.636230,689.980408 
	C794.315735,689.399719 795.646667,687.413147 796.585083,684.014282 
	C804.447571,681.343018 811.272644,675.424744 820.146118,678.394348 
	C820.921143,678.653748 821.764832,678.233459 822.705200,677.409058 
	C823.514465,676.492554 824.126099,675.946228 825.166931,675.252014 
	C829.862122,673.401978 833.996826,675.100952 838.178467,675.785706 
	C839.166565,675.947449 840.094360,676.551086 840.292114,677.630371 
	C840.549011,679.032532 839.520325,679.715271 838.434692,680.197510 
	C836.294861,681.147949 833.998596,681.442322 831.302979,681.784790 
	C829.205078,682.456970 827.376648,682.609497 825.426697,683.633423 
	C818.907349,684.873962 813.197266,687.978333 806.765991,688.029846 
	C805.793152,688.037659 804.836487,688.515686 803.951172,688.983215 
	C800.312317,690.904724 800.159790,693.252686 803.441345,695.729248 
	C807.178040,698.549377 811.408936,699.715271 816.392456,699.719971 
	C816.988770,699.830933 817.223022,699.893188 817.793152,700.089478 
	C819.581299,701.466248 821.458008,701.498413 823.167664,702.023926 
	C825.022095,702.593750 826.466309,703.880005 826.735046,705.769226 
	C827.093750,708.291199 828.716675,709.354370 830.716736,710.308960 
	C835.378174,712.533813 836.521973,712.011963 837.727539,706.973328 
	C837.921082,706.164551 838.211487,705.404785 839.033203,704.515259 
	C840.572021,703.667908 841.847717,703.639160 843.500732,704.217651 
	C846.306152,709.041077 846.006531,711.313904 842.472534,714.844116 
	C839.361877,717.951538 839.699036,719.846680 843.582947,722.033569 
	C847.521179,724.251160 851.701294,725.611023 856.515747,726.197266 
	C858.235535,727.310120 858.046936,729.032898 858.774841,730.375183 
	C860.056274,729.753845 859.540161,728.277832 860.427734,727.124634 
	C861.947998,725.809021 864.254150,725.519104 864.561707,726.699402 
	C865.932007,731.957825 869.683716,729.038269 872.765259,729.572266 
	C874.729309,732.544312 871.274231,733.095642 870.661011,735.181213 
	C874.841919,736.101257 878.515137,735.711121 880.908264,731.782776 
	C882.363342,729.394348 885.719238,730.027893 887.004028,727.235352 
	C887.169189,724.234863 885.979492,722.485718 883.515381,721.497070 
	C881.504578,720.690247 879.658691,719.553223 877.948914,718.235168 
	C875.324219,716.211853 872.809021,716.210083 869.979919,718.577759 
	C867.061035,719.317505 865.107361,718.397949 863.546265,715.883911 
	C864.081177,712.655701 864.474304,710.062378 860.175354,709.847534 
	C858.732971,709.775391 857.845947,708.240356 858.334045,706.855774 
	C860.021729,702.069275 857.473511,699.039795 854.289429,696.164795 
	C853.290466,695.262695 852.762329,694.033936 852.128540,692.481689 
	C851.847961,691.702087 851.722229,691.244385 851.559692,690.427979 
	C850.240234,686.706909 848.393738,686.547119 846.109619,689.128601 
	C845.457764,689.865417 844.913208,690.700867 844.297241,691.470215 
	C842.863586,693.260986 841.101562,694.647278 838.804504,693.205261 
	C836.414612,691.705261 836.039856,688.788879 837.699890,687.187134 
	C841.090149,683.916138 841.544006,679.425842 843.963501,675.449158 
	C845.748962,674.513489 847.315369,674.376770 849.305786,674.465576 
	C850.193542,674.451416 850.685303,674.371094 851.179199,674.296265 
	C857.595337,673.324524 858.695129,674.574707 856.959412,680.979675 
	C858.868469,680.348877 858.479553,678.697144 858.454224,677.452881 
	C858.387756,674.186829 859.053223,671.176208 860.839661,668.428955 
	C861.795898,666.958496 861.875732,665.353516 861.780396,663.639465 
	C861.449951,657.698425 860.399963,651.707947 863.652466,646.079773 
	C864.781372,644.126465 863.578613,642.408203 861.603271,641.777222 
	C859.100098,640.977661 858.954895,643.080200 858.731995,644.698364 
	C858.436401,646.843994 858.657898,649.022705 858.548096,651.183350 
	C858.233887,657.361572 855.357910,659.652527 849.359009,657.887817 
	C845.255920,656.680847 841.198792,656.429749 837.030579,656.744568 
	C835.011047,656.897217 833.104553,657.527161 831.378235,658.583496 
	C829.175476,659.931458 828.531372,661.473633 831.162109,663.044189 
	C832.854126,664.054321 835.171875,664.676025 834.570251,667.278320 
	C833.907410,670.145569 831.441528,671.102600 828.869446,671.324280 
	C824.090454,671.736206 820.321533,671.462097 816.207703,667.033386 
	C811.137695,661.575256 806.239807,653.889221 796.448303,655.252930 
	C794.111328,655.578430 791.933838,653.962952 791.377136,651.287781 
	C790.952881,649.248962 792.227356,648.234253 794.423584,647.676025 
	C795.349182,647.580688 795.883362,647.556213 796.826050,647.530151 
	C799.156006,647.296448 801.062622,647.366028 803.325684,647.935547 
	C804.795593,648.432678 805.650879,649.520752 806.874817,649.412842 
	C811.852173,648.973938 815.407043,650.486450 817.684143,655.402100 
	C818.896179,658.018494 821.796875,657.383789 822.931580,654.602905 
	C823.681274,652.765503 823.382385,650.607300 824.882202,648.619080 
	C827.228455,647.191162 829.431824,647.363281 832.036621,647.696045 
	C838.720398,649.694641 838.788086,649.716553 840.737366,643.699219 
	C841.480103,641.406311 842.929993,640.028625 845.112427,639.375977 
	C848.311096,638.419434 849.600952,636.261230 849.460510,633.038513 
	C849.417419,632.050293 849.512634,631.052124 849.711731,630.075134 
	C850.060974,628.360962 850.735596,626.780151 852.682434,626.566345 
	C854.474182,626.369446 855.422729,627.790466 855.854797,629.214050 
	C857.367249,634.197632 860.653992,633.900452 864.822998,632.177612 
	C866.975098,631.421387 868.817444,631.474365 871.037781,631.669189 
	C873.166809,632.024292 874.904602,632.606934 876.823730,631.808044 
	C873.930664,630.809204 870.966797,630.069763 868.006592,629.274292 
	C864.565369,628.349487 862.979614,626.310669 865.088501,622.469116 
	C869.699707,620.971069 874.080322,620.316589 878.970459,620.721680 
	C880.453308,620.807373 881.452148,622.329773 882.551147,621.183838 
	C883.231018,620.474915 882.426575,619.711731 882.146545,618.954102 
	C880.776062,615.246460 881.798767,613.764587 885.715515,613.645142 
	C887.060486,613.604126 888.393372,613.805054 889.648621,614.257690 
	C892.076477,615.133179 894.183411,614.808167 896.086487,612.984680 
	C897.434814,611.692688 899.171631,611.037659 900.909424,610.427612 
	C903.387878,609.557617 904.857483,607.765686 904.746887,605.184448 
	C904.641907,602.734802 902.719666,601.501770 900.623291,600.650696 
	C899.381470,600.146484 898.072205,599.883240 896.740234,599.749023 
	C894.092224,599.482178 892.087158,599.902283 892.251831,603.319092 
	C892.426392,606.942627 890.779358,609.063843 886.918762,608.873413 
	C884.374878,608.747986 882.604187,610.407776 880.528687,611.408142 
	C876.041565,613.570740 871.018982,612.123352 869.908691,608.447754 
	C868.666260,604.334473 870.621948,601.995728 875.684204,601.471069 
	C877.279053,601.305786 879.181152,601.822083 880.217407,600.127869 
	C880.655273,599.411987 880.074158,598.505981 879.420410,597.930664 
	C877.807434,596.511108 876.031189,596.869446 874.257751,597.557617 
	C872.402466,598.277527 870.618469,599.308594 868.519531,598.999146 
	C866.344360,598.678528 864.741272,597.547546 864.331482,595.312195 
	C863.900513,592.961548 865.575867,592.058899 867.439941,591.514771 
	C870.195923,590.710144 872.766724,589.617249 874.838989,587.541382 
	C875.558289,586.820923 876.553101,586.084778 877.498718,586.406982 
	C884.362000,588.745605 891.840271,584.487061 898.577820,588.486938 
	C900.310059,589.515320 902.442810,589.132507 903.081421,586.913696 
	C903.876892,584.150085 901.316589,584.175903 899.695618,583.749939 
	C893.385010,582.091492 887.150879,582.526794 881.041199,584.767944 
	C879.635437,585.283569 878.213989,585.705566 876.773865,586.114197 
	C874.442078,586.775818 871.959412,587.766785 870.738831,584.885010 
	C869.523804,582.016296 871.285339,579.753784 873.488342,578.019592 
	C874.995544,576.833130 876.992310,576.220642 878.689453,576.923096 
	C884.237427,579.219299 889.476440,577.613892 894.784058,576.176025 
	C896.971558,575.583374 899.327698,575.674866 901.443359,574.579773 
	C897.206238,573.130981 893.008484,573.338562 888.827942,574.522644 
	C884.409241,575.774109 879.919189,575.961121 875.470642,575.041382 
	C872.597656,574.447449 869.295959,573.867920 868.602539,570.220093 
	C868.163818,567.912170 872.815979,563.617981 876.064758,563.081238 
	C879.384338,562.532837 882.840210,562.795349 886.041016,561.261841 
	C877.253967,564.547668 868.390137,562.342529 859.555054,561.933533 
	C857.451904,561.836182 855.650574,562.706787 854.392151,564.478455 
	C853.126648,566.260071 851.620911,567.798828 849.516968,569.272461 
	C848.664185,569.608582 848.149597,569.611694 847.317017,569.234253 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M689.248840,208.757324 
	C687.517273,209.004578 686.032654,208.966217 684.552307,209.028015 
	C683.241150,209.082779 681.582642,209.101105 681.606018,210.851181 
	C681.627502,212.468994 683.365601,212.464188 684.550964,212.933929 
	C686.267578,213.614166 689.790649,211.531448 689.499756,214.963577 
	C689.237854,218.053604 685.784607,217.090759 683.715942,216.915405 
	C678.790283,216.497803 674.291138,217.130753 670.218018,220.232849 
	C669.597290,219.423416 669.099060,219.150757 668.202881,219.749725 
	C667.372986,220.536209 666.366699,220.965530 666.180542,221.626007 
	C664.973206,225.909698 664.106262,229.920532 657.929138,229.714066 
	C656.273376,229.658707 657.322998,233.871643 657.000000,236.553421 
	C654.515381,236.888550 651.982666,237.037872 649.560120,236.590302 
	C647.260742,236.165482 645.742859,234.666077 646.046082,231.956390 
	C646.352112,229.221359 648.271484,228.802170 650.381470,229.040497 
	C654.623230,229.519562 657.090942,228.226242 657.169373,223.513336 
	C657.204346,221.416290 659.031067,220.990326 660.832520,221.010498 
	C663.761902,221.043320 665.227295,220.161957 664.198181,216.803070 
	C663.352966,214.044571 664.806702,212.886932 667.648804,212.954956 
	C669.967896,213.010437 672.480164,212.969788 672.986206,209.388275 
	C673.763062,207.882309 675.585144,207.816742 676.183044,206.002182 
	C674.043701,205.958405 674.342468,207.827988 673.229858,208.755035 
	C670.719116,209.073105 668.895447,208.349152 669.081299,205.735428 
	C669.248901,203.378464 667.954224,201.675461 667.052673,199.373962 
	C667.915466,197.099106 671.642456,199.236481 671.399780,196.350311 
	C671.204956,194.033310 668.572876,193.369873 666.652161,192.506561 
	C664.051880,191.337845 661.357910,191.087769 658.606445,191.948288 
	C658.463501,193.941818 661.018005,194.539658 660.218750,196.727081 
	C655.169922,197.004578 650.337463,196.992004 645.505554,197.033661 
	C643.915466,197.047363 642.276733,197.432159 642.330872,199.386002 
	C642.384705,201.329178 644.162109,200.934235 645.410339,200.975601 
	C647.574158,201.047287 649.742126,200.996933 651.908325,201.000061 
	C654.208984,201.003372 656.581055,200.584824 657.594604,203.671219 
	C658.060974,205.091263 659.778503,204.907318 661.101135,205.054413 
	C662.785889,205.241806 664.043579,206.008972 663.720703,207.933975 
	C663.452881,209.530518 662.052979,210.246857 660.719055,209.899521 
	C655.797668,208.618042 650.792480,209.216232 645.832214,208.906662 
	C645.211304,208.867905 644.294556,208.632828 644.052612,208.192947 
	C639.870117,200.589203 632.761475,204.447433 627.039490,204.324326 
	C623.022400,204.237915 618.808594,204.892670 614.665344,205.026199 
	C613.005920,205.079666 610.757324,205.208862 610.678284,207.092560 
	C610.580017,209.435287 612.985107,208.799210 614.530884,209.004349 
	C615.506958,209.133896 616.476868,209.310028 617.724792,209.732880 
	C618.616211,210.984695 618.188965,211.807968 617.299072,212.802399 
	C609.354187,213.254150 601.447693,211.680511 594.419312,216.254379 
	C592.888672,217.250458 591.609985,216.193665 590.260254,215.662277 
	C587.831299,214.705963 585.417053,213.454239 582.369873,214.950974 
	C580.463806,212.564072 582.422119,210.930984 583.648193,208.777298 
	C585.385803,207.596436 586.945740,207.512207 588.917542,207.974792 
	C589.704285,208.328171 590.101013,208.616730 590.377441,209.024094 
	C591.218262,210.262970 590.761414,212.648422 593.052063,212.572678 
	C595.255859,212.499802 594.781006,210.129059 595.918152,208.656021 
	C597.163879,207.727859 598.376038,207.748962 599.463928,207.171097 
	C593.964294,206.894928 588.364258,204.821289 582.915283,208.621765 
	C581.260376,210.176590 579.970032,211.580414 579.230835,213.769974 
	C577.642334,213.956848 577.163696,212.733337 576.274719,212.045532 
	C574.164490,210.412750 572.361389,210.578827 571.122986,213.276123 
	C569.307983,217.229126 565.444458,218.575928 561.655945,215.983688 
	C555.143799,211.527878 548.237793,208.971298 540.262268,208.866058 
	C536.545898,208.817001 535.032532,206.012939 534.965515,202.307632 
	C534.845520,195.672913 532.334473,193.587204 525.568604,194.015076 
	C523.947449,194.117599 523.337891,194.853226 522.912048,196.362793 
	C521.901672,199.943970 519.302612,201.264587 515.680786,201.022705 
	C513.192078,200.856476 510.565948,201.368561 508.224060,200.743149 
	C505.494751,200.014252 501.928680,199.106537 504.129822,194.682175 
	C504.706635,193.522720 505.569550,192.197281 503.872559,191.224380 
	C502.009125,190.156036 500.085693,190.141266 498.179413,191.206665 
	C496.669067,192.050797 496.578400,193.764465 497.211029,194.687775 
	C500.028198,198.799744 497.372192,200.548218 494.356171,202.412811 
	C491.509888,204.172501 491.887177,206.012558 494.505524,207.717880 
	C495.864349,208.602859 497.928345,209.289490 497.458984,211.253586 
	C496.888123,213.642471 494.531097,212.945587 492.804993,212.975388 
	C486.503448,213.084290 480.185394,212.567307 474.032135,214.731415 
	C471.602600,215.585892 469.447052,214.122360 467.922577,212.234467 
	C466.484467,210.453506 467.848907,208.670792 468.638031,207.165527 
	C469.813599,204.923111 472.036530,204.578766 474.288300,205.286850 
	C476.494659,205.980652 478.830139,206.613251 480.730835,207.852615 
	C484.071198,210.030716 486.321533,207.668610 488.091309,205.940475 
	C490.145264,203.934921 486.348633,203.351273 486.056763,201.332245 
	C486.900208,200.001785 488.126770,200.286133 489.258423,200.159149 
	C490.423340,200.028397 491.774780,199.935608 491.613281,198.279343 
	C491.485046,196.964203 490.432892,196.223053 489.235962,196.053787 
	C485.545868,195.531860 482.722412,196.680588 481.235657,200.746582 
	C478.346283,201.128860 476.994965,199.990189 476.985107,197.207352 
	C476.976074,194.660400 477.746246,193.040497 480.603668,192.969406 
	C481.896820,192.937225 483.826172,192.752441 483.322510,191.018829 
	C482.773499,189.129074 481.123047,190.786270 479.951538,190.820953 
	C477.631989,190.889603 475.310760,190.962311 472.991119,190.940262 
	C471.761322,190.928574 470.706757,190.427811 470.299072,189.136948 
	C469.875824,187.796753 470.597778,186.925674 471.627136,186.223770 
	C472.585693,185.570175 473.569397,184.951614 474.506775,184.269196 
	C476.007629,183.176498 478.594604,182.504395 477.153107,179.900879 
	C476.317993,178.392593 474.927734,176.580704 472.805206,177.301971 
	C470.719421,178.010742 467.875641,178.749680 468.558044,181.834152 
	C469.177216,184.632568 467.950439,185.057678 465.380463,184.997894 
	C464.484406,184.558914 463.903076,184.189926 463.324951,183.339432 
	C461.926941,180.336288 460.509796,179.962997 458.636688,182.955383 
	C457.147491,184.338303 455.710419,185.311081 454.535217,187.023865 
	C453.492188,188.130142 451.470123,187.491562 451.477936,189.137039 
	C451.482666,190.123764 452.977295,189.999008 454.023834,190.665680 
	C455.819611,195.549561 458.531128,194.197952 461.679352,192.128265 
	C463.851044,191.901413 465.618256,192.130447 467.231995,193.158096 
	C469.532349,194.623001 469.228607,196.865662 468.741699,198.932922 
	C468.267761,200.945206 466.493652,201.301117 464.762787,200.736145 
	C461.473450,199.662521 461.155914,201.609146 460.988159,204.053879 
	C460.463226,211.704865 460.436676,211.718613 453.105011,213.016525 
	C451.914948,213.227203 450.612823,213.411377 450.504211,214.798294 
	C450.365723,216.567001 451.879517,217.124573 453.190430,216.911972 
	C457.065155,216.283585 459.713348,218.568573 462.782349,220.728455 
	C461.477844,224.810562 458.070770,225.693390 454.567993,226.377563 
	C452.824615,226.718094 450.982239,226.123016 449.232330,226.822525 
	C449.558136,227.709213 450.465546,227.883743 451.186096,228.766739 
	C451.836548,231.605423 449.984833,232.001495 448.348541,231.708878 
	C445.041779,231.117538 441.142151,230.010635 439.974884,226.984482 
	C438.336853,222.737869 435.169586,222.498138 431.844238,221.925644 
	C433.237396,223.283630 436.296082,223.935715 435.193604,226.763657 
	C434.212097,229.281250 431.537811,229.409286 429.199707,229.407684 
	C422.987061,229.403442 417.107605,227.486893 411.156250,226.027695 
	C409.794525,225.693817 408.744232,224.783508 408.560730,223.310211 
	C408.371124,221.787918 409.351471,220.878571 410.570679,220.171768 
	C411.713104,219.509491 413.145813,219.477463 414.946869,217.759811 
	C407.425385,218.047516 400.639008,218.543365 393.667206,216.933807 
	C391.264862,215.673050 389.368469,212.662918 386.837372,216.521759 
	C386.717987,218.167587 386.666229,219.481979 386.679932,221.087524 
	C386.602234,221.997299 386.848206,221.786377 387.385620,221.379776 
	C392.570374,221.464340 393.555298,222.657623 391.575256,226.647873 
	C390.614777,228.583466 390.727020,230.439636 390.580200,232.723419 
	C388.905151,236.066910 390.545837,237.208755 393.177551,236.976944 
	C396.969116,236.643005 400.756805,236.436371 404.544220,236.564148 
	C407.922089,236.678116 407.321075,234.911407 406.682709,232.549805 
	C407.782867,231.016815 409.178406,230.949326 410.444305,231.208755 
	C415.360382,232.216339 420.626648,230.771561 425.407562,233.707672 
	C426.006744,235.203568 423.207550,236.825592 425.334717,237.187424 
	C427.851349,237.615509 431.471893,239.413086 433.019958,234.684235 
	C436.655304,232.729019 440.109070,233.743698 443.553436,234.167770 
	C445.819519,234.446732 447.746796,234.855789 449.759155,232.515411 
	C452.018433,229.887863 454.871948,231.313934 457.193542,233.918640 
	C457.298157,236.774307 455.107147,237.349808 453.272247,238.744644 
	C450.997162,240.804016 448.561279,239.745712 446.326996,239.984558 
	C444.017944,240.231400 441.520294,240.044250 441.015869,243.608353 
	C440.334686,244.667984 439.541473,244.760040 438.335754,244.251450 
	C438.098022,241.943710 437.197906,241.009888 435.063232,240.981903 
	C429.177856,240.904724 423.428894,240.607712 417.513367,239.347031 
	C410.353455,237.821121 402.779266,240.604813 395.304993,240.077560 
	C393.247406,239.932404 393.010284,241.559540 393.002930,243.581421 
	C392.874359,244.111252 392.735077,244.331451 392.627625,244.316879 
	C384.097443,243.159836 375.126587,243.448486 368.185425,237.004913 
	C366.735931,235.659302 366.769073,235.623596 364.048065,235.408051 
	C365.027130,237.337524 368.631927,236.377808 368.219971,239.730316 
	C360.734985,240.036301 360.317383,240.463028 361.949951,246.616364 
	C362.302551,248.221725 362.342255,249.405426 361.242126,250.726242 
	C359.367859,251.322632 356.977173,250.065369 356.309448,252.426163 
	C355.491028,255.319870 358.282257,254.624786 359.738647,255.273148 
	C361.514832,256.063843 360.824371,257.783478 360.705688,259.900024 
	C356.736481,262.042938 353.620453,261.815735 351.733459,257.304443 
	C351.649567,255.304825 350.849731,253.917252 350.387329,252.060333 
	C351.958008,248.104462 350.687408,246.508118 346.861969,246.572586 
	C341.025604,246.670959 335.251801,246.075516 329.224548,244.728333 
	C327.800293,243.698669 327.099304,242.303543 325.264526,242.468765 
	C319.131287,243.021103 313.175812,241.915649 307.239929,240.458176 
	C305.229401,239.964493 302.996674,239.571518 301.057556,241.243042 
	C300.679657,243.213058 303.657959,243.209686 303.296875,245.462463 
	C298.940582,248.327438 294.608368,245.992188 289.975769,245.873322 
	C287.677917,241.771362 284.502625,240.953644 280.493927,241.968201 
	C277.305145,242.775253 273.994659,242.462753 270.727753,242.658142 
	C276.274597,244.208328 281.988861,242.761520 287.846375,244.079407 
	C289.230896,245.025589 289.752350,246.016068 289.169128,247.679535 
	C283.000946,248.000000 277.001923,248.000122 271.002869,247.999893 
	C270.169678,247.999863 269.197296,248.282639 268.524597,247.953598 
	C260.990631,244.268585 253.206970,247.280609 245.555603,246.994995 
	C243.746399,246.927475 241.421021,247.211700 241.345825,244.668488 
	C241.279663,242.431442 243.486755,243.110474 244.829819,242.988022 
	C247.498856,242.744766 248.009979,241.036285 248.000702,238.807785 
	C247.979919,233.808670 247.992935,228.809402 248.001205,223.810211 
	C248.012421,217.023300 248.016037,217.021698 254.565491,217.000961 
	C256.565155,216.994629 258.565918,216.957504 260.564301,217.008713 
	C264.212372,217.102188 267.061523,216.703873 267.186401,211.815063 
	C267.281158,208.106430 270.725769,209.241760 273.051514,208.996658 
	C274.946991,208.796890 277.573456,209.830826 278.389374,207.490646 
	C279.175232,205.236801 276.656921,204.088257 275.218750,202.912735 
	C273.225342,201.283325 270.915649,200.300110 273.178162,197.048630 
	C274.586029,195.025391 272.134827,194.772888 270.970276,194.130554 
	C270.254730,193.735886 269.302856,193.628906 269.285431,192.575348 
	C269.263458,191.246246 270.377228,191.032211 271.312988,190.787964 
	C277.549561,189.160080 283.897522,190.098206 290.186707,190.195114 
	C292.461121,190.230148 292.126892,192.076340 290.866791,193.394348 
	C287.808411,196.593231 290.416016,196.811523 292.767792,196.964005 
	C297.186737,197.250534 299.831177,194.746307 300.013580,190.366898 
	C300.086548,188.615921 299.437866,186.540451 301.566406,185.499146 
	C302.396667,185.092987 303.139526,185.723663 303.747986,186.328644 
	C305.290680,187.862488 305.192047,189.570587 304.293945,191.370636 
	C304.070953,191.817719 303.611023,192.348267 303.708893,192.723282 
	C305.030609,197.788300 305.677307,202.259308 298.654053,203.814423 
	C297.452301,204.080505 297.446594,207.428482 299.231781,209.041885 
	C302.391174,211.897232 300.462158,212.544800 297.627533,213.349228 
	C295.307617,214.007568 292.996643,214.198227 290.714233,213.948410 
	C284.399963,213.257309 278.704529,215.995483 272.689178,216.912109 
	C271.755219,217.054413 270.466156,216.973480 270.546265,218.385864 
	C270.595825,219.259033 271.483490,219.660385 272.308838,219.942505 
	C274.688232,220.755768 277.589630,222.142120 279.389313,220.076202 
	C282.702423,216.273010 286.787323,217.203720 290.829071,216.960144 
	C294.206116,216.756622 297.794525,218.042740 300.905243,215.438354 
	C301.931702,214.578964 302.993835,215.965408 304.052856,216.303879 
	C307.676056,217.461838 311.053131,217.678070 314.098846,214.488388 
	C318.494812,209.884644 318.776428,209.011673 314.641937,204.206024 
	C312.595764,201.827713 312.637695,199.950668 314.748016,197.703461 
	C317.374268,194.906845 319.695282,191.892075 320.274689,187.865707 
	C320.400269,186.993210 321.497742,185.455719 321.814697,185.536148 
	C326.708038,186.777924 330.524323,181.682419 335.691254,183.475342 
	C337.936951,184.254562 340.879272,183.223083 343.461578,182.767029 
	C345.226471,182.455338 344.949127,180.809708 344.948883,179.508377 
	C344.948669,178.213013 344.454071,177.177994 342.994019,177.101395 
	C341.500885,177.023087 339.706512,176.549927 338.549835,177.179260 
	C330.291626,181.672562 322.730164,176.168900 314.859070,175.611267 
	C311.539154,175.376099 310.891571,172.322220 313.510956,170.215439 
	C317.025665,167.388535 314.598114,166.784988 312.388824,165.709015 
	C310.160767,164.623871 307.805603,165.114243 305.499725,165.004745 
	C303.792755,164.923676 301.874695,165.039444 302.174805,162.514694 
	C302.387543,160.724915 303.436035,159.319702 305.436646,159.411270 
	C317.871857,159.980499 330.191254,157.811234 342.615845,157.900131 
	C346.551392,157.928299 350.470428,156.783691 354.497711,156.947922 
	C357.365082,157.064819 360.033112,157.206558 362.553406,158.950043 
	C365.728638,161.146591 368.334351,159.772461 368.799744,156.326675 
	C369.342438,152.308563 371.759186,151.885559 374.754150,152.048889 
	C377.390564,152.192657 380.001526,152.753281 382.635040,153.016739 
	C384.295197,153.182816 385.069244,153.962280 384.859314,155.607773 
	C384.755005,156.425537 384.313995,157.236893 384.360352,158.031586 
	C384.766602,164.995743 384.789032,164.992889 377.798889,165.001038 
	C375.965973,165.003174 374.131958,164.982254 372.300476,165.037827 
	C370.654572,165.087753 369.234100,165.658249 369.236053,167.565369 
	C369.237732,169.182159 370.472961,169.778687 371.928558,169.957718 
	C374.720978,170.301163 377.505737,170.727036 380.306213,170.980103 
	C385.742584,171.471344 386.976227,172.676910 385.737762,177.891861 
	C384.970306,181.123520 385.342529,183.722580 388.707306,183.731796 
	C391.562744,183.739639 396.587616,188.129837 397.212982,180.574661 
	C397.513214,176.947662 401.726868,175.408829 405.344666,176.893585 
	C409.725891,178.691635 413.145447,176.825562 412.988525,172.735077 
	C412.885925,170.059341 411.941681,168.661560 408.914001,169.490982 
	C406.080170,170.267334 403.231720,170.529938 400.484436,172.071671 
	C395.513062,174.861465 392.120239,172.527344 392.000427,166.811615 
	C391.773743,155.996399 391.822205,156.601089 402.304565,155.761032 
	C408.094849,155.296997 411.029633,159.609283 415.514038,161.143311 
	C417.995087,161.992050 420.261292,163.114655 422.937683,162.998474 
	C424.937256,162.911697 426.706604,163.488739 427.469757,165.401352 
	C428.180511,167.182617 426.332458,167.946228 425.439331,169.050308 
	C424.340546,170.408585 422.201233,171.515976 423.565979,173.637436 
	C424.992798,175.855438 426.990204,174.781128 428.914490,174.076355 
	C432.061523,172.923782 436.036835,174.922424 436.936096,178.134583 
	C437.302032,179.441803 437.190704,180.917313 439.097137,180.839142 
	C441.013824,180.760544 440.886566,179.237579 441.038361,177.928604 
	C441.354065,175.206955 443.492676,174.601028 445.627777,174.080307 
	C448.112976,173.474228 449.363281,175.206329 450.281433,176.986084 
	C451.478760,179.307068 449.234253,179.651138 447.936646,180.516953 
	C446.929718,181.188812 444.340607,180.844498 445.951782,183.319565 
	C447.141724,185.147629 448.781403,184.945450 450.257629,183.812073 
	C451.833527,182.602112 453.279449,181.206360 454.685608,179.795547 
	C456.833710,177.640381 459.458771,176.300369 462.353699,177.157104 
	C466.981903,178.526764 468.818115,177.059296 469.101715,172.348999 
	C469.302338,169.017014 470.972107,168.350784 474.938385,169.671921 
	C474.626099,173.606491 476.422546,174.483261 479.758362,174.320908 
	C484.223633,174.103561 488.580353,175.076828 493.007324,177.013062 
	C493.750519,177.901443 493.821991,178.612671 493.523285,179.701538 
	C490.937561,182.243256 487.878571,179.834946 485.312439,181.098312 
	C487.010223,187.055969 487.972351,187.773026 493.776733,187.334747 
	C500.172211,186.851807 506.429199,185.728302 512.720276,183.313416 
	C515.061584,185.183243 517.582825,185.236130 520.041748,185.662552 
	C526.919861,186.855316 533.989197,186.800369 540.727905,188.956497 
	C542.379456,189.484924 544.235168,189.507751 545.652771,189.063950 
	C541.762817,188.773300 537.571960,188.540405 535.421875,183.539856 
	C535.395874,181.479828 536.485291,180.294128 537.635864,178.736847 
	C540.465515,177.062714 543.037720,177.552750 545.625793,178.327667 
	C549.588318,179.514130 553.603638,180.321213 558.815735,180.017807 
	C554.247559,177.037918 550.068481,176.534363 545.744690,176.594711 
	C539.734985,176.678619 533.892090,176.036270 528.437561,172.446625 
	C527.786499,170.333649 528.331543,168.824448 529.586365,167.093521 
	C531.839050,165.111588 534.158875,165.441696 536.455322,165.897690 
	C545.600037,167.713562 554.869751,166.441956 564.066223,167.191162 
	C557.829407,166.453369 551.487793,166.691086 545.159912,164.411926 
	C542.527893,160.907578 537.076599,160.000626 533.556091,162.631958 
	C531.869202,164.462433 529.545715,165.209076 528.488647,167.734863 
	C525.053040,168.958191 522.026062,168.579651 518.738281,167.089478 
	C516.358154,164.892365 518.033142,162.769806 518.333008,160.368469 
	C518.684021,159.240829 520.327515,158.346848 518.806458,157.690857 
	C517.530212,157.140457 515.593994,156.047195 514.663208,158.879852 
	C512.685242,161.315536 510.844147,163.429993 514.063843,166.373535 
	C514.444641,168.059204 514.335876,169.349396 513.518494,170.918961 
	C512.359863,171.956085 511.333191,172.101334 509.888519,171.652130 
	C507.500519,169.506851 506.541229,166.558777 503.678589,164.856842 
	C503.189819,163.792252 503.235535,162.954056 503.021271,162.173645 
	C502.501923,160.281815 500.893158,160.194366 499.482391,160.472519 
	C497.395172,160.884064 498.804321,162.570145 498.565887,164.025787 
	C498.255096,165.891129 495.580322,166.199570 496.251251,167.818115 
	C496.941040,169.482193 499.035889,169.223862 500.648499,169.438385 
	C503.509857,169.818970 506.670532,168.715820 509.367859,171.445953 
	C510.579681,173.390411 510.415588,175.023514 509.303802,177.001831 
	C504.385223,179.664215 499.567810,178.859634 494.372559,178.026581 
	C491.336395,176.163818 491.425659,172.747498 489.217896,170.323303 
	C489.000610,168.612671 489.000000,167.224106 488.999084,165.419006 
	C490.419037,163.437668 491.232391,161.755157 490.014954,159.339661 
	C491.331390,153.535767 491.296387,154.130890 485.814392,153.808533 
	C480.250671,153.481400 474.697296,153.340515 469.134369,153.163971 
	C466.620178,153.084152 464.157562,152.756134 461.842438,154.066223 
	C460.325012,154.924911 458.740845,155.261093 457.065277,154.517715 
	C455.483185,153.815857 453.032898,153.878494 453.247772,151.354828 
	C453.461609,148.843674 455.677124,149.165985 457.491394,148.969223 
	C465.178040,148.135605 472.960846,150.033752 480.661591,147.520630 
	C484.341675,146.319611 487.311096,151.645065 490.227692,151.229599 
	C493.322449,150.788742 495.712616,146.934769 500.000122,148.818207 
	C501.633362,149.535675 503.366241,149.841202 504.728851,151.188507 
	C507.498108,153.926712 508.576660,152.620880 509.016479,149.384506 
	C509.879913,143.031281 509.939240,142.985229 503.536957,143.013794 
	C495.640320,143.049026 488.846680,139.411865 482.074005,136.167755 
	C479.279236,134.829071 476.243561,135.823746 473.154877,134.154053 
	C474.944427,132.555267 476.650818,133.028076 478.155762,133.021393 
	C480.177155,133.012405 481.225098,131.957062 481.747162,130.199707 
	C482.282867,128.396515 481.505920,125.688004 484.149780,125.268677 
	C487.230225,124.780075 490.618530,124.152046 493.445435,125.847839 
	C497.463562,128.258194 501.683746,129.115372 506.265930,129.037262 
	C509.721558,128.978363 512.092957,131.522125 514.438354,133.424438 
	C517.376587,135.807602 520.112976,136.375076 523.888855,136.207657 
	C529.437012,135.961655 535.046692,137.680481 540.618896,135.894379 
	C542.237671,135.375504 543.947388,134.945572 543.872375,132.762009 
	C543.809631,130.933899 542.630859,130.154007 540.922424,130.035110 
	C539.408875,129.929779 537.835144,129.906372 537.290649,128.070938 
	C537.019592,127.157333 537.458374,126.475609 538.236023,125.986130 
	C539.890564,124.944702 542.155518,124.202118 543.384888,125.499748 
	C549.433350,131.883881 557.658630,129.151077 564.782288,130.818512 
	C567.795715,131.523895 569.851685,131.980026 569.988586,135.355881 
	C570.080627,137.625839 571.325378,138.833496 573.487793,138.542740 
	C575.426697,138.282028 575.917664,136.734894 576.052734,134.843018 
	C576.225586,132.424652 574.728821,128.428696 578.539368,128.332214 
	C583.350403,128.210388 588.436340,126.153526 593.132141,130.418793 
	C596.650391,133.614380 600.907043,136.712357 606.078064,136.938599 
	C612.062378,137.200439 618.066956,136.999832 624.531372,137.000305 
	C626.485413,139.767975 628.849976,140.589554 631.885010,140.476959 
	C634.445312,140.381989 637.115112,140.030518 639.759644,141.718201 
	C639.588745,144.344513 637.742432,144.029739 636.153687,143.979660 
	C631.919678,143.846176 627.701050,144.250488 623.448120,143.228531 
	C621.052246,142.652817 617.554749,141.796692 617.011475,146.599472 
	C616.369568,148.356339 614.413269,148.470062 614.015076,149.986237 
	C614.756287,151.933563 617.775757,151.929214 617.366699,154.809830 
	C614.874146,156.829910 612.272705,154.178757 609.604736,155.720245 
	C607.368042,157.191788 605.112122,158.537720 606.523132,161.306778 
	C607.748779,163.712341 610.238892,163.672455 612.602844,163.353851 
	C613.917175,163.176727 615.090820,162.545822 616.652954,161.992035 
	C620.024231,161.660019 622.794434,160.917923 624.722046,157.797028 
	C629.186035,158.506638 630.683289,161.720200 628.028992,165.490219 
	C624.933533,169.886810 620.422119,173.574997 615.221558,174.076828 
	C610.199829,174.561432 607.651184,175.978516 607.122803,180.920883 
	C607.040588,181.689880 606.248352,182.319519 606.211365,184.136566 
	C614.978638,178.588974 620.011292,186.088318 626.395203,189.721954 
	C624.057556,193.718842 621.292297,191.518906 618.719910,190.456451 
	C616.178467,189.406815 613.767639,187.864670 610.009521,190.372192 
	C619.291443,192.648346 627.735291,191.316574 636.087158,191.520691 
	C636.749390,191.596481 636.291016,191.366699 636.207092,191.377914 
	C635.056213,191.532059 633.907654,191.359116 632.498169,190.755005 
	C630.707153,185.190582 631.577576,183.621231 636.655334,182.508438 
	C638.135559,182.184021 639.288330,181.079483 641.141968,180.697723 
	C643.039673,180.385635 644.544128,180.471481 646.340637,181.154572 
	C646.875366,184.457367 648.698120,185.132980 651.393921,184.421021 
	C650.680847,184.084839 649.842896,183.946198 648.965454,183.158020 
	C648.163818,180.996933 648.824036,179.015884 648.475830,176.769501 
	C649.501953,174.595367 651.590881,174.922821 653.293762,174.152145 
	C651.836365,172.852219 649.589966,173.494202 648.603638,171.348999 
	C649.735657,167.382660 653.902588,169.513123 656.323853,166.909760 
	C652.893494,165.792023 649.061340,167.085846 646.384521,163.931946 
	C645.656433,162.407455 645.641296,161.168671 646.165283,159.575226 
	C647.635132,157.457062 649.698853,157.766586 651.626648,157.391418 
	C648.120972,156.560211 644.714050,156.198349 640.918457,157.169861 
	C638.593506,157.577164 636.620911,157.653961 634.311951,157.169678 
	C633.019897,156.711014 632.128723,155.960205 630.675537,156.688080 
	C624.574463,156.183945 623.635437,154.848618 624.818237,148.341537 
	C627.231750,146.204102 629.924011,146.634125 632.156677,147.333908 
	C637.774902,149.094818 642.303833,147.834610 646.398621,143.782211 
	C648.686646,141.517960 648.703491,133.496918 646.165283,129.755432 
	C645.455872,128.709717 643.846619,127.958420 644.562622,126.407593 
	C645.328308,124.749138 647.053528,125.048241 648.534912,125.001938 
	C653.784729,124.837845 656.864319,127.152931 657.941589,132.547058 
	C658.427917,134.982254 659.383972,136.767776 661.897888,136.904205 
	C665.005066,137.072815 666.080200,138.938019 665.919495,141.494370 
	C665.650024,145.782150 667.775635,147.057327 671.624756,147.031158 
	C675.925110,147.001907 680.818359,145.652390 683.056763,151.278458 
	C687.859375,150.666061 691.297180,156.232391 697.376587,152.953415 
	C702.860596,149.995544 710.103027,151.913498 716.546936,152.307587 
	C718.480347,152.425842 718.161194,154.555389 717.316284,155.933594 
	C715.886230,158.266251 713.745300,159.388123 710.975525,158.988785 
	C708.028870,158.563904 705.491150,159.592117 702.989380,160.960526 
	C702.263611,161.357513 701.282837,161.456512 701.613098,162.571701 
	C701.874756,163.454910 702.704895,163.965012 703.567932,163.782440 
	C711.484680,162.107880 719.513062,163.579483 727.453979,162.884506 
	C735.431152,162.186371 743.270386,163.808426 751.186829,164.088104 
	C752.400269,164.130966 753.614380,164.620697 753.501038,166.126419 
	C753.391235,167.584198 752.122742,167.969315 750.932617,167.941208 
	C745.248840,167.807007 739.844482,169.091599 733.792419,167.570236 
	C725.235168,165.419159 716.010010,166.670746 707.268433,169.144836 
	C706.011047,169.500717 705.160034,170.246109 705.215332,171.591858 
	C705.270569,172.937103 706.175964,173.741608 707.461243,173.857986 
	C709.608032,174.052353 711.841614,174.426331 713.872803,173.481689 
	C722.008118,169.698135 730.037842,172.182770 738.101562,173.725372 
	C740.571411,174.197876 743.104797,174.317780 745.529907,173.621292 
	C749.479370,172.487045 753.480225,173.192307 757.453735,173.041122 
	C758.795654,172.990051 760.373901,173.171555 760.476624,174.899796 
	C760.583374,176.695557 759.017456,176.853729 757.662720,176.972229 
	C750.243896,177.621201 742.798096,178.899307 735.416016,178.603607 
	C732.133301,178.472107 729.034912,177.694962 725.812805,178.962357 
	C724.795776,179.362396 723.243286,178.945190 723.335693,180.662476 
	C723.411499,182.072327 724.769226,182.613266 725.847900,182.711472 
	C732.278198,183.296890 738.492554,185.154236 745.237366,183.653564 
	C750.901733,182.393295 756.886658,180.743774 762.905518,182.930344 
	C765.422180,183.844620 767.692871,185.707138 770.898376,184.494919 
	C772.416992,183.920609 773.895935,185.930359 774.448914,187.448456 
	C775.178162,189.450714 773.165955,189.834869 771.919495,190.485764 
	C770.479980,191.237488 768.640381,191.213730 767.565063,192.695465 
	C767.711548,193.025391 767.894775,193.570404 767.955811,193.557022 
	C775.057983,192.003220 781.740845,196.190033 788.833557,195.056030 
	C790.983337,194.712341 792.971985,195.424805 792.868164,198.186584 
	C792.775940,200.641525 790.946411,201.038345 788.961426,200.985596 
	C786.058899,200.908463 783.208252,201.416229 783.186768,204.911743 
	C783.163025,208.773010 786.257446,209.054581 789.289368,209.009445 
	C793.917114,208.940536 798.744629,208.122498 800.498718,214.494461 
	C801.110840,216.718246 803.961060,215.785980 805.882507,216.014542 
	C810.277893,216.537369 815.416931,214.034149 818.398987,220.194275 
	C819.220215,221.890793 823.523438,222.099808 824.861328,218.678375 
	C825.804688,216.265900 827.454468,215.925369 829.580872,215.999786 
	C831.245056,216.058060 833.117371,215.850800 833.701965,217.940750 
	C834.260376,219.937363 832.717529,221.407578 831.363281,221.928650 
	C826.908386,223.642731 824.261353,227.815796 819.882202,229.696091 
	C815.806274,231.446259 816.006714,234.037735 819.904480,236.458954 
	C821.027588,237.156616 822.180237,237.848785 823.164856,238.718399 
	C825.104248,240.431396 825.488770,242.027161 822.472961,243.006561 
	C819.776489,243.882233 818.649780,245.516312 819.960632,248.651550 
	C818.943420,251.194626 816.520752,251.160110 814.757690,252.358337 
	C819.474365,250.699936 824.192322,252.106750 829.353271,252.450165 
	C828.277039,250.859467 826.326965,251.147858 825.911499,249.332581 
	C830.813599,247.003098 835.824890,247.718491 840.644226,248.510437 
	C845.324036,249.279449 849.217346,250.035812 849.734070,243.533295 
	C849.996887,240.225937 852.496460,239.749130 855.233154,240.007690 
	C858.174988,240.285614 861.905212,238.889313 863.006226,243.331833 
	C863.782776,246.465698 865.275513,248.625748 868.867615,247.910431 
	C872.564392,247.174286 871.852173,244.065109 871.985229,241.501938 
	C872.071472,239.839996 872.015198,238.170731 872.026001,236.504761 
	C872.035278,235.074768 872.417664,233.552353 873.902832,233.338867 
	C877.990845,232.751266 882.117493,232.256149 886.239624,232.152710 
	C888.473328,232.096649 889.901428,233.829330 889.936218,236.111496 
	C889.990295,239.650986 892.155090,240.113632 894.958984,240.015305 
	C897.122009,239.939453 899.293091,239.942993 901.455383,240.034607 
	C904.810242,240.176712 906.099670,242.176056 905.987488,245.335434 
	C905.880005,248.363724 904.291382,250.056488 901.291504,249.941879 
	C898.491028,249.834869 895.712341,249.240067 892.911316,249.035431 
	C891.108704,248.903732 888.910522,248.703094 888.306274,250.982788 
	C887.765076,253.024597 887.291504,255.426788 888.992676,257.158325 
	C890.561340,258.754974 892.894592,259.881775 894.870789,258.410217 
	C897.898376,256.155670 901.813965,256.376160 904.820496,254.173126 
	C907.190613,252.436523 909.766846,252.785065 912.354431,254.170486 
	C915.133911,255.658691 914.358215,257.813782 914.095337,260.170685 
	C913.783203,262.969116 913.831177,265.840485 914.041382,268.656494 
	C914.340271,272.659973 912.355774,274.190094 908.706421,273.971130 
	C904.012756,273.689575 898.425659,275.881104 895.761902,269.574707 
	C890.125977,269.469757 885.538513,264.733490 879.565002,265.956207 
	C878.125793,266.250824 876.574951,265.999939 875.075439,266.000000 
	C866.517517,266.000214 866.566406,266.012878 864.507996,273.985840 
	C864.009399,275.916962 864.406067,276.890198 866.449158,276.965576 
	C867.614319,277.008575 868.786743,276.944305 869.945679,277.045471 
	C872.162476,277.238953 874.308044,278.667908 874.263916,280.509491 
	C874.199768,283.191925 871.443298,281.653900 869.887085,281.949890 
	C868.600708,282.194641 867.230774,282.000275 865.450195,282.000824 
	C864.213928,277.786224 860.568115,278.330414 858.531006,279.386566 
	C855.668274,280.870728 852.571167,280.675842 849.395752,281.945801 
	C847.919373,282.255127 846.923767,282.078857 845.795044,281.096832 
	C845.123230,278.913116 846.103882,277.876862 848.022949,277.116943 
	C850.168518,276.906311 851.958557,277.272736 854.370178,277.100098 
	C852.343201,276.282501 850.900269,277.193024 849.143433,276.936859 
	C843.468140,274.600525 838.039490,274.866638 832.278381,276.057281 
	C833.914795,278.654999 836.663940,278.092712 838.850220,279.472168 
	C840.175049,282.070251 838.824280,284.217316 838.227295,286.730408 
	C835.280823,286.712036 833.208435,285.368408 831.339722,283.064484 
	C828.483215,280.311584 825.466858,278.367981 821.868408,277.061462 
	C823.503601,278.177765 825.345215,279.086151 825.144165,281.685486 
	C823.852844,282.120544 822.583496,281.965210 821.577942,282.408295 
	C814.083984,285.710571 810.477600,284.306122 806.858154,276.514160 
	C805.743286,274.114105 800.274719,272.471436 797.202942,273.964111 
	C792.106079,276.440796 786.540100,276.057068 781.189758,276.934326 
	C779.006958,277.292236 776.848267,276.564301 775.531555,274.548645 
	C775.015320,273.758453 775.661316,273.005096 776.209412,272.349731 
	C777.939026,270.281525 779.888794,271.375000 781.676758,272.082855 
	C785.946106,273.773102 789.401001,272.227325 792.829346,269.309052 
	C800.594604,267.595459 807.900208,265.298859 815.149658,262.607910 
	C818.113281,261.507843 821.714294,262.433472 824.998474,262.989044 
	C826.472473,263.238403 827.963501,263.380035 829.440613,262.999115 
	C831.143066,262.560089 831.923584,261.289490 831.859497,259.684540 
	C831.785400,257.826569 830.102844,257.776367 828.874817,257.863831 
	C823.257202,258.264099 817.454834,258.652679 812.277100,260.584412 
	C806.003174,262.925079 799.928162,261.891235 793.413330,261.525543 
	C791.949829,259.424896 791.359192,255.351776 788.217529,260.363434 
	C782.110718,264.141327 775.747742,264.093414 769.245728,263.409790 
	C766.358459,263.106232 764.216797,261.974060 762.943787,259.304962 
	C762.604553,258.593628 762.210022,257.603302 761.193726,257.936401 
	C759.957031,258.341736 759.966675,259.556671 760.437561,260.421021 
	C762.465576,264.143250 762.197205,267.662201 759.517273,271.184845 
	C761.705811,270.641235 760.740967,267.252563 763.707397,267.807861 
	C764.087524,270.700226 764.626892,273.018555 768.056885,273.070251 
	C768.961853,273.083893 769.704285,273.546356 769.717285,274.533508 
	C769.729675,275.470032 768.997498,275.976929 768.213257,276.320190 
	C765.079346,277.691803 762.003784,277.021271 759.033020,275.846619 
	C750.632874,272.525055 742.118774,273.935486 733.603516,275.091675 
	C732.349548,275.261963 731.072083,275.923492 731.501953,277.282074 
	C732.235352,279.599548 734.462646,278.840607 736.597656,279.000824 
	C737.727295,279.707001 738.057617,280.579620 738.074341,281.927673 
	C737.858337,283.590759 737.642639,284.905182 737.605957,286.566986 
	C737.311768,287.947479 737.232117,289.003357 737.719116,290.360352 
	C738.548645,292.051270 739.375549,293.487030 741.139099,293.284698 
	C748.193359,292.475525 755.297852,292.503479 762.395386,291.817688 
	C754.599426,291.911163 746.927307,289.858673 738.784058,290.768921 
	C738.254883,290.516296 738.063538,290.377380 737.730103,289.915161 
	C737.738770,286.155609 740.244568,285.833771 742.812622,285.735809 
	C745.679138,285.626434 744.868530,284.503632 743.421875,282.859222 
	C742.897827,281.520294 742.948181,280.540710 743.758911,279.305725 
	C748.693298,279.970642 753.267395,281.206482 757.251343,284.079498 
	C758.788025,285.187653 760.049377,284.936401 761.498352,283.327179 
	C763.995056,280.554474 772.172302,281.719574 774.175964,284.927338 
	C775.512878,287.067810 773.527893,287.827240 772.313843,288.805237 
	C771.557068,289.414856 770.380310,289.756683 770.535278,290.937927 
	C770.720703,292.351746 772.123291,292.452332 773.168335,292.863129 
	C779.471130,295.340424 786.070007,295.163666 792.661743,294.980438 
	C793.640808,294.953217 794.702087,294.905273 795.574890,294.523193 
	C801.113159,292.098419 802.151672,292.479004 803.887268,298.608307 
	C804.712769,301.523651 805.290527,304.294525 808.282959,305.962280 
	C810.076050,306.961639 810.115601,308.573517 808.403625,310.004150 
	C805.375916,312.534393 797.654968,310.127258 797.075500,306.226501 
	C796.323669,301.165222 792.330566,302.456482 789.415161,302.133911 
	C786.975586,301.863983 784.833313,302.688629 785.736755,306.232727 
	C786.253906,308.261627 784.229004,309.096008 782.669800,309.526276 
	C781.104858,309.958130 779.397949,309.903442 777.749634,310.000031 
	C773.711670,310.236816 771.397217,311.752930 772.994629,316.628265 
	C769.938171,320.444672 765.680847,319.683014 761.390259,320.090332 
	C759.925903,319.411407 758.940063,318.657532 759.281067,317.293488 
	C759.656860,315.790070 761.045959,316.071564 762.171326,316.010254 
	C763.333130,315.946930 764.555786,316.138000 765.648254,315.839203 
	C768.176575,315.147705 770.364380,314.147339 767.441162,311.221436 
	C764.785583,308.563293 766.489929,307.344391 769.131592,307.101257 
	C772.307739,306.808929 776.796082,306.881592 776.417480,303.194580 
	C776.050232,299.619110 771.445984,299.026428 767.845154,299.004578 
	C757.734558,298.943268 747.571899,296.863831 737.650757,297.568909 
	C730.172668,298.100403 723.783630,295.020111 716.753540,294.868622 
	C715.248047,294.836182 712.906555,294.697021 712.642700,292.859528 
	C712.372559,290.978210 714.731323,290.218079 716.070068,289.774384 
	C719.899170,288.505463 723.861328,287.911987 727.048340,291.524628 
	C727.924927,292.518219 728.872986,293.497925 729.949646,294.254303 
	C731.528320,295.363342 732.842224,294.204590 733.946106,293.284943 
	C735.436890,292.043060 734.401062,290.903778 733.419128,289.949707 
	C732.244507,288.808472 731.289124,287.182007 729.461548,287.117889 
	C725.945740,286.994568 724.177307,285.167511 722.176147,282.250824 
	C719.474670,278.313446 714.090332,280.453430 710.080139,279.053772 
	C709.185242,278.741394 708.095825,279.019257 707.101013,278.952118 
	C705.669861,278.855560 704.317261,278.371948 704.312561,276.720917 
	C704.306885,274.719513 706.042358,275.214569 707.275391,274.991608 
	C710.854858,274.344391 714.412598,273.569427 718.001831,272.986176 
	C719.998779,272.661682 720.824341,271.598053 721.002197,269.663513 
	C721.152832,268.024323 721.569153,266.410492 721.846924,264.781860 
	C722.862000,258.829834 721.885803,257.848694 715.993652,255.697906 
	C712.424133,254.394943 709.208008,252.457764 705.150757,252.942078 
	C702.867798,253.214600 701.582031,253.846725 702.276672,256.299194 
	C703.381042,260.198395 700.808167,260.099213 698.363098,259.854187 
	C694.857422,259.502869 687.062073,252.562439 686.281006,249.225372 
	C685.807312,247.201279 687.082520,245.759750 688.690002,245.437836 
	C691.091003,244.956970 693.663391,244.891159 696.103577,245.169220 
	C698.586060,245.452103 697.963684,247.425278 697.476990,249.057755 
	C697.114197,250.274826 696.948914,251.420547 698.543945,251.808319 
	C699.386536,252.013153 700.473450,251.844208 700.628113,250.949005 
	C701.491089,245.953400 706.484985,245.099670 709.653564,242.044586 
	C711.333069,242.409454 712.191895,243.491898 713.505798,244.518829 
	C716.473755,247.344604 719.293701,247.427307 722.492188,244.888489 
	C724.546631,243.834106 725.834839,244.924026 727.144409,245.822739 
	C729.988647,247.774658 731.152222,246.383759 731.826294,243.303162 
	C734.311829,240.919128 733.513367,238.800568 732.037720,236.337692 
	C732.782349,234.850891 734.866455,235.027374 735.040222,232.820236 
	C731.972900,231.632690 726.912476,237.697968 725.771118,230.260864 
	C729.108215,230.156723 731.624023,229.476837 731.992188,225.379333 
	C728.556580,220.664246 729.114075,215.401321 728.617676,210.028778 
	C730.062622,207.123169 732.226318,207.520477 734.181335,208.655579 
	C736.566528,210.040451 737.342224,212.407654 736.956665,215.109528 
	C736.699951,216.908829 735.597839,218.540024 735.822205,220.435516 
	C736.018555,222.093857 736.963684,222.836517 738.585999,222.281967 
	C740.036133,221.786270 740.238953,220.730652 739.352234,219.549774 
	C737.498413,217.081039 737.589478,214.645935 739.346680,212.196167 
	C740.679016,210.338654 740.064087,209.193817 738.080383,208.393921 
	C735.587280,207.388626 733.084717,206.376419 730.357788,207.183853 
	C724.089539,209.039856 717.884827,208.337509 711.644226,207.016312 
	C709.608521,206.585342 707.421448,205.899887 705.157043,207.781799 
	C707.109436,209.920456 709.298035,207.794998 711.454346,208.447418 
	C712.445068,212.321228 712.449158,212.317291 716.363403,216.370117 
	C717.195862,217.232010 717.804932,218.196381 717.217041,219.741852 
	C714.142639,218.731964 711.291504,217.450058 708.425232,216.203079 
	C706.950745,215.561646 705.389465,214.259796 703.882507,215.902237 
	C702.211975,217.722855 704.891235,218.749893 704.958984,220.651474 
	C703.802002,222.628159 700.626831,221.598953 700.170288,224.730347 
	C699.650818,226.990891 698.322693,227.938873 696.498779,227.458252 
	C695.025879,227.070129 695.014709,225.468903 695.009277,224.128403 
	C695.000549,221.991913 694.810913,219.834335 695.042908,217.723618 
	C695.434937,214.158417 694.579651,211.352585 691.075684,209.358643 
	C692.599243,207.055679 694.969177,207.929260 696.933350,207.276199 
	C694.456299,207.699417 691.773193,206.778076 689.248840,208.757324 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M725.875732,863.317749 
	C726.720886,862.076355 727.773621,861.771423 728.640259,862.409790 
	C731.917969,864.824280 735.582703,865.295349 739.497986,865.049255 
	C741.696289,864.911194 742.887512,866.091003 742.991577,868.344360 
	C743.161194,872.014404 745.420227,873.122253 748.719910,873.010986 
	C750.717224,872.943665 752.722168,873.074463 754.717651,872.983704 
	C758.383057,872.817017 761.478333,872.760559 762.340393,877.782715 
	C762.845154,880.723572 766.167603,881.054871 768.936035,880.500366 
	C773.576416,879.570984 777.809509,879.101868 778.182251,885.727417 
	C778.255737,887.033020 779.302002,887.756042 780.566284,887.918823 
	C784.165100,888.382080 785.254028,890.627625 784.954651,893.920166 
	C784.640625,897.372375 782.038208,896.941162 779.729797,897.007812 
	C776.721069,897.094849 775.045898,898.619751 775.038940,901.644836 
	C775.034668,903.497986 774.558716,904.922180 772.734009,905.627502 
	C770.780212,906.382812 770.123352,907.898438 769.792297,909.867554 
	C769.557617,911.263062 769.054443,913.052673 767.012573,912.687073 
	C765.134888,912.350708 765.147339,910.679871 764.988098,909.146729 
	C764.460388,904.065979 762.845337,903.078186 758.939697,905.163086 
	C757.334167,906.020264 756.974182,907.303040 757.011047,908.873779 
	C757.052429,910.640503 756.625549,912.167053 755.082458,913.223145 
	C752.616943,914.910522 739.322144,913.923218 737.444946,911.463928 
	C735.897095,909.435913 734.666870,908.947205 732.514465,910.184448 
	C731.390625,910.830383 729.955933,911.578247 729.235596,909.647644 
	C728.757629,908.366516 729.521729,907.551453 730.535950,906.737305 
	C732.048035,905.523560 735.395996,905.779053 734.584595,902.640747 
	C734.051147,900.577576 731.937317,899.663025 729.966431,899.210449 
	C727.309570,898.600525 725.529358,900.079529 724.222107,902.226685 
	C722.533875,904.999634 720.820496,907.582947 716.876892,907.108276 
	C715.458618,906.937561 714.176392,907.838562 714.293823,909.485779 
	C714.406616,911.067627 715.841736,910.965515 716.989868,910.981567 
	C719.062073,911.010559 721.187195,910.876770 720.944580,913.973145 
	C720.784180,916.018799 719.620789,916.996338 717.677002,916.944885 
	C715.638000,916.890930 712.150513,917.646790 712.440857,914.841919 
	C712.978271,909.649597 709.719299,911.280579 707.267517,910.987000 
	C705.041809,910.720581 703.505676,912.062073 701.824951,913.104370 
	C700.589417,913.870605 699.720886,914.880615 700.388977,916.395386 
	C701.023682,917.834290 702.401978,918.253113 703.755066,917.818848 
	C706.479736,916.944458 709.040161,916.384216 710.603088,919.583435 
	C711.241882,920.890930 711.093872,922.237000 709.669067,923.047302 
	C706.940308,924.599243 703.963135,924.594238 701.681946,922.671936 
	C699.178040,920.561890 697.188416,920.341553 694.132812,921.579773 
	C691.819824,922.517029 688.884399,922.001343 686.227234,921.980164 
	C684.085632,921.963135 682.469116,922.699524 682.430176,924.943481 
	C682.391418,927.179565 683.940552,928.025085 686.112183,928.012024 
	C689.769043,927.989929 693.407410,928.104126 697.008179,927.117126 
	C698.158508,926.801819 699.887329,926.468384 700.554932,928.023682 
	C701.307861,929.777771 699.441650,930.185852 698.503662,930.939331 
	C697.611511,931.656006 696.482544,932.613770 695.488464,932.579285 
	C689.657959,932.376953 688.218628,935.088196 689.982056,940.237305 
	C690.084351,940.536011 689.994263,940.899475 690.000061,941.232666 
	C690.178101,951.470154 690.177673,951.480164 700.606506,951.991699 
	C701.950867,952.057617 703.159302,952.326355 704.424316,952.895386 
	C710.677124,955.708008 714.832092,953.510620 714.811279,946.639038 
	C714.793640,940.802551 717.389832,934.226868 711.556152,929.273865 
	C710.405579,928.296997 711.736511,927.248108 712.820923,926.648621 
	C718.524475,923.495850 726.934875,928.193970 726.995178,934.587097 
	C727.023438,937.586243 726.500488,940.689758 727.098206,943.564941 
	C728.599487,950.786255 723.854431,955.367065 720.574524,960.607971 
	C719.888672,961.703796 718.747253,961.981750 717.526306,961.980774 
	C714.027222,961.978088 710.521484,962.091858 707.032593,961.900818 
	C705.780090,961.832214 703.821594,961.347534 703.455688,960.502563 
	C700.490906,953.655823 694.315552,955.368347 689.765686,956.344116 
	C681.626099,958.089783 674.377075,955.982117 666.944763,953.765564 
	C665.445312,953.318359 664.186096,952.175415 664.154419,950.551331 
	C664.077454,946.603027 661.584778,945.993042 658.450317,945.997681 
	C649.187073,946.011414 639.811340,945.120239 630.696472,946.283203 
	C623.514282,947.199585 616.379883,949.811768 609.638245,952.912903 
	C605.609009,954.766296 602.992554,953.462524 603.099609,949.018738 
	C603.222412,943.923279 602.389099,940.325134 596.135681,940.933960 
	C594.418518,941.101135 593.563599,939.935059 592.919312,938.365967 
	C591.353821,934.553345 588.757812,934.284607 585.265747,936.100098 
	C581.108154,938.261536 577.190857,941.527527 571.803528,939.300903 
	C570.165466,938.623840 569.216797,940.301331 569.046509,942.005432 
	C568.851379,943.957458 569.714722,945.546265 570.702393,947.123047 
	C571.628296,948.601196 573.543152,950.029236 572.172607,951.941345 
	C570.950134,953.646973 568.624390,953.422302 567.089417,952.710571 
	C565.187378,951.828735 562.252319,951.355408 562.478516,948.284851 
	C562.841309,943.360962 559.519714,942.892456 556.103638,943.106934 
	C551.504150,943.395752 548.616760,946.326355 549.050781,949.712585 
	C549.152039,950.502808 549.305176,951.546387 549.836975,951.981567 
	C556.082092,957.092407 562.573792,961.419250 571.304932,958.406006 
	C578.365051,955.969421 585.427429,956.749756 592.257446,959.221375 
	C594.947021,960.194641 595.826233,958.425049 597.287903,957.226440 
	C598.363403,956.344482 599.242554,954.475891 600.985657,955.807373 
	C602.370972,956.865417 602.316345,958.564209 601.380432,959.878967 
	C599.260925,962.856384 599.533081,965.481445 602.977722,966.664612 
	C606.059998,967.723267 609.009949,967.306030 609.113342,962.746521 
	C609.167358,960.363159 611.072083,959.853882 613.090088,960.077026 
	C615.236694,960.314392 617.101562,961.112183 616.959595,963.713257 
	C616.734497,967.835388 617.430786,971.134399 622.568604,971.166077 
	C622.727722,971.167053 623.017090,971.237671 623.026428,971.305725 
	C623.854187,977.358643 630.665955,975.869568 633.408875,979.549805 
	C634.973511,981.649231 637.801514,981.755310 639.233459,980.480835 
	C641.460754,978.498413 643.591736,975.793640 642.805176,972.285583 
	C642.202332,969.597168 639.862244,970.157776 637.918945,969.985291 
	C634.621704,969.692627 631.335510,969.278198 628.040222,968.961609 
	C625.697510,968.736511 624.665283,967.207275 625.074280,965.195557 
	C626.148315,959.912598 625.047058,956.878540 618.822937,957.884216 
	C617.685669,958.067932 616.469666,957.416809 616.475830,955.894714 
	C616.479736,954.930664 617.259033,954.229004 618.052673,954.240417 
	C623.551819,954.318848 628.543457,950.924438 634.190369,951.972168 
	C635.700378,952.252441 637.320251,952.190247 637.726990,954.049011 
	C638.115234,955.823425 636.823669,956.762207 635.544922,957.606201 
	C634.350464,958.394470 631.294556,958.407104 632.911011,960.573486 
	C634.821716,963.134338 636.372681,967.825684 640.539490,966.396118 
	C643.713928,965.306946 648.074341,966.057800 649.923218,961.166443 
	C651.063354,958.150085 657.244995,960.047913 657.838196,963.770508 
	C658.281006,966.548950 659.084656,968.067688 662.077637,968.074829 
	C664.500549,968.080627 664.751343,970.003845 665.194580,971.873047 
	C665.611328,973.630676 666.246704,975.505676 668.448975,975.864563 
	C670.781555,976.244690 671.711670,974.786438 672.249634,972.817444 
	C672.368896,972.381104 672.760925,971.940063 673.145935,971.670776 
	C674.207825,970.928040 675.123474,971.533936 675.745422,972.315613 
	C677.759094,974.846863 681.491821,976.200439 681.686646,979.906799 
	C681.777344,981.631104 680.043091,982.825012 678.221985,982.913635 
	C674.064209,983.115906 671.037964,984.436340 669.573914,988.865723 
	C668.898560,990.908936 666.545044,990.136169 665.448181,988.802246 
	C662.118713,984.753113 657.690247,984.826782 653.123535,984.980530 
	C651.115295,985.048157 649.593384,985.466797 648.934387,987.673401 
	C647.621155,992.070496 644.410095,991.938660 641.577209,989.818481 
	C638.957031,987.857422 636.320862,987.461670 633.637146,988.381165 
	C629.260376,989.880737 626.591187,987.472168 625.281372,984.302368 
	C624.104309,981.453796 622.564087,981.041504 620.052368,980.965820 
	C615.278198,980.821899 610.166687,982.383789 606.724731,976.788269 
	C605.305725,974.481384 602.303223,976.164734 602.027344,979.437622 
	C601.795532,982.188354 601.798157,984.786316 597.939941,985.043030 
	C593.993530,985.305725 591.819275,983.993958 592.007874,979.808105 
	C592.110413,977.531250 591.678345,975.904236 588.800476,975.991821 
	C586.119812,976.073425 585.925232,977.736328 586.005676,979.704285 
	C586.173828,983.817932 583.988708,984.534424 580.213440,985.242126 
	C575.129944,986.195068 570.248596,986.697449 565.136841,986.051208 
	C562.213684,985.681702 559.795288,985.242310 559.972168,981.522827 
	C560.020142,980.513123 559.685120,978.794861 559.013489,978.908508 
	C551.826050,980.125244 553.559448,970.590210 547.505737,969.806519 
	C539.417786,968.759460 531.054932,970.136108 523.270874,967.847412 
	C511.919434,964.509827 500.241486,965.750549 488.803101,964.139404 
	C485.358246,963.654175 484.332977,962.608093 486.891083,959.405090 
	C489.139282,956.590149 488.225281,954.362366 484.389862,954.020874 
	C482.755829,953.875549 481.558746,953.212646 480.627380,951.913208 
	C479.405701,950.208862 480.911011,946.843994 478.138550,946.403687 
	C473.783325,945.711975 469.424438,943.994019 464.871887,945.510254 
	C463.792694,945.869629 462.589050,945.922363 461.435852,945.979431 
	C458.333649,946.132812 456.240234,944.954712 456.025818,941.560425 
	C455.831177,938.478882 456.910309,936.299194 460.306671,936.031494 
	C462.122253,935.888367 463.686920,935.482300 463.883209,933.358765 
	C464.101654,930.995850 462.161804,930.833191 460.618622,929.810791 
	C452.763550,924.606750 444.115143,927.901062 435.886414,927.049438 
	C433.562958,926.808899 431.294647,927.066467 429.485535,925.041687 
	C427.638245,922.973816 425.610992,921.197998 430.002075,919.800415 
	C432.013855,919.160156 432.102264,917.405579 431.911957,915.678284 
	C431.715240,913.892944 430.543793,913.132690 428.838593,913.020325 
	C428.175446,912.976624 427.498169,912.938232 426.859100,912.773926 
	C424.307922,912.118042 421.207916,910.659485 419.606171,913.510498 
	C417.982117,916.401001 421.444000,917.674194 423.052216,919.333923 
	C424.136322,920.452698 425.507782,921.314209 424.615509,923.039978 
	C423.861328,924.498779 422.221191,925.279541 420.806183,924.885132 
	C414.118011,923.020752 407.132568,925.140381 400.511017,923.284180 
	C395.448730,921.864990 391.254852,925.707092 386.401978,925.236633 
	C386.142151,925.211426 385.625183,925.523743 385.600586,925.724487 
	C384.930908,931.188293 380.350006,929.695862 377.123871,930.423218 
	C369.702667,932.096313 367.548676,930.417603 367.725250,922.653809 
	C367.789642,919.822754 366.647614,919.049866 364.321503,919.027771 
	C359.515594,918.982056 354.854767,918.820190 350.114166,917.232361 
	C345.761963,915.774536 344.115662,918.165894 345.632843,922.569275 
	C346.974823,926.464111 345.639832,929.095215 341.602570,929.938416 
	C339.828003,930.309021 338.269043,930.591553 338.112488,932.722046 
	C337.962433,934.763916 339.097137,935.754150 341.049377,936.013977 
	C342.694946,936.232971 344.042877,936.947815 343.725037,938.874756 
	C343.414948,940.754822 341.797363,940.789185 340.257660,941.015869 
	C333.646790,941.989075 327.604431,938.871216 321.161285,938.477600 
	C313.857056,938.031311 306.514832,937.054077 299.384338,939.054260 
	C295.368256,940.180847 292.724335,939.340576 290.545807,935.954529 
	C291.891052,934.334045 293.680267,935.049194 295.219727,935.036926 
	C304.550751,934.962524 313.882660,934.996460 323.214294,935.000183 
	C325.239136,935.001038 327.402893,935.057922 328.577911,933.092529 
	C329.951813,930.794495 327.429718,929.656738 326.312927,927.506104 
	C330.778687,927.461304 333.859253,925.298950 336.383759,922.129150 
	C338.599548,919.346924 337.807739,916.643311 334.525818,917.337463 
	C328.267883,918.661194 322.993164,916.021240 317.263153,915.191650 
	C312.501312,914.502258 307.588531,915.525391 304.805420,910.070618 
	C303.827301,908.153564 300.790894,909.884338 299.810974,908.806030 
	C293.266876,901.604919 283.990265,906.763184 276.684021,903.383423 
	C274.996857,902.603027 273.933197,904.161133 272.563110,904.466064 
	C270.574127,904.908630 268.685120,903.941040 268.343292,901.963440 
	C267.942505,899.644470 270.106537,900.132812 271.499878,900.013184 
	C272.327881,899.942200 273.170197,900.030945 273.996674,899.950989 
	C275.196106,899.834839 276.406677,899.548767 276.532196,898.074524 
	C276.635559,896.860657 275.487213,896.628723 274.721985,896.020996 
	C272.727631,894.437256 268.913422,896.518555 268.113586,892.874268 
	C267.479034,889.983032 270.147369,888.625183 271.398956,886.570923 
	C272.795532,884.278809 271.797577,882.356506 268.871552,882.044128 
	C267.155731,881.860901 265.090759,881.739563 265.309174,879.535706 
	C265.531677,877.289856 267.770447,877.053406 269.606781,877.041382 
	C278.937988,876.980225 288.277496,876.781372 297.597687,877.104492 
	C301.609772,877.243591 305.052124,875.745300 308.724731,874.857422 
	C309.939545,874.563721 311.016235,873.891174 310.425018,872.411865 
	C309.905945,871.113037 308.699127,870.923645 307.476318,871.346680 
	C297.404022,874.832031 287.009613,872.192993 276.794464,872.961975 
	C274.336914,873.147034 272.414062,871.934204 272.161346,869.410706 
	C271.895355,866.754700 273.106018,864.916260 276.175629,864.940063 
	C277.424805,864.949707 278.672211,864.312561 279.054962,863.242126 
	C280.225861,859.967529 282.274200,858.452820 285.804138,858.915710 
	C286.847839,859.052612 287.795349,858.515259 287.711761,857.199341 
	C287.638031,856.038757 286.807434,855.329834 285.795959,855.200378 
	C284.152771,854.990234 282.415436,854.747742 280.822571,855.057373 
	C276.128601,855.969971 272.259949,852.848938 267.792877,852.625305 
	C266.303406,852.550781 265.506653,850.925049 264.961060,849.425781 
	C263.245972,844.712952 264.033081,839.853149 264.155029,835.067322 
	C264.224457,832.342163 266.766693,833.135925 268.461243,833.019348 
	C271.275330,832.825806 273.416840,833.761414 273.934937,836.843811 
	C274.274231,838.862549 273.242371,841.493286 276.058502,842.405518 
	C279.281769,843.449646 282.621094,843.343323 285.879456,842.449646 
	C287.299530,842.060242 287.955383,840.935791 287.997192,839.413330 
	C288.033478,838.091858 288.041870,836.720947 288.395081,835.465332 
	C290.034912,829.636719 289.664185,829.087830 283.610138,829.000671 
	C281.278748,828.967163 278.944092,829.000244 276.617157,828.881042 
	C275.347015,828.815857 274.385376,828.092957 274.229187,826.746094 
	C274.071472,825.386169 274.840363,824.371948 276.108643,824.218567 
	C278.953064,823.874695 280.460205,822.529114 279.885834,819.598083 
	C279.231232,816.257812 276.445679,817.069214 274.240997,817.031189 
	C272.010590,816.992615 270.078278,816.908386 268.682434,814.620056 
	C266.835358,811.591980 264.596588,813.927551 262.576599,814.655640 
	C260.529144,815.393677 256.731964,814.315674 256.787323,817.118164 
	C256.850159,820.297485 260.549530,818.456909 262.569458,819.096191 
	C264.187866,819.608398 266.009857,820.018311 265.859039,822.179443 
	C265.716644,824.220032 265.001099,826.176086 262.739594,826.811584 
	C261.640228,827.120544 260.389313,826.877869 259.279022,827.164124 
	C258.356140,827.402100 257.165894,827.838318 256.758331,828.561218 
	C256.079010,829.766174 257.503754,830.119019 258.260376,830.647095 
	C260.659027,832.321167 260.425507,833.361267 257.583191,834.079712 
	C255.152573,834.694092 252.754822,835.665161 250.361115,834.143494 
	C248.961716,833.253845 245.730804,832.984924 248.954086,830.250427 
	C250.278946,829.126587 249.334885,828.188416 248.123215,827.634277 
	C247.525970,827.361145 246.878952,827.125732 246.232712,827.043030 
	C243.107620,826.642578 240.889557,825.108948 240.193863,821.953003 
	C239.448715,818.572754 242.188171,817.479675 244.834915,816.253906 
	C240.831146,814.692749 235.128799,816.802124 234.224304,820.442078 
	C233.280807,824.238892 231.129517,825.052246 227.735306,825.066223 
	C225.443069,825.075684 223.155563,825.713440 220.861801,826.041199 
	C219.138672,826.287476 218.112686,827.179321 218.105621,828.982666 
	C218.098602,830.775024 219.131317,831.525269 220.810608,831.985474 
	C225.824173,833.359619 227.266235,835.991943 225.581894,840.628662 
	C224.834305,842.686584 223.782684,844.766541 221.208771,844.883972 
	C218.326294,845.015503 216.502869,842.722900 216.511795,840.565735 
	C216.547394,831.961365 209.095627,832.146790 204.420563,831.388367 
	C197.698257,830.297791 190.512527,830.164001 183.586349,831.830261 
	C182.177444,832.169250 180.536499,832.130432 179.133636,831.763916 
	C177.242447,831.269775 175.873779,829.895020 176.191483,827.726135 
	C176.504318,825.590454 178.404404,825.859497 179.771210,826.049316 
	C187.042252,827.058716 194.344421,827.128601 201.654678,826.968994 
	C203.312012,826.932800 205.795425,827.724731 205.804138,824.826660 
	C205.812622,822.009277 203.509933,822.011475 201.450867,822.005981 
	C194.642303,821.987793 187.833145,822.171265 181.065582,821.040771 
	C179.149933,820.720825 177.214874,820.678345 177.167679,818.203308 
	C177.118652,815.634338 179.215820,816.011841 180.803879,816.024902 
	C184.307343,816.053772 187.629242,814.539185 191.185806,814.957031 
	C193.399368,815.217163 194.088699,813.807007 193.994934,811.420105 
	C195.531586,809.269958 197.561737,809.510498 199.955215,809.659546 
	C203.913376,810.986328 206.734314,810.802368 208.338226,806.598206 
	C209.199142,804.341675 211.616821,803.396729 213.781204,804.184937 
	C218.406235,805.869263 223.259445,807.484131 225.948959,812.133118 
	C227.024643,813.992493 227.996384,815.613647 230.515762,814.886414 
	C232.898102,814.198914 233.217224,812.274292 233.424179,810.195679 
	C234.289703,801.502014 240.157852,795.563538 249.098343,794.445190 
	C251.684448,794.121704 253.988098,793.350769 255.724701,791.398743 
	C257.842651,789.018066 260.248627,787.111877 263.009338,785.465210 
	C266.495728,783.385620 266.126282,780.763672 262.339264,778.870300 
	C260.252960,777.827148 256.531189,777.591125 257.980225,774.231201 
	C259.423157,770.885498 262.984375,771.883972 265.406769,773.275085 
	C269.152740,775.426208 272.963013,775.071350 277.287750,774.727661 
	C281.789368,775.716492 286.094696,774.728760 290.522491,776.256714 
	C291.519531,777.064087 291.756989,777.815979 291.665344,779.038330 
	C290.788910,780.969788 289.307617,781.354919 287.797333,781.316101 
	C284.278992,781.225647 280.876801,781.971619 277.038177,782.615356 
	C280.921814,785.155884 279.803009,787.671448 277.220520,790.159119 
	C275.094788,792.206726 272.193024,792.604736 269.639343,793.736877 
	C268.014160,794.457275 269.227448,794.786926 270.045776,795.466919 
	C271.617859,797.194031 271.869812,799.354736 273.647949,801.006287 
	C274.592743,803.414612 274.118317,805.260681 272.841339,806.979431 
	C270.813416,809.708801 272.304962,810.562439 274.880493,810.496582 
	C278.653625,810.400085 282.444977,810.507324 286.309784,810.232056 
	C285.949890,808.956360 284.997803,808.888184 284.410339,808.384216 
	C281.783447,806.130798 281.971130,804.683044 285.282532,803.591125 
	C289.257538,802.280457 293.464508,802.076904 297.589722,801.554565 
	C300.265198,801.215820 302.687744,800.632629 305.064972,799.143127 
	C310.065765,796.009766 313.786682,797.946655 314.548553,803.751709 
	C315.368134,809.996521 315.659058,810.262573 321.971680,810.371887 
	C324.470398,810.415161 326.969696,810.387207 329.468994,810.422302 
	C332.367859,810.462952 334.703522,811.406189 335.913055,814.232422 
	C337.218933,817.283691 339.797058,818.126587 342.786682,818.290710 
	C345.783508,818.455322 348.785370,818.424805 351.782013,818.371948 
	C355.288330,818.310181 358.253662,819.114136 360.607910,822.037476 
	C363.188446,825.241638 367.237518,824.675171 370.772369,825.344116 
	C373.477570,825.856079 376.632111,825.539490 377.432251,829.577576 
	C374.741852,832.428101 371.715515,831.121582 368.804962,830.945923 
	C366.376007,830.799255 363.651581,830.013306 362.539825,833.755737 
	C362.503082,835.148010 362.343414,836.130737 361.620514,837.320618 
	C357.888550,840.947266 352.918579,838.683533 349.153656,840.846802 
	C355.400177,843.804993 358.463440,843.145203 360.947479,838.064697 
	C363.556671,835.654480 367.315125,837.100647 369.861664,834.350708 
	C374.549744,833.022278 378.999176,833.086853 383.821106,833.044678 
	C384.443634,833.312622 384.672394,833.458008 385.106750,833.949829 
	C385.284332,836.094299 383.620880,836.786438 382.502869,838.268433 
	C378.863678,840.361572 374.750732,839.383484 371.302124,841.675476 
	C370.638977,843.611694 370.123627,845.466370 372.344696,846.034058 
	C376.026794,846.975281 379.865784,847.056824 383.482391,845.872742 
	C386.375427,844.925720 383.934296,842.898254 383.860443,840.980347 
	C383.829987,839.819763 384.024048,839.080261 384.647766,838.080322 
	C386.077850,836.913025 387.326141,836.891235 388.928040,837.681458 
	C391.508301,840.860046 392.339630,844.453735 393.862244,848.048218 
	C395.667603,849.735168 397.222595,848.796936 399.110107,848.163574 
	C400.166748,847.950256 400.882782,847.992615 401.948975,848.292969 
	C403.678528,849.279785 404.595123,850.418274 404.502411,852.468506 
	C402.846344,854.839905 400.247681,852.302124 398.424744,854.337524 
	C399.452789,856.267700 401.986725,855.347778 403.459229,857.002502 
	C404.181274,858.239258 404.265594,859.246155 403.992676,860.670898 
	C403.508057,861.807373 402.936707,862.325012 401.733032,862.583801 
	C397.843140,861.503906 394.387207,860.126526 392.222534,857.057190 
	C390.117859,854.072815 387.237061,856.642212 384.620270,855.365906 
	C382.285736,852.409119 379.584900,851.174805 376.205841,851.653809 
	C371.234985,852.358459 366.714600,848.998535 361.351685,850.080811 
	C359.223694,849.914551 357.769348,847.207458 355.629089,849.726562 
	C355.380035,851.415955 353.934875,853.092102 355.792236,854.047729 
	C357.312714,854.830017 358.648834,853.623962 359.653412,852.453979 
	C361.031525,850.848999 362.617249,849.945068 364.837799,849.982361 
	C368.733917,850.047729 370.416840,851.640137 370.088318,855.475525 
	C369.933411,857.283691 369.197510,859.047668 369.812042,861.240234 
	C370.009186,864.022705 368.089752,865.638062 367.159637,867.996887 
	C362.845612,868.847168 358.983093,872.543945 354.579437,868.967590 
	C353.805359,868.338867 352.401489,869.014893 352.178558,869.988831 
	C351.730225,871.947510 353.492310,871.425720 354.450867,871.508789 
	C356.561768,871.691650 358.711212,871.457886 361.107452,872.293701 
	C363.628265,874.215088 366.988647,873.151001 369.130371,875.845886 
	C369.792480,878.813049 367.733887,879.057129 366.043732,879.479248 
	C361.486023,880.617737 358.401215,882.902588 358.371979,888.118469 
	C358.360321,890.203064 357.313812,891.874207 354.817078,892.593140 
	C354.133331,892.651123 353.728729,892.306335 353.464417,892.454895 
	C352.891113,892.777161 353.376404,892.204956 353.808350,893.082397 
	C354.293243,895.131592 354.158264,896.796082 352.250336,897.552429 
	C350.648193,898.187439 349.254425,897.363403 348.245300,896.195801 
	C346.164185,893.787720 343.559967,892.652161 340.454529,892.200806 
	C337.390106,891.755432 335.359619,889.907837 334.527802,886.843750 
	C333.849548,884.345520 332.499390,883.907227 330.650635,885.759216 
	C327.625336,888.789978 324.218567,888.592468 320.467072,887.390686 
	C316.446960,886.102905 312.325134,886.251892 307.057007,887.353088 
	C310.193726,888.853210 312.487000,888.403015 314.656433,888.422546 
	C318.330505,888.455444 321.989410,888.504639 325.562653,889.489136 
	C332.038422,891.273499 333.621399,895.986267 329.444977,901.286011 
	C331.232513,901.785156 333.361633,902.772278 333.828156,900.020264 
	C334.339508,897.003601 335.778412,895.622925 338.753418,895.545593 
	C342.085663,895.459106 345.320831,895.860840 346.766571,899.313049 
	C348.067352,902.419250 350.136475,903.556641 353.348877,903.448059 
	C355.879852,903.362488 357.878937,902.860046 358.807892,900.122009 
	C359.832947,897.100891 362.385620,895.367493 364.916687,893.747986 
	C368.681030,891.339539 370.551178,891.655029 373.354218,895.297974 
	C374.521973,896.815735 375.793213,898.287354 377.603577,898.562805 
	C381.826599,899.205200 385.171478,902.303772 390.190613,903.265381 
	C388.103668,900.615784 383.457306,901.058411 384.832855,897.355347 
	C386.025482,894.144836 389.447754,894.456543 392.256073,895.021973 
	C396.274261,895.831055 399.409729,894.863098 401.926331,891.547668 
	C403.345337,889.678284 405.319519,888.372620 407.467346,887.370605 
	C408.828186,886.735718 410.597168,886.195068 411.099579,884.853088 
	C413.346130,878.852051 418.075012,877.846497 423.894073,878.480347 
	C423.032471,877.168579 422.059784,877.663635 421.272552,877.573853 
	C420.284363,877.461060 419.276031,877.534363 418.279602,877.442566 
	C415.065552,877.146606 412.693665,875.780396 412.948883,872.192871 
	C413.203766,868.610168 415.536133,867.424988 419.243988,868.131958 
	C421.277710,869.519470 420.842499,873.512329 424.922791,872.134583 
	C425.742554,872.092407 426.208679,872.139954 427.007507,872.336914 
	C430.019012,873.749329 432.354675,875.296875 434.434784,877.641663 
	C437.497620,881.094238 441.574799,879.501770 445.937073,878.674011 
	C443.489899,875.378174 440.603455,874.677429 437.400391,874.804871 
	C435.075928,874.897278 432.407654,875.497986 431.546997,872.539062 
	C430.713928,869.674866 432.963501,868.416016 435.164154,867.431396 
	C436.200012,866.968018 436.740875,866.286987 436.679474,864.775024 
	C436.771210,863.630310 436.970581,862.889954 437.520081,861.847961 
	C442.155273,858.064453 447.043823,859.850464 452.166382,860.259399 
	C453.077606,860.279724 453.785095,860.643433 454.011475,859.816162 
	C454.078522,859.571167 453.673340,859.357910 452.989899,859.254395 
	C449.143311,858.614319 445.629669,860.011353 441.721008,859.084778 
	C436.222198,857.863647 431.044922,858.760986 425.471741,858.397705 
	C424.447479,858.265259 423.896118,858.066528 423.029846,857.512207 
	C421.207581,852.081421 423.148651,848.847595 428.110596,849.674622 
	C447.404663,852.890503 466.979950,850.948303 486.302887,853.252319 
	C489.579559,853.642944 492.513245,852.246338 495.501068,851.334045 
	C500.233856,849.888733 504.712280,850.342529 509.093658,852.452148 
	C510.488831,853.123901 511.786560,853.989685 511.522980,855.815552 
	C511.260010,857.637390 509.751160,858.118042 508.244568,858.380859 
	C504.941376,858.957153 501.579102,858.454346 498.273743,858.794006 
	C496.479736,858.978394 494.192291,857.977173 493.174683,860.691895 
	C494.055481,862.204651 494.967957,863.262512 496.275024,864.450562 
	C498.781555,868.437500 501.580414,869.520264 506.096832,868.200317 
	C511.109863,866.735291 516.411072,867.336487 521.443420,869.775146 
	C521.518494,871.046509 521.010132,871.820923 520.692566,872.989990 
	C521.243591,876.844116 524.245178,876.846008 527.041992,877.749939 
	C527.840332,882.485535 524.779846,882.178223 521.908386,881.879761 
	C520.265259,881.709045 519.079834,880.282532 517.527893,879.748169 
	C516.913574,880.802979 517.872009,881.761719 517.320679,883.025757 
	C514.862854,885.504517 511.730225,885.005981 508.908478,885.835938 
	C509.729095,887.217468 511.723297,886.822144 512.392456,888.660278 
	C510.524292,893.251099 506.685577,890.363098 503.738800,891.305603 
	C505.494080,893.308105 509.147491,894.087463 508.372162,897.541382 
	C507.784393,900.159668 505.395844,900.772400 502.582275,900.585938 
	C501.086304,899.697449 501.600891,898.389893 501.243225,897.366760 
	C500.400574,894.956055 498.295746,893.825195 496.069611,893.378906 
	C493.332886,892.830383 493.581390,895.892029 491.977295,897.324097 
	C488.330505,896.972961 485.147888,897.371582 482.049805,898.838867 
	C478.656586,900.445862 474.990173,900.615295 471.293701,900.555542 
	C469.213379,900.521912 467.060760,900.344849 464.891296,901.486206 
	C466.474152,903.086853 468.048309,902.422241 469.457214,902.529907 
	C471.473053,902.683960 473.645325,902.722534 475.187683,903.995422 
	C478.691406,906.886780 482.468597,909.136169 486.744751,910.693665 
	C488.467255,911.320984 489.885620,912.737549 489.683899,914.900146 
	C489.484467,917.038269 487.862000,918.461670 486.079224,918.523254 
	C479.285858,918.758057 474.439667,923.996765 466.797729,925.285156 
	C471.571259,927.154724 474.517975,929.447571 476.604431,933.217590 
	C478.190979,936.084351 482.365662,934.889954 483.880096,931.589417 
	C486.372803,926.157043 487.488159,925.511963 493.550171,926.501343 
	C497.776642,927.191223 501.687469,925.242004 505.902679,925.373230 
	C514.712830,925.647461 523.540344,925.522766 532.744202,925.030518 
	C528.685913,923.851196 527.201965,920.976379 526.448669,917.300293 
	C525.679749,913.547180 523.562378,912.904785 520.341248,915.131470 
	C518.971985,916.078064 518.011902,917.530640 516.572754,918.395630 
	C513.551514,920.211609 510.398010,920.795166 508.205933,917.453247 
	C506.049255,914.165161 503.047913,913.641479 499.600800,913.515442 
	C496.124695,913.388245 491.613068,913.593384 491.630768,908.894043 
	C491.647125,904.549438 496.191681,903.410217 499.524902,903.773804 
	C507.000366,904.589172 509.612854,898.268860 514.005127,894.752319 
	C518.286438,891.324646 522.670410,888.025452 525.745422,883.025818 
	C528.438782,878.646729 532.584412,878.783997 536.563599,881.867126 
	C538.138367,883.087280 539.956421,884.349854 539.396912,886.715820 
	C538.820251,889.154114 536.824341,890.024902 534.540955,890.358704 
	C533.586060,890.498413 532.604126,890.476379 531.273499,890.753601 
	C533.273560,891.689026 534.799011,891.067932 536.304993,890.592407 
	C539.371338,889.624268 541.690430,890.503784 542.636536,893.593567 
	C543.304871,895.776184 544.539368,895.938904 546.399780,895.634338 
	C548.534668,895.284973 550.669983,894.785522 552.820923,894.697510 
	C556.153259,894.561279 557.840088,893.293152 558.298401,889.710999 
	C558.785278,885.904663 562.445374,883.825562 564.732544,880.552734 
	C567.509460,879.073975 570.268188,879.565674 573.249329,878.955322 
	C575.509155,878.590210 577.336548,878.763306 579.511353,879.390076 
	C580.395691,879.463806 580.899902,879.469971 581.772705,879.430542 
	C588.398438,876.902100 594.777039,876.695984 601.203918,878.569763 
	C603.763123,879.315918 605.924500,879.184753 608.262329,877.611633 
	C612.915588,874.480530 617.946289,874.951477 619.731445,878.331604 
	C621.880615,882.401001 619.934692,887.260498 615.093872,889.895569 
	C614.511536,890.212585 613.860046,890.410156 613.484375,891.288086 
	C615.199280,890.953186 616.697021,889.787720 618.468811,889.623230 
	C620.288818,889.454163 622.127747,889.424316 623.919373,888.977051 
	C626.795715,888.259033 629.119141,887.127808 629.918213,883.795166 
	C630.898926,879.705078 633.955261,877.848755 638.002686,877.584900 
	C641.828613,877.335510 645.659119,877.293518 649.457092,877.988525 
	C651.604980,878.381592 653.747314,878.406006 655.917542,878.097412 
	C658.676819,877.705078 660.839966,876.921082 660.069214,873.408508 
	C659.582275,871.189270 661.263977,869.315979 660.955627,866.933594 
	C660.159302,866.450439 659.546570,866.313965 658.658325,866.026672 
	C657.585144,865.649292 656.796326,865.523376 655.661133,865.864380 
	C654.880493,866.056641 654.414856,866.106018 653.568726,866.088074 
	C649.127136,863.861450 647.453064,860.937134 648.529785,857.912231 
	C649.712219,854.590027 653.220703,853.423706 657.761902,854.244263 
	C663.371765,855.257996 667.577881,857.883972 670.281128,863.281006 
	C671.530701,863.551636 672.436768,863.455383 673.661987,863.308960 
	C676.337463,862.211548 675.708008,860.611023 674.592896,858.668884 
	C674.267639,858.175720 674.145447,857.970398 673.865479,857.438232 
	C672.315308,853.458618 672.390503,850.223206 676.335999,848.150024 
	C680.528137,845.947327 683.862610,846.589722 686.998779,850.166748 
	C687.437256,850.666931 687.751953,851.339661 688.278259,851.687195 
	C691.351929,853.716919 689.981079,860.173035 694.871216,859.583801 
	C698.356262,859.163879 702.073608,858.487122 705.524048,857.241577 
	C706.615845,856.847534 707.732056,856.497559 709.276489,856.326477 
	C709.908875,856.384644 710.157043,856.451050 710.703857,856.761230 
	C711.007690,858.482300 710.879517,859.981262 711.850952,861.663208 
	C712.883118,866.008484 709.738098,865.450500 707.151489,865.888184 
	C704.517639,865.025452 702.605652,860.882446 699.786743,865.441528 
	C698.985779,868.260559 697.520081,870.428650 696.372681,873.108643 
	C692.384155,874.568481 687.637878,872.362488 684.685669,876.879944 
	C685.889038,878.787598 688.044739,878.626831 689.886963,879.055542 
	C691.841553,879.510437 693.865112,879.368774 695.853088,879.541931 
	C698.188477,879.745361 700.893005,879.805054 700.839722,883.098694 
	C700.792175,886.030151 698.321167,886.213806 696.134705,886.450989 
	C694.646790,886.612488 693.124451,886.520081 691.668030,886.900024 
	C689.921997,887.355530 688.064697,887.579224 686.243835,889.268005 
	C689.228638,891.112915 692.619934,889.931152 695.737305,891.304016 
	C697.589600,893.694519 700.244507,895.151245 700.481567,898.521729 
	C698.183289,901.184082 694.785645,898.191223 692.281799,900.924377 
	C695.616821,903.181274 700.320801,901.233215 703.161987,904.898621 
	C702.436768,909.129883 699.333862,909.345764 696.301758,909.130493 
	C693.154602,908.907043 690.021240,908.509033 686.855835,908.454224 
	C683.539368,908.396790 680.576782,907.501465 678.844910,904.346924 
	C677.030640,901.042175 678.950806,898.661682 680.918701,896.302734 
	C681.226440,895.933777 681.538269,895.521301 681.309875,894.550537 
	C677.625244,896.528564 675.522705,901.203857 670.345825,900.886841 
	C674.407043,903.601074 676.791870,906.932373 676.494995,911.862427 
	C676.276855,915.485046 675.024414,917.927246 671.132507,918.448425 
	C668.864502,918.752075 667.052368,919.152100 667.566467,922.448792 
	C668.469360,928.239868 665.426208,931.713684 659.361389,932.369080 
	C655.305054,932.807373 651.180603,932.269592 647.132568,932.924927 
	C656.520935,934.183838 656.520935,934.183838 660.491455,938.961548 
	C665.027344,934.429871 670.184875,930.592468 674.790405,926.121460 
	C676.356018,924.601624 677.603333,923.234558 677.412842,921.120117 
	C676.382141,909.681885 685.496643,908.013977 694.030273,910.321960 
	C698.555176,911.545715 701.057373,910.617188 703.600952,905.905151 
	C704.239502,903.879700 706.994812,902.575745 704.551147,900.003052 
	C705.046692,897.825317 707.182556,897.730469 708.359863,896.080444 
	C710.051331,894.883057 711.533630,895.000671 712.880981,895.834656 
	C716.468689,898.055542 720.094238,897.704590 723.169250,895.284607 
	C730.064209,889.858398 736.780640,893.514404 743.527649,895.285217 
	C745.652344,895.842834 746.146179,898.149719 747.127136,899.862671 
	C747.541748,900.586670 748.075928,901.382874 749.017883,901.188965 
	C749.967468,900.993408 750.192749,900.035217 750.227722,899.212341 
	C750.429810,894.457886 750.787598,889.688110 749.932922,885.318420 
	C749.085632,884.866272 748.650330,884.787476 748.470398,885.053711 
	C743.738098,892.056091 741.516724,892.916504 733.095581,890.930908 
	C728.853088,889.930542 724.626709,889.369629 720.240479,890.197510 
	C714.959290,891.194336 709.671326,889.146545 704.329285,889.996216 
	C702.765747,890.244873 701.431641,889.235229 701.215759,887.420898 
	C700.949890,885.186218 702.285522,883.818665 704.176941,883.398254 
	C709.523132,882.210144 714.945557,880.775940 720.434509,881.974670 
	C723.283875,882.596863 725.956116,882.240540 728.742615,882.040588 
	C726.222168,880.248596 722.682190,884.062683 720.505554,880.042480 
	C721.764160,876.812012 724.696777,875.372437 726.698669,872.624390 
	C727.959045,871.928162 728.954346,871.838867 730.334290,872.142578 
	C733.614441,874.358398 735.538452,877.169617 736.551758,881.117004 
	C739.226868,879.351501 739.453552,877.267517 738.737183,874.663818 
	C737.883606,871.561707 735.711365,872.285706 733.261597,872.541504 
	C731.384338,871.431152 729.923889,870.309265 728.398315,868.735962 
	C727.612549,866.731995 726.308350,865.373352 725.875732,863.317749 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M360.750763,260.324158 
	C364.049988,260.171326 366.935272,259.651611 369.772125,262.254486 
	C372.016968,264.314209 375.864655,262.925720 378.989166,262.951202 
	C382.820007,262.982483 387.149872,264.860107 389.946533,260.158508 
	C390.545349,259.151855 392.293823,258.748932 393.233429,260.479950 
	C394.238739,262.332092 397.083435,263.208466 396.645630,265.683258 
	C396.300201,267.635590 394.207153,266.849304 392.814728,266.981934 
	C391.824219,267.076233 390.817688,266.988190 389.819550,267.020203 
	C388.622131,267.058563 387.400482,267.159424 387.176971,268.687439 
	C386.956818,270.192688 387.578003,271.244659 389.041016,271.797729 
	C394.111847,273.714569 398.492981,277.741364 404.850708,275.325256 
	C406.996643,274.509705 410.434021,275.620361 412.482941,277.995941 
	C413.247498,278.882385 414.235077,279.738678 413.223358,280.995636 
	C412.464081,281.938995 411.478455,281.742371 410.522552,281.241577 
	C407.215851,279.509155 403.813568,279.609619 400.366821,280.733063 
	C397.813416,281.565338 395.959869,279.619934 393.374603,279.047119 
	C392.809875,279.135529 392.619781,279.272247 392.079773,279.387085 
	C389.588501,278.198486 387.391998,278.134857 384.812958,279.143433 
	C383.366150,279.564728 382.160461,279.639648 381.129700,280.125488 
	C375.610687,282.726898 370.076141,282.935303 364.462250,280.256744 
	C371.681000,284.254333 378.998077,281.550659 386.258270,280.724670 
	C388.021973,280.524017 389.695160,279.477875 391.751587,280.740967 
	C392.095764,281.826843 392.120361,282.666656 392.307037,283.468750 
	C393.581238,288.943207 393.343842,289.190979 387.393860,288.007904 
	C385.185730,287.921570 383.439819,288.667297 380.852112,288.348602 
	C382.584991,289.152039 383.798218,289.495239 384.761139,290.761597 
	C386.664703,291.136230 388.743317,290.484222 389.515442,292.643921 
	C389.987640,293.964691 388.835846,294.581848 387.341492,294.956909 
	C384.600037,297.158295 381.685394,296.763885 378.912415,296.392944 
	C374.433990,295.793854 369.999603,295.400604 365.564087,296.577972 
	C362.849152,297.298584 360.542480,297.044708 359.553528,293.919647 
	C358.535736,290.703430 361.190887,290.089966 363.695068,288.773529 
	C360.029053,287.610260 356.805847,287.592712 353.539032,288.117096 
	C349.567810,288.754578 345.569702,288.826843 341.578064,288.512939 
	C339.012177,288.311157 336.497925,287.797241 336.376801,284.076843 
	C339.824890,280.208344 344.743805,280.918915 349.701691,278.902924 
	C344.426453,278.541229 339.828674,276.982849 337.153748,282.832642 
	C333.352020,284.747406 329.764465,286.368500 326.119629,287.791992 
	C323.878540,288.667236 320.675842,289.248627 319.740662,286.199493 
	C318.900604,283.460632 321.636871,282.402374 323.888733,281.610779 
	C324.503265,281.394714 325.174133,281.256592 325.709961,280.338989 
	C323.997620,279.182037 321.886566,279.739136 319.764404,279.003632 
	C316.346313,277.639771 316.140839,273.670044 312.908661,271.710266 
	C312.654938,274.317841 314.511841,275.835541 314.354309,278.151825 
	C314.209442,283.677032 310.224762,284.574249 306.059021,285.638062 
	C303.973572,284.848206 304.462677,283.665009 304.947754,282.334076 
	C305.623688,280.479401 303.981995,282.309662 303.497009,281.640442 
	C302.319794,281.338593 301.649323,280.675720 300.413147,280.538696 
	C299.167145,280.568054 298.259766,280.508118 297.006592,280.467651 
	C292.608276,280.773224 288.527649,280.279388 283.731659,280.840057 
	C288.318726,282.229279 292.440338,281.996735 296.631073,283.273499 
	C297.529816,283.922760 297.867859,284.554840 298.212219,285.584839 
	C298.416565,286.587921 298.449188,287.266052 298.809265,288.188538 
	C299.284332,289.236633 299.807159,289.733978 301.046997,289.568878 
	C303.514221,289.066559 305.329773,287.883972 307.248108,286.940399 
	C309.213074,285.973999 311.262238,285.433258 313.464447,285.690155 
	C315.175812,285.889771 316.749603,286.425385 317.389984,288.212952 
	C317.981171,289.863342 316.966766,291.052551 315.929596,292.148071 
	C314.431396,293.730591 312.760529,295.242523 310.497955,295.303650 
	C306.449738,295.412994 303.338379,297.525604 299.878052,299.747925 
	C299.168579,300.041809 298.594269,299.818024 298.535431,300.481323 
	C299.197540,300.877930 299.510681,301.307983 299.861511,302.112305 
	C300.070221,303.153809 299.972351,303.849640 299.496338,304.839233 
	C298.878296,305.445374 298.428223,305.643036 297.564331,305.723572 
	C293.207123,303.697479 290.216766,304.527618 288.373657,308.769653 
	C287.530273,310.710785 285.976349,311.853668 284.010712,312.566071 
	C282.003754,313.293396 281.431885,314.900513 281.456268,316.917450 
	C281.484222,319.229950 282.674194,321.144043 284.704987,321.319611 
	C290.947815,321.859314 294.137482,326.147797 297.612305,330.805115 
	C303.387573,331.223328 307.942627,334.354858 313.048065,335.745453 
	C314.188019,336.055908 315.304443,336.604889 315.389465,338.002197 
	C315.481750,339.519531 314.500427,340.468536 313.311066,341.158386 
	C310.487701,342.795959 307.542267,342.642639 304.669281,341.389221 
	C300.870850,339.732025 297.272400,337.642609 293.052216,337.018951 
	C290.944794,336.707520 289.609436,335.011536 288.787781,333.124054 
	C287.049469,329.130951 283.734192,329.346558 280.335419,329.858765 
	C278.968628,330.064758 277.726715,330.722748 277.700470,332.372925 
	C277.676147,333.904755 278.521515,335.011108 280.038544,335.124634 
	C284.971466,335.493652 287.648743,337.718567 287.595886,343.002167 
	C287.570953,345.492432 289.823456,346.026337 291.907013,346.272980 
	C293.746643,346.490692 295.552856,346.064972 297.375824,345.959473 
	C299.075317,345.861084 300.858948,345.888641 301.536865,347.863464 
	C302.154968,349.664032 301.186157,351.076019 299.960663,352.302887 
	C298.322845,353.942596 296.237976,354.470490 294.023773,354.478760 
	C289.048340,354.497345 284.029022,355.060486 279.165710,353.438568 
	C277.521759,352.890259 275.889771,353.133942 274.242706,353.483368 
	C271.430420,354.079956 268.835236,353.425018 268.636993,350.286865 
	C268.433838,347.071442 271.209625,346.958313 273.667816,347.099152 
	C275.287903,347.192017 276.939331,347.598450 278.821960,346.490967 
	C277.053406,343.443481 274.064301,342.343903 271.444092,340.791534 
	C266.597321,337.920074 266.357605,336.312714 270.302704,332.193237 
	C271.400665,331.046692 272.291077,329.953400 271.797882,328.262695 
	C271.310760,326.592712 270.063629,325.891998 268.458099,325.723328 
	C266.144867,325.480225 263.829956,325.156097 261.495789,325.460907 
	C259.340637,325.742310 258.115417,327.441803 256.567413,328.670868 
	C253.882065,330.802948 256.962128,331.626801 257.523621,332.993011 
	C257.908875,333.930511 258.272278,334.867645 258.280701,335.863800 
	C258.301849,338.362091 259.602661,339.429474 261.995514,339.538269 
	C263.325104,339.598724 264.639923,339.877441 265.806732,340.596252 
	C267.019226,341.343170 267.883911,342.320343 267.578796,343.857178 
	C267.244843,345.539368 265.885956,345.781281 264.507263,345.748657 
	C262.013397,345.689728 259.505951,345.671906 257.034973,345.335785 
	C251.119110,344.530975 245.911667,340.982391 239.781281,340.865417 
	C238.621170,340.843323 237.813705,339.710388 237.393982,338.155945 
	C237.125565,336.568207 239.444946,334.933411 237.182755,334.186768 
	C235.146744,333.514801 234.566345,335.334534 234.294037,337.371765 
	C234.170624,338.898956 234.294479,340.049988 234.575821,341.190125 
	C235.341995,344.294830 234.306122,346.072113 230.927933,346.059814 
	C228.726303,346.051788 226.969772,346.878265 226.113907,348.946503 
	C225.099518,351.397827 223.398849,352.923981 221.023071,354.102997 
	C218.371780,355.418762 217.913986,357.220428 221.183365,358.486938 
	C223.282959,359.300262 224.954117,360.565063 226.255524,362.361816 
	C227.793015,364.484528 230.127457,364.788330 232.453156,365.038544 
	C234.000626,365.205078 235.155441,364.646606 234.971680,362.845428 
	C234.418716,357.425293 238.140228,355.984894 242.267563,355.170471 
	C245.699280,354.493347 249.026291,354.208160 251.002350,350.506195 
	C252.072357,348.501648 254.468140,348.920990 256.442963,349.677521 
	C259.269257,350.760193 261.753418,352.392548 264.060638,354.351227 
	C269.202606,358.716339 274.730286,360.553040 281.582581,358.209473 
	C287.626740,356.142303 294.120941,358.212830 300.421783,357.136108 
	C305.628418,356.246368 310.974670,356.323975 316.319061,357.166779 
	C320.268097,357.789551 324.435760,357.245331 327.971100,355.294189 
	C331.504303,353.344147 335.389435,353.603485 339.053223,352.616547 
	C341.444824,351.972321 342.951630,354.079895 345.002136,355.512817 
	C346.215302,359.467285 343.908539,361.483612 341.034027,363.550201 
	C330.872284,363.782410 320.898254,363.110870 311.383301,363.887970 
	C299.294342,364.875275 287.218018,365.262665 275.129364,365.815125 
	C273.461548,365.891357 271.776154,366.088867 270.179565,365.502686 
	C261.847565,362.443573 253.454147,365.724579 244.028549,364.417694 
	C246.080399,366.872986 248.109283,366.895294 249.747986,368.268280 
	C250.380295,368.970337 250.563690,369.459564 250.535492,370.384918 
	C249.919006,372.222351 247.970108,372.435883 247.552307,373.995728 
	C247.752274,375.091583 248.672592,375.219360 249.441025,375.301819 
	C253.516647,375.738983 255.966171,377.096741 253.443085,381.680298 
	C252.597275,383.216797 253.153839,384.163513 254.878143,384.543365 
	C260.336487,385.745819 266.249573,384.927277 271.244843,388.253845 
	C271.771423,388.604553 272.615356,388.310760 273.026306,387.726593 
	C273.590424,386.924683 273.227356,386.127533 272.681519,385.466064 
	C271.219910,383.694794 269.207275,382.928192 267.025543,382.586456 
	C264.002747,382.113037 261.232300,381.045044 258.802094,379.192322 
	C257.624420,378.294434 256.155884,377.301605 256.780640,375.561890 
	C257.472260,373.636017 259.355743,373.690430 261.025024,373.775513 
	C263.599854,373.906738 265.835632,375.120972 267.979034,376.384003 
	C271.411743,378.406860 274.840790,378.521942 278.365540,376.986176 
	C278.919830,375.641388 277.761810,375.119049 277.337494,374.293732 
	C275.610107,370.934082 277.501862,367.488281 280.902985,368.578156 
	C288.279449,370.941925 294.478394,368.566681 300.987915,365.664185 
	C306.093292,363.387726 309.735321,366.155792 309.822754,371.827698 
	C309.855896,373.977051 311.475891,373.418304 312.526245,373.814697 
	C314.873291,374.700500 318.955109,373.766418 319.083466,376.949707 
	C319.214111,380.189362 315.053833,379.990570 312.746521,380.429443 
	C306.244751,381.665955 299.727417,382.765015 293.325928,384.495605 
	C291.453796,385.001678 289.373993,384.916504 288.058838,387.400879 
	C291.370087,388.912109 294.425262,388.891174 297.124329,386.933929 
	C300.984253,384.134949 305.262634,384.347504 309.588440,384.604858 
	C311.420013,384.713806 313.467957,384.970215 313.667969,387.395691 
	C313.852173,389.629456 312.281799,390.748749 310.366547,391.140839 
	C301.769836,392.900757 293.473846,396.373932 284.419312,395.588135 
	C282.116455,395.388275 279.765198,395.682434 276.686584,397.077911 
	C280.168457,397.706726 282.896118,397.261780 285.537903,397.588257 
	C290.015015,398.141449 291.213562,400.740875 288.776581,404.516998 
	C284.899323,410.524902 274.937134,412.356964 268.833191,408.135742 
	C267.057037,406.907440 265.477356,405.410248 263.581635,404.330475 
	C261.993195,403.425781 260.207245,402.524780 260.378784,400.295319 
	C260.548920,398.084564 262.401672,397.444519 264.123749,396.764221 
	C264.586914,396.581299 265.064850,396.421631 265.397034,396.014771 
	C258.344788,393.987976 250.070496,400.184174 249.664200,408.179993 
	C249.435333,412.684570 249.426285,416.860962 244.188889,418.677368 
	C242.873016,419.133759 242.761978,420.822632 242.285767,422.055359 
	C241.869431,423.133148 241.526245,424.373688 240.048508,424.384155 
	C238.661530,424.393982 237.872528,423.350311 237.568695,422.254364 
	C236.470078,418.291260 233.656494,417.332092 229.971390,417.368042 
	C226.904556,417.398010 224.857498,415.275299 222.663605,413.459534 
	C218.484039,410.000336 215.289490,410.534180 212.363190,415.067749 
	C210.413773,418.087891 209.930664,421.539276 209.295547,425.000366 
	C208.491135,429.383850 207.892166,434.096680 202.169373,435.679993 
	C200.131424,434.994202 198.477020,434.398743 197.390778,437.055603 
	C193.815552,437.553406 190.715790,434.736694 186.944336,436.388519 
	C182.339722,433.724335 177.103622,432.477966 174.796631,427.278900 
	C174.464691,426.530792 173.426392,426.374786 172.597565,426.826233 
	C171.843338,427.237030 171.278610,427.877228 171.352386,428.796814 
	C171.429871,429.762634 172.196136,430.146484 173.011490,430.375732 
	C176.058975,431.232666 179.393143,431.777863 178.916687,436.529541 
	C178.361603,438.219727 177.642014,439.438629 176.681610,440.915161 
	C175.715164,442.150909 173.523575,441.999207 173.870178,443.592529 
	C174.421326,446.126312 177.205719,445.649963 179.165833,446.888916 
	C179.722336,448.785309 179.886795,450.465485 182.356033,450.110504 
	C184.370026,449.820984 185.846527,450.884735 186.900894,452.566895 
	C188.624573,455.316864 187.945679,457.312866 184.830353,458.199219 
	C182.968033,458.729095 180.698730,458.436005 180.039322,461.302307 
	C179.901489,461.913055 179.933914,462.236084 180.380447,462.621094 
	C186.508713,463.579468 189.532562,459.501740 193.062256,455.571899 
	C195.648926,454.833038 197.826324,455.437714 200.359161,456.057739 
	C202.111008,457.115021 203.287704,458.346527 204.997864,459.449890 
	C205.668198,462.350342 206.761002,463.579285 209.462677,461.846161 
	C210.436203,461.221680 211.519073,460.754608 212.373932,459.957520 
	C213.578735,458.834106 215.136902,457.744690 214.777481,455.875275 
	C214.390121,453.860596 212.476654,453.749176 210.843460,453.488739 
	C209.535583,453.280151 208.196594,453.227722 206.696945,452.356628 
	C204.699966,450.142212 202.557938,448.484528 201.284515,446.173340 
	C199.657959,443.221222 199.636124,440.720459 204.223633,440.258575 
	C205.810898,440.498444 206.282196,442.951660 207.655350,441.834412 
	C208.949341,440.781494 207.166183,439.964203 206.486160,438.757690 
	C205.909180,434.437714 207.141739,431.885590 211.722809,432.141632 
	C213.299881,432.229767 214.701889,431.798981 215.489304,430.239410 
	C217.067673,427.113220 219.853592,426.496826 223.032257,426.423615 
	C229.629059,426.271790 236.300598,427.145966 242.639450,424.058289 
	C244.814407,422.998901 247.276077,424.227020 248.797180,426.274200 
	C250.771072,428.930725 250.013916,431.614258 248.594116,434.373901 
	C250.206146,432.638123 252.229095,431.788635 254.498917,431.614777 
	C258.819153,431.283875 263.150146,431.312988 267.470673,431.574371 
	C269.110931,431.673615 271.049133,431.846954 271.368896,433.937897 
	C271.636993,435.691071 270.223267,436.660767 268.780396,437.184113 
	C266.523407,438.002777 264.947083,439.846924 262.914276,440.901337 
	C258.998962,442.932129 258.297577,445.469879 260.219910,449.440918 
	C262.528748,454.210388 261.932465,458.813385 258.054108,462.762207 
	C256.446381,464.399170 254.678360,465.519318 252.302109,464.996155 
	C249.700760,464.423401 247.866837,462.443573 247.991180,460.107483 
	C248.344650,453.467621 244.978180,450.194122 239.350372,447.498047 
	C236.408356,446.088654 234.437363,444.680725 231.442184,446.942535 
	C229.836945,448.154755 227.662338,447.988861 225.633362,447.858887 
	C224.095291,447.760406 222.396179,447.347687 220.892090,449.149689 
	C223.284760,450.050079 225.077225,448.957550 226.913620,448.530579 
	C229.314896,447.972260 231.262329,448.581879 232.422882,450.794220 
	C233.703323,453.235077 232.984589,455.391571 230.844437,456.933929 
	C228.140686,458.882507 225.260056,460.555939 221.995117,461.445374 
	C220.243317,461.922638 218.433792,462.349548 217.846802,464.354340 
	C218.586655,465.579559 219.724747,465.559235 220.593445,465.325989 
	C226.909622,463.630280 233.381943,463.863556 239.815643,463.465302 
	C242.377472,463.306732 244.755493,463.204498 247.096100,464.334351 
	C252.949493,467.159912 259.029938,467.091705 265.104309,465.380463 
	C268.130859,464.527832 271.001465,464.568481 273.931152,465.470978 
	C276.526703,466.270447 278.384949,465.952911 279.240143,462.868713 
	C279.996033,460.142426 282.234711,458.949219 284.976013,458.924164 
	C287.580963,458.900360 289.335724,460.286835 290.380768,462.643341 
	C290.649658,463.249725 290.375549,462.481079 290.564392,463.105316 
	C291.395813,465.853943 295.504456,467.976318 292.358093,471.491791 
	C289.370087,474.830231 285.258240,474.778351 281.713959,473.464386 
	C277.988892,472.083405 274.650055,471.988037 271.069092,473.355865 
	C275.530121,475.035706 280.146515,473.930023 284.697815,474.009003 
	C287.154205,474.051575 290.379089,473.509003 290.529175,477.001404 
	C290.698181,480.933929 287.131958,480.341400 284.625854,480.513489 
	C281.308075,480.741333 277.968475,480.478729 274.642761,480.649323 
	C272.968201,480.735229 271.399323,481.018188 270.239349,482.539093 
	C265.018433,489.384369 264.994476,489.366089 257.398071,485.223389 
	C254.799515,482.913025 253.959869,480.211273 253.418274,477.206085 
	C253.070831,475.278259 253.572571,472.238953 249.709869,472.550171 
	C248.068848,473.307709 246.794022,474.055634 245.325562,475.089813 
	C244.601959,475.635895 244.243225,476.030182 244.936569,476.834412 
	C247.148026,478.113800 249.160751,479.090302 251.666412,479.852112 
	C254.382980,483.153503 254.027832,485.473267 250.662766,486.732300 
	C246.704468,488.213348 242.893890,487.495605 239.453873,485.045258 
	C238.330750,484.245209 237.440750,483.093445 237.871460,481.691559 
	C238.641296,479.185913 237.476486,477.225830 236.282043,474.838135 
	C235.924545,474.031097 235.777527,473.552643 235.612259,472.702087 
	C235.050446,470.832825 234.925659,468.535339 232.813950,468.917023 
	C230.934311,469.256805 230.997070,471.401825 231.127228,473.071320 
	C231.332626,475.705872 231.527222,478.058746 234.496750,479.639465 
	C238.242630,481.633484 238.092834,483.527710 234.132828,485.378632 
	C229.031647,487.762878 223.982468,489.912689 218.469589,485.335876 
	C215.543350,482.906494 211.889175,484.561035 208.629333,486.989197 
	C206.120087,487.706177 203.974899,487.706299 201.468811,486.933624 
	C198.940262,484.627808 196.065063,484.290009 193.295349,484.084290 
	C188.365341,483.718140 184.019577,481.653595 179.564224,479.871674 
	C178.191925,479.322845 176.550247,478.730042 176.667679,476.897430 
	C176.795135,474.908386 178.532242,474.338165 180.144455,473.836273 
	C180.776459,473.639496 181.460785,473.446838 182.106567,473.558044 
	C188.208145,474.608734 194.329483,473.095978 199.235535,473.508820 
	C193.075424,473.472046 185.679504,474.487701 178.362259,473.016418 
	C177.192596,472.781281 176.499008,473.472321 175.959290,474.375763 
	C174.116470,477.460541 171.315109,477.801178 167.813812,476.993774 
	C163.854919,471.546570 158.562653,475.876892 154.074753,474.405945 
	C157.339340,475.680756 160.706802,473.601837 164.020111,475.642700 
	C165.072418,478.590088 164.227661,481.018799 162.710709,483.632507 
	C158.469009,485.193359 154.510529,484.838348 150.158417,484.125366 
	C147.101700,482.945892 148.018387,479.033691 144.789062,477.962463 
	C143.835663,477.194672 143.488571,476.520782 143.782745,475.332642 
	C145.309418,473.507294 146.624573,472.019043 147.927399,470.520081 
	C148.638504,469.701965 149.055069,468.798340 148.143692,467.888916 
	C147.156982,466.904388 145.971542,466.791779 145.148376,467.972626 
	C142.871002,471.239594 139.513184,471.147675 136.165741,470.981934 
	C129.839127,470.668610 123.650024,472.684265 117.305740,472.010956 
	C115.410606,471.809845 112.958946,472.509888 113.094200,469.305573 
	C113.214867,466.446625 115.496956,466.939209 117.188271,467.045776 
	C120.973076,467.284210 121.932098,465.480255 122.085297,461.954285 
	C122.422218,454.199707 124.384506,446.785126 128.268478,439.986359 
	C129.943130,437.054962 128.839401,434.814514 125.318855,433.969330 
	C117.590515,432.113983 109.744102,433.441925 101.970512,432.953552 
	C100.365997,432.852753 97.539322,434.056091 97.493233,431.447937 
	C97.450119,429.007507 100.246826,429.086975 102.213448,429.017151 
	C111.018417,428.704590 119.735596,430.664368 128.562958,430.023071 
	C132.190231,429.759583 135.801163,430.074310 139.454269,429.205505 
	C144.016571,428.120453 147.316284,431.192017 148.011719,436.437531 
	C148.797012,442.360779 150.699478,444.070679 154.450104,442.249054 
	C156.896820,441.060669 157.842575,439.443634 155.677277,437.243439 
	C153.701141,435.235504 152.492188,432.675537 152.346512,430.137878 
	C152.081024,425.513214 149.286697,425.478027 146.218948,426.227814 
	C142.694504,427.089172 139.506226,427.404022 136.167374,425.410370 
	C134.909805,424.659485 132.900543,424.892822 131.277557,425.072144 
	C118.750351,426.456207 106.677322,424.325623 94.850830,420.411713 
	C93.145462,419.847321 91.165024,420.663116 89.082321,418.707062 
	C94.763565,415.544617 100.602539,417.656677 106.533745,417.000000 
	C108.042435,418.193146 109.001411,419.989227 110.774025,417.243469 
	C115.393539,419.655243 120.259659,419.008209 125.029182,418.972626 
	C126.139465,418.964325 127.620941,419.119904 127.618507,417.430420 
	C127.616608,416.107788 126.353859,416.216644 125.392471,416.000122 
	C119.467415,414.665955 113.485191,413.516663 107.665039,411.816254 
	C104.965004,411.027405 102.937263,408.338989 99.683777,408.585632 
	C99.342567,408.611481 98.703369,407.718506 98.599434,407.175842 
	C98.432068,406.302063 99.306229,406.217712 99.930984,406.056122 
	C101.206619,405.726074 102.564064,405.588196 103.746941,405.057312 
	C105.024353,404.484039 106.982697,404.165985 106.727043,402.221771 
	C106.456039,400.160828 104.859734,399.220490 102.827286,399.024200 
	C99.367432,398.689972 95.912140,398.307068 92.450516,397.993317 
	C91.245308,397.884064 89.808022,397.782349 89.596222,396.483521 
	C89.307419,394.712463 91.079330,394.178986 92.253639,394.198669 
	C96.846077,394.275665 101.074272,391.018372 105.895683,392.910645 
	C107.183533,393.416046 108.867172,393.013641 110.355652,392.879150 
	C111.870110,392.742279 112.779442,391.821930 112.863998,390.269775 
	C112.962303,388.465363 111.773926,388.086914 110.280869,388.005371 
	C107.748062,387.867096 104.686996,387.706085 104.337051,384.698914 
	C103.957947,381.441101 106.520615,379.724274 109.568321,379.059021 
	C110.778870,378.794800 111.910301,378.529877 111.831200,376.968231 
	C111.735878,375.086365 110.317513,375.009827 108.951210,375.022675 
	C105.130066,375.058655 101.306465,375.166809 97.514160,373.315491 
	C98.343361,371.246368 100.090805,371.052948 101.882416,371.016724 
	C104.047729,370.972900 106.215965,371.046906 108.380028,370.978394 
	C109.633072,370.938751 111.520515,371.375671 111.592834,369.562531 
	C111.668457,367.666260 109.756416,368.179230 108.520096,367.996155 
	C105.124733,367.493347 101.616653,367.403595 98.047638,365.264465 
	C101.676910,362.492767 105.677002,362.457306 109.433380,362.062134 
	C116.743439,361.293182 117.992439,360.127472 117.999626,352.874847 
	C118.001114,351.375214 117.978531,349.875153 118.007111,348.376038 
	C118.088913,344.086273 120.334221,342.007263 124.579750,342.002136 
	C126.579262,341.999695 128.615845,342.246124 130.570908,341.951050 
	C135.246445,341.245331 137.569992,343.444214 137.712479,347.809204 
	C137.857224,352.242889 140.710190,352.936340 144.045288,352.951752 
	C148.545975,352.972565 153.175079,352.176392 154.248138,358.629486 
	C154.589691,360.683380 157.029068,359.871582 158.599091,359.987610 
	C163.721146,360.365936 168.870972,359.414429 174.006561,360.725250 
	C176.581100,361.382385 177.067673,362.683014 177.035141,364.945465 
	C177.003922,367.115234 176.867630,368.787537 174.105957,369.228149 
	C172.071228,369.552765 169.860718,370.051910 169.955093,373.177246 
	C170.041748,376.047119 172.186432,376.113159 173.952972,376.798615 
	C174.989090,377.200623 176.309128,376.964264 176.820999,378.387482 
	C177.633423,380.646423 177.254166,383.706421 180.038651,384.750488 
	C185.670349,386.862152 191.546646,387.699371 197.543137,386.845917 
	C199.548111,386.560547 200.686066,384.935760 199.369690,383.234192 
	C197.397141,380.684387 195.515396,377.928986 191.470901,377.925018 
	C184.786407,377.918488 184.112015,377.079529 184.021744,370.120392 
	C183.928818,362.956696 183.913040,355.789276 184.039261,348.626587 
	C184.096481,345.379822 183.539978,343.885162 179.769653,342.438477 
	C170.912842,339.039948 162.047546,337.468231 152.671509,337.952362 
	C151.223373,338.027130 149.750580,337.443726 148.277405,337.258331 
	C146.225357,337.000122 144.882324,336.286194 144.967850,333.834534 
	C145.047974,331.537140 145.090988,329.374359 147.952682,328.892365 
	C151.519241,328.291718 152.395233,326.078094 152.029266,322.786926 
	C151.477448,317.824432 152.170349,313.388885 157.782410,311.491699 
	C158.218048,311.344421 158.797882,310.905823 158.873093,310.517822 
	C160.015396,304.624329 166.087097,305.247223 169.605682,302.512482 
	C170.679855,301.677612 173.245651,301.510193 174.375763,302.225739 
	C178.316620,304.720886 182.829330,303.475098 186.929459,304.721527 
	C190.087875,305.681732 192.128555,304.451569 192.006271,300.744354 
	C191.868652,296.572174 191.814362,292.427856 194.434738,288.764404 
	C195.467865,287.319977 194.567993,286.086395 193.146210,285.645447 
	C188.939697,284.340912 184.711014,283.071014 180.422897,282.083221 
	C178.472687,281.633942 176.478699,283.029510 175.855652,284.535797 
	C175.036057,286.517303 177.604813,286.924408 178.802795,287.816650 
	C180.118683,288.796814 181.763428,289.297638 182.472626,291.014740 
	C183.343262,293.122650 183.066544,295.096619 181.306778,296.416565 
	C179.867172,297.496338 178.380692,297.006927 176.845551,295.770844 
	C173.160217,292.803436 170.547470,293.296478 167.963379,297.043335 
	C164.243439,302.437195 156.211288,302.021179 152.798889,296.213379 
	C151.823547,294.553345 150.272903,294.253265 148.615891,293.930695 
	C147.137238,293.642914 145.616257,293.235565 145.179001,291.495117 
	C144.973145,290.675720 145.076950,289.767334 146.002197,289.381317 
	C147.559479,288.731567 148.497757,290.018646 149.518631,290.748444 
	C153.842957,293.839874 158.769989,292.887054 163.544907,292.969421 
	C164.397659,292.984100 165.363602,292.839508 165.443542,291.818054 
	C165.513580,290.923309 164.674866,290.456604 163.955978,289.998169 
	C162.312424,288.950043 160.452530,289.122894 158.644943,288.899506 
	C156.655167,288.653564 155.088837,287.302673 154.621796,285.570282 
	C154.073975,283.538300 156.449310,283.764099 157.538513,282.950623 
	C168.112549,275.053192 180.435989,274.760406 192.768646,275.116516 
	C197.480087,275.252594 201.418411,273.300140 205.696945,272.231293 
	C207.810608,271.703217 208.008575,270.212341 208.016998,268.419159 
	C208.030212,265.605988 208.041016,262.837891 208.718918,260.010956 
	C209.828751,255.382797 212.644760,252.971756 217.121429,253.089874 
	C222.918610,253.242828 228.152817,251.587616 233.394577,249.486450 
	C237.056534,248.018570 238.606216,249.894318 238.997696,253.422226 
	C239.586227,258.725922 245.734421,263.481079 251.174026,263.044220 
	C253.162125,262.884552 255.176422,263.059296 257.166534,262.913971 
	C258.993866,262.780548 260.370911,261.727051 260.728821,259.931305 
	C261.160431,257.765686 259.354248,257.057770 257.806274,256.504456 
	C255.232956,255.584671 252.545670,255.933365 249.901688,256.232422 
	C249.087631,256.324493 248.293701,256.886353 247.504868,256.856445 
	C244.776276,256.752869 241.177155,255.853317 241.072891,253.470871 
	C240.964432,250.992828 244.559402,252.124908 246.523987,252.055176 
	C251.183365,251.889755 255.986847,251.332901 260.479156,252.217987 
	C264.616364,253.033112 267.931366,253.826843 272.497345,252.408646 
	C278.636230,250.501923 285.628143,251.344620 292.253540,250.999939 
	C296.225250,250.793320 297.916443,252.615036 298.047760,256.640350 
	C298.581909,273.014069 298.651642,273.008820 282.311371,273.001770 
	C277.243744,272.999573 272.504211,272.890381 269.425323,267.486511 
	C268.126862,265.207520 265.102905,264.315643 262.007874,266.281586 
	C257.260895,269.296814 252.245316,270.507324 247.196915,266.621674 
	C244.385956,264.458099 240.819885,264.892029 237.619400,264.316376 
	C235.175171,263.876740 233.757477,266.404724 233.513168,268.545532 
	C233.196991,271.316193 235.686417,270.879578 237.425369,270.986328 
	C242.160950,271.276917 247.002731,270.181854 251.543793,272.790039 
	C253.039444,273.649109 255.134384,273.013275 256.823456,272.085022 
	C260.105438,270.281403 263.668396,271.127502 267.124847,271.091888 
	C268.320557,271.079529 269.564209,271.554901 269.503204,273.065857 
	C269.443573,274.542999 268.193481,274.856354 266.994019,274.947632 
	C265.501526,275.061218 263.961548,274.866241 262.511932,275.155029 
	C260.319366,275.591827 258.772400,277.034241 259.221741,279.374268 
	C259.667938,281.697754 262.151154,281.451141 263.317230,280.825836 
	C273.254181,275.496918 283.986511,277.233734 294.495300,276.988190 
	C296.563934,276.939819 298.401794,276.008331 298.671967,274.096924 
	C299.168762,270.581879 301.100433,269.720703 304.194885,269.977814 
	C306.228363,270.146759 307.864258,269.270111 308.613373,267.383850 
	C309.489624,265.177490 307.086700,265.336945 306.206421,264.364105 
	C304.319977,262.279358 303.981293,259.631561 303.997681,256.921478 
	C304.011780,254.588928 304.000214,252.256180 304.000000,249.462677 
	C303.919922,247.643723 304.883179,247.239304 305.885071,247.355911 
	C310.312592,247.871140 314.771149,246.766464 319.553528,247.772110 
	C320.374451,248.068024 320.838562,248.262375 321.179443,248.607758 
	C325.499817,252.984940 325.496735,252.987976 329.145630,247.411133 
	C333.533600,245.764542 337.012054,246.870575 340.301636,250.055771 
	C341.127441,252.326385 340.622925,254.207184 340.244385,256.481384 
	C339.775574,257.876923 339.184692,258.690887 337.806152,259.231262 
	C335.433350,259.398285 333.165070,256.809784 331.363403,260.233215 
	C331.381012,264.168945 333.598877,264.283173 335.098694,262.046112 
	C337.214478,258.890411 339.721893,258.962494 343.069702,259.508667 
	C344.888123,259.345032 346.353333,259.468292 348.191406,259.517059 
	C351.891541,259.491364 354.097473,261.715546 356.862549,263.639038 
	C357.827393,264.166565 358.427582,264.585083 359.386414,265.100464 
	C361.737518,266.954010 363.763611,268.651855 366.571075,268.859924 
	C369.476501,269.075226 369.625732,272.573120 372.349121,273.445526 
	C374.623871,273.327545 376.546936,273.459045 378.823608,273.472900 
	C380.129913,273.470703 381.536011,273.822449 381.798523,272.823212 
	C382.098969,271.679413 380.672913,271.552307 379.809967,271.265961 
	C379.036774,271.009430 378.270538,270.720154 377.122620,270.528687 
	C376.307037,270.505035 375.838013,270.505157 375.011383,270.530518 
	C371.805084,271.417206 369.255981,271.139252 367.861694,268.169830 
	C366.544037,265.363617 363.705322,265.777344 361.193359,264.587219 
	C360.499786,263.974609 360.246399,263.513763 360.053528,262.589050 
	C360.044556,261.631653 360.209656,261.119568 360.750763,260.324158 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M377.308777,830.323853 
	C376.127228,826.699768 373.353760,827.383179 370.965485,826.947632 
	C366.181244,826.075134 360.348053,827.480164 357.877350,821.148376 
	C357.314667,819.706360 355.616882,820.014832 354.306244,820.008545 
	C350.483704,819.990234 346.660858,820.020325 342.838409,819.993774 
	C338.786560,819.965637 335.177948,819.226257 334.246185,814.386169 
	C333.844971,812.301941 332.360687,811.958313 330.520416,811.985779 
	C326.532501,812.045288 322.541901,811.940491 318.554504,812.015015 
	C314.866943,812.083984 313.122101,810.488708 312.960999,806.691895 
	C312.571014,797.502136 312.048950,797.574341 304.037415,801.991577 
	C300.801117,803.775879 296.366302,803.395386 292.473450,803.977173 
	C289.551392,804.413940 286.623810,804.814209 283.166534,805.306580 
	C284.676605,807.814697 287.155396,807.474121 288.799622,808.538269 
	C289.639465,809.081848 290.612244,809.790588 290.464569,810.845886 
	C290.285034,812.129028 289.016022,811.934265 288.047852,811.989990 
	C283.091217,812.275146 278.114471,811.529663 273.150604,812.557190 
	C271.542908,812.890015 269.883392,811.810852 269.250549,810.052734 
	C268.633759,808.339172 269.507629,807.158325 271.059143,806.475403 
	C273.221771,805.523499 273.014130,803.635254 272.998901,801.402466 
	C271.883362,797.259033 274.969666,797.811401 276.784058,797.596375 
	C280.408478,797.166931 284.069763,797.610962 287.716644,797.697327 
	C290.169922,797.755432 292.636108,797.614807 295.882324,796.847961 
	C290.495300,793.990662 285.839722,797.680420 281.019043,796.210938 
	C278.685486,791.587952 276.289001,793.997559 273.659485,795.889954 
	C272.511200,796.707336 271.587799,796.891052 270.296326,796.231445 
	C269.008636,795.937744 267.976746,796.021423 267.033264,795.771484 
	C265.816223,795.449097 264.927338,794.589172 265.303101,793.201904 
	C265.660065,791.884155 266.762909,792.402710 267.741577,792.347290 
	C271.214020,792.151001 274.407349,791.147156 276.950470,788.563538 
	C279.140350,786.338806 279.690887,784.618103 275.779633,784.014160 
	C274.662689,783.841736 273.186768,783.998047 273.370972,782.289856 
	C273.506836,781.029968 274.751404,781.035339 275.741150,781.024719 
	C280.543915,780.973328 285.236115,779.387207 290.653076,780.323364 
	C290.322998,783.482056 287.898102,783.196289 285.719666,783.404297 
	C287.569824,783.866943 289.302582,782.882446 291.465332,782.821411 
	C294.408844,783.416260 296.047913,782.967346 296.851685,779.869629 
	C297.690796,776.635742 298.092743,774.091125 293.995453,772.582214 
	C292.501068,770.875366 292.805481,768.845215 291.925995,767.248169 
	C291.002899,765.572083 289.701843,764.689270 287.890656,766.009766 
	C286.395355,767.099976 286.168213,768.368286 287.760620,769.654785 
	C288.954010,770.618896 290.149841,771.608093 288.729980,773.595337 
	C286.471008,774.716614 284.450073,774.647949 282.056152,774.047485 
	C281.275970,773.673950 280.827148,773.400452 280.612518,772.964111 
	C277.688446,767.019958 276.835693,766.845459 271.585358,771.253052 
	C273.207123,772.661926 275.534760,771.240967 277.315155,772.949890 
	C277.830261,773.974060 277.810303,774.699890 277.245911,775.709351 
	C272.307404,777.243958 267.943787,776.607361 263.707428,774.132690 
	C262.049011,773.163879 259.308868,772.364685 258.669708,774.944519 
	C258.117889,777.171753 260.762054,776.848938 262.327728,777.006104 
	C265.479645,777.322632 266.977142,779.826050 267.649078,782.195129 
	C268.314819,784.542480 265.888153,786.206787 263.953003,786.875977 
	C260.925629,787.922913 259.041443,789.980225 257.118286,792.285522 
	C256.087067,793.521606 254.491272,795.260681 253.250366,795.182495 
	C247.251495,794.804932 243.265396,798.973267 238.373093,801.055847 
	C236.049561,802.045044 234.971298,803.524719 234.998825,805.973816 
	C235.023132,808.137451 235.009094,810.302002 234.975372,812.465637 
	C234.924271,815.745789 232.557739,816.588074 229.968903,816.924438 
	C227.491745,817.246216 225.502502,816.903625 224.868362,813.676331 
	C224.263031,810.595642 215.041275,804.767273 212.125320,805.226990 
	C210.786011,805.438171 209.223694,806.013428 209.214386,807.519043 
	C209.175598,813.798645 205.065964,812.006348 201.291687,811.274658 
	C200.543869,809.267822 199.843765,807.605835 199.616074,805.501282 
	C202.035019,803.512939 203.377853,802.161377 200.754181,799.159119 
	C198.634720,796.733765 199.620102,793.593201 202.705582,792.088318 
	C205.260056,790.842407 206.967972,789.129028 208.135895,786.569763 
	C209.459686,783.669006 212.268860,783.411072 214.983383,783.528870 
	C219.581192,783.728271 223.044113,782.961548 223.983780,777.377014 
	C224.554550,773.985107 229.136581,772.598328 233.135300,773.860291 
	C236.976868,775.072632 235.858078,777.796265 235.142944,780.382690 
	C234.608292,782.316406 234.365051,784.300171 234.046127,786.271057 
	C233.913895,787.088135 233.781860,787.920471 234.108505,788.751038 
	C235.121948,789.108826 235.199097,788.210144 235.365341,787.686462 
	C236.576675,783.871033 238.808014,783.061829 242.182678,785.217285 
	C243.125031,785.819153 244.193878,786.858276 245.306717,785.831787 
	C246.261520,784.951050 245.760818,783.640625 245.538544,782.516418 
	C245.310837,781.364746 245.006470,780.138062 244.098007,779.385437 
	C239.971863,775.966675 240.394531,771.038635 239.837204,766.504211 
	C239.381271,762.794739 241.313766,760.316711 245.005814,759.807556 
	C249.122894,759.239868 253.329666,758.862427 257.086090,761.421692 
	C257.773071,761.889771 258.491852,762.326965 259.310303,762.531677 
	C260.749542,762.891663 262.103180,762.693115 262.565430,761.125549 
	C263.008209,759.624084 261.731506,758.960754 260.594818,758.782959 
	C257.322296,758.270752 254.861694,756.270447 252.164062,754.634277 
	C249.193161,752.832214 247.447647,750.497375 247.751175,746.899109 
	C247.929367,744.786682 247.137344,743.270813 244.925247,742.916809 
	C243.055496,742.617676 241.323090,742.723694 242.433624,745.431824 
	C243.524353,748.091797 243.478180,750.614807 241.490158,753.280579 
	C241.014008,753.702820 240.800644,753.849060 240.227173,754.150024 
	C237.880035,754.556396 237.146866,755.850098 236.830246,757.682739 
	C236.125275,761.762939 233.573120,763.506287 229.482742,763.634521 
	C218.659393,763.973755 207.879471,763.339722 197.104446,762.481079 
	C194.112305,762.242737 192.016968,760.800598 191.641953,757.667053 
	C191.146698,753.528870 190.397400,749.068909 191.915619,745.301392 
	C194.385040,739.173584 190.679230,735.538757 187.661591,731.562622 
	C186.384338,729.879700 184.526581,728.623230 183.727478,725.828369 
	C182.303436,730.277954 180.177795,733.363281 176.288895,734.804565 
	C171.195374,736.692383 166.979828,734.495422 166.555786,729.187500 
	C166.208435,724.839783 164.304977,723.402832 160.237885,723.621216 
	C156.744064,723.808777 153.230072,723.573730 149.913651,722.284912 
	C147.489685,721.342896 146.749374,719.815063 148.919312,717.842041 
	C152.377014,714.698303 155.817215,711.766357 161.122391,712.251465 
	C165.590240,712.659912 169.810486,711.104370 173.894394,709.034302 
	C167.162766,707.494263 160.178192,710.268799 153.316513,707.247253 
	C150.262848,704.095459 151.440674,702.499939 154.748718,701.405212 
	C158.480362,700.170410 162.249054,701.223694 165.990250,700.996643 
	C169.085510,700.808777 172.224396,701.473022 175.640366,700.260864 
	C176.843796,699.442932 176.989014,698.587830 176.997757,697.337708 
	C179.080536,694.660461 181.905716,694.493347 184.711319,694.512573 
	C187.746933,694.533386 190.161530,693.858337 191.305679,690.625549 
	C191.933411,688.851929 193.444138,687.700684 195.173172,687.025085 
	C197.522171,686.107239 198.787155,684.301758 199.793671,681.768188 
	C199.986786,680.851624 201.340759,680.393250 199.820587,679.692749 
	C197.184036,680.060913 194.940750,680.650696 192.754456,681.421997 
	C189.911560,682.425049 187.242615,682.262817 184.701996,680.555786 
	C182.876740,679.329346 180.771866,678.883789 178.664337,678.402588 
	C175.514252,677.683289 174.033981,675.945007 175.750793,672.320496 
	C176.469894,671.684570 176.940414,671.369202 177.705170,671.026917 
	C180.428024,669.253296 183.069183,670.115112 185.675873,670.332825 
	C187.803726,670.510498 190.095474,671.141479 191.976288,669.782776 
	C193.580338,668.624146 195.415359,667.113586 192.334305,665.066345 
	C190.285995,665.000000 188.571976,665.000000 186.429596,665.000000 
	C184.347946,663.407654 181.978699,663.816345 180.100754,662.898193 
	C177.459122,661.606689 174.958710,660.155029 177.871124,656.430115 
	C180.046005,655.338196 181.974823,655.447632 184.305237,655.702393 
	C190.724533,657.510803 195.613846,656.041992 199.849640,651.230103 
	C203.433090,647.159302 209.004608,647.671265 213.756973,646.672791 
	C220.018906,645.357178 226.455109,644.510620 233.166824,645.978699 
	C225.387283,644.985657 223.704391,636.289978 217.091492,634.037781 
	C215.555176,633.514587 215.032028,631.517029 214.113312,630.133484 
	C211.017487,625.471313 207.072556,625.417480 204.202667,630.071472 
	C202.949448,632.103882 201.364914,633.338013 199.002716,633.362854 
	C196.954941,633.384460 195.058624,633.994873 193.185181,634.651306 
	C188.531067,636.282288 188.487289,636.474976 191.525146,640.304749 
	C193.343979,642.597778 192.031952,643.588989 189.931473,644.158325 
	C185.262100,645.423889 177.683868,642.611206 174.719223,638.297241 
	C173.216141,636.110046 172.514603,636.596008 171.213394,638.446045 
	C166.684433,644.885254 163.341934,644.894592 158.801010,638.459106 
	C157.446045,636.538879 156.400681,636.086792 155.336609,638.402710 
	C153.340347,642.747681 149.677658,643.618896 145.419952,643.576660 
	C139.592590,643.518860 133.758179,643.760620 127.937973,643.483948 
	C121.032722,643.155579 118.366554,639.861877 118.451096,632.189514 
	C118.529739,625.052185 120.853729,622.690369 128.079025,622.453613 
	C130.408920,622.377197 132.743484,622.362549 135.071991,622.438354 
	C138.948563,622.564514 142.387955,622.471130 143.026947,617.258911 
	C143.331192,614.777344 145.438232,613.852966 148.014771,614.737305 
	C149.357193,615.198120 150.870514,616.966125 152.005447,614.029846 
	C152.068817,613.114441 152.187347,612.598267 152.577911,611.733521 
	C154.791046,609.255127 157.395432,609.386841 160.404846,609.475525 
	C163.602142,609.315796 166.394318,609.351379 169.562836,608.801758 
	C172.461838,608.806274 174.358627,609.632080 174.810349,612.756104 
	C174.428360,615.199768 172.119461,615.324585 170.891937,617.111694 
	C174.189316,619.612183 176.478638,615.791504 179.546280,615.851685 
	C181.315872,615.708862 182.262955,615.263428 181.225616,613.669983 
	C178.256302,609.108765 181.267517,604.301758 180.765854,599.578003 
	C180.459808,596.696411 187.747971,594.057739 191.787125,595.345398 
	C194.985168,596.364807 197.798355,597.893311 200.597702,594.231812 
	C202.026535,592.362854 203.560333,594.029724 204.265915,595.728149 
	C205.086227,597.702637 206.083771,599.424683 208.589981,598.980652 
	C211.072937,598.540649 213.284592,597.802917 214.566864,595.141357 
	C217.383606,589.294678 219.984711,588.419189 226.226624,591.104248 
	C226.553329,593.842468 227.960007,594.685791 230.257080,594.565613 
	C232.519348,594.447266 234.976776,594.740540 235.267807,591.097778 
	C236.535690,588.591187 238.291458,589.088440 240.102188,589.713135 
	C240.987198,590.018433 242.012924,590.899719 242.800034,589.844604 
	C243.619766,588.745789 242.992218,587.642517 242.197189,586.708984 
	C241.347015,585.710632 240.237915,584.845825 240.608551,582.961426 
	C242.775742,580.142578 243.378525,576.045959 248.189819,576.337769 
	C250.224060,577.427551 249.273087,580.584839 252.250946,580.992493 
	C253.216339,582.270813 250.957367,585.430786 254.843994,584.266968 
	C257.435089,583.491089 260.769073,582.514954 261.693420,586.597229 
	C262.407318,589.750244 258.109131,593.812317 254.455261,593.865784 
	C253.262985,593.883240 251.680527,592.701782 251.046631,594.609924 
	C250.588364,595.989319 251.213760,597.530396 252.451294,598.034851 
	C259.801056,601.030701 258.635468,607.714294 259.192444,613.858887 
	C250.573883,613.253601 241.860168,614.925964 233.144852,610.490723 
	C239.955109,617.397766 239.955109,617.397766 248.289764,619.371948 
	C248.403061,622.405579 248.403061,622.405579 243.535126,624.130737 
	C245.928421,625.301514 249.505997,625.127014 250.726074,623.544861 
	C252.483109,621.266357 249.167572,621.301147 248.691681,619.584900 
	C248.441971,615.094360 251.510284,615.185425 253.468658,616.699463 
	C257.978210,620.185730 262.704376,620.705444 267.997833,619.716858 
	C269.623291,619.413330 271.351410,619.708740 272.398682,621.807800 
	C270.285461,625.113159 266.794159,624.342041 263.893860,625.192871 
	C261.206573,625.981201 257.689545,633.816406 259.200714,636.111694 
	C260.292328,637.769836 262.064026,637.567017 263.697266,637.272583 
	C265.048431,637.029053 264.852753,636.078247 264.584015,634.989441 
	C262.790466,627.724365 263.574615,626.317627 270.581085,623.893250 
	C271.053925,623.729675 271.476013,623.473755 272.125336,622.900513 
	C273.074310,622.204712 273.813843,622.168396 274.941162,622.529358 
	C276.921600,624.549255 278.369232,626.543274 279.306702,628.800415 
	C280.172272,630.884460 280.509094,633.027771 277.077454,633.732422 
	C275.272888,633.335266 274.826141,631.983154 274.012695,630.995605 
	C273.143463,629.940247 272.038635,628.784668 270.760651,629.935059 
	C269.141876,631.392273 270.559387,632.538818 271.709686,633.491699 
	C272.208588,633.905090 272.789185,634.224243 273.468872,634.967041 
	C274.106415,636.163208 274.150177,637.105774 273.803101,638.405518 
	C272.961029,640.832458 268.268890,640.405701 269.889435,643.469727 
	C271.545959,646.601746 274.583862,644.113647 277.023834,643.782410 
	C279.457214,643.452148 280.508301,641.508850 281.993958,639.491699 
	C282.526550,639.131958 282.763000,639.013794 283.389862,638.780151 
	C288.952087,638.472656 294.043091,639.216125 299.524170,640.193420 
	C302.360474,642.857422 302.341400,645.620667 300.659790,648.899414 
	C299.037048,650.364136 297.390045,650.479187 295.356293,650.540161 
	C292.092102,650.530579 289.170654,651.621582 286.218018,650.197571 
	C288.284332,651.419373 289.810272,653.025330 291.154633,654.840881 
	C292.024719,656.015869 292.854401,657.429260 295.043091,656.218018 
	C296.347260,655.826111 297.314667,655.821228 298.618225,656.247925 
	C300.985748,657.646118 300.739685,660.206177 302.306763,662.236816 
	C304.055298,659.402649 305.001984,656.264771 308.013885,654.489014 
	C310.657928,652.930054 312.960724,652.592773 315.287109,654.564026 
	C317.557922,656.488281 317.640564,658.971069 315.941040,661.217896 
	C314.594116,662.998657 314.361206,664.859924 315.898010,666.074463 
	C320.944061,670.061890 319.905487,675.362305 319.356720,680.892334 
	C317.294250,682.531189 313.346741,680.991455 313.572327,685.286560 
	C313.687439,687.478149 313.581604,689.804993 316.465546,690.239075 
	C319.019714,690.623657 321.013733,689.662903 322.002838,687.138550 
	C322.783386,685.146545 322.284790,682.827820 323.813538,680.684204 
	C325.289581,679.688965 326.578247,679.642517 328.288422,680.094116 
	C329.653778,680.753235 330.213257,681.628113 330.756226,682.960938 
	C330.971832,683.800049 330.976349,684.300964 331.028687,684.796570 
	C331.125610,685.714783 330.595367,687.238831 331.850159,687.304504 
	C333.585175,687.395203 333.005341,685.680054 333.195526,684.273438 
	C333.335938,683.202393 333.638977,682.556885 334.263245,681.658325 
	C335.718994,680.124268 336.936707,678.866882 338.921692,677.982300 
	C340.320496,677.596741 341.368134,677.553894 342.825989,677.690063 
	C343.760010,677.845032 344.262634,678.033142 345.045746,678.558044 
	C346.007507,679.722412 346.148468,680.734314 345.850708,682.160400 
	C344.865387,683.871521 343.082275,683.899475 342.077209,684.874756 
	C340.663116,686.246948 340.860870,687.244812 343.336304,686.339233 
	C343.643524,685.618408 343.813965,685.191406 344.202667,684.455994 
	C344.590393,683.970520 344.782043,683.802185 345.314392,683.442871 
	C347.727356,682.223999 349.868225,682.395447 352.411316,683.034912 
	C353.800201,683.736694 354.419403,684.582520 354.718842,686.073242 
	C354.453552,688.716736 352.780548,689.727844 350.974426,690.608948 
	C348.718628,691.709473 348.567505,693.124695 349.656860,695.382202 
	C352.098450,700.441772 351.126495,702.521301 345.503876,704.613037 
	C343.626312,703.852966 342.359619,702.783020 340.867981,701.441162 
	C339.922485,699.846619 341.046936,698.630066 340.859558,697.273376 
	C338.754211,696.616577 338.627197,698.589355 337.560608,699.854736 
	C336.412842,700.768860 335.512878,700.593933 334.592773,700.091187 
	C333.767975,699.640503 333.155853,698.686096 331.604614,699.048096 
	C332.999054,702.545776 337.633789,702.992676 338.594330,706.969727 
	C338.462738,708.072693 338.088501,708.682495 337.240479,709.390991 
	C335.417542,710.412292 333.217560,709.309753 331.693146,711.906677 
	C337.444183,713.020691 342.958588,712.323486 348.408600,712.488770 
	C351.278778,712.575745 354.004486,712.157959 356.531128,709.870605 
	C357.115479,709.619812 357.371399,709.563416 358.026276,709.555054 
	C361.484955,710.787476 362.531067,713.169434 362.336029,716.670410 
	C362.185364,717.609314 362.007996,718.111145 361.515778,718.885254 
	C354.969604,720.112244 348.638245,720.779236 341.544159,720.448914 
	C343.316528,721.309937 344.736420,721.860962 344.287354,723.955200 
	C341.717621,726.107910 339.049469,725.339478 336.433105,725.036377 
	C330.484985,724.347656 323.017395,727.993530 320.376038,733.325317 
	C319.212799,735.673462 316.784760,736.136047 314.491669,738.606384 
	C319.718536,739.869263 324.308380,739.569397 328.908325,738.739441 
	C330.865692,738.386230 332.753662,737.598389 334.784149,737.715088 
	C337.472229,737.869690 339.306030,738.895630 338.755859,742.378784 
	C336.555084,745.162964 331.937286,744.180908 330.979126,748.626343 
	C329.209167,751.418640 326.976501,753.685120 330.731628,756.492188 
	C332.155151,757.556213 332.244904,759.733948 329.580719,760.858154 
	C329.080353,760.934998 328.913696,760.938171 328.747375,760.947510 
	C326.864258,761.052979 324.730835,759.722534 323.210083,761.848328 
	C322.924988,762.246826 323.063354,762.867065 323.456848,763.218201 
	C325.157715,764.736633 326.961121,763.143188 329.051270,763.414429 
	C329.701660,763.619629 329.942200,763.842957 330.194427,764.052368 
	C333.304535,766.633057 326.896393,768.791077 330.006348,771.744324 
	C332.513214,774.124756 330.720337,776.977478 329.437225,779.546387 
	C328.085144,782.253296 327.711731,784.948547 330.099457,787.375671 
	C331.813293,789.117737 333.090515,789.450012 333.708710,786.476807 
	C334.391663,783.192139 334.519287,779.885071 334.539612,776.550659 
	C334.556824,773.726562 334.479340,770.838257 337.036011,768.393677 
	C338.906342,767.481201 340.512665,767.489014 342.543182,767.914062 
	C347.549561,771.617188 348.461731,776.932190 349.377228,782.170166 
	C349.851746,784.885071 348.994324,787.742615 350.220459,790.766113 
	C350.557129,791.305298 350.676514,791.525513 350.938293,792.084229 
	C351.146149,798.256226 355.510834,798.775574 360.076843,799.591919 
	C360.598602,799.868958 360.800201,799.995972 361.270386,800.363159 
	C362.384827,801.578735 362.624481,802.726196 362.499146,804.330078 
	C363.790710,808.860718 364.603302,809.177307 367.845917,806.622314 
	C368.727997,805.927307 369.517212,805.133484 371.017029,804.724731 
	C378.412628,804.839722 385.461548,803.682800 392.721863,805.603638 
	C393.976624,808.953979 391.609650,809.105774 389.734253,809.383362 
	C386.791870,809.818909 383.799225,809.481140 380.832916,809.627991 
	C378.756592,809.730774 376.578491,809.715454 375.145142,812.704834 
	C381.062622,811.601440 386.346283,809.388977 392.239990,811.096924 
	C395.393188,814.843323 391.087921,814.459106 389.812775,816.197693 
	C392.409485,814.911438 395.655334,815.472717 396.928894,811.819092 
	C397.718628,810.309875 398.628693,809.478638 400.381531,809.236816 
	C401.293884,809.354919 401.746887,809.594482 402.330688,810.293823 
	C402.908661,811.889343 402.602020,813.116333 402.250916,814.693848 
	C400.882904,818.184814 401.880676,820.017761 405.270142,820.553955 
	C406.847107,820.803467 408.553711,820.844116 409.587677,822.852905 
	C409.791718,824.462646 409.425842,825.610168 408.579102,826.978149 
	C406.627380,829.038147 402.959503,827.578735 402.041565,831.299927 
	C401.031128,833.088928 399.809784,833.953552 397.698669,833.976929 
	C396.544189,833.725952 395.930817,833.302612 395.346039,832.306030 
	C394.941681,830.408508 394.799530,828.823242 394.475525,826.931702 
	C394.718445,825.783936 395.420593,825.222290 395.283508,824.035095 
	C393.939697,825.330933 389.184662,822.799438 391.331787,828.485474 
	C389.742279,830.822449 387.326569,830.348816 384.951477,831.185242 
	C382.178802,831.648254 379.826965,831.902893 377.308777,830.323853 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M845.891174,305.330261 
	C846.252441,302.360657 848.070557,301.981018 850.239380,301.993744 
	C855.180542,302.022736 860.122009,302.001282 865.531677,302.000763 
	C868.344238,304.565125 871.661743,303.179688 874.438782,304.049286 
	C876.314453,304.636627 878.403320,304.320160 880.630737,305.223389 
	C882.537842,307.755524 885.132690,308.446716 887.769775,309.722290 
	C887.857727,311.436523 886.916809,311.848450 885.630554,312.070740 
	C883.501770,312.438599 881.780029,313.322693 882.048096,315.961609 
	C882.281799,318.262238 883.588867,319.846832 885.827271,319.887848 
	C889.609924,319.957214 890.063660,322.142365 890.029785,325.173126 
	C889.940491,333.169250 889.992920,341.167023 890.006104,349.164154 
	C890.009766,351.362640 889.860718,353.867889 893.026672,353.845093 
	C896.195496,353.822266 896.138062,351.278595 895.961853,349.115631 
	C895.551575,344.080994 896.561279,340.919037 902.745361,340.649414 
	C906.165588,340.500244 909.506714,338.175934 912.852112,336.757996 
	C917.511963,334.782959 920.328979,335.850372 922.059204,340.562195 
	C922.932861,342.941315 924.463928,344.011383 926.924255,343.999146 
	C930.922729,343.979279 934.921326,343.991638 938.919861,344.003815 
	C940.085022,344.007355 941.683411,343.571594 942.345154,344.163025 
	C946.825256,348.166931 951.222229,344.495270 955.641052,344.309357 
	C961.377686,344.068054 967.128845,345.717651 972.881714,344.091278 
	C973.971252,343.783264 975.321106,344.372681 975.512817,345.793457 
	C975.717773,347.311951 974.464966,347.599823 973.311462,347.976593 
	C971.019531,348.725220 968.762451,348.140381 966.470947,348.099640 
	C960.538635,347.994171 954.599365,348.314117 948.662048,348.421722 
	C947.635559,348.440338 947.185913,348.964508 946.931335,349.834900 
	C945.797546,353.710236 942.778687,354.109589 939.399414,354.019318 
	C936.069641,353.930359 932.729492,354.127380 929.405334,353.964447 
	C926.025085,353.798767 923.367249,354.347137 921.452087,357.697418 
	C919.253418,361.543915 915.117798,360.033844 911.845337,359.883392 
	C907.107788,359.665619 905.520813,361.518982 905.951843,366.032471 
	C906.174255,368.361298 906.891418,369.650635 909.353821,370.117981 
	C911.644287,370.552734 913.878723,371.029022 916.238831,371.025513 
	C929.851868,371.005249 943.483032,370.498718 956.997070,372.933868 
	C959.360840,373.359833 961.463562,373.888245 961.782349,376.383301 
	C962.108398,378.935089 963.039001,381.974609 960.963623,384.025879 
	C959.052307,385.915009 956.221680,385.189362 953.827515,384.626160 
	C952.052124,384.208527 951.987427,382.448822 952.006775,380.917114 
	C952.051147,377.419983 950.041260,376.681610 947.078857,377.172638 
	C945.284790,377.469971 943.492065,377.775452 941.697449,378.069366 
	C940.089233,378.332733 938.697571,379.150116 938.472290,380.745911 
	C938.225342,382.495819 939.689941,383.353882 941.172607,383.832458 
	C942.529846,384.270538 944.628296,383.984863 944.504395,386.033447 
	C944.374390,388.183441 942.325806,388.389709 940.568359,389.091675 
	C934.813354,391.390198 928.486938,390.539337 921.462524,392.955780 
	C926.225525,394.124908 929.448914,396.018311 933.132874,397.231049 
	C938.109680,398.869385 945.100952,398.195618 946.577698,405.741211 
	C946.708923,406.411499 947.588867,407.170319 948.294434,407.449097 
	C950.402527,408.281860 953.444275,408.237122 953.698975,411.080627 
	C954.082581,415.362946 953.909607,419.715637 953.671692,424.021729 
	C953.590698,425.486053 952.013428,425.964661 950.573853,425.966461 
	C941.910583,425.977295 933.247253,425.980560 924.583984,425.979218 
	C923.381775,425.979004 922.080200,425.839996 921.552185,424.645538 
	C919.586670,420.199646 917.403748,422.130432 915.176086,424.329132 
	C912.567444,426.903687 910.151001,429.713898 907.311890,432.000702 
	C905.143311,433.747528 903.918091,436.074371 905.493713,437.914124 
	C907.767517,440.569000 908.884949,436.858276 910.549500,436.001648 
	C911.133789,435.700958 911.713806,435.392029 912.646667,435.042816 
	C914.434753,435.786102 915.399536,437.282410 917.289551,437.771301 
	C917.505859,437.827820 917.865356,437.622864 917.840088,437.376953 
	C917.664612,435.843750 917.720520,434.598053 918.771912,433.254517 
	C920.286499,432.739929 921.571533,432.283203 922.865112,432.257935 
	C925.600281,432.204529 929.291809,430.475647 929.901672,435.164368 
	C930.363770,438.716553 930.579956,442.502319 925.043823,442.065338 
	C924.019836,441.984497 922.892334,442.379730 922.556091,443.382904 
	C919.530762,452.411194 915.021606,461.156189 916.998474,471.593506 
	C914.321472,473.800659 910.783508,471.914429 907.834900,474.356323 
	C910.902222,476.656891 913.600891,474.899048 916.652893,474.010345 
	C922.350830,478.414734 915.510010,478.574005 914.587646,480.825226 
	C915.221558,482.630035 916.793335,481.990662 917.942383,481.959686 
	C926.604370,481.726440 934.805420,484.885376 943.429749,485.261719 
	C948.337036,485.475861 951.444885,489.980103 951.013245,493.814880 
	C950.791931,495.781372 949.693787,496.736572 948.070190,497.418701 
	C946.410767,498.115906 945.786011,496.915894 944.867859,495.899567 
	C943.150269,493.998444 932.102661,493.129669 930.271545,494.584503 
	C928.216675,496.217194 930.708496,497.076813 930.941528,498.661621 
	C927.492004,498.289612 926.754639,502.748718 923.353760,502.951660 
	C921.622742,501.484863 920.762268,499.754517 920.849487,497.340607 
	C921.817139,496.885712 923.034729,497.074615 923.390747,496.607239 
	C928.031067,490.515350 934.633240,492.134827 940.806458,491.972290 
	C942.328918,491.932220 944.549988,492.648132 944.763672,490.166138 
	C944.975525,487.704681 942.846863,487.348358 941.027649,487.057220 
	C934.778748,486.057068 928.526123,486.581421 922.281433,487.157623 
	C921.632996,487.217438 920.874268,487.866272 920.391541,487.674866 
	C911.406555,484.111420 901.024780,487.432922 892.440186,481.997711 
	C889.754089,480.297028 881.215881,483.436188 880.351318,486.490967 
	C879.617554,489.083832 880.375977,490.761444 883.296143,490.965576 
	C884.292358,491.035248 885.297729,490.992401 886.290405,491.089081 
	C887.332520,491.190613 888.434143,491.609070 888.390076,492.767883 
	C888.338257,494.131836 887.014893,494.000824 886.073303,493.970093 
	C882.546021,493.854980 879.083130,494.034058 875.833191,495.615570 
	C873.728943,496.639557 872.261475,497.520264 875.036926,499.747101 
	C878.357605,502.411407 876.183228,504.107300 873.413940,504.818420 
	C871.541626,505.299316 869.263000,505.409180 867.490295,504.776581 
	C864.965576,503.875641 861.739380,502.732727 864.498657,498.763519 
	C866.773376,495.491272 866.335205,491.753479 865.510559,488.152710 
	C864.637634,484.340820 864.828857,480.500732 865.155823,476.721832 
	C865.531799,472.377258 868.253967,470.937653 872.173035,472.825836 
	C876.830017,475.069580 881.659485,473.615997 886.402832,473.860992 
	C887.644043,473.925049 888.689026,473.091736 888.733826,471.721008 
	C888.790161,469.999054 887.299744,469.375977 886.060608,469.282867 
	C882.581360,469.021393 879.077820,469.098999 875.585876,468.981354 
	C874.332458,468.939148 872.633667,469.393311 872.313599,467.572693 
	C872.024170,465.925598 873.561523,465.614319 874.703125,464.887695 
	C879.151978,462.055939 883.823486,463.827026 888.223511,464.446533 
	C891.523682,464.911163 892.716553,463.882324 892.884338,461.186066 
	C893.064453,458.292999 894.201172,457.070068 897.132690,456.885925 
	C899.927612,456.710388 901.182983,454.668549 900.961853,451.994568 
	C900.622437,447.890106 902.658569,446.633026 906.370605,446.979034 
	C907.353760,447.070648 908.376892,446.996796 909.353699,446.832672 
	C910.866150,446.578491 910.975403,445.359894 910.928345,444.145660 
	C910.865784,442.529297 909.987122,442.444336 908.557739,442.617096 
	C905.909607,442.937164 902.190308,444.461395 901.355896,440.680420 
	C900.519043,436.888245 899.660828,432.574554 904.583862,429.767090 
	C908.776794,427.376038 911.355103,422.925903 915.277039,419.915375 
	C918.831238,417.187103 917.691040,411.108826 913.751465,408.975800 
	C911.187195,407.587433 909.196228,405.665985 907.171387,403.693665 
	C905.753235,402.312256 904.552551,401.425537 903.886108,404.424988 
	C903.386230,406.674835 901.756714,408.779449 901.662842,410.992462 
	C901.445129,416.128204 898.078003,417.391052 894.062683,418.532288 
	C890.257080,419.613922 889.997498,424.536041 886.364014,426.150879 
	C885.517639,426.527039 886.793701,428.134460 887.718140,428.907043 
	C889.596375,430.476624 889.067322,432.313934 887.110901,432.607605 
	C882.771545,433.259003 878.325806,434.672546 873.936707,432.779480 
	C872.347290,432.093964 870.454468,430.736877 871.357971,429.117645 
	C873.009460,426.158051 872.615662,423.025146 873.263428,420.004364 
	C873.946411,416.819061 875.907532,414.648590 879.640869,414.985260 
	C880.797913,415.089569 881.976746,415.060699 883.138123,414.982666 
	C884.695862,414.878082 886.881592,415.440582 886.757629,412.830200 
	C886.653992,410.647095 884.709106,411.031158 883.290283,411.022064 
	C876.624268,410.979218 869.955566,410.893829 863.292603,411.041962 
	C859.567810,411.124817 856.584839,410.911377 856.881470,405.924133 
	C857.020996,403.578247 855.128479,403.006439 853.072754,403.006866 
	C851.074524,403.007294 849.259155,403.360657 849.087402,405.770020 
	C848.698425,411.225586 844.933105,412.128204 840.633301,411.946472 
	C836.791504,411.784119 833.355164,412.136505 830.880249,415.553040 
	C830.055542,416.691437 828.719604,417.081543 827.309326,416.979462 
	C826.201172,416.899200 824.817383,417.299683 824.331177,415.813477 
	C823.891479,414.469391 824.781372,413.185822 825.769531,412.958740 
	C830.102478,411.963013 833.243164,409.934143 833.978394,405.205231 
	C834.011841,404.990479 834.530273,404.670563 834.790955,404.698303 
	C840.156982,405.269684 841.663391,399.986359 845.219421,397.816864 
	C847.707336,396.299042 847.317749,394.690399 844.844482,393.429413 
	C842.328064,392.146393 839.893250,390.677216 836.859497,390.963623 
	C835.304077,391.110413 833.765137,390.774567 833.247681,388.995605 
	C832.751648,387.290375 832.899048,385.714539 834.638184,384.629913 
	C836.529114,383.450592 837.314514,381.629120 836.971985,379.484802 
	C836.378235,375.766968 838.685242,373.640442 841.043152,371.459808 
	C842.869812,369.770538 844.915100,369.422852 846.968079,370.720947 
	C849.334595,372.217255 849.467834,374.548492 848.456543,376.902222 
	C847.943542,378.096191 847.083008,379.163635 846.265442,380.198120 
	C845.262817,381.466827 843.472900,382.484497 844.418091,384.423889 
	C845.428467,386.497131 847.642639,386.350464 849.285217,385.851471 
	C852.853943,384.767395 855.799133,385.472198 858.464172,387.905029 
	C859.386414,388.746918 860.500244,388.885559 861.688110,389.013245 
	C867.909668,389.682251 868.879150,390.644501 868.953552,397.140442 
	C868.999268,401.135101 870.304077,402.757629 874.440369,401.421783 
	C875.974243,400.926422 877.770813,401.304199 879.372437,400.946747 
	C882.830566,400.175018 886.622803,399.984070 887.534851,395.209137 
	C888.053345,392.494934 890.399719,391.063751 893.141907,391.132385 
	C895.883545,391.201019 897.720703,392.828888 898.667664,395.455841 
	C899.174500,396.862061 898.781006,399.286438 901.372803,398.756348 
	C903.713989,398.277496 902.920166,396.119629 902.971680,394.594604 
	C903.228088,387.002777 903.195312,387.001495 895.560303,387.000092 
	C889.894043,386.999023 884.227417,386.961761 878.561584,387.010651 
	C874.788147,387.043243 872.124451,385.632660 871.108093,381.778534 
	C870.901550,380.994995 870.782043,379.801788 870.258118,379.547241 
	C863.617920,376.321045 860.168335,370.281586 856.512146,364.382080 
	C856.133301,363.770874 855.171448,363.157318 854.483154,363.160675 
	C850.044861,363.182343 847.671509,361.633209 846.627808,356.770294 
	C845.998230,353.836731 841.506836,353.826508 838.671997,353.145203 
	C834.237427,352.079437 833.034912,349.999847 835.005432,346.020874 
	C836.743958,342.510315 835.492004,340.905792 831.816223,341.032410 
	C827.751404,341.172455 823.450745,339.963165 819.828430,343.023743 
	C818.701721,343.975677 817.369873,343.472992 816.024719,343.242310 
	C813.745117,342.851318 811.408569,342.660065 809.142212,343.574127 
	C802.392578,346.296448 799.277100,345.211304 795.845642,339.319214 
	C794.839966,337.592346 795.911133,336.495972 796.391418,335.161438 
	C797.770630,331.329437 796.142639,329.203278 792.373352,329.076782 
	C785.714539,328.853394 779.042969,328.986206 772.377014,329.024872 
	C771.129944,329.032104 769.355774,328.690216 769.333557,330.646942 
	C769.311340,332.602234 770.957581,332.954346 772.541260,332.967010 
	C777.373962,333.005676 782.207520,332.959534 787.039856,333.016846 
	C788.968628,333.039734 791.681213,332.588409 791.645569,335.412354 
	C791.615234,337.810913 788.488098,337.746155 787.244080,337.585571 
	C777.284424,336.299805 767.299683,337.354980 757.341248,336.924164 
	C751.753418,336.682434 746.143250,336.966370 740.543030,336.993561 
	C739.211914,337.000031 737.870850,337.011871 736.551758,336.865173 
	C735.295227,336.725372 734.268921,336.049713 734.305054,334.649597 
	C734.347778,332.996063 735.725891,333.031982 736.875000,333.026703 
	C743.541077,332.996002 750.207397,333.017700 756.873535,332.994995 
	C759.671753,332.985474 764.074524,333.364441 764.027222,330.426819 
	C763.979980,327.494751 759.656128,329.170868 757.287964,329.087524 
	C750.296997,328.841461 743.291809,329.015503 736.292542,328.974243 
	C735.225220,328.967957 733.626038,329.357025 733.583191,327.664490 
	C733.552307,326.444214 734.708008,325.599518 735.872437,325.309601 
	C736.982605,325.033203 738.175537,325.015106 739.332397,325.012085 
	C747.831787,324.989899 756.412842,324.273926 764.806274,325.217285 
	C772.247681,326.053650 779.614441,326.050262 787.012390,325.925812 
	C791.613647,325.848450 796.325745,325.586060 800.627014,323.647980 
	C802.875671,322.634796 804.734375,323.472046 806.182678,324.689636 
	C810.046875,327.938446 812.535095,325.889465 814.519043,322.690216 
	C816.708252,319.160034 818.646729,317.758087 821.180664,322.274536 
	C821.793945,323.367706 822.938232,324.387451 824.014404,323.711151 
	C827.776184,321.347076 832.191406,322.562225 836.122192,321.205078 
	C837.632629,320.683594 839.085327,320.431946 838.959900,318.441376 
	C838.870667,317.024323 839.587646,315.274109 837.886597,314.390717 
	C836.097778,313.461761 835.345032,315.193726 834.163574,316.022156 
	C830.491028,318.597321 823.888733,317.045593 821.618896,313.225861 
	C821.109863,312.369293 820.800659,311.094879 820.050171,310.728607 
	C814.377625,307.960175 814.669556,302.960052 815.000000,297.449829 
	C819.058533,295.240265 816.461060,292.086243 816.785645,289.250305 
	C818.337952,288.964630 819.648621,289.089508 820.606384,290.156586 
	C822.312866,292.058014 823.867920,291.962158 826.157471,290.716309 
	C829.224426,289.047363 832.201294,289.552917 833.849487,293.672150 
	C834.830688,294.925507 834.545654,295.845734 833.747192,297.038086 
	C832.336243,298.283966 830.749634,298.077515 829.380798,298.749969 
	C830.674622,299.064423 831.617737,298.150543 833.072144,297.986481 
	C836.395142,296.972504 839.997009,298.286469 842.729614,295.219604 
	C845.578491,294.900909 847.145203,295.905365 846.937439,298.720093 
	C846.733215,301.486206 844.580383,300.927887 842.865906,300.983917 
	C840.733826,301.053619 838.598022,301.002411 836.464172,301.029205 
	C834.602112,301.052551 833.317749,301.789307 833.068359,303.818115 
	C832.773987,306.212677 833.925720,307.141541 836.596497,306.997192 
	C839.324341,307.905823 841.641479,308.749725 844.201355,308.827728 
	C847.223206,308.919800 846.465942,307.315308 845.891174,305.330261 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M660.769958,865.228882 
	C663.309753,865.030396 664.912048,865.777405 663.117798,868.222473 
	C661.242615,870.777588 660.676453,873.120911 662.381348,876.003174 
	C663.273743,877.511780 662.178528,878.639832 660.658936,878.843567 
	C656.751831,879.367310 652.938843,880.743835 648.849976,879.513000 
	C644.973694,878.346252 640.900879,878.979431 636.926819,879.105652 
	C633.982666,879.199158 631.401917,880.981140 631.342773,883.880798 
	C631.207336,890.526306 626.035889,890.033936 622.031006,890.891479 
	C619.216553,891.494080 615.930786,890.330139 614.268860,893.925903 
	C613.890076,894.745361 612.856140,894.866455 612.097839,894.286438 
	C610.997498,893.444519 610.414001,892.204956 610.252625,890.859863 
	C610.100281,889.591003 611.000488,889.175964 612.104980,889.037048 
	C615.622314,888.594788 618.826233,887.614380 618.937073,883.290649 
	C618.992737,881.118652 619.971375,878.063904 616.885010,877.333618 
	C614.309937,876.724243 611.122620,875.851440 609.130127,878.850525 
	C607.085754,881.927734 604.102234,881.515442 601.559265,880.504517 
	C595.150452,877.956421 588.982727,878.376587 582.385559,880.948853 
	C581.389587,880.549683 581.367371,879.755676 580.945557,879.195129 
	C580.785706,879.636108 580.680847,880.098022 580.222778,880.755127 
	C578.257812,880.667419 576.515686,880.334778 574.386719,880.001099 
	C573.388550,879.429749 573.201660,878.700867 573.274719,877.534424 
	C574.625488,874.368103 572.503052,874.606323 570.924194,874.580200 
	C565.321777,874.487366 559.715881,874.457458 554.115417,874.570312 
	C549.724060,874.658752 546.046570,873.575073 543.312866,869.846680 
	C542.075562,868.159302 540.522583,866.199829 537.879150,868.830566 
	C537.137146,869.508057 536.565552,869.993896 535.937988,869.982788 
	C531.582581,869.906006 529.521729,872.183777 529.027710,876.653198 
	C528.528687,877.334473 528.150757,877.629639 527.359985,877.929321 
	C522.760925,879.524170 520.247620,877.863525 519.042358,873.355469 
	C519.251343,872.735168 519.528992,872.501831 520.072327,872.189453 
	C520.310730,872.078003 520.762756,871.882568 520.881348,871.441284 
	C518.728821,869.148010 507.911194,868.255981 505.435547,869.716553 
	C501.594299,871.982849 497.879303,870.421875 496.008850,865.380737 
	C498.767670,862.232788 502.570038,864.272705 505.964935,862.881958 
	C501.666260,862.036499 497.591766,863.162109 493.305878,862.170898 
	C492.066162,861.667664 491.144836,861.292053 490.194550,861.016418 
	C489.411499,860.789185 488.408173,860.781921 488.550476,859.632690 
	C488.664154,858.714905 489.466370,858.274536 490.308624,858.129395 
	C492.746796,857.709045 495.080994,856.839111 497.647217,856.955322 
	C501.295044,857.120605 504.956940,856.991211 508.612549,856.957092 
	C509.450775,856.949280 510.376068,856.817871 510.602661,855.840637 
	C510.918243,854.479370 509.739166,854.455811 508.889862,853.962524 
	C502.322632,850.147766 496.074707,852.808472 489.826019,854.846924 
	C488.755768,855.196106 487.688660,856.376465 486.643341,855.620605 
	C482.857300,852.883057 478.581787,853.987732 474.556610,854.080078 
	C461.772766,854.373413 449.067596,852.287048 436.285248,853.008118 
	C434.176147,853.127075 432.476929,852.017578 430.595154,851.418152 
	C425.438202,849.775391 423.423431,851.084473 423.001953,856.578735 
	C422.453613,857.599854 421.753693,857.763489 420.625824,857.586792 
	C418.187408,855.311707 416.273987,852.994995 414.294037,850.321899 
	C413.888672,848.668274 412.804291,847.991577 411.617798,846.972046 
	C410.576385,845.336914 410.466827,843.731689 409.934143,841.903198 
	C409.530212,840.591125 409.653290,839.278381 408.210388,839.080811 
	C406.780029,838.884888 406.436310,840.029541 405.827484,841.334961 
	C405.148285,842.220581 404.524658,842.560120 403.392151,842.628723 
	C401.278625,841.994202 399.887115,841.027100 399.442841,838.749634 
	C401.076843,836.085144 403.330627,834.135071 403.787109,830.803467 
	C405.746185,829.390625 408.343689,829.588013 409.573669,826.962402 
	C413.366547,825.625610 414.997925,828.875610 417.785461,830.368591 
	C418.473724,831.328674 418.431549,832.055542 418.056030,833.128113 
	C416.359619,835.166443 414.090515,836.744202 418.200378,838.314575 
	C420.511688,839.197693 419.430023,841.419983 419.209167,843.170410 
	C419.051300,844.421936 419.267975,845.518677 420.530487,846.107788 
	C421.010986,846.331970 421.514801,846.177002 421.908600,845.835815 
	C423.037598,844.857849 422.649994,843.741455 422.127045,842.654785 
	C420.899261,840.103516 419.640381,837.566833 419.192627,834.373352 
	C419.360992,833.059143 419.701508,832.170105 420.418213,831.062012 
	C423.337341,829.083923 424.694122,831.480530 426.476379,832.643799 
	C426.822296,832.869507 427.711060,833.530823 427.581055,832.248779 
	C427.537384,831.818298 427.055145,831.461609 426.661377,830.749878 
	C426.613220,828.624451 427.763184,825.782898 423.598633,827.827698 
	C421.763123,828.147522 420.378693,828.049133 419.507202,826.141968 
	C420.231110,823.991760 420.940948,822.170837 420.934021,819.872253 
	C423.213959,818.259399 424.742828,820.089233 425.908203,820.417786 
	C423.383759,816.616333 417.447205,816.994446 414.516754,812.331421 
	C414.553589,810.607910 415.386047,809.693298 416.683044,808.675171 
	C418.344269,807.980225 419.631195,808.399658 420.925842,808.705261 
	C424.586121,809.569458 427.299377,808.377625 428.321960,804.693848 
	C429.452850,800.619873 431.783325,800.004211 435.800568,800.287476 
	C440.984283,800.653076 445.749969,800.981018 449.078674,805.612366 
	C450.015625,806.916016 451.305115,806.764465 452.647583,806.058960 
	C454.313568,805.183411 456.045410,804.386536 457.525818,806.860718 
	C457.425232,808.447205 457.229523,809.705322 458.528046,810.965210 
	C459.096863,812.117371 459.142242,813.018982 458.862061,814.269165 
	C458.127655,815.996582 456.269470,816.474609 456.026215,818.502319 
	C455.835632,820.035461 455.725891,821.255005 456.161377,822.762817 
	C456.550598,824.620911 457.861816,825.551880 458.741150,827.151917 
	C459.185120,828.379578 459.178436,829.306458 458.876587,830.573730 
	C456.095581,835.062012 456.094116,835.076416 460.980530,835.573120 
	C462.299683,835.707214 463.629547,835.731140 465.144531,836.555603 
	C466.330994,837.732910 466.785950,838.857239 466.552246,840.545166 
	C464.887360,842.837708 461.980652,842.949463 460.388916,845.430298 
	C457.009216,847.643005 454.321472,846.733521 451.865540,844.221008 
	C453.552917,846.707397 455.682343,847.605652 458.638275,847.549561 
	C468.392700,847.364624 478.153259,847.576355 487.911163,847.430054 
	C488.253784,847.424866 488.648163,847.386292 488.817871,847.137817 
	C489.222870,846.544983 488.598297,846.341675 488.016876,845.825867 
	C486.004425,842.997131 482.937958,842.893555 480.132324,841.552124 
	C479.501373,838.414124 477.590820,836.156738 476.607880,833.212280 
	C477.681061,831.656128 479.317200,831.575745 480.802307,830.516541 
	C482.171600,830.153625 483.191620,830.297546 483.999908,830.880615 
	C487.166412,833.164551 490.526581,833.824524 494.270599,832.517395 
	C495.396545,832.124390 496.560913,832.298035 497.661987,833.582581 
	C498.282867,834.857605 498.339905,835.854004 498.167419,837.216309 
	C497.394531,838.979004 495.735138,840.290833 497.643494,842.321899 
	C497.475037,846.002441 499.703369,846.261719 502.139862,846.029175 
	C503.868774,845.864136 505.518616,844.832214 507.646942,845.776123 
	C515.487427,848.788147 522.750305,852.361023 531.254211,851.484863 
	C537.721313,850.818542 544.181824,851.633728 550.443176,853.591370 
	C553.542969,854.560486 556.660217,854.226562 559.811279,853.775269 
	C563.447205,853.254639 567.114746,852.982605 570.441345,855.093323 
	C571.998474,856.081299 573.657837,857.132812 573.515747,859.265503 
	C573.368286,861.479370 571.791138,862.744812 569.943787,863.696167 
	C568.213257,864.587341 566.067810,864.353577 564.201599,865.538757 
	C568.979370,868.108582 573.288940,867.340942 578.021606,863.079956 
	C581.184204,861.501160 584.108826,860.228516 585.705322,856.736938 
	C588.695435,855.544983 590.648438,855.930725 590.695557,859.321411 
	C590.717468,860.894531 588.927002,863.247192 592.252014,863.302490 
	C594.740784,863.343933 598.232056,864.463318 599.072388,861.019409 
	C600.180786,856.476868 603.387390,853.233887 605.256653,849.191650 
	C607.257568,844.864563 614.229553,843.365051 618.170593,846.019836 
	C620.277222,847.439026 621.126953,849.429321 620.992920,851.985901 
	C620.808228,855.507690 618.493286,857.276062 615.703003,858.560852 
	C613.307922,859.663635 610.475952,860.169678 610.542114,864.058777 
	C610.471375,864.900940 610.472290,865.387756 610.477112,865.874878 
	C610.543274,872.569092 610.699036,872.665405 616.572144,869.358032 
	C623.244507,870.576416 623.244507,870.576416 625.615173,865.049683 
	C626.636047,863.889832 627.793945,863.791077 628.878662,863.492798 
	C629.643372,863.282593 630.522949,863.111511 630.634644,862.116699 
	C630.694214,861.585999 630.272156,861.242188 629.787292,861.079529 
	C628.973022,860.806213 628.152771,860.859253 627.516174,861.452576 
	C626.432739,862.462402 626.178772,864.025879 624.873169,865.302856 
	C621.316467,865.610657 620.938782,863.218323 620.531128,860.873291 
	C619.980896,857.708557 620.951721,854.610657 620.974365,851.475769 
	C621.009277,846.642700 619.642822,844.990784 614.857483,845.090210 
	C610.980225,845.170776 607.982727,844.109985 604.931030,841.629822 
	C601.419128,838.775574 597.094116,837.362793 591.991699,838.551941 
	C590.198792,837.558228 590.352966,836.109375 590.790955,834.948853 
	C592.168213,831.299622 591.213318,829.336487 587.170898,829.055359 
	C586.202209,828.988037 585.379883,828.304077 584.522583,827.201599 
	C584.057983,826.092590 584.060730,825.316406 584.473999,824.188293 
	C590.355713,823.286438 593.572388,817.460388 599.656067,816.926392 
	C601.398865,816.770264 602.728577,817.447388 604.432556,817.054749 
	C606.261047,816.619873 607.421143,815.677979 608.849243,814.537231 
	C612.356506,814.095947 615.844543,814.364624 617.561707,810.190186 
	C620.479126,809.109985 623.096985,809.219055 626.078003,809.961304 
	C629.235168,814.092712 632.979431,814.715515 637.805054,812.919678 
	C634.733887,812.123047 631.815186,812.932129 628.934937,811.539001 
	C627.752563,808.857178 623.490356,809.932800 623.666992,806.138855 
	C625.414307,804.319519 627.446228,804.506104 629.376099,804.401001 
	C631.786255,804.269653 633.892700,803.677551 635.336731,801.051270 
	C639.681946,798.731873 641.299805,800.855225 641.968262,805.056396 
	C646.879395,799.560913 652.983154,799.981384 659.130432,800.911499 
	C661.454468,801.263123 663.500244,802.426575 665.126221,804.195679 
	C669.626221,809.091858 675.316284,809.751282 681.443054,808.727112 
	C683.316772,808.413879 684.636841,807.540100 685.370728,805.481689 
	C687.487671,799.544250 688.080383,799.298096 695.266602,799.848755 
	C698.901001,800.918518 699.971985,803.671814 701.110535,806.514099 
	C703.095520,811.469727 704.215881,812.349548 708.594116,812.718140 
	C702.266785,807.254822 698.354797,801.291382 702.352051,792.721375 
	C703.210571,791.844421 703.924316,791.440613 705.140625,791.055420 
	C714.777222,789.604675 724.028687,790.318787 733.262512,790.395874 
	C736.881287,790.426086 739.083984,792.379028 739.731140,796.002319 
	C740.572632,800.714355 741.405334,805.424194 740.253174,810.614929 
	C736.774597,811.100403 735.843445,808.682800 735.083618,806.296570 
	C734.670410,804.999023 734.592407,803.567688 732.975159,803.276489 
	C730.998169,802.920593 731.013367,804.719666 730.577454,805.842041 
	C729.525146,808.552002 726.550903,810.220764 726.923706,813.781982 
	C727.381714,818.156860 728.923706,819.424255 732.996887,818.010864 
	C739.636963,815.706787 746.096924,817.288696 752.540405,819.022278 
	C753.991394,819.412720 754.652954,820.370850 754.996582,821.824829 
	C757.011353,830.350098 763.307983,834.384399 771.182312,835.663818 
	C775.566956,836.376221 780.328308,835.679993 784.801514,833.332764 
	C786.973816,831.599976 789.088196,831.850220 791.616943,832.734558 
	C794.720154,833.172668 797.689697,828.593506 800.199158,833.698242 
	C802.235352,837.840210 801.090332,841.167725 796.514893,841.917114 
	C793.754639,842.369263 792.879028,843.706055 793.003174,846.598267 
	C792.441772,849.691833 790.266968,848.949646 788.575439,848.972961 
	C783.245178,849.046326 777.913269,849.020569 772.582153,848.994690 
	C770.233948,848.983337 768.192200,849.257568 768.025818,852.280945 
	C767.880920,854.912537 768.369751,856.834656 771.614929,857.060120 
	C774.735229,857.277039 774.215881,859.056885 772.700134,860.727234 
	C770.180664,863.503723 768.132202,866.938477 763.961426,861.869202 
	C760.968689,858.231567 756.375549,860.705933 752.848389,861.973206 
	C749.988953,863.000549 747.896484,863.315186 745.602112,861.195374 
	C743.362976,859.126404 740.510559,860.188354 737.922302,860.031189 
	C736.139221,859.922974 734.457214,859.585266 733.720337,857.900146 
	C732.756653,855.696594 735.079834,855.656372 736.151550,854.932251 
	C737.581787,853.965942 738.229370,852.782471 737.793396,851.131653 
	C737.239197,849.033264 735.496216,849.016235 733.394775,848.999573 
	C732.045227,846.283752 732.373596,843.537964 733.070068,840.550903 
	C735.791687,839.154175 738.449280,839.087036 740.443237,840.728088 
	C745.134460,844.588745 750.501953,843.236389 755.706909,843.262451 
	C756.862549,843.268188 758.235046,842.943420 758.403931,841.550232 
	C758.592529,839.994385 757.096252,839.854126 756.034424,839.523682 
	C751.268494,838.040344 746.444763,836.859497 741.393921,837.081909 
	C740.064209,837.140381 738.737915,837.253784 737.217529,836.405212 
	C736.518799,835.686340 736.230713,835.109558 736.160095,834.454407 
	C736.123413,834.113342 735.906677,833.580078 735.858948,833.612122 
	C735.378967,833.935059 735.632812,834.449463 735.594482,835.231812 
	C734.385681,838.234436 732.256592,839.552063 729.571960,838.454163 
	C726.760925,837.304688 726.780457,834.640259 727.408813,832.035950 
	C727.525818,831.551147 727.706543,831.085144 727.862976,830.612000 
	C728.539062,828.567749 729.697754,826.296814 726.862854,825.051453 
	C724.848633,824.166687 722.823730,824.764587 721.580627,826.641052 
	C720.105774,828.867126 720.295837,831.165344 722.467285,832.851135 
	C724.960815,834.786865 726.756531,836.996643 726.715576,840.653748 
	C725.637390,843.568542 723.856567,842.458557 722.144104,841.963623 
	C720.327820,841.438782 718.776123,839.833557 716.155151,840.664734 
	C719.474060,842.687378 725.518494,840.003479 725.217407,846.733826 
	C719.204102,844.630127 713.726929,843.687561 708.262756,848.346863 
	C706.361206,849.968384 703.022339,849.765808 701.041016,847.337952 
	C699.618958,845.595154 699.098999,843.487061 702.345764,843.081055 
	C703.925537,842.883545 705.183167,842.235168 706.433044,841.429688 
	C707.469543,840.761719 709.921509,843.006714 709.698730,840.095032 
	C709.532532,837.922607 707.446960,838.044739 705.383423,837.993652 
	C703.422180,836.437500 702.944885,834.294739 702.105652,832.377441 
	C700.560181,828.846375 698.379456,828.222961 694.939941,830.455444 
	C696.352173,832.635010 699.497192,832.773804 700.105957,835.671143 
	C696.522888,839.190796 691.975281,838.952148 687.935913,839.267395 
	C687.577881,840.498779 688.131592,840.837158 688.542053,840.808167 
	C694.378174,840.396118 697.593628,842.623535 696.974426,848.923279 
	C696.702637,851.688538 697.512634,853.545105 700.593445,854.433960 
	C703.843262,855.371582 707.035950,855.199280 708.686035,852.541016 
	C712.113403,847.019592 717.156677,849.202026 721.614075,849.257996 
	C724.280029,849.291443 724.876160,852.370911 725.509827,854.480835 
	C726.100891,856.449219 724.316467,856.855286 722.361328,856.987488 
	C716.540283,851.095215 715.705383,851.060303 710.239563,856.755615 
	C706.596863,858.846863 702.965332,859.851257 699.092712,860.017639 
	C698.275146,860.052795 697.128296,859.944092 696.702148,860.415771 
	C692.079102,865.532898 691.349976,861.563843 690.157898,857.834351 
	C689.246948,854.984741 686.919434,852.899841 685.368103,850.414246 
	C682.642761,846.047791 679.360229,848.701172 676.587585,849.523010 
	C673.644043,850.395447 673.598938,853.114746 674.753723,855.747009 
	C674.946167,856.185608 674.910828,856.724182 674.990356,857.608276 
	C672.824097,859.619324 672.681824,862.346191 671.232971,864.744202 
	C668.678772,865.111084 667.484802,864.210327 666.879089,861.746277 
	C665.942200,857.935364 655.971924,853.778625 652.425903,855.417542 
	C648.166870,857.385925 648.548279,861.551270 653.647583,864.954590 
	C656.005005,867.652100 658.211670,867.423767 660.769958,865.228882 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M178.217987,656.733337 
	C176.131653,660.274536 178.725113,661.021851 180.867645,661.752808 
	C182.680450,662.371338 184.849594,662.144470 185.931854,664.648193 
	C181.490738,665.225403 176.849152,663.412659 172.678009,667.369568 
	C170.766937,669.182495 167.118607,668.345459 164.420914,667.884460 
	C158.965515,666.952148 153.516800,666.983582 148.047653,667.012878 
	C145.044357,667.028931 143.035126,665.295166 144.247375,662.791870 
	C145.838654,659.505798 145.057266,655.497498 147.760422,652.693665 
	C148.645370,651.775757 149.082611,650.780762 147.897156,649.696350 
	C144.828537,646.889282 135.882904,649.656738 135.007080,653.806885 
	C133.724289,659.885376 128.951675,657.702881 125.341835,657.902344 
	C121.969025,658.088684 120.880302,655.981201 120.922966,652.725464 
	C120.957237,650.110229 121.255997,646.229736 119.772453,645.102966 
	C115.461945,641.829346 112.668106,637.015808 108.248390,634.180786 
	C106.193260,632.862488 104.119041,632.269775 104.015846,629.518372 
	C103.933655,627.326782 102.921623,626.257507 100.717773,625.932739 
	C97.372406,625.439636 95.745903,623.250671 96.095627,619.957336 
	C96.498680,616.161560 99.396706,615.909912 102.412415,615.989136 
	C104.407455,616.041504 106.405365,615.996033 108.401848,615.978210 
	C110.259483,615.961670 111.752243,615.353760 111.869408,613.240479 
	C112.001129,610.864685 110.510788,610.030518 108.385231,610.023132 
	C102.894539,610.004150 97.403694,610.022095 91.913055,609.995605 
	C86.347168,609.968689 85.112442,608.841370 85.027542,603.356689 
	C84.966499,599.413452 84.633484,595.534058 87.231712,591.874573 
	C88.865372,589.573608 88.708061,586.765808 84.785782,585.940002 
	C81.794373,585.310181 78.112221,583.813782 78.679596,580.622742 
	C79.249832,577.415588 82.606178,575.507874 86.505424,575.964111 
	C88.310982,576.175293 90.174187,576.137817 91.989677,575.973938 
	C97.263756,575.498047 101.403549,576.262512 101.081932,583.037170 
	C100.964775,585.505066 104.240654,585.292542 104.932541,587.645081 
	C104.275475,589.073914 103.107307,589.335693 101.968483,589.455811 
	C98.384903,589.833801 97.288124,591.913147 97.514526,595.277283 
	C97.746468,598.723572 99.491829,600.162048 102.839066,600.547058 
	C110.833061,601.466431 111.006546,601.068481 114.378464,594.648193 
	C116.079636,591.409241 112.609222,590.774170 112.862457,588.323364 
	C113.567070,587.690491 114.125549,587.133362 114.702614,587.113464 
	C117.524696,587.015747 120.357803,586.911011 119.956367,582.962524 
	C119.629814,579.750488 121.899437,575.008301 115.630058,574.910583 
	C114.375870,574.891052 113.314117,574.137939 112.789436,573.103943 
	C111.463966,570.491699 109.388130,569.677795 106.630692,569.990601 
	C102.993408,570.403137 101.489563,568.261169 101.069847,565.073730 
	C100.854370,563.437439 100.323036,561.863037 100.037704,560.314941 
	C99.135391,555.419250 96.880600,553.392273 91.696640,553.764709 
	C88.680862,553.981323 88.050369,551.094421 87.968445,548.328369 
	C87.681366,538.636292 87.632553,538.575562 77.974518,538.023682 
	C73.845238,537.787720 71.960518,535.771423 72.005318,531.698242 
	C72.050491,527.589844 74.981514,527.990845 77.652931,528.007751 
	C81.795197,528.033936 85.936317,527.757019 90.029190,528.913086 
	C94.958008,530.305237 99.272141,526.442322 104.574860,527.001648 
	C106.161667,529.783447 103.847458,530.078552 102.260956,530.434692 
	C98.630844,531.249634 97.525772,533.607422 97.573112,536.972961 
	C97.612656,539.784790 97.514862,542.598633 97.625122,545.410461 
	C97.698517,547.282166 98.057808,549.093323 99.672935,550.265442 
	C102.176430,552.082275 103.931923,554.370361 104.759003,557.377502 
	C105.025108,558.345032 105.526901,559.366760 106.717216,559.274170 
	C107.621300,559.203857 107.879784,558.266541 108.202866,557.486389 
	C111.748299,548.925171 114.673668,547.900085 124.104340,552.201355 
	C125.084587,553.031128 125.674789,553.754211 126.498985,554.430420 
	C125.990463,553.791870 125.401543,553.433838 124.711746,552.665588 
	C123.681725,551.294678 123.701096,550.095703 124.182922,548.525208 
	C124.862556,547.036133 126.602509,546.846008 126.692902,545.071594 
	C122.720085,543.766968 118.390518,544.140930 114.160370,542.522949 
	C112.695534,538.890869 115.410553,538.836121 117.354874,538.668335 
	C119.784851,538.458618 122.143875,537.946777 124.536552,537.587952 
	C125.700478,537.413452 126.798386,537.132812 126.967987,535.331909 
	C127.213638,533.956360 127.836182,532.645020 128.837723,533.690552 
	C131.985947,536.977112 135.903595,535.842285 139.589539,535.972412 
	C142.253342,536.066467 145.097214,536.533997 145.468353,539.387756 
	C145.979950,543.321716 148.341583,544.127319 151.738464,544.735840 
	C152.286728,546.027100 151.651077,546.732605 150.860291,546.832153 
	C145.132599,547.553040 145.223618,551.237549 146.353989,555.870728 
	C146.481705,557.327026 146.309906,558.356567 145.682114,559.683594 
	C142.519440,562.142822 139.324356,560.321411 136.250107,560.549316 
	C131.165543,560.926147 126.194374,561.604126 121.359718,558.367798 
	C118.716415,556.598389 115.496620,557.990662 113.994926,561.339783 
	C112.484604,564.708191 113.446686,567.824402 116.785820,569.211731 
	C119.481087,570.331604 121.687241,571.802612 123.564919,574.069275 
	C126.237282,577.295288 130.167847,576.766663 133.780853,576.763184 
	C136.268463,576.760803 138.757156,576.284546 141.255585,576.607178 
	C143.786423,576.934021 145.850174,577.949768 145.926987,580.848938 
	C146.003418,583.734436 144.050293,584.909485 141.506409,585.331848 
	C139.196884,585.715393 136.844925,585.433167 134.528717,585.706604 
	C132.651184,585.928223 130.777008,586.353943 130.324127,588.554626 
	C129.905060,590.591064 131.349518,591.618652 132.948105,592.275879 
	C135.242935,593.219299 136.838730,594.746155 137.721771,597.071350 
	C138.600937,599.386414 140.600357,600.088684 142.802536,600.586304 
	C145.279877,601.145996 147.652206,601.966370 148.553864,605.120850 
	C146.058426,610.236816 146.058426,610.236816 152.746338,613.759033 
	C153.950470,614.632507 155.378616,615.275696 154.209320,616.610535 
	C153.040665,617.944641 151.273849,618.456848 149.701248,617.286621 
	C144.975067,613.769836 143.756165,615.815308 143.939590,620.668701 
	C144.038559,623.287354 142.274139,624.010437 139.976425,623.999512 
	C135.483093,623.978149 130.988998,624.045166 126.496277,623.989624 
	C122.194283,623.936401 120.131592,625.897644 119.965836,630.283997 
	C119.586258,640.328674 121.080261,641.999023 131.047211,641.999695 
	C136.539215,642.000122 142.031586,641.959717 147.523087,642.011292 
	C150.939453,642.043396 153.404938,640.877075 153.958878,637.206055 
	C154.244553,635.312805 155.021469,634.046997 157.107758,634.101624 
	C159.232849,634.157227 159.776077,635.603394 160.080353,637.417725 
	C160.552139,640.230957 162.268982,641.960083 165.186630,641.877197 
	C167.852386,641.801575 169.450912,640.129395 169.896683,637.538818 
	C170.201523,635.767151 170.629852,634.216797 172.769226,634.104126 
	C175.171005,633.977600 175.823090,635.545166 176.112885,637.575256 
	C176.329956,639.095703 176.973953,640.741394 178.518799,641.116333 
	C182.702179,642.131592 186.926529,643.302368 191.677231,642.684570 
	C190.309204,639.678772 185.472809,641.120422 186.124557,637.322510 
	C186.784729,633.475525 190.793381,634.357178 193.280533,633.145813 
	C194.859024,632.377014 196.792358,632.125244 198.582809,632.054932 
	C201.022583,631.959167 202.788956,631.488403 203.085999,628.605591 
	C203.306259,626.467834 204.590622,624.212769 206.687683,624.500977 
	C209.597305,624.900879 213.719757,624.653748 214.866394,627.873962 
	C216.334808,631.997742 218.519653,634.415588 222.775467,634.983521 
	C224.116806,635.162537 224.877060,635.798889 224.967041,637.207764 
	C225.239746,641.478088 228.247025,642.522522 231.783966,643.055420 
	C232.894791,643.222717 234.065018,643.613037 234.993240,644.226440 
	C236.301559,645.091064 236.182892,646.569214 235.703568,647.841919 
	C235.257812,649.025513 234.166000,648.932800 233.174698,648.542419 
	C223.016006,644.541626 213.314636,649.113647 203.432785,650.175354 
	C202.561859,650.268982 201.414169,650.959412 201.007843,651.706055 
	C197.510712,658.131775 191.707474,658.225403 185.288010,657.273010 
	C184.847794,654.479187 187.131866,654.983887 188.417084,654.325562 
	C190.366348,653.327026 191.178452,651.677979 190.302292,649.873413 
	C189.384735,647.983643 187.632034,649.161194 186.447983,649.821167 
	C183.249573,651.603943 179.824066,651.359314 176.394394,650.877686 
	C175.973923,653.460449 179.067902,654.227112 178.217987,656.733337 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M241.214355,753.262817 
	C241.684937,749.999512 243.252991,746.960083 239.693359,744.477722 
	C238.533661,743.669067 239.151917,742.108948 240.308167,741.604675 
	C242.863831,740.490173 245.476974,740.735413 247.981125,742.019531 
	C250.098297,743.105164 249.931641,744.677917 249.322464,746.657532 
	C248.418213,749.596008 249.428574,751.635864 252.399582,753.049561 
	C255.735367,754.636780 258.362701,757.444824 262.558502,757.517151 
	C264.992645,757.559082 265.670593,760.154602 264.650482,762.125122 
	C263.236237,764.857056 260.387024,766.053406 258.038910,763.846069 
	C254.358215,760.385925 250.132401,761.066467 245.883789,760.998657 
	C243.865143,760.966431 242.303558,761.709045 241.408035,763.546814 
	C240.268021,765.886475 242.330536,775.730103 244.519104,777.240417 
	C247.814316,779.514404 247.365173,783.133240 247.744446,786.218018 
	C248.060410,788.787720 244.462952,789.660645 242.078018,787.242188 
	C238.607849,783.723206 237.346085,784.480591 236.723999,789.042419 
	C236.528748,790.474182 235.977142,792.865601 233.820328,792.318298 
	C232.097336,791.881165 231.845657,789.744324 232.083817,788.065674 
	C232.477158,785.293030 233.069885,782.546204 233.649368,779.803467 
	C234.003281,778.128296 236.446609,775.672729 233.006729,775.245972 
	C230.353806,774.916931 226.333130,773.118774 225.088043,777.813171 
	C223.191437,784.963867 223.252869,785.068176 215.934479,784.963257 
	C212.249664,784.910400 208.969513,784.764771 208.434952,789.804443 
	C208.206528,791.957825 205.904556,792.503479 203.885345,793.084778 
	C200.678085,794.008179 199.882675,797.552551 202.362900,799.460754 
	C203.687302,800.479736 206.455627,800.385925 205.609161,802.972412 
	C204.882935,805.191528 202.217758,804.632751 200.247543,805.740356 
	C198.211395,807.994141 195.735901,808.125793 192.953918,808.455261 
	C190.208801,808.558350 187.793106,808.397461 185.335022,809.886963 
	C178.750992,809.731812 172.519760,811.333130 166.246170,810.046021 
	C164.435028,809.674438 162.178802,809.947205 162.278488,807.402405 
	C162.376648,804.896545 164.625046,804.958008 166.463623,805.023499 
	C169.933014,805.147156 173.412262,804.626465 176.874924,805.700073 
	C178.560928,806.222839 180.584351,806.293091 182.316818,805.922363 
	C190.627396,804.143738 191.562424,804.633911 191.939713,795.740417 
	C192.235840,788.759583 192.063919,781.755249 191.975769,774.762939 
	C191.915085,769.948914 189.853210,768.705322 185.697540,771.287109 
	C182.998047,772.964172 180.587921,773.623962 177.857559,771.861206 
	C174.784912,769.877441 176.080429,766.728760 176.116699,764.077881 
	C176.159286,760.967102 178.480927,760.070984 181.284882,759.893921 
	C183.360382,759.762817 183.976608,758.227722 183.982605,756.363586 
	C183.989563,754.199036 183.995728,752.034485 184.007736,749.869934 
	C184.024551,746.840759 185.287552,744.638000 188.414963,744.128601 
	C191.042343,743.700745 192.667435,742.509521 191.645447,739.830566 
	C190.697250,737.344910 188.353271,737.584290 186.604736,738.800720 
	C182.449448,741.691406 178.789993,741.969788 175.195557,737.896667 
	C173.989441,736.529846 172.070969,736.786072 170.475647,737.357178 
	C167.540237,738.408020 164.538467,737.893127 161.564682,737.964355 
	C159.297379,738.018738 157.421402,736.901794 157.217819,734.673096 
	C156.694061,728.939697 153.569061,725.971252 148.147919,724.784607 
	C145.944626,724.302307 144.949142,722.530945 145.016891,720.264587 
	C145.082520,718.069519 145.089249,715.643616 147.834396,715.116821 
	C150.170364,714.668518 151.435684,713.025330 153.129288,711.722656 
	C155.324448,710.034058 153.737411,708.968689 153.051956,707.313599 
	C159.448486,706.738586 165.933594,707.618530 172.351959,706.095642 
	C174.299850,705.633423 176.602631,705.911804 176.857773,708.680603 
	C177.106949,711.384583 175.167831,711.638000 172.999847,712.075562 
	C167.641846,713.156799 162.370331,714.434937 156.806778,714.070496 
	C152.787750,713.807251 152.747162,719.786255 148.463104,719.690552 
	C153.206024,722.463074 158.258286,722.075867 163.219940,721.960999 
	C166.555817,721.883728 168.113098,723.062988 168.059540,726.578613 
	C168.017334,729.347839 166.795807,732.978943 171.199020,733.819946 
	C175.729813,734.685303 178.944824,732.588135 181.049469,728.733521 
	C181.655777,727.623047 181.798187,726.219299 181.975006,724.925659 
	C182.154770,723.610535 181.964417,721.789978 183.541183,721.653931 
	C185.635681,721.473206 184.811188,723.502319 184.997498,724.641968 
	C185.419022,727.220520 186.130432,729.261353 189.130219,730.157715 
	C191.504929,730.867310 194.514679,740.119080 193.639053,743.489624 
	C192.440170,748.104553 193.241302,752.751770 193.037796,757.377869 
	C192.928116,759.870911 194.316360,760.892151 196.740311,761.005188 
	C207.837769,761.522583 218.920181,762.341187 230.049210,762.052063 
	C233.223022,761.969604 235.173340,761.265991 235.048096,757.606995 
	C234.955063,754.889038 236.435608,753.272095 239.615173,753.008606 
	C241.245605,754.861755 242.510132,756.678162 245.354553,755.107727 
	C244.217163,754.000549 242.502991,754.476196 241.214355,753.262817 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M153.243469,528.708984 
	C152.239304,528.706482 151.081360,528.630127 150.782684,528.083984 
	C148.897476,524.636536 146.213455,521.501648 144.643906,518.161377 
	C143.144730,514.970947 142.409058,512.875732 138.584732,512.584961 
	C136.213638,512.404724 134.638306,510.255768 133.079147,508.515686 
	C131.699875,506.976349 131.716263,505.365295 133.547226,504.333801 
	C136.564240,502.634125 137.090683,499.450317 136.431366,497.046906 
	C135.669296,494.268799 132.273849,495.117188 129.206543,494.674805 
	C128.461899,493.829315 128.522430,493.329834 129.032715,492.594421 
	C134.321518,490.705780 139.095871,492.428253 143.889496,493.469757 
	C145.572723,493.835419 146.829269,495.023773 146.895996,496.970520 
	C146.968414,499.083405 145.786301,500.338104 143.938400,501.088684 
	C142.866684,501.523956 141.678635,501.670441 140.683075,502.279266 
	C139.055801,503.274384 137.445374,504.631287 138.482254,506.633575 
	C139.493408,508.586182 141.524445,508.100281 143.348633,507.519897 
	C148.967911,505.732025 150.775330,501.087219 152.285065,495.726776 
	C153.500275,494.480103 154.643951,494.005768 156.372589,494.355713 
	C158.085922,495.602783 157.520599,496.878326 156.917847,498.065430 
	C155.080841,501.683411 157.090210,502.451385 160.129135,502.504059 
	C161.123520,502.521271 162.122284,502.562225 163.108185,502.420563 
	C164.075638,502.281586 165.244019,502.231201 165.436310,501.016052 
	C165.593445,500.023010 164.575226,499.713684 163.885010,499.304871 
	C162.349777,498.395477 160.414093,497.864441 160.599808,495.192871 
	C162.063141,493.520020 164.088379,493.239655 165.707336,491.664062 
	C167.600250,490.841583 169.259399,491.239655 170.883713,491.132416 
	C174.348602,490.903534 176.063217,492.634247 176.379288,495.970764 
	C176.425705,496.460754 176.424820,496.956360 176.493805,497.441498 
	C176.759460,499.310028 175.988480,501.782379 178.221939,502.685486 
	C180.718933,503.695221 181.957077,501.337799 183.731140,499.635132 
	C185.621277,498.668579 187.133041,498.807892 188.862167,500.026917 
	C190.143234,501.675537 190.320999,503.445160 189.105972,504.394440 
	C185.957687,506.854248 184.545822,510.987640 180.759003,512.770569 
	C178.720169,513.730469 176.720825,514.971252 177.534866,517.629883 
	C178.398819,520.451416 180.889313,520.459045 183.304779,520.220947 
	C184.627182,520.090637 185.913025,519.710022 187.629471,519.520874 
	C192.317886,519.167480 196.612717,519.150146 201.340332,519.648865 
	C203.479614,520.417480 205.343414,520.343384 207.075516,520.806824 
	C209.646469,521.494751 212.550415,522.305969 211.806320,525.887024 
	C211.151688,529.037476 208.665955,529.288757 205.570251,528.510803 
	C201.295670,526.739014 197.356873,527.755371 193.069122,528.149658 
	C187.111420,527.887573 181.592270,530.303711 175.935974,528.589905 
	C174.933273,528.286133 173.409988,528.416565 172.893005,529.667847 
	C172.377609,530.915283 173.516876,531.781616 174.251602,532.642761 
	C175.395798,533.983887 176.701660,534.924622 178.934753,533.932922 
	C180.040298,533.739746 180.789795,533.736389 181.918747,533.844666 
	C186.713882,536.089539 188.101685,538.483521 187.166748,542.387024 
	C186.215790,546.357483 183.258347,547.417664 179.644882,547.535461 
	C177.820374,547.594910 175.985458,547.457031 174.172821,547.756897 
	C172.887146,547.969543 171.975418,548.598511 172.101486,550.055908 
	C172.228027,551.518982 173.254929,552.025452 174.543350,551.949036 
	C177.193283,551.791748 179.821533,551.403564 182.493484,551.445129 
	C186.025681,551.499939 188.444077,552.759033 189.615753,556.385620 
	C191.286835,561.557983 195.233215,563.960754 200.514938,564.373779 
	C201.177216,564.425537 201.847870,564.376038 202.506363,564.446899 
	C205.373749,564.755066 208.965378,564.067566 209.499527,568.269226 
	C209.859695,571.102234 204.784958,575.052673 201.357956,574.905701 
	C199.862610,574.841614 198.425156,574.325012 196.930939,574.211060 
	C195.430786,574.096680 193.955719,574.334839 192.293884,574.454346 
	C193.296387,577.266052 197.358841,580.094666 191.828888,582.559631 
	C189.394302,580.072754 187.027481,578.207825 183.226135,578.466675 
	C181.336487,574.293823 179.588211,571.364502 174.223587,573.786499 
	C171.126785,575.184570 166.886841,572.242554 166.447998,569.437500 
	C165.903107,565.954773 169.916916,562.313049 174.417343,562.157410 
	C170.088959,560.721436 165.387955,561.785950 160.719086,560.186401 
	C160.026108,559.242493 159.824158,558.493530 159.754318,557.298706 
	C159.825027,556.355286 159.962509,555.837402 160.368637,554.993774 
	C163.977844,552.104004 164.766602,549.073547 162.887985,545.090149 
	C160.375504,539.762695 161.541885,533.979004 161.490784,528.385376 
	C161.457169,524.705444 161.235443,521.095520 160.256516,517.557434 
	C159.742630,515.700134 158.794571,514.006287 156.523071,514.338623 
	C154.585510,514.622131 154.001389,516.293884 153.770950,518.031677 
	C153.313354,521.482727 153.819565,524.962097 153.243469,528.708984 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M175.579559,672.013062 
	C175.599396,675.327087 176.347443,677.627258 180.428223,677.028992 
	C180.905197,676.959106 181.614639,676.909790 181.878296,677.180298 
	C187.740265,683.196594 194.052292,678.578857 200.592300,678.001587 
	C201.577850,678.686035 201.735550,679.457581 201.235535,680.657471 
	C200.621185,684.507935 200.296692,687.960815 195.559174,688.374817 
	C194.046265,688.506958 192.614822,689.741577 192.504684,691.256531 
	C192.137421,696.307983 188.723816,696.287598 185.115982,695.979675 
	C182.596085,695.764587 180.246445,696.507019 177.421219,696.988464 
	C176.445312,694.313171 174.273621,695.039978 172.583893,695.026367 
	C165.097000,694.966003 157.609360,695.003601 150.121979,694.996765 
	C148.468277,694.995239 146.295135,695.407471 145.519333,693.795471 
	C143.086060,688.739441 138.121689,690.216431 134.229767,689.166382 
	C130.904892,688.269348 130.152664,690.682129 129.831299,693.557983 
	C129.459015,696.889709 127.342636,698.537231 123.887161,697.936768 
	C120.751198,697.391846 120.099724,694.920105 120.081612,692.336426 
	C120.062057,689.546021 121.785553,688.170288 124.524040,688.009216 
	C125.988937,687.923096 127.666489,688.179871 127.917870,686.013062 
	C128.104477,684.404602 127.780800,682.976074 126.243912,682.173218 
	C123.002983,680.480225 122.034698,677.638428 123.471100,674.628723 
	C125.557213,670.257507 129.909119,672.364685 133.333908,672.063904 
	C136.588593,671.778015 136.904404,674.109619 137.068192,676.677551 
	C137.399841,681.877808 142.065964,679.005310 144.718216,680.729126 
	C143.799118,684.766785 140.847488,683.316772 138.549133,682.909424 
	C135.637909,682.393372 133.075211,680.657654 129.906448,679.757446 
	C130.452942,681.865479 131.413345,683.375916 133.370056,684.096191 
	C138.158127,685.858582 143.221176,686.765076 147.905060,688.895508 
	C149.611008,689.671509 150.765015,688.826355 150.715942,686.774536 
	C150.672989,684.978577 150.334534,683.126465 151.763733,681.260193 
	C152.492844,681.426636 153.105789,681.770142 153.460464,682.289856 
	C154.604050,683.965820 153.145157,687.199402 156.011368,687.661133 
	C159.069962,688.153931 162.272568,688.056519 165.383728,687.827881 
	C168.098969,687.628296 167.967667,685.309448 167.997940,683.288513 
	C168.030380,681.122375 167.616974,679.470947 164.987335,679.002686 
	C161.050797,678.301636 156.876312,678.800842 153.098175,676.381592 
	C155.244400,673.210999 159.037567,675.721497 161.729248,673.266235 
	C166.405670,672.933289 170.851700,673.411987 175.579559,672.013062 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M624.592896,147.998077 
	C626.314514,150.949066 624.194336,156.160065 630.628418,155.990692 
	C631.385986,155.618668 631.846069,155.471985 632.690063,155.777496 
	C635.370544,155.998459 637.741150,155.999344 640.555847,156.000427 
	C642.867920,156.800598 644.791748,157.473923 646.787170,158.731476 
	C646.998352,160.389435 646.999146,161.777649 647.000000,163.582321 
	C645.355469,165.678879 643.511902,165.294739 641.296509,164.324402 
	C639.227112,162.631027 637.694275,160.835739 634.918701,162.219955 
	C632.164124,163.593765 632.464172,165.636612 632.929993,168.076874 
	C633.747375,172.359161 631.310669,174.065994 627.430298,174.537979 
	C625.990540,174.713104 624.207703,174.859360 623.784851,176.560364 
	C623.516296,177.640610 624.847473,178.101089 625.734619,178.476425 
	C627.420837,179.189835 630.345032,180.065887 630.622070,178.382385 
	C631.752197,171.513565 637.584351,171.896805 642.399841,169.950653 
	C644.709534,169.399414 646.617065,169.332001 648.706177,170.694046 
	C650.151855,172.365631 651.744751,171.893616 653.197205,172.007935 
	C654.683289,172.124893 656.595398,172.027603 656.474365,174.110199 
	C656.358398,176.107285 654.441162,175.871445 652.968567,176.005447 
	C651.825195,176.109467 650.690552,176.307785 649.276001,176.732086 
	C646.836609,179.071854 642.906677,178.177658 641.237122,181.740417 
	C638.501648,182.156189 637.319214,188.168045 633.349060,182.755463 
	C634.322754,185.850922 632.858948,187.483063 632.041138,189.658691 
	C630.405762,191.620941 628.594604,191.560410 626.339172,190.342056 
	C622.183777,188.497360 618.460083,186.867188 615.390930,184.002197 
	C613.829529,182.544662 612.138855,182.891937 610.410217,184.024353 
	C609.189819,184.823853 607.808838,185.483673 606.406799,185.865433 
	C605.373657,186.146744 604.004883,186.315399 603.369751,184.958817 
	C602.868286,183.887711 603.501831,183.003189 604.238403,182.332581 
	C606.187500,180.558136 607.208435,178.813919 606.342163,175.858124 
	C605.647766,173.488922 608.091553,172.622131 609.897644,173.086777 
	C616.309143,174.736267 620.115479,169.753937 624.804565,167.328201 
	C628.707947,165.308960 628.788696,161.053299 625.368286,158.054810 
	C622.339783,157.647385 619.901428,156.608856 617.234070,155.280563 
	C616.876160,153.917038 616.289734,153.334824 615.251343,152.912048 
	C613.676941,152.271088 611.147644,152.015594 611.541443,149.762405 
	C611.914368,147.628876 614.279236,147.274811 616.630005,147.005707 
	C619.395264,147.330627 621.790588,147.663696 624.592896,147.998077 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M126.624603,534.998413 
	C128.162292,535.672607 129.683212,536.430725 129.438705,537.849487 
	C129.127502,539.655334 127.281090,538.774414 126.081871,538.997986 
	C122.118568,539.736755 117.921776,539.236694 114.098175,541.620239 
	C114.403870,543.302124 114.371353,544.707947 115.111687,546.246582 
	C117.850151,549.009766 120.684105,546.025330 123.658295,547.010254 
	C124.183258,547.224731 124.380501,547.341309 124.789032,547.728943 
	C125.000061,549.388916 124.999817,550.777771 124.999634,552.583374 
	C122.950020,553.186096 121.151215,552.446411 119.271126,551.718201 
	C114.275795,549.783508 110.586685,552.114807 109.988213,557.385010 
	C109.761154,559.384460 109.633835,561.691467 107.030762,561.817749 
	C104.464409,561.942322 103.201317,560.165100 103.028061,557.700989 
	C102.881187,555.612061 102.413277,553.586243 100.333763,552.757935 
	C96.323860,551.160828 95.646553,548.081909 95.973335,544.306519 
	C96.129860,542.498169 95.999962,540.665039 96.000351,538.843140 
	C96.002098,530.642151 96.004898,530.653442 103.821861,528.756409 
	C104.214767,528.661072 104.491409,528.086731 104.911850,527.368896 
	C108.716316,527.002441 112.431122,527.002441 117.109756,527.002441 
	C113.935875,530.818726 112.474442,533.926636 118.625839,534.994629 
	C120.403664,536.353027 121.948441,536.246887 123.780701,535.219604 
	C124.750603,534.999756 125.500206,534.999512 126.624603,534.998413 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M143.622803,474.952972 
	C144.176773,475.548523 144.354156,476.097076 144.766388,476.822815 
	C146.373108,478.481964 146.630127,480.240051 146.146454,482.584534 
	C145.871109,483.281097 145.725876,483.542480 145.256744,484.107635 
	C137.815384,485.321045 130.675217,484.509918 123.545860,484.673859 
	C115.172966,484.866486 115.170341,484.752411 114.409027,493.592957 
	C112.035057,498.592499 111.116493,498.861450 105.364059,496.276825 
	C105.152046,493.863159 104.889053,491.567291 102.374489,491.406921 
	C98.826729,491.180634 95.730499,489.463165 92.299019,489.006256 
	C91.062965,488.841736 89.317986,489.316559 89.288826,487.331696 
	C89.263863,485.632782 90.582062,484.602844 92.072548,484.212006 
	C95.269608,483.373657 98.512535,482.698669 101.756058,482.054413 
	C103.136597,481.780182 104.616852,481.451843 104.754700,479.917511 
	C105.122696,475.821442 107.950500,475.592957 110.866722,476.055725 
	C120.489388,477.582520 129.824066,473.940430 139.404419,474.596924 
	C140.672852,474.683899 141.978668,474.538025 143.622803,474.952972 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M153.244202,529.250000 
	C151.736893,525.475525 152.003555,521.812683 151.997192,518.169800 
	C151.991592,514.956909 153.174637,512.431213 156.598694,512.157776 
	C160.007187,511.885681 161.348694,514.132568 162.066589,517.332703 
	C163.470276,523.589661 162.917328,529.884521 162.952698,536.164001 
	C162.972626,539.703125 163.316284,542.990906 165.014984,546.237915 
	C167.016815,550.064270 166.341293,553.351685 161.365692,554.954407 
	C159.012024,553.859070 160.133911,549.895203 157.087326,550.074585 
	C153.238754,550.301270 155.282242,554.870361 152.697174,556.691833 
	C150.086029,557.774780 147.849655,557.891052 145.345886,556.275391 
	C142.767365,547.390503 143.123703,546.811462 151.717728,545.271118 
	C152.444305,545.000000 152.888626,545.000000 153.666168,545.000000 
	C157.010162,543.126221 157.823822,540.590637 156.233047,537.384644 
	C154.984558,534.868469 155.460251,531.758911 153.244202,529.250000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M321.405212,249.115784 
	C315.508392,248.837402 310.016754,248.674194 304.262573,248.756409 
	C299.298584,248.667999 294.597168,248.334152 289.447876,248.000153 
	C288.663330,247.186249 288.215759,246.381165 288.901459,245.246628 
	C293.490845,245.175034 297.982880,245.351898 302.737488,245.264374 
	C303.359039,244.642456 303.784607,244.397186 304.639282,244.211273 
	C307.464600,244.708832 309.868042,244.844925 312.435303,243.167053 
	C317.973999,243.769974 323.224182,241.287338 328.671875,243.738037 
	C335.614166,243.775436 342.120056,245.654892 348.757782,245.031784 
	C349.081482,245.001419 349.434753,245.029907 349.742615,245.129181 
	C351.653168,245.745193 353.869019,246.362335 354.380646,248.452606 
	C354.948029,250.770569 351.377563,249.352859 351.068542,251.661499 
	C347.453979,254.684250 344.036316,253.151260 340.308289,251.344910 
	C336.983826,248.763046 333.692108,247.456284 329.425781,248.002319 
	C326.847717,249.846985 324.378906,249.830887 321.405212,249.115784 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M112.555847,588.000000 
	C113.914970,590.565247 117.455566,590.209839 118.238899,591.869995 
	C119.333229,594.189209 114.881096,593.872742 114.650696,596.932495 
	C114.259628,602.125977 110.637100,603.644714 105.737480,602.153320 
	C105.121315,601.965820 104.423470,602.042480 103.763000,602.005371 
	C96.879486,601.617981 96.097313,600.531189 95.938385,593.558289 
	C95.790749,587.080627 100.387962,588.327515 104.571564,587.998535 
	C107.370972,587.998352 109.741333,587.999146 112.555847,588.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M151.560852,681.002075 
	C152.054733,682.986450 151.652267,685.124878 152.257675,686.924255 
	C153.805801,691.525391 150.724762,691.819702 148.249481,690.712952 
	C142.846924,688.297241 137.037003,687.301331 131.612762,685.161377 
	C127.963249,683.721558 128.552780,679.669739 126.887695,676.258545 
	C133.114136,678.792053 137.833130,683.357788 144.720673,681.269775 
	C147.041183,681.000549 149.082367,681.001099 151.560852,681.002075 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M489.545410,158.998260 
	C494.005890,162.458023 493.975525,163.155197 489.368408,164.993912 
	C482.504730,162.012695 475.738708,164.423676 469.113525,164.020309 
	C466.333679,163.851074 462.083588,163.945129 461.676819,161.751480 
	C461.029205,158.258743 465.542328,159.458282 467.652466,158.291168 
	C471.604340,156.105316 475.777161,157.971085 479.749359,158.337067 
	C482.858765,158.623550 485.964478,158.926453 489.545410,158.998260 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M883.837708,302.309204 
	C884.000000,300.611420 884.000000,299.222839 884.000000,297.417725 
	C887.678528,296.920105 891.316406,297.488403 894.630127,298.949554 
	C897.366455,300.156158 898.956360,302.889130 898.370911,305.810944 
	C897.786621,308.727295 894.965454,309.049377 892.344971,309.001465 
	C891.515808,308.986267 890.686157,308.999664 889.428345,309.000000 
	C887.341187,308.274445 886.348938,306.723480 884.688049,305.461243 
	C883.896973,304.437531 883.574951,303.591187 883.837708,302.309204 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M175.533752,700.000000 
	C175.441757,702.693726 173.267105,701.940247 171.575180,701.975220 
	C165.936066,702.091675 160.310257,701.877686 154.686462,702.904724 
	C152.010757,703.393372 152.318619,704.533142 152.956757,706.638062 
	C151.228958,706.663635 149.470383,706.182678 147.683640,706.028809 
	C145.622635,705.851318 144.009689,705.271301 144.125107,702.896179 
	C144.237061,700.592285 145.714554,699.201416 147.938187,699.096619 
	C151.562790,698.925903 155.315582,698.475037 158.807190,699.175171 
	C164.243408,700.265259 169.648941,699.797668 175.533752,700.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M105.075249,496.361938 
	C107.606247,495.415955 110.930031,499.294312 112.947418,494.363953 
	C118.180382,490.808533 123.213585,493.051910 128.635315,494.705902 
	C129.666290,499.737854 127.233955,501.118530 122.907890,501.048645 
	C116.626587,500.947174 110.593422,500.127502 105.075249,496.361938 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M493.000000,176.582520 
	C487.469788,177.867966 482.258087,175.160828 476.611511,175.796692 
	C472.674957,176.239990 471.978058,173.504242 474.684113,170.180389 
	C476.828674,170.171234 478.551819,169.734741 480.619019,169.052338 
	C481.523773,169.541962 482.065857,170.065674 482.803223,170.793839 
	C484.819794,170.851562 486.693024,171.371017 488.727234,170.270050 
	C493.769989,169.826828 492.904968,173.307343 493.000000,176.582520 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M705.624634,864.752686 
	C708.189148,864.835022 710.395691,864.713135 711.929443,862.348022 
	C715.747925,862.027344 719.525330,861.716675 723.583069,862.986511 
	C725.349182,864.340576 726.379211,865.781067 725.231995,868.179749 
	C719.332031,871.728271 713.211548,870.359497 706.806641,870.375488 
	C704.173584,869.559692 702.535889,868.360901 702.595276,865.382935 
	C703.490906,864.299011 704.335815,864.206177 705.624634,864.752686 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M953.384644,553.314575 
	C952.080139,552.855530 950.755249,553.323486 950.504333,551.999084 
	C950.226501,550.532898 951.828186,550.298584 952.800598,549.259521 
	C958.974548,549.000000 964.949036,549.000000 970.923584,549.000122 
	C971.753418,549.000122 972.583252,548.993713 973.413025,549.002869 
	C975.781494,549.028992 979.216309,549.258972 979.169922,551.569275 
	C979.113342,554.388062 975.693726,552.432434 973.423889,553.001892 
	C971.934448,553.002014 970.866455,553.001587 969.398010,553.001831 
	C964.032715,555.925049 958.922852,555.308350 953.384644,553.314575 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M732.651428,840.660400 
	C734.016907,843.428955 735.389832,845.851501 733.041138,848.656494 
	C730.451050,848.616028 727.589539,850.009216 725.193848,847.328430 
	C724.698364,843.113098 722.067993,842.861328 718.992065,842.950806 
	C717.115906,843.005432 714.867859,843.708374 713.567200,841.595093 
	C712.942993,840.581116 713.801819,839.737671 714.531372,839.054260 
	C716.622742,837.095215 718.566589,838.837158 720.497925,839.405518 
	C722.240234,839.918274 723.032959,842.768555 725.774902,841.250977 
	C727.917603,839.077332 730.064453,839.274658 732.651428,840.660400 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M960.934814,509.659821 
	C960.301514,512.356934 962.895142,516.117126 958.539673,516.878906 
	C953.854980,517.698364 949.693726,515.223389 948.262878,511.253723 
	C947.670227,510.640533 947.484802,510.225494 947.574829,509.407166 
	C950.071655,507.535950 953.430603,510.564758 955.744080,507.213806 
	C957.742310,507.523621 959.766418,507.456055 960.934814,509.659821 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M825.571533,248.999695 
	C827.265625,250.530960 829.095520,249.740021 830.639038,250.127472 
	C831.654114,250.382263 832.558533,250.837540 832.529907,252.035400 
	C832.501404,253.227417 831.577271,253.801331 830.557983,253.851135 
	C828.924683,253.930923 827.218018,254.037521 825.652161,253.667709 
	C821.872620,252.775040 818.186829,252.408081 814.572754,254.198395 
	C813.185547,254.885544 811.888672,254.905823 811.355530,253.258240 
	C810.738159,251.350403 812.278442,251.237778 813.597290,250.890625 
	C815.505127,250.388397 817.365234,249.704483 819.622803,249.048431 
	C821.714355,248.999191 823.428711,248.999588 825.571533,248.999695 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M640.269165,141.725693 
	C636.008179,141.999481 632.016174,141.969208 628.024658,142.005219 
	C624.936157,142.033081 623.607788,140.860443 624.956299,137.365234 
	C629.072083,137.001114 633.146667,137.089142 637.215576,136.975128 
	C640.615723,136.879837 641.710510,138.265015 640.269165,141.725693 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M700.308472,836.139404 
	C698.417480,834.562805 696.836426,833.218384 694.557495,832.891296 
	C692.910278,832.654907 691.607727,831.726685 692.851746,829.846924 
	C694.407654,827.495850 697.195435,827.654236 699.516541,827.278442 
	C701.274536,826.993896 702.525513,828.598206 702.845459,830.331726 
	C703.285889,832.717773 705.792419,834.432129 704.996094,837.584290 
	C703.535706,837.419739 702.076294,836.849243 700.308472,836.139404 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M710.250610,857.000916 
	C710.148254,853.296692 712.137695,851.492981 715.758850,850.968018 
	C719.983093,850.355530 721.153564,852.976257 721.947754,856.617920 
	C718.636047,856.995850 715.277039,856.999023 711.460205,857.003540 
	C710.835388,857.003845 710.668274,857.002869 710.250610,857.000916 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M955.714172,506.778412 
	C954.908264,511.715637 953.374695,512.166626 948.358887,509.069946 
	C946.748596,508.907593 945.791443,508.134735 944.458740,507.336304 
	C943.297974,506.048248 942.944458,504.903381 943.754028,503.291748 
	C948.203491,502.919617 951.894714,504.484894 955.714172,506.778412 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M917.001587,473.665833 
	C914.509766,478.401306 910.644653,477.170715 906.950378,476.365082 
	C906.005798,476.159058 905.136353,475.474182 905.241089,474.251740 
	C905.337585,473.124634 906.083618,472.276947 907.088501,472.226196 
	C910.131287,472.072449 913.121216,470.056671 916.613953,471.949005 
	C917.000244,472.445129 917.000488,472.889008 917.001587,473.665833 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M192.138367,665.325562 
	C193.325317,663.534302 194.429062,664.589111 195.304520,665.474304 
	C196.180939,666.360535 195.311539,667.324524 194.881256,668.146606 
	C192.992844,671.754639 189.829391,672.727661 186.161255,671.772156 
	C183.732742,671.139648 181.340012,670.969299 178.441833,671.000000 
	C179.002960,667.664368 181.770905,669.314148 183.787109,669.052673 
	C185.713715,668.802795 187.701996,669.075134 189.653656,668.958618 
	C191.655334,668.839050 193.348907,668.293030 192.138367,665.325562 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M153.011902,529.409668 
	C158.134567,531.441833 156.960770,537.025330 158.728073,541.090454 
	C159.597672,543.090637 157.218796,544.779846 154.378555,544.991943 
	C156.007675,539.789795 153.676376,534.858521 153.011902,529.409668 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M161.666779,673.000000 
	C161.055252,678.749023 156.530655,674.825867 153.446136,676.001526 
	C151.078506,675.991882 149.155273,676.036194 147.237503,675.953491 
	C145.699524,675.887207 144.751480,675.254822 145.744415,673.246338 
	C150.334351,671.205322 154.773438,671.951965 159.601654,672.727722 
	C160.444916,672.998413 160.889236,672.999207 161.666779,673.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M191.748108,807.362183 
	C194.395264,806.667664 196.790543,806.335388 199.592896,806.001526 
	C200.645172,807.441833 204.645126,807.870361 201.179260,810.749817 
	C198.957443,811.000671 196.915497,811.001343 194.435852,811.003418 
	C193.554138,811.003540 193.110107,811.002197 192.333038,811.000427 
	C191.179428,810.008484 190.931107,808.928955 191.748108,807.362183 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M943.706238,502.849609 
	C944.238892,504.120758 944.477722,505.241516 944.858276,506.681152 
	C945.379089,507.384705 945.515991,507.841980 945.204224,508.687683 
	C943.283875,509.002319 941.570251,509.001007 939.428284,508.999207 
	C937.705505,507.390045 937.979980,505.399231 937.836426,503.274994 
	C938.313293,502.211853 938.626587,501.423706 938.969971,500.317566 
	C941.041199,499.967163 941.966248,501.759064 943.706238,502.849609 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M884.633423,304.747131 
	C887.071350,305.202057 888.314941,306.381592 888.958862,308.658875 
	C888.881287,309.310608 888.689514,309.566498 888.212402,309.884430 
	C885.594421,309.104218 881.335693,312.280518 881.003296,306.409760 
	C881.298950,303.784180 882.756287,304.081573 884.633423,304.747131 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M919.000000,438.570465 
	C916.543396,438.784576 913.356873,439.925507 913.008423,435.388062 
	C913.773071,431.861420 916.349731,433.397156 918.589600,433.000977 
	C918.998352,434.714874 918.999207,436.428497 919.000000,438.570465 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M159.895981,672.610474 
	C155.643112,672.997742 151.285599,672.997864 146.463287,672.996582 
	C148.473511,670.504944 155.349167,670.120239 159.895981,672.610474 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M191.570679,807.000000 
	C192.000000,808.066711 192.000000,809.133362 192.000000,810.600037 
	C189.856216,810.822998 187.657608,811.418884 185.280762,810.271729 
	C185.186966,805.193420 188.911972,807.627991 191.570679,807.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M865.000000,285.625000 
	C864.676208,286.000000 864.338623,286.060333 864.030762,285.990326 
	C861.818481,285.487335 857.657349,287.159943 857.722290,284.699768 
	C857.811462,281.322571 861.895691,283.695526 864.558472,283.000000 
	C865.000000,283.750000 865.000000,284.500000 865.000000,285.625000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M110.599274,417.001221 
	C110.776802,418.220032 112.465149,419.570465 110.990204,420.605835 
	C109.887894,421.379639 108.368332,421.250580 107.187309,420.726105 
	C105.611565,420.026337 106.863266,418.746552 106.958099,417.357361 
	C108.067101,417.000397 109.133278,417.000824 110.599274,417.001221 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M725.344604,868.290527 
	C724.696716,866.575806 724.393433,865.151672 724.045044,863.363770 
	C724.444458,863.000000 724.888916,863.000000 725.666687,863.000000 
	C727.118286,864.345459 729.277893,864.965698 728.994263,867.645691 
	C728.046265,869.047546 726.905518,869.029663 725.344604,868.290527 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M128.104004,528.993286 
	C126.784737,528.899780 125.383186,529.017151 125.571983,527.695435 
	C125.801682,526.087463 127.323524,525.906982 128.692093,526.149292 
	C129.793182,526.344238 131.342682,526.659729 131.269531,527.917053 
	C131.175461,529.533508 129.490356,528.772827 128.104004,528.993286 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M123.584122,535.000000 
	C122.785759,537.971252 121.068161,537.502991 119.077545,535.354980 
	C120.387993,535.001648 121.777618,535.000854 123.584122,535.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M482.951172,170.680054 
	C482.107513,170.904114 481.340240,170.635757 481.047821,169.317810 
	C481.890503,169.094864 482.658844,169.361969 482.951172,170.680054 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M938.963806,499.714844 
	C938.451355,499.821716 937.902710,499.644653 937.177002,499.232605 
	C937.902283,497.986481 938.523804,498.224518 938.963806,499.714844 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M1073.046143,1070.608398 
	C1074.162354,1070.323242 1074.973267,1070.086182 1075.774658,1070.115112 
	C1077.118896,1070.163452 1078.906250,1070.499146 1078.314941,1072.181519 
	C1077.738525,1073.820801 1075.719482,1074.255493 1074.075073,1073.714966 
	C1072.939819,1073.341675 1070.063599,1073.137939 1073.046143,1070.608398 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M804.330505,649.238647 
	C801.956604,649.001404 799.915710,649.000305 797.437378,648.998413 
	C794.711121,646.316345 795.813416,643.782410 797.344910,641.274902 
	C797.944824,640.292725 799.193359,639.651550 799.315491,638.476501 
	C799.492920,636.768860 800.294006,634.570129 797.965820,633.751526 
	C796.128906,633.105591 794.272034,633.551086 793.578125,635.771973 
	C792.436340,639.426636 789.983154,640.745300 786.334839,639.958740 
	C783.494385,639.346252 783.071045,641.384399 782.497314,643.658203 
	C782.491394,644.691711 782.549438,645.380249 782.501831,646.414917 
	C782.368896,649.921631 785.229858,650.542664 787.025635,652.065430 
	C788.295715,653.142456 789.735596,654.207275 789.425842,656.091370 
	C789.118591,657.959778 787.429565,658.068909 785.949890,658.188965 
	C782.899475,658.436401 783.751648,659.938904 784.961792,661.373901 
	C787.000854,663.791687 790.007568,663.438477 793.091248,663.979004 
	C794.191711,664.402771 794.809448,664.879456 795.546387,665.839478 
	C796.495422,668.323120 795.981445,670.085266 793.846924,671.692017 
	C791.664246,672.691650 789.671814,672.558777 787.369629,673.022461 
	C786.392822,673.045837 785.842102,672.966919 784.910522,672.677856 
	C780.493042,669.972534 776.431946,672.488831 772.404663,672.569885 
	C766.168945,672.695251 766.036865,672.840881 765.438660,666.324402 
	C765.503357,663.266663 767.302368,660.537598 763.690308,658.217468 
	C762.013367,657.140381 764.690918,654.053833 763.814880,651.665405 
	C760.427185,655.360168 760.204956,660.334106 758.234741,664.988525 
	C754.768250,673.657166 750.892273,681.730103 745.902893,689.642700 
	C741.370239,695.921448 736.244690,701.257629 731.472229,707.325928 
	C730.938416,707.912292 730.622559,708.233521 730.069702,708.786743 
	C728.583496,710.219910 727.400452,711.500488 725.617798,712.546753 
	C725.000671,712.903259 724.662109,713.120911 724.132080,713.577515 
	C723.536438,714.265198 723.105835,714.694275 722.356445,715.234070 
	C721.897400,715.480591 721.704224,715.562195 721.211182,715.737915 
	C719.226501,716.263428 717.760498,716.997620 716.784668,718.966492 
	C715.142883,721.145325 713.120667,721.999939 710.486755,722.461975 
	C703.468750,724.976685 696.293457,725.620056 689.395508,728.591614 
	C688.055359,729.183228 687.014526,729.265808 685.566162,729.168823 
	C682.201172,728.335815 679.234985,727.740723 676.042725,729.780579 
	C674.370728,730.426575 673.022522,730.617065 671.214783,730.484375 
	C667.730896,730.433350 664.659302,730.669678 661.206177,730.220703 
	C658.989441,729.316101 657.282166,727.873230 654.702332,729.163574 
	C650.730225,729.820251 647.202148,729.726257 643.419434,728.165283 
	C638.646362,725.047974 633.284363,725.254578 628.439026,723.735229 
	C625.673096,722.867798 622.896362,721.569763 619.496094,721.420471 
	C613.608398,720.918030 609.008057,717.866028 603.638306,715.922302 
	C602.946899,715.453857 602.637146,715.109680 602.310181,714.393433 
	C602.013916,713.596191 601.834717,713.082703 600.820435,713.408447 
	C600.082336,713.602112 599.642090,713.583130 598.884521,713.327576 
	C598.002869,712.680725 597.593262,712.079529 597.079041,711.103394 
	C596.477905,709.485046 595.949524,708.294434 594.475525,707.844604 
	C593.304260,707.487183 592.076050,706.789246 592.150513,705.529724 
	C592.419250,700.985962 589.738281,699.220703 585.981812,697.742493 
	C583.563354,696.790771 584.159973,694.247192 586.141479,693.588867 
	C592.410278,691.506226 598.809998,689.024170 605.396057,692.742432 
	C607.052246,693.677429 608.294922,694.989685 609.057068,696.712219 
	C609.910950,698.642090 611.207336,699.699646 613.371399,698.991699 
	C615.365662,698.339233 614.776733,696.624695 614.753967,695.189697 
	C614.626648,687.155884 616.173584,685.607178 624.256287,685.454895 
	C630.310852,685.340881 636.124817,685.590332 638.528198,692.652222 
	C639.056641,694.205139 640.047241,694.357483 641.166870,693.401062 
	C642.641907,692.141052 644.927063,690.673645 642.794556,688.595337 
	C639.940857,685.814148 636.598999,683.251038 632.229187,683.973022 
	C626.493042,684.920654 620.571289,684.009644 614.931763,686.056824 
	C611.734497,687.217590 609.330078,683.692627 606.653259,683.714844 
	C604.279175,683.734558 603.919434,688.297974 600.350403,688.423889 
	C593.728271,688.657593 587.374207,691.271118 580.593994,690.680298 
	C577.979980,690.452637 575.121704,690.707397 572.797729,692.055481 
	C569.482971,693.978088 566.306274,691.775879 562.760986,692.378174 
	C561.711182,692.720276 561.003357,692.693542 560.001221,692.246155 
	C558.962769,691.490662 558.523804,690.607971 557.400391,689.928101 
	C556.958252,689.524902 556.797729,689.343140 556.486389,688.825928 
	C556.234863,688.005066 556.219666,687.516296 556.377808,686.669678 
	C558.547363,682.413391 562.122253,681.682190 566.126648,681.427307 
	C574.256348,680.909851 582.267334,683.189880 590.633240,682.244629 
	C588.400574,681.102295 585.815918,680.537537 585.195068,677.809387 
	C584.569031,675.058411 586.613892,673.582458 588.222229,671.947449 
	C589.642517,670.503723 590.697937,668.835449 591.455444,666.976196 
	C592.203430,665.140320 593.495789,663.805115 595.457458,663.653503 
	C600.373047,663.273865 600.986328,660.624939 599.554626,656.300171 
	C600.746643,654.642578 602.225220,654.327576 604.158142,654.312378 
	C605.688477,654.468140 605.719482,656.760742 606.988708,655.999084 
	C607.901611,655.451111 607.562439,653.892639 607.760864,652.402344 
	C609.054504,648.076843 611.326050,647.302185 615.007568,649.294434 
	C621.053223,652.566223 627.023254,652.633118 632.632751,648.110779 
	C634.325439,646.746094 636.294250,646.529236 638.264954,647.341248 
	C643.150452,649.354370 647.930298,649.069885 652.631104,646.827698 
	C654.028137,646.161194 655.458069,645.737549 656.951172,646.100830 
	C661.987488,647.326172 666.761902,645.599487 671.954041,644.711304 
	C672.506592,644.402832 672.734741,644.299500 673.318359,644.096741 
	C679.417175,645.504944 684.604858,644.910156 689.213013,640.647888 
	C691.072571,638.927856 693.745422,641.176392 696.321167,640.188049 
	C697.113220,640.058899 697.569214,640.034302 698.364075,640.059326 
	C699.731323,640.181152 700.815735,640.709839 701.919739,639.449341 
	C702.738220,638.546509 703.433960,637.997253 704.411926,637.262329 
	C708.478271,633.466370 708.478271,633.466370 703.887817,626.103149 
	C703.500793,625.684814 703.351990,625.510437 703.001343,625.056091 
	C700.726746,621.874390 698.555420,619.556824 699.902771,615.013733 
	C701.197876,610.646851 696.253357,606.914917 691.538452,606.603149 
	C687.301208,606.322937 684.306396,607.393921 682.298950,611.501099 
	C681.723206,611.569336 681.489319,611.550964 680.930054,611.400818 
	C678.688416,609.112488 676.287964,609.098877 673.408691,609.792603 
	C672.619751,609.927612 672.164368,609.957520 671.367432,609.941772 
	C668.234863,608.926331 666.666138,611.857910 663.888855,612.060303 
	C663.057129,612.043152 662.588440,611.951904 661.789246,611.681396 
	C658.074707,609.483521 654.360657,610.826294 650.465698,610.488770 
	C649.004944,610.477112 647.874268,610.383240 646.640503,611.340332 
	C646.140076,611.598938 645.930664,611.686096 645.388123,611.850647 
	C644.601196,611.966736 644.149170,611.953613 643.376587,611.783813 
	C642.071838,611.676819 641.924438,609.907410 640.340942,610.696167 
	C639.116821,611.127625 638.214966,611.057434 637.057556,610.421143 
	C635.270996,607.140198 638.960083,606.374023 639.461548,603.828369 
	C639.402405,602.241821 639.540039,601.029663 639.847534,599.466003 
	C642.494995,594.734253 647.553101,594.723755 651.437195,592.826172 
	C659.781311,588.749634 666.612366,583.508240 670.012390,574.571106 
	C670.249207,573.948669 669.796631,574.599976 670.225769,574.128845 
	C678.854858,564.655396 675.492676,553.254272 675.404053,542.366882 
	C675.345154,535.133911 671.935303,529.079285 668.993408,522.785767 
	C666.330078,517.088196 664.189209,511.195862 660.309570,506.083435 
	C654.988220,499.071228 649.158264,492.563141 642.025696,487.433350 
	C634.759583,482.207428 626.824768,478.033691 618.781921,474.095917 
	C617.463684,473.450500 615.993652,472.863892 615.340027,471.357208 
	C614.499390,469.419586 613.385254,469.251160 611.390930,470.031677 
	C607.579651,471.523315 603.745239,470.174103 601.496887,467.342438 
	C599.436157,464.747101 600.800659,460.981750 602.662354,458.491119 
	C608.829407,450.240723 615.392700,442.300690 624.330078,436.771057 
	C627.433655,434.850861 630.700012,433.106293 632.892212,429.507874 
	C633.552124,428.898743 633.980469,428.612579 634.802124,428.216064 
	C636.446411,427.649384 637.761475,427.647583 639.473145,427.777222 
	C641.529663,428.492981 643.012146,427.629578 644.561707,427.267700 
	C647.763672,426.519928 650.089722,427.124847 650.137451,431.405640 
	C650.032715,432.002655 649.968750,432.231842 649.757446,432.773987 
	C649.629761,433.903137 647.103149,434.474426 649.698364,435.499542 
	C651.157349,435.481140 652.295288,435.474182 653.783325,435.591187 
	C657.885254,437.710907 661.476440,440.222626 666.319702,440.381104 
	C668.614502,442.322418 669.899536,444.746124 671.997742,446.378265 
	C673.514099,447.557770 675.289062,447.761719 677.712219,447.111481 
	C673.908508,444.858734 669.929932,443.714966 666.720947,440.652893 
	C665.618042,438.201752 666.258118,436.762726 668.925720,436.063660 
	C671.715942,436.266602 673.925964,437.283936 676.686279,437.674500 
	C678.389771,437.958862 679.624634,438.479675 681.324829,438.779480 
	C688.563110,439.700226 695.428162,439.323486 702.584351,439.101990 
	C703.193298,439.020142 703.425781,438.871552 703.381470,438.526062 
	C702.256165,438.289368 701.314819,438.172302 700.160034,437.591553 
	C697.710022,434.352539 697.468689,431.403717 700.386414,428.633026 
	C702.377808,426.741974 701.878235,424.847565 700.338989,422.995941 
	C698.409424,420.674957 697.013794,418.133301 696.908325,414.682800 
	C697.003235,414.098297 697.066772,413.868988 697.298096,413.324341 
	C697.942993,412.434387 698.559021,412.123535 699.648499,412.021973 
	C700.444458,412.000000 700.888916,412.000000 701.666687,412.000000 
	C703.657837,411.813232 704.440613,410.405609 705.775391,409.227417 
	C707.066711,409.000000 708.133362,409.000000 709.600037,409.000000 
	C713.230408,412.075317 715.012451,416.486725 719.738464,418.269623 
	C722.068726,419.148712 720.564026,422.510071 721.659302,425.399750 
	C723.508972,422.717682 723.708374,420.647766 721.998962,418.462067 
	C719.082642,414.733215 719.664978,409.274078 715.659485,405.994904 
	C714.628723,405.151062 715.492554,403.510437 716.756714,402.250580 
	C720.427246,401.940399 723.855957,401.865540 727.585571,402.986755 
	C729.057007,402.820587 729.356384,403.453369 729.214478,404.665131 
	C729.351868,408.047821 729.605713,411.111908 730.081665,414.141052 
	C730.446411,416.462738 731.733215,418.303864 734.344116,417.660126 
	C737.055908,416.991486 736.977661,418.518585 736.998291,420.628479 
	C737.140381,421.185120 737.280762,421.370239 737.448730,421.902466 
	C735.068237,425.566406 737.102234,429.237854 736.466309,433.011230 
	C735.083862,434.460632 733.419189,434.799164 731.777832,435.881104 
	C729.429504,437.773010 727.253845,439.282898 723.588562,439.897614 
	C726.758911,440.800049 728.400146,439.332947 730.621033,439.039307 
	C732.722046,439.025330 734.438049,439.592651 736.489502,439.374268 
	C737.821533,438.915405 737.349915,438.306366 736.799866,437.430298 
	C736.150513,436.252075 736.033752,435.313232 736.454956,433.982391 
	C737.413025,432.467896 738.598450,431.729218 739.909546,431.759674 
	C743.996643,431.854492 747.483215,430.125092 751.399109,428.348145 
	C752.333679,428.202850 752.858826,428.288208 753.681641,428.751434 
	C754.572815,430.212616 756.962036,429.782654 756.667542,431.544403 
	C756.306396,433.704773 754.314941,432.793091 752.969849,432.998260 
	C749.063416,433.594086 747.482483,436.940674 749.650146,440.921265 
	C753.283813,438.534149 756.754211,438.856018 760.650330,440.648712 
	C761.305115,443.800476 760.315125,445.302643 757.232605,445.026672 
	C755.470703,444.868988 753.683044,444.999268 751.453491,444.999939 
	C750.444580,445.527649 749.786377,445.795898 748.668579,445.720764 
	C746.454224,444.428162 744.825745,441.783417 742.279236,444.662445 
	C741.697632,445.320038 740.268005,445.558105 740.866028,446.798645 
	C741.362305,447.828033 742.550293,447.456482 743.850647,447.452698 
	C746.327393,447.423126 748.484558,447.176636 750.681580,448.744080 
	C754.096741,448.998199 757.196655,448.961395 760.294617,449.023102 
	C761.365356,449.044434 762.921997,448.526947 763.301941,449.885193 
	C763.648315,451.123230 761.806213,451.388123 761.161377,452.699951 
	C758.071960,455.707275 754.315674,453.903656 750.952454,454.830170 
	C755.075012,456.287537 759.566711,454.301605 763.738647,456.767029 
	C764.000305,457.333954 763.987000,457.667328 764.002869,457.999329 
	C764.410339,466.514435 764.412476,466.524200 755.534668,468.014587 
	C754.530334,468.183167 753.365112,468.210144 753.356689,469.501099 
	C753.348145,470.819397 754.520935,470.934479 755.536987,470.968384 
	C758.015259,471.051086 761.533936,470.759857 761.347534,473.947083 
	C761.165100,477.064606 757.592102,476.979126 754.922363,476.996277 
	C752.260559,477.013367 749.598145,476.976715 746.937012,477.023102 
	C745.799194,477.042908 744.404236,476.944397 744.299561,478.577698 
	C744.210754,479.965454 745.235413,480.936249 746.453186,480.821808 
	C751.019104,480.392761 754.839478,483.968994 759.498535,483.060699 
	C760.890442,482.789337 762.725220,482.795593 762.830566,484.994019 
	C762.932434,487.118958 761.541565,487.828430 759.644897,487.979950 
	C755.689819,488.295929 751.739380,488.670776 747.785828,489.007355 
	C746.793884,489.091797 745.641602,488.998413 745.322510,490.210175 
	C744.999939,491.434784 746.057251,491.901306 746.897583,492.436310 
	C748.702881,493.585693 750.496338,494.753784 752.644775,495.958923 
	C753.628723,495.916260 754.236694,496.013245 755.094727,496.401062 
	C756.027161,496.953217 756.617249,497.514343 757.760742,497.543213 
	C758.870789,497.520752 759.685730,497.420746 760.742981,497.827148 
	C763.062561,498.227509 762.991699,499.764374 762.998657,501.627167 
	C758.848572,506.262360 753.957581,504.884949 748.807617,503.417908 
	C749.182434,505.006409 750.264648,504.656403 750.894714,504.941162 
	C751.817383,505.358215 752.667908,505.812988 753.264893,506.658539 
	C753.856445,507.496338 753.857849,508.560242 754.294250,509.431091 
	C755.477173,511.791412 757.526184,511.732635 759.109375,509.192169 
	C760.393555,507.131561 760.760559,504.423828 763.710449,503.196045 
	C766.396851,502.382111 768.766724,501.607452 771.197937,501.189850 
	C773.683838,500.762939 776.225586,500.528595 777.173584,503.904358 
	C777.816772,506.194794 779.888367,505.923492 781.700012,506.035767 
	C783.056030,506.119812 784.595581,506.321014 784.481384,508.106049 
	C784.371582,509.823181 782.812561,509.884521 781.471375,509.981598 
	C777.523376,510.267334 773.533630,509.472748 769.305786,510.718567 
	C768.717285,511.626495 768.289124,512.094543 767.299438,512.135559 
	C764.248047,512.894226 763.806458,516.338074 761.066040,517.660645 
	C760.706116,518.575867 760.208862,518.874390 759.302551,519.013306 
	C756.321411,520.750122 756.694153,522.313232 759.617920,522.831665 
	C761.693298,523.199646 763.391418,523.740295 764.837158,525.297119 
	C767.510803,528.176331 770.941467,528.621338 774.485596,527.602600 
	C776.572998,527.002625 778.894043,526.615356 780.090515,528.212891 
	C782.708618,531.708496 785.796936,530.622498 788.992493,529.968994 
	C790.767090,529.606018 792.480835,529.573730 794.343384,529.760071 
	C797.637085,530.089600 800.981201,529.510986 803.541077,526.993713 
	C807.131897,523.462646 811.187988,525.771667 814.848450,526.458008 
	C816.873108,526.837646 815.604004,528.834106 814.309448,529.792419 
	C813.001892,530.760315 810.917603,531.481567 811.506775,533.420898 
	C812.185608,535.655396 814.423157,536.623779 816.618713,536.865173 
	C819.086609,537.136475 821.602295,536.973572 824.548828,536.998535 
	C825.684448,536.397888 826.462097,536.237549 827.665039,536.750244 
	C827.999146,538.165894 828.048035,539.334534 827.982971,540.496826 
	C827.921021,541.604309 828.351562,542.996460 826.978577,543.578979 
	C825.467834,544.219971 825.071472,542.708069 824.307251,541.933960 
	C823.841370,541.462158 823.403381,540.909668 822.839905,540.596558 
	C820.340210,539.207092 818.394226,540.844177 816.367859,541.906921 
	C815.533081,542.344727 815.153809,543.052917 815.697327,543.952637 
	C816.788879,545.759399 818.535400,546.119446 820.462524,545.995300 
	C823.595032,545.793579 825.972290,546.178711 826.156067,550.296204 
	C826.313416,553.820251 829.900940,553.145447 832.038696,553.683472 
	C834.331665,554.260559 835.971985,554.609924 836.375671,557.379517 
	C836.674011,559.426147 838.027100,560.929382 840.449585,561.101929 
	C842.189453,561.225830 843.012939,562.547791 843.317200,564.120728 
	C843.545715,565.302063 842.620239,565.950500 841.776733,566.447815 
	C840.334900,567.297791 838.739258,567.241699 837.640625,566.011963 
	C833.972534,561.906067 831.328247,562.254700 829.523438,567.574951 
	C829.383911,567.986389 828.825500,568.247437 828.484436,568.600281 
	C827.640747,569.473267 824.571411,568.398193 825.853882,570.892334 
	C826.872437,572.873169 828.727112,571.729797 830.013000,570.479797 
	C833.026306,567.550842 835.100952,570.239624 837.995117,571.831909 
	C839.565674,572.071167 840.554077,572.325623 841.663757,571.411011 
	C842.711365,570.839050 843.522400,570.564819 844.709412,570.834717 
	C845.580994,570.506165 846.164490,570.014709 846.873962,569.260437 
	C847.443665,568.997192 847.887390,568.996765 848.663818,568.995728 
	C851.001953,567.412842 852.893494,567.871887 854.128052,569.809082 
	C855.682922,572.248962 857.616577,572.428589 860.128113,571.823547 
	C862.568665,571.235596 865.130737,571.149902 867.557190,571.824402 
	C869.773804,572.440552 872.288269,573.174316 872.568970,575.994385 
	C872.844177,578.760864 871.164551,580.733521 868.951416,582.112854 
	C865.567444,584.221985 855.162598,582.776978 851.920715,579.703613 
	C848.795654,578.103516 847.257568,579.625122 845.371765,581.626831 
	C840.031006,587.295959 834.187561,592.319702 825.323242,592.159363 
	C819.721802,590.138489 814.673401,593.278748 809.464722,592.746704 
	C811.894592,593.686157 814.454468,593.325439 817.330566,593.521851 
	C819.863892,593.563904 822.035095,593.301208 824.558960,593.957764 
	C826.506042,595.126160 828.286560,595.451111 830.075867,595.876160 
	C832.112366,596.359680 833.965637,597.479431 833.611511,599.963684 
	C833.234619,602.607483 831.080688,603.483643 828.788330,603.311646 
	C822.817505,602.863525 816.822632,602.782349 810.869202,602.038940 
	C807.131348,601.572205 803.339417,601.129883 798.834473,603.141052 
	C804.901733,604.554871 810.152588,604.278992 815.379333,604.359009 
	C818.518982,604.407043 821.751709,604.038086 824.880127,606.244263 
	C825.333801,606.759094 825.478394,606.995911 825.727905,607.635315 
	C825.288391,612.857483 821.686584,612.550598 818.354004,612.314270 
	C812.734009,611.915649 807.197693,610.361267 801.498596,611.064819 
	C799.925293,611.259094 798.091980,610.832214 796.912292,612.864258 
	C798.173035,614.751953 800.361328,614.331543 802.134155,614.713867 
	C806.286926,615.609314 808.503174,617.956116 808.592224,622.295410 
	C808.738708,629.433472 808.511292,636.573242 809.342651,643.706909 
	C809.692688,646.710938 809.131348,649.924561 804.330505,649.238647 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M296.578033,304.990662 
	C297.444458,305.000000 297.888885,305.000000 298.666687,305.000000 
	C301.980743,303.955048 305.072418,304.587830 308.465332,304.406708 
	C311.276886,304.889160 311.446259,302.491669 312.875092,301.133972 
	C319.358215,299.376923 324.388519,295.382812 329.949371,292.470734 
	C323.538483,293.594788 318.063019,296.635742 312.887085,301.102295 
	C311.651764,302.372009 310.308533,302.602844 308.675049,303.192169 
	C305.354706,303.663147 302.428680,304.188049 299.345764,302.279358 
	C298.529602,301.687592 298.059204,301.371552 297.294403,301.028351 
	C296.645813,300.302826 296.958679,299.813171 297.751221,299.197266 
	C302.528168,294.141083 309.847839,295.260010 314.751770,291.179108 
	C315.633636,290.445221 316.959869,289.942230 316.478729,288.540497 
	C315.960693,287.031464 314.453033,287.197723 313.142609,286.972382 
	C310.219208,286.469666 307.985260,288.142914 305.824524,289.465668 
	C304.177704,290.473785 302.690521,291.150726 300.405029,291.002075 
	C299.091278,290.876801 298.753143,290.143005 298.181000,289.232422 
	C297.683716,288.529449 297.368622,288.058472 297.026794,287.293152 
	C299.075562,285.102539 301.751648,285.412903 304.621185,285.016846 
	C307.628998,282.876343 312.824219,284.041992 312.998993,278.384644 
	C314.611023,276.525024 316.373199,276.503632 318.620728,277.723755 
	C321.474609,278.030212 323.947845,278.001709 326.413605,278.124023 
	C327.667267,278.186218 328.632446,278.911011 328.759705,280.246094 
	C328.913391,281.858398 327.904877,282.613678 326.447571,282.901428 
	C326.286743,282.933197 326.121429,282.956696 325.957916,282.958191 
	C323.778015,282.978149 321.566315,282.977814 320.589294,285.474548 
	C320.474274,285.768555 320.812500,286.541107 321.101196,286.644775 
	C323.629211,287.552765 326.547272,287.171448 328.071533,285.491974 
	C330.491089,282.826050 333.208221,282.999573 336.569641,283.000000 
	C336.922058,285.308624 337.691681,286.973999 340.309937,286.959808 
	C344.289856,286.938202 348.431091,287.488678 352.208160,286.574554 
	C356.998291,285.415222 361.410034,286.719910 365.981873,287.057831 
	C366.864288,287.123077 367.646149,287.484985 367.703430,288.478027 
	C367.758240,289.428589 367.058655,290.023315 366.252411,290.275848 
	C365.162842,290.617126 364.023621,290.840118 362.889282,290.987854 
	C361.550629,291.162231 359.995422,291.036743 360.623413,293.161438 
	C361.040863,294.573761 361.404877,296.479828 363.483459,295.639801 
	C370.967133,292.615417 378.561493,295.366974 386.542908,295.000824 
	C387.000000,295.750610 387.000000,296.499969 387.000000,297.624084 
	C387.618286,299.371460 386.480927,300.141571 385.702789,301.384857 
	C385.133850,301.986176 384.766022,302.281799 384.050537,302.695404 
	C380.925385,303.794983 378.595734,302.598633 376.166626,301.369385 
	C373.788269,300.165833 371.391388,298.567566 368.430115,299.738586 
	C373.497986,301.145874 377.858063,305.167816 383.962708,303.535645 
	C384.844574,302.993958 385.478210,302.737488 386.502014,302.601044 
	C387.951599,302.946838 388.858551,303.605713 389.835114,304.708557 
	C389.741791,305.497284 389.482361,305.992126 389.111481,306.743469 
	C389.557495,308.990204 388.174194,309.863800 386.489563,310.911316 
	C382.097900,312.228271 377.681030,310.550995 373.333435,312.585938 
	C376.612335,314.490204 379.826294,312.108246 382.997253,313.670227 
	C382.841675,318.316162 376.575775,323.775085 372.038757,323.604187 
	C370.551300,323.548187 369.076904,323.232574 367.575165,323.244904 
	C364.356415,323.271332 362.763214,324.708618 362.659912,328.092255 
	C362.477325,334.075134 362.038239,334.402008 356.065277,334.532806 
	C350.588043,334.652771 345.105011,334.349426 339.632507,334.717133 
	C338.678589,334.781250 337.613556,334.875671 336.970062,336.333466 
	C338.428467,338.262970 342.334442,337.170837 342.871399,340.522888 
	C343.345581,343.483093 340.526947,344.713470 339.500610,347.320953 
	C342.818542,348.207428 347.272339,347.692932 347.294189,353.255371 
	C347.161163,354.439362 346.855621,355.155060 345.976685,355.974243 
	C342.527466,357.464935 340.646027,354.041382 337.008270,354.443420 
	C331.485809,355.053864 327.162720,358.899841 321.655273,358.639465 
	C320.512329,358.585449 319.270752,359.205139 318.214722,358.945160 
	C307.884064,356.402191 297.590759,360.149689 287.269165,359.049347 
	C284.853516,358.791840 282.575531,359.549835 280.146637,360.352936 
	C274.023895,362.377411 268.368958,360.186737 263.603271,356.031494 
	C261.836609,354.491119 260.140472,352.967438 257.984558,351.966827 
	C254.744949,350.463257 251.348572,347.940674 250.795425,354.443726 
	C250.648209,356.174500 248.860138,356.072693 247.565674,355.965698 
	C244.826920,355.739197 242.279480,356.617767 239.747559,357.318176 
	C236.863541,358.115906 234.738861,359.862366 236.825058,363.146301 
	C238.259125,365.403687 237.044724,366.630524 235.304108,366.607880 
	C231.433273,366.557526 226.877274,367.803162 224.719727,362.733582 
	C223.936081,360.892242 222.416870,360.008820 220.357880,359.973022 
	C218.345291,359.938080 216.551758,359.072510 216.441055,356.947723 
	C216.341690,355.040527 217.575287,353.248871 219.510361,352.852783 
	C222.664398,352.207092 224.107819,350.430634 224.925629,347.416107 
	C225.539246,345.154236 227.764969,343.748383 230.011642,344.302643 
	C235.409058,345.634155 234.167664,343.396759 232.378464,340.487885 
	C231.796143,339.541138 232.237152,338.554718 232.856003,337.323059 
	C234.304672,335.872498 235.734833,335.613800 237.655899,336.686798 
	C238.315796,340.055237 240.557648,340.053833 242.799545,339.992859 
	C245.809296,339.911011 248.403137,340.362244 251.163986,342.204865 
	C255.433273,345.054199 260.857452,343.630646 265.733032,344.454437 
	C267.059418,344.678558 267.035706,343.132446 266.183716,342.379791 
	C265.477509,341.755951 264.417297,341.126556 263.544067,341.166077 
	C258.943604,341.374146 255.185059,341.111298 256.838318,334.690765 
	C257.253845,333.076935 255.579330,332.162781 253.896408,331.987061 
	C252.856506,331.878448 251.735977,331.532959 251.568726,330.450806 
	C251.350479,329.038727 252.738434,329.217499 253.610748,328.879425 
	C254.364380,328.587372 255.373795,328.265961 255.695709,327.654907 
	C259.271790,320.866760 265.341858,324.469696 270.382050,324.192871 
	C272.763000,324.062134 273.446503,326.483734 273.868896,328.448273 
	C274.383453,330.841309 273.471985,332.717346 271.200623,333.758972 
	C270.030182,334.295685 268.312012,334.328674 268.434021,336.092133 
	C268.549561,337.761749 269.992004,338.704071 271.408264,339.124573 
	C274.537964,340.053802 276.851593,342.236450 279.425049,344.005035 
	C280.384705,344.664490 282.103119,345.067474 281.437408,346.944855 
	C280.834290,348.645782 279.516083,349.048523 277.872528,349.007812 
	C276.044708,348.962524 274.247650,348.990021 272.463287,348.348785 
	C271.076416,347.850372 269.618530,348.191589 269.455933,349.915985 
	C269.313690,351.424347 270.826141,352.925140 271.839539,352.542603 
	C279.405945,349.686340 286.693451,354.616394 294.217072,353.108215 
	C296.424530,352.665710 298.423584,352.442810 299.873718,350.581390 
	C300.502563,349.774200 301.161224,348.930145 300.557648,347.896759 
	C299.948639,346.854095 298.845795,346.943420 297.891479,347.238342 
	C294.793945,348.195709 291.703217,348.508667 288.627716,347.267090 
	C286.567535,346.435394 284.890350,345.676178 285.787750,342.579071 
	C286.918365,338.677216 285.057037,336.470459 280.775452,337.012390 
	C276.986176,337.491974 275.443512,335.196381 275.449707,332.086670 
	C275.455841,329.002380 278.011627,328.091949 280.734528,328.022430 
	C285.038971,327.912598 289.714203,327.087860 290.487183,333.507538 
	C290.639587,334.773041 292.065430,335.977356 293.437408,335.821381 
	C298.012970,335.301239 301.224640,338.275421 304.914856,339.988892 
	C307.865387,341.358917 310.699432,341.482117 313.439545,339.664856 
	C314.464630,338.985016 315.410889,337.403198 313.472015,337.260559 
	C307.771484,336.841187 303.439880,332.082764 297.276978,332.271301 
	C295.912720,330.380524 297.049347,329.486694 298.466125,328.375824 
	C303.155151,326.972015 307.322540,327.100555 311.255035,329.632568 
	C312.659607,330.536957 314.425354,331.499908 315.932434,330.497681 
	C320.657410,327.355591 326.008575,327.520966 331.279633,327.103180 
	C331.896271,327.054291 332.585815,326.924652 333.084198,325.881165 
	C330.385162,325.334290 327.841400,325.526764 325.335663,326.261383 
	C319.485443,327.976440 313.632355,328.390900 307.637207,326.927246 
	C304.903137,326.259735 302.050690,325.999084 298.859436,327.014282 
	C295.247864,327.403778 292.760803,326.184906 292.294037,322.920288 
	C291.837677,319.728485 294.530029,318.458679 296.980164,317.397888 
	C299.137756,316.463776 301.407654,316.237762 304.086609,317.015747 
	C308.043213,321.506775 312.757324,318.742096 316.952087,319.198425 
	C313.332581,318.543945 309.512604,320.673126 305.764832,318.104980 
	C304.365265,315.329254 305.379883,313.699371 307.906128,312.351593 
	C311.454468,311.113190 314.795288,311.743530 318.058563,311.260254 
	C314.838745,311.611816 311.614716,311.445984 308.012695,311.534973 
	C303.220642,312.609406 298.784729,313.454926 295.108002,309.780182 
	C293.145935,307.819153 292.313507,305.814301 296.578033,304.990662 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M691.677490,362.749237 
	C696.361023,364.283081 697.772217,368.617371 700.843384,371.698608 
	C701.379028,372.384125 701.515747,372.839752 701.203979,373.682800 
	C697.481018,374.947693 696.297119,376.170502 697.410034,380.593506 
	C699.514343,388.956482 697.676208,391.985382 689.624878,391.016571 
	C686.214355,390.606140 684.435120,391.301971 686.487549,395.293427 
	C687.654724,397.563202 687.038513,400.391754 688.632141,402.834290 
	C689.875854,404.740448 691.093140,405.813904 693.621887,405.990875 
	C695.041931,405.207916 696.044495,405.156555 696.996338,406.576965 
	C697.307983,408.104065 697.615906,409.208160 697.961914,410.656128 
	C698.380371,411.385345 698.527039,411.845245 698.219971,412.689819 
	C697.388916,413.517853 696.758545,414.003815 695.580322,414.290894 
	C694.422363,414.425903 693.652710,414.486542 692.512512,414.373993 
	C688.108582,412.972229 685.565063,409.972198 683.727295,406.350433 
	C682.761536,404.447235 681.817078,402.863678 678.224854,403.284943 
	C682.152893,406.490540 685.333679,409.429718 687.929871,412.901459 
	C688.835083,414.111908 689.908875,415.161407 689.961975,416.786865 
	C690.049622,419.473480 688.052246,421.092102 685.380127,420.455261 
	C683.890747,420.100281 682.703613,419.132690 681.118286,418.181885 
	C678.401062,416.447235 676.342590,414.507477 674.352661,411.968842 
	C672.565796,409.568237 670.580994,407.677887 670.333801,404.481049 
	C670.449707,403.888763 670.524109,403.658539 670.752808,403.103180 
	C672.647034,399.783905 669.734924,396.688843 671.265015,393.366882 
	C672.186707,391.878479 673.659058,391.714752 674.619568,390.812500 
	C670.133179,388.153229 665.352600,385.898834 662.292236,381.431854 
	C661.264221,379.931335 659.686157,379.477753 657.874023,378.619202 
	C656.035828,376.987915 656.022156,375.339172 657.053589,373.251404 
	C659.120911,370.969391 661.232727,369.626617 664.106140,370.295258 
	C661.713745,369.668732 659.614319,370.145386 657.629883,372.264893 
	C653.072083,376.856934 651.676514,376.659576 647.529602,370.787476 
	C647.351135,367.925964 648.655701,366.018524 650.235291,364.297760 
	C652.348145,361.996033 652.161011,359.932404 649.878235,357.910553 
	C648.522522,356.709778 647.447693,355.200439 645.906067,354.204376 
	C644.080505,353.024902 642.118713,352.442291 640.688904,354.581970 
	C638.977783,357.142700 636.352112,357.315125 633.429932,357.852692 
	C630.075195,358.753143 628.673462,355.465729 625.760437,355.058044 
	C625.219116,354.801880 625.011047,354.680817 624.525452,354.327881 
	C622.291138,351.388336 619.798096,349.381287 616.797668,347.863464 
	C614.667053,346.785614 612.204163,345.713135 612.767578,342.274109 
	C616.443787,340.851105 618.457153,343.170959 620.509033,345.198608 
	C625.756897,350.384430 628.465027,351.001068 634.590332,348.266296 
	C635.479492,347.869263 636.675781,347.791351 636.712952,346.520569 
	C636.741821,345.532867 635.931213,345.151215 635.052490,345.001617 
	C633.362183,344.713928 632.841553,343.666138 633.235962,342.107330 
	C633.628601,340.555542 634.841797,340.159882 636.271118,340.023102 
	C640.036804,339.662720 643.588867,340.193207 646.729736,342.451202 
	C651.139587,345.621460 655.127686,347.828522 660.258484,342.856720 
	C662.992981,340.207031 667.019531,342.509918 668.858154,345.269348 
	C671.300049,348.934326 675.633972,349.825500 678.943542,353.003906 
	C681.185059,353.002869 682.533203,352.436249 684.037598,351.333374 
	C685.432373,350.750916 686.569580,350.713287 688.057800,350.848145 
	C689.458740,351.064941 690.531616,351.356506 691.848022,350.540649 
	C692.616699,350.360229 693.058777,350.402802 693.745239,350.777283 
	C695.604065,351.323242 696.689148,350.140228 697.977478,349.557770 
	C700.326660,355.349548 700.326660,355.349548 711.719238,353.272369 
	C714.587036,350.694885 717.370178,350.999176 720.617188,352.690857 
	C726.486084,352.756775 732.002869,352.394897 736.635376,356.954834 
	C737.127747,357.165955 737.255554,357.331909 737.192871,357.747681 
	C736.898987,360.449158 737.864685,361.355499 740.319397,360.452698 
	C741.418884,360.048340 742.373901,360.623138 742.494629,361.768158 
	C742.601135,362.777863 741.562561,363.045471 740.775879,363.175140 
	C736.961670,363.803925 733.802979,365.382172 730.940674,368.163361 
	C729.142578,369.910309 726.274841,369.236603 723.870667,368.375519 
	C721.762634,367.620453 722.506958,366.119629 722.959351,364.354431 
	C723.287781,363.595154 723.641418,363.260834 724.408936,362.876526 
	C726.010132,362.409607 727.353333,362.263672 728.550171,361.078125 
	C727.511292,359.911530 726.168091,360.431671 725.017761,360.404205 
	C720.731140,360.301819 716.467896,360.518372 712.179260,359.619537 
	C709.777954,359.116272 706.817139,358.510315 704.625671,361.495117 
	C702.890991,362.628754 701.740417,364.388092 699.309448,363.233154 
	C697.298279,360.287781 695.078613,358.330475 691.365845,358.007690 
	C688.663940,360.630890 685.642212,361.880585 681.871338,362.371399 
	C679.031067,362.374756 676.678284,361.646912 675.655396,359.360107 
	C673.660645,354.900665 669.700378,354.788544 665.737549,354.350311 
	C669.630493,357.788177 674.142700,360.428589 677.783020,364.147949 
	C679.517090,365.919617 680.718262,364.086121 682.368530,363.090637 
	C685.573059,362.423370 688.384766,360.830658 691.677490,362.749237 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M616.247009,261.766846 
	C614.702454,261.997742 613.384338,262.139587 612.105957,261.974060 
	C608.379883,261.491760 608.056824,263.378845 608.993286,266.618744 
	C607.887024,268.323486 607.532715,269.779999 608.118408,271.709900 
	C605.084412,274.048096 603.010437,271.912476 601.822632,270.012665 
	C599.402527,266.141907 596.689758,266.679016 593.337830,268.293915 
	C587.619080,271.049194 587.388489,270.911469 584.966858,264.846191 
	C584.642334,264.033295 584.381531,263.034943 583.359131,263.575745 
	C578.064575,266.376648 577.912781,261.876465 577.128601,258.974670 
	C576.617188,257.082153 576.117432,256.221588 574.039307,255.949997 
	C567.255981,255.063431 560.526428,252.682266 553.802734,256.449036 
	C552.152954,257.373322 550.513306,255.733871 550.521912,254.395554 
	C550.538818,251.765884 552.860718,249.527496 555.026672,250.248566 
	C562.173523,252.627686 569.540344,251.429657 576.734863,252.416428 
	C577.877075,252.573105 579.036072,253.001022 580.147217,252.892746 
	C581.561462,252.754929 583.528687,252.574677 583.368286,250.614029 
	C583.237061,249.010895 581.486755,249.026398 580.152405,249.015610 
	C576.825928,248.988708 573.495972,248.909561 570.173401,249.023926 
	C567.484802,249.116470 565.355774,247.868027 564.630737,245.611893 
	C563.831787,243.125900 566.266541,242.500763 568.148560,241.770126 
	C569.392151,241.287338 570.714355,240.442429 570.528381,238.926086 
	C570.283813,236.931976 568.495117,237.040939 567.012451,237.017044 
	C563.686707,236.963470 560.358887,237.019852 557.033997,236.942047 
	C555.413391,236.904129 553.726624,236.088318 553.672302,234.535202 
	C553.590271,232.194077 555.820679,233.033051 557.052551,233.061172 
	C560.103210,233.130814 562.574097,232.110825 564.603394,229.897873 
	C566.871582,227.424362 569.150879,227.398285 571.388977,229.904114 
	C574.020569,232.850403 579.387939,233.749130 578.294434,239.544815 
	C578.040955,240.888229 580.007202,241.299911 580.996033,240.881927 
	C587.154724,238.278534 589.937622,242.903091 592.640015,246.632370 
	C595.319153,250.329590 599.108032,252.922897 601.540588,256.871368 
	C603.734619,260.432556 606.183655,258.675873 608.248291,256.145477 
	C609.350708,254.794449 608.394592,251.526062 611.613159,252.179459 
	C614.105530,252.685455 615.357727,254.760925 616.413391,256.883514 
	C616.633057,257.325073 616.750671,257.817413 616.957642,258.643188 
	C617.174133,259.914642 617.120667,260.783661 616.247009,261.766846 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M640.591370,219.646851 
	C643.500122,217.854523 646.101624,216.449341 649.316956,216.997665 
	C650.299316,217.165207 651.427490,217.053299 651.704346,218.301056 
	C651.914062,219.246246 651.178955,219.789749 650.473572,220.268372 
	C646.859314,222.720734 645.814880,227.853271 640.648438,228.940674 
	C638.213745,229.453125 638.288086,234.578247 640.744263,236.615524 
	C642.549744,238.113098 645.100525,239.024261 643.425537,242.004883 
	C641.627258,245.204819 639.462524,247.823151 635.577209,249.069702 
	C624.397827,252.656509 614.173218,248.217331 603.783325,245.608154 
	C600.443787,244.769531 598.111633,241.555099 596.481506,238.551056 
	C594.881714,235.602936 597.586731,232.464569 601.199402,233.139511 
	C607.179138,234.256668 613.033447,232.507416 618.982239,232.809647 
	C621.363953,232.930634 624.782410,232.117004 624.853760,228.667343 
	C624.931458,224.912674 621.482483,224.355576 618.805176,224.135712 
	C615.020386,223.824875 611.158630,224.079010 607.362122,224.435715 
	C605.382629,224.621704 603.560730,224.520584 602.429260,222.980148 
	C601.550354,221.783661 601.747986,220.040344 602.416382,218.659378 
	C603.434021,216.556625 605.575989,216.678116 607.242371,217.147125 
	C611.231018,218.269821 615.079651,218.129105 619.033875,217.098831 
	C620.452820,216.729111 622.095825,216.789978 623.302612,217.920624 
	C628.489502,222.780350 634.218750,222.487717 640.591370,219.646851 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M521.874756,298.643616 
	C523.054993,302.032104 525.779480,301.824829 528.136536,301.947418 
	C531.760498,302.135925 535.402405,302.051941 539.034851,301.984833 
	C542.216553,301.926056 545.054810,301.942261 547.355530,305.035248 
	C548.764587,306.929504 551.543030,307.572235 554.144653,306.399719 
	C555.047424,305.992859 555.932800,305.375061 556.874329,305.264496 
	C558.553101,305.067413 561.026978,304.061005 561.569336,306.339447 
	C562.170898,308.866882 559.713623,308.963867 557.885498,308.983795 
	C555.558899,309.009186 554.039856,309.815155 554.019165,312.799286 
	C551.618286,314.627075 549.684753,312.447601 547.108948,312.301971 
	C540.821045,310.385132 535.112366,307.909271 528.741333,306.285706 
	C526.888733,305.583221 525.352600,305.062836 523.468689,304.529541 
	C522.684753,304.447937 522.235474,304.420868 521.449951,304.387390 
	C520.890564,304.362152 520.667236,304.348419 520.110962,304.294006 
	C519.343628,304.165497 518.918274,304.046082 518.203735,303.764709 
	C517.162720,303.263733 516.561096,302.613312 515.367920,302.507690 
	C513.785645,302.416687 512.512512,302.380646 510.959595,302.041656 
	C509.725098,301.611786 508.880096,300.989014 507.503906,301.076111 
	C506.089447,301.139587 505.019897,301.049377 503.896973,300.080170 
	C503.543060,299.451965 503.749146,298.878876 503.533539,298.714264 
	C503.015259,298.318604 503.665649,298.536774 503.085846,299.343781 
	C502.032013,300.051544 501.145782,300.123566 499.904358,300.005066 
	C499.150879,299.846283 498.735107,299.711945 498.030273,299.435730 
	C497.536896,299.242065 497.341400,299.163086 496.853882,298.969788 
	C495.978088,298.622131 495.383118,298.433990 494.423889,298.396820 
	C492.981445,298.352783 491.867310,298.281921 490.443024,297.950562 
	C489.464111,297.664398 488.815002,297.533997 487.811859,298.024963 
	C479.477966,296.937378 471.487885,295.914215 463.389771,296.501770 
	C454.844574,297.121765 446.309143,297.834839 437.481812,299.473328 
	C436.601105,299.647491 436.110016,299.754669 435.280518,299.953003 
	C434.087891,300.318512 433.231140,300.581879 432.037781,300.800659 
	C430.909943,300.903870 430.106903,301.021515 429.045776,301.443237 
	C428.530182,301.566589 428.315704,301.591187 427.773438,301.590149 
	C426.557556,301.376678 425.724823,301.061768 424.774109,300.242493 
	C423.723938,297.861786 424.572418,296.683258 426.660675,296.124207 
	C428.018066,295.760834 429.496735,295.641022 430.760620,294.242065 
	C433.585815,293.170593 433.421844,292.246460 431.278625,290.853363 
	C429.423920,289.647827 427.551819,287.361633 429.002960,285.637299 
	C430.372437,284.009979 433.413757,284.884033 435.686127,285.245392 
	C436.881104,285.435455 437.169098,286.756653 436.496155,287.624725 
	C434.002167,290.841888 436.777588,291.213959 438.739563,291.712738 
	C442.207428,292.594391 445.382263,294.831696 449.245300,294.010284 
	C450.380981,293.768799 451.856323,293.940613 451.709747,292.286591 
	C451.566650,290.672211 449.433472,291.062439 449.169403,290.800934 
	C446.638763,288.295166 442.527588,289.458527 440.275879,286.721405 
	C438.720032,284.830170 439.592102,283.891174 441.629425,284.061737 
	C445.729004,284.404877 450.854279,281.955627 453.001556,288.128815 
	C453.385315,289.231964 455.053009,288.957916 456.606049,289.001556 
	C460.856964,289.343781 464.466919,291.608490 468.755127,290.234375 
	C470.237732,289.559387 471.519928,289.213287 472.703064,288.656616 
	C474.606659,287.760956 477.763428,287.263458 478.371033,288.166443 
	C482.404480,294.161011 488.172333,288.791260 492.881012,290.702148 
	C495.225739,291.653687 497.900024,292.045929 499.990479,293.658234 
	C504.875214,297.425659 510.278351,298.413574 516.604492,297.011536 
	C518.936951,296.129517 520.452820,296.797028 521.874756,298.643616 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M346.737427,354.631836 
	C346.093842,350.729126 345.139404,347.654846 340.018341,349.759338 
	C338.933319,350.205261 337.606445,350.061279 337.207092,348.567322 
	C336.852722,347.241669 336.857697,345.916687 338.193512,345.011505 
	C339.896393,343.857666 342.647430,343.259430 341.707214,340.395386 
	C340.940552,338.059998 338.559174,339.282257 336.892822,338.976990 
	C335.446381,338.711945 334.121674,338.122070 334.230377,336.566528 
	C334.393616,334.230438 336.072906,333.078033 338.308502,333.048004 
	C344.285004,332.967743 350.267944,332.857971 356.238770,333.053406 
	C359.861786,333.171997 362.183136,333.162140 361.143585,328.181061 
	C360.026794,322.829987 362.266022,320.461060 370.440155,321.795532 
	C374.277618,322.422058 375.932587,321.143341 377.924957,319.318970 
	C379.591339,317.793060 382.164886,317.193024 382.957611,314.344788 
	C383.458496,312.530609 384.538635,311.512054 385.779449,310.225769 
	C386.908417,309.185364 387.816864,308.369507 388.862671,307.276825 
	C391.815643,306.828308 394.698456,306.088287 397.632812,307.987549 
	C398.842072,307.131256 399.565735,306.141205 400.783997,305.217194 
	C400.998779,305.002441 401.000000,305.000000 401.001221,305.000000 
	C403.053986,303.820923 405.483002,303.610474 407.770844,302.226440 
	C410.025116,302.185455 411.671509,301.181213 413.675415,300.145081 
	C414.813446,299.257843 415.394806,298.476776 414.215973,297.216492 
	C413.998779,297.000000 414.000000,297.000000 414.000000,297.000610 
	C413.022339,295.575531 411.585693,297.006042 410.236023,296.217285 
	C408.369263,292.734833 410.314209,291.604248 413.032257,291.046265 
	C415.069977,290.627899 417.138397,290.359039 419.596558,290.012390 
	C421.933807,289.120789 423.301208,290.189514 424.820312,291.675690 
	C424.999023,292.168304 424.999268,292.335358 424.999756,292.753052 
	C425.948761,297.990936 421.811279,294.583618 420.406708,295.789398 
	C420.778015,297.337372 422.165649,298.504639 422.180511,300.562256 
	C422.139008,301.398865 422.019501,301.862732 421.652832,302.623505 
	C421.275421,303.088562 421.088654,303.242615 420.561035,303.515411 
	C410.185608,305.830841 400.887634,309.812897 391.933228,315.434570 
	C391.573273,315.846069 391.451569,316.028259 391.139465,316.475037 
	C390.800629,316.895355 390.652191,317.051117 390.244873,317.394867 
	C389.611481,317.775543 389.225372,317.940979 388.537842,318.182007 
	C387.646912,318.448914 387.117798,318.755310 386.350708,319.295258 
	C383.862335,320.425476 381.509827,321.200989 380.209381,323.947815 
	C379.669647,324.535767 379.322418,324.830566 378.654572,325.265228 
	C378.150452,325.515259 377.940430,325.594238 377.407928,325.752258 
	C375.663483,325.871552 374.708557,326.623993 373.847046,328.083618 
	C373.203644,328.837646 372.686615,329.245361 371.866211,329.801392 
	C370.796997,330.361450 370.063385,330.830994 369.145386,331.630859 
	C363.476349,335.245575 359.765228,340.344452 355.705444,345.083008 
	C352.924683,348.328674 350.331604,351.738373 346.737427,354.631836 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M792.664856,259.677673 
	C798.693542,259.710449 804.287781,261.858215 810.143127,259.587921 
	C816.587830,257.089142 823.606018,256.609802 830.498596,256.051514 
	C832.482910,255.890793 834.993042,257.224030 834.372437,258.820160 
	C833.523254,261.004242 834.182617,264.860870 830.052551,265.005188 
	C825.066956,265.179291 820.239868,263.451660 815.211975,263.952942 
	C812.528870,264.220398 809.884216,263.983917 808.685303,267.247681 
	C807.983643,269.158051 806.072632,268.995514 804.400391,268.997009 
	C800.911133,269.000183 797.421875,268.999268 793.466309,269.000000 
	C789.083252,265.064240 784.119263,266.919708 779.822388,267.299591 
	C775.518494,267.680084 771.230347,267.860687 766.463257,267.998077 
	C765.576172,267.477875 765.050842,267.331543 764.210693,267.785095 
	C764.000000,267.998779 764.000000,268.000000 764.000000,267.999878 
	C761.851257,268.246643 762.472107,269.554474 762.763245,270.915070 
	C763.092346,272.452820 762.286499,273.477142 760.760925,273.828613 
	C759.549561,274.107727 758.398010,273.899109 757.634338,272.835236 
	C756.667542,271.488220 757.155090,270.181610 758.276733,269.310150 
	C761.534668,266.778870 761.149597,264.235992 758.883484,261.214691 
	C757.068665,258.794952 758.090393,256.871277 760.715393,255.676880 
	C763.940125,254.209579 763.805786,256.542175 764.141541,258.572723 
	C764.439941,260.377197 765.734192,261.948151 767.444641,261.790955 
	C772.896973,261.289917 778.587646,264.402374 783.856934,260.676636 
	C784.708557,260.074493 786.080444,260.208160 787.607178,260.000244 
	C789.308044,258.866730 790.746521,258.624939 792.664856,259.677673 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M458.767944,246.243530 
	C461.799469,251.305878 466.595032,248.846237 470.708405,248.989914 
	C473.323792,249.081299 476.334106,248.386475 477.352844,251.862701 
	C478.348114,255.258743 475.823975,256.529358 473.409454,257.829437 
	C471.279053,258.976562 469.182526,260.278015 470.665741,263.239594 
	C472.457764,266.817688 475.382446,267.642487 479.035675,266.443970 
	C483.786163,264.885468 484.360931,265.393127 484.021912,270.287231 
	C483.785736,273.696442 482.332184,274.303497 479.486023,272.772644 
	C475.829620,270.805969 472.339722,270.559448 468.979675,273.589203 
	C465.431702,276.788391 462.089874,274.309814 458.895081,272.783936 
	C451.783417,269.387360 451.819122,269.312653 457.499237,263.999268 
	C464.251007,257.683411 464.250977,257.683380 458.046143,249.347412 
	C457.830933,248.087387 457.879364,247.221237 458.767944,246.243530 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M842.803345,509.000000 
	C845.196533,508.896820 847.426331,509.523193 848.699219,507.424805 
	C850.861572,503.860138 853.738342,504.588379 856.393677,506.336182 
	C858.498108,507.721344 860.601440,508.123962 862.974060,508.005737 
	C866.989197,507.805664 870.773132,508.205444 874.541565,510.119812 
	C879.221863,512.497437 881.154968,511.289368 881.999695,506.158813 
	C882.371826,503.898712 883.504150,502.974884 885.679565,503.058746 
	C887.751831,503.138611 888.003967,504.552643 887.990295,506.173126 
	C887.987427,506.505707 887.988037,506.838623 887.999451,507.170959 
	C888.268555,514.998901 888.268677,514.961731 880.322998,515.014709 
	C875.940552,515.043945 871.946655,514.911316 867.761475,518.032288 
	C864.321350,520.597656 860.191223,516.433350 857.723694,513.700989 
	C854.835449,510.502594 852.594604,511.304108 849.062988,512.020264 
	C843.837219,513.080139 838.458862,517.366394 832.795410,512.594055 
	C831.747375,511.710907 829.600342,512.125427 827.959167,511.954559 
	C826.237366,511.775238 825.156311,510.915009 825.115051,509.114746 
	C825.067566,507.043854 826.270813,506.065033 828.225281,506.061401 
	C829.365234,506.059296 830.952515,505.897827 831.570801,506.543365 
	C834.694580,509.804962 838.611389,508.883392 842.803345,509.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M501.149506,237.697266 
	C500.165588,239.124924 498.943176,239.580124 497.330078,239.965271 
	C494.950989,241.443649 492.198517,241.534149 490.194092,243.718506 
	C489.669525,245.099518 489.339081,246.199051 489.004303,247.648071 
	C489.771637,248.970367 490.944305,249.495331 491.869415,250.724915 
	C491.827026,252.831436 492.265411,254.554352 492.951477,256.621063 
	C490.696259,259.015289 488.807007,254.580063 486.251556,256.789429 
	C482.871796,256.298920 484.348297,254.429474 485.064270,252.926178 
	C486.070923,250.812561 485.743774,249.210388 483.279327,249.122513 
	C480.071503,249.008163 478.035217,246.735657 475.214294,245.269867 
	C475.203125,241.947678 478.000183,242.276581 480.149384,241.245209 
	C481.926025,241.001999 483.238678,241.547729 484.590546,241.860092 
	C484.838806,241.917465 485.012634,241.963745 485.044037,241.939758 
	C485.321045,241.727966 485.232208,241.560425 484.534302,241.277466 
	C483.224670,239.664490 481.298615,238.759491 481.619263,236.230423 
	C483.775726,234.495697 485.518982,235.866104 487.304230,236.498428 
	C489.812439,237.386856 492.094727,237.385391 494.580475,234.660507 
	C490.161072,234.259476 486.236603,234.169098 482.332062,234.014755 
	C481.133606,233.967392 480.186462,234.753693 479.863007,236.460968 
	C478.392365,239.280350 476.755096,241.276382 473.158997,240.483978 
	C472.040222,239.495712 471.823334,238.496979 471.797913,237.069305 
	C472.098267,234.346207 475.643585,232.439514 472.894104,229.325455 
	C475.233490,227.324875 477.421967,225.567535 480.637634,225.012848 
	C482.077423,225.777618 482.396942,227.764816 484.682648,227.045197 
	C486.272156,227.314774 487.598022,228.034271 488.806915,227.873627 
	C493.369659,227.267380 496.985718,228.542953 499.779266,232.724762 
	C501.178375,234.245682 501.080933,235.867264 501.149506,237.697266 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M612.681152,341.859070 
	C613.276855,345.035431 615.792053,345.848175 617.967529,346.977692 
	C620.906250,348.503448 623.857544,350.000000 624.610962,353.987244 
	C624.143616,354.538818 623.742004,354.635406 623.005066,354.596619 
	C622.079041,354.362518 621.510071,354.079773 620.680542,353.604248 
	C619.545593,352.820709 618.815369,352.053802 617.805908,351.134827 
	C616.976929,350.382019 616.410767,349.798035 615.458557,349.206177 
	C615.028625,348.936523 614.857849,348.825500 614.434448,348.541229 
	C613.361877,347.739349 612.496826,347.184845 611.253296,346.630035 
	C604.814392,343.076508 599.459229,338.415710 592.864136,335.071228 
	C587.404724,331.438843 581.660706,328.987549 576.169250,325.440857 
	C574.879089,324.514984 573.842529,323.807739 572.345215,323.301025 
	C571.287170,322.942780 570.539001,322.629608 569.477173,322.293823 
	C568.999695,322.129913 568.810852,322.058014 568.347778,321.857544 
	C567.898865,321.628845 567.724182,321.528687 567.299622,321.262146 
	C566.435242,320.553925 565.669495,320.324036 564.631958,319.954102 
	C564.158997,319.756531 563.973083,319.667480 563.520386,319.421082 
	C562.442078,318.539764 561.423096,318.350433 560.092468,318.076324 
	C559.576538,317.916351 559.373352,317.838531 558.881104,317.605133 
	C557.628418,316.575378 556.343323,316.025574 555.881470,314.379028 
	C561.266663,312.662872 565.417358,317.174622 570.527344,316.948517 
	C573.671875,316.809357 576.829041,314.459167 579.995789,317.714050 
	C581.163208,318.913940 581.807678,319.415283 581.552673,321.185791 
	C581.147583,323.999176 583.166382,325.510590 585.502441,324.563721 
	C590.430603,322.566040 594.007202,326.068451 598.195984,326.967926 
	C599.569092,327.262756 600.855652,328.174133 599.939392,329.746887 
	C599.368652,330.726746 598.020264,331.123016 596.962585,329.970886 
	C596.064941,328.992981 594.910522,329.021851 593.369263,329.006531 
	C593.258118,332.177155 596.164917,332.398285 598.165649,333.552460 
	C601.083069,335.235474 603.886841,337.189789 607.626099,337.993225 
	C608.021729,340.927734 610.649475,340.785400 612.681152,341.859070 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M543.238892,252.760361 
	C544.822388,252.163651 546.043701,252.114182 546.483948,253.559341 
	C546.931580,255.028564 545.809265,255.779327 544.577271,255.860046 
	C539.654602,256.182495 538.222534,259.801666 536.865906,263.639648 
	C535.848206,266.518616 525.443237,268.720764 523.677795,266.381226 
	C522.398010,264.685272 523.850403,263.685272 525.224854,262.917542 
	C526.685547,262.101685 528.335388,261.432526 527.822754,259.243805 
	C527.325012,257.118958 525.606018,257.074066 523.909912,256.957916 
	C520.137268,256.699615 518.116577,252.573135 514.084351,252.586761 
	C512.840881,252.590958 512.904968,250.752853 513.146729,249.618225 
	C513.541809,247.764267 515.068909,248.258377 515.852234,248.896530 
	C521.112976,253.182404 526.960693,252.123901 532.839172,250.997940 
	C536.435669,250.309082 539.174866,254.134628 543.238892,252.760361 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M828.369385,536.952393 
	C827.250122,536.999695 826.500244,536.999451 825.375488,536.998413 
	C824.461487,534.285278 822.277588,535.142578 820.595764,535.015198 
	C818.613220,534.865112 816.177246,535.170410 816.270386,532.253662 
	C816.358032,529.505981 818.401184,528.814209 820.869263,528.989685 
	C822.719238,529.121277 824.536926,529.013245 825.999573,527.618591 
	C827.468689,526.217773 830.723816,526.455383 830.500854,523.650085 
	C830.298889,521.108887 827.167725,521.470093 825.158630,519.810852 
	C828.043457,518.552795 830.556580,518.734985 833.032593,519.580688 
	C839.031128,521.629456 844.892578,522.202087 850.649292,518.743591 
	C852.729736,517.493713 854.770081,517.824585 857.411072,520.887695 
	C847.950562,518.966187 844.358032,529.325378 835.876221,528.638489 
	C833.479065,528.444275 832.611389,530.761414 832.290161,532.740845 
	C831.928101,534.971741 831.037476,536.463074 828.369385,536.952393 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M728.676758,209.653061 
	C729.494568,210.423325 730.468872,210.931259 730.411682,211.259171 
	C729.594604,215.940964 731.335693,220.186615 732.253967,224.765640 
	C726.266663,226.182159 722.237488,222.403854 717.352661,220.044312 
	C716.756775,217.706894 715.346741,216.485321 713.234497,215.932526 
	C708.639893,214.730042 708.751526,212.524826 711.943115,209.332977 
	C713.085022,207.724579 714.526978,207.367310 716.068542,207.678406 
	C720.130005,208.498032 724.382324,207.846237 728.676758,209.653061 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M544.782043,295.310272 
	C551.503113,295.881653 557.422363,292.428802 564.037415,292.792023 
	C572.151917,293.237610 580.212708,293.802979 588.243408,294.990631 
	C589.899414,295.235535 591.925415,295.141724 591.496033,297.606049 
	C591.168396,299.486847 589.908203,301.453491 587.802673,300.860107 
	C583.704651,299.705139 579.223083,300.937012 575.333984,298.303314 
	C571.951965,296.013000 567.464905,295.928680 564.309692,297.657715 
	C557.716675,301.270660 551.858582,299.503754 545.377380,297.047638 
	C544.651062,296.602753 544.506531,296.142517 544.782043,295.310272 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M725.651367,229.846588 
	C727.199158,234.289352 729.572083,233.449768 732.139587,231.353043 
	C733.357849,230.358170 734.727051,229.457260 736.095276,230.487350 
	C737.597168,231.618134 737.165100,233.508102 736.728516,235.040436 
	C736.049866,237.422287 734.271667,236.530884 732.358643,236.043503 
	C728.269958,236.678101 724.412598,237.962402 720.877930,235.780029 
	C715.456726,232.432785 710.508362,234.487823 705.751709,236.951645 
	C703.155884,238.296204 701.361877,237.844345 700.535583,235.521057 
	C699.403442,232.337616 702.307434,233.201050 703.850891,232.985336 
	C706.364563,232.634048 707.073181,230.277985 708.554749,228.839615 
	C710.902466,226.560349 712.789062,225.225433 716.285767,227.306656 
	C718.854553,228.835632 722.266479,228.948181 725.651367,229.846588 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M782.521606,344.968384 
	C780.432129,344.994232 778.768860,344.989197 777.105835,345.007141 
	C775.395508,345.025574 773.420898,345.078186 773.283447,347.248352 
	C773.131287,349.650848 774.914978,350.739685 777.120117,350.945465 
	C778.936096,351.114960 780.780884,350.982666 782.595093,351.163208 
	C783.866272,351.289703 784.803101,352.106750 784.709290,353.483429 
	C784.623169,354.748077 783.533203,354.991364 782.502380,354.955902 
	C778.786621,354.828094 774.942627,355.862335 771.734192,352.580994 
	C770.022400,350.830353 767.283325,350.441803 764.824341,349.986938 
	C762.177490,349.497345 761.290833,347.472565 761.162170,345.288025 
	C761.003662,342.595795 763.305664,342.398834 765.303406,342.010315 
	C770.712708,340.958344 776.170776,340.838531 781.649536,341.032410 
	C783.052002,341.082031 784.357300,341.425507 784.673035,343.041443 
	C784.924133,344.326416 784.030823,344.768768 782.521606,344.968384 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M514.152466,273.274963 
	C523.094849,276.012573 531.355103,280.141022 540.835938,279.099945 
	C542.314392,278.937592 544.639893,279.245178 544.363342,281.519775 
	C544.148132,283.289886 542.329834,284.018951 540.500000,283.969330 
	C539.511230,283.942505 538.482239,283.933075 537.546814,283.658752 
	C533.773254,282.552155 530.135376,280.370148 526.062622,282.925079 
	C525.349976,283.372162 523.952209,283.303864 523.135864,282.915894 
	C517.580994,280.276123 511.972961,278.053162 505.616547,278.800446 
	C505.231415,278.845734 504.429443,278.048065 504.367340,277.571533 
	C504.240845,276.600464 505.044983,276.211334 505.945526,276.058716 
	C508.703033,275.591400 511.559265,275.398010 514.152466,273.274963 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M798.248840,429.000549 
	C792.959534,428.955627 788.451294,425.593445 783.353027,427.770081 
	C782.497498,428.135284 780.677612,426.721527 781.771301,425.627563 
	C783.362244,424.036255 784.725708,421.320129 787.901306,422.475098 
	C789.760559,423.151337 791.061523,425.045563 793.237183,424.967926 
	C802.369446,424.642090 811.395203,425.686493 820.407715,426.977783 
	C822.094910,427.219513 823.705688,426.742126 825.193237,425.911255 
	C826.544067,425.156799 827.996582,424.931183 829.520996,425.018524 
	C831.111206,425.109680 832.728149,425.430267 832.672180,427.383301 
	C832.616638,429.322601 830.853638,428.971680 829.611877,428.975983 
	C819.313782,429.011841 809.015564,428.998993 798.248840,429.000549 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M414.452271,247.023865 
	C417.543823,248.494308 420.132263,249.689011 423.114685,247.550049 
	C424.373657,246.647125 426.438416,246.556854 427.171967,248.024887 
	C429.360016,252.403839 433.131775,252.114929 437.012329,252.037186 
	C438.376282,252.009872 440.483887,251.413712 440.649841,253.533401 
	C440.825256,255.773773 438.815338,255.993927 437.088776,255.973984 
	C434.933167,255.949112 432.613129,256.282745 430.653015,255.614304 
	C422.114441,252.702499 413.327606,251.446548 404.381195,250.972504 
	C402.762360,250.886719 401.158112,250.428131 401.330658,248.499741 
	C401.494934,246.663971 403.266266,247.041122 404.526886,247.021027 
	C407.690094,246.970581 410.854645,247.005829 414.452271,247.023865 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M608.382568,271.831726 
	C606.499573,270.246063 602.219482,268.332855 608.644104,267.005615 
	C617.733765,267.741882 626.460205,268.395172 635.199951,267.020996 
	C637.267212,266.695953 639.310242,267.198944 641.376831,265.815796 
	C643.919983,264.113647 645.927979,266.646698 647.999268,267.781494 
	C648.815125,268.228485 648.944702,269.361847 648.260254,269.937378 
	C646.492065,271.424164 644.077332,273.620880 642.183777,272.520874 
	C637.541870,269.824341 632.764221,271.171570 628.104980,271.111359 
	C621.668213,271.028137 615.171143,270.243774 608.382568,271.831726 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M665.209106,264.212585 
	C665.197937,264.750702 665.221802,264.916077 665.269592,265.074249 
	C666.255005,268.338867 670.457092,265.616821 671.306519,268.030853 
	C671.997559,269.994720 668.024658,271.293884 669.488098,274.155212 
	C670.264404,275.673096 670.487000,277.608612 667.968567,277.884338 
	C665.916870,278.109009 663.142761,277.635559 663.300293,275.377441 
	C663.713135,269.461182 660.819397,264.105621 661.021790,258.319214 
	C661.161438,254.326813 663.049072,253.098663 666.905823,254.530197 
	C669.366150,255.443405 671.727356,256.623627 674.353577,257.781342 
	C673.011230,262.155884 666.997375,259.969330 665.209106,264.212585 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M805.660889,413.996155 
	C809.059021,413.931488 810.539856,412.812744 809.374268,409.607208 
	C808.663452,407.652374 809.125671,406.108429 811.063965,404.962585 
	C812.784607,403.945465 814.325806,404.419891 815.775269,405.231964 
	C817.686279,406.302643 817.024658,408.099487 816.583740,409.671143 
	C816.260132,410.824371 815.919495,411.874146 816.660583,412.998779 
	C817.570557,414.379547 819.888428,415.483032 817.842224,417.572479 
	C816.131775,419.319092 813.953186,420.514435 811.546814,419.837585 
	C808.423340,418.959106 805.304321,419.010193 802.151672,418.995209 
	C800.200195,418.985901 798.222961,418.734497 798.179260,416.279907 
	C798.127625,413.374451 800.543518,414.155334 802.230347,414.015869 
	C803.223022,413.933838 804.227905,413.999664 805.660889,413.996155 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M510.457367,233.000122 
	C510.174561,226.829788 510.190674,226.994003 516.208313,227.804504 
	C524.394592,228.907120 532.565552,230.306244 540.890991,230.048248 
	C542.319397,230.003998 544.747742,229.211624 544.610657,231.893463 
	C544.498718,234.082092 542.379944,233.933334 540.719666,233.994720 
	C530.761353,234.362930 520.839661,233.631546 510.457367,233.000122 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M368.267822,240.246887 
	C367.787659,238.254181 366.556763,238.011322 365.119324,237.994858 
	C363.435150,237.975555 361.671356,237.965790 361.242554,235.792877 
	C361.024872,234.689743 361.587585,233.757141 362.599487,233.424484 
	C364.519562,232.793289 367.139923,232.214523 368.111969,234.049286 
	C370.773407,239.072815 374.642090,240.305725 380.073364,240.424286 
	C384.142242,240.513123 388.166138,242.660126 392.604431,243.945663 
	C392.998779,244.000610 393.000000,244.000000 392.999390,244.000610 
	C393.377808,244.387283 393.525665,244.847595 393.222351,245.692596 
	C390.846924,251.015030 387.070587,248.951950 384.307251,247.361420 
	C380.591583,245.222748 377.014404,243.511612 372.650085,243.983109 
	C370.080170,244.260757 369.241974,242.442841 368.267822,240.246887 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M486.308655,257.161713 
	C486.046753,255.696655 485.875549,254.208282 487.355316,253.594513 
	C488.892303,252.957047 489.068146,254.712708 489.908752,255.323807 
	C490.685699,255.888611 491.510864,256.387115 492.658020,256.956787 
	C496.061890,257.036346 498.873016,256.360291 501.268829,254.302307 
	C501.872437,253.783829 502.670380,253.201767 503.397949,253.175613 
	C505.102661,253.114349 507.724518,252.028992 508.279358,253.997055 
	C509.010284,256.589600 506.571625,257.012268 504.427673,257.027100 
	C501.499359,257.047394 500.919128,258.934814 501.014008,261.379456 
	C501.129059,264.342590 502.815430,263.890808 504.770416,263.261719 
	C506.071167,262.843170 507.450500,262.878937 508.332306,264.191315 
	C509.039276,265.243530 508.358093,266.055481 507.643799,266.779724 
	C506.427979,268.012421 505.146149,268.218781 503.684967,267.112000 
	C500.353455,264.588562 496.594940,263.129883 492.409760,262.906219 
	C488.828857,262.714935 489.102325,258.605408 486.308655,257.161713 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M737.435059,357.998291 
	C737.002441,357.997559 737.001160,357.498779 737.000610,357.249390 
	C736.980530,355.328888 737.479004,354.411163 739.416748,353.954742 
	C745.849792,352.439453 752.711243,354.227997 758.948425,351.288879 
	C759.976135,350.804565 760.747375,351.801147 761.462219,352.505890 
	C763.079529,354.100250 764.367188,355.947571 767.020630,356.332184 
	C769.003052,356.619568 769.395020,358.606232 768.622681,360.316528 
	C767.566345,362.655731 765.668457,361.717041 764.528320,360.618530 
	C759.052185,355.341888 753.426208,354.523376 746.557434,358.302795 
	C744.376709,359.502686 740.803589,358.171814 737.435059,357.998291 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M761.307251,320.281250 
	C764.738525,319.006409 768.476990,318.012787 772.609009,317.008972 
	C777.975708,316.999146 782.948914,317.007996 787.922180,316.995392 
	C789.729858,316.990814 791.616638,317.226410 792.800476,315.314148 
	C793.719299,313.829834 794.751282,312.253204 796.545288,314.345886 
	C798.050415,316.101593 797.929810,317.952179 796.479187,319.748138 
	C795.664001,320.757507 794.567688,321.006866 793.372925,320.980713 
	C782.787964,320.749207 772.189270,321.549957 761.307251,320.281250 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M716.658264,402.041595 
	C715.933289,404.656616 716.274414,406.794891 719.129761,408.115387 
	C720.469727,408.735077 721.068115,409.929199 720.962280,411.363220 
	C720.753113,414.195557 721.578735,416.322906 723.924133,418.242523 
	C725.967896,419.915253 724.847717,425.715515 722.676331,427.223083 
	C721.820801,427.817139 721.142456,427.505737 720.404541,427.113342 
	C718.941467,426.335327 718.774170,425.000275 719.279724,423.698883 
	C720.737427,419.946014 719.686035,418.313843 715.542358,417.805176 
	C711.090088,417.258606 712.332214,412.174530 710.049072,409.364258 
	C713.479858,408.662537 715.172607,411.129303 716.994324,414.227631 
	C718.625183,410.863708 716.282043,409.257660 715.110168,407.894257 
	C712.696350,405.086060 712.858459,403.395020 716.658264,402.041595 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M647.446045,246.117691 
	C650.316650,245.994415 653.398804,245.381424 654.331299,248.315598 
	C655.295959,251.350739 652.428589,252.720505 650.383057,254.443954 
	C645.850830,258.262512 640.606323,256.813477 635.572693,256.838837 
	C633.810608,256.847748 632.938354,255.449219 633.147034,253.724121 
	C633.365417,251.918594 634.898987,251.430389 636.389709,251.135117 
	C637.955383,250.824997 646.225281,247.078537 647.446045,246.117691 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M408.748840,267.001709 
	C414.575012,268.983612 421.071106,268.152161 425.125031,273.538788 
	C427.016937,276.052643 430.225403,274.339478 432.593781,275.408081 
	C433.656891,275.887756 435.687592,275.585541 435.379974,277.520599 
	C435.174225,278.814911 433.713898,279.002441 432.516174,278.967865 
	C429.324432,278.875793 426.638031,278.456696 423.453857,276.624268 
	C418.890991,273.998413 414.394379,269.430023 408.028534,270.952393 
	C406.775543,271.252045 405.379852,271.032532 404.057373,270.943054 
	C402.640015,270.847137 401.280365,270.337921 401.320892,268.679535 
	C401.369049,266.711914 403.077057,267.090088 404.326080,267.022247 
	C405.650116,266.950348 406.980927,267.002960 408.748840,267.001709 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M728.309998,333.471680 
	C725.507629,337.155548 721.421936,337.936096 717.395447,337.121735 
	C713.452148,336.324127 709.739624,335.732605 705.699097,336.531128 
	C701.648621,337.331573 700.096741,333.202118 697.401123,331.323364 
	C697.182312,331.170898 697.347229,329.982025 697.693359,329.604523 
	C698.446838,328.782959 699.682922,328.788818 700.427307,329.403198 
	C707.064453,334.881378 714.254395,333.238068 721.573608,331.354584 
	C723.907715,330.753876 726.043274,332.110840 728.309998,333.471680 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M361.401123,251.000153 
	C361.161835,249.827515 361.322449,248.656876 361.742126,247.242493 
	C364.236938,246.729050 366.437683,247.278412 368.267456,248.306061 
	C372.351440,250.599762 376.612274,251.372696 381.210144,251.039368 
	C382.603271,250.938370 384.476013,250.589935 384.802429,252.710678 
	C385.135742,254.876068 383.687866,256.756714 381.906464,256.679382 
	C377.183472,256.474457 371.583832,259.588104 368.173767,253.137985 
	C367.157013,251.214752 364.170898,251.061829 361.401123,251.000153 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M451.341736,229.272705 
	C450.036804,228.840622 448.994202,228.855942 448.126526,228.473953 
	C447.013611,227.984039 445.247131,227.658005 445.668335,225.950485 
	C446.023651,224.510071 447.650909,224.891663 448.660522,225.051224 
	C453.816620,225.866089 458.255676,224.088959 462.760498,221.263184 
	C465.954468,221.055588 468.920837,220.974060 471.857910,221.229446 
	C473.161346,221.342804 474.510162,222.356079 474.403076,223.799820 
	C474.285400,225.386353 472.662598,224.800217 471.614746,225.006363 
	C469.478180,225.426697 466.972382,223.938156 465.099182,226.618805 
	C463.731598,229.249084 461.260132,229.199326 458.831177,230.134338 
	C456.111786,230.610565 453.808014,230.918884 451.341736,229.272705 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M696.999878,313.595917 
	C692.072327,313.727142 688.789429,315.442993 687.798218,320.773804 
	C687.225525,323.854156 681.222839,326.721313 678.167175,325.809174 
	C676.678589,325.364807 676.022339,324.343536 676.147827,322.833130 
	C676.274414,321.309387 677.400757,321.091370 678.628906,321.016846 
	C681.557983,320.839142 685.437927,321.583405 682.465393,316.333435 
	C681.761047,315.089417 683.569031,313.296967 684.507996,313.506683 
	C688.094116,314.307739 689.890808,311.345276 692.446899,310.150269 
	C696.108704,308.438263 697.330688,309.406464 696.999878,313.595917 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M544.531067,295.001801 
	C544.998413,295.446075 544.999268,295.889679 545.000000,296.666046 
	C544.436279,297.306549 543.835815,297.933685 543.314331,297.873840 
	C536.356567,297.075317 529.441895,296.758179 522.335510,299.034851 
	C520.542236,298.427429 519.082092,297.851166 517.313354,297.137817 
	C515.378723,291.565674 510.026337,294.105652 506.099091,292.059631 
	C509.647034,290.246185 513.349060,290.154449 515.885681,291.392151 
	C523.850830,295.278717 532.259155,294.508545 540.578735,294.990784 
	C541.736938,295.057922 542.902405,295.000763 544.531067,295.001801 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M492.428680,251.002777 
	C490.412903,251.030548 489.018066,250.800598 489.010864,248.365448 
	C492.172211,248.115585 494.984650,246.345596 497.736908,245.820328 
	C505.190002,244.397903 512.238037,239.926132 521.342957,241.422302 
	C511.601868,246.422272 502.787842,250.412628 492.428680,251.002777 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M731.631897,242.953186 
	C732.869812,244.836807 733.879822,246.895569 731.764404,248.347397 
	C729.848999,249.661957 727.939941,248.282974 726.344849,247.194427 
	C725.439331,246.576447 724.978088,245.326569 723.355469,245.047699 
	C720.055298,247.169678 716.952698,246.958603 713.388855,245.301514 
	C711.487061,244.905533 710.419556,244.247894 710.041138,242.336304 
	C717.280334,237.796036 724.314880,239.348648 731.631897,242.953186 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M391.683960,278.751953 
	C392.003418,278.997559 392.501709,278.997955 392.750854,278.998352 
	C392.822205,279.548615 392.644409,280.098450 392.231384,280.826385 
	C387.598755,281.240753 383.419159,282.749939 379.061462,283.377014 
	C373.793121,284.135132 368.894043,282.993103 363.833832,282.983398 
	C362.093811,282.980072 360.845673,282.406708 361.183594,280.362885 
	C361.486084,278.533447 363.252319,277.380981 364.397827,278.202698 
	C371.145111,283.042847 377.619507,279.201202 384.592224,278.000916 
	C387.122009,278.161804 389.301208,277.606506 391.683960,278.751953 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M763.787476,502.883636 
	C762.926453,505.540924 761.151733,507.663727 760.442505,510.499268 
	C759.773926,513.172180 756.976562,513.246460 754.557434,512.883667 
	C752.281616,512.542358 752.340698,511.140564 752.691833,509.193115 
	C753.210388,506.317169 751.011963,506.114136 748.874878,505.978088 
	C747.516357,505.891571 745.343811,506.584442 745.344055,504.385193 
	C745.344238,501.821198 747.864563,501.537964 749.442383,502.109589 
	C753.785278,503.683105 757.962830,503.728455 762.611816,502.052795 
	C763.118408,502.310638 763.310059,502.566406 763.787476,502.883636 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M848.196411,436.000763 
	C854.835754,434.189056 857.298889,439.597046 860.845520,442.601349 
	C862.429443,443.943085 861.853455,446.375580 860.274536,447.970337 
	C859.210327,449.045074 857.469543,449.473938 857.255371,447.571411 
	C856.534241,441.165710 851.449036,441.910645 847.278687,440.976685 
	C845.251770,440.522766 841.611267,442.430481 841.527954,439.296539 
	C841.445679,436.200195 845.066101,436.069092 848.196411,436.000763 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M492.516235,221.020203 
	C488.775543,220.949753 485.422638,221.354279 482.254761,219.933426 
	C481.466339,219.579834 480.608887,219.217850 480.569763,218.311905 
	C480.523895,217.249710 481.466095,217.040848 482.313995,217.037750 
	C487.469818,217.018890 492.747803,216.362579 497.738220,217.272476 
	C501.004333,217.867996 503.591095,221.130295 506.823914,222.732178 
	C508.247223,223.437424 507.564606,224.794983 505.957306,224.925949 
	C503.297241,225.142700 500.608337,225.260376 498.279022,223.557327 
	C496.660339,222.373810 495.040558,221.209946 492.516235,221.020203 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M917.622253,514.929626 
	C924.614990,514.560669 931.200317,513.546387 937.861938,513.993652 
	C938.856995,514.060425 939.872314,513.920837 940.855286,514.050415 
	C942.306030,514.241699 944.294312,514.320129 944.344604,516.117615 
	C944.402283,518.178162 942.356140,517.959412 940.922119,517.973389 
	C935.594116,518.025391 930.265320,517.997742 924.468384,518.000000 
	C919.659729,521.608154 919.659729,521.608154 913.248047,517.239197 
	C913.030457,513.896606 913.374756,513.722778 917.622253,514.929626 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M660.391602,196.997162 
	C659.733582,196.412720 659.571838,195.740936 659.176025,195.263016 
	C658.104553,193.969360 654.921021,194.098755 655.708801,191.826126 
	C656.512756,189.507065 659.306946,190.189407 661.399048,189.971878 
	C664.568542,189.642319 667.146790,191.073105 669.821716,192.385559 
	C672.232727,193.568451 674.371277,195.324966 673.627502,198.027374 
	C672.641052,201.611694 669.787109,198.846573 667.384399,199.008759 
	C665.170410,197.576706 663.192078,196.615479 660.391602,196.997162 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M879.724121,448.054138 
	C878.790222,446.056122 878.733398,444.427551 880.537231,443.443481 
	C882.230469,442.519745 883.839600,443.126587 884.961853,444.526825 
	C886.728577,446.731232 888.425842,446.930542 890.854919,445.367065 
	C892.130310,444.546173 894.274109,444.400757 895.442688,445.841492 
	C896.575867,447.238403 896.069946,449.047119 895.178955,450.505066 
	C894.413879,451.756866 893.462097,452.917542 892.459473,453.994263 
	C891.589050,454.929016 890.696106,456.179657 889.145569,455.656921 
	C887.705688,455.171478 888.047424,453.775848 888.007507,452.677002 
	C887.894836,449.579010 886.294922,448.476624 883.439575,449.376221 
	C881.925171,449.853302 880.883911,449.482574 879.724121,448.054138 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M426.194641,263.125000 
	C424.739380,263.592957 423.354462,264.447723 422.530975,263.594421 
	C417.591583,258.476440 411.110443,261.132843 405.402710,259.990356 
	C403.648315,259.639221 401.154083,259.638611 401.454010,257.309723 
	C401.726929,255.190445 404.188049,256.288391 405.695374,255.997925 
	C412.979919,254.594269 419.213165,259.668549 426.345459,259.372253 
	C427.377136,259.329376 428.702820,260.082153 428.715485,261.491852 
	C428.727112,262.785980 427.558258,262.896423 426.194641,263.125000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M767.021606,512.407776 
	C767.495544,511.738129 767.991089,511.479919 768.743286,511.110229 
	C774.315796,512.941833 779.718567,512.063965 785.100220,511.446716 
	C787.680725,511.150726 790.425903,509.952026 792.472290,512.493835 
	C793.357361,513.593018 793.263611,515.269165 792.021301,516.077759 
	C789.601685,517.652710 787.034058,517.315247 784.504517,516.021851 
	C780.415710,513.931152 776.000732,515.488403 771.766907,514.985901 
	C769.983887,514.774292 767.513245,515.936646 767.021606,512.407776 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M768.733398,466.556610 
	C770.998596,464.519836 773.397522,465.033112 775.677673,465.042572 
	C777.922241,465.051880 779.039612,466.297241 778.946960,468.490997 
	C778.860107,470.545746 778.468262,472.534393 775.926697,472.930664 
	C774.303711,473.183746 772.627869,473.164581 771.034973,473.527527 
	C768.848328,474.025848 766.772339,474.611481 765.526978,471.955688 
	C764.090210,468.891571 766.195862,467.811371 768.733398,466.556610 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M546.359436,225.533691 
	C544.825012,222.698975 543.931213,219.826355 546.704468,218.081238 
	C550.075500,215.959869 554.018677,216.752502 557.726746,217.649277 
	C558.126038,217.745819 558.586914,218.703506 558.565063,219.243378 
	C558.425781,222.683228 550.151611,227.204056 546.359436,225.533691 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M517.614258,219.625885 
	C514.261169,220.862732 507.214600,216.905380 506.595612,213.780899 
	C506.510101,213.349335 506.542114,212.794601 506.747620,212.422867 
	C507.568695,210.937912 514.809753,209.918427 516.444702,211.010223 
	C520.302307,213.586273 520.693054,215.804977 517.614258,219.625885 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M458.599152,245.945114 
	C458.832489,246.856659 458.666229,247.711487 458.249969,248.783157 
	C457.555542,249.000168 457.111115,249.000336 456.333313,249.000549 
	C453.445618,250.943909 450.990234,250.842087 448.316895,248.323334 
	C444.409973,247.993423 440.821045,248.038300 437.235443,247.949127 
	C435.778656,247.912888 433.792755,247.667755 433.668671,246.073929 
	C433.488770,243.762878 435.684814,244.124390 437.618042,244.000153 
	C438.749664,243.998978 439.500580,243.999191 440.626953,243.998779 
	C446.835022,243.716431 452.560059,244.413239 458.599152,245.945114 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M453.748047,187.362091 
	C453.915009,184.717255 454.593048,183.057358 457.627625,183.006683 
	C459.618805,181.598206 461.362762,181.353821 463.625031,182.708969 
	C464.314484,183.469864 464.630188,183.940338 464.972961,184.705109 
	C463.842377,185.583359 462.693878,186.186340 461.525391,186.747665 
	C458.111847,188.387512 459.104919,189.941940 461.800415,191.739441 
	C461.684296,196.499771 457.889191,195.621674 455.393341,195.719467 
	C452.673920,195.826004 453.336395,193.483017 453.958313,191.355911 
	C453.179138,190.008255 452.930756,188.928818 453.748047,187.362091 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M589.254028,316.634766 
	C585.833252,315.246704 581.444580,313.542908 582.447693,309.413208 
	C583.414001,305.435425 588.590759,305.709778 591.924744,304.192230 
	C592.878357,303.758148 594.434631,304.733917 595.733337,304.950378 
	C597.309021,305.213074 598.927246,305.636658 598.689148,307.559631 
	C598.465515,309.365143 596.704590,308.890961 595.448669,308.992157 
	C592.642639,309.218323 589.374756,308.527557 588.765930,312.779480 
	C589.820679,313.991852 590.068054,315.069916 589.254028,316.634766 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M816.914307,487.000000 
	C812.302063,486.997345 808.173157,487.007568 804.044434,486.983673 
	C802.897949,486.977020 801.467651,487.053589 801.309326,485.545715 
	C801.141296,483.945435 802.341370,483.114838 803.808594,483.098450 
	C810.082397,483.028320 816.357361,483.038391 822.631714,483.060516 
	C823.933228,483.065094 824.896423,483.637207 824.799622,485.139618 
	C824.701782,486.659729 823.589844,486.942688 822.351685,486.965363 
	C820.700745,486.995605 819.049072,486.990143 816.914307,487.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M856.761108,496.277985 
	C856.923218,497.506500 856.828247,498.525635 856.000183,498.803436 
	C849.569885,500.960419 843.384705,504.454407 836.203186,502.832092 
	C834.957336,502.550659 833.305176,502.187805 833.372620,500.541840 
	C833.452393,498.597107 835.245422,499.028473 836.463135,499.028412 
	C842.233337,499.028137 848.076355,499.624969 853.049011,495.579651 
	C854.062988,494.754822 855.552673,494.673584 856.761108,496.277985 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M754.235229,428.740906 
	C753.555542,429.001129 753.111084,429.001068 752.333313,429.001099 
	C746.993896,427.046967 740.871460,427.746582 737.072021,422.376160 
	C737.000000,422.000000 737.000000,421.500000 737.000000,421.250000 
	C738.440613,421.014404 739.394287,420.027924 740.475464,419.306122 
	C743.870361,417.039581 746.926514,418.339935 748.345276,421.350494 
	C749.741272,424.312592 751.414429,425.275940 754.359131,425.037262 
	C755.336060,424.958099 756.499268,425.059509 756.699646,426.297546 
	C756.960938,427.912079 755.291626,427.820190 754.235229,428.740906 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M847.734497,534.316101 
	C849.748840,537.693848 847.781860,540.698853 847.569397,543.718079 
	C847.441589,545.535095 841.630981,545.662109 840.680847,543.737976 
	C839.262512,540.865479 839.694031,537.854492 841.517700,535.219177 
	C843.045105,533.012085 845.036621,532.187073 847.734497,534.316101 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M626.882141,296.710114 
	C622.479004,297.039307 618.205505,298.020691 615.538208,293.442688 
	C615.128052,292.738770 613.846191,292.405975 612.901001,292.180847 
	C611.279663,291.794617 609.491455,291.768005 609.167297,289.596283 
	C609.009705,288.540283 609.205139,287.450562 610.402527,287.243744 
	C612.341736,286.908783 615.232605,286.178467 615.946777,287.977753 
	C617.436829,291.732117 620.265564,291.791412 623.364319,292.418427 
	C625.218933,292.793732 627.709412,293.530121 626.882141,296.710114 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M937.556335,503.000000 
	C939.155334,504.617096 940.410645,506.214142 939.035767,508.665649 
	C933.241638,505.979095 927.842224,510.346619 922.201233,509.821198 
	C921.061401,509.715027 919.882935,509.876648 918.771484,509.661133 
	C917.937805,509.499481 917.228516,508.746979 917.647095,507.834778 
	C917.836853,507.421356 918.877502,506.976929 919.277283,507.146667 
	C923.255432,508.835541 925.790649,506.637054 928.474365,504.178314 
	C930.955750,501.904938 934.218628,503.369934 937.556335,503.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M481.663208,235.749649 
	C481.968445,238.118225 484.753113,238.335205 484.957062,240.660950 
	C484.091156,242.188431 482.865112,241.892776 481.331848,241.856110 
	C479.146393,242.832001 477.292755,243.664001 475.219574,244.748001 
	C471.991425,243.634964 468.651306,244.419769 465.282318,243.274078 
	C464.375366,242.206818 464.192200,241.327164 464.758820,239.961563 
	C467.401489,238.065353 469.812408,238.051712 472.625977,239.700699 
	C475.751282,240.305420 477.205505,238.433319 478.843201,236.303604 
	C479.672913,235.357483 480.459198,235.237579 481.663208,235.749649 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M913.059021,517.332764 
	C913.324463,517.050171 913.720825,516.997192 913.962463,517.165955 
	C917.095764,519.353577 920.259644,520.282410 923.789185,517.770996 
	C923.205750,522.143066 928.002869,519.975525 928.917236,522.641785 
	C929.579529,523.685669 929.731506,524.458191 929.235107,525.657288 
	C926.359070,525.998474 923.695679,526.210938 921.083191,525.937500 
	C918.278076,525.643921 914.739502,525.880615 915.614868,521.098022 
	C915.929565,519.378601 913.443481,519.200256 913.059021,517.332764 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M766.348145,368.422485 
	C771.060852,364.848785 775.588989,361.963470 781.557678,363.997437 
	C782.501404,364.319031 783.631104,364.252838 783.656372,365.573090 
	C783.669739,366.273071 783.078735,366.971649 782.388184,366.824219 
	C776.838135,365.639313 773.801514,370.350037 769.562012,372.213104 
	C768.297424,372.768890 767.058594,373.320343 765.898010,372.135498 
	C764.742859,370.956329 764.974426,369.758942 766.348145,368.422485 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M920.623413,496.993164 
	C922.019653,498.617767 923.380127,500.103973 922.997681,502.622955 
	C917.642822,500.985809 912.289673,498.974762 906.931213,496.977936 
	C906.330078,496.753906 905.574768,496.719482 905.556885,495.852783 
	C905.536011,494.841553 906.320923,494.227905 907.088196,494.225677 
	C910.984863,494.214294 915.577576,490.896698 918.406799,496.488373 
	C918.598389,496.867157 919.614319,496.828857 920.623413,496.993164 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M967.349365,528.861023 
	C961.627869,530.101562 956.612244,527.532227 951.332397,527.706482 
	C948.946838,527.785278 948.740356,525.493286 949.347168,523.546021 
	C949.987671,521.490479 951.603882,520.111938 953.479797,521.485291 
	C957.599365,524.500977 961.717163,526.787598 967.115662,526.192383 
	C968.774109,526.009521 969.387573,527.427612 967.349365,528.861023 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M653.635498,304.371216 
	C658.362000,304.801483 662.721924,305.410736 667.084839,305.998627 
	C668.683899,306.214081 669.901367,307.016541 670.525513,308.470398 
	C670.992798,309.558868 670.150696,310.311127 669.427368,310.992401 
	C667.965027,312.369751 666.504944,311.512634 665.434692,310.604370 
	C662.533508,308.142303 659.142883,309.280090 655.947021,308.996338 
	C653.000000,308.734680 650.678833,308.120575 653.635498,304.371216 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M720.996582,352.589386 
	C718.299988,352.999359 715.599976,352.999908 712.449951,353.000854 
	C710.162292,351.249939 707.875366,352.251770 705.795349,352.007233 
	C704.301880,351.831665 702.449646,351.935913 702.495056,349.882233 
	C702.531616,348.231628 703.982849,347.314545 705.573975,347.126526 
	C707.052734,346.951813 708.613647,346.814941 710.052734,347.097595 
	C713.113464,347.698822 715.894043,349.351593 719.207581,349.172546 
	C720.987000,349.076385 720.936584,350.838959 720.996582,352.589386 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M649.922119,317.158752 
	C648.536804,313.291382 645.739685,312.846069 642.541504,312.964203 
	C641.311707,313.009613 639.419800,313.365021 639.398621,311.518585 
	C639.377136,309.653534 641.259705,310.096741 642.501099,310.016663 
	C644.507996,309.887329 646.820312,309.708893 648.172668,311.230347 
	C651.078186,314.499237 654.303833,314.439362 658.033386,313.240570 
	C659.763306,312.684509 661.588928,313.181091 661.741394,315.338837 
	C661.895996,317.525848 659.932434,318.561066 658.417053,317.758240 
	C655.579834,316.255096 652.847900,318.921417 649.922119,317.158752 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M481.417603,200.998322 
	C481.053619,195.986603 484.986328,192.826965 489.821106,194.229446 
	C492.110870,194.893661 494.457336,196.710388 493.748505,199.245697 
	C492.688416,203.037216 489.526886,202.042191 486.376221,201.050995 
	C484.609711,201.000916 483.221832,201.000000 481.417603,200.998322 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M698.969116,363.327087 
	C700.890686,363.413666 701.903931,361.810120 703.656616,361.059113 
	C705.178345,359.726074 706.557678,358.876404 708.368164,359.029327 
	C713.307983,359.446625 718.209595,360.266357 723.201294,360.152466 
	C724.990662,360.111603 725.840332,361.337891 725.239563,363.596558 
	C724.553650,363.999237 724.109741,363.999695 723.332886,364.000671 
	C719.078796,363.894684 715.195190,363.998016 711.256531,363.212311 
	C708.078613,362.578339 704.268799,361.909515 702.197021,366.025146 
	C701.734985,366.943176 700.580261,367.107849 699.563538,366.642303 
	C698.037170,365.943451 698.768799,364.730896 698.969116,363.327087 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M829.627930,376.596313 
	C827.985474,379.783508 827.090515,381.441254 824.250793,377.406189 
	C822.333679,374.682007 819.435059,371.386963 814.929077,371.926086 
	C813.921265,372.046692 812.730103,371.564056 812.606934,370.496185 
	C812.395813,368.665741 814.044434,369.188873 815.054504,368.971985 
	C818.934509,368.138855 828.570129,372.909210 829.627930,376.596313 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M540.026978,243.986938 
	C538.827698,243.062134 537.485962,241.637985 539.314575,241.360992 
	C542.991089,240.804077 546.872681,240.132385 550.486206,242.017975 
	C552.975464,243.316940 553.859253,245.606033 552.581238,247.833603 
	C551.333252,250.008713 549.196167,248.968079 547.370239,247.913071 
	C545.074768,246.586777 542.697144,245.402588 540.026978,243.986938 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M567.433960,266.819214 
	C563.523254,266.944946 560.037842,266.982391 556.552551,266.971130 
	C554.959778,266.965973 553.375977,266.587128 553.269104,264.694275 
	C553.142639,262.455292 554.865295,262.047913 556.649536,262.025421 
	C559.637085,261.987762 562.626648,261.955353 565.613098,262.021729 
	C569.350708,262.104767 569.862671,263.829102 567.433960,266.819214 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M711.228882,313.960510 
	C716.879639,311.704773 722.053345,314.803925 727.289673,314.153870 
	C727.979126,314.068298 728.557434,314.613983 728.623779,315.352081 
	C728.717102,316.389038 727.991150,316.844910 727.105835,316.896606 
	C721.686401,317.212982 716.265930,317.598053 710.841980,317.668518 
	C709.824158,317.681732 707.724548,317.534973 707.744080,315.913086 
	C707.762024,314.418365 709.580872,314.340332 711.228882,313.960510 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M917.820435,514.687195 
	C916.798157,516.992371 914.074646,513.472107 913.059082,516.675293 
	C905.907288,516.815918 898.702820,519.815979 891.252014,515.970093 
	C895.784546,513.952148 900.256531,513.384644 904.654663,514.258057 
	C907.737183,514.870178 910.412048,515.037109 913.207275,513.491577 
	C914.769409,512.627808 916.415344,512.807739 917.820435,514.687195 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M697.000000,314.000610 
	C699.125000,314.257019 703.310791,314.138153 702.102783,316.429077 
	C700.594360,319.289429 703.704651,326.122498 696.327881,325.006409 
	C696.005066,324.957581 695.650085,325.070435 695.341187,324.989502 
	C693.334412,324.463867 689.898315,326.363007 689.606079,323.353790 
	C689.407715,321.311310 692.808960,321.170471 694.875183,321.029999 
	C700.667053,320.636261 695.958008,316.757904 697.001709,314.403625 
	C697.000000,313.998779 697.000000,314.000000 697.000000,314.000610 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M455.118958,277.583557 
	C455.749634,278.542084 456.581635,279.346924 456.440643,279.906738 
	C456.127960,281.148376 455.147552,280.506805 454.552765,280.020905 
	C453.119537,278.850006 451.448822,279.055969 449.798828,278.990234 
	C446.774902,278.869690 441.585663,274.452240 441.144196,271.575623 
	C440.890625,269.923492 441.381409,268.467743 443.244354,268.235718 
	C444.811768,268.040497 446.084686,269.011444 445.525970,270.590332 
	C444.245911,274.207703 447.103058,273.792542 448.929932,274.479187 
	C450.992554,275.254425 453.750122,274.586304 455.118958,277.583557 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M733.381104,369.364746 
	C735.930786,367.139282 737.890808,366.970612 739.520935,369.961243 
	C739.901367,370.659149 740.796875,371.063873 741.235107,371.745178 
	C742.112244,373.108673 745.267395,372.640228 744.551514,374.740753 
	C743.741882,377.116119 741.067017,375.709564 739.222229,375.970551 
	C737.131531,376.266327 735.395142,375.150421 733.590881,374.297791 
	C730.748047,372.954346 730.703064,371.405670 733.381104,369.364746 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M472.562500,229.000000 
	C477.792664,231.775070 477.792664,231.775070 473.159454,235.702698 
	C470.987732,237.816101 468.634766,237.728592 465.782776,237.059692 
	C462.116913,234.460236 462.890991,231.918335 465.589020,229.170013 
	C468.041656,229.000000 470.083344,229.000000 472.562500,229.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M465.888397,228.743164 
	C465.338318,231.081253 463.689911,232.967239 464.868103,235.693237 
	C465.820923,236.991776 466.069427,238.071594 465.251953,239.638901 
	C464.999664,240.751587 464.999298,241.501953 464.998260,242.627579 
	C462.718018,241.543732 460.177155,240.872391 457.264709,240.261765 
	C456.455841,237.966614 459.180817,237.412491 459.062744,235.218597 
	C459.803345,233.085617 459.477448,231.354584 458.875916,229.325638 
	C460.452759,227.519943 462.620056,228.013565 464.636597,227.044891 
	C465.258118,227.495041 465.517456,227.990692 465.888397,228.743164 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M845.669800,280.730316 
	C846.853699,281.165924 847.709778,281.333099 848.782959,281.750732 
	C849.000427,283.067230 849.000793,284.133209 849.001709,285.598999 
	C847.255188,289.629486 844.320251,287.668396 841.385498,287.267822 
	C840.248169,287.000763 839.498779,287.000305 838.374695,286.999329 
	C837.153198,284.733948 836.881653,282.548828 838.937805,280.339355 
	C841.234375,278.497589 843.304138,279.260590 845.669800,280.730316 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M692.112549,270.998779 
	C689.546570,270.618073 686.063354,272.155121 685.695618,269.269257 
	C685.265259,265.892120 689.173401,267.486359 691.013184,266.554382 
	C692.983948,265.556122 695.227600,266.037598 697.357666,266.030426 
	C699.132996,266.024445 700.837952,266.454559 700.739197,268.665131 
	C700.655334,270.540009 699.099731,270.937012 697.508850,270.971802 
	C695.860718,271.007843 694.211487,270.991943 692.112549,270.998779 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M633.380981,299.620697 
	C635.502808,296.969025 637.719727,296.798676 640.047546,298.668549 
	C641.289246,299.666046 642.650452,299.951172 644.194946,300.039886 
	C646.345703,300.163483 649.202087,300.335693 648.681458,303.281036 
	C648.147217,306.303497 644.346008,305.553040 643.351440,304.761383 
	C640.368652,302.387115 637.002808,302.018829 633.601562,301.324921 
	C633.397217,301.283203 633.356506,300.439331 633.380981,299.620697 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M623.813721,210.287399 
	C626.528625,208.089340 629.410583,209.154922 632.129211,209.141418 
	C633.903503,209.132599 634.619446,210.698730 634.751465,212.238693 
	C634.912964,214.123688 633.578857,214.953033 631.936279,214.929550 
	C628.160522,214.875565 624.356750,215.493637 620.305054,214.279114 
	C619.817444,211.383057 622.862122,212.186340 623.813721,210.287399 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M682.030273,297.097168 
	C686.758057,298.755951 684.872681,302.326324 684.617004,305.120270 
	C684.444885,307.000671 682.594055,308.004730 680.537354,307.972351 
	C679.175537,307.950958 676.972839,307.073425 677.572021,306.318848 
	C679.787842,303.527893 677.081055,298.669342 682.030273,297.097168 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M746.869751,346.381287 
	C744.392944,346.719269 742.252380,346.942688 740.113281,346.930145 
	C738.146973,346.918640 737.026123,345.816620 737.119812,343.773895 
	C737.189880,342.245575 738.073792,341.148315 739.579041,341.156158 
	C742.316101,341.170441 745.311279,339.986816 747.715271,341.984283 
	C749.321167,343.318604 748.801575,344.937134 746.869751,346.381287 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M763.991455,456.649445 
	C758.938171,456.629425 753.777649,457.873199 748.803406,456.077911 
	C747.754761,455.699463 746.306396,455.510345 746.669678,453.949890 
	C746.956909,452.715973 748.264282,453.034027 749.187805,453.022369 
	C752.813904,452.976532 756.440857,453.000031 760.533752,452.997681 
	C761.848450,454.229431 764.271362,454.038208 763.991455,456.649445 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M884.969971,304.682068 
	C883.829102,305.160706 882.658203,305.321381 881.243652,305.741028 
	C876.664551,306.595306 872.506287,305.019257 868.223938,304.956635 
	C866.823914,304.936127 866.128479,304.168884 866.019470,302.402039 
	C871.688049,302.000824 877.376038,302.000397 883.532043,302.000000 
	C884.313293,302.788025 884.626587,303.576050 884.969971,304.682068 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M421.445740,301.547241 
	C421.380524,299.116119 419.657074,297.842163 418.576935,296.238586 
	C418.048462,295.454010 416.936035,294.644287 417.654297,293.681213 
	C418.459686,292.601379 419.742249,293.004974 420.818726,293.540619 
	C422.156616,294.206360 423.427704,296.115051 424.933350,293.342896 
	C426.829163,292.827881 428.553009,293.266571 430.620972,293.952271 
	C431.102722,296.497406 429.866058,297.906677 427.420807,297.554382 
	C425.513336,297.279602 424.980011,297.902252 425.121277,299.962097 
	C425.176636,300.539429 425.200256,300.753357 425.020447,301.219543 
	C424.628754,301.579346 424.440521,301.686829 423.939087,301.884399 
	C422.967102,302.062164 422.329102,301.991302 421.445740,301.547241 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M804.356384,354.514343 
	C806.418274,357.206116 808.296082,359.388367 811.828003,359.075256 
	C813.162109,358.957001 814.637268,359.420898 814.494568,361.198608 
	C814.382141,362.600677 813.085144,362.778290 811.908630,362.978485 
	C806.155762,363.957397 801.232178,360.426147 802.055847,355.885590 
	C802.260620,354.756866 802.788635,354.081726 804.356384,354.514343 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M789.866455,349.148499 
	C788.667908,347.191956 786.966187,345.362091 789.212769,343.661499 
	C791.310303,342.073761 793.304077,343.657349 795.177063,344.633667 
	C797.330017,345.755859 798.310486,347.619110 797.768555,349.982941 
	C797.414001,351.529602 796.260925,352.032501 794.699463,351.326202 
	C793.197449,350.646790 791.660889,350.043671 789.866455,349.148499 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M573.183838,306.730286 
	C574.618042,306.341827 575.685730,304.175690 576.731628,306.397491 
	C577.312683,307.632080 576.624207,308.731842 575.178223,308.920502 
	C573.358704,309.157776 572.190552,310.226562 571.158569,311.604156 
	C569.531067,313.776611 567.246826,313.076416 565.189758,312.709290 
	C563.975891,312.492706 563.456482,311.199188 563.277466,310.016632 
	C563.031250,308.389984 564.301025,308.305084 565.438110,307.905579 
	C567.845825,307.059570 570.327942,307.007935 573.183838,306.730286 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M498.417358,282.115173 
	C501.640808,282.295044 506.315277,280.930298 506.367554,284.444183 
	C506.421509,288.068848 501.731018,286.780334 498.956787,286.953857 
	C496.846222,287.085907 493.873474,287.304138 493.509338,284.798981 
	C493.095123,281.949219 496.646271,283.420959 498.417358,282.115173 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M697.378784,407.049957 
	C696.442017,406.157806 695.586853,406.011902 694.336426,406.002563 
	C693.114319,402.746460 692.796936,399.995209 697.694275,401.720520 
	C698.155701,401.882996 698.668823,401.920013 699.162537,401.965607 
	C701.578003,402.188629 705.206726,401.884247 704.653137,405.232971 
	C704.020813,409.058136 700.527100,408.034973 697.378784,407.049957 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M631.489563,312.831085 
	C627.484924,313.060486 624.013489,314.494232 620.332153,314.009613 
	C618.867676,313.816833 617.117249,314.065735 617.218445,311.848694 
	C617.302673,310.002472 618.822632,310.031708 620.148193,310.020477 
	C623.445618,309.992554 626.743958,309.974548 630.040894,310.023682 
	C631.837341,310.050446 633.531982,310.289764 631.489563,312.831085 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M616.382202,262.165100 
	C616.168396,261.142059 616.334351,260.287781 616.750122,259.216736 
	C620.658081,259.002594 624.316345,258.988800 627.974243,259.017487 
	C629.123840,259.026489 630.560425,258.944458 630.691162,260.493927 
	C630.828796,262.127319 629.578064,262.927856 628.124939,262.902710 
	C624.333984,262.837128 620.489868,263.655090 616.382202,262.165100 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M589.422119,316.999725 
	C589.001648,315.931274 589.000854,314.866241 589.000000,313.401794 
	C591.159851,313.003265 593.335144,313.169281 595.476196,312.969055 
	C599.384583,312.603455 601.495178,314.087097 601.484314,318.592255 
	C597.559509,318.056549 593.700623,317.529816 589.422119,316.999725 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M845.557190,305.000824 
	C847.000916,306.252319 849.916443,306.348297 848.676880,308.926300 
	C847.710510,310.936188 845.894409,310.866943 843.983459,310.182953 
	C841.698730,309.365173 838.442261,311.317535 837.059082,307.378540 
	C838.901123,303.072235 842.444458,305.808563 845.557190,305.000824 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M782.437988,521.103210 
	C784.373047,520.864929 785.776123,521.330566 787.115601,522.059082 
	C788.560181,522.844849 790.472229,523.468140 789.744263,525.637512 
	C789.054993,527.691589 787.104797,528.273743 785.255188,527.792175 
	C783.058105,527.220032 780.429932,526.644104 779.517883,524.319153 
	C778.888794,522.715576 780.669800,521.811829 782.437988,521.103210 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M468.619904,289.998352 
	C468.811066,291.788452 466.948822,293.167694 466.131958,292.516785 
	C463.408173,290.346252 459.640839,292.194458 457.093323,289.372925 
	C459.947021,288.977112 460.049896,285.691376 462.053009,284.600586 
	C463.180359,283.986694 464.277252,282.783325 465.679504,283.578644 
	C467.392853,284.550446 466.402191,285.711884 465.743378,286.928467 
	C464.187317,289.802094 466.224945,289.929657 468.619904,289.998352 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M800.224121,464.997986 
	C804.351196,465.586212 807.820496,463.785614 811.469971,464.030884 
	C812.263733,464.084229 813.247314,464.006409 813.388672,465.101715 
	C813.438110,465.484619 813.114075,466.029816 812.797546,466.327789 
	C810.195496,468.777588 798.796692,468.475861 795.675415,465.862732 
	C796.814514,464.421478 798.416199,465.237488 800.224121,464.997986 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M761.258118,440.754425 
	C757.639343,440.998718 754.278625,440.998657 750.460205,440.998047 
	C750.061096,435.541168 753.969421,435.998962 757.627625,435.993561 
	C760.730103,435.989014 762.235413,437.268768 761.258118,440.754425 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M825.273804,282.267883 
	C824.260498,280.210907 823.282776,278.850098 821.008118,278.929413 
	C819.594116,278.978760 818.134644,278.365173 818.496521,276.638519 
	C818.822388,275.083740 820.616760,274.550690 821.724182,275.115601 
	C825.223083,276.900574 829.624634,277.662720 830.990845,282.626282 
	C829.180115,282.852051 827.306946,283.369476 825.273804,282.267883 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M813.238525,296.727356 
	C810.082092,297.193268 808.166199,295.513733 807.333313,293.095490 
	C806.637390,291.074890 808.056519,289.146118 809.688782,288.020508 
	C811.619324,286.689148 813.875732,286.036560 815.913208,288.637512 
	C814.765747,291.352234 814.817078,294.139496 813.238525,296.727356 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M874.028931,523.000000 
	C875.988770,523.000183 877.492126,522.912781 878.979797,523.028137 
	C880.194946,523.122253 882.180664,522.382507 882.338257,524.120728 
	C882.511658,526.031860 880.675476,526.672424 878.921387,526.981567 
	C874.446594,527.770142 870.896729,524.664734 865.451172,524.479492 
	C868.770935,522.356201 871.271301,523.283020 874.028931,523.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M756.896118,398.974304 
	C758.404175,398.694641 759.510925,398.336853 760.642761,398.218567 
	C761.534973,398.125336 762.291199,398.720154 762.491943,399.563416 
	C762.721802,400.528900 761.925293,401.044525 761.149536,401.361053 
	C759.018860,402.230499 756.846069,402.936554 754.924927,404.323853 
	C753.455200,405.385132 751.617798,405.406006 750.587769,403.677277 
	C749.256775,401.443390 751.370422,400.832794 752.768372,400.159027 
	C753.930481,399.598938 755.253845,399.373291 756.896118,398.974304 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M700.456543,225.000610 
	C696.951538,218.956543 701.441833,220.876663 704.615723,220.997833 
	C707.217346,221.491623 711.231262,219.743469 711.247803,222.825058 
	C711.267517,226.523346 707.152527,224.522171 704.846680,224.957687 
	C703.578064,225.197311 702.226990,225.000610 700.456543,225.000610 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M897.315796,497.612244 
	C895.235474,496.758331 892.467102,496.649048 893.367676,493.839752 
	C894.111145,491.520599 896.753662,491.224091 898.801270,491.297241 
	C900.663879,491.363800 902.523071,493.068451 902.468506,494.769318 
	C902.383484,497.415039 899.932739,497.780243 897.315796,497.612244 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M767.790649,392.762512 
	C765.569275,391.820923 763.601868,390.936859 761.849670,389.738190 
	C760.791138,389.014069 759.825684,387.773926 760.575562,386.331573 
	C761.446777,384.655975 762.774414,385.455902 764.001892,385.995789 
	C764.908630,386.394562 765.820740,386.851013 766.778625,387.051788 
	C771.267395,387.992645 771.432739,389.906616 767.790649,392.762512 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M772.956604,402.958984 
	C774.937317,402.948120 776.229187,403.529510 777.172913,404.817322 
	C777.872253,405.771576 777.703003,407.287659 776.818665,407.240509 
	C773.489807,407.063080 769.787292,407.918671 766.968262,405.704712 
	C765.125916,404.257721 766.376587,402.572601 768.280701,402.251709 
	C769.630981,402.024109 771.128723,402.671631 772.956604,402.958984 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M842.630981,294.838501 
	C843.304199,297.802307 842.312561,299.302124 839.232422,299.026428 
	C837.471252,298.868835 835.684387,298.999268 833.455750,299.000000 
	C832.607544,298.620667 832.459351,298.161652 832.778809,297.311523 
	C833.313293,296.212280 833.626648,295.424561 833.969971,294.319031 
	C836.754456,294.220734 839.590271,293.443085 842.630981,294.838501 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M674.445557,278.003876 
	C677.223816,273.732513 681.326294,275.106995 685.132629,275.326721 
	C685.932312,275.372925 686.634399,276.108948 686.520508,277.105194 
	C686.384521,278.295227 685.469055,278.865814 684.447083,278.865662 
	C681.161133,278.865082 677.827087,279.603638 674.445557,278.003876 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M686.292847,329.005981 
	C688.718079,329.600464 692.420410,327.571198 692.466797,330.715759 
	C692.513245,333.861908 688.647156,333.702118 686.110474,333.888245 
	C684.477417,334.008057 682.444275,333.509247 682.311523,331.483337 
	C682.165649,329.256531 684.127869,329.066711 686.292847,329.005981 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M787.927551,476.887817 
	C790.210510,476.046967 792.220032,475.258850 793.545227,477.577240 
	C794.364807,479.011047 794.139587,480.734528 792.566101,481.441467 
	C790.427307,482.402374 787.638000,482.827271 785.940796,481.359833 
	C783.934937,479.625641 785.275269,477.843872 787.927551,476.887817 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M729.230042,304.689972 
	C724.845947,304.999176 720.691223,305.037659 716.538208,304.970093 
	C715.488892,304.953033 713.794128,305.467804 713.686035,303.866638 
	C713.564331,302.064697 715.307983,302.061188 716.603333,302.037537 
	C720.091675,301.973846 723.581665,302.005676 727.535522,301.999786 
	C729.133728,302.396362 729.613647,303.193848 729.230042,304.689972 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M813.333313,296.999207 
	C813.603943,294.331299 811.401001,290.865509 815.620605,289.059204 
	C816.166687,289.000092 816.333313,289.000153 816.750000,289.000732 
	C817.035522,290.209198 817.764099,291.044495 818.432190,291.977905 
	C821.033875,295.612762 820.543213,296.505249 815.407043,296.999634 
	C814.555542,296.999878 814.111084,296.999756 813.333313,296.999207 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M657.000183,237.417511 
	C658.147644,237.000565 659.295288,236.998260 660.442932,237.002335 
	C662.465942,237.009506 664.591003,236.978409 664.870117,239.700516 
	C665.071838,241.667313 663.999817,242.811325 662.029114,242.936310 
	C659.648315,243.087280 657.245667,243.151093 657.039185,239.801422 
	C656.998901,239.147629 657.011902,238.490570 657.000183,237.417511 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M499.175110,272.828552 
	C500.556000,276.265106 498.249359,275.919861 496.482941,275.980804 
	C494.013824,276.066040 491.448761,276.080444 489.633728,274.103699 
	C489.009949,273.424316 489.987030,272.596344 490.713287,272.215271 
	C493.495972,270.755188 496.281769,270.079620 499.175110,272.828552 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M670.879578,320.033142 
	C669.136108,321.060760 667.749268,321.036469 666.373596,320.115051 
	C665.658997,319.636383 664.884399,318.366730 665.368408,318.241516 
	C668.651794,317.392059 670.605347,314.584717 673.447327,313.189270 
	C673.826904,313.002899 674.931519,313.785461 675.377319,314.366943 
	C675.968811,315.138458 675.397034,315.902039 674.770691,316.497925 
	C673.587708,317.623230 672.400330,318.743896 670.879578,320.033142 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M802.604187,368.354431 
	C803.883667,369.554352 807.102234,368.016937 806.175232,370.807007 
	C805.644592,372.404175 803.526367,373.180450 801.528137,372.660492 
	C800.900146,372.497040 800.266052,372.221069 799.732971,371.853882 
	C797.987793,370.651703 794.426941,370.202972 795.771423,367.311768 
	C797.073853,364.511230 798.970337,368.261932 800.790466,368.001099 
	C801.255920,367.934357 801.760559,368.141357 802.604187,368.354431 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M425.296265,291.837463 
	C423.538208,291.427551 422.077637,290.853851 420.308533,290.140686 
	C419.027710,288.375214 417.238129,286.832886 419.297089,284.830048 
	C420.428314,283.729706 422.262238,283.648468 422.642731,284.933258 
	C423.356293,287.342773 429.303436,287.483032 425.296265,291.837463 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M479.094360,281.960266 
	C476.493774,281.355896 474.398621,280.439728 472.252441,282.447754 
	C471.261627,283.374756 469.602966,283.190826 469.238403,281.380310 
	C468.889771,279.649017 470.061279,279.054016 471.582947,279.036133 
	C474.215515,279.005157 476.853363,278.913849 479.479340,279.043060 
	C480.819305,279.109039 483.112091,278.231903 483.258392,280.103638 
	C483.439331,282.417755 481.025970,281.810089 479.094360,281.960266 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M693.944580,350.629639 
	C693.556641,351.003082 693.113281,351.004944 692.337402,351.008881 
	C691.387085,351.731995 690.571716,351.979340 689.328857,351.998505 
	C687.612366,352.006683 686.224670,352.006439 684.420776,352.007385 
	C682.711548,352.974945 681.283752,353.534912 679.320618,353.229187 
	C678.997559,353.003662 679.000000,353.000000 678.998657,353.001221 
	C681.204651,348.336884 690.385681,346.644958 693.944580,350.629639 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M501.394897,237.997910 
	C499.459534,236.841644 498.952576,235.430267 499.965820,233.327393 
	C503.030609,233.000824 506.061188,233.000412 509.545898,233.000305 
	C510.000000,233.000610 510.000000,233.000000 510.000000,232.999695 
	C509.028961,237.566025 505.187134,237.416367 501.394897,237.997910 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M729.314087,305.142273 
	C728.752441,304.195190 728.504944,303.390350 728.128662,302.292786 
	C732.301758,300.543488 734.573486,304.241150 737.614319,305.903992 
	C738.185364,306.216217 737.815125,307.445129 737.172729,308.087769 
	C736.216736,309.044067 735.143860,309.095032 734.055176,308.364899 
	C732.562378,307.363678 731.102295,306.313660 729.314087,305.142273 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M680.780396,361.316925 
	C684.566956,361.555786 687.006958,358.529755 690.629761,358.047607 
	C691.328125,359.422241 691.657654,360.843262 691.993591,362.632751 
	C688.948547,363.334290 685.897034,363.667328 682.422791,364.001404 
	C680.882446,363.598785 680.390137,362.815186 680.780396,361.316925 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M523.621460,241.819244 
	C526.490540,240.492477 529.301453,241.184631 532.065369,241.031052 
	C533.004028,240.978882 534.280151,240.866470 534.422424,242.165497 
	C534.544434,243.279388 533.468384,243.822006 532.551208,244.171265 
	C529.104431,245.483810 525.860779,245.717407 523.621460,241.819244 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M701.324524,307.093994 
	C702.607117,305.381226 704.171387,304.910400 705.877319,305.031769 
	C707.698975,305.161346 710.588562,304.304657 710.420776,307.229340 
	C710.301086,309.314972 707.696289,310.059998 705.689758,309.829559 
	C704.046021,309.640778 701.960876,309.635925 701.324524,307.093994 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M791.212646,387.003052 
	C792.921753,387.145050 794.270935,387.116272 795.497375,387.489960 
	C796.482300,387.790039 796.970215,388.779510 796.759827,389.889343 
	C796.555420,390.967438 795.824829,391.845490 794.781433,391.737152 
	C792.680969,391.519043 790.592896,391.070648 788.540527,390.554993 
	C787.695068,390.342560 787.176697,389.455200 787.495789,388.594604 
	C788.049927,387.099945 789.451416,387.032349 791.212646,387.003052 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M910.171082,503.000580 
	C911.752136,503.025024 912.907898,502.984680 914.049316,503.094269 
	C915.159546,503.200897 915.915405,503.865295 915.798035,505.036957 
	C915.546082,507.551453 913.501038,507.023468 912.008118,506.945251 
	C909.911011,506.835388 906.855469,508.036743 906.393372,504.698608 
	C906.093689,502.533905 908.407288,503.213318 910.171082,503.000580 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M709.339172,283.340118 
	C711.075256,284.694824 710.969055,286.048431 709.343933,286.560211 
	C706.979492,287.304810 704.415710,287.554047 702.100037,286.243011 
	C701.162720,285.712311 700.814697,284.614716 701.535828,283.662445 
	C702.731750,282.083405 704.309875,282.208923 706.032898,282.806030 
	C706.953186,283.124969 708.003601,283.068604 709.339172,283.340118 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M407.648834,301.934143 
	C406.788269,304.883606 404.657074,305.965393 401.308533,305.132019 
	C401.002136,304.838684 400.973236,304.671326 401.005768,304.516907 
	C401.443970,302.436005 399.743774,299.558838 402.312958,298.367798 
	C405.320618,296.973419 405.169891,301.294952 407.648834,301.934143 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M856.695862,289.735718 
	C856.998413,291.067566 856.999207,292.133911 857.000000,293.600128 
	C854.203552,293.271881 850.000549,296.042450 849.010986,290.396271 
	C848.773132,287.677795 850.067017,287.166412 851.983887,287.616974 
	C853.543640,287.983582 855.132080,288.360199 856.695862,289.735718 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M775.537415,395.393707 
	C777.916931,393.249298 780.476807,394.049896 782.865601,394.120880 
	C784.867371,394.180359 785.422791,395.646545 784.389526,397.219666 
	C783.200989,399.029205 776.714905,397.884827 775.537415,395.393707 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M825.083862,352.913727 
	C823.151855,352.504944 821.031616,352.333038 821.310852,350.203796 
	C821.622803,347.824402 824.165405,347.313507 825.938904,346.300842 
	C826.645081,345.897583 827.661926,346.394440 827.781921,347.353333 
	C828.062805,349.598633 828.650146,352.062592 825.083862,352.913727 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M929.459656,526.000000 
	C929.000000,525.250000 929.000000,524.500000 929.000000,523.375000 
	C932.156616,523.001465 935.314453,522.953918 938.469177,523.031921 
	C939.519104,523.057861 941.214600,522.534912 941.316467,524.139587 
	C941.430481,525.936157 939.689758,525.933838 938.391724,525.964417 
	C935.568848,526.031250 932.743591,525.994446 929.459656,526.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M897.988403,525.000000 
	C895.291748,524.780640 892.965210,525.508545 889.477234,523.817322 
	C894.765076,522.892517 898.768738,521.681885 903.065063,522.078979 
	C903.946289,522.160400 904.710938,522.605896 904.644958,523.584351 
	C904.579834,524.550354 903.754456,524.902405 902.885986,524.937927 
	C901.408142,524.998352 899.927246,524.983276 897.988403,525.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M708.392090,297.469849 
	C707.575012,298.071320 707.031921,298.492035 706.418945,298.664337 
	C704.011658,299.340973 701.644714,299.208191 700.625916,296.590027 
	C699.949341,294.851349 701.920593,294.324738 703.014465,294.258606 
	C705.329102,294.118713 708.635498,292.659454 708.392090,297.469849 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M829.687622,438.026794 
	C831.973328,438.349792 833.365295,439.276550 832.585876,441.315948 
	C831.770325,443.449799 829.650269,443.020905 827.889038,442.921692 
	C826.196411,442.826355 825.049561,441.877045 825.161560,440.114349 
	C825.341736,437.278992 827.650269,438.230316 829.687622,438.026794 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M991.927124,502.731384 
	C993.406006,504.708618 992.577820,505.751495 990.982117,506.427246 
	C989.266296,507.153839 987.196960,507.657440 985.848450,506.363617 
	C984.411560,504.984924 984.389160,502.900543 986.783997,502.172363 
	C988.304077,501.710083 990.047607,501.760620 991.927124,502.731384 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M429.964569,266.128265 
	C431.643860,263.441162 433.318054,263.794037 435.011749,265.548340 
	C435.783600,266.347778 437.199768,267.233032 436.329376,268.413025 
	C435.014709,270.195282 432.826355,270.238464 430.828186,269.890198 
	C428.481445,269.481110 429.147766,267.911835 429.964569,266.128265 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M689.333313,208.999664 
	C690.522034,206.322876 697.281555,204.358826 699.394958,206.018707 
	C700.020935,206.510376 700.708191,207.129135 700.401367,208.072266 
	C700.072510,209.082962 699.109436,208.954529 698.323547,208.971344 
	C696.181763,209.017151 694.038635,208.995087 691.447998,208.999954 
	C690.555542,208.999985 690.111084,208.999969 689.333313,208.999664 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M599.265442,287.309326 
	C597.808411,288.066193 596.717285,287.848480 595.635620,287.349854 
	C594.272095,286.721313 591.634766,286.687561 592.649414,284.489502 
	C593.828552,281.934814 596.763000,282.788788 598.838440,283.275635 
	C600.955444,283.772308 601.817383,285.354126 599.265442,287.309326 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M729.370850,404.991760 
	C728.824646,404.450409 728.646912,403.902008 728.234558,403.176208 
	C728.851807,401.629089 724.390198,400.323608 728.014587,398.834351 
	C730.337585,397.879791 732.595825,397.813477 733.351807,400.335236 
	C734.114624,402.879974 732.605774,404.714844 729.370850,404.991760 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M440.461700,262.966309 
	C438.362396,262.675720 437.761841,261.637756 438.339874,260.132782 
	C438.784821,258.974304 439.860992,259.173401 440.858917,259.444153 
	C441.645203,259.657471 442.473602,259.729126 443.244873,259.981201 
	C445.039978,260.567902 448.526306,258.629608 448.369812,261.673096 
	C448.244934,264.102539 444.796631,262.619415 442.850830,262.980896 
	C442.217133,263.098602 441.541870,262.992615 440.461700,262.966309 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M856.931274,289.652527 
	C854.413025,290.132782 852.095459,286.790863 849.159851,289.705597 
	C846.551636,288.689545 843.346130,289.987000 841.085449,287.364441 
	C843.469971,286.800751 845.994446,287.452026 848.713013,286.272827 
	C851.885803,286.475677 854.925171,286.581909 856.931274,289.652527 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M793.997192,362.995483 
	C792.686340,362.222137 791.589417,361.736420 790.778198,360.954285 
	C789.879395,360.087646 789.687500,358.796143 790.505371,357.790222 
	C791.502502,356.563873 792.589050,357.313293 793.507507,358.078613 
	C794.396667,358.819489 795.253174,359.604919 796.073120,360.421875 
	C796.856445,361.202301 799.310547,360.776154 798.258667,362.823578 
	C797.372131,364.549225 795.726318,363.857086 793.997192,362.995483 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M832.961792,468.779907 
	C834.525635,471.484406 833.012390,472.073334 831.109924,471.939178 
	C829.132202,471.799744 826.595764,473.091278 825.347595,470.612946 
	C824.594788,469.118134 825.694214,468.120819 827.309082,468.068176 
	C829.099976,468.009766 830.927979,467.703156 832.961792,468.779907 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M731.842651,344.326538 
	C730.724670,344.715363 729.888550,345.066071 729.121582,344.952118 
	C727.373901,344.692413 724.808777,346.101715 724.234497,343.143463 
	C723.887268,341.355103 725.460510,340.821289 726.818481,341.077667 
	C728.870667,341.465149 732.534119,339.306824 731.842651,344.326538 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M764.401550,486.391022 
	C767.264648,481.701691 770.369141,486.065582 773.314148,485.918610 
	C770.217957,485.631989 767.997131,492.377289 764.401550,486.391022 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M747.628113,444.712769 
	C748.752625,444.996338 749.501709,444.997559 750.625366,444.999390 
	C750.999573,446.065765 750.999207,447.131531 750.998169,448.596802 
	C748.629211,448.995667 746.260803,448.994965 743.448669,448.992859 
	C741.969788,447.893799 740.759583,446.668457 741.938721,445.159302 
	C743.355896,443.345398 745.300354,443.377563 747.628113,444.712769 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M760.998047,497.609802 
	C760.214111,498.310577 759.428162,498.621124 758.325317,498.960785 
	C757.358887,498.123474 756.231934,498.048553 755.212952,497.221008 
	C754.447205,496.829163 753.898682,496.651031 753.172485,496.238281 
	C753.608337,493.367554 755.611450,492.895935 757.817261,493.192474 
	C760.012207,493.487549 761.232910,494.853699 760.998047,497.609802 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M670.145264,282.905182 
	C671.590027,285.851562 670.767151,287.915466 668.005371,288.498688 
	C666.446228,288.827881 665.211487,287.159607 665.199646,285.608337 
	C665.175049,282.406281 667.138916,281.775543 670.145264,282.905182 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M800.765991,499.832703 
	C798.423157,499.974884 797.188049,499.152802 797.104431,497.088562 
	C797.044189,495.599976 797.709473,494.535950 799.233643,494.185364 
	C800.448364,493.906036 801.673401,494.082825 802.328125,495.178528 
	C803.414551,496.996887 803.182190,498.650177 800.765991,499.832703 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M609.642578,276.597137 
	C610.680664,276.024231 611.429932,275.640961 612.225342,275.446564 
	C613.777710,275.067169 615.964111,274.152985 616.538513,276.160248 
	C617.361389,279.035797 614.372620,278.945251 612.705322,279.538452 
	C610.451172,280.340332 608.601379,280.106262 609.642578,276.597137 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M447.997681,248.379089 
	C450.349548,248.611755 452.692535,249.285721 455.584503,249.000305 
	C455.761078,250.121170 455.522156,251.241745 455.141602,252.681152 
	C453.353241,254.133987 451.366486,253.081741 449.328674,253.916748 
	C445.424805,252.871140 449.054352,250.367050 447.997681,248.379089 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M760.986938,364.363892 
	C759.745667,368.699371 756.161499,367.171082 753.230896,367.356628 
	C753.007141,367.370789 752.483643,366.288483 752.506042,365.732239 
	C752.543274,364.808167 753.379944,364.227783 754.199768,364.186127 
	C756.350037,364.076965 758.510010,364.156952 760.986938,364.363892 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M385.042328,290.687469 
	C383.251160,290.661438 381.511658,290.288055 379.757477,290.005707 
	C378.647736,289.827057 377.187042,289.957397 377.378784,288.330933 
	C377.557709,286.813385 379.036896,286.994110 380.129639,287.028503 
	C382.223846,287.094421 384.361969,286.609589 386.704407,287.721558 
	C386.364929,288.791382 385.726349,289.584991 385.042328,290.687469 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M490.433777,244.000000 
	C489.762970,241.234161 491.766022,239.894745 496.690094,239.866806 
	C497.555542,245.318985 493.616394,243.747055 490.433777,244.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M385.250549,301.299011 
	C384.982025,299.800293 385.806946,299.152405 386.816589,298.252075 
	C390.160522,296.918274 393.187561,296.047638 392.140869,301.677551 
	C391.226501,302.859100 390.229980,303.104370 388.733551,302.963623 
	C387.444305,302.353607 386.425659,302.079651 385.250549,301.299011 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M737.347900,283.339508 
	C737.000000,281.931854 737.000000,280.866119 737.000000,279.400818 
	C739.041687,279.000946 741.083313,279.000641 743.562500,279.000793 
	C744.000000,280.066895 744.000000,281.132568 744.000000,282.597900 
	C742.077881,284.878235 739.937805,284.749817 737.347900,283.339508 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M459.661285,234.754730 
	C461.881165,238.066162 459.440063,238.661697 457.214539,239.750366 
	C455.896393,239.691193 454.792023,239.384216 453.343842,239.038925 
	C453.011047,236.742188 454.820404,236.119644 456.693756,235.138504 
	C457.672638,234.356857 458.456635,234.239151 459.661285,234.754730 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M701.046021,374.350586 
	C700.998413,373.553925 700.999207,373.110321 701.000000,372.333954 
	C702.933716,372.104980 705.608826,369.292694 706.547791,373.124603 
	C706.872070,374.448151 705.844299,376.043610 704.359924,376.630463 
	C702.628906,377.314819 701.590637,376.355957 701.046021,374.350586 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M623.571533,209.999985 
	C625.713196,212.489822 625.713196,212.489822 620.256409,213.740021 
	C619.144531,213.832321 618.289124,213.665848 617.216797,213.249084 
	C617.247559,212.194458 617.495178,211.390121 617.871338,210.293152 
	C619.714355,210.000320 621.428711,210.000153 623.571533,209.999985 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M583.684631,208.795502 
	C584.436707,211.151596 582.644897,212.496841 582.045593,214.643311 
	C581.195129,214.750748 580.390259,214.503937 579.292725,214.128265 
	C578.576416,211.797134 577.184570,209.152664 581.638672,209.006271 
	C582.383911,208.620621 582.840332,208.483978 583.684631,208.795502 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M791.847839,379.712891 
	C789.754028,379.732788 787.449219,379.886200 787.658447,377.431152 
	C787.799255,375.779663 789.687988,374.603546 791.420166,375.228973 
	C793.715942,376.057861 793.244507,377.880371 791.847839,379.712891 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M555.953979,314.032776 
	C556.988342,314.710968 558.036133,315.368652 559.254517,316.289398 
	C557.886597,318.869995 555.903076,317.632935 554.193176,317.021057 
	C551.566833,316.081299 549.098083,314.725159 546.240845,313.360962 
	C545.708252,312.540039 545.757568,312.045074 546.478516,311.408936 
	C549.247437,310.956635 551.046753,312.748566 553.638855,313.163757 
	C554.015198,313.205444 554.279968,313.280060 554.370361,313.176392 
	C554.881897,313.133667 555.249817,313.309967 555.779297,313.819275 
	C555.986572,314.053192 555.967468,314.012268 555.953979,314.032776 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M844.983459,570.617065 
	C844.210938,571.312195 843.424377,571.626831 842.320068,571.971924 
	C841.013367,572.845337 839.922607,572.943970 838.375488,572.223328 
	C838.000610,571.999817 838.000000,572.000000 838.001221,571.998779 
	C837.651428,568.972656 840.088013,568.782288 841.995850,568.221985 
	C843.621948,567.744263 844.864746,568.257812 844.983459,570.617065 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M857.019653,294.400024 
	C858.955078,294.054626 860.917175,294.029755 862.861755,294.202393 
	C863.655701,294.272858 864.450928,294.847443 864.409180,295.793365 
	C864.361633,296.870453 863.370850,297.065582 862.609985,296.932129 
	C860.632446,296.585205 857.776489,298.389343 857.019653,294.400024 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M700.029663,345.185425 
	C698.075500,345.947571 696.073364,346.939484 695.318542,344.497040 
	C694.878967,343.074524 696.288147,342.165924 697.714600,342.094727 
	C699.590393,342.001068 701.648376,341.969635 700.029663,345.185425 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M896.809814,502.145111 
	C898.276062,502.800873 899.941467,502.969025 899.669617,504.730316 
	C899.465027,506.055847 898.233826,506.061768 897.365784,505.573486 
	C896.136169,504.881714 893.932068,505.694366 893.646851,503.906219 
	C893.369568,502.168060 895.114136,502.128204 896.809814,502.145111 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M786.534302,400.891693 
	C788.784363,397.798950 791.570374,399.271515 794.260925,399.726227 
	C791.866089,401.060577 789.518555,402.548553 786.534302,400.891693 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M395.762360,252.273376 
	C397.256134,254.083221 397.746887,255.766388 395.420319,256.769165 
	C394.484192,257.172638 393.409882,256.686066 393.193939,255.487488 
	C392.879150,253.740341 392.896729,252.093658 395.762360,252.273376 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M449.220337,254.256073 
	C450.082123,250.755280 452.295593,252.114273 454.659668,252.849472 
	C454.225250,257.470825 451.606781,255.152649 449.220337,254.256073 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M761.374939,430.615173 
	C763.598389,428.254517 765.006836,429.885010 766.328552,431.277710 
	C766.843262,431.820068 766.349976,432.754517 765.725586,432.755859 
	C764.038940,432.759460 761.650574,434.175873 761.374939,430.615173 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M484.923584,226.647842 
	C484.786560,228.629974 484.129578,230.169022 482.251831,229.662323 
	C480.331207,229.144028 481.219055,227.175690 481.002502,225.405655 
	C482.372772,225.174759 484.028809,224.485977 484.923584,226.647842 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M670.860840,232.645569 
	C669.961548,234.726440 668.835144,235.420380 667.509888,233.575760 
	C666.799133,232.586349 666.699829,231.123871 667.899231,230.482956 
	C669.555603,229.597870 670.800842,230.173859 670.860840,232.645569 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M626.306396,283.330933 
	C628.622498,285.581757 627.014832,285.898987 625.335083,285.949158 
	C624.129761,285.985168 622.200073,286.297119 622.398010,284.435455 
	C622.618530,282.360809 624.590027,283.146149 626.306396,283.330933 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M772.271912,431.292847 
	C773.467041,429.643188 774.694824,428.472839 776.355774,430.178864 
	C776.959473,430.798889 777.112183,431.880066 776.350647,432.351135 
	C774.893494,433.252502 773.299744,433.639435 772.271912,431.292847 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M860.424927,527.111511 
	C862.432800,529.291809 860.566650,530.005737 859.221069,530.652649 
	C858.180420,531.152954 857.250549,530.533386 857.150757,529.321045 
	C856.973755,527.171265 858.463623,526.993103 860.424927,527.111511 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M387.748901,302.061951 
	C389.066376,301.996582 390.132782,301.996582 391.598999,301.996460 
	C392.753815,303.529388 393.251648,304.947784 390.332397,304.995972 
	C388.866821,304.942932 387.619080,305.129242 387.063416,303.338531 
	C387.064301,302.651123 387.225159,302.357300 387.748901,302.061951 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M639.718872,287.198059 
	C641.498108,288.878571 640.869385,290.019928 639.141418,290.581726 
	C638.176697,290.895355 636.903015,290.286987 636.692688,289.395599 
	C636.188354,287.258148 637.827881,287.118286 639.718872,287.198059 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M607.744019,309.254456 
	C609.260437,310.766510 609.405212,312.037659 607.466492,312.780060 
	C606.685852,313.079010 605.711975,312.830505 605.398193,311.976776 
	C604.749390,310.211182 605.334656,309.116699 607.744019,309.254456 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M759.084778,519.360168 
	C759.472351,518.687012 759.942261,518.371521 760.706055,518.028015 
	C762.210083,518.187317 764.408997,517.968628 763.510864,519.845886 
	C762.589172,521.772644 760.588928,521.471130 759.084778,519.360168 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M728.833862,388.088074 
	C727.913574,385.663818 729.331116,385.233582 730.957214,385.106903 
	C731.842529,385.037933 732.830750,385.445404 732.440674,386.477692 
	C731.907104,387.889709 730.994751,389.185272 728.833862,388.088074 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M837.874878,432.564636 
	C837.404968,430.092072 838.962341,430.042572 840.485474,430.182434 
	C841.395508,430.265961 842.038391,430.995789 841.601257,431.913391 
	C840.859314,433.470673 839.419189,432.929749 837.874878,432.564636 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M945.061646,509.341553 
	C944.998352,508.558380 944.999207,508.113129 945.000000,507.333923 
	C946.135010,507.055603 947.380188,506.871490 947.938477,508.659363 
	C948.001404,509.441772 948.000305,509.885986 947.998413,510.663269 
	C946.863892,510.942047 945.620422,511.127167 945.061646,509.341553 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M668.333313,220.000092 
	C667.637207,218.892807 666.480652,217.473251 668.686523,217.134293 
	C670.390625,216.872467 671.046631,217.741974 670.123291,219.704163 
	C669.555542,220.000061 669.111084,220.000122 668.333313,220.000092 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M705.672424,409.035706 
	C705.621826,411.006805 704.963318,412.721924 702.201172,412.228638 
	C702.751160,410.608337 703.710449,409.454376 705.672424,409.035706 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M742.158081,407.048645 
	C743.232544,407.168518 744.601135,407.046082 744.248657,408.038666 
	C743.682739,409.632294 741.982422,409.255280 741.033569,408.541656 
	C739.931641,407.712891 741.080566,407.335693 742.158081,407.048645 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M413.998962,297.374878 
	C415.093048,297.116760 416.533661,296.787659 416.720062,298.392151 
	C416.873322,299.711243 415.741089,299.881531 414.359802,299.988159 
	C413.997284,299.249084 413.998230,298.499420 413.998962,297.374878 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M983.487183,491.803406 
	C985.237488,491.703033 984.981262,492.787628 984.735107,493.712494 
	C984.662048,493.986816 983.832886,494.372864 983.676941,494.259247 
	C982.898376,493.691833 983.054138,492.840515 983.487183,491.803406 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M400.631470,305.012390 
	C400.904755,306.518707 400.244781,307.583038 398.333008,307.957550 
	C397.970276,306.410065 398.199860,305.016541 400.631470,305.012390 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M679.859863,257.448761 
	C680.901855,257.047791 681.755737,256.888092 682.299805,257.688385 
	C682.407776,257.847290 681.996216,258.657288 681.712646,258.729645 
	C680.786316,258.966095 679.702576,259.198059 679.859863,257.448761 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M410.052856,296.312958 
	C411.274414,296.387085 412.807953,294.072571 413.948181,296.688599 
	C412.726837,296.613251 411.193146,298.926056 410.052856,296.312958 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M420.790436,279.240204 
	C421.690308,279.848663 421.627319,280.409302 420.904388,280.688507 
	C420.573853,280.816193 419.843842,280.639038 419.698120,280.376465 
	C419.293274,279.646790 419.815735,279.300720 420.790436,279.240204 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M393.290955,245.957092 
	C393.001617,245.558151 393.000763,245.113297 392.999359,244.334824 
	C393.637573,244.100754 394.403290,244.155304 394.448486,244.956238 
	C394.465454,245.257263 393.886688,245.591873 393.290955,245.957092 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M764.333435,267.998993 
	C764.100159,267.360107 764.156128,266.594879 764.956909,266.550873 
	C765.258118,266.534302 765.591980,267.113007 765.955872,267.708923 
	C765.556091,267.998108 765.111511,267.998657 764.333435,267.998993 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M698.294189,412.973114 
	C698.000000,412.555542 698.000000,412.111115 698.000000,411.333313 
	C698.548462,411.176910 699.096863,411.353851 699.822632,411.765411 
	C699.529480,412.315430 699.058960,412.630829 698.294189,412.973114 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M555.800171,313.800720 
	C555.599182,313.902252 555.211853,313.751282 554.642578,313.336548 
	C554.845093,313.231232 555.229370,313.389740 555.800171,313.800720 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M607.997437,337.996796 
	C608.078552,337.961792 608.162109,337.930023 608.126099,337.951538 
	C608.006531,338.004791 608.000000,338.000000 607.997437,337.996796 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M799.750183,708.713135 
	C799.900085,709.313904 799.819763,709.894470 799.697571,709.903381 
	C793.022461,710.392212 792.521057,714.939514 792.670288,720.171875 
	C792.712646,721.657715 791.510010,722.827393 789.731873,723.051453 
	C788.553833,723.199890 787.286438,723.657715 787.506775,725.167419 
	C787.754761,726.865662 789.255737,726.954956 790.596436,726.972168 
	C793.914307,727.014709 797.277466,727.339905 800.540771,726.918762 
	C805.380920,726.294312 809.510925,728.857971 814.094543,729.405762 
	C817.373474,729.797668 818.512268,735.572754 815.740845,739.156738 
	C813.017944,742.677917 812.287231,744.724548 817.151489,746.738525 
	C819.749756,747.814209 819.112854,750.797546 816.217834,752.326965 
	C814.188232,753.399292 811.987610,752.865173 809.156006,752.779785 
	C806.488831,748.052429 808.052429,743.689270 809.267273,739.345093 
	C806.836426,738.313965 805.369019,740.131348 803.229736,740.745605 
	C802.395203,740.948242 801.904541,740.991760 801.036499,740.954102 
	C792.463562,739.653137 784.466858,740.202698 776.645386,743.213257 
	C769.593872,745.927490 764.446289,741.796143 765.170654,734.221802 
	C765.437256,731.433472 765.770386,728.640198 765.661377,724.717590 
	C763.660767,728.793213 765.749512,733.822449 760.198608,734.988647 
	C757.374512,734.831970 755.151733,733.623901 752.738708,733.309875 
	C750.756836,733.051941 748.247070,733.193054 747.960449,735.449951 
	C747.716370,737.371460 750.298157,737.115845 751.734558,737.622009 
	C752.190063,737.782532 752.687622,737.824463 753.532349,738.023254 
	C756.382812,738.980225 759.131836,738.168030 761.575989,739.468994 
	C765.463989,741.538574 765.826599,744.056396 762.245544,747.572266 
	C758.826172,749.091980 754.438477,747.259827 752.474182,751.861816 
	C752.035278,753.145142 752.148254,754.068115 752.964355,755.177246 
	C754.591187,761.158508 759.188599,760.309448 763.674377,760.493530 
	C766.798462,764.572510 770.245422,764.948547 774.476135,762.549377 
	C771.453125,758.455444 767.092590,761.572266 763.564209,759.789673 
	C762.795471,757.247253 763.913696,755.925598 766.132324,754.866089 
	C772.905334,752.260193 778.482727,755.820068 784.344360,757.214722 
	C785.509644,757.492004 786.604370,758.537354 786.551270,759.690063 
	C786.354675,763.960022 789.149841,764.464905 792.664734,764.823608 
	C794.522583,768.103577 791.965210,767.919189 790.370361,767.936096 
	C784.804993,767.995300 780.825195,770.331482 778.061890,775.219971 
	C777.043274,777.021790 775.312805,778.425110 773.102295,779.194397 
	C770.672913,780.039795 770.904663,781.376343 773.088257,782.500244 
	C777.155701,784.593628 781.314270,785.927002 785.965515,784.550171 
	C787.201355,784.184326 788.670776,783.920532 789.856140,784.248718 
	C791.275879,784.641846 793.794617,784.445801 793.573608,786.670532 
	C793.375305,788.667542 791.176880,787.838379 789.802185,787.990601 
	C787.775208,788.214966 785.673828,788.328674 785.117188,790.814026 
	C784.820068,792.140625 784.913147,793.710449 786.271851,794.367493 
	C788.483215,795.436829 790.957275,795.622253 792.741333,793.650757 
	C796.993774,788.951538 802.144592,789.549561 807.506165,790.692810 
	C810.047729,791.234802 811.073792,792.647461 811.026672,795.265381 
	C810.974915,798.141479 808.753723,798.415283 806.946228,798.741089 
	C804.681030,799.149414 802.305054,798.968933 799.976379,798.994873 
	C794.270508,799.058472 792.804016,800.945618 794.297546,806.351196 
	C796.529602,814.429504 795.338196,815.656372 787.035645,816.104431 
	C780.352783,816.465027 773.466248,815.865906 767.071106,818.734558 
	C766.140503,819.151978 764.948730,819.080322 764.403198,818.065430 
	C763.745911,816.842712 763.767944,815.399353 764.557739,814.242493 
	C766.321411,811.659119 768.946106,810.853699 771.962708,810.995056 
	C775.017883,811.138245 779.187256,810.995667 779.154114,807.700439 
	C779.127197,805.029419 774.877441,804.105713 771.791260,804.020874 
	C766.653137,803.879456 761.518188,804.224304 756.402283,803.099731 
	C753.824158,802.533020 751.466431,802.697937 750.418701,806.424683 
	C749.687378,809.025879 746.653931,810.432190 743.370300,810.993042 
	C743.233887,803.845032 747.619751,798.470947 754.125000,797.406433 
	C755.444824,797.190491 757.086853,797.204224 757.614990,795.860474 
	C759.799744,790.301941 764.453857,790.300476 769.225464,790.387817 
	C770.052002,790.402954 770.877686,790.267273 772.209473,789.357483 
	C769.738525,788.091797 767.302124,788.207520 765.077881,787.783752 
	C761.977112,787.193054 759.161194,786.459045 758.687256,782.663940 
	C758.204773,778.800232 759.116821,775.722534 763.214539,774.343689 
	C763.832520,774.135742 764.446106,773.825562 765.009033,772.828247 
	C762.462830,770.435547 760.058594,768.807068 756.145752,770.819275 
	C751.088745,773.419922 749.348206,772.731323 745.509399,767.627686 
	C744.938782,766.388062 744.838989,765.430542 744.983398,764.112183 
	C745.162964,762.834045 746.187134,762.018616 745.217712,760.759705 
	C742.129883,758.890015 739.745605,762.941589 736.656738,760.963989 
	C736.099487,759.889648 735.880615,758.972412 736.309875,758.266724 
	C738.150024,755.241821 737.173096,752.877380 735.317993,750.125366 
	C732.194214,745.491394 733.616943,742.356873 739.190796,741.241699 
	C739.987122,741.574890 740.300110,741.941467 740.579407,742.361023 
	C741.076843,743.108276 740.940552,744.633667 742.297729,744.348816 
	C743.456543,744.105652 743.332153,742.814697 743.416321,741.511902 
	C742.847656,739.093079 740.894470,739.285767 739.419189,738.730103 
	C737.315918,737.937927 733.958008,738.655029 734.690735,734.575256 
	C735.517517,729.971497 737.775818,727.026550 741.202515,727.934937 
	C747.015686,729.476074 753.016113,725.082947 759.038696,730.281067 
	C758.564026,726.553833 756.861755,726.427979 755.328125,726.849792 
	C750.305908,728.231201 747.411194,725.941040 745.597107,721.575256 
	C744.902344,719.903076 743.322083,719.232300 741.822998,717.957153 
	C741.559448,717.466125 741.498169,717.250793 741.453369,716.705444 
	C741.647400,715.764771 741.987732,715.222107 742.257141,714.325317 
	C742.345093,713.820435 742.375488,713.616089 742.489502,713.110596 
	C742.669556,712.623474 742.765869,712.437561 743.054932,711.997498 
	C743.400940,711.589844 743.554138,711.436401 743.963440,711.075684 
	C745.166565,710.276978 746.065002,709.651489 745.975830,708.013062 
	C745.943665,707.020935 746.004883,706.366760 746.270630,705.411072 
	C746.700134,704.511292 747.133545,704.012695 747.808472,703.284119 
	C748.173523,702.875000 748.326111,702.718140 748.720764,702.342529 
	C749.972717,701.217834 751.812195,700.723511 750.501099,698.434937 
	C750.289795,697.917358 750.221069,697.701538 750.103088,697.145020 
	C750.055542,696.339233 750.121033,695.883240 750.393555,695.117798 
	C751.407471,693.723755 752.493042,692.856140 753.673889,691.611206 
	C756.168335,689.829407 759.865112,690.935059 761.166138,687.171753 
	C761.735291,685.791077 762.393677,684.913818 763.828247,684.411560 
	C764.729004,684.310425 765.330261,684.337769 766.105469,683.846436 
	C766.479858,683.490112 766.624878,683.333252 767.002930,682.938721 
	C769.129089,680.076294 772.142761,679.753052 775.302185,678.857666 
	C778.914307,678.272217 782.118896,678.163574 785.549805,679.730103 
	C788.772339,682.371277 792.597778,681.791443 796.426636,683.514587 
	C797.445312,688.393188 797.226135,692.167603 791.051697,691.080933 
	C789.883789,690.875366 788.740295,691.510620 788.336182,692.801636 
	C787.845825,694.367737 789.569336,694.459839 790.042053,695.249817 
	C792.502808,699.361694 796.541199,700.068359 800.676270,701.016846 
	C804.852722,701.974792 805.166016,704.563843 801.371643,707.944336 
	C799.327759,705.643799 797.953186,702.435181 794.156921,705.896484 
	C793.547974,706.888916 793.671143,707.386230 794.961731,707.465820 
	C796.753784,707.506409 798.213806,707.591064 799.750183,708.713135 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M804.099365,649.384277 
	C807.937073,649.214478 808.508423,646.533264 807.913147,643.738770 
	C806.442749,636.835205 806.857483,629.869263 807.129089,622.963440 
	C807.322632,618.043335 804.990540,616.440979 800.845520,616.050781 
	C797.974915,615.780457 793.572144,616.416321 793.763489,612.788696 
	C793.916077,609.895935 798.049683,609.783875 801.347412,609.358643 
	C806.979858,608.632202 812.122192,610.425964 817.502075,610.671875 
	C820.155762,610.793213 823.177063,612.322876 824.939880,608.381653 
	C826.923706,606.029785 829.743713,606.694519 832.315063,605.498901 
	C836.087830,604.765991 839.502991,605.879395 842.924133,605.480896 
	C844.015869,605.353699 845.731445,606.062378 845.567688,603.571960 
	C845.328796,602.960327 845.211243,602.659973 845.138489,602.348145 
	C844.783752,600.828125 844.090759,599.419739 842.324402,599.654419 
	C839.839478,599.984619 842.456787,602.214172 840.978394,603.385010 
	C838.652283,604.861450 836.310120,604.542664 833.715576,604.910095 
	C830.837219,605.751526 828.024963,605.252441 825.342773,606.885498 
	C822.440369,606.246704 819.848328,605.941650 817.160156,605.962952 
	C811.080566,606.011169 804.999512,605.838074 798.918823,605.807251 
	C797.326355,605.799194 796.406433,605.102539 796.157104,603.570251 
	C795.938171,602.224792 796.467896,601.303772 797.880066,601.044128 
	C799.826416,600.686218 801.798828,599.959534 803.720886,600.070190 
	C811.131042,600.496948 818.525024,601.200989 825.926453,601.783813 
	C827.246704,601.887817 828.575317,601.976379 829.897156,601.946289 
	C831.335815,601.913574 832.612793,601.371155 832.734680,599.749451 
	C832.864380,598.024109 831.313477,597.722046 830.122009,597.215149 
	C828.531555,596.538513 826.342041,597.853333 825.087402,595.368896 
	C828.860352,593.149109 833.154358,592.405212 837.302673,590.423340 
	C841.869751,595.267761 841.872803,595.272156 847.527222,591.351013 
	C848.362488,590.771790 849.214966,590.307861 850.593750,590.522095 
	C852.210938,592.543396 852.007263,595.481812 855.065674,596.589600 
	C855.570374,597.445801 855.809448,598.218445 855.381348,598.675964 
	C852.919678,601.307068 855.279968,601.806152 857.244690,602.884399 
	C858.049133,604.013550 858.140320,604.946533 857.835144,606.270203 
	C857.021423,607.924316 855.614807,608.110657 854.441284,608.654480 
	C853.426575,609.124817 852.879700,609.844299 853.061584,610.985229 
	C853.209656,611.914124 853.827454,612.483154 854.724976,612.517761 
	C856.102539,612.570984 856.028442,611.282837 856.580444,610.135132 
	C861.211060,606.741333 861.211060,606.741333 857.784912,603.177368 
	C857.494080,598.063354 861.469666,601.822449 863.112183,600.159851 
	C861.850220,597.977295 859.304199,598.746643 857.247681,597.644775 
	C855.319763,595.856140 856.200439,593.554565 855.227661,591.421814 
	C854.238647,587.759949 850.371887,587.984497 848.529358,585.343079 
	C848.662720,582.469666 850.233459,580.542175 851.706055,578.216370 
	C855.781921,583.865723 862.005737,580.107788 866.947144,581.540527 
	C868.980652,582.130066 871.290405,578.902283 871.491211,576.412354 
	C871.715637,573.628601 868.915955,573.457214 866.855896,573.057922 
	C865.896790,572.871948 864.874817,573.012512 863.881470,572.999573 
	C859.461060,572.942139 853.716675,577.166748 851.672302,569.231506 
	C851.614563,569.007507 850.437134,569.072083 849.389160,568.998657 
	C852.057434,565.887939 853.118591,560.054810 858.559753,560.256165 
	C863.330994,560.432739 868.226562,560.462036 872.898193,561.339294 
	C876.450195,562.006287 879.414307,561.718201 882.441101,560.058105 
	C883.158264,559.664734 883.927490,559.162842 884.698914,559.112671 
	C886.462769,558.998047 888.989380,558.388062 889.414185,560.632507 
	C889.879028,563.088379 887.606995,563.873718 885.446777,563.967407 
	C883.450806,564.054016 881.396179,564.287903 879.459290,563.942322 
	C874.745605,563.101074 872.541016,566.672424 869.997620,569.287476 
	C868.289734,571.043579 870.609924,571.809937 871.832214,572.338928 
	C877.074158,574.607544 882.553833,574.833923 887.907349,573.088501 
	C892.348877,571.640381 896.794861,572.000061 901.258423,572.132568 
	C902.834717,572.179260 904.866577,572.625854 904.674805,574.889343 
	C904.513000,576.798401 902.789001,576.966858 901.236084,576.985168 
	C896.874451,577.036621 892.721375,577.922302 888.643982,579.532532 
	C886.109985,580.533203 883.398193,580.290100 880.905457,578.955688 
	C879.219971,578.053467 877.444153,577.652771 875.613220,578.323853 
	C872.777832,579.363159 870.881226,581.878357 871.657715,584.431091 
	C872.591431,587.500732 875.593506,585.515564 877.187256,584.697388 
	C884.821228,580.778931 892.741638,580.384583 900.878479,582.221985 
	C902.670593,582.626709 905.192993,582.601746 905.711182,585.078430 
	C906.118469,587.024780 904.897217,588.623474 903.514282,589.855408 
	C901.648071,591.517883 899.502197,591.454102 897.569336,590.003357 
	C894.318481,587.563416 890.714600,587.421570 887.113586,588.529907 
	C883.472168,589.650574 879.589539,586.862854 877.135376,587.868408 
	C874.324585,589.020020 871.929321,592.034119 868.500977,592.909668 
	C867.064392,593.276611 864.532959,592.601318 865.453430,595.401733 
	C866.150635,597.522522 868.056946,598.458923 870.306458,597.696167 
	C871.543518,597.276733 872.622375,596.409729 873.806946,595.812683 
	C876.214783,594.599243 878.652588,594.371704 880.727417,596.363342 
	C882.042969,597.626160 883.267761,599.241821 882.628418,601.217712 
	C881.976685,603.231812 880.042603,602.963623 878.391418,602.993835 
	C877.058716,603.018250 875.724854,603.029846 874.392456,603.002014 
	C872.546265,602.963562 871.057922,603.298645 871.017090,605.619202 
	C870.981201,607.655212 870.765808,609.840942 873.307800,610.528931 
	C876.075134,611.278015 878.926880,611.724548 881.209473,609.263062 
	C882.645325,607.714600 884.300049,606.456238 886.456604,607.120239 
	C890.953735,608.505188 891.665955,606.045959 890.667542,602.747253 
	C889.468689,598.786194 891.249512,598.076294 894.551392,597.953369 
	C896.954895,597.863831 899.231201,598.399048 901.391357,599.133179 
	C904.302002,600.122192 907.215088,602.050537 906.755127,605.411133 
	C906.332031,608.502075 904.512268,610.581543 900.958740,612.430420 
	C895.528137,615.255981 890.841675,617.406311 884.873108,615.064026 
	C884.051697,614.741577 882.772461,614.922241 882.359619,616.109375 
	C881.930542,617.343262 883.012939,617.807251 883.755310,618.443970 
	C885.017578,619.526672 886.617798,620.593323 884.921997,622.495483 
	C883.471313,624.122681 881.904663,624.557495 880.164429,622.301392 
	C879.379944,618.560852 877.345032,617.157898 873.590759,617.710571 
	C872.663818,617.494995 871.930725,616.769897 871.522217,618.312866 
	C869.995544,620.742188 867.734192,620.489868 865.643494,620.569153 
	C861.712830,620.718262 857.772705,620.500610 852.933167,620.775330 
	C857.309143,622.056152 861.200073,620.498535 864.778137,622.730591 
	C864.713562,626.136536 865.651855,627.935730 869.270752,628.156616 
	C872.357117,628.345093 875.385742,629.401489 878.454651,629.998474 
	C879.443604,630.190857 880.580872,630.163940 880.704346,631.432678 
	C880.820435,632.626160 879.789368,633.149475 878.862305,633.481323 
	C876.788330,634.223816 874.669678,634.286743 872.281189,633.272522 
	C869.967834,630.861694 867.773010,630.967957 865.236389,632.766174 
	C863.297424,636.596558 860.127380,634.776367 857.609253,634.809448 
	C855.199829,634.841248 855.158997,632.658508 854.909241,630.782959 
	C854.722412,629.380005 854.510925,627.327698 852.560730,627.643372 
	C850.754700,627.935669 850.864807,629.960144 851.039795,631.398193 
	C851.685547,636.705688 850.438538,640.509705 844.297852,641.190552 
	C842.197815,641.423340 842.143311,643.302185 841.980347,645.035522 
	C841.406494,651.140991 839.289368,652.236816 833.232483,649.280518 
	C830.614868,646.601990 828.158386,645.190552 825.182190,648.724976 
	C824.895935,651.133118 825.024719,653.309814 824.616272,655.380493 
	C824.266785,657.152100 823.320923,658.829346 821.075073,658.944885 
	C818.776306,659.063171 816.144470,658.531372 815.823547,656.234680 
	C815.029846,650.554016 811.275757,650.729858 807.173401,650.978577 
	C806.035889,651.047607 804.818481,651.080017 804.099365,649.384277 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M781.239502,647.247620 
	C781.000366,646.250000 781.000183,645.500000 781.000000,644.375000 
	C783.569641,640.317139 788.486023,638.576965 792.403992,640.231567 
	C796.056152,641.773865 795.580811,645.135681 795.244934,648.621643 
	C793.723999,649.125793 791.974121,648.854248 792.287292,650.904297 
	C792.564697,652.720398 793.990845,653.864807 795.796387,653.890076 
	C799.128235,653.936890 802.407776,654.223755 805.644470,655.006104 
	C806.444946,655.199524 807.756836,655.159607 807.967346,655.621582 
	C810.221619,660.568970 815.785217,662.463806 818.387390,666.655273 
	C820.008789,669.266968 821.196045,670.200684 823.893738,670.011841 
	C826.677551,669.816956 829.574951,670.528870 832.163818,668.885071 
	C833.038818,668.329468 833.835205,667.674133 833.759094,666.552185 
	C833.671265,665.259033 832.626038,665.072815 831.593567,665.020020 
	C829.133789,664.894409 827.314148,663.792969 827.135620,661.243469 
	C826.931641,658.329529 829.230835,657.548035 831.503601,656.640076 
	C837.450317,654.264465 843.316833,654.064636 849.296692,656.474854 
	C854.321533,658.500183 856.923950,656.751465 856.998108,651.450439 
	C857.039978,648.459351 856.948669,645.459900 857.153687,642.480469 
	C857.255249,641.004211 857.840698,639.338135 859.636475,639.174988 
	C861.950623,638.964783 863.869507,639.986267 864.999390,642.077515 
	C865.711792,643.396179 867.802124,644.618958 866.004944,646.374023 
	C860.698730,651.556030 863.420349,657.862061 863.496704,663.767456 
	C863.513245,665.045105 864.480896,666.636108 863.497192,667.571350 
	C859.637268,671.241211 859.869263,675.876526 859.962952,680.549683 
	C859.995850,682.190735 859.399353,683.523438 857.595764,683.847351 
	C856.457764,684.051697 855.187500,684.236694 854.430542,683.073242 
	C853.846436,682.175537 853.979370,681.106689 854.590881,680.249573 
	C855.569763,678.877441 858.091858,677.863647 856.386230,675.799316 
	C854.766846,673.839355 852.688110,676.149353 850.391663,675.988037 
	C847.296936,672.622253 843.491882,673.685791 839.709900,673.413086 
	C841.294800,673.979858 842.599243,674.441162 843.818359,675.707764 
	C843.989380,677.623718 842.557739,679.073853 843.425598,680.801636 
	C844.432861,682.807068 843.414062,683.926758 841.674316,684.657410 
	C839.643616,685.510315 838.637939,687.157593 838.168457,689.166321 
	C837.846436,690.544067 838.099121,691.871765 839.509949,692.567383 
	C840.999756,693.302063 842.017334,692.507324 842.825439,691.296997 
	C843.467529,690.335205 844.082092,689.352234 844.772217,688.425842 
	C847.531433,684.721802 850.494446,685.029724 852.949097,689.625916 
	C851.766296,691.550171 849.496643,689.954834 848.073242,691.763916 
	C849.595032,693.243652 851.020691,691.522949 852.691467,691.847900 
	C854.842102,695.875549 859.602234,697.653076 860.394348,702.328430 
	C860.509094,703.005676 861.894470,703.707458 861.291931,704.367798 
	C855.866394,710.314453 861.898926,708.289185 864.404480,709.147095 
	C869.262024,710.810303 865.188904,712.647095 864.155273,714.701721 
	C862.951782,716.841919 859.365356,715.642273 859.429016,717.996033 
	C859.494263,720.408264 862.662048,720.372864 864.568481,721.829224 
	C865.545227,722.450745 866.119324,723.082581 867.198792,723.461853 
	C868.106262,723.535645 868.715271,723.586609 869.640259,723.552185 
	C870.780396,723.389648 871.504944,723.150085 871.166260,721.787354 
	C870.451111,720.591309 869.765991,719.725891 869.845154,718.291748 
	C870.956177,713.740601 874.392273,714.290344 876.611328,715.653625 
	C879.853455,717.645325 882.977417,719.772339 886.482178,721.332214 
	C888.678040,722.309509 889.129944,724.135742 888.037476,726.649109 
	C886.366394,728.831787 884.204163,728.461670 881.760559,728.370117 
	C879.760132,728.026245 878.020386,727.113708 876.483887,729.285706 
	C875.438354,729.972656 874.592529,730.211426 873.332520,730.055359 
	C871.615417,730.512878 870.268066,731.234070 868.837952,731.486572 
	C866.418030,731.913757 862.938782,733.703552 864.288574,728.281067 
	C864.699280,726.631042 862.701355,727.223877 861.351318,727.003174 
	C860.008972,727.822083 858.929810,728.068787 857.364258,727.254150 
	C850.785400,728.470459 845.918213,724.691162 840.692566,722.587646 
	C837.792358,721.420227 837.149231,715.498779 839.742798,714.641602 
	C844.677002,713.010986 844.066101,709.493591 844.000000,705.438904 
	C843.509949,704.515137 843.319458,703.914001 843.218750,703.237183 
	C842.974304,701.593689 843.970215,698.867554 841.362183,698.911743 
	C838.976440,698.952148 839.728577,701.692017 839.373169,703.358948 
	C839.304382,703.681458 839.252747,704.008057 839.076050,704.663452 
	C838.686829,707.796936 840.177124,711.569763 836.403198,712.739807 
	C832.403625,713.979736 829.200439,711.109924 825.972351,709.180542 
	C825.570862,708.940552 825.318420,707.988403 825.419861,707.445068 
	C826.086426,703.873779 823.883057,703.184937 821.120239,703.003967 
	C819.979248,702.929260 818.741943,703.103943 818.086304,701.362061 
	C819.952515,698.781799 824.153748,700.191772 825.456299,696.258545 
	C831.160645,695.738647 829.752319,700.609680 831.291687,703.689209 
	C831.834473,705.243286 831.111816,706.522278 831.770386,707.939941 
	C832.950256,708.706360 833.591248,708.687439 833.399536,706.969727 
	C833.378906,706.433472 833.389160,706.215210 833.465454,705.670898 
	C834.905701,702.123535 834.756958,699.116943 832.432434,696.315308 
	C831.602905,695.315674 831.168213,694.078674 831.400818,692.382324 
	C834.325867,689.095947 835.449463,686.265137 831.015076,683.702576 
	C830.292480,683.285034 829.899780,682.496887 829.867615,681.323730 
	C832.854614,679.289734 836.303772,679.647522 840.114136,678.209351 
	C835.069092,676.114380 830.521790,675.863403 825.454590,676.001587 
	C823.436340,676.155579 821.885925,675.182007 820.228821,675.934265 
	C821.156799,676.958008 822.642517,676.610779 823.785156,677.731689 
	C822.828796,681.297424 820.303772,679.950745 818.225037,679.539734 
	C814.237244,678.751526 810.586731,678.378601 807.205566,681.616516 
	C804.708923,684.007202 800.837280,682.677185 797.406738,683.988525 
	C794.375854,682.176819 792.175720,679.912292 789.852173,677.297791 
	C789.328308,677.030029 789.125183,676.906860 788.638855,676.562073 
	C787.424744,675.244995 786.218323,674.281128 786.779053,672.289246 
	C788.616577,671.025208 790.397888,670.911438 792.609131,671.001953 
	C796.130859,671.025024 796.356079,670.610229 795.227661,666.239746 
	C794.504333,665.740784 794.008667,665.481567 793.256470,665.109924 
	C791.595947,663.562744 789.651550,663.711304 787.914246,663.235474 
	C786.765381,662.920837 785.613953,662.406738 785.606934,660.984131 
	C785.601318,659.848755 786.583618,659.380798 787.440063,658.827942 
	C791.018433,656.517944 791.017639,655.788391 787.865234,652.856750 
	C785.823792,650.958313 782.704956,650.351562 781.239502,647.247620 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M745.581543,766.936279 
	C749.785706,771.651855 753.712952,772.307678 758.096252,767.670105 
	C759.160217,766.544312 760.621887,767.088684 761.549011,768.301025 
	C762.293945,769.275085 763.093933,769.966675 764.402283,770.001648 
	C766.179382,770.049072 767.930908,770.466064 767.751160,772.709656 
	C767.603455,774.553101 766.187012,775.862793 764.419678,775.853271 
	C759.953491,775.829346 759.996826,778.658875 759.956116,781.889099 
	C759.906860,785.787720 762.361206,785.856018 765.213196,786.143738 
	C767.495667,786.374084 769.717163,787.159180 772.077942,787.034607 
	C773.478149,786.960632 774.833008,787.226013 774.848938,789.072876 
	C774.864685,790.897949 773.773987,791.791382 772.065430,791.927124 
	C770.744141,792.031982 769.410522,791.982971 768.082397,791.999634 
	C764.208252,792.048340 759.981201,790.971375 759.228149,797.093750 
	C758.888855,799.852356 755.705566,799.085815 753.696289,798.938354 
	C749.628479,798.639771 747.091003,800.169800 747.089966,804.338501 
	C747.089172,807.430359 744.275635,808.224792 743.063721,810.655396 
	C742.250000,811.000000 741.500000,811.000000 740.375000,811.000000 
	C738.344788,806.050476 739.217041,800.792419 738.106140,795.736816 
	C737.521362,793.075806 736.515625,791.936584 733.896851,791.999207 
	C724.441223,792.225525 714.969055,791.497681 705.263184,792.245178 
	C702.740601,787.066528 704.078613,784.216675 709.147522,783.534607 
	C712.784912,783.045105 716.467957,783.385681 720.129700,783.304504 
	C721.628296,783.271240 723.136169,783.278625 724.584351,782.830627 
	C725.593445,782.518433 726.405518,781.923706 726.420105,780.756653 
	C726.436157,779.470520 725.452209,779.144409 724.433716,778.955322 
	C722.126221,778.526672 719.771240,778.834106 717.458679,778.554932 
	C714.982727,778.256104 712.362549,778.097534 711.554199,774.636353 
	C711.906006,773.832458 712.239685,773.273560 712.699097,773.294006 
	C721.015869,773.662292 729.476746,772.094788 737.603088,774.980957 
	C739.490417,775.651306 741.422363,776.820496 743.173279,775.333252 
	C744.839111,773.918457 743.592651,771.942505 743.324036,769.796875 
	C743.387573,768.157166 743.991333,767.303223 745.581543,766.936279 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M760.746033,747.326355 
	C761.853821,745.764648 764.533020,745.874756 763.826660,743.567810 
	C763.198547,741.516479 761.342834,740.540405 759.391968,740.132690 
	C757.493347,739.735962 755.514282,739.724304 753.285278,739.271790 
	C753.428040,735.876892 757.160828,736.535645 758.685242,734.153503 
	C762.987305,734.218567 762.885864,731.526062 763.072876,728.679871 
	C763.225403,726.358215 761.615906,722.199951 765.655884,722.522217 
	C768.945374,722.784607 767.452759,726.320129 767.115295,728.724426 
	C766.725281,731.502502 766.909607,734.304321 766.284241,737.125977 
	C765.553589,740.422302 771.523804,744.144226 774.961792,742.398682 
	C783.139160,738.246948 791.661499,738.152161 800.702148,739.723145 
	C801.144958,742.185059 799.312439,742.086548 798.030090,742.409729 
	C796.139282,742.886292 794.648376,743.618286 794.333923,746.146851 
	C792.403564,751.631287 791.651855,756.844482 792.974915,762.614990 
	C793.002441,763.443970 793.002380,763.888000 793.002441,764.664917 
	C791.029053,766.162964 788.766357,766.467163 786.739929,765.807739 
	C783.628662,764.795227 785.453552,762.294617 785.597107,760.329468 
	C785.683838,759.142456 784.856079,758.147400 783.916443,758.241760 
	C778.344543,758.801575 773.676147,753.042053 767.406372,755.998413 
	C766.112122,754.851013 764.718323,754.792542 763.504272,754.300842 
	C760.467529,753.070679 758.940430,751.024841 760.746033,747.326355 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M887.020752,694.506592 
	C887.340393,689.814453 886.121460,685.439941 888.251099,681.327698 
	C889.235046,679.427917 887.818787,679.129395 886.321228,679.001160 
	C884.333984,678.830933 882.752197,679.528809 881.481079,681.019287 
	C879.302612,683.573730 876.446289,683.419250 873.698914,682.598450 
	C871.532043,681.951050 870.951599,679.982300 870.989075,677.784302 
	C871.045898,674.452881 873.258850,672.498779 875.786438,671.389771 
	C879.622253,669.706665 883.864807,669.735596 888.025146,670.064270 
	C890.708801,670.276367 891.207092,672.243530 890.959961,674.373047 
	C890.509583,678.253235 892.309387,679.438293 895.876587,679.027771 
	C897.351624,678.858093 898.863647,679.037720 900.356812,678.992310 
	C903.533142,678.895752 906.608582,679.091553 906.931946,683.214172 
	C907.225769,686.959839 907.450806,690.849548 901.959595,691.058289 
	C899.923096,691.135681 899.089050,692.424622 899.007446,694.353516 
	C898.880920,697.346069 897.305420,698.916016 894.302002,698.985474 
	C891.101013,699.059570 887.742737,699.381348 887.020752,694.506592 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M819.777893,808.737061 
	C820.232483,813.326050 820.590210,817.532227 815.189880,819.492737 
	C813.268921,820.190063 812.634277,822.310669 813.679077,824.598328 
	C814.381165,826.135559 814.668274,827.858826 816.813293,827.910217 
	C819.203491,827.967529 820.196350,826.243774 821.274231,824.493530 
	C821.974976,823.355652 821.663818,820.929199 823.775940,821.417419 
	C825.670654,821.855347 824.654907,823.955566 825.021667,825.299011 
	C826.572754,830.980530 822.533569,833.242065 818.702332,835.444092 
	C816.682251,836.605225 814.602478,836.112549 814.140442,833.485229 
	C813.670288,830.811462 812.247131,829.444519 809.735291,828.750366 
	C807.508850,828.135071 806.103271,826.792969 805.805054,824.215271 
	C805.532532,821.859802 803.882263,820.085693 801.431030,820.255493 
	C798.625000,820.449707 799.052429,822.965942 799.001953,824.910889 
	C798.943420,827.163086 797.534424,829.232300 795.839233,827.359375 
	C791.604492,822.680847 785.967163,824.335449 781.025391,823.037292 
	C779.624573,822.669312 777.581116,823.087158 777.549622,821.113586 
	C777.511597,818.730225 779.714966,819.116699 781.305542,819.008301 
	C784.932556,818.760986 788.478943,817.966614 792.150635,817.847229 
	C797.068970,817.687256 797.823730,812.919983 799.955811,809.351562 
	C801.305908,807.869446 802.744263,807.622681 804.664429,808.675476 
	C805.469849,809.315491 805.939697,809.630920 806.703552,809.973206 
	C807.492371,810.448914 807.896179,810.962402 808.209900,811.558472 
	C808.807861,812.694763 809.007507,814.413452 810.602539,814.425110 
	C812.367065,814.437988 812.281433,812.644287 812.634460,811.452698 
	C813.762024,807.648132 816.203430,807.037109 819.777893,808.737061 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M931.941101,679.002441 
	C927.408020,680.135559 923.274109,681.217773 919.167847,682.396484 
	C915.911011,683.331482 912.724182,683.814331 911.215759,679.865723 
	C909.601746,675.640930 911.327942,671.986267 915.478577,671.080139 
	C918.007019,670.528198 918.743713,669.027771 918.932007,666.934265 
	C919.095520,665.117432 919.043823,663.274475 918.987854,661.446106 
	C918.888184,658.190247 919.297485,655.263916 923.422424,655.041687 
	C927.575867,654.817993 930.326599,657.266113 930.798157,660.957520 
	C931.358948,665.347412 930.645386,669.483765 935.765320,672.249329 
	C938.775757,673.875366 936.846313,677.614685 931.941101,679.002441 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M841.584717,783.588623 
	C837.504089,785.703430 833.163391,784.996216 829.288025,786.789429 
	C826.830933,787.926331 825.326965,785.691345 825.109253,783.243286 
	C824.903687,780.931946 824.852966,778.577942 825.038025,776.267090 
	C825.355164,772.306152 824.032166,769.206482 821.120056,766.504456 
	C819.866760,765.341675 817.037659,764.210266 818.930786,761.799377 
	C820.697937,759.548950 822.562866,756.816833 826.193420,757.603699 
	C830.368591,758.508545 834.014709,760.904175 838.381653,761.545105 
	C840.931824,761.919373 842.054504,765.002441 840.237305,767.110046 
	C837.829773,769.902283 839.025085,771.561096 840.812988,773.766541 
	C843.136963,776.633118 844.151367,779.857239 841.584717,783.588623 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M924.781982,590.259399 
	C927.511230,590.108643 929.857483,589.891663 932.164917,590.101562 
	C935.277954,590.384888 936.980164,592.337708 936.925964,595.455566 
	C936.869263,598.716919 934.313721,598.900269 931.929932,598.976013 
	C927.493713,599.117065 923.106873,598.737854 918.825317,600.880798 
	C916.545959,602.021667 915.792297,603.246765 916.107239,605.426514 
	C916.425110,607.626831 917.512573,609.072876 920.056152,609.016235 
	C922.379456,608.964417 924.745422,608.902283 927.021484,609.277100 
	C928.790833,609.568359 930.639587,610.370544 930.892883,612.609741 
	C931.136353,614.762085 929.806580,616.135132 928.074341,616.894836 
	C923.939575,618.708069 919.663635,618.466064 915.444824,617.150452 
	C913.884521,616.663879 912.948669,615.437500 913.048401,613.732910 
	C913.199280,611.153381 912.479309,608.838135 911.409241,606.526978 
	C911.036865,605.722717 910.924500,604.269775 911.398743,603.685669 
	C915.114075,599.108887 915.811890,591.661072 923.467346,590.777405 
	C923.783081,590.741028 924.077393,590.518921 924.781982,590.259399 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M817.219604,700.739136 
	C811.059387,701.956116 806.055359,699.620728 801.281921,696.506714 
	C798.425964,694.643738 798.137268,691.210815 800.671631,688.950134 
	C801.276184,688.410828 802.013367,687.854370 802.774658,687.682251 
	C809.884949,686.074402 817.013794,684.548584 824.566162,682.998291 
	C826.674927,684.334290 827.034912,686.331787 827.629578,688.589417 
	C827.148010,691.099976 825.597412,692.717529 824.562012,694.979370 
	C823.415405,696.057922 822.267029,696.360718 821.110596,696.371887 
	C818.319214,696.398743 817.306824,697.897766 817.219604,700.739136 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M856.702942,747.338867 
	C862.128540,746.019470 867.115784,744.235962 872.533813,745.621643 
	C877.806580,746.970215 879.944275,745.482483 880.998474,740.011536 
	C881.574219,737.023560 883.535522,736.938904 885.795715,737.043335 
	C888.426880,737.164978 889.344910,738.867676 888.873901,741.100830 
	C888.289551,743.871399 887.895020,746.617310 887.949158,749.445068 
	C887.979126,751.011292 887.186462,752.003418 885.546204,751.938538 
	C876.310120,751.573608 867.019897,753.025574 857.416382,751.060181 
	C855.953979,750.045654 855.981201,748.897339 856.702942,747.338867 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M916.347046,722.868042 
	C911.419128,722.966309 906.914917,722.842773 902.425232,723.043457 
	C899.035828,723.194946 897.074707,721.150818 895.620911,718.756775 
	C893.269287,714.884155 895.821411,710.802612 900.472656,711.075012 
	C904.914429,711.335205 909.379089,711.904907 913.731506,712.822876 
	C919.778809,714.098328 920.773804,717.717224 916.347046,722.868042 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M837.374084,836.854980 
	C838.367615,834.954956 839.951538,834.135559 841.573608,834.147827 
	C845.979431,834.181213 849.496765,833.732178 849.390442,827.891724 
	C849.355896,825.996826 851.768677,825.990967 853.458557,826.021851 
	C855.073242,826.051392 856.529968,826.493835 856.800110,828.307434 
	C857.033081,829.872009 857.711914,831.906494 855.768433,832.739685 
	C852.166504,834.283997 850.455017,836.349731 850.243896,840.677307 
	C850.105286,843.518555 847.826721,847.136047 843.256287,845.081421 
	C842.837524,844.893188 842.273132,845.019043 841.776306,845.015869 
	C838.495239,844.994873 829.801758,848.861206 838.424194,840.281677 
	C839.306274,839.404053 837.521606,838.322083 837.374084,836.854980 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M820.371948,809.058472 
	C816.327148,808.649414 813.237183,808.904419 813.910889,813.988159 
	C814.100037,815.415710 813.211243,816.532532 811.667603,816.841553 
	C809.992737,817.176941 808.787537,816.400391 807.795654,815.137085 
	C806.769775,813.830444 807.068787,812.291565 806.998413,810.407593 
	C808.621765,808.871033 810.530518,807.996643 811.813843,806.562561 
	C814.945007,803.063782 817.868958,801.419739 821.517090,805.895447 
	C822.623291,807.252563 824.076050,807.269348 825.209290,805.587402 
	C826.054504,804.332886 827.066406,803.256714 828.710449,803.064392 
	C831.037720,802.792175 833.204773,803.036743 833.703125,805.800049 
	C834.142944,808.239136 832.505493,809.727295 830.369202,809.843201 
	C826.679504,810.043396 824.536621,811.541626 824.554688,816.179138 
	C822.025574,813.800354 824.224792,809.879761 820.371948,809.058472 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M849.882996,776.624939 
	C853.710022,776.093079 857.058533,775.920471 857.727783,771.161560 
	C857.984375,769.337402 860.306274,768.792480 862.302307,769.078369 
	C864.369751,769.374634 865.000854,770.781250 864.977356,772.632507 
	C864.943542,775.290833 865.376526,777.873474 865.777527,780.498169 
	C866.204895,783.295654 864.735291,785.789612 861.912842,786.748840 
	C859.235840,787.658752 858.577087,785.220459 857.773865,783.480530 
	C856.450378,780.613647 854.117126,779.963623 851.247314,779.960327 
	C849.035461,779.957825 846.423523,779.852051 849.882996,776.624939 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M953.341553,611.355652 
	C948.809326,609.718079 944.609924,611.819763 940.432739,610.975769 
	C939.049072,610.696167 936.595886,612.104858 936.617432,609.355408 
	C936.632446,607.437256 938.093933,606.303467 940.270996,605.977966 
	C948.026367,604.818237 955.824036,604.247742 963.447693,606.212646 
	C967.700928,607.308838 972.038208,606.329224 977.255615,607.679321 
	C971.679932,610.585205 965.952271,607.169922 961.250244,610.707153 
	C958.741882,613.097839 956.257812,612.870728 953.341553,611.355652 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M882.848450,783.999756 
	C880.548584,784.000000 878.720154,784.011963 876.892029,783.997681 
	C871.733459,783.957458 870.150085,782.251648 870.241699,777.164734 
	C870.276733,775.220215 871.444519,775.033813 872.852234,775.022400 
	C876.342285,774.994080 879.837952,775.124268 883.321472,774.969238 
	C886.748840,774.816589 887.964783,776.265869 888.087646,779.671814 
	C888.221802,783.391174 886.578003,784.395508 882.848450,783.999756 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M921.212769,642.000000 
	C924.992493,642.001526 928.304688,642.003601 931.616943,642.004272 
	C933.716064,642.004639 935.783020,642.165771 935.871338,644.942871 
	C935.948181,647.358887 934.177734,647.589966 932.264160,648.091187 
	C927.029114,649.462341 921.738281,648.850647 916.468811,648.983276 
	C914.822510,649.024780 912.916443,649.171570 912.334778,647.120911 
	C911.709473,644.916382 913.569519,643.851074 914.959229,642.775146 
	C916.674744,641.447021 918.797485,642.160889 921.212769,642.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M967.796631,577.607178 
	C961.693481,579.985046 953.431030,575.477783 954.013245,570.541260 
	C954.130493,569.546997 954.070801,568.298279 955.031860,567.885803 
	C958.820557,566.259888 962.868347,566.486755 966.612671,567.608765 
	C969.501160,568.474304 969.011902,571.574219 968.997498,574.053284 
	C968.990601,575.231995 968.875854,576.368469 967.796631,577.607178 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M879.744446,714.656982 
	C877.720032,711.297058 877.242371,707.278748 872.677490,706.278931 
	C871.708069,706.066650 871.802490,703.685303 873.022644,703.498047 
	C876.298096,702.995300 880.335510,701.347595 882.603027,704.811768 
	C885.286133,708.910889 886.889404,706.838867 888.870667,704.534790 
	C890.481812,705.474548 888.954651,706.580933 889.124451,707.576477 
	C890.406982,715.095459 890.387756,715.102905 882.607605,714.992065 
	C881.782288,714.980347 880.958130,714.883423 879.744446,714.656982 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M795.027588,781.299805 
	C795.657104,777.493042 798.052307,776.915771 800.977783,776.982056 
	C804.501770,777.061951 808.157898,777.018921 810.412781,780.253540 
	C812.289612,782.945923 810.442993,786.544556 807.021057,786.892639 
	C804.063354,787.193604 801.042847,787.093140 798.064636,786.919922 
	C794.656799,786.721741 795.035400,784.046570 795.027588,781.299805 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M896.044922,555.991577 
	C894.003052,555.668823 892.401672,555.290039 890.779907,555.046875 
	C886.490173,554.403503 884.572754,551.078430 886.197510,547.043823 
	C886.675293,545.857361 887.565125,544.869324 888.774963,545.152344 
	C892.397339,545.999512 896.116943,546.750977 899.484497,548.246826 
	C901.463745,549.126038 903.406250,551.476990 902.546936,553.899109 
	C901.624207,556.500061 898.745789,555.925476 896.044922,555.991577 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M872.692505,616.782593 
	C879.645264,615.281921 881.156250,616.178711 880.147827,621.666260 
	C875.299194,622.335144 870.598389,622.667908 865.450012,623.001587 
	C861.513428,623.001587 858.021240,623.090088 854.536804,622.963196 
	C852.707214,622.896545 849.752747,623.819214 849.644653,621.101318 
	C849.531311,618.254211 852.506531,619.088623 854.320374,619.036072 
	C858.304443,618.920654 862.295593,619.061035 866.280884,618.969360 
	C867.838074,618.933594 869.680359,619.638428 870.896484,617.391174 
	C871.398499,616.651794 871.859375,616.506042 872.692505,616.782593 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M793.237854,762.774597 
	C790.195435,757.610901 789.753784,752.197998 792.919434,746.374756 
	C797.631287,743.986145 799.256226,744.458191 802.457520,749.072144 
	C803.317261,752.817078 806.886047,750.777527 808.731628,752.761780 
	C808.781860,755.334534 807.286316,756.024658 805.192871,755.997192 
	C803.360657,755.973145 801.527649,755.985291 799.695190,756.002136 
	C793.867249,756.055664 792.964722,757.009521 793.237854,762.774597 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M795.333313,649.000000 
	C794.906433,648.519287 794.841614,648.030823 794.715088,647.558960 
	C792.629700,639.781921 787.815125,638.411926 781.359009,643.927856 
	C782.048218,640.833313 781.434143,635.805298 787.616882,638.808167 
	C789.451172,639.699097 791.741577,638.829773 791.912170,636.130310 
	C792.070801,633.620850 792.827820,631.912903 795.678650,632.018188 
	C797.446167,632.083435 799.685547,631.465637 800.502075,633.600647 
	C801.393311,635.930725 801.777161,638.673523 799.737854,640.597168 
	C797.448730,642.756592 796.652588,645.189209 797.000000,648.583191 
	C796.555542,648.998352 796.111084,648.999146 795.333313,649.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M879.667358,727.012085 
	C882.369934,726.999146 884.739807,726.998352 887.553650,726.996582 
	C889.690369,729.513489 889.610352,731.166138 886.003296,730.462708 
	C884.114014,730.094299 882.895020,731.099121 882.338196,732.299561 
	C879.709229,737.967041 874.761597,737.105957 870.037964,736.985413 
	C869.090698,736.961243 867.845825,737.189636 867.561218,735.955383 
	C867.313660,734.882141 868.303162,734.222107 869.052551,733.595459 
	C870.282471,732.566895 872.211060,732.455261 872.938843,730.342651 
	C873.557068,729.157776 874.413025,729.012390 875.664429,729.003540 
	C876.946472,728.062683 877.838867,727.034180 879.667358,727.012085 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M893.406128,569.994385 
	C891.843811,569.875183 890.379211,570.067566 890.398376,568.417908 
	C890.413269,567.143250 891.636658,567.043213 892.640503,567.034851 
	C896.452087,567.003052 900.264099,567.016418 904.075806,566.997437 
	C907.040466,566.982666 910.036804,567.322205 912.780762,565.656250 
	C914.777832,564.443726 916.294922,565.486755 917.418091,567.121582 
	C918.040466,568.027466 919.003052,569.040222 918.317139,570.261536 
	C917.745605,571.279175 916.563782,570.935059 915.609741,570.992126 
	C908.322266,571.428589 901.151184,569.297424 893.406128,569.994385 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M929.486633,560.620483 
	C931.690857,561.381531 933.546448,561.967529 935.402039,562.553467 
	C930.508301,562.889282 927.078735,569.221069 921.402771,565.764404 
	C919.325134,564.499146 916.892822,563.247375 917.514709,560.656372 
	C918.244202,557.617310 921.255005,557.932007 923.775208,558.028564 
	C925.887390,558.109436 927.519104,559.265259 929.486633,560.620483 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M869.636719,717.993958 
	C870.576782,718.818909 871.156067,719.637817 871.867676,720.728394 
	C873.366638,722.932312 871.662415,723.590027 870.275024,724.745483 
	C869.250061,724.997681 868.500183,724.997864 867.375305,724.997803 
	C866.474915,724.456848 865.932739,723.932922 865.197510,723.202271 
	C863.472107,722.426208 861.975891,721.736694 860.408508,721.306885 
	C858.274658,720.721741 856.775635,719.750549 857.060425,717.249268 
	C857.416199,714.124268 859.884705,715.263245 861.648987,715.010864 
	C862.132202,714.941833 862.633606,715.000610 863.563354,715.001587 
	C865.159119,717.057007 867.085510,717.755798 869.636719,717.993958 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M831.666077,753.791504 
	C827.030823,756.546265 825.469727,753.127136 823.726685,750.331055 
	C822.496155,748.357117 823.246277,746.857666 825.263062,745.900024 
	C828.407471,744.407043 831.676514,744.350586 834.025269,746.930725 
	C836.623413,749.785034 833.700745,751.647827 831.666077,753.791504 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M904.095215,637.000000 
	C901.324036,636.542542 897.396729,638.340210 897.398132,634.374451 
	C897.399536,630.639648 901.120178,630.989929 903.926941,631.003723 
	C905.738770,631.012634 907.572754,630.891602 909.356262,631.130920 
	C911.311218,631.393250 912.877686,632.573120 912.737610,634.691772 
	C912.595520,636.842163 910.710327,636.972229 908.998291,636.991272 
	C907.515076,637.007812 906.031555,636.998108 904.095215,637.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M847.171631,811.000000 
	C845.568848,810.955994 844.302246,811.197144 843.272217,810.809570 
	C841.029968,809.965698 838.733643,808.464661 839.256592,805.839844 
	C839.882202,802.699768 842.673584,803.987549 844.583984,804.422974 
	C847.629944,805.117249 850.585754,806.203064 853.588135,807.094666 
	C854.593506,807.393372 855.563293,807.795654 855.528381,808.989624 
	C855.485168,810.472290 854.292786,810.858887 853.087769,810.924438 
	C851.267517,811.023376 849.439636,810.981812 847.171631,811.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M826.966492,717.691406 
	C825.636658,722.747437 821.828308,721.167664 819.195129,720.642029 
	C815.903259,719.984985 816.739319,716.630371 817.246094,714.428650 
	C818.100464,710.717163 821.454041,712.200867 823.854248,712.054993 
	C827.980652,711.804260 826.773010,715.013977 826.966492,717.691406 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M775.261353,825.999451 
	C777.863220,826.021912 780.021973,825.978760 782.172546,826.091370 
	C783.376221,826.154419 784.557190,826.594482 784.502502,828.103149 
	C784.459595,829.286804 783.482178,829.839783 782.459534,829.871460 
	C776.845459,830.045410 771.201660,830.604858 765.834717,828.108398 
	C764.036011,827.271729 763.702026,826.119080 764.424805,824.551880 
	C765.178223,822.918396 766.472717,823.242004 767.784119,823.902222 
	C770.000000,825.017883 772.166870,826.322632 775.261353,825.999451 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M952.756470,611.288086 
	C955.370544,611.000000 957.741150,611.000000 960.555847,611.000000 
	C962.737976,614.581360 960.419312,615.132080 957.799805,614.948486 
	C953.586426,614.653137 949.403076,613.944092 945.956543,617.407532 
	C945.469604,617.896912 944.396667,617.956299 943.596436,617.951721 
	C941.368225,617.938843 938.054382,618.593201 937.771057,615.866516 
	C937.492126,613.182251 940.880920,614.595337 942.500366,613.859558 
	C945.705139,612.403564 949.558838,613.970886 952.756470,611.288086 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M827.218933,689.246033 
	C825.975830,687.380676 824.620300,685.889893 824.997253,683.368347 
	C825.453308,680.329712 827.580322,681.186218 829.606812,681.000000 
	C830.499817,682.130249 831.498108,682.663025 832.508484,683.264709 
	C837.308167,686.123047 837.351929,688.588989 832.363098,691.951050 
	C830.142517,691.778076 828.754517,690.701172 827.218933,689.246033 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M979.013794,594.605225 
	C973.865051,594.876038 969.067139,594.952820 964.268921,594.978821 
	C962.751160,594.987061 961.040405,594.970520 961.222412,592.761292 
	C961.374084,590.919800 962.946899,591.031921 964.254761,591.021301 
	C967.731750,590.993103 971.239014,590.754150 974.674683,591.135315 
	C976.525085,591.340576 979.727783,590.596558 979.013794,594.605225 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M931.160278,551.600098 
	C934.478027,551.263123 937.445862,551.122192 940.415222,551.033386 
	C942.458008,550.972290 943.564941,552.243713 943.674500,554.053345 
	C943.815796,556.387146 941.830627,555.934326 940.445068,555.974304 
	C937.793945,556.050903 935.137817,556.049316 932.486511,555.977905 
	C931.215393,555.943665 929.472595,556.590881 928.797485,555.303711 
	C927.876709,553.548218 929.937683,552.891907 931.160278,551.600098 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M805.372009,707.608032 
	C807.621704,704.155396 810.308838,702.257019 814.277222,703.075500 
	C816.863953,703.609009 817.522522,705.319031 816.512756,707.501282 
	C815.041565,710.680786 807.754028,710.909912 805.372009,707.608032 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M848.990723,856.610474 
	C845.502014,856.994263 842.003601,857.018555 838.506226,856.971252 
	C836.511047,856.944336 834.556458,856.186218 834.536377,854.055115 
	C834.514526,851.735596 836.821289,851.805115 838.356445,852.067932 
	C842.150391,852.717468 847.541077,849.536560 848.990723,856.610474 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M968.558289,628.024780 
	C970.946777,628.275818 972.150513,629.302307 971.791626,631.334106 
	C971.424255,633.414246 969.572327,632.937988 968.162170,632.979126 
	C965.841980,633.046875 963.518250,632.994202 961.195984,632.990112 
	C959.606201,632.987305 958.048950,632.823975 958.150635,630.700378 
	C958.237122,628.893066 959.467407,628.098389 961.156616,628.051636 
	C963.477173,627.987488 965.800354,628.017761 968.558289,628.024780 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M856.679810,746.829102 
	C857.001892,748.066711 857.001343,749.133362 857.001587,750.600037 
	C856.856689,751.077209 856.688782,751.129333 856.568420,751.235352 
	C853.749268,753.716797 850.826172,753.543457 848.082764,751.273254 
	C846.616028,750.059570 846.511353,748.176636 847.931580,746.906006 
	C850.559448,744.555054 853.472839,744.119812 856.679810,746.829102 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M953.012085,553.405762 
	C958.021423,553.002014 963.042908,553.001587 968.531006,553.001831 
	C968.929932,553.647766 969.094238,554.469727 968.758423,554.910889 
	C965.660217,558.979736 961.261169,556.333130 957.484192,556.941895 
	C955.243530,557.302979 953.162781,556.765503 953.012085,553.405762 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M817.250000,701.000000 
	C814.146362,695.597656 814.728516,695.028687 823.569519,695.000000 
	C824.751526,694.384644 825.422424,694.545532 826.019165,695.607300 
	C826.987732,702.449463 822.307983,700.829224 818.443481,701.000000 
	C818.000000,701.000000 817.500000,701.000000 817.250000,701.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M928.106079,574.000000 
	C930.909058,574.623474 934.969299,572.374573 935.318115,575.599609 
	C935.601257,578.216858 931.444702,576.353027 929.447327,577.306885 
	C926.964417,578.492554 924.233643,577.923340 921.602112,577.963318 
	C920.813110,577.975342 919.826843,578.025574 919.605286,576.963867 
	C919.400757,575.983704 920.213318,575.538208 920.897583,575.089172 
	C922.983643,573.720276 925.342773,574.023010 928.106079,574.000000 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M839.417603,705.002075 
	C838.790039,704.749084 838.436157,704.518982 838.390320,704.238586 
	C837.968140,701.655701 837.022217,698.914001 839.951660,697.089417 
	C840.927124,696.481812 842.059448,695.776428 843.123413,696.878174 
	C845.240295,699.070557 845.307312,701.590637 844.048950,704.627136 
	C842.611389,705.002136 841.222778,705.001892 839.417603,705.002075 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M913.497314,623.555237 
	C914.001343,619.924561 917.617004,618.747864 921.074524,620.785156 
	C922.601807,621.685059 923.961792,622.868896 926.240295,624.542847 
	C921.485107,624.952332 917.591064,625.961243 913.497314,623.555237 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M844.428345,676.001587 
	C841.191895,675.192810 838.383850,674.383118 835.225281,673.472412 
	C840.741882,670.603027 848.223877,671.634277 849.986084,675.594238 
	C848.283936,675.998657 846.570312,675.999695 844.428345,676.001587 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M800.212402,708.883545 
	C798.285645,709.000000 796.571289,709.000000 794.428467,709.000000 
	C793.334656,708.335266 792.631348,707.691650 792.841797,706.314331 
	C792.934814,701.408752 796.737427,703.035645 798.798523,702.849121 
	C801.930481,702.565552 800.641113,705.516785 800.999939,707.603455 
	C800.881287,708.310425 800.689514,708.566162 800.212402,708.883545 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M924.121826,807.993652 
	C922.458618,807.576233 920.165588,809.617188 920.026611,806.483582 
	C919.932861,804.369568 920.123962,802.313538 922.841125,802.092163 
	C924.778625,801.934326 927.109375,801.530579 927.756958,804.185364 
	C928.370728,806.701782 926.979858,807.894043 924.121826,807.993652 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M930.131104,706.389771 
	C931.208496,703.912109 932.918579,703.451233 934.682312,704.629822 
	C936.659668,705.951050 936.148499,708.174194 935.704102,710.074280 
	C935.357849,711.554993 934.014404,712.119324 932.583191,711.864258 
	C929.556580,711.324707 930.050598,708.909973 930.131104,706.389771 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M953.016602,603.000000 
	C950.754395,602.940918 948.931091,603.022705 947.154175,602.773621 
	C945.950134,602.604858 944.975159,601.701233 945.304626,600.332581 
	C945.706055,598.665283 947.122559,598.951538 948.035950,599.607361 
	C950.528564,601.397156 953.041382,600.224915 955.593933,600.079407 
	C957.032654,599.997314 959.500549,599.192810 959.549866,601.571472 
	C959.590698,603.542358 957.302429,602.839600 955.939270,602.983093 
	C955.126343,603.068726 954.297058,602.999573 953.016602,603.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M855.667419,846.197388 
	C857.123962,847.424255 857.773254,848.849915 859.667175,849.277649 
	C861.535522,849.699585 860.938904,851.275818 860.010132,852.360474 
	C859.308533,853.179810 858.053345,854.513611 857.317810,852.952942 
	C855.917847,849.982544 852.779419,849.984924 850.432617,848.215332 
	C851.503357,846.140076 853.315125,845.932556 855.667419,846.197388 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M973.805359,620.451538 
	C974.263184,620.196655 974.422729,620.131775 974.584106,620.126892 
	C977.329651,620.044189 980.101746,620.266235 980.748657,623.546692 
	C981.045044,625.050171 979.494019,626.875122 978.147339,626.489929 
	C975.512817,625.736389 973.133667,624.127686 973.805359,620.451538 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M825.444397,649.002014 
	C823.950317,647.046326 824.045105,645.311951 826.609192,645.148682 
	C829.144897,644.987183 832.487122,643.843201 832.989014,648.605225 
	C830.629517,649.000488 828.258972,649.001038 825.444397,649.002014 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M824.236572,677.824707 
	C823.673462,678.001770 823.331665,678.059875 823.017700,677.990967 
	C821.081543,677.566101 818.611206,679.117798 817.274719,676.557434 
	C816.876831,675.795166 817.161377,674.625793 817.978882,674.463196 
	C820.292114,674.003174 822.763184,673.048706 824.903992,675.624817 
	C824.825195,676.550659 824.648010,677.098816 824.236572,677.824707 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M918.667236,583.731079 
	C921.850708,582.790039 923.647034,585.724792 926.420105,585.593445 
	C923.789124,587.286072 921.262146,589.033691 918.192383,586.974487 
	C916.681396,585.960999 916.923340,584.857727 918.667236,583.731079 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M852.999878,691.666870 
	C852.854736,692.075012 852.709717,692.150513 852.564270,692.225037 
	C850.864746,693.095276 849.821289,695.938110 847.185181,693.991211 
	C845.720703,692.909485 845.543640,691.233948 846.403076,690.080444 
	C848.061768,687.854248 850.243591,689.447388 852.607788,689.947449 
	C852.998291,690.445557 852.999084,690.889648 852.999878,691.666870 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M805.149536,808.661316 
	C803.611084,809.000000 802.222229,809.000000 800.416626,809.000000 
	C800.895813,807.020691 797.603882,802.089539 803.713806,803.779236 
	C806.517212,804.554504 806.227234,806.301086 805.149536,808.661316 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M950.469116,621.462891 
	C951.210754,620.795715 951.754272,620.205933 952.305542,620.198608 
	C953.914429,620.177124 956.263428,619.013000 956.660400,621.646790 
	C956.925476,623.405334 955.304932,624.545959 953.590088,624.849609 
	C951.306274,625.254028 949.607849,624.747192 950.469116,621.462891 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M935.900452,685.000488 
	C934.776489,687.853760 933.028625,688.419250 930.885132,686.703613 
	C929.683411,685.741821 929.682556,684.267639 930.879395,683.303345 
	C933.022888,681.576538 934.768372,682.155151 935.900452,685.000488 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M857.001709,727.432739 
	C858.068420,727.002319 859.134460,727.002136 860.600220,727.002197 
	C861.000000,727.166504 861.025757,727.335327 860.996033,727.493835 
	C860.582886,729.694519 862.177307,733.479980 858.749695,733.478943 
	C855.687439,733.477966 857.530396,729.836304 857.001709,727.432739 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M938.052612,567.731812 
	C938.795288,567.343567 939.256897,567.083862 939.722229,567.077026 
	C941.321899,567.053711 943.268799,566.738525 943.512390,569.023987 
	C943.661926,570.426697 942.338074,570.917297 941.153992,570.920288 
	C939.253540,570.925110 936.898865,571.242065 938.052612,567.731812 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M911.965576,589.639526 
	C913.697632,588.172913 915.362793,586.936890 916.696228,589.322144 
	C917.493042,590.747498 916.436768,592.029541 915.021667,592.352966 
	C913.217346,592.765259 911.217529,592.885803 911.965576,589.639526 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M833.267090,832.059265 
	C833.635803,831.313965 833.866638,830.773376 834.260986,830.595337 
	C835.936707,829.838806 837.771790,829.436768 839.277039,830.717163 
	C839.964966,831.302368 839.231323,832.318604 838.470703,832.645203 
	C836.717163,833.398010 834.826477,835.484680 833.267090,832.059265 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M965.000793,620.998169 
	C963.484985,620.759155 961.475830,621.548584 961.650024,619.619202 
	C961.812134,617.824280 963.793884,618.032288 965.232788,618.025879 
	C966.542786,618.020020 968.277222,618.059753 968.353638,619.599731 
	C968.451172,621.565491 966.515137,620.756653 965.000793,620.998169 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M865.437500,633.002319 
	C864.990845,631.377014 865.330322,629.944824 867.364441,630.057434 
	C869.144531,630.155945 871.554199,629.109558 871.981445,632.605713 
	C869.958313,633.002380 867.916687,633.002319 865.437500,633.002319 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M912.610168,579.355103 
	C914.625610,578.997253 916.531921,578.863647 916.492371,581.199829 
	C916.465759,582.775391 914.834473,583.147461 913.494080,582.846924 
	C911.553711,582.411865 910.445923,581.429504 912.610168,579.355103 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M977.155273,583.763611 
	C977.176636,580.922241 978.408997,580.589661 980.226257,581.889404 
	C981.279968,582.643066 982.445129,583.781738 981.097595,585.081543 
	C979.520752,586.602539 978.161194,585.888733 977.155273,583.763611 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M791.909180,832.631592 
	C789.958313,833.000000 787.916687,833.000000 785.437500,833.000000 
	C786.949158,829.759888 789.325195,830.469910 791.909180,832.631592 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M460.145935,234.713043 
	C459.250000,235.001007 458.500000,235.000809 457.375000,235.000458 
	C455.716492,232.363846 453.000702,233.164062 450.391418,232.127884 
	C451.491058,235.927399 450.513947,236.862000 446.743347,236.182755 
	C442.557556,235.428711 438.268127,234.419540 433.453094,235.000397 
	C430.743134,237.091980 428.268524,236.882095 425.351746,235.344696 
	C424.825806,233.912430 424.307007,232.871597 423.109802,233.229935 
	C417.803986,234.818100 412.728851,232.263931 407.256836,232.756470 
	C401.694702,236.136200 396.209839,235.129532 390.354065,233.352127 
	C389.120270,230.499969 387.588837,228.512756 390.499115,225.639420 
	C393.721741,222.457718 390.008362,222.289581 387.377747,222.000854 
	C386.833252,222.149292 386.666504,222.298599 386.249878,222.223663 
	C385.219238,220.580536 384.661224,219.147903 385.864929,217.307800 
	C387.787933,215.171387 389.963013,215.315491 392.600647,215.890717 
	C400.056519,215.923691 407.101410,217.276001 414.180237,216.063690 
	C415.445618,215.846985 417.509125,215.397888 417.685516,217.652298 
	C417.830414,219.503983 416.047852,219.946716 414.720764,220.551926 
	C414.135284,220.818909 413.445465,220.888321 412.793365,220.971817 
	C411.333679,221.158691 409.409241,221.020630 409.524628,223.096954 
	C409.635284,225.088516 411.533936,224.909760 413.006744,224.993134 
	C417.114258,225.225662 420.762665,227.580597 424.954132,227.516449 
	C426.755676,227.488876 428.559723,227.889343 430.368134,227.966125 
	C431.891968,228.030838 433.901398,227.785431 434.205811,226.345886 
	C434.615601,224.408051 432.349884,223.971100 430.991852,223.212952 
	C430.301392,222.827499 429.369110,222.903290 428.602844,222.618103 
	C427.764069,222.305908 427.191223,221.575775 427.443115,220.681686 
	C427.749878,219.592651 428.718872,218.920090 429.740448,219.503189 
	C432.318665,220.974762 435.146332,220.828003 437.918243,221.228088 
	C439.500916,221.456497 440.807251,222.164200 440.777039,224.521545 
	C440.732239,228.019409 447.212677,231.146088 450.934448,229.340515 
	C453.371704,229.001236 455.741730,229.000656 458.555878,229.000031 
	C460.723358,230.500443 463.164612,231.829910 460.145935,234.713043 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M302.013977,228.176697 
	C308.936127,229.627228 315.591919,228.708328 322.215607,228.995102 
	C323.544220,229.052612 324.881744,228.976181 326.203796,229.091660 
	C327.423767,229.198227 328.587006,229.641541 328.499329,231.146988 
	C328.430725,232.325623 327.363617,233.036575 326.416504,232.851501 
	C319.117859,231.425293 312.216827,235.953674 304.900787,234.218979 
	C304.008270,234.007370 302.836487,234.160126 302.016846,234.579407 
	C292.893433,239.246445 283.445831,236.285416 274.119751,235.801666 
	C272.319519,235.708298 267.165039,228.550262 267.431610,226.882004 
	C267.661591,225.442566 268.822418,225.100540 270.029541,225.058212 
	C272.189941,224.982483 274.373047,224.822723 276.513031,225.035889 
	C282.851562,225.667297 288.926178,224.164566 295.049316,222.998474 
	C299.030182,222.240356 301.299347,223.782959 302.013977,228.176697 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M364.934906,193.969894 
	C359.653015,195.613632 359.048737,197.629822 362.432159,201.190720 
	C363.410675,202.220490 365.315186,202.916931 364.324829,204.724854 
	C363.352478,206.499878 361.449493,206.067017 359.817505,205.761734 
	C357.869873,205.397400 355.860291,205.122070 354.034668,204.407074 
	C351.612061,203.458313 348.451263,202.729965 352.503845,199.281235 
	C354.964081,197.187576 353.402283,194.203766 349.758484,194.112320 
	C346.313324,194.025864 342.852905,194.717453 339.392517,194.978119 
	C338.072418,195.077560 336.672882,195.164383 335.418518,194.838089 
	C333.744965,194.402740 331.308868,194.138718 331.573639,191.773117 
	C331.817230,189.596893 334.073059,190.032120 335.667725,190.019165 
	C341.327209,189.973190 346.987640,189.959442 352.646881,190.017014 
	C354.819794,190.039124 356.704071,189.535828 357.602631,187.431839 
	C358.494568,185.343369 356.741302,184.542465 355.387146,183.570724 
	C353.966766,182.551468 353.738739,181.115829 354.413330,179.584061 
	C354.951630,178.361618 356.065887,177.991058 357.316956,178.114105 
	C359.129700,178.292404 359.707153,179.492874 359.991760,181.158997 
	C361.148712,187.931793 361.213165,187.852524 367.759705,185.702362 
	C372.277618,184.218491 376.997559,185.016495 381.619080,185.197815 
	C383.330566,185.264969 384.854034,186.599564 384.677856,188.526657 
	C384.509888,190.363556 382.719696,190.003571 381.470428,189.973068 
	C375.718597,189.832611 370.306458,190.990616 364.934906,193.969894 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M569.336548,153.341339 
	C568.218018,150.005341 562.966980,152.082520 563.373108,148.561722 
	C563.637756,146.266907 567.455017,146.107880 568.545288,143.745544 
	C569.496155,141.685471 571.066528,140.875458 573.319153,140.940094 
	C574.235107,140.966400 575.630249,140.714828 576.018799,140.095200 
	C579.028809,135.295319 583.112915,136.844391 587.007935,138.205536 
	C589.947510,139.232788 592.897400,138.861542 595.852539,139.009735 
	C597.183533,139.076477 598.661865,138.986160 598.812073,140.849960 
	C598.964050,142.736771 597.576904,142.851349 596.190735,143.026459 
	C593.407959,143.378006 591.412781,145.063110 591.164856,147.719513 
	C590.878418,150.786880 589.255615,151.145264 586.844421,151.008530 
	C583.015442,150.791428 579.225220,150.884796 575.385254,152.949677 
	C573.376709,154.420441 571.599182,154.632736 569.336548,153.341339 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M537.704956,178.673828 
	C538.140259,180.465149 537.695068,181.658661 536.202515,182.730774 
	C534.709045,184.585449 532.851501,184.726746 531.063660,184.707092 
	C525.339783,184.644150 519.593628,185.358292 513.446167,184.143524 
	C506.231750,188.261703 498.554993,188.549591 490.933197,188.970001 
	C488.082794,189.127243 482.244568,183.787201 482.147095,181.141556 
	C482.092834,179.669144 483.012329,179.190948 484.339630,179.026794 
	C487.039276,178.692917 489.588806,179.418411 492.583557,179.987885 
	C493.674774,180.149399 494.240387,180.522293 495.078369,181.031860 
	C496.129364,181.489029 496.880615,181.328827 497.934265,180.931122 
	C500.604156,180.299881 502.984009,180.448395 505.721619,180.635132 
	C509.433807,181.328568 510.517120,177.346893 513.792908,177.000214 
	C518.811890,177.150253 523.449585,177.410416 528.515137,177.457687 
	C530.928528,177.579498 532.925842,177.514648 535.354858,177.655197 
	C536.374207,177.803482 536.914185,178.019180 537.704956,178.673828 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M538.433838,179.001236 
	C537.555542,179.001129 537.111084,179.000427 536.333313,178.999557 
	C533.935120,177.441711 531.731079,177.802505 529.249084,178.763123 
	C523.935242,178.841324 518.816833,179.561295 513.421265,178.014984 
	C511.973755,177.938095 510.928802,178.175644 509.460663,177.405151 
	C509.000000,175.285645 509.000000,173.571289 509.000000,171.428467 
	C510.067352,170.999512 511.134674,170.999039 512.602234,170.997742 
	C518.181580,167.809052 523.213989,170.054337 528.635254,171.703156 
	C534.174744,173.488220 539.256531,175.746658 544.796936,174.952927 
	C549.782410,174.238708 554.213745,176.383682 558.891724,177.218307 
	C560.351685,177.478775 561.822815,178.528183 561.483826,180.325317 
	C561.182007,181.924850 559.598694,182.058731 558.254639,181.969864 
	C553.832275,181.677383 549.317688,181.656754 545.214783,179.745102 
	C543.097168,178.758453 540.987488,179.169388 538.433838,179.001236 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M568.694641,153.280701 
	C570.714355,153.000626 572.428711,153.000610 574.571533,153.000916 
	C575.477173,156.192169 577.974365,156.994324 580.486023,156.906372 
	C585.097534,156.744904 585.374023,160.035721 585.912598,163.109512 
	C586.231079,164.927307 585.551331,165.887863 583.348633,165.318970 
	C580.975952,164.706161 578.535645,164.671585 576.144775,163.708817 
	C570.577881,161.467133 564.713989,160.478409 558.652588,160.978745 
	C556.070435,161.191895 553.946228,160.621490 554.038757,157.326309 
	C554.123413,154.309219 556.150269,154.061432 558.502625,153.974823 
	C561.798035,153.853485 565.129272,154.494431 568.694641,153.280701 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M729.257568,209.736832 
	C723.636108,209.666473 718.272278,209.333847 712.455444,209.001938 
	C710.292786,209.617462 708.622314,210.406311 706.863403,210.802338 
	C704.740051,211.280441 703.288574,210.393051 703.060669,208.052246 
	C702.856750,205.957031 703.800415,204.940048 705.928833,205.008041 
	C708.078186,205.076660 710.197327,204.978607 712.347412,205.570602 
	C717.840576,207.083038 723.514465,207.960464 728.977234,205.883224 
	C733.697510,204.088318 737.358459,206.668671 741.292786,208.047058 
	C744.373169,209.126266 742.483582,210.891296 741.272949,212.177063 
	C739.095947,214.489243 737.541016,216.694916 741.130981,219.071899 
	C742.134033,219.736008 743.373901,220.663605 742.635376,221.816681 
	C741.427612,223.702423 739.039673,223.970154 737.080627,224.706177 
	C735.709106,225.221466 734.954834,224.032852 734.536987,222.905289 
	C733.759521,220.807251 733.838318,218.801758 734.831055,216.668716 
	C737.179443,211.622849 735.071350,208.868195 729.257568,209.736832 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M554.291870,144.162689 
	C551.975708,145.097626 551.823853,146.821274 552.036865,148.480927 
	C552.627319,153.081985 549.887939,153.442627 546.532593,152.882217 
	C538.191040,151.488983 529.836182,150.092087 522.057678,144.764877 
	C526.678772,142.924652 530.774536,145.233353 534.805359,145.381256 
	C537.884766,145.494263 541.042480,145.876678 544.180298,146.010239 
	C547.845093,146.166214 551.032776,146.328064 551.360107,141.019699 
	C551.545715,138.010376 556.112427,137.038696 558.677551,138.921295 
	C559.778564,139.729370 560.153748,140.722031 559.752747,141.941772 
	C558.900269,144.535080 556.580933,143.813095 554.291870,144.162689 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M723.656738,192.714127 
	C725.495422,196.759552 724.113525,198.263138 720.207825,198.025070 
	C717.887085,197.883606 715.546692,198.086792 713.222107,197.981049 
	C709.202942,197.798218 705.475830,197.953278 702.361816,201.260986 
	C700.262512,203.490814 697.161255,204.014648 694.064392,204.016510 
	C691.420776,204.018097 688.819702,204.045914 686.161682,204.675720 
	C682.240906,205.604706 680.401917,203.629913 680.959656,199.351471 
	C682.901489,197.083237 685.345093,197.125412 687.499451,197.904678 
	C690.751526,199.081009 693.968567,198.948227 697.630737,198.834885 
	C701.023010,198.720245 702.124939,195.609512 704.799683,194.260880 
	C707.252869,191.916534 709.696533,192.087357 712.616699,193.691803 
	C715.142029,193.824280 717.340515,194.417801 719.717712,193.270721 
	C720.995117,192.169220 722.089905,191.917755 723.656738,192.714127 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M408.333130,201.074463 
	C405.250702,201.027908 403.144684,201.750427 402.769531,205.024597 
	C402.646210,206.100693 401.917023,207.194046 401.227875,208.099014 
	C400.095032,209.586655 398.581329,210.779343 396.614502,210.579376 
	C395.263306,210.442001 394.079559,209.575607 394.065674,207.974197 
	C394.047546,205.882339 393.312164,203.368088 395.804291,202.211273 
	C398.268494,201.067398 398.963196,199.384415 399.146820,196.768494 
	C399.401276,193.143311 401.826630,191.145432 405.485413,191.047882 
	C409.974731,190.928207 411.370483,193.273712 409.417847,197.338760 
	C409.141510,197.914062 409.142151,198.622391 409.007294,199.634583 
	C409.187164,200.469666 409.061218,200.848389 408.333130,201.074463 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M529.249756,167.192047 
	C527.213074,163.328674 529.621948,163.073196 532.627869,162.999481 
	C536.625061,160.431656 540.465393,161.270523 544.658325,162.641754 
	C549.050659,164.541840 553.357422,164.503357 557.573730,164.959457 
	C559.712646,165.190857 561.900940,164.921432 564.059143,165.031479 
	C565.822449,165.121399 568.357910,164.980209 568.305115,167.308014 
	C568.256836,169.434601 565.802002,169.013626 564.230408,168.968369 
	C559.600830,168.835052 554.807007,168.145294 550.388062,168.429993 
	C543.684937,168.861877 537.370728,167.302567 530.434265,167.000885 
	C529.833191,167.128860 529.666321,167.256485 529.249756,167.192047 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M434.363953,189.733063 
	C430.114502,191.455704 425.455994,190.903748 422.053711,194.597656 
	C420.972015,195.772064 418.126038,195.513855 416.371246,194.432373 
	C414.075867,193.017715 416.360016,191.633011 416.716614,190.216827 
	C417.407074,187.474411 416.191833,186.218277 413.443817,185.959320 
	C411.533264,185.779282 408.783356,185.626907 409.435089,182.881424 
	C410.091888,180.114456 412.950958,180.864929 414.967346,181.222519 
	C417.926575,181.747330 419.920471,184.361588 422.954346,185.109390 
	C425.952942,185.848480 428.696991,187.459930 431.994171,187.093033 
	C433.359711,186.941071 434.628143,187.602295 434.363953,189.733063 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M513.059082,184.382416 
	C518.080872,181.804764 523.410706,183.482941 528.607727,183.030197 
	C530.744080,182.844101 532.910156,182.999344 535.531372,182.999054 
	C539.463257,189.156525 546.153442,186.869705 551.287109,189.124664 
	C546.688293,189.339371 542.743347,191.721497 537.450989,189.765854 
	C531.456482,187.550690 524.550110,187.776642 518.030579,187.024857 
	C516.103027,186.802582 513.918457,187.356735 513.059082,184.382416 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M496.492432,136.693024 
	C494.143616,134.166748 491.828613,135.819122 489.632111,135.980453 
	C487.509308,136.136353 485.162720,135.861404 484.649384,133.650146 
	C484.079895,131.197311 485.467133,128.762619 487.247162,127.079895 
	C488.795197,125.616486 490.016724,127.377258 490.902100,128.455688 
	C494.504028,132.843002 498.963593,134.047806 504.299072,132.380402 
	C505.625977,131.965744 506.965820,131.811096 508.090057,132.778488 
	C509.317230,133.834442 511.173309,134.803558 510.671967,136.691681 
	C510.175140,138.563004 507.828949,139.057541 506.558167,138.685013 
	C503.350769,137.744751 499.821259,138.759201 496.492432,136.693024 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M845.623962,259.308044 
	C843.692688,258.122253 841.044434,257.910126 841.242920,255.361710 
	C841.462158,252.546356 844.142456,253.337601 845.922729,252.788177 
	C846.993225,252.457809 848.022522,251.808914 848.907776,251.100128 
	C851.001099,249.424301 853.388794,249.548950 855.450867,250.551102 
	C857.871521,251.727524 856.962952,254.329849 856.869324,256.388519 
	C856.802795,257.851685 856.312927,259.299042 855.979370,260.745941 
	C855.178223,264.221008 853.218445,264.910339 850.292358,262.747040 
	C848.836548,261.670807 847.399902,260.568665 845.623962,259.308044 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M640.677917,162.724213 
	C642.616699,163.973816 644.398010,164.088104 646.609131,163.998871 
	C649.684692,164.728088 652.409119,165.579910 655.173828,164.729904 
	C656.969421,164.177841 658.113892,164.534348 658.514465,166.326065 
	C658.979980,168.408234 657.426758,169.405609 655.846069,169.781418 
	C653.814880,170.264313 651.666565,170.254623 649.284302,170.728577 
	C646.958313,171.000000 644.916687,171.000000 642.437500,171.000000 
	C640.112427,171.248840 638.379089,170.726089 636.743530,169.863968 
	C634.211182,168.529175 632.064026,166.758072 633.713379,163.615356 
	C635.260132,160.668243 637.721985,160.467148 640.677917,162.724213 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M729.805054,258.275940 
	C734.075378,258.056915 738.039185,258.227325 738.197510,252.555206 
	C738.245117,250.848404 740.102844,249.934708 741.515198,250.258881 
	C745.558777,251.187057 744.847107,255.159851 745.656311,257.952820 
	C746.259949,260.036591 745.029114,262.091675 742.498230,261.903503 
	C739.011353,261.644226 736.034363,263.008453 732.960449,264.160217 
	C731.164490,264.833099 729.519653,265.410370 728.183289,263.328247 
	C726.802917,261.177246 727.319580,259.618225 729.805054,258.275940 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M737.749634,289.732788 
	C743.392822,288.863007 748.750000,288.522369 754.204773,289.887878 
	C756.424866,290.443634 758.775818,289.885681 761.064758,289.248260 
	C762.746155,288.780029 765.263733,288.434998 765.445801,290.918854 
	C765.592896,292.926483 763.682556,294.916656 761.565247,294.509186 
	C754.574585,293.163879 747.728394,294.514343 740.828186,294.991547 
	C737.757385,295.203857 735.866821,294.268982 736.964966,290.347656 
	C737.166504,289.822662 737.332947,289.644135 737.749634,289.732788 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M328.977478,243.587402 
	C323.605957,243.221375 318.220032,246.285736 312.413879,244.015381 
	C310.139069,245.590607 307.891174,244.789963 305.402832,244.999756 
	C304.555542,245.000046 304.111115,245.000107 303.333313,245.000092 
	C302.754242,244.783600 302.538361,244.512115 302.257782,244.359924 
	C300.675751,243.501785 297.303192,244.273621 297.846466,241.696121 
	C298.244965,239.805405 301.152679,237.906509 303.432129,238.291565 
	C311.490082,239.652695 319.436646,241.682358 327.720825,241.253586 
	C328.546478,241.210861 328.867737,242.313599 328.977478,243.587402 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M748.838257,249.312714 
	C746.464417,245.489822 742.985229,247.536636 740.072510,247.019379 
	C738.388489,246.720322 735.682434,247.579514 735.625732,245.164062 
	C735.558105,242.285400 738.423584,243.195572 740.239624,243.017532 
	C741.223572,242.921036 742.288818,243.225464 743.208069,242.964249 
	C750.373230,240.927994 752.339905,247.592361 756.258545,250.848740 
	C757.537964,251.911896 757.249817,253.925385 756.016052,255.385025 
	C755.109375,256.457642 753.983093,257.129211 752.542358,256.888947 
	C750.729553,256.586609 749.535278,255.441940 749.172241,253.683121 
	C748.907959,252.402298 748.997681,251.048416 748.838257,249.312714 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M625.754028,190.258484 
	C627.714355,189.999313 629.428711,189.999237 631.571533,189.998962 
	C634.484741,190.033859 636.972900,190.004272 639.452515,190.135727 
	C640.447449,190.188477 641.488525,190.654694 641.360718,191.919296 
	C641.249878,193.014999 640.199890,192.961395 639.415222,192.962982 
	C629.966919,192.982056 620.518433,192.985428 611.070190,192.946762 
	C609.635925,192.940887 608.419006,192.324066 608.223999,190.727554 
	C608.037903,189.203995 609.026001,188.075470 610.246887,187.600891 
	C612.788635,186.612885 615.285645,186.654007 617.816467,188.171753 
	C620.125244,189.556351 622.328125,191.795975 625.754028,190.258484 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M753.671509,203.688080 
	C751.657959,204.789597 749.883240,205.246780 748.019409,204.937912 
	C746.242676,204.643478 743.933411,204.425980 744.341003,201.913208 
	C744.659607,199.949371 744.852966,197.309662 747.775269,197.089355 
	C749.785278,196.937866 751.770081,196.888260 753.504883,195.696152 
	C755.002258,194.667236 756.401001,193.295029 758.363708,194.945389 
	C759.983826,196.307632 760.146057,198.065231 759.930664,199.938370 
	C759.661804,202.276810 757.789795,202.626984 755.962646,203.010330 
	C755.314636,203.146286 754.679810,203.344772 753.671509,203.688080 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M601.353760,199.263733 
	C599.725647,198.688431 598.416809,198.470428 597.188171,198.029053 
	C596.257324,197.694656 595.344482,197.095581 595.435120,195.885757 
	C595.522583,194.718964 596.492615,194.268219 597.393555,193.843872 
	C600.253723,192.496780 603.848572,191.998993 606.085876,193.477325 
	C611.225037,196.873108 616.659668,195.695435 622.039185,196.001938 
	C625.098022,196.176224 628.225647,195.572800 631.222900,196.452606 
	C631.233459,196.968674 631.244019,197.484726 631.254578,198.000793 
	C624.148804,198.000793 617.043030,198.000793 609.468628,198.001312 
	C606.856689,199.886108 604.501648,200.756775 601.353760,199.263733 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M389.637878,233.009796 
	C395.355743,232.998978 400.710266,232.999191 406.532410,232.999390 
	C407.713501,234.510056 410.756683,234.342010 410.249359,236.137375 
	C409.628723,238.333664 407.080750,237.919205 405.144592,238.000198 
	C400.904755,238.177551 396.660614,237.654892 392.426758,238.808884 
	C390.019165,239.465088 388.496887,237.386795 387.464996,235.514389 
	C386.563141,233.877884 387.660645,233.128632 389.637878,233.009796 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M616.633911,154.952423 
	C619.829651,154.895523 622.602234,154.968384 624.825500,157.686493 
	C624.988708,163.075531 621.470764,163.123978 617.417419,162.999939 
	C615.208130,162.195068 613.142456,162.422379 611.374756,161.453003 
	C609.037842,160.171478 607.234619,158.696289 608.749512,155.331345 
	C611.361938,150.603500 613.848389,155.358124 616.633911,154.952423 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M846.039551,280.661621 
	C843.864136,281.113831 841.769958,280.955872 839.387573,280.053467 
	C835.884521,280.379089 833.086731,279.443939 830.595459,277.695831 
	C828.550537,276.260956 829.154053,274.234375 831.197083,274.305267 
	C836.918274,274.503723 842.969788,271.480225 848.675659,275.852173 
	C849.378479,276.386108 849.524963,276.846161 849.219849,277.690002 
	C847.485474,278.094696 846.420227,278.753479 846.039551,280.661621 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M740.491760,268.998077 
	C738.416443,268.872009 736.251343,269.048859 736.528015,266.661163 
	C736.731812,264.901550 738.454590,264.093933 740.292664,264.021698 
	C744.888672,263.841095 749.538086,264.566040 753.965332,262.329041 
	C755.552368,261.527161 756.837524,263.005005 756.876953,264.745941 
	C756.935547,267.338348 755.352539,268.814880 752.892273,268.924530 
	C748.912415,269.101929 744.919678,268.989624 740.491760,268.998077 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M697.997437,198.603500 
	C694.780640,200.673050 691.769653,202.135361 688.051270,199.547836 
	C686.579712,198.523819 683.970764,199.134293 681.439819,199.000275 
	C679.744263,196.905334 680.374756,195.251160 682.249817,193.959244 
	C685.429504,191.768372 695.287720,193.226608 697.724854,196.300308 
	C698.069214,196.734543 697.917114,197.562500 697.997437,198.603500 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M359.182587,211.700928 
	C353.895752,212.498657 348.985901,213.185089 344.064209,213.772476 
	C342.420929,213.968597 340.743713,214.007675 339.088715,213.943985 
	C337.276489,213.874268 335.551636,212.978882 335.518646,211.141357 
	C335.476532,208.797180 337.659607,209.030258 339.276733,209.019821 
	C345.434814,208.980042 351.593475,208.976212 357.751434,209.024460 
	C359.352386,209.037018 361.570557,208.848816 359.182587,211.700928 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M288.999390,244.999390 
	C283.522003,244.998474 278.044983,244.969833 272.568542,245.013245 
	C270.655914,245.028412 268.870392,244.808914 267.750732,243.060638 
	C266.910126,241.748047 266.985718,239.777817 268.750610,240.330093 
	C274.623016,242.167664 280.256409,239.834259 285.995911,239.977280 
	C289.090851,240.054367 290.383057,241.163300 289.042694,244.634796 
	C288.998779,244.998169 289.000000,245.000000 288.999390,244.999390 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M577.370850,188.002869 
	C573.457947,188.538361 571.117371,184.513245 567.230957,185.746033 
	C566.045776,187.047287 564.897217,187.019226 563.338867,186.297806 
	C562.229614,184.409470 561.965942,183.226639 564.420959,182.982544 
	C570.510620,182.377029 576.648743,181.935349 582.638367,180.766479 
	C586.348999,180.042374 586.047302,181.921829 585.999756,184.596497 
	C583.625061,187.035583 580.414246,186.779388 577.370850,188.002869 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M503.660004,163.656708 
	C506.824951,165.125397 509.915649,166.036636 508.999878,170.592941 
	C505.236694,170.907944 501.444794,171.057220 497.722717,170.616791 
	C495.995483,170.412399 493.377167,170.233948 493.231293,167.614960 
	C493.070831,164.733994 495.937286,165.380234 497.752991,164.259293 
	C499.587524,162.328522 501.408051,162.466568 503.660004,163.656708 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M711.514160,201.904510 
	C712.092346,200.296188 713.192688,199.982178 714.402832,200.030121 
	C718.743103,200.202179 722.946167,199.779510 727.097168,198.265152 
	C728.825378,197.634674 731.565430,197.180771 732.341492,199.507187 
	C733.011047,201.514435 730.358337,201.950729 729.038330,202.489471 
	C723.231812,204.859299 717.335083,205.151520 711.514160,201.904510 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M785.695679,251.696320 
	C787.448059,254.885651 786.127747,256.465729 783.376343,255.772797 
	C778.301331,254.494675 773.232971,255.182709 768.170410,255.049866 
	C765.522339,254.980392 764.991089,253.986633 765.955078,251.370773 
	C768.253113,248.914581 770.697510,249.086823 773.618774,250.694763 
	C775.714355,251.000198 777.428711,251.000397 779.571533,251.000610 
	C781.906311,249.631592 783.706848,249.715958 785.695679,251.696320 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M800.896973,231.946487 
	C800.497620,233.212524 800.155701,234.140305 799.643066,234.961472 
	C798.817505,236.283752 799.032776,238.523605 796.910767,238.720734 
	C794.482727,238.946304 794.880371,236.495743 794.158813,235.224686 
	C792.920105,233.042343 791.080017,231.840652 788.835999,231.078430 
	C787.804443,230.728058 787.009766,230.240540 787.249390,228.996429 
	C787.418335,228.119492 788.361450,227.092377 788.826050,227.438477 
	C792.429260,230.122665 797.970703,227.043167 800.896973,231.946487 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M427.311218,212.977585 
	C428.861145,213.088135 430.034241,213.073013 431.151794,213.314835 
	C431.976959,213.493378 432.616455,214.117996 432.542633,215.104553 
	C432.470856,216.063629 431.781555,216.656311 430.960785,216.769012 
	C428.845184,217.059540 426.564301,217.600449 424.745392,216.079025 
	C422.790894,214.444153 420.785400,213.206100 418.198364,212.806671 
	C416.689789,212.573761 415.243286,211.509781 415.445160,209.806244 
	C415.615112,208.372375 416.638184,206.922028 418.010315,206.368271 
	C419.551453,205.746277 420.943237,206.730453 421.842590,207.985641 
	C423.252228,209.953003 423.884064,212.655533 427.311218,212.977585 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M528.559448,167.000000 
	C529.166687,167.000183 529.333313,167.000366 529.750000,167.000885 
	C529.696655,168.424225 529.393372,169.847229 529.045044,171.633591 
	C523.966370,171.664093 518.932678,171.331223 513.450745,170.997650 
	C513.001648,169.609467 513.000854,168.221970 513.000000,166.418152 
	C514.261719,164.635117 515.713684,164.709991 517.624878,165.719391 
	C521.351501,166.532104 524.675293,167.371338 528.559448,167.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M668.055542,169.994629 
	C670.786377,169.966980 673.096619,170.204391 672.854248,173.278992 
	C672.655457,175.799652 670.832886,177.107147 668.374634,176.935928 
	C665.913574,176.764496 662.786865,178.267059 661.306458,175.006424 
	C660.639648,173.537445 660.584778,171.645340 661.652588,170.229080 
	C663.460144,167.831696 665.641663,170.323975 668.055542,169.994629 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M584.428467,209.001236 
	C583.555542,209.001221 583.111084,209.000610 582.333313,208.999695 
	C581.967102,206.993362 582.417236,205.137894 584.815491,205.108353 
	C589.781921,205.047134 594.793213,204.434692 599.699280,205.491318 
	C601.075500,205.787689 603.687134,205.709946 603.263611,207.990570 
	C602.939697,209.734390 600.730164,208.797180 599.352966,208.980133 
	C598.536682,209.088577 597.694275,209.000290 596.431763,209.000854 
	C594.382690,210.400604 592.638000,210.647476 590.374023,209.291595 
	C588.285645,209.001038 586.571289,209.000839 584.428467,209.001236 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M773.989197,250.599579 
	C771.629456,251.000351 769.258850,251.000702 766.444153,251.001648 
	C765.791931,250.751663 765.424988,250.515976 765.399841,250.248184 
	C765.089600,246.945328 763.422791,242.570480 767.713196,241.305908 
	C772.648193,239.851303 772.789429,244.467041 773.438843,247.810623 
	C773.594421,248.611526 773.797241,249.403244 773.989197,250.599579 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M507.390076,181.889587 
	C503.970001,182.002625 500.939972,182.003311 497.455994,182.004730 
	C496.614288,182.382736 496.153351,182.528427 495.309021,182.222580 
	C494.105835,181.904999 493.338074,181.634186 493.049133,180.315674 
	C492.999176,179.250458 492.999573,178.500290 493.000000,177.375061 
	C498.022095,177.000000 503.044189,177.000000 508.533142,177.000000 
	C510.538940,179.089203 509.389008,180.491806 507.390076,181.889587 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M261.000000,232.119644 
	C260.881714,235.228302 260.867737,237.888199 260.586578,240.519547 
	C260.492767,241.397781 259.952881,242.586639 258.685150,242.426056 
	C257.531311,242.279907 257.099396,241.249680 257.089905,240.216599 
	C257.048767,235.727112 257.010468,231.235321 257.117767,226.747879 
	C257.159698,224.994324 258.339478,223.957703 260.107269,224.149353 
	C262.349884,224.392532 261.279419,225.953247 261.160492,227.177673 
	C261.016235,228.662872 261.045685,230.164948 261.000000,232.119644 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M545.808594,140.191040 
	C545.685486,145.245636 541.973022,144.329575 539.623474,143.809280 
	C536.862244,143.197830 531.865173,144.524246 531.839478,140.820908 
	C531.818481,137.801178 536.765625,138.790390 539.471619,138.162704 
	C541.499634,137.692261 544.207520,137.124649 545.808594,140.191040 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M608.645264,155.046570 
	C608.580566,158.140137 609.907959,159.869843 613.033630,160.586868 
	C614.331848,160.884689 616.127563,160.526138 616.922363,162.645508 
	C615.340210,164.090973 613.739807,165.023163 611.550903,165.080490 
	C608.196411,165.168350 605.357361,164.312988 604.317749,161.070496 
	C603.306580,157.916611 605.772888,156.306732 608.645264,155.046570 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M577.059937,188.384399 
	C578.926819,184.827026 582.067322,184.957169 585.587708,184.998520 
	C587.887085,185.464081 590.628845,185.746674 590.422058,187.998322 
	C590.171509,190.725815 587.235596,191.113235 584.966492,190.915405 
	C582.277466,190.680984 578.868103,192.563202 577.059937,188.384399 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M765.009399,212.959946 
	C768.512512,211.695831 771.494507,211.712479 774.321594,213.695038 
	C774.999023,214.170090 775.775696,214.568893 775.696411,215.543350 
	C775.614258,216.551987 774.843811,216.901718 773.980896,216.930481 
	C770.219055,217.055801 766.453796,216.896652 762.734741,217.845932 
	C761.600830,218.135361 760.283447,217.254395 760.314758,215.936707 
	C760.380127,213.190018 762.701050,213.303909 765.009399,212.959946 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M557.063049,211.015244 
	C555.342834,209.590439 552.981079,208.818069 553.602234,206.395554 
	C553.976685,204.935394 555.661743,205.052841 556.992737,205.006729 
	C559.286499,204.927246 560.783142,205.728287 561.052551,208.242966 
	C561.303162,210.582840 563.033020,210.956528 564.993042,211.049957 
	C566.396667,211.116867 567.899841,211.648346 567.498291,213.325638 
	C567.069458,215.117142 565.249817,215.339676 563.845581,214.728592 
	C561.603699,213.752991 559.533875,212.382065 557.063049,211.015244 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M374.262604,200.999466 
	C372.265137,200.857941 370.200684,201.407898 370.187866,198.866898 
	C370.178467,197.002029 371.802307,196.300354 373.230865,196.208115 
	C376.167175,196.018524 379.142731,196.050461 382.072144,196.318817 
	C383.344025,196.435333 384.576935,197.500885 384.519592,199.004044 
	C384.453674,200.732147 382.959290,200.926346 381.616791,200.963928 
	C379.314789,201.028427 377.010040,200.993500 374.262604,200.999466 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M770.160034,234.951324 
	C772.615845,232.936264 781.273132,233.143356 783.046082,234.856094 
	C783.998169,235.775879 784.105347,236.919739 783.741150,238.072159 
	C783.350769,239.307297 782.102539,240.371094 781.129456,239.826691 
	C777.635742,237.872208 773.411621,237.787979 770.160034,234.951324 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M335.113159,170.050827 
	C333.397491,169.909943 331.708252,169.950272 331.605530,168.305069 
	C331.500397,166.621460 333.237732,167.169479 334.254791,166.952057 
	C336.948120,166.376358 339.314484,164.350998 342.292877,165.073822 
	C343.857269,165.453476 345.870667,165.645325 345.697876,167.848419 
	C345.555969,169.657669 344.086853,170.645676 342.307861,170.913376 
	C339.979828,171.263672 337.722565,170.847946 335.113159,170.050827 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M784.076965,221.000000 
	C786.850769,221.114212 789.181824,221.115067 791.477539,221.400101 
	C792.359009,221.509552 793.546082,222.003494 793.426880,223.273834 
	C793.315918,224.456650 792.282593,224.880753 791.257996,224.902832 
	C787.599426,224.981644 783.938538,225.006393 780.279236,224.974197 
	C779.023804,224.963150 777.318542,225.256561 777.328125,223.262634 
	C777.336121,221.597092 778.710754,221.141891 780.127075,221.048569 
	C781.287415,220.972137 782.455505,221.011810 784.076965,221.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M339.845184,251.351227 
	C343.378571,251.333832 346.757111,251.665817 350.566620,251.997772 
	C352.413757,252.646561 353.034698,253.774933 352.995544,255.651367 
	C352.419250,256.505493 351.814117,256.972717 350.689697,257.265625 
	C349.108734,257.472870 347.898285,257.438202 346.348633,257.273560 
	C345.141174,257.160675 344.273773,257.075928 343.147095,257.626953 
	C342.621124,257.829651 342.402832,257.896912 341.840668,258.013031 
	C340.775635,258.071869 340.105469,257.901428 339.250610,257.247711 
	C339.071167,255.211197 338.720093,253.369797 339.845184,251.351227 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M676.657532,188.507416 
	C675.885620,185.944107 677.247498,185.159088 679.096863,185.064835 
	C681.562073,184.939209 684.038574,185.032959 686.510254,185.041061 
	C688.121887,185.046356 688.929260,185.871658 688.936096,187.477509 
	C688.942871,189.067429 688.180969,190.015930 686.557068,189.939545 
	C683.321960,189.787384 679.939270,190.816879 676.657532,188.507416 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M724.382690,193.052643 
	C722.931580,193.000717 721.865540,193.000229 720.399780,192.999268 
	C720.876770,188.330078 726.379028,186.061340 730.167725,188.838104 
	C731.254272,189.634445 732.177856,190.655563 731.702515,192.153091 
	C731.231934,193.635666 729.948914,194.082794 728.569031,193.941742 
	C727.286804,193.810669 726.033752,193.394638 724.382690,193.052643 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M746.278320,211.263275 
	C747.497009,210.189301 748.637695,209.733261 749.777283,210.187302 
	C752.017334,211.079758 754.091980,212.513092 753.772888,215.197388 
	C753.452087,217.896210 750.952393,216.935486 749.328552,216.932938 
	C747.637329,216.930283 745.456116,217.862381 744.368896,215.804367 
	C743.418396,214.005203 744.810303,212.714630 746.278320,211.263275 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M562.783813,186.280167 
	C564.066711,185.998459 565.133362,185.998764 566.600037,185.998611 
	C568.425476,188.006424 573.529114,187.139679 572.178406,190.527847 
	C570.987183,193.515823 566.804871,192.150543 564.067993,191.696289 
	C561.019958,191.190384 559.749451,189.246902 562.783813,186.280167 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M563.711426,174.925644 
	C560.391541,173.399902 560.591064,171.738678 563.589478,171.212738 
	C566.652527,170.675491 569.878906,171.018799 573.032166,171.072067 
	C574.232361,171.092346 575.466980,171.489532 575.496521,172.968414 
	C575.526489,174.463837 574.296814,174.884506 573.095825,174.918762 
	C570.108459,175.003983 567.117493,174.963837 563.711426,174.925644 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M408.011230,201.396881 
	C408.310486,200.881348 408.566193,200.689621 408.883575,200.212402 
	C411.988495,200.002304 414.977478,199.975830 417.965271,200.021866 
	C419.079590,200.039017 420.486267,199.843063 420.673859,201.465347 
	C420.814117,202.678131 419.697418,203.065170 418.835968,203.530869 
	C414.128754,206.075684 409.755310,205.369370 408.011230,201.396881 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M518.053162,165.674957 
	C516.609375,165.999100 515.221191,166.000046 513.416504,166.001404 
	C512.121826,164.521072 508.967896,165.206543 509.446686,162.628006 
	C509.819977,160.617737 511.940765,160.243210 513.778137,159.252899 
	C515.607422,157.656906 517.100830,158.088928 518.772400,159.645935 
	C519.675598,161.946320 519.767273,163.794769 518.053162,165.674957 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M647.446777,159.000793 
	C644.843140,158.748642 642.154663,159.887756 641.055176,156.371368 
	C645.546570,153.377640 650.022949,156.095596 654.533020,156.202118 
	C655.269531,156.219528 655.709045,156.873596 655.662415,157.579773 
	C655.615784,158.285004 655.110657,158.817566 654.368530,158.847275 
	C652.212402,158.933578 650.053650,158.954071 647.446777,159.000793 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M424.630371,235.006470 
	C427.372223,234.998703 429.742004,234.999237 432.555908,235.000183 
	C435.246613,237.848587 434.988342,239.333145 430.933563,239.026993 
	C428.795227,238.865540 426.634552,239.023346 424.485657,238.969864 
	C423.139282,238.936356 421.587891,238.799850 421.483063,237.091492 
	C421.374603,235.323715 422.893768,235.135406 424.630371,235.006470 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M712.996460,193.587860 
	C710.627808,193.997650 708.257996,193.998337 705.444092,193.999207 
	C703.819824,193.234344 701.829346,193.020004 702.292175,190.995728 
	C702.776428,188.877945 704.689514,188.959686 706.353210,189.021973 
	C709.332642,189.133514 713.193237,187.951065 712.996460,193.587860 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M601.001282,199.390869 
	C603.395264,198.669495 605.790466,198.336548 608.592896,198.002716 
	C609.000000,198.664658 609.146545,199.368927 608.972900,199.982651 
	C608.420776,201.934555 610.522278,205.553146 607.058838,205.520981 
	C604.255676,205.494934 600.674194,204.147934 601.001282,199.390869 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M736.757324,283.202332 
	C739.041687,282.997955 741.083313,282.998352 743.562500,282.998169 
	C745.611572,283.090576 747.777893,282.947571 747.539062,285.211456 
	C747.299500,287.482483 745.346863,287.479828 743.368713,287.106232 
	C741.626648,286.777252 739.754700,286.708374 738.287598,288.776031 
	C737.362244,288.724243 736.911560,288.276306 736.855835,287.298584 
	C736.541016,285.842529 735.562622,284.755188 736.757324,283.202332 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M839.487915,259.390503 
	C841.934937,259.203796 843.971069,259.681702 844.525635,261.943298 
	C844.879333,263.385712 843.322937,264.176575 842.195679,264.860229 
	C840.259216,266.034637 838.128113,266.487671 836.000671,265.616211 
	C834.847290,265.143738 834.968323,263.912262 835.664612,263.085388 
	C836.729370,261.821014 837.984436,260.717010 839.487915,259.390503 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M714.259277,178.610168 
	C715.825012,179.145691 717.423584,179.216354 717.545471,180.870789 
	C717.674133,182.615891 716.030945,182.658691 714.875122,183.199997 
	C712.359436,184.378189 710.112366,184.021164 708.036377,182.283066 
	C707.327515,181.689560 707.065002,180.795090 707.706482,180.127930 
	C709.403442,178.362823 711.439514,177.519409 714.259277,178.610168 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M807.658630,225.759399 
	C806.463928,226.846268 805.348328,227.119003 804.187561,226.947189 
	C802.039734,226.629242 799.241821,225.631348 799.745728,223.538208 
	C800.361572,220.979889 803.520874,220.758987 806.019226,221.083664 
	C808.856567,221.452423 809.689819,222.979813 807.658630,225.759399 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M545.134338,162.673904 
	C541.307068,163.000031 537.614197,162.999466 533.460632,162.998535 
	C533.272217,161.612518 530.904419,160.848221 531.900391,159.567383 
	C532.745056,158.481049 534.437073,158.796539 535.619751,159.173477 
	C538.188721,159.992249 540.783630,160.020294 543.412598,160.019897 
	C544.962524,160.019669 546.098022,160.417709 545.134338,162.673904 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M349.731567,256.307861 
	C350.748169,256.003235 351.498779,256.001587 352.624695,255.999374 
	C353.006104,261.569885 357.247711,259.581818 360.570526,260.000000 
	C361.000000,260.444458 361.000000,260.888885 361.000000,261.666687 
	C359.893127,262.980103 359.050751,264.269928 357.313934,264.940002 
	C353.855865,264.982727 353.000122,260.791840 349.361786,261.004608 
	C347.802216,259.391144 348.353119,257.971069 349.731567,256.307861 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M641.426636,182.000122 
	C640.015869,175.561539 644.682739,177.160248 648.556458,176.999664 
	C649.568176,178.466736 652.076660,179.781143 649.176880,181.754639 
	C648.620911,182.394852 648.163269,182.541901 647.315918,182.224686 
	C645.288452,182.003616 643.572083,182.001923 641.426636,182.000122 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M504.153351,163.651672 
	C502.285645,163.999573 500.571289,163.999741 498.428467,163.999664 
	C497.262054,162.421707 494.871094,160.681442 496.447571,159.231476 
	C498.255188,157.568954 501.141602,158.917404 503.424286,159.281952 
	C505.810608,159.663071 504.905884,161.735703 504.153351,163.651672 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M601.179749,170.794830 
	C600.732666,169.869522 600.509155,169.238373 600.136047,168.713531 
	C599.014404,167.135818 598.244812,165.231140 599.626587,163.784363 
	C600.771057,162.586090 601.907898,164.423645 602.932129,165.027573 
	C604.868652,166.169464 606.328125,167.808899 605.260498,170.254074 
	C604.453430,172.102325 603.027954,172.548828 601.179749,170.794830 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M801.245972,239.024139 
	C801.523438,238.387390 801.659363,238.009140 801.920044,237.876968 
	C804.262390,236.689224 806.798279,236.074524 808.499512,238.566605 
	C809.678284,240.293320 808.143433,241.844086 806.611145,242.479218 
	C803.705078,243.683899 801.894043,242.348145 801.245972,239.024139 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M646.921997,182.360504 
	C647.448242,182.004501 647.891541,182.003693 648.667480,182.002960 
	C649.986084,183.195770 651.366699,182.914322 652.661621,183.016205 
	C653.888245,183.112747 655.694275,182.685608 655.603760,184.649078 
	C655.534363,186.153870 653.954346,185.969711 652.835449,185.980667 
	C649.467651,186.013657 646.099304,185.992554 642.433899,185.992554 
	C643.277161,183.019455 646.306274,184.891785 646.921997,182.360504 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M335.992096,201.233810 
	C337.318878,198.518433 344.251190,198.336487 345.464417,200.777969 
	C346.217865,202.294281 345.125061,202.861023 343.816132,202.940613 
	C341.185150,203.100571 338.536133,203.247787 335.992096,201.233810 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M589.960815,209.347351 
	C591.714355,209.000977 593.428711,209.000717 595.571533,209.000854 
	C596.002258,210.621933 597.746338,212.415695 595.376160,213.800232 
	C593.812378,214.713684 592.430237,214.831085 590.795471,213.914261 
	C588.751343,212.767929 589.409485,211.261841 589.960815,209.347351 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M519.344604,159.960297 
	C517.578918,159.671478 516.157776,159.342346 514.368347,159.006302 
	C511.781158,157.009338 512.181763,155.619980 515.093872,155.390991 
	C516.674194,155.266708 518.339233,155.766251 519.913818,156.182205 
	C520.959290,156.458374 522.516846,156.706650 522.369751,158.120224 
	C522.242126,159.346725 520.785400,159.591751 519.344604,159.960297 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M598.368164,161.211548 
	C596.815796,159.066025 596.738892,156.979340 597.289001,154.861801 
	C597.553833,153.842499 598.375305,153.215668 599.456177,153.186691 
	C600.545349,153.157471 601.754395,153.870483 601.613159,154.749985 
	C601.221313,157.189575 602.328918,160.448257 598.368164,161.211548 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M562.050049,196.859665 
	C562.120422,193.736008 564.028809,193.790024 565.846680,194.200241 
	C567.303894,194.529083 568.679016,195.470459 568.555176,197.189041 
	C568.454285,198.588196 567.257141,199.483063 565.948303,199.784302 
	C563.891968,200.257599 562.427368,199.520187 562.050049,196.859665 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M597.025024,181.698059 
	C597.001770,179.429749 597.067627,177.559143 599.471558,177.156174 
	C600.497314,176.984222 601.617737,177.214310 601.755798,178.428101 
	C602.005188,180.620041 601.535156,182.982941 599.802002,184.205734 
	C597.989014,185.484848 597.362549,183.624451 597.025024,181.698059 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M392.980103,215.598724 
	C391.175446,217.753815 388.896942,216.708054 386.418335,217.000671 
	C384.745758,215.527512 384.367645,213.633606 386.540436,213.311386 
	C388.670654,212.995483 391.824158,211.323639 392.980103,215.598724 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M577.333130,200.074890 
	C576.054932,199.799576 574.905823,199.869049 574.206360,199.341934 
	C572.653687,198.171844 572.682983,196.510071 573.733887,194.981628 
	C574.475037,193.903580 575.385620,192.783066 576.962646,193.665268 
	C578.982056,194.794952 578.870422,196.482742 578.038574,198.655823 
	C578.180359,199.467682 578.069031,199.850647 577.333130,200.074890 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M666.696533,182.334717 
	C667.541504,182.124908 668.034851,182.021088 668.527466,182.024765 
	C669.999329,182.035751 671.691650,181.751846 672.424622,183.456039 
	C672.843811,184.430893 671.921326,184.909058 671.076416,185.079666 
	C669.376038,185.423004 667.915039,187.553970 666.062866,186.221985 
	C664.546753,185.131699 664.912048,183.640411 666.696533,182.334717 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M713.062988,245.385529 
	C716.032898,245.000443 719.063293,244.999680 722.548096,244.999146 
	C721.442383,248.988083 716.717896,249.356552 713.062988,245.385529 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M673.398193,208.999878 
	C673.000305,208.339798 672.916077,207.666229 673.018066,207.022141 
	C673.247070,205.576279 672.242554,203.174713 674.891296,203.333450 
	C676.724609,203.443314 678.420837,205.052170 678.502869,206.880585 
	C678.605957,209.178375 676.397949,208.894867 674.786560,208.994080 
	C674.457703,209.014343 674.126526,208.998795 673.398193,208.999878 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M560.630005,134.919159 
	C559.906616,135.506226 559.315857,135.934616 558.732300,135.924973 
	C556.874146,135.894318 554.635071,136.697708 553.532776,134.431610 
	C553.033813,133.405914 553.910583,132.409119 554.917969,132.298737 
	C557.189636,132.049850 559.939758,130.941483 560.630005,134.919159 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M849.375610,278.049072 
	C849.000000,277.555542 849.000000,277.111115 849.000000,276.333313 
	C850.106506,275.704498 851.197754,275.270874 852.323181,275.146515 
	C853.929749,274.969025 855.635071,274.583649 857.123657,275.499817 
	C857.740784,275.879608 857.593323,276.687592 857.033691,277.174988 
	C855.625061,278.401978 854.032837,279.210358 852.095093,278.833282 
	C851.296387,278.677795 850.531433,278.348450 849.375610,278.049072 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M577.002441,200.390869 
	C577.310181,199.881348 577.565735,199.689301 577.883423,199.212311 
	C579.683716,199.034958 582.025513,196.899582 582.748657,200.149597 
	C583.168945,202.038284 581.673706,203.241898 580.080017,203.728485 
	C577.593872,204.487564 576.920288,202.929810 577.002441,200.390869 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M382.424072,218.545227 
	C380.233246,220.320892 378.121948,221.670624 375.702179,219.584030 
	C375.195770,219.147354 374.936157,218.146805 375.524841,217.853943 
	C377.873383,216.685532 380.304840,216.061905 382.424072,218.545227 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M547.091370,205.442764 
	C547.192810,203.560822 546.390320,201.587524 548.799927,201.178818 
	C549.896973,200.992752 550.698730,201.570374 550.878784,202.710556 
	C551.143616,204.387711 550.943115,206.101364 549.561707,207.155441 
	C548.198364,208.195770 547.632568,206.879684 547.091370,205.442764 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M453.646301,186.958801 
	C454.000000,188.066559 454.000000,189.133133 454.000000,190.599640 
	C452.511078,190.895691 450.873230,192.695587 449.546143,190.724976 
	C448.863220,189.710861 448.851562,188.543564 449.433441,187.463120 
	C450.450684,185.574341 451.934998,186.612030 453.646301,186.958801 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M789.617432,243.114594 
	C791.306213,243.817154 792.356506,244.674774 791.049133,246.122742 
	C789.936096,247.355377 788.357849,247.108429 786.920898,246.749420 
	C786.117188,246.548599 785.327698,245.965851 785.460022,245.049088 
	C785.791321,242.753708 787.650146,243.092346 789.617432,243.114594 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M463.995972,182.594116 
	C462.285431,182.999161 460.572052,182.998947 458.430573,182.998444 
	C457.990845,180.990295 457.834106,179.001358 460.839447,178.986252 
	C463.160370,178.974609 464.154144,179.926010 463.995972,182.594116 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M793.128357,259.703003 
	C791.611755,260.001465 790.223450,260.000488 788.418823,259.999146 
	C787.974731,257.693848 788.621582,255.419891 791.201416,255.495255 
	C792.944031,255.546143 794.382263,257.136688 793.128357,259.703003 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M846.604187,267.178284 
	C848.714050,269.532990 847.271179,270.469177 845.309692,270.880615 
	C844.110535,271.132080 842.290771,271.261627 842.304688,269.460754 
	C842.323669,266.999664 844.381653,267.000641 846.604187,267.178284 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M376.899292,212.398651 
	C375.249573,211.504517 374.934296,210.546844 376.251190,209.600525 
	C377.422546,208.758820 378.755432,208.833481 379.926086,209.719208 
	C380.618958,210.243423 381.072021,211.045105 380.656097,211.877991 
	C379.851501,213.489212 378.489227,212.921600 376.899292,212.398651 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M588.210449,195.864227 
	C589.528748,196.085449 591.021240,195.743698 591.202576,196.771790 
	C591.449158,198.169830 589.958862,197.888077 589.054749,197.978561 
	C588.399353,198.044159 587.727112,198.043243 587.071228,197.979980 
	C585.831726,197.860458 583.868042,198.124100 584.460999,196.122818 
	C585.007019,194.279922 586.646484,195.538223 588.210449,195.864227 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M817.657349,226.403076 
	C817.013000,223.648148 818.821960,224.096390 820.220703,224.103516 
	C820.906311,224.106995 821.662964,224.558807 821.453430,225.265549 
	C820.905334,227.114166 819.453003,227.108093 817.657349,226.403076 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M785.983032,251.611359 
	C784.257812,251.667007 782.515686,251.334610 780.386719,251.001419 
	C782.102905,248.014160 784.106262,247.695236 785.983032,251.611359 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M820.311646,239.571106 
	C820.998108,241.841660 819.793457,242.015427 818.304260,241.763092 
	C817.565796,241.637970 817.148132,241.022690 817.317078,240.346695 
	C817.707764,238.782928 818.899048,239.030228 820.311646,239.571106 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M656.707397,146.793854 
	C655.598450,148.271652 654.595459,147.920563 653.754028,146.928833 
	C653.282959,146.373596 653.386414,145.565186 654.094788,145.310501 
	C655.244263,144.897217 656.385498,144.920059 656.707397,146.793854 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M439.770081,183.219421 
	C440.944641,184.162491 441.152283,185.082169 439.942963,185.645752 
	C438.913483,186.125534 437.867462,185.618225 437.602356,184.555161 
	C437.229645,183.060501 438.468933,183.153000 439.770081,183.219421 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M455.581299,199.159760 
	C457.320435,200.590698 456.230988,201.275589 455.115967,201.704712 
	C454.312775,202.013840 453.388275,201.745956 453.294189,200.674377 
	C453.174500,199.311447 454.198578,199.133484 455.581299,199.159760 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M728.000061,284.864197 
	C727.215332,282.638519 728.631287,283.018097 729.707153,283.212860 
	C730.437988,283.345215 730.822205,283.996674 730.404114,284.701843 
	C729.877686,285.590088 729.058716,285.459534 728.000061,284.864197 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M385.877533,222.288925 
	C386.000000,221.999390 386.500000,221.999557 386.750000,221.999786 
	C387.210358,222.854752 388.221771,223.688477 387.204315,224.568939 
	C386.930481,224.805923 386.217133,224.853897 385.901489,224.663605 
	C384.974792,224.104843 385.449860,223.305771 385.877533,222.288925 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M632.992188,155.660583 
	C632.555542,155.997925 632.111084,155.998276 631.333313,155.998093 
	C631.131714,155.124496 630.701721,153.846252 631.911865,153.627792 
	C633.020081,153.427765 632.833252,154.608047 632.992188,155.660583 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M736.713135,287.043274 
	C737.259338,287.494995 737.518677,287.989990 737.890259,288.741272 
	C738.001770,289.164642 738.001160,289.331696 738.000244,289.749390 
	C737.833313,290.000214 737.666687,290.000458 737.250000,290.000946 
	C736.943481,289.003143 733.887146,288.595551 736.713135,287.043274 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M526.945923,878.366943 
	C527.119019,877.689758 527.310852,877.434143 527.787720,877.116577 
	C529.699036,875.303162 531.517639,876.092957 533.359985,876.699097 
	C534.814087,877.177612 536.105225,878.531555 538.110840,877.429504 
	C539.165771,876.013306 542.251709,876.595825 540.814941,873.627197 
	C539.331543,872.141724 537.461731,871.490112 536.891357,869.326355 
	C537.186768,867.620728 536.435730,865.693787 538.436462,865.240784 
	C540.756409,864.715576 543.129578,865.844849 543.961365,867.726196 
	C546.230713,872.859192 550.297241,873.191284 554.996277,873.049438 
	C561.272583,872.859863 567.559082,872.995667 573.841064,873.017334 
	C575.164246,873.021912 576.617371,872.967834 576.832520,874.813293 
	C577.007141,876.310913 576.105469,876.869873 574.382446,876.994995 
	C572.111816,878.837402 569.708435,878.615417 567.395264,878.653870 
	C562.247070,878.739319 557.094666,878.590027 550.896362,878.779785 
	C556.071350,880.003906 560.514465,878.326660 564.755371,880.716370 
	C564.698975,883.256287 563.691833,885.160645 561.845886,886.461548 
	C559.867065,887.856201 559.044617,889.331299 559.690002,891.931641 
	C560.216736,894.054199 559.057068,895.823242 556.478516,895.972717 
	C551.873474,896.239746 547.318604,896.617615 542.912659,898.270447 
	C540.748657,899.082275 541.136719,897.181702 541.180115,895.918396 
	C541.335815,891.385681 540.455627,890.700195 536.483398,892.468628 
	C534.860413,893.191101 533.220215,892.973206 531.580200,892.964905 
	C529.618164,892.954956 527.481445,892.271851 527.695923,890.121338 
	C527.886230,888.212280 530.175720,889.295654 531.523193,889.005188 
	C531.682312,888.970886 531.855164,889.000427 532.021667,889.000366 
	C534.505615,888.999573 537.504944,889.499023 538.434509,886.506104 
	C539.222229,883.969788 536.066406,883.728821 534.774780,882.404480 
	C532.726807,880.305115 530.328857,880.458862 528.118469,882.412903 
	C527.495911,882.963440 526.563171,883.488770 526.395325,884.177307 
	C524.833069,890.587280 518.210754,892.206909 514.608337,896.706360 
	C512.580994,899.238647 508.793793,900.083069 507.973175,904.046387 
	C507.687683,905.425171 505.330200,905.002014 503.861023,904.982361 
	C500.857208,904.942261 497.853516,904.873108 495.039307,906.142517 
	C493.655518,906.766785 492.110352,907.590698 492.478271,909.362122 
	C492.799316,910.907776 494.173706,911.813782 495.811066,911.919678 
	C497.469482,912.026978 499.144806,911.890564 500.800446,912.019043 
	C504.369659,912.296082 508.036621,911.919434 509.385864,916.861145 
	C510.157471,919.687134 514.681152,919.248779 517.223755,916.043823 
	C518.612488,914.293335 519.863464,912.548096 522.149475,912.168884 
	C525.480957,911.616150 528.177979,911.956787 528.045166,916.479919 
	C527.938660,920.109497 528.856567,923.192139 533.530396,923.039856 
	C534.876282,922.995972 536.508484,923.218445 536.475342,924.956482 
	C536.435974,927.021790 534.583130,926.970825 533.104919,926.976318 
	C523.948730,927.010193 514.781250,927.268494 505.640228,926.895935 
	C500.345337,926.680176 495.373352,929.792175 489.714294,927.347473 
	C487.219635,926.269775 485.600586,930.083069 485.093933,932.769714 
	C484.715881,934.774292 484.657257,937.563293 481.964264,936.583923 
	C479.318237,935.621582 474.728729,937.894043 474.164734,933.160522 
	C473.690491,929.179993 471.387604,928.185242 468.131134,928.025757 
	C466.182526,927.930359 464.881775,927.442932 465.074188,925.153809 
	C465.254425,923.010010 466.817108,922.894226 468.333282,923.039124 
	C471.223206,923.315369 473.446533,922.354736 475.397766,920.202942 
	C477.493530,917.891907 480.485443,918.248779 483.277252,917.826111 
	C485.359070,917.511047 488.685211,917.595093 488.634857,914.557190 
	C488.598572,912.370728 486.037567,911.416443 483.834290,910.978149 
	C480.825592,910.379578 478.242767,909.208679 476.272034,906.625671 
	C473.806824,903.394592 470.052185,903.740173 466.635834,904.169861 
	C464.378326,904.453735 462.463013,904.758057 462.297913,901.946045 
	C462.151184,899.446899 464.415710,899.082947 466.176117,899.085815 
	C472.321808,899.095947 478.779480,899.767273 484.134125,896.520203 
	C487.263885,894.622253 489.018829,895.312622 491.666962,896.961914 
	C495.385529,896.903992 498.512115,897.784851 501.706848,899.745850 
	C502.943604,899.720886 503.888275,899.432617 504.841370,899.175842 
	C505.965973,898.872925 507.298523,898.669067 507.557709,897.350769 
	C507.863922,895.793518 506.490509,895.109802 505.478821,894.270935 
	C503.790863,892.871399 499.741791,892.786621 500.536499,890.356934 
	C501.570831,887.194397 504.778900,890.022461 507.036926,889.940247 
	C508.503693,889.886841 509.964935,889.682190 511.714325,889.272644 
	C512.898560,887.848328 514.260681,887.769592 515.529602,887.473022 
	C517.849548,886.931091 517.669067,885.373108 517.039673,883.323914 
	C516.713196,882.423218 516.569458,881.546692 516.112976,881.330566 
	C514.701294,880.662048 513.689270,880.067993 515.095032,878.462646 
	C516.346069,877.033813 517.535828,877.060303 518.889282,878.385071 
	C521.421204,880.863342 524.032288,883.100281 526.945923,878.366943 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M353.000824,892.399658 
	C355.289764,891.641907 356.837402,890.821716 357.052887,888.038269 
	C357.604523,880.911438 359.657318,878.940674 366.869904,877.971619 
	C367.732697,877.855652 368.439484,877.589905 369.206360,876.100098 
	C372.076202,874.520752 374.378967,875.502563 377.083862,876.258545 
	C379.549438,877.854797 381.880402,877.759460 384.175232,877.219299 
	C385.477631,876.912720 386.758484,876.560303 388.092346,876.578308 
	C390.528442,876.611206 393.116425,876.969055 393.530212,879.832336 
	C394.014221,883.181274 391.049194,883.199280 388.834076,883.682861 
	C382.588409,885.046204 376.241791,884.502991 369.930145,884.650574 
	C367.890839,884.698120 365.683350,884.301880 363.852753,885.990417 
	C365.261932,887.856873 367.059174,887.363647 368.590759,887.329468 
	C376.929260,887.143250 385.221222,887.580200 393.457214,888.904114 
	C396.241669,889.351624 398.397491,889.098816 398.860718,885.618347 
	C399.169983,883.294617 400.771912,881.884033 402.885071,880.910583 
	C409.076202,878.058716 409.776947,871.623718 404.116089,866.382202 
	C402.146606,865.574768 400.611023,864.839172 400.800415,862.375793 
	C401.549408,861.823120 402.097595,861.646179 402.822876,861.234619 
	C403.788696,860.269592 404.692810,859.782959 406.164917,859.671143 
	C407.048462,859.674988 407.544403,859.721558 408.383057,859.890259 
	C411.469788,860.841675 413.648438,862.988464 416.890991,863.442688 
	C418.094543,863.544739 418.752930,863.101440 419.583282,862.283325 
	C420.175934,861.769531 420.555847,861.519226 421.290741,861.161499 
	C422.863770,860.678040 424.089905,860.704224 425.637878,861.324585 
	C426.807709,862.229797 427.035156,863.191406 426.998230,864.603760 
	C426.874512,865.195557 426.795715,865.422485 426.522644,865.946289 
	C424.955048,867.411682 423.079956,867.752380 421.469818,869.173279 
	C420.957825,869.363892 420.737610,869.392700 420.259277,869.210754 
	C419.001831,869.015625 417.999329,868.986755 417.003632,869.055725 
	C414.644287,869.219055 413.952667,870.720398 414.046539,872.833008 
	C414.136047,874.847229 415.213409,875.901550 417.188934,875.951538 
	C419.853455,876.019043 422.520721,875.972961 425.185791,876.026184 
	C426.347443,876.049377 427.701202,876.043518 427.692627,877.706604 
	C427.685425,879.107544 426.648804,879.748718 425.393555,879.876587 
	C424.072479,880.010986 422.733398,879.977539 421.401855,879.997925 
	C417.831757,880.052612 413.871826,879.065186 413.587616,884.909302 
	C413.504913,886.609436 410.895996,887.854858 409.004944,888.394531 
	C406.082977,889.228210 403.728394,890.971008 402.346161,893.346069 
	C399.864685,897.609924 396.351685,897.403625 392.436493,896.646973 
	C391.302002,896.427673 390.153076,896.046387 389.024658,896.090881 
	C387.557892,896.148804 385.675598,896.410217 385.620789,898.224426 
	C385.569305,899.928650 387.344391,899.908081 388.668945,899.988586 
	C390.691589,900.111450 393.284943,900.062500 392.626953,902.984558 
	C391.980896,905.853516 388.465912,905.740234 387.282562,904.689209 
	C383.364655,901.209351 378.162354,901.180542 373.908630,898.819641 
	C372.973114,898.300415 372.335724,897.649109 372.095490,896.598633 
	C371.108429,892.281860 368.517029,892.632080 365.889404,894.810852 
	C363.345551,896.920288 359.514893,898.122559 359.799561,902.628906 
	C359.894714,904.135071 358.485016,904.901184 357.017670,904.934753 
	C354.521759,904.991882 352.018127,905.009338 349.526398,904.878601 
	C346.089294,904.698303 346.460083,901.705261 345.495911,899.512573 
	C343.924225,895.938293 340.416138,897.077881 337.542053,897.055115 
	C334.013855,897.027039 336.060760,899.538757 335.935028,901.005981 
	C335.758148,903.070435 334.638031,904.064575 332.658112,903.873901 
	C331.018036,903.715759 329.409943,903.244385 327.780090,902.954407 
	C326.751343,902.771423 325.703918,902.477234 325.538849,901.341492 
	C325.355530,900.079834 326.538940,899.733948 327.429565,899.385376 
	C329.012177,898.765808 331.513885,899.043884 330.811890,896.211426 
	C330.194519,893.720459 329.012939,891.666077 326.100342,890.995422 
	C320.512268,889.708679 314.861877,890.077759 309.220459,889.975159 
	C307.526764,889.944336 305.114746,890.602966 305.180725,887.703918 
	C305.236511,885.253357 307.235779,884.867126 309.170715,885.033875 
	C313.717987,885.425720 318.354797,884.001404 322.833466,886.584534 
	C325.190491,887.944031 328.121399,887.241394 329.741425,884.419373 
	C330.647766,882.840515 331.753876,881.728821 333.728333,882.179199 
	C335.842834,882.661560 336.003723,884.354614 335.996246,886.084167 
	C335.979706,889.922363 338.645142,890.958252 341.643311,890.905457 
	C344.837341,890.849243 347.116425,891.932739 348.749725,894.570374 
	C349.455597,895.710144 350.134674,897.178345 351.718994,896.684814 
	C353.664795,896.078796 352.776337,894.177063 353.000824,892.399658 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M535.300781,911.677490 
	C534.334900,911.293640 533.424438,911.274231 533.117554,910.834045 
	C528.832458,904.688171 521.989014,906.127808 516.027405,905.030945 
	C514.371826,904.726379 512.305725,904.912354 512.356384,902.650452 
	C512.406616,900.409912 514.521301,901.052124 515.871765,901.032166 
	C521.037109,900.955688 526.205383,901.063232 531.370361,900.974915 
	C533.847168,900.932556 535.864685,901.813782 537.825989,903.216675 
	C541.443726,905.804138 544.553467,905.717346 546.869324,901.359436 
	C548.441833,898.400391 549.722351,898.661133 551.116089,901.734436 
	C554.066467,908.240723 564.280701,909.382385 568.992493,903.871582 
	C571.980896,900.376343 571.308716,897.158630 566.556824,895.467407 
	C562.566589,894.047180 562.848938,892.186218 564.376587,889.099854 
	C565.990906,885.838501 567.498108,886.052368 570.612366,887.531128 
	C574.272888,889.269104 575.180176,891.402954 575.083740,895.156860 
	C574.844238,904.478821 575.198181,904.511597 584.418762,906.199707 
	C586.597412,906.598572 590.609558,906.072998 590.486511,909.151184 
	C590.359924,912.315979 586.567200,912.109009 584.076050,911.923706 
	C579.149475,911.557373 575.273438,914.822998 570.655579,915.375366 
	C570.370056,915.409424 570.086304,915.710449 569.859314,915.943420 
	C564.662903,921.273987 561.817139,923.147339 555.575439,925.055115 
	C554.117371,925.500732 553.097961,926.248779 553.092896,927.751099 
	C553.083740,930.464905 551.668091,931.740112 549.150269,931.893860 
	C547.399963,932.000732 545.450500,931.367065 544.822754,929.904236 
	C543.879944,927.706909 544.741638,925.325134 546.200439,923.475464 
	C546.968079,922.502136 548.306152,921.978577 549.386597,921.251953 
	C549.236328,920.911682 549.104431,920.387939 548.992554,920.392212 
	C543.375916,920.605530 538.287048,920.065186 537.202820,912.989685 
	C537.133789,912.539185 536.179443,912.224304 535.300781,911.677490 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M720.703552,878.681274 
	C721.727173,881.803467 723.530029,881.209656 725.196655,880.157654 
	C726.541382,879.308838 727.867249,878.846375 729.418945,879.096069 
	C731.197327,879.382324 732.043274,880.520996 731.889099,882.248596 
	C731.730652,884.023499 730.854675,884.781555 728.858826,884.474915 
	C722.170288,883.447205 715.460571,882.437805 708.696045,883.990173 
	C707.254150,884.321106 705.771729,884.472900 704.326050,884.789917 
	C702.887146,885.105591 701.756653,885.854431 702.240295,887.541443 
	C702.752197,889.327026 704.221375,888.648376 705.526794,888.717957 
	C715.741821,889.262085 726.095947,886.481934 736.196533,890.329102 
	C740.457153,891.951904 744.639343,889.289673 746.045105,884.611572 
	C746.554504,882.916199 747.453552,882.012939 749.211731,882.116150 
	C751.026489,882.222656 751.919373,883.372314 751.940491,885.065552 
	C752.004700,890.220276 751.982361,895.376282 751.970398,900.531738 
	C751.966614,902.168701 751.424194,903.514099 749.612427,903.845581 
	C747.639465,904.206726 746.158875,903.197632 745.821533,901.430115 
	C745.050903,897.392090 742.299988,897.061768 738.946350,895.552612 
	C731.804016,892.338501 726.877808,895.105713 721.168823,898.205994 
	C718.366211,899.728149 714.774841,900.203308 712.162903,896.895752 
	C711.493408,896.047974 710.475159,895.907349 709.234009,896.744751 
	C706.617065,898.888733 704.547485,897.852905 702.921875,896.009705 
	C701.168823,894.021851 698.960571,893.140076 696.281616,892.232666 
	C692.554871,891.714172 689.013245,892.688782 685.653442,891.275330 
	C684.403442,890.749512 683.089905,890.061096 683.258240,888.465637 
	C683.423218,886.901306 684.929260,887.276489 685.984863,886.933044 
	C689.609436,885.753540 693.186951,884.525024 697.112732,884.950439 
	C698.493347,885.100098 699.905457,884.746704 699.793213,882.871521 
	C699.681213,880.998840 698.177002,880.968567 696.849182,881.032410 
	C693.175537,881.208984 689.580505,880.733704 686.004028,879.973328 
	C684.189514,879.587585 682.221252,879.312195 682.139465,876.901184 
	C682.067017,874.767151 684.041809,874.750549 685.320740,873.821838 
	C688.629028,871.419006 691.791382,871.449158 695.626709,872.992615 
	C698.721069,871.023193 701.017578,872.264587 702.319458,874.561951 
	C703.965698,877.466980 706.336121,877.481995 709.059998,877.586548 
	C712.845093,877.731934 716.717285,876.810547 720.703552,878.681274 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M703.750000,904.871094 
	C705.737488,907.287476 706.804749,909.331909 702.700256,910.271973 
	C701.259521,910.601929 699.955688,911.437683 699.062561,912.761108 
	C698.083313,914.212036 696.772095,914.156006 695.683411,913.053040 
	C692.322388,909.648315 688.111938,910.873901 684.322937,911.253601 
	C680.102417,911.676514 678.763000,915.056580 679.019592,918.980286 
	C679.292419,923.152100 679.367981,927.120422 673.940918,928.072144 
	C673.788086,928.098938 673.592224,928.200500 673.521057,928.326355 
	C671.411560,932.058044 669.393433,935.664978 664.383911,936.385498 
	C662.740417,936.621948 663.142212,939.220459 662.834412,940.823975 
	C662.672058,941.670227 662.174194,942.382935 661.262573,942.553467 
	C660.299438,942.733643 659.519348,942.159241 659.344116,941.336121 
	C657.912354,934.607422 652.812256,934.767273 647.646484,934.996704 
	C647.147766,935.018860 646.644287,935.028198 646.147888,934.986267 
	C644.872925,934.878601 643.182678,935.165710 643.337341,933.132935 
	C643.462524,931.486145 644.853455,931.061890 646.263733,931.044006 
	C650.261230,930.993164 654.260925,930.939941 658.256958,931.023132 
	C660.912231,931.078369 663.133667,930.272461 665.049438,928.447388 
	C666.794373,926.785156 667.689453,925.018738 666.168030,922.736633 
	C663.735779,919.088318 665.564697,917.544067 669.104187,917.063538 
	C671.617920,916.722229 674.856384,917.713440 674.959412,913.540527 
	C675.057678,909.556335 675.624451,905.352173 670.722290,903.389526 
	C669.357056,902.843079 666.839355,903.394287 667.227295,900.814636 
	C667.637817,898.084167 670.078247,899.066956 671.701599,899.057129 
	C674.144958,899.042297 675.779663,898.090454 677.019348,896.034424 
	C677.694153,894.915161 678.615356,893.894775 679.595947,893.021240 
	C680.989624,891.780029 682.726685,891.473145 684.075867,892.905334 
	C685.636475,894.562073 683.778320,895.522278 682.889404,896.589355 
	C682.358459,897.226746 681.703186,897.758789 681.138733,898.370422 
	C678.305969,901.439514 678.701782,905.200378 682.634399,906.078186 
	C688.047241,907.286377 693.735352,907.359314 699.319397,907.617493 
	C700.395569,907.667236 701.535828,906.329651 702.823242,905.316040 
	C703.166687,904.914062 703.333313,904.828125 703.750000,904.871094 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M638.463989,920.029907 
	C634.422913,921.681335 636.340332,923.530945 637.854980,925.342773 
	C638.875122,926.563293 640.642944,927.575256 638.927673,929.486938 
	C637.519653,931.056213 635.874451,931.377014 634.006897,930.353088 
	C633.716675,930.194031 633.418579,929.781555 633.185730,929.824036 
	C621.987244,931.865906 623.010803,920.279663 617.741882,915.708801 
	C615.695740,913.933716 617.156250,911.200562 620.351257,911.064392 
	C624.835022,910.873413 629.323730,911.505676 633.523132,912.922852 
	C636.421997,913.901062 638.860901,914.180176 641.606018,912.764282 
	C644.290466,911.379456 647.210205,910.712708 650.282715,911.007812 
	C653.550415,911.321716 656.217407,912.260254 656.008240,916.323547 
	C655.859131,919.219177 657.715637,920.806885 659.830566,922.292908 
	C660.969238,923.092957 663.108276,923.503662 662.486755,925.382385 
	C661.863892,927.265442 659.720703,928.232727 657.999023,927.817261 
	C653.550537,926.743835 649.086243,927.129944 644.624939,926.974548 
	C643.798828,926.945862 642.928467,926.929749 642.464600,926.072021 
	C642.003601,925.219421 642.387329,924.535583 642.915710,923.820862 
	C643.697510,922.763245 646.527466,923.160034 645.444153,920.960632 
	C644.574402,919.194885 642.440613,920.222107 640.870850,920.014893 
	C640.217529,919.928711 639.542603,920.006531 638.463989,920.029907 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M417.617859,896.694702 
	C420.023285,896.908264 422.576019,896.803955 422.762878,894.433777 
	C423.190399,889.011414 426.246765,889.844482 429.698853,890.919983 
	C430.156799,891.062683 430.685944,890.976929 431.591003,890.998474 
	C434.839813,889.737915 437.724854,890.457153 440.955261,890.919556 
	C442.434723,891.505310 443.125061,892.424194 443.655518,893.449463 
	C445.196960,896.428711 447.263275,896.333069 449.988464,894.409912 
	C451.058228,894.110291 451.772675,894.170959 452.729431,894.747498 
	C453.044830,898.709534 451.340179,902.746643 456.203735,905.261108 
	C457.695557,906.032410 457.853516,909.526123 455.941925,910.416504 
	C453.853516,911.389038 450.875336,912.320251 448.960999,909.228882 
	C444.462799,901.964966 440.863159,901.198181 432.559875,904.036072 
	C428.007019,905.592102 422.939301,906.540894 418.098633,904.808105 
	C415.510010,903.881409 412.818359,904.407532 410.290771,903.589478 
	C408.031342,902.858154 405.782867,902.093079 402.971527,901.157104 
	C405.475067,899.314026 408.722870,900.543701 409.929199,897.357422 
	C412.253632,894.915710 414.697357,895.086914 417.617859,896.694702 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M359.622284,848.736511 
	C367.050842,848.651733 373.988037,850.701599 381.051910,850.004150 
	C384.379425,849.675598 383.717499,852.660156 384.843689,854.646362 
	C385.681122,855.782166 386.780548,856.042847 387.531372,857.295654 
	C387.869385,859.171936 387.337128,860.438477 386.114258,861.897095 
	C380.484650,864.493774 375.122345,864.418152 369.359589,862.288940 
	C367.949188,860.375000 366.610779,859.015320 368.344177,856.772034 
	C369.582214,855.169800 369.669281,852.645508 367.521820,851.581421 
	C365.275085,850.468018 362.670197,850.277588 361.015747,853.035645 
	C360.266174,854.285156 359.758636,855.615295 357.977173,855.992554 
	C353.209167,857.002197 352.320282,855.678040 353.991882,849.383240 
	C355.638977,847.746460 357.413940,847.837524 359.622284,848.736511 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M604.943970,930.254639 
	C605.812439,933.194641 607.918518,932.992004 609.914856,932.997742 
	C613.726257,933.008667 617.537781,932.999207 621.349121,933.020020 
	C622.926331,933.028564 624.458130,933.328857 624.747437,935.191406 
	C625.019043,936.940247 623.809448,938.299927 622.393127,938.549744 
	C618.192139,939.290588 614.011963,940.498535 609.656006,939.928345 
	C608.413635,939.765686 607.409363,939.124084 606.753113,938.182251 
	C604.974915,935.630005 603.601501,933.000305 599.483582,934.684692 
	C597.838074,935.357727 596.355469,933.117554 595.459839,931.542664 
	C594.582764,930.000122 595.487366,928.589417 596.557312,927.538269 
	C597.930054,926.189514 599.638977,925.672241 601.583496,926.365601 
	C603.293945,926.975586 604.257141,928.235657 604.943970,930.254639 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M525.908081,961.991333 
	C523.316589,962.015869 521.179382,962.087952 519.123291,961.203125 
	C517.923767,960.687012 516.343872,960.566589 516.409668,958.834961 
	C516.462830,957.436768 517.246887,956.214233 518.734314,955.987305 
	C524.960632,955.037476 531.159302,952.168335 537.483765,955.708740 
	C538.606750,956.337402 539.926453,956.750427 541.203003,956.937317 
	C543.115601,957.217224 545.247559,957.454651 544.682129,959.964783 
	C544.069702,962.683350 541.407776,963.936340 539.272888,962.702515 
	C534.911499,960.181885 530.708557,961.126648 525.908081,961.991333 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M437.657227,864.728394 
	C439.338654,867.484863 438.513275,868.840271 435.781219,868.965027 
	C433.534454,869.067688 432.004700,870.217346 432.506378,872.320068 
	C433.032532,874.525208 435.018646,873.823669 436.668701,873.288940 
	C440.670715,871.992249 443.824799,874.410400 446.813202,876.227661 
	C449.026520,877.573669 448.251282,880.265747 445.860596,880.662964 
	C442.295898,881.255127 438.569122,880.917786 434.913025,880.862732 
	C434.381256,880.854675 433.423248,880.247009 433.399231,879.869934 
	C433.146454,875.901184 429.537201,875.434143 427.075012,873.352539 
	C427.281097,871.271912 427.512115,869.560486 425.165833,868.465332 
	C424.587219,867.478699 424.799255,866.885071 425.713989,866.204529 
	C427.498352,864.516663 429.469391,864.186157 431.836060,863.717590 
	C434.014252,863.492859 435.762543,863.357361 437.657227,864.728394 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M484.146606,887.417847 
	C482.544678,882.868164 478.519196,884.032593 475.595703,883.107666 
	C473.594604,882.474487 471.328613,882.167664 471.569702,879.728577 
	C471.779114,877.609985 474.223541,878.482300 475.652893,877.932495 
	C480.353790,876.124329 483.236359,879.400330 486.792114,881.229309 
	C488.732483,879.192993 490.916870,879.462158 493.476624,879.762451 
	C494.889740,880.175415 495.728119,880.765076 496.687988,882.312866 
	C498.205627,886.828552 497.695831,889.455017 492.395721,889.001709 
	C489.074066,890.961792 486.479706,890.741699 484.146606,887.417847 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M522.618042,946.613647 
	C519.860474,942.919556 521.109802,941.139526 525.042236,941.040039 
	C530.367981,940.905396 535.699768,941.000549 541.028931,941.008728 
	C542.776489,941.011353 544.677124,941.218872 544.752930,943.383484 
	C544.826721,945.488403 543.063232,946.197083 541.269714,947.014771 
	C535.080811,949.836670 529.057800,948.098083 522.618042,946.613647 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M593.278198,919.158997 
	C592.978638,913.149902 595.369385,911.339111 600.549194,913.470642 
	C604.652832,915.159180 608.611450,917.127686 613.214783,917.097351 
	C614.823547,917.086731 616.495850,918.146301 616.383911,919.660767 
	C616.224609,921.815491 614.064819,920.807617 612.745972,920.968384 
	C611.071228,921.172424 609.494507,920.848022 608.086548,919.730225 
	C606.875793,918.769104 605.350403,918.872253 604.546387,920.099121 
	C600.442932,926.360901 596.974365,922.604797 593.278198,919.158997 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M336.779236,863.309692 
	C336.922516,862.007874 336.696838,860.951721 335.770996,860.532104 
	C331.880707,858.768921 334.080536,857.240051 335.940613,855.331909 
	C335.925171,854.372559 336.110138,853.819458 336.748779,853.162659 
	C338.289001,852.778564 339.594543,852.298950 340.858002,852.391907 
	C343.169769,852.561890 345.316956,853.486450 347.290924,854.697693 
	C350.383240,856.594910 347.071930,858.370789 347.115204,860.221924 
	C347.145844,861.534058 347.043182,862.849243 347.000000,864.580322 
	C345.085449,866.908386 342.969452,866.739807 340.379791,865.301025 
	C339.250305,865.000000 338.500610,865.000000 337.376068,865.000000 
	C336.622192,864.614014 336.474609,864.153809 336.779236,863.309692 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M368.939758,862.329590 
	C374.406677,861.651917 379.872162,862.740112 385.617157,861.166260 
	C388.612640,859.672913 390.235962,860.861511 390.760468,863.325623 
	C391.357117,866.128906 389.304047,867.406555 386.995270,867.332031 
	C383.105164,867.206482 380.100159,868.642517 377.084412,870.937561 
	C373.765015,873.463684 370.568451,872.312622 367.444336,868.713379 
	C365.573730,865.497314 367.216553,864.074768 368.939758,862.329590 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M721.342651,878.960510 
	C717.890442,879.001587 714.780396,879.034241 711.671387,878.991943 
	C707.244385,878.931824 702.272339,880.276245 700.309998,874.137573 
	C699.892151,872.830322 698.093140,873.008911 696.388550,873.001038 
	C695.393921,869.955017 696.735840,867.674438 698.931824,865.351135 
	C699.962219,863.995911 701.092041,863.948669 702.656311,864.711060 
	C703.036865,866.738098 704.146545,867.772949 705.709839,868.852539 
	C706.729065,869.788757 707.119507,870.717712 707.198486,872.153076 
	C707.269287,873.444885 706.698547,874.869141 708.105347,874.987549 
	C713.120605,875.409607 718.397766,877.115234 722.980347,872.473694 
	C724.361267,871.894348 725.392822,871.927917 726.698669,872.718201 
	C726.346558,875.980408 724.251587,877.661011 721.342651,878.960510 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M340.007324,865.374634 
	C342.041656,864.999695 344.083344,864.999390 346.562500,864.998291 
	C347.215363,865.774292 347.864410,866.976807 347.581787,867.264709 
	C342.360535,872.583618 347.918976,874.435120 350.788727,876.798035 
	C353.895355,879.356018 353.038971,880.342957 349.361694,880.993408 
	C347.951477,879.829529 346.895447,878.716248 345.227600,880.767822 
	C341.431915,880.675781 336.362762,882.677551 336.999329,875.418579 
	C336.384796,874.249023 336.545105,873.578247 337.605042,872.979858 
	C339.535156,872.775879 341.528809,873.731750 342.500458,872.103577 
	C343.595520,870.268555 341.418060,869.362793 340.709900,868.023560 
	C340.346008,867.335266 340.238190,866.511658 340.007324,865.374634 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M336.997345,852.652466 
	C336.824921,853.548828 336.647400,854.097656 336.235535,854.824463 
	C332.866425,854.965637 329.762756,854.820190 326.839813,856.469421 
	C325.062836,857.472046 322.947266,857.030090 320.980377,856.927734 
	C318.649414,856.806519 318.028076,855.226990 318.005768,853.163391 
	C317.981659,850.931702 318.519104,849.139465 321.154083,849.057922 
	C324.127014,848.965881 327.104248,849.012817 330.540405,848.998413 
	C331.992188,848.177612 333.071289,847.931152 334.636841,848.745972 
	C336.415283,849.646729 337.034332,850.776611 336.997345,852.652466 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M659.347351,889.316772 
	C658.865784,886.739868 656.315796,883.518921 659.784851,882.536560 
	C663.607666,881.453918 668.028259,880.807617 671.640198,883.759216 
	C672.759460,884.673828 673.366516,886.220398 674.842285,886.788391 
	C676.208679,887.314209 678.011414,887.484802 677.548767,889.603027 
	C677.133545,891.504211 675.885010,892.838623 673.996460,893.443359 
	C671.553406,894.225708 671.453186,892.228638 670.733459,890.770264 
	C669.429749,888.128601 667.276855,887.366577 664.255615,888.738281 
	C662.736328,890.376404 661.259705,890.305298 659.347351,889.316772 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M280.632751,772.742493 
	C283.369324,772.998108 285.741119,772.998657 288.557251,772.998413 
	C290.207214,771.826721 291.603271,771.265808 293.648834,771.828125 
	C299.873444,773.059998 299.694183,773.045288 298.244537,779.135925 
	C297.478088,782.356018 298.244415,786.515686 292.343933,784.151428 
	C291.294556,783.235168 290.945770,782.371338 291.359100,780.994141 
	C290.804138,779.539856 294.744110,778.512268 291.263763,777.166748 
	C286.592834,776.665833 282.115204,777.731567 277.381378,776.166992 
	C277.001068,775.250000 277.000916,774.500000 277.000977,773.375000 
	C277.961517,772.009521 279.070374,771.965820 280.632751,772.742493 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M280.625610,794.706177 
	C284.791901,796.326050 288.164429,793.248962 291.924896,793.970093 
	C293.203766,794.215271 294.602020,793.904907 295.850861,794.222046 
	C297.309418,794.592346 298.766296,795.491760 298.489899,797.270142 
	C298.238800,798.885498 296.684357,798.976074 295.340363,798.980896 
	C289.452667,799.002014 283.564301,798.952026 277.677521,799.029846 
	C276.133331,799.050232 274.301636,798.370117 273.103699,800.612427 
	C270.311218,800.781433 269.927368,798.934082 270.001831,796.382324 
	C270.856293,795.835571 271.711365,795.668640 272.783691,795.252075 
	C275.329742,793.728638 277.723999,793.256287 280.625610,794.706177 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M426.356506,862.285583 
	C424.611450,862.000732 423.222870,862.001465 421.417786,862.002319 
	C418.262878,861.686584 417.876587,860.164124 419.451782,857.458008 
	C420.749664,856.999756 421.500549,856.999573 422.626953,856.998413 
	C423.446320,856.998047 423.890228,856.998596 424.667053,856.999573 
	C430.285614,859.077820 435.796814,857.506653 441.590179,858.000122 
	C445.794220,858.949463 449.486176,856.186096 453.647827,857.844421 
	C454.819794,858.991272 455.088257,860.067627 454.252777,861.637695 
	C448.937286,862.090515 443.894196,859.640808 438.407410,861.941345 
	C436.573059,863.418640 434.772827,863.557190 432.455994,863.488281 
	C430.162292,863.622437 428.326904,863.732605 426.356506,862.285583 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M658.599182,888.989136 
	C660.388916,889.000000 661.777771,889.000000 663.583374,889.000000 
	C665.354309,891.514587 665.110474,892.850403 661.757263,893.061035 
	C656.113037,893.415649 650.618042,892.201599 645.041199,891.922974 
	C642.732300,891.807617 640.593079,890.865723 641.125610,888.002258 
	C641.680603,885.018188 644.250671,884.388062 646.458984,885.444153 
	C650.233948,887.249817 654.167419,888.197388 658.599182,888.989136 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M384.618439,840.690674 
	C384.655182,843.153748 388.933960,843.515747 387.198608,845.832275 
	C385.783875,847.720825 383.170868,847.929016 380.825531,847.981445 
	C378.336853,848.037109 375.832397,848.147278 373.358521,847.944519 
	C368.558105,847.551086 367.768799,846.013794 369.958740,841.351074 
	C374.700256,839.721313 379.409637,838.703918 384.618439,840.690674 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M376.556641,830.000000 
	C379.370575,830.000000 381.741119,829.999939 384.555847,830.000244 
	C386.522675,830.528503 386.929230,831.664001 386.541077,833.497742 
	C386.157166,834.004944 385.941406,834.154968 385.362244,834.152466 
	C380.329285,834.676147 375.657684,835.322815 370.458618,835.000000 
	C367.151520,836.162537 364.380188,835.939941 361.337341,834.266846 
	C360.521667,829.955872 362.559235,828.718567 366.295746,828.963928 
	C369.587585,829.180115 372.752991,830.523438 376.556641,830.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M312.243378,899.003296 
	C310.345001,899.111267 308.507507,899.022766 308.500610,901.083191 
	C308.494904,902.788452 310.302185,902.580078 311.454041,903.128479 
	C313.971008,904.326904 316.557983,905.185791 320.256653,905.467346 
	C316.167206,908.549805 312.583771,908.810547 309.070648,906.917175 
	C306.764709,905.674316 304.713348,903.981812 302.200684,903.019287 
	C300.705353,902.446472 299.519806,901.395081 301.106476,899.630188 
	C303.151581,897.355408 313.801025,895.495789 316.644745,896.976868 
	C316.993866,897.158691 317.164368,897.683472 317.445129,898.088989 
	C315.948212,899.607117 314.195038,898.744080 312.243378,899.003296 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M681.954712,944.467407 
	C679.609253,944.755432 677.636597,944.833008 675.672546,945.007019 
	C670.846191,945.434692 670.827576,942.251526 671.014648,938.884949 
	C671.241821,934.797058 673.752502,936.333130 675.967346,936.775452 
	C677.891968,937.159729 679.975525,936.950012 681.803345,937.559143 
	C683.587708,938.153931 686.191650,938.602844 686.461914,940.742737 
	C686.777954,943.244446 683.855652,943.231201 681.954712,944.467407 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M495.196655,881.202271 
	C492.628601,881.000000 490.258392,881.000000 487.444092,881.000000 
	C485.894196,878.944275 486.149689,877.208801 488.739410,877.119812 
	C495.634796,876.882935 502.601288,876.246826 509.227753,879.063599 
	C509.869843,879.336548 510.558136,879.536926 510.452698,880.321594 
	C510.329926,881.235413 509.575684,881.725891 508.722534,881.848938 
	C505.127655,882.367065 501.588593,883.438232 497.446625,883.000000 
	C497.001221,883.000000 497.000000,883.000000 497.000183,883.000000 
	C496.475830,882.457336 495.932739,881.933411 495.196655,881.202271 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M565.438721,881.001221 
	C560.204712,880.995605 555.409241,881.006470 550.614136,880.971924 
	C549.057983,880.960693 547.350708,880.676819 547.351440,878.754333 
	C547.352295,876.460144 549.393433,877.040039 550.734009,877.029663 
	C558.174622,876.972168 565.615906,877.005493 573.524841,877.011230 
	C573.995117,877.760864 573.997559,878.507080 574.000000,879.626648 
	C571.491577,881.950378 568.575806,880.592163 565.438721,881.001221 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M706.307983,873.318604 
	C706.001953,871.932129 706.001465,870.866760 706.001709,869.401855 
	C712.064758,868.945618 718.155640,869.409851 724.571960,868.002808 
	C726.066711,868.000000 727.133362,868.000000 728.600037,868.000000 
	C730.047607,869.408936 732.662415,868.970154 732.956543,871.659790 
	C733.178955,873.057251 732.546570,873.356262 731.335754,873.214478 
	C729.934204,873.001831 728.866028,873.001282 727.397095,873.001587 
	C725.930786,873.002258 724.865051,873.002014 723.399658,873.002136 
	C717.570068,874.044617 712.140747,875.100891 706.307983,873.318604 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M632.753906,897.338867 
	C635.795044,896.899780 638.705322,897.257507 641.357788,896.590515 
	C645.201538,895.623779 646.528076,897.302734 647.096130,900.590942 
	C647.388062,902.280518 648.858948,904.252319 646.603210,905.436707 
	C644.650940,906.461670 642.299866,906.579773 640.580933,904.920776 
	C638.518066,902.929749 636.174072,901.489929 633.315247,900.143005 
	C632.357239,899.327393 632.239014,898.543579 632.753906,897.338867 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M576.277954,920.958252 
	C578.779419,925.548706 576.053284,929.559692 575.799500,933.700562 
	C575.705139,935.240417 574.429688,935.887085 572.943115,935.935852 
	C571.944092,935.968689 570.805542,936.040710 570.351868,934.929810 
	C569.754150,933.466431 569.691772,931.779785 571.010010,930.778809 
	C573.205994,929.111389 573.377380,927.841980 570.949219,926.299438 
	C568.398499,924.679077 569.238892,922.390808 570.556824,920.686829 
	C572.095642,918.697449 574.200256,918.474854 576.277954,920.958252 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M312.721130,833.369629 
	C314.819824,831.522156 316.333710,827.426636 318.955322,832.649170 
	C318.794098,835.440308 318.301697,837.884033 319.014526,840.663086 
	C316.890930,845.625793 310.108826,847.043945 306.773163,843.454163 
	C305.336395,841.907837 306.941437,841.274658 308.012512,840.532593 
	C309.477203,839.518005 311.799622,840.836548 312.915161,838.360474 
	C311.885651,836.700256 311.623047,835.290161 312.721130,833.369629 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M387.293030,857.265869 
	C386.106140,856.904419 385.337891,856.635071 385.049927,855.315186 
	C388.627808,853.898804 391.897430,852.919006 394.479584,857.424438 
	C395.562347,859.313721 398.665833,860.044922 400.925934,861.651306 
	C401.022980,863.596863 402.276489,864.109436 403.702850,864.865723 
	C404.320038,865.383484 404.701935,865.751404 404.867096,866.213257 
	C405.642609,868.382080 405.906311,870.845703 403.786346,872.075928 
	C401.464142,873.423523 399.315796,871.515930 398.467316,869.662964 
	C396.067200,864.421448 391.171387,861.597839 387.293030,857.265869 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M479.990662,868.000061 
	C477.863983,867.998718 476.207062,867.908997 474.563202,868.017212 
	C472.242615,868.169922 470.707214,867.289673 470.199402,864.976013 
	C469.751160,862.933777 469.874390,860.753723 471.686371,859.577637 
	C473.513184,858.391968 475.345367,859.826904 476.457123,861.116638 
	C479.039398,864.112732 482.038971,865.515808 485.999298,865.089172 
	C487.100281,864.970581 488.525909,865.379761 488.336670,866.931824 
	C488.176849,868.242676 486.834412,867.942566 485.914154,867.975525 
	C484.100220,868.040710 482.282410,867.998108 479.990662,868.000061 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M384.999237,834.250000 
	C385.165436,833.999695 385.332123,833.999451 385.748779,833.998352 
	C388.985687,832.462646 391.830597,830.545776 395.687744,830.755798 
	C396.177185,831.548523 396.354401,832.097107 396.766418,832.822815 
	C397.334320,833.317871 397.531342,833.708008 397.595917,834.493774 
	C397.498627,835.544006 396.786316,836.337402 398.131165,836.993042 
	C398.829926,837.179443 399.212341,837.335632 399.788666,837.767700 
	C399.955872,839.883301 401.960632,840.154175 402.848175,841.713562 
	C403.379669,842.434998 403.536102,842.936768 403.299561,843.879517 
	C401.373535,845.597473 399.565369,845.141479 397.389160,844.268188 
	C394.531677,842.583618 391.860657,841.321045 389.269043,839.248657 
	C388.156036,837.859436 386.911743,838.028687 385.050964,837.864075 
	C383.606384,836.925537 383.557587,836.149902 384.725067,835.202393 
	C384.999908,834.833313 384.999786,834.666687 384.999237,834.250000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M704.367065,904.992981 
	C704.000000,905.000000 703.500000,905.000000 703.250000,905.000000 
	C700.150391,904.613342 697.624756,902.793152 694.402771,903.788452 
	C692.564392,904.356384 690.224854,903.513367 690.170959,900.894592 
	C690.113892,898.124451 692.319214,897.892212 694.385559,898.039978 
	C696.003357,898.155762 697.598022,898.593506 699.601501,898.944092 
	C701.601196,897.739014 703.090332,898.051147 704.755615,899.660645 
	C705.613220,901.624512 708.759766,900.151611 708.422729,902.493774 
	C708.182129,904.165894 706.527344,904.970703 704.367065,904.992981 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M361.388611,873.267090 
	C358.352539,872.999756 355.703613,873.051025 353.058228,872.976624 
	C351.805176,872.941406 350.194855,873.509583 349.467590,872.035095 
	C348.602112,870.280518 350.204376,869.328308 351.211823,868.343079 
	C352.200592,867.376038 353.425964,865.313354 354.794464,866.690552 
	C357.588409,869.502258 359.923553,870.528748 362.999847,867.186340 
	C363.377502,866.776062 365.130524,867.631714 366.623047,867.950317 
	C368.145172,869.331848 368.429504,870.814087 367.513184,872.856201 
	C365.473267,874.307312 363.635010,874.110779 361.388611,873.267090 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M442.293152,892.260254 
	C438.916870,891.743042 435.752777,892.612000 432.372681,891.164307 
	C431.982239,889.320801 432.404480,888.070679 434.450989,888.014221 
	C437.959167,887.917358 441.557983,887.652466 444.672241,889.527039 
	C447.644165,891.316040 450.711639,892.112000 454.139984,892.050110 
	C455.438354,892.026672 457.730988,891.353577 457.539764,893.649536 
	C457.370911,895.677002 455.167480,894.750854 453.407745,895.003174 
	C452.248627,895.004395 451.499725,895.003906 450.376282,895.004150 
	C446.492462,897.270508 444.872894,893.901733 442.293152,892.260254 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M731.044800,873.351318 
	C731.550354,872.824951 732.098206,872.647461 732.823059,872.235413 
	C735.013794,873.127319 736.392273,866.490845 738.938232,871.682129 
	C741.373047,876.646973 741.087036,880.623474 737.458923,883.008911 
	C736.013367,883.959351 734.081177,883.476196 734.524719,882.298401 
	C736.108215,878.094055 732.073975,876.582947 731.044800,873.351318 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M360.546600,838.000000 
	C362.170593,843.027832 361.573425,843.693237 356.504028,844.042236 
	C353.432343,844.253784 350.605255,843.150024 347.633453,842.907471 
	C346.580200,842.821411 345.591003,842.342896 345.483826,841.203430 
	C345.343506,839.711304 346.489807,839.199158 347.724792,839.089050 
	C351.847534,838.721558 355.896240,837.498718 360.546600,838.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M454.873291,879.717468 
	C457.977509,877.456421 461.189453,878.068848 464.293091,878.026001 
	C466.133209,878.000610 466.949921,878.933533 466.983887,880.769958 
	C467.019775,882.708557 466.452820,883.843872 464.257690,884.012939 
	C459.624481,884.369995 455.206635,883.310669 450.235626,882.551575 
	C451.370178,880.456482 453.625946,881.271240 454.873291,879.717468 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M681.308533,865.227905 
	C678.827271,862.743042 678.840881,859.923950 679.011780,857.087891 
	C679.115784,855.361877 678.407288,853.027405 680.802063,852.500793 
	C682.696899,852.084167 684.267944,853.506226 685.329041,855.025269 
	C686.446106,856.624451 687.749146,858.341919 686.046509,860.399536 
	C685.005981,861.657104 683.473816,862.545776 683.043213,864.648560 
	C682.618896,865.391296 682.157898,865.544006 681.308533,865.227905 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M337.988586,872.601440 
	C337.684418,873.470520 337.368866,873.940979 337.026031,874.705750 
	C333.333740,874.985352 329.667969,875.007629 326.004089,874.935242 
	C324.801086,874.911438 323.589966,874.544495 323.478210,873.066284 
	C323.386566,871.854126 324.324982,871.335266 325.353760,871.127014 
	C328.384766,870.513367 330.978760,868.358093 334.392761,868.943787 
	C336.486450,869.303040 337.889465,869.886902 337.988586,872.601440 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M502.051392,899.674683 
	C498.640747,899.872314 495.403595,899.290527 492.230286,897.278992 
	C492.046661,894.772644 491.399078,891.958008 494.493042,891.423035 
	C498.113251,890.797058 500.199768,894.047913 502.850281,895.709229 
	C504.903381,896.996155 502.282440,898.094055 502.051392,899.674683 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M462.732056,919.734619 
	C461.334198,920.966736 460.010498,921.038208 458.662109,920.979309 
	C457.660797,920.935547 456.551147,920.921692 456.289764,919.672485 
	C456.053650,918.544250 456.719727,917.446350 457.706116,917.382019 
	C461.051971,917.163757 463.328979,914.602966 466.356598,913.722900 
	C467.819061,913.297913 469.194031,912.754944 470.698242,913.425537 
	C471.978638,913.996338 473.101898,914.707031 472.812531,916.371643 
	C472.546417,917.902588 471.305664,917.898560 470.125031,917.991333 
	C467.703217,918.181519 465.059540,917.326843 462.732056,919.734619 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M294.084290,835.233459 
	C298.405273,829.554260 299.069366,829.288818 304.830536,831.009949 
	C301.818176,833.003113 299.715332,835.250366 299.686157,838.976440 
	C299.672485,840.718445 297.772034,841.065857 296.175323,840.951172 
	C293.898376,840.787781 291.909485,839.858459 290.697449,837.928772 
	C289.687317,836.320496 290.901947,835.537720 292.662506,835.042053 
	C293.462799,835.245605 293.584869,836.119629 294.319366,835.819092 
	C294.931091,835.568726 294.099823,836.129883 294.084290,835.233459 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M516.586792,883.010864 
	C519.030640,883.331848 519.869812,884.728638 519.554932,886.545410 
	C519.224670,888.450928 517.731140,889.140625 515.829163,889.006897 
	C514.841492,888.937439 513.844604,888.998108 512.425964,889.000000 
	C510.879608,887.622986 509.280060,888.147400 507.859711,887.949097 
	C506.668518,887.782715 505.463654,887.452759 505.482880,885.982056 
	C505.501984,884.515259 506.725372,884.056458 507.907440,884.083435 
	C510.728424,884.147888 513.462219,883.718140 516.586792,883.010864 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M493.010895,862.367493 
	C497.808350,860.051819 502.827393,861.342834 507.743835,861.065369 
	C508.799866,861.005737 509.762909,861.458008 509.694763,862.750549 
	C509.621094,864.148499 508.606171,864.830872 507.366241,864.872498 
	C503.889191,864.989380 500.407471,864.967957 496.464417,865.001709 
	C494.411682,865.030273 493.018738,864.799805 493.010895,862.367493 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M417.999146,896.566528 
	C415.628937,897.000366 413.259064,897.000732 410.445221,897.001770 
	C408.521240,896.943542 406.783997,897.096069 406.434814,895.179138 
	C406.162720,893.685669 407.541687,892.835693 408.556305,892.078247 
	C411.050385,890.216003 414.012817,890.673218 416.629639,891.366272 
	C418.886688,891.964111 417.713654,894.460388 417.999146,896.566528 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M336.642578,862.953186 
	C337.000092,863.444458 337.000153,863.888916 337.000732,864.666687 
	C333.389008,865.028870 329.792633,864.854309 326.216370,865.854431 
	C324.504852,866.333069 322.000977,866.055969 321.690674,864.032166 
	C321.345062,861.778076 323.977478,862.537903 325.285675,861.875488 
	C329.260376,859.862671 332.817047,860.457520 336.642578,862.953186 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M705.232117,899.747742 
	C703.578918,899.670959 702.157776,899.341919 700.368347,899.006470 
	C700.209473,895.933899 695.553284,895.962708 696.008972,892.369141 
	C699.122925,890.890930 701.650330,891.345459 703.344299,894.533447 
	C704.351929,896.429688 706.090576,897.191711 708.616333,896.997314 
	C708.905762,899.365112 706.653809,898.678345 705.232117,899.747742 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M389.006714,839.367554 
	C392.207306,838.652771 394.282745,840.861694 396.829498,842.695312 
	C398.900757,845.967712 397.973236,847.956116 394.428284,849.093506 
	C392.074799,848.794128 389.049683,849.809387 391.353241,845.754517 
	C392.713745,843.359741 388.449188,842.309082 389.006714,839.367554 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M632.682129,896.855591 
	C633.000000,897.750000 633.000000,898.500000 633.000000,899.625000 
	C632.428467,902.128723 632.609985,904.961121 628.883118,903.813110 
	C625.313049,902.713440 625.418274,899.804504 626.280823,897.425537 
	C627.533508,893.970581 630.141479,895.511963 632.682129,896.855591 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M265.332031,802.600464 
	C262.602112,800.923767 259.942078,800.956726 257.278625,800.940735 
	C256.448364,800.935730 255.523544,800.765198 255.391449,799.731445 
	C255.215912,798.357849 256.266083,798.056519 257.286530,798.066223 
	C259.787262,798.089966 262.194641,797.570312 264.644745,797.190125 
	C267.119537,796.806152 268.172394,799.139343 268.619873,800.899780 
	C269.207306,803.210876 267.230225,802.958557 265.332031,802.600464 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M534.824219,931.659912 
	C536.175537,933.582092 535.196472,934.671448 533.767761,934.782166 
	C530.630493,935.025208 527.458923,934.943054 524.307983,934.821899 
	C523.319092,934.783875 522.373230,934.097412 522.490967,932.862305 
	C522.603699,931.679932 523.556580,931.150574 524.584473,931.124817 
	C527.892822,931.041870 531.219299,930.607788 534.824219,931.659912 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M633.152954,906.615967 
	C635.480713,907.419006 637.429199,908.049500 640.810303,909.143494 
	C634.920227,910.492310 630.435120,910.018555 625.975891,909.719299 
	C625.155762,909.664307 624.464844,908.989136 624.479309,908.025696 
	C624.497375,906.819092 625.371826,906.255249 626.396179,906.150513 
	C628.525452,905.932800 630.676880,905.741333 633.152954,906.615967 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M666.801758,869.302856 
	C667.837585,869.103699 668.515808,868.935486 669.156189,869.028320 
	C670.806946,869.267517 673.055847,868.103760 673.801392,870.639893 
	C674.226257,872.084961 673.402710,873.181335 672.114319,873.899780 
	C670.287964,874.918457 668.422119,875.614929 666.598877,873.989929 
	C665.024231,872.586670 664.384644,871.043274 666.801758,869.302856 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M297.086670,862.129272 
	C297.446259,859.322083 299.230194,858.326843 301.302368,858.433594 
	C302.891846,858.515625 304.522217,859.410706 304.850098,861.382874 
	C305.088989,862.819702 304.737610,864.267456 303.246460,864.609802 
	C300.882721,865.152405 298.204407,865.941589 297.086670,862.129272 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M315.690674,864.965454 
	C314.153992,865.063049 312.983795,865.010803 311.874176,865.245239 
	C310.579346,865.518738 309.130615,866.229492 308.524872,864.495850 
	C307.955627,862.866699 309.041870,861.657532 310.392151,860.901489 
	C312.556519,859.689697 314.809418,859.381348 316.713959,861.346619 
	C318.004822,862.678711 318.738220,864.176392 315.690674,864.965454 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M403.313416,857.225708 
	C402.185608,857.001648 401.359467,857.078979 400.553955,856.983093 
	C399.039886,856.802856 397.103607,857.692261 396.258118,855.641418 
	C395.787231,854.499207 396.173462,853.358704 397.183990,852.584961 
	C398.700562,851.423889 400.262146,850.680298 401.977325,852.330933 
	C402.304077,852.645569 402.856354,852.725830 403.651672,852.957153 
	C404.517914,853.095154 404.960876,853.332703 405.518066,854.047913 
	C405.933472,855.154236 405.891266,855.909607 405.349243,856.953735 
	C404.638336,857.498047 404.142914,857.562561 403.313416,857.225708 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M604.251221,905.856079 
	C602.229736,904.863220 600.517273,903.770020 601.440186,901.595825 
	C602.331787,899.495300 604.476196,899.980713 606.249207,900.087158 
	C607.715088,900.175232 608.954041,900.979858 608.765747,902.547302 
	C608.492432,904.822388 606.920166,905.917053 604.251221,905.856079 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M589.102905,887.040527 
	C591.378418,887.320679 594.225708,887.265381 594.289978,889.299377 
	C594.354675,891.346985 591.619446,891.804321 589.630249,891.953857 
	C587.996582,892.076660 585.955872,892.260864 585.412964,890.309509 
	C584.697144,887.737122 587.034607,887.581726 589.102905,887.040527 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M483.637512,887.010864 
	C486.282349,888.073059 488.424011,889.646545 491.591125,889.001770 
	C488.950531,893.426819 485.310303,893.944214 482.059418,890.320740 
	C480.538910,888.625916 480.752930,887.342346 483.637512,887.010864 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M385.000000,840.625549 
	C380.311371,841.000793 375.622742,841.001648 370.468262,841.003113 
	C369.028717,839.828186 369.545593,839.213928 370.825562,839.068237 
	C374.259918,838.677185 377.701782,838.352112 381.569733,838.000427 
	C382.681549,837.414917 383.448975,837.278564 384.647614,837.784424 
	C384.998779,838.002441 385.000000,838.000000 384.999390,838.002441 
	C384.999176,838.753601 384.999573,839.502380 385.000000,840.625549 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M319.320129,840.971802 
	C315.946289,838.695251 316.404419,836.094482 318.683411,833.182861 
	C320.446808,832.954773 321.372192,833.951050 322.709412,834.852295 
	C324.321136,836.611694 323.851471,838.044800 322.280792,839.698059 
	C321.212616,840.313721 320.425232,840.627441 319.320129,840.971802 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M312.571472,833.000000 
	C312.997437,834.388916 312.998535,835.777771 312.999207,837.583374 
	C311.702271,837.999512 310.399719,838.077576 309.110870,837.977966 
	C307.619232,837.862732 305.496979,838.604187 305.224792,836.231323 
	C304.950256,833.837708 306.987213,833.408936 308.750793,833.091003 
	C309.852386,832.892456 311.012665,833.019287 312.571472,833.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M193.343750,818.255493 
	C195.945633,815.957703 198.751892,815.505737 201.755951,816.473633 
	C202.496719,816.712280 203.358444,817.061218 203.448456,818.040222 
	C203.540802,819.044739 202.790833,819.715759 202.009888,819.718628 
	C199.066193,819.729431 195.900284,821.250793 193.343750,818.255493 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M335.049500,848.617737 
	C333.932770,848.997925 332.866730,848.998352 331.400970,848.998169 
	C330.948853,848.672180 330.934967,848.334778 330.837799,848.023376 
	C330.254242,846.153564 329.190308,844.182129 331.252655,842.608826 
	C332.384094,841.745728 333.815887,841.765198 334.919159,842.745728 
	C336.844147,844.456543 335.605408,846.383850 335.049500,848.617737 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M360.999664,834.397827 
	C363.754364,834.218872 366.590668,833.440002 369.631012,834.837280 
	C368.759827,839.987244 364.683197,837.284912 361.432953,838.000000 
	C360.998779,838.000000 361.000000,838.000000 360.999390,838.000000 
	C360.998962,836.932739 360.999146,835.865417 360.999664,834.397827 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M394.059540,849.328247 
	C396.156555,847.908997 397.232117,846.200317 396.999329,843.372192 
	C398.741791,843.332764 400.483582,843.665527 402.612061,843.999146 
	C403.594147,844.492310 403.779205,845.148682 403.669800,846.227661 
	C402.706604,848.244385 400.483582,847.112488 399.239929,848.765015 
	C397.484924,850.047607 396.137482,852.341431 394.059540,849.328247 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M441.693298,857.719238 
	C436.438446,859.485107 430.895966,859.988770 425.204102,857.338623 
	C430.458984,857.265564 435.946838,856.453247 441.693298,857.719238 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M551.004883,938.307495 
	C548.902893,939.108215 547.123596,939.562866 545.870361,937.552734 
	C545.315796,936.663208 545.020935,935.683105 545.934570,934.862976 
	C547.328247,933.611755 549.101746,933.774353 550.653320,934.184998 
	C553.369629,934.903931 553.057312,936.447998 551.004883,938.307495 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M249.620819,810.775330 
	C249.258087,808.640076 250.507492,807.778748 251.891418,807.251892 
	C253.400787,806.677246 254.779510,807.219788 255.854782,808.432617 
	C256.412964,809.062134 256.887604,809.772644 256.438141,810.623352 
	C255.702347,812.015930 254.329620,811.972229 253.013290,811.987061 
	C251.860474,812.000000 250.712479,811.981018 249.620819,810.775330 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M332.222321,836.973145 
	C330.130219,836.617859 328.106689,835.746643 328.748108,833.992126 
	C329.475372,832.002747 331.940918,833.189331 333.635468,833.067566 
	C334.962158,832.972290 336.623810,833.298096 336.474304,835.015625 
	C336.273499,837.322632 334.190277,836.870178 332.222321,836.973145 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M680.842773,865.356567 
	C681.444458,864.999756 681.888916,864.999512 682.666687,864.998413 
	C683.457153,865.680786 683.921082,866.359497 684.370056,867.048096 
	C685.373535,868.587463 684.687317,869.893738 683.728882,871.101440 
	C683.024658,871.988708 682.011108,872.132751 681.063538,871.620728 
	C679.590332,870.824829 680.033081,869.326477 680.063416,868.073242 
	C680.082581,867.281982 680.465759,866.499573 680.842773,865.356567 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M671.399963,865.000000 
	C671.894592,862.593567 668.720032,858.336548 674.590210,858.003296 
	C676.413086,859.971375 676.124268,862.069946 675.231506,864.598145 
	C673.933289,865.000000 672.866638,865.000000 671.399963,865.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M419.953003,869.371094 
	C420.001221,869.000000 420.500610,869.000000 420.750305,869.000000 
	C422.017548,868.171021 423.131470,867.874939 424.792542,868.605652 
	C426.044281,869.896301 426.096466,871.046021 425.264771,872.629395 
	C423.304596,874.464844 421.318695,874.507263 419.675079,873.321838 
	C418.383881,872.390564 419.751251,870.955811 419.953003,869.371094 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M703.374756,865.001282 
	C701.933289,865.001648 700.866638,865.000854 699.399963,865.000000 
	C698.208801,864.799194 697.019653,864.535645 697.729370,863.518738 
	C699.361633,861.180054 703.892212,861.644226 705.979736,864.599609 
	C705.248169,864.996765 704.498840,864.998413 703.374756,865.001282 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M660.624695,865.000000 
	C660.647278,867.372375 659.354370,868.852661 656.891296,868.893921 
	C654.899963,868.927246 654.085632,867.624878 654.009094,865.380859 
	C654.445007,864.999207 654.889954,864.998413 655.668701,864.996338 
	C656.387695,864.617676 656.846558,864.471619 657.688354,864.775879 
	C658.748169,864.996765 659.498779,864.998413 660.624695,865.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M589.062744,900.921875 
	C587.314636,900.537476 585.422729,900.499207 585.594543,898.532104 
	C585.705261,897.264587 587.165039,896.996399 588.359253,897.040588 
	C589.593689,897.086182 591.244873,896.565247 591.665894,898.295105 
	C592.055420,899.896057 590.867493,900.612305 589.062744,900.921875 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M285.024963,899.217590 
	C287.035126,894.745605 290.351135,897.845398 293.239746,897.782288 
	C290.728790,899.023193 288.283691,900.490540 285.024963,899.217590 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M640.173828,893.661133 
	C639.792969,894.007935 639.653564,894.101440 639.503784,894.173218 
	C637.807678,894.986023 635.983032,895.730774 634.475403,894.106445 
	C633.721497,893.294250 634.304077,892.022156 635.275146,891.598999 
	C637.439148,890.656006 639.454590,890.651306 640.173828,893.661133 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M405.398071,854.212524 
	C404.690369,853.881409 404.434296,853.689636 404.115906,853.212341 
	C404.046173,851.554077 403.048767,850.629395 402.146637,849.292908 
	C401.854462,848.402283 402.047974,847.939514 402.972168,847.498108 
	C404.943634,847.026550 406.194214,847.630676 407.545532,849.040955 
	C408.302185,851.554260 408.756134,853.668701 405.398071,854.212524 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M425.333008,873.002380 
	C424.999573,871.934448 424.999176,870.866455 424.998169,869.398010 
	C424.870605,868.830994 424.743652,868.664368 424.808472,868.248901 
	C426.554016,868.107483 428.430450,867.543152 429.363861,869.254822 
	C430.322418,871.012695 428.589325,871.740784 427.297791,872.846680 
	C426.554077,873.002380 426.110077,873.002380 425.333008,873.002380 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M615.372314,881.784912 
	C613.550781,883.311218 611.915100,883.482178 610.627502,881.664795 
	C610.030090,880.821655 610.794312,880.164551 611.551208,879.809082 
	C613.476013,878.904907 614.943054,879.202087 615.372314,881.784912 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M361.086945,873.363525 
	C362.714020,872.999695 364.428009,872.999390 366.570404,872.998291 
	C367.441833,872.616028 367.952759,872.468018 368.897339,872.749817 
	C369.744049,873.701111 369.797699,874.493958 369.240662,875.662781 
	C366.555084,874.685547 363.345032,875.991333 361.086945,873.363525 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M431.764282,862.343994 
	C433.714386,862.000000 435.428802,862.000000 437.571594,862.000000 
	C438.000000,862.750000 438.000000,863.500000 438.000000,864.625000 
	C436.285614,865.000000 434.571198,865.000000 432.428406,865.000000 
	C431.356812,864.328491 431.248657,863.547791 431.764282,862.343994 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M291.000000,777.375000 
	C292.496521,776.379211 294.483429,775.047241 295.444214,776.829102 
	C296.627045,779.022766 294.113800,779.261780 292.359833,779.955566 
	C291.834351,780.176270 291.667480,780.352600 291.249695,780.264526 
	C290.999207,779.250000 290.999603,778.500000 291.000000,777.375000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M449.492859,873.242310 
	C448.709961,870.692505 449.830688,869.934021 451.893280,870.053101 
	C452.753052,870.102722 453.791229,870.410889 453.450531,871.394836 
	C452.885620,873.026062 451.778076,874.251709 449.492859,873.242310 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M580.333313,881.000000 
	C579.950745,880.683105 579.900269,880.366333 579.852478,880.049194 
	C579.673828,878.865356 578.424805,877.604492 579.801086,876.539673 
	C580.305542,876.149414 581.504822,876.042236 582.007751,876.376587 
	C583.724731,877.518066 582.245667,878.948547 582.046753,880.633728 
	C581.555542,881.000000 581.111084,881.000000 580.333313,881.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M345.399994,881.000000 
	C345.214966,879.636047 344.362457,877.609009 346.448181,877.289124 
	C348.327454,877.000977 348.892426,878.595947 348.991272,880.622314 
	C347.933319,881.000000 346.866669,881.000000 345.399994,881.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M293.749084,835.000000 
	C295.518524,835.028137 296.997803,835.137207 296.876984,837.238831 
	C296.825867,838.127747 296.333496,838.820984 295.400970,838.763794 
	C293.567780,838.651306 293.103271,837.323059 293.008789,835.377930 
	C293.000000,835.000000 293.499390,835.000000 293.749084,835.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M403.001678,857.402161 
	C403.446075,857.001648 403.889679,857.000793 404.666046,857.000000 
	C407.364441,856.958252 407.596130,858.500610 407.230042,860.656738 
	C405.933319,860.998352 404.866669,860.999146 403.399994,861.000000 
	C403.000305,859.933960 403.000610,858.867920 403.001678,857.402161 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M322.340027,839.958862 
	C322.328918,838.578918 322.657837,837.157776 322.993378,835.368347 
	C324.317810,835.175049 325.743988,835.485901 325.578613,837.138123 
	C325.423981,838.683167 324.141296,839.500183 322.340027,839.958862 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M675.320618,864.813293 
	C675.000000,862.958313 675.000000,860.916687 675.000000,858.437500 
	C678.421143,859.898499 677.212830,862.244934 675.320618,864.813293 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M359.914917,848.640137 
	C358.286133,849.000244 356.571533,849.000549 354.428467,849.001587 
	C355.565948,845.718262 357.642426,846.554504 359.914917,848.640137 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M604.764771,895.204224 
	C605.855530,896.114441 606.351440,897.076172 604.900208,897.502441 
	C603.950867,897.781250 602.769775,897.182129 602.639526,896.131409 
	C602.497498,894.985413 603.653503,895.146606 604.764771,895.204224 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M454.332764,861.958984 
	C454.000000,860.933289 454.000000,859.866638 454.000000,858.399963 
	C455.173309,858.086670 456.333771,858.422791 456.586700,859.673584 
	C456.858856,861.019836 455.835938,861.616150 454.332764,861.958984 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M440.709717,866.706177 
	C441.088867,865.329224 441.927643,864.878174 443.002106,865.313232 
	C443.740356,865.612122 443.742859,866.368225 443.278198,866.943115 
	C442.510071,867.893494 441.637665,867.919006 440.709717,866.706177 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M428.103485,885.954590 
	C427.049011,884.422119 427.822815,884.060852 428.969513,884.241150 
	C429.957703,884.396606 430.901489,885.087219 430.413910,886.166992 
	C429.976746,887.135071 429.058868,886.400879 428.103485,885.954590 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M326.609436,913.376282 
	C326.121002,911.975830 326.405212,911.154724 327.768127,911.326416 
	C328.118652,911.370544 328.641052,911.903503 328.678162,912.254822 
	C328.821625,913.614502 328.004120,913.896118 326.609436,913.376282 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M403.191528,847.749756 
	C402.690063,848.119080 402.433838,848.311340 402.115662,848.789673 
	C401.249084,849.001709 400.499420,849.000916 399.374878,849.000122 
	C399.295166,846.977783 400.028229,845.617493 402.703766,846.872986 
	C403.127289,847.166504 403.254547,847.333008 403.191528,847.749756 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M301.512939,854.196838 
	C299.762451,854.297119 300.018402,853.212463 300.264862,852.287415 
	C300.337952,852.013000 301.167328,851.627075 301.323242,851.740784 
	C302.101837,852.308289 301.946411,853.159607 301.512939,854.196838 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M384.901794,837.754028 
	C384.248810,838.001770 383.498810,838.001099 382.373810,838.000244 
	C382.094879,836.485657 382.752319,835.418945 384.662628,835.041016 
	C384.662537,835.813965 384.216339,836.619507 384.901794,837.754028 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M353.000610,892.000000 
	C351.998779,891.807983 351.018646,891.566040 351.271942,890.205261 
	C351.318542,889.955017 351.655090,889.565247 351.852966,889.568176 
	C353.052277,889.585815 352.839020,890.588379 352.993408,891.666260 
	C353.001221,892.000000 353.000000,892.000000 353.000610,892.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M657.953308,864.706909 
	C657.554199,864.995911 657.110840,864.996643 656.334961,864.996216 
	C656.101685,864.357361 656.157532,863.593750 656.957336,863.550354 
	C657.257996,863.534058 657.590881,864.111755 657.953308,864.706909 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M521.218201,872.686401 
	C520.553650,872.996643 520.109741,872.998108 519.332886,872.999756 
	C519.095947,872.106689 519.364929,871.338135 520.684814,871.049988 
	C521.378357,871.385315 521.524658,871.844543 521.218201,872.686401 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M514.557129,872.044678 
	C514.184509,872.724365 513.809448,872.732544 513.478149,872.246948 
	C513.408813,872.145325 513.411621,871.909790 513.483704,871.810730 
	C513.829041,871.337097 514.203735,871.354187 514.557129,872.044678 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M275.609802,632.760803 
	C279.064697,633.073853 279.286621,631.660217 278.132538,629.232178 
	C277.222290,627.316895 274.849487,626.246216 275.007019,623.372314 
	C276.411865,621.328003 278.273468,621.910217 280.042969,621.979858 
	C281.150970,622.023499 282.712585,622.420105 282.953461,620.983948 
	C283.232330,619.321533 281.576355,618.992859 280.317230,618.652954 
	C279.055054,618.312073 277.700958,618.358948 276.197937,617.580566 
	C275.162323,616.583679 275.167572,615.487549 274.935028,614.396606 
	C274.019440,615.175293 274.101074,616.243713 273.272156,617.238220 
	C272.390625,617.939575 271.681946,618.171143 270.553772,618.340637 
	C266.411469,618.445435 263.019531,617.303406 259.449280,614.632874 
	C258.141846,610.852783 256.492767,607.916077 256.935059,604.435852 
	C257.247925,601.973938 255.375427,600.664734 253.384705,600.375305 
	C249.574371,599.821472 249.277557,597.089172 249.042847,594.262329 
	C248.820847,591.588745 249.787125,590.660522 252.593155,591.751953 
	C256.286743,593.188660 259.750397,591.138428 260.642242,587.412292 
	C261.002869,585.905579 260.395813,585.112854 258.985382,585.088501 
	C257.688446,585.066162 256.354767,585.224915 255.093750,585.534180 
	C253.280655,585.978882 251.235443,588.224426 249.750793,586.231384 
	C248.517624,584.576050 251.837936,583.589539 251.956940,581.348206 
	C253.868454,577.204529 257.194855,577.559937 260.524872,578.060669 
	C262.282898,578.325134 263.908661,579.465576 265.821777,578.859131 
	C261.386902,575.139832 259.461151,574.841187 254.219543,577.141724 
	C252.672134,577.820740 251.164093,578.460632 249.261719,577.234436 
	C246.151367,577.441040 243.703354,578.230713 243.690308,581.876526 
	C243.687332,582.706604 242.585983,582.895203 241.380722,582.986023 
	C237.555054,589.237671 231.687790,588.851074 225.436295,588.521423 
	C223.198898,588.424072 221.368790,588.538696 219.554916,588.334106 
	C217.624588,588.116394 215.835800,587.438049 215.673050,585.222412 
	C215.523453,583.186035 217.288834,582.566284 218.837387,581.913635 
	C219.882111,581.473389 221.148026,581.687012 222.395966,580.510742 
	C219.064774,579.209106 215.779770,579.910461 212.635941,579.545410 
	C209.816925,579.218140 206.456573,579.039978 206.381226,575.307800 
	C206.310242,571.791931 209.107864,570.163696 212.286407,569.655579 
	C217.040390,568.895630 221.897430,569.740784 226.666275,568.890869 
	C230.092606,568.280212 233.552383,567.846008 236.817139,566.537598 
	C238.895935,565.704346 240.995605,565.538757 243.459473,566.587769 
	C246.610794,568.689880 249.285522,569.313416 252.443924,567.071594 
	C255.551834,564.865601 259.048645,565.480286 262.508850,566.672913 
	C265.373383,567.660217 268.314056,568.838562 271.435608,567.274902 
	C267.687622,566.497803 264.063293,565.528809 260.655029,563.831299 
	C259.438812,563.225525 258.021149,562.947449 256.857300,563.902954 
	C253.149567,566.947205 248.816681,566.635803 244.117111,566.326904 
	C241.833572,565.778992 240.248215,564.788574 240.367996,562.590820 
	C240.546478,559.315918 243.807068,560.167297 245.954361,558.855713 
	C242.302811,558.377747 239.392761,558.891663 237.140808,561.609741 
	C235.304825,563.825806 233.322571,564.210327 231.305298,561.857483 
	C228.947403,559.107361 225.382782,559.008850 222.365402,557.721375 
	C217.501129,555.645874 216.858185,552.939941 220.439377,549.009033 
	C222.389816,546.868103 224.704605,545.308838 227.580719,544.665100 
	C229.212006,544.299988 230.849457,543.908020 231.927689,542.449158 
	C233.234680,540.680786 233.825378,538.937134 230.803711,538.637573 
	C226.120819,538.173218 222.040604,535.614075 217.290070,535.212708 
	C213.342133,534.879089 211.425247,531.996399 212.344559,528.072388 
	C213.211212,524.373230 212.556992,522.293152 208.526505,521.740967 
	C207.051239,521.538879 205.470612,520.845764 205.660110,518.900513 
	C205.815231,517.308228 207.280426,516.927917 208.555618,516.538391 
	C210.815552,515.848145 213.119141,515.638184 215.469864,516.018799 
	C218.947876,516.581909 222.469681,516.752014 225.918579,516.088318 
	C230.575439,515.192139 234.544861,517.783875 239.777481,518.745789 
	C237.690598,515.723206 235.303528,515.219971 232.919174,515.362732 
	C226.451889,515.749756 220.026993,513.957520 213.511124,515.343872 
	C210.538589,515.976318 207.579376,514.838562 204.844421,512.557129 
	C204.438217,512.011658 204.307938,511.764465 204.070236,511.102051 
	C203.793015,508.707916 204.606964,507.236938 206.683136,506.025665 
	C210.231857,505.009644 213.434494,505.334351 217.016525,505.394409 
	C225.633057,505.310272 233.839310,505.654663 242.044907,506.279266 
	C236.895737,503.374329 231.380035,503.292786 225.372345,503.951660 
	C224.433563,503.949890 223.907913,503.834808 223.051056,503.401855 
	C221.964600,502.197388 221.854980,501.112762 222.292801,499.579742 
	C222.807495,498.764801 223.240677,498.457611 224.149521,498.218781 
	C229.711670,498.799652 234.932281,497.427765 240.436661,498.324158 
	C242.454147,497.590485 244.358429,497.784698 246.100128,496.580872 
	C246.314438,495.958710 246.196075,495.636047 245.666901,495.194092 
	C244.102859,494.394409 242.859100,493.707092 242.144043,491.922577 
	C241.888077,489.094482 243.357544,487.635040 245.486145,487.734344 
	C249.245178,487.909729 252.006302,486.409302 254.461807,483.841278 
	C254.914688,483.367676 255.661087,483.432465 256.658569,483.772430 
	C258.242645,484.451172 259.544037,484.785187 260.714478,485.377808 
	C264.442108,487.265076 267.685486,488.611694 268.228699,482.299774 
	C268.442749,479.812592 270.656830,478.974121 273.088898,478.993286 
	C277.585938,479.028717 282.083862,479.029724 286.580566,478.974548 
	C287.825073,478.959259 289.601746,479.336884 289.670471,477.399109 
	C289.739868,475.443085 288.110229,475.019226 286.517700,475.049469 
	C285.034882,475.077667 283.478790,475.078552 282.089752,475.517761 
	C277.212402,477.060059 272.550598,474.891449 267.775146,474.879303 
	C267.091248,474.877563 266.471161,474.492004 266.362335,473.725006 
	C266.213013,472.672791 266.929138,472.082642 267.794922,472.120148 
	C272.647430,472.330139 277.297516,467.940704 282.507812,472.365509 
	C285.124329,474.587555 288.885651,472.854675 291.624756,470.582703 
	C291.995422,470.275299 292.400177,469.868011 292.525360,469.431335 
	C292.952881,467.939758 286.360779,459.808655 284.852539,460.128662 
	C282.688385,460.587830 279.859650,460.742004 280.618103,464.236786 
	C281.270294,467.241974 278.600739,468.454437 277.264801,467.754578 
	C270.798065,464.366882 264.668610,468.019318 258.415741,468.056854 
	C253.851654,468.084229 249.432785,467.985870 245.504288,465.264313 
	C244.967712,464.892609 244.105698,464.323883 243.712509,464.511871 
	C237.782242,467.346741 231.179001,463.082031 225.317276,466.304230 
	C223.618011,467.238281 221.732849,467.121185 219.934784,466.951630 
	C218.093887,466.778076 214.658234,467.843750 214.794754,465.874756 
	C214.954788,463.566620 216.743561,460.184021 220.039978,460.141907 
	C224.074768,460.090393 226.661194,457.260651 229.991562,455.917267 
	C232.052155,455.086090 232.472260,453.244232 231.496826,451.337891 
	C230.524994,449.438568 229.038651,448.826508 226.951523,449.980988 
	C225.657654,450.696625 224.186066,451.116333 222.761215,451.563721 
	C220.751190,452.194916 218.899811,451.678467 218.291458,449.639984 
	C217.559906,447.188660 219.617905,446.387024 221.460709,446.173431 
	C223.236832,445.967529 225.087616,446.255096 226.889832,446.462799 
	C229.178101,446.726440 231.462708,446.392670 232.639130,444.426270 
	C234.647568,441.069092 236.241135,442.522797 237.951035,444.468750 
	C239.750977,446.517242 241.778717,448.340210 244.544495,448.635101 
	C248.175903,449.022308 249.445557,450.915009 249.011917,454.338287 
	C248.928848,454.994019 248.972900,455.670654 249.005295,456.335388 
	C249.165527,459.622742 248.645538,463.411987 253.391144,463.924805 
	C255.765213,464.181335 259.752899,459.340912 259.985870,456.218323 
	C260.188232,453.506104 259.678741,451.049408 258.194977,448.617645 
	C256.101471,445.186646 255.876953,442.069305 260.770203,440.513733 
	C263.955383,439.501190 265.294464,435.089020 269.450104,435.526611 
	C269.718689,435.554871 270.346741,434.569702 270.362091,434.043030 
	C270.385437,433.241455 269.544769,433.063080 268.889740,433.066681 
	C263.566498,433.096008 258.235352,433.004669 252.923203,433.275757 
	C249.329132,433.459167 249.099945,436.768738 248.243668,440.375000 
	C246.331329,436.782532 246.830444,434.199585 248.163544,431.710571 
	C249.176208,429.819824 248.961838,428.141022 247.584839,426.667206 
	C246.249252,425.237701 244.070038,424.212128 242.620834,425.241669 
	C236.556686,429.549866 229.767273,427.577881 223.268295,427.960083 
	C220.241577,428.138092 217.129990,427.549896 216.690598,432.036835 
	C216.518341,433.795654 214.574722,434.349762 213.118088,433.921021 
	C209.071594,432.730042 207.446579,434.504639 207.049438,438.625305 
	C207.447037,440.247528 206.685913,440.789673 205.355972,441.104187 
	C200.279053,441.074188 200.873550,443.288239 202.787827,446.246063 
	C203.992844,448.107910 206.874390,448.538391 206.992310,451.639893 
	C208.990051,454.958008 209.358093,457.693054 205.460266,459.954224 
	C203.234802,460.027893 201.606918,459.819672 201.049683,457.355560 
	C201.042648,455.262451 201.904709,452.979095 199.884598,452.135986 
	C196.743500,450.825104 195.608170,454.315582 193.243683,455.755920 
	C191.707520,461.931824 184.257126,466.014374 179.312866,463.164551 
	C177.924942,459.429260 178.042175,456.729767 182.946091,456.945343 
	C184.367493,457.007812 186.975250,457.691986 186.591354,455.144928 
	C186.292267,453.160461 185.285110,450.472931 181.960556,451.498627 
	C180.417892,451.974548 178.614731,452.743896 177.465118,451.048859 
	C176.466354,449.576233 178.658890,448.944824 178.931458,447.346680 
	C177.453369,445.536530 177.387100,443.299347 176.098465,441.325745 
	C175.967316,439.584381 176.052734,438.215881 177.700684,437.162720 
	C180.496002,435.036530 183.304840,434.819427 186.637726,435.752380 
	C190.427063,433.025757 193.481415,433.862579 196.660461,436.953247 
	C197.906265,435.982117 199.030792,435.352875 200.676849,435.032898 
	C206.848633,433.661743 207.248810,428.664032 207.884567,424.157135 
	C208.261261,421.486786 208.713165,418.920380 209.791336,416.479431 
	C213.520950,408.035675 218.268021,407.067810 224.868073,413.364471 
	C226.060776,414.502319 227.131790,416.169189 228.964111,415.910339 
	C234.926208,415.068115 239.073654,416.619415 239.724350,423.986755 
	C240.698715,419.432892 242.191513,416.243225 246.893982,415.527649 
	C248.597748,415.268372 247.901031,412.973907 247.982422,411.555115 
	C248.195557,407.839142 247.004196,403.724701 251.095306,401.172852 
	C251.225357,401.091766 251.405807,400.881012 251.377396,400.786377 
	C249.111420,393.242157 255.328613,396.956146 257.913361,395.823975 
	C259.274719,395.227692 260.563660,394.462738 261.872070,393.749695 
	C263.800720,392.698669 265.682831,392.633972 267.447601,394.057251 
	C268.236176,394.693268 268.940735,395.508575 268.747589,396.603241 
	C268.525909,397.858948 267.431671,397.878296 266.418640,397.997467 
	C265.274689,398.132050 264.084991,398.214539 263.026001,398.613739 
	C262.339539,398.872498 261.566315,399.559906 261.337006,400.228485 
	C260.925690,401.427917 261.984924,402.130737 262.912598,402.368713 
	C265.647522,403.070282 267.282349,405.249664 269.422821,406.761658 
	C273.799591,409.853455 284.702728,410.223206 288.266327,403.072113 
	C289.404663,400.787781 289.260223,399.122467 286.227722,399.035645 
	C283.065582,398.945129 279.898895,399.024780 276.735077,398.975403 
	C275.414062,398.954803 273.731964,398.986084 273.608551,397.272705 
	C273.485474,395.563904 275.245544,395.441101 276.465759,395.092773 
	C279.518127,394.221436 282.624512,393.867706 285.821045,393.971710 
	C291.832642,394.167419 297.743591,393.557465 303.317383,391.048920 
	C305.658997,389.995087 308.174927,390.083923 310.648987,389.829193 
	C311.927917,389.697540 312.843811,388.833405 312.706848,387.462708 
	C312.582092,386.214417 311.465027,385.962097 310.444305,386.046082 
	C305.983368,386.412903 301.361298,384.762085 297.309906,388.816498 
	C294.783630,391.344696 290.611938,390.488220 287.342621,389.365021 
	C285.167450,388.617737 284.268433,386.675323 287.014587,384.990082 
	C287.864441,384.468536 288.742950,383.388275 289.526581,383.459778 
	C295.840454,384.036133 301.051056,379.110809 307.384583,379.996368 
	C311.355621,380.551605 314.421631,377.357849 318.362640,377.294861 
	C315.481110,375.723114 312.391876,375.267761 309.263336,374.983704 
	C308.056488,374.874146 306.841248,374.688293 306.478821,373.347748 
	C306.158478,372.163055 307.049194,371.596710 308.007233,371.134155 
	C311.444641,369.474640 308.794891,368.341431 307.536194,367.429413 
	C302.712219,363.934387 298.656555,367.994720 295.252594,369.872803 
	C290.443787,372.525970 286.286011,371.191193 281.989380,370.131897 
	C280.128082,369.673035 278.889557,369.167603 277.793671,370.891663 
	C276.552032,372.845032 278.283234,373.434967 279.477844,374.291412 
	C280.684570,375.156494 282.196289,376.070251 281.637695,377.828583 
	C281.110657,379.487488 279.344910,378.638611 278.176666,379.038452 
	C273.341156,380.693420 269.173615,379.351379 265.139771,376.618469 
	C262.910828,375.108368 260.394592,374.403687 257.076599,375.709167 
	C260.036407,378.848511 263.105316,380.829132 267.055084,380.955627 
	C270.163330,381.055176 272.411896,382.705353 274.486877,384.604248 
	C275.703949,385.718079 276.661774,387.416382 275.188293,389.013428 
	C273.908600,390.400391 271.960144,391.635498 270.366302,390.063293 
	C265.603668,385.365387 259.362274,387.210266 253.855911,386.208282 
	C249.470322,385.410248 249.529938,384.278992 251.792175,381.363434 
	C253.884811,378.666443 254.602478,376.576752 249.780869,376.984772 
	C247.510986,377.176849 244.831146,376.863129 244.575851,374.332336 
	C244.297226,371.570404 247.176239,371.445801 249.629761,371.006531 
	C253.093185,368.780701 256.786743,370.955994 259.763702,369.430267 
	C256.885956,367.004822 253.651489,370.818756 250.256317,369.227722 
	C247.434113,369.019897 245.202087,367.811401 242.846237,367.057617 
	C241.810822,366.726318 241.085754,366.151886 241.156448,364.944305 
	C241.245773,363.418274 242.323944,362.880676 243.552856,363.064972 
	C252.770859,364.447662 262.198975,360.033997 271.405945,364.308105 
	C273.365845,365.217926 276.240631,364.122467 278.696442,364.004456 
	C289.372742,363.491425 300.241150,365.512451 310.662842,361.398346 
	C311.845001,360.931671 313.561554,361.622589 314.978821,361.980530 
	C321.885834,363.724884 328.803467,362.940979 335.632324,361.768494 
	C337.750763,361.404755 339.053040,362.173126 340.986328,362.998016 
	C341.499756,364.878235 340.339142,365.847168 338.637512,366.833923 
	C333.355804,366.711578 329.977814,368.989136 327.237091,373.030243 
	C325.898376,375.004120 324.940948,376.523865 326.084625,378.837646 
	C327.070007,380.831085 326.564911,382.484985 324.319031,383.146729 
	C319.823029,384.471375 321.213989,386.398346 323.666840,388.513611 
	C324.421967,389.164764 325.256378,389.717773 326.198822,390.689423 
	C326.581726,391.427216 326.651611,391.893188 326.490845,392.710449 
	C323.682220,395.339478 320.465973,396.372253 315.853973,396.732758 
	C319.071503,398.667694 322.035400,399.071899 323.674774,401.886841 
	C324.240021,403.490967 324.129913,404.703400 323.154358,406.116394 
	C320.992096,407.413666 319.472687,409.021088 317.529724,409.994537 
	C314.085968,411.719971 311.634399,413.422821 314.616608,417.927704 
	C316.390381,420.607178 314.232330,423.016235 311.580872,424.280731 
	C303.245483,428.255981 294.602570,430.823303 285.205200,429.569000 
	C284.569916,429.484161 283.874725,429.295319 282.826874,431.265594 
	C289.418610,430.178131 295.398407,433.483368 301.299988,429.223785 
	C306.395264,425.546051 313.509735,426.684448 316.838379,431.487305 
	C317.180664,432.005829 317.318604,432.216095 317.607300,432.777893 
	C317.811615,433.381012 317.865204,433.632599 317.921875,434.272217 
	C317.852295,435.172455 317.689148,435.653809 317.186829,436.385681 
	C314.998047,437.685791 314.293823,439.288391 313.999329,441.475372 
	C313.361633,446.210754 311.650360,447.266632 306.810699,446.482544 
	C304.277649,446.072083 301.716705,444.985382 298.959137,446.712708 
	C300.456360,449.442871 302.890259,449.272217 305.118835,449.434601 
	C307.436859,449.603516 309.802063,449.472076 311.937744,450.603088 
	C313.639648,451.504364 315.005859,452.746338 314.746399,454.884186 
	C314.484680,457.041016 312.879425,457.900024 310.955048,458.256195 
	C308.013458,458.800598 305.012695,458.309082 301.840729,458.657318 
	C304.165436,459.233612 306.284302,459.250366 308.450348,459.150299 
	C313.664825,458.909424 316.910248,462.300568 315.669525,467.209717 
	C314.093872,473.443878 314.032928,479.300446 317.387054,484.999115 
	C318.372650,486.673645 317.548401,488.512024 317.297974,490.326935 
	C316.932159,492.978577 316.254547,495.989716 319.935547,497.706573 
	C320.536835,497.926849 320.771332,498.041534 321.325989,498.394287 
	C323.135925,500.106384 323.384613,501.965759 323.256561,504.330505 
	C323.089325,512.963440 325.708893,520.670227 327.896088,528.435242 
	C331.699707,541.939026 335.993652,555.283386 341.267670,568.304932 
	C342.219025,570.653870 343.495911,572.707764 345.136230,575.044067 
	C345.656219,575.760803 345.885223,576.206848 346.180817,577.024902 
	C346.951752,583.164429 350.892334,587.486816 353.311646,592.874268 
	C353.639557,593.374390 353.758881,593.583618 354.027252,594.124146 
	C357.096161,600.937866 360.727142,607.042847 364.170502,613.246765 
	C365.212952,615.125000 366.562073,616.895020 365.670044,619.589966 
	C364.132019,621.565369 362.744415,623.209351 361.190613,625.169617 
	C359.772705,625.988770 358.412048,626.097717 357.646545,625.172180 
	C352.213715,618.603699 344.798553,619.608948 337.600372,619.755127 
	C335.526428,619.797241 332.552704,621.065796 331.891541,618.453796 
	C331.108337,615.359497 334.648621,615.241455 336.592560,614.441528 
	C340.647400,612.773193 345.235840,614.988892 349.738556,612.779358 
	C345.876007,610.357788 344.521057,607.585144 346.281494,602.910645 
	C348.036346,598.251282 343.236603,589.968445 338.646057,587.514648 
	C337.293243,586.791565 336.139618,586.989258 334.851959,587.515869 
	C332.987122,588.278503 331.159180,588.214844 329.953003,586.407959 
	C328.674896,584.493225 329.920563,582.951660 331.064880,581.476501 
	C332.079163,580.168945 333.517212,579.213562 334.447388,577.250488 
	C330.525330,575.310791 329.374237,572.130554 329.889771,567.674072 
	C330.318604,563.967285 327.271729,561.449646 324.221252,560.190796 
	C321.089325,558.898315 317.496399,557.968262 314.108551,559.972778 
	C312.794373,560.750305 311.986450,561.870728 313.516571,563.115356 
	C316.638611,565.654907 315.564972,567.493530 312.737854,569.252625 
	C311.669495,569.917419 309.965973,570.400024 310.315857,572.011353 
	C310.631134,573.463440 312.288666,573.624512 313.551971,573.990906 
	C315.323730,574.504761 317.191650,574.395203 318.985626,574.741150 
	C320.954407,575.120850 323.287231,575.507996 323.321655,578.031799 
	C323.352234,580.272583 321.219391,581.002319 319.412384,581.149292 
	C316.023651,581.424927 314.803925,583.325806 314.824799,586.259399 
	C318.158539,582.196289 322.632874,581.965088 325.099548,586.275330 
	C327.040314,589.666687 329.487915,591.548950 333.334534,591.778931 
	C334.499878,591.848511 335.623840,592.281677 336.612610,592.917725 
	C338.743225,594.288330 341.156403,595.793945 340.426788,598.721191 
	C339.617493,601.968140 336.837830,602.181763 333.968262,601.818298 
	C328.746368,601.156860 323.328461,601.974731 318.405640,599.111084 
	C316.464172,597.981628 314.593536,599.418823 313.107727,600.903137 
	C311.360565,602.648560 309.420807,603.778870 306.578644,602.532410 
	C305.149872,601.325806 304.427063,600.037781 303.748199,598.705994 
	C303.450836,598.122559 303.029114,597.548157 302.255310,597.621704 
	C301.729156,597.671692 301.391113,598.073975 301.275116,598.570374 
	C301.066467,599.463257 301.383942,600.223694 302.125549,600.717529 
	C303.244812,601.462830 304.780426,600.900391 306.104797,602.094604 
	C307.778412,603.826538 307.057404,605.861572 307.727966,608.065674 
	C310.996765,605.798889 313.871521,603.366211 317.358215,601.909912 
	C319.454224,601.034363 321.675171,600.231384 323.608459,601.297302 
	C327.286957,603.325500 331.008820,602.737366 334.804474,602.343201 
	C336.299652,602.187927 337.785339,602.051758 339.194763,602.822327 
	C340.940125,603.776428 342.058868,605.129150 341.959656,607.197266 
	C341.867584,609.116577 340.599854,610.163147 338.945770,610.798462 
	C336.711334,611.656616 334.349274,611.579102 332.026215,611.512573 
	C327.400787,611.380127 323.542450,612.860229 320.539154,616.464417 
	C318.077026,619.418945 314.905853,621.125916 311.060944,621.436584 
	C308.981934,621.604614 307.365936,622.486145 305.689270,623.735107 
	C302.462738,626.138672 299.984589,624.808472 299.670410,620.757812 
	C299.552704,619.240173 299.941193,617.789978 300.272949,616.321899 
	C301.323822,611.672119 299.112823,607.918030 294.587830,607.951111 
	C289.969482,607.984863 285.367828,609.272766 280.643646,608.368347 
	C283.325989,609.746826 286.162933,608.594971 288.889923,609.326965 
	C293.629181,610.598938 295.021973,612.380005 294.047028,617.086731 
	C293.346771,620.467468 293.629669,622.973511 297.635864,623.880615 
	C300.021667,624.420837 301.214996,626.234680 301.121307,628.717590 
	C301.022247,631.343140 299.630188,633.183411 297.176361,633.928101 
	C294.241547,634.818665 291.296082,634.747131 288.411774,633.381592 
	C287.083344,632.752625 286.158081,630.213867 284.261108,631.940613 
	C282.694244,633.366882 282.316132,635.376099 283.231628,637.458740 
	C283.504028,638.078308 283.564178,638.742126 283.217285,639.707275 
	C282.833954,640.000000 282.666656,640.000000 282.248474,640.000183 
	C279.139313,641.193726 276.347382,640.863098 273.297272,639.317261 
	C272.999908,637.932678 272.999786,636.865295 272.999207,635.397766 
	C272.062653,632.667358 273.390350,632.368896 275.609802,632.760803 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M270.749329,294.808655 
	C271.686707,297.974396 274.112976,296.788239 275.898132,296.984009 
	C279.394897,297.367432 281.305054,301.285431 281.007599,307.086639 
	C280.809723,310.945679 278.007507,310.043274 275.927856,309.931091 
	C272.790710,309.761902 268.516022,311.657623 267.718750,306.160553 
	C267.621948,305.493256 266.836182,305.037170 266.179230,305.399323 
	C265.690552,305.668732 265.241425,306.303650 265.101868,306.858124 
	C264.659241,308.616089 264.370117,310.412659 264.010223,312.596558 
	C262.725555,314.323486 261.251587,314.322601 259.338196,313.326111 
	C257.689240,311.507568 255.904434,312.112000 254.305389,311.978577 
	C252.612305,311.837311 252.123642,310.971649 252.095352,309.352631 
	C251.994308,303.567444 249.562958,301.450073 243.376038,302.086823 
	C240.922409,302.339355 239.278748,302.863586 238.493851,299.459290 
	C237.577805,295.486053 233.787613,295.961792 230.621292,296.020569 
	C227.552597,296.077515 225.347412,297.537811 225.144119,300.659424 
	C224.945389,303.711121 223.325150,304.077118 220.863770,304.027252 
	C216.042191,303.929504 211.216568,304.043884 206.394058,303.972687 
	C204.843475,303.949768 202.787552,304.608307 202.191864,302.371002 
	C201.648849,300.331482 202.166046,298.757202 204.159241,297.290466 
	C208.359924,294.199341 207.768005,290.157013 203.149887,288.197083 
	C202.100830,287.751831 199.963821,288.369781 200.596390,286.175079 
	C201.078094,284.503845 201.579987,282.446686 203.789703,282.129028 
	C205.198013,281.926605 207.431549,281.460602 207.690887,283.246948 
	C208.777695,290.733276 214.321457,288.888123 218.977203,288.996490 
	C222.948303,289.088959 226.902985,288.831543 230.857330,289.852295 
	C233.419861,290.513763 236.107559,289.931366 238.673325,288.965698 
	C241.177994,288.022980 240.915100,286.029663 241.006836,284.031464 
	C241.103989,281.915314 240.043777,281.033478 238.086700,280.835144 
	C232.174957,280.236023 226.267487,279.594238 220.355042,279.002319 
	C218.870865,278.853729 217.104355,279.079437 217.211288,276.838379 
	C217.300659,274.965668 218.861130,275.120178 220.182571,275.004242 
	C223.803635,274.686493 227.436279,273.961884 231.037643,274.099091 
	C235.249405,274.259552 239.446243,274.631683 242.021957,279.218628 
	C243.776947,282.344025 247.522339,281.094360 250.338120,280.891937 
	C254.437180,280.597229 256.683167,281.915192 256.976440,286.100739 
	C257.074921,287.506256 257.381805,289.169128 259.075928,288.862732 
	C264.227539,287.931030 267.298004,290.307709 269.680420,294.829163 
	C270.166595,294.872040 270.333221,294.744049 270.749329,294.808655 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M184.104660,499.681641 
	C184.059860,504.091522 181.253082,504.949402 178.069290,504.892944 
	C174.661880,504.832550 174.591049,501.983459 175.084335,499.812714 
	C176.824112,492.156891 171.375198,492.655853 166.376007,492.049316 
	C163.275772,491.936066 162.120590,490.458435 162.375641,487.464417 
	C162.673492,486.905518 162.837128,486.712799 163.313782,486.321960 
	C166.166641,485.092407 168.875076,485.963348 172.491058,485.302124 
	C169.379776,484.086121 166.932205,484.705353 164.368347,484.049011 
	C163.833221,483.810974 163.632431,483.686157 163.216431,483.279419 
	C161.905716,480.690155 162.630524,478.647003 163.959106,476.343353 
	C164.666107,475.337463 165.449905,475.249054 166.653259,475.762695 
	C174.360275,475.994598 174.360275,475.994598 175.547897,469.714661 
	C185.484283,475.934052 197.054947,469.462585 208.650940,474.439728 
	C197.471588,475.915375 187.317581,473.866791 177.528427,476.464478 
	C183.767105,480.195618 190.196335,482.622375 197.273438,482.994324 
	C198.898468,483.079712 200.771423,482.721497 200.980103,485.598083 
	C200.618423,488.829620 198.259644,488.343933 196.500244,488.478241 
	C192.246277,488.803070 187.965622,488.426758 182.499649,488.772552 
	C184.989212,489.608795 186.635986,489.569855 188.189270,490.716553 
	C189.066742,491.949188 189.145874,492.989807 188.729462,494.452148 
	C187.690964,496.001556 186.385605,496.235962 184.701141,496.460815 
	C183.519348,496.631683 182.425034,495.989563 181.972427,497.127991 
	C181.817764,497.516998 182.505539,497.745758 183.165268,498.130737 
	C183.785309,498.593811 184.035583,498.950867 184.104660,499.681641 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M236.615906,310.762146 
	C237.947418,308.900909 239.064301,305.589355 240.513199,306.453339 
	C243.634232,308.314423 246.665131,310.976898 247.857224,314.728638 
	C248.440918,316.565613 247.894562,318.674591 248.661560,320.603333 
	C249.071426,321.634003 248.641388,322.809753 247.420898,322.819427 
	C243.628052,322.849548 241.570343,325.228333 239.381226,327.872253 
	C237.861389,329.707886 227.453140,329.603027 225.077682,327.934387 
	C223.527817,326.845703 222.040588,325.961060 220.103378,325.989960 
	C218.490906,326.014008 216.970612,325.762207 217.145691,323.627106 
	C217.294357,321.814087 218.555984,321.044159 220.234314,321.050598 
	C224.215607,321.065826 228.211166,320.992249 232.170364,321.322052 
	C233.729141,321.451904 235.105225,321.775726 236.302734,320.583221 
	C236.997467,319.891388 236.963928,319.063568 236.279358,318.391388 
	C235.072906,317.206726 233.679565,317.598480 232.138992,317.686462 
	C226.840942,317.988892 221.521927,317.928131 216.210739,317.993805 
	C213.888138,318.022491 211.269791,317.446625 211.609146,314.803955 
	C211.944778,312.190216 214.655243,313.019958 216.578323,313.348572 
	C222.337738,314.332764 228.083069,314.529510 233.820679,313.190186 
	C235.041138,312.905334 235.906937,312.224121 236.615906,310.762146 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M240.824097,498.704926 
	C240.675888,499.533691 240.079071,500.539948 240.064865,500.531769 
	C235.381927,497.842163 230.312836,500.924225 225.284302,499.271942 
	C224.480652,497.631439 225.572571,496.428650 225.482880,494.761475 
	C228.064636,491.361206 233.035828,492.643188 235.796692,487.851807 
	C229.368240,492.847443 223.770248,491.436829 218.206314,488.312744 
	C216.709030,487.472015 215.026642,487.578583 213.356842,487.720367 
	C211.757675,487.856110 210.021729,488.417877 208.920380,486.337708 
	C211.551285,481.629456 216.211426,481.678741 218.904282,483.955353 
	C225.705902,489.705414 230.689026,485.033661 237.734329,482.268860 
	C232.085526,480.780396 229.270981,478.404266 229.564011,473.376526 
	C229.712341,470.831512 229.320328,468.085754 232.281555,467.245789 
	C235.353851,466.374329 236.110504,469.047943 236.954376,471.634705 
	C236.652573,472.364746 236.206314,472.474121 235.727142,472.575684 
	C234.958267,472.738678 233.368546,471.714050 233.588669,473.279785 
	C233.832489,475.013947 235.366211,473.844604 236.683792,473.880920 
	C238.295593,476.550507 243.237976,478.308258 238.776337,482.152222 
	C238.641357,482.268463 238.924622,483.056732 239.180618,483.410248 
	C240.973389,485.886108 248.972946,487.021729 251.295807,485.032837 
	C252.517151,483.987122 254.075089,482.677704 251.343475,481.067657 
	C249.094482,478.515564 251.014938,475.155090 249.045898,472.336517 
	C251.596649,468.037415 252.833984,471.278168 253.817673,473.128632 
	C255.483398,476.262085 254.571442,480.255463 256.947876,483.623962 
	C252.790756,486.250000 249.283783,490.172272 243.825836,489.539734 
	C243.630203,489.517059 243.303833,490.622559 243.018997,491.601746 
	C242.675354,492.365021 242.276474,492.614990 241.815170,492.763824 
	C236.985382,494.321777 236.912979,494.670624 240.824097,498.704926 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M159.483368,392.896515 
	C153.946869,392.773590 152.270416,386.046326 146.835083,386.460632 
	C146.618546,386.477142 146.095840,385.066833 146.050964,384.301636 
	C145.847473,380.831757 144.830536,377.997437 141.666779,375.938080 
	C139.669724,374.638153 139.116516,365.457123 140.626526,363.187012 
	C142.710281,360.054291 145.814484,360.806122 148.728790,361.459717 
	C150.294678,361.810913 151.918152,362.257019 152.023666,364.530090 
	C152.127014,366.755951 151.233383,367.845245 149.181808,368.492889 
	C148.221725,368.796021 146.512268,368.646118 146.579437,370.255615 
	C146.640121,371.709198 148.024109,372.017090 149.188431,371.944122 
	C153.890747,371.649353 153.189209,374.897552 152.843811,377.640961 
	C152.099274,383.554657 153.198975,384.904663 159.301254,384.997131 
	C160.463181,385.014740 161.625610,385.004822 162.787766,384.999268 
	C165.445831,384.986572 168.198166,384.460114 168.071121,388.776398 
	C167.959808,392.558044 165.928787,393.191101 162.891205,393.005951 
	C161.898117,392.945374 160.899857,392.969757 159.483368,392.896515 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M305.251160,318.191711 
	C302.263885,318.124969 299.419769,317.490814 296.881714,319.063049 
	C295.312683,320.035034 292.718048,320.345886 293.289795,322.976044 
	C293.818207,325.406708 295.874725,326.123993 298.608063,325.998047 
	C299.661896,326.663940 299.750671,327.448273 299.237366,328.652893 
	C298.363220,329.792725 297.725220,330.587860 297.043030,331.692749 
	C293.228729,327.156525 289.097992,323.090332 282.590302,321.801422 
	C280.073700,321.302979 279.785156,318.070892 279.998444,315.436035 
	C280.199860,312.948334 281.408539,311.311646 283.867462,311.066406 
	C286.478821,310.806000 286.731812,308.793030 287.550293,307.019379 
	C289.579376,302.622437 291.916199,301.980255 296.811920,304.677673 
	C296.563507,306.141785 294.143372,305.926239 294.682037,307.026337 
	C295.578308,308.856781 297.321136,310.341827 299.450317,310.867584 
	C302.091583,311.519806 304.627930,310.525391 307.595032,310.014648 
	C308.667603,310.668793 309.367584,311.313293 309.158752,312.686646 
	C307.612915,314.144104 305.401794,314.707428 306.001343,317.625732 
	C305.833679,318.126984 305.667358,318.255219 305.251160,318.191711 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M208.555267,485.999084 
	C215.334671,485.103394 220.795532,488.093597 226.541824,489.880188 
	C229.459702,490.787354 231.502014,488.891968 233.656876,487.271240 
	C234.924011,486.318176 236.744659,485.195099 238.095367,486.950623 
	C239.635559,488.952484 237.008545,489.216583 236.253448,490.236328 
	C233.841019,493.494293 230.025513,493.648987 226.261292,494.736267 
	C220.818832,497.311005 215.758240,498.263702 211.095779,493.819031 
	C210.277893,493.039307 209.385529,492.251801 207.707123,492.376648 
	C206.772720,492.416901 206.230316,492.442902 205.260468,492.526611 
	C199.440994,492.530792 193.968628,493.770142 188.354355,491.289429 
	C185.859573,490.893738 183.701294,490.920746 181.590561,490.617126 
	C180.506653,490.461243 179.168152,489.852722 179.340958,488.412201 
	C179.526855,486.862305 180.965714,487.063660 182.090561,487.011627 
	C188.185196,486.729614 194.306595,487.531036 200.692032,486.279724 
	C203.369980,485.998993 205.740570,485.999207 208.555267,485.999084 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M171.434265,409.100983 
	C168.353806,409.844727 165.198517,411.116943 167.315033,406.042480 
	C168.461456,403.293854 165.872070,403.183350 164.525360,402.277924 
	C163.163589,401.362396 161.176666,400.735992 161.475037,398.791779 
	C161.790375,396.737122 163.774887,396.496185 165.592636,395.898987 
	C170.459946,394.299774 175.209442,392.378632 180.484131,393.011963 
	C181.617035,393.148010 182.883347,393.147430 183.822769,393.678925 
	C185.054611,394.375885 186.746246,395.328369 186.354492,396.978912 
	C185.953522,398.668243 184.114868,397.804779 182.897034,397.984772 
	C181.916946,398.129639 180.870255,397.976440 179.936615,398.245361 
	C178.697037,398.602356 177.178574,398.999207 177.265320,400.707825 
	C177.347870,402.333771 178.618317,402.854858 180.061859,402.956635 
	C181.055450,403.026764 182.057465,402.994263 183.047729,403.090118 
	C184.196381,403.201355 184.847549,403.863861 184.821976,405.053070 
	C184.802216,405.971649 184.336334,406.886353 183.431778,406.785309 
	C179.305923,406.324554 175.886734,409.304230 171.434265,409.100983 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M239.300140,373.965057 
	C240.005280,375.859406 240.960541,377.468933 240.867065,379.015015 
	C240.653412,382.548676 242.505554,384.315948 245.451477,385.152893 
	C248.403000,385.991394 249.359634,387.734589 249.008270,390.650391 
	C248.790741,392.455536 247.809219,393.585114 246.219635,393.572845 
	C243.523117,393.552063 242.220825,391.910889 241.804184,389.180023 
	C241.337845,386.123352 239.360611,384.894470 236.134384,384.931915 
	C230.800491,384.993835 226.395096,381.263153 225.217834,376.103668 
	C224.887375,374.655396 224.551819,372.912964 226.186325,372.083069 
	C228.315720,371.001923 230.315186,368.578705 232.885284,369.730255 
	C235.060303,370.704834 237.672882,371.325409 239.300140,373.965057 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M159.322174,446.728943 
	C161.726456,449.114502 161.865952,451.817169 159.538910,453.318970 
	C157.357452,454.726837 154.364395,454.921478 152.944656,451.472900 
	C152.217880,449.707550 150.849792,449.797913 149.783386,450.891510 
	C147.210754,453.529846 145.398376,453.440857 144.027435,449.823273 
	C143.839905,449.328369 142.961716,448.780914 142.408813,448.786713 
	C135.889557,448.854828 135.872452,443.327789 134.349014,439.284637 
	C133.526001,437.100372 133.503403,434.594604 135.743256,432.929718 
	C137.801254,431.399994 140.215652,431.869141 142.475601,432.251221 
	C144.231323,432.548035 144.027222,434.230591 143.964874,435.504944 
	C143.856522,437.720245 144.262970,439.831299 145.112976,441.822845 
	C147.055374,446.373840 150.529434,447.967957 155.113937,446.460968 
	C156.448868,446.022186 157.698792,445.933472 159.322174,446.728943 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M150.285950,414.000610 
	C156.900375,414.000031 163.048248,413.943542 169.194061,414.032104 
	C171.213379,414.061218 174.191269,413.152222 174.615036,416.053436 
	C175.054749,419.063782 172.245926,421.444733 169.923676,421.612671 
	C162.325027,422.162292 154.663162,422.027283 147.032852,421.847809 
	C144.336792,421.784363 145.079117,419.223480 145.047043,417.528137 
	C144.984222,414.208221 147.298492,413.924133 150.285950,414.000610 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M205.666916,413.809265 
	C203.635239,416.187561 201.561066,416.438019 199.209305,415.098572 
	C197.732681,414.257568 196.125183,414.074371 194.437317,413.963837 
	C189.258545,413.624756 187.424545,409.726105 190.181259,405.271698 
	C191.240753,403.559692 192.298157,404.290802 193.492065,404.981995 
	C196.431549,406.683807 199.216187,406.568451 201.532562,403.925964 
	C203.008087,402.242737 204.965897,402.272339 206.875854,401.770264 
	C208.956268,401.223358 210.472305,401.376068 211.497787,403.303253 
	C212.520172,405.224609 211.682541,406.732239 210.332352,408.200623 
	C208.771973,409.897614 207.385086,411.754150 205.666916,413.809265 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M338.308411,366.281311 
	C339.516876,365.349976 340.329742,364.487671 341.247864,363.339539 
	C342.710052,360.989838 344.067047,358.925995 345.527893,356.521576 
	C345.870880,355.831055 346.110016,355.481079 346.375244,354.924347 
	C351.911926,348.320465 357.430695,341.930389 362.927704,335.521698 
	C364.461639,333.733368 366.156586,332.173828 368.889343,331.336151 
	C369.488983,331.640076 369.548615,331.837921 369.596497,332.346954 
	C369.520386,332.856293 369.456024,333.054413 369.237579,333.523865 
	C368.711853,334.299042 368.365845,334.818848 367.944244,335.669739 
	C366.053467,338.495972 364.333984,341.054382 361.828064,343.406982 
	C360.499054,344.417084 359.746033,345.467072 359.030823,346.960388 
	C358.570557,347.840271 358.197662,348.384247 357.579224,349.152344 
	C356.638672,350.140900 355.533112,350.492249 354.959137,351.834167 
	C354.324158,352.814209 353.744080,353.428772 352.911224,354.234558 
	C352.525360,354.578094 352.367645,354.709198 351.969513,355.025085 
	C351.038757,355.634918 350.536591,356.218842 350.107849,357.239075 
	C349.877472,357.671478 349.778046,357.840027 349.510559,358.247253 
	C348.996613,358.941650 348.576080,359.339294 348.140991,360.081543 
	C347.783997,360.633148 347.564636,360.936401 347.169495,361.454346 
	C346.789612,361.977325 346.584320,362.284149 346.260376,362.847656 
	C345.960297,363.438202 345.774658,363.770782 345.414154,364.339203 
	C344.606171,365.315155 343.989136,366.069611 343.373108,367.193726 
	C343.149963,367.645599 343.056885,367.825775 342.821533,368.276855 
	C342.490417,368.915741 342.305939,369.285950 342.005432,369.953735 
	C341.262848,371.644806 340.835052,373.115082 340.131104,374.840576 
	C339.789703,375.804810 339.455627,376.414032 339.236237,377.446289 
	C338.954865,378.258270 338.706207,378.685303 338.102539,379.320709 
	C337.044739,379.881927 336.275726,379.907623 335.138977,379.484314 
	C334.408081,378.887939 334.122253,378.430511 333.822723,377.521240 
	C333.646240,376.586243 333.604645,376.044647 333.588318,375.094879 
	C333.642517,371.191315 335.431152,368.590881 338.308411,366.281311 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M174.109711,462.396790 
	C174.289169,465.999207 173.293762,466.314911 169.827682,466.495453 
	C164.994843,466.747101 160.219955,467.901520 155.282074,467.050201 
	C153.801132,466.794891 152.312485,466.744843 150.985229,465.958191 
	C149.948486,465.343689 148.955338,464.634705 149.319016,463.229248 
	C149.641830,461.981842 150.785141,462.027649 151.764297,462.025116 
	C153.312393,462.021118 154.826630,461.873901 155.856796,460.591339 
	C157.826157,458.139435 159.978088,457.563873 162.778885,459.320557 
	C165.359634,460.939270 167.504501,459.056976 169.782349,458.141174 
	C171.548035,457.431305 173.421234,455.139740 175.164948,456.621887 
	C177.116776,458.280945 174.392563,460.136963 174.109711,462.396790 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M130.376053,409.291748 
	C125.354820,410.916168 120.506981,409.773560 115.748489,409.870209 
	C114.014084,409.905426 112.958923,408.666626 113.156120,406.904297 
	C113.416817,404.574341 115.419159,403.862000 117.186531,404.134827 
	C120.956589,404.716858 124.776497,404.688019 128.501831,405.245148 
	C131.288620,405.661926 134.150681,405.566956 136.841278,406.236511 
	C141.044449,407.282471 144.711136,407.961700 146.439011,402.566833 
	C146.977325,400.886017 148.779007,400.705231 150.260071,401.349670 
	C152.384842,402.274170 151.944000,404.333374 151.978561,406.127258 
	C152.012711,407.900604 151.300110,408.971252 149.375824,408.970856 
	C145.228317,408.969971 141.080795,408.989227 136.465729,409.000732 
	C134.382172,410.400818 132.638412,410.646362 130.376053,409.291748 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M239.078705,394.131134 
	C232.492050,396.725250 226.189117,399.046539 219.261429,398.030579 
	C217.683762,397.799194 215.086258,400.106628 215.009903,396.242218 
	C214.947403,393.078766 216.278122,391.092865 219.584290,391.041779 
	C225.373962,390.952271 231.166092,390.989471 236.956741,391.033020 
	C237.743729,391.038940 238.669662,391.190613 239.277649,391.627655 
	C240.024429,392.164429 239.811279,393.085815 239.078705,394.131134 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M216.230988,332.994293 
	C217.174362,332.999390 217.687012,332.921722 218.166565,333.014679 
	C219.998840,333.369873 222.882477,331.889160 223.460159,334.223083 
	C224.107910,336.840118 220.805374,335.750092 219.457764,336.707733 
	C218.163666,337.627319 217.092209,338.502167 216.841705,340.096252 
	C216.267029,343.753204 213.819809,344.033783 210.688126,344.100586 
	C207.277328,344.173401 207.316559,342.103241 206.678116,339.778687 
	C204.614471,332.264862 205.977997,331.189819 216.230988,332.994293 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M186.719742,428.273071 
	C184.484818,429.154297 182.745148,429.239655 181.246902,427.699524 
	C180.224594,426.648682 178.799728,425.736908 179.288040,423.959015 
	C179.789185,422.134338 181.400055,422.252258 182.893143,421.969025 
	C188.518204,420.902008 193.573273,423.917725 199.016602,424.080963 
	C200.126877,424.114258 200.941605,424.734711 200.677856,426.010559 
	C200.399261,427.358398 199.337173,427.885925 198.099380,427.925171 
	C194.444489,428.041168 190.787140,428.079926 186.719742,428.273071 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M204.957016,460.348846 
	C207.597931,458.152252 206.950241,455.397461 207.001053,452.419342 
	C207.666412,452.000916 208.331055,451.994385 208.995560,452.001404 
	C211.975250,452.032776 215.674500,451.563019 216.635117,455.079620 
	C217.705887,458.999481 213.775742,460.608429 211.330170,462.643829 
	C210.363403,463.448456 208.892181,463.631134 207.676758,464.157318 
	C206.452835,464.687134 205.030777,465.580902 203.956268,464.557343 
	C202.350250,463.027557 205.042831,462.123444 204.957016,460.348846 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M186.982086,435.608734 
	C184.344925,436.826782 181.637100,437.216980 178.427856,436.998169 
	C178.424423,433.228912 176.316956,431.997833 172.985275,431.999298 
	C171.014481,432.000153 169.239578,431.616547 169.117767,429.199768 
	C168.985947,426.584747 170.952026,425.538879 172.923355,424.573822 
	C175.627579,423.250031 175.760971,425.561890 176.594421,427.077271 
	C178.383759,430.330719 180.439560,433.288269 184.823441,433.061523 
	C186.225067,432.989014 186.898880,433.856628 186.982086,435.608734 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M271.450867,295.000763 
	C270.998779,295.001221 270.499390,295.000214 270.249695,295.000122 
	C269.235443,290.019836 271.663544,288.530945 276.270447,288.957916 
	C280.454620,289.345673 284.546631,289.910065 288.471558,291.669281 
	C283.149536,294.383392 277.627808,295.219208 271.450867,295.000763 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M258.848389,313.294220 
	C260.388580,312.999054 261.777161,312.999359 263.582275,312.999207 
	C264.447083,312.795807 264.876801,312.508148 265.346985,312.405029 
	C267.066040,312.028015 269.317261,311.112762 270.272156,312.867737 
	C271.181763,314.539459 268.886536,315.566711 267.794495,316.622223 
	C266.075623,318.283630 264.832886,320.636322 262.061340,320.971832 
	C259.569916,321.273407 257.940887,319.882416 256.761658,318.063782 
	C255.316528,315.835022 258.021179,315.083252 258.848389,313.294220 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M203.553864,369.095612 
	C205.959549,370.090973 208.964478,370.629120 208.398849,373.473877 
	C207.930527,375.829285 205.297638,376.708069 202.906097,376.947388 
	C199.928299,377.245361 197.228027,376.425537 195.643951,373.775360 
	C194.613525,372.051422 196.363541,371.147766 197.521057,370.314789 
	C199.198868,369.107422 201.144028,368.835480 203.553864,369.095612 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M164.227234,486.762177 
	C163.834351,487.000000 163.667480,487.000000 163.250305,487.000000 
	C157.136139,489.467438 151.258026,490.403229 145.058075,486.821533 
	C144.504288,485.930817 144.425507,485.356567 144.769867,484.377380 
	C145.000610,483.998779 145.000320,483.500000 145.000153,483.250610 
	C145.989960,482.180176 147.068832,481.931091 148.635910,482.743744 
	C153.355988,482.996429 157.713791,482.997742 162.536407,482.998291 
	C163.167892,482.998016 163.334549,482.998444 163.751221,482.999451 
	C164.151047,483.157928 164.300888,483.315887 164.622894,483.752960 
	C164.923126,484.455078 164.972488,484.887726 164.877594,485.648376 
	C164.685226,486.153503 164.569229,486.338928 164.227234,486.762177 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M337.423035,283.000000 
	C336.383301,278.535950 338.079041,276.416840 342.803558,276.957428 
	C345.095917,277.219727 347.440369,277.007599 349.760315,277.055023 
	C351.148865,277.083374 351.899200,277.839600 351.877869,279.244171 
	C351.861511,280.323975 351.136108,281.755768 350.383179,281.482452 
	C345.879852,279.847412 342.176361,283.972717 337.423035,283.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M297.359375,284.061646 
	C292.466339,284.586670 288.109894,283.020203 283.628113,282.865662 
	C282.188385,282.816010 280.224457,282.314667 280.398926,280.466614 
	C280.598969,278.348022 282.706970,279.063324 284.065308,279.032806 
	C288.063171,278.942871 292.064331,279.000275 296.532104,279.000000 
	C297.928894,279.873322 298.407806,280.958038 298.507263,282.584412 
	C298.381439,283.435028 298.127594,283.830872 297.359375,284.061646 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M360.651672,264.003418 
	C364.577789,264.117004 368.639557,262.774353 369.614990,268.540741 
	C370.011627,270.885406 372.593689,269.524506 374.632263,269.049438 
	C376.434387,271.859253 373.688690,272.803925 372.252319,274.730713 
	C370.857574,274.917847 369.394226,275.090851 369.198090,273.579559 
	C368.804871,270.549561 366.934540,269.603485 364.312561,270.044983 
	C361.352386,270.543457 361.086884,267.673004 359.219482,266.283112 
	C358.534821,264.748199 359.436890,264.390167 360.651672,264.003418 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M193.398132,456.053955 
	C192.970779,452.287811 196.306839,451.250885 198.525177,449.576691 
	C199.927856,448.518097 201.161209,450.551025 201.995850,451.657135 
	C203.262390,453.335510 203.800598,455.226013 201.357834,456.921417 
	C198.558517,457.036011 196.104111,457.149261 193.398132,456.053955 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M338.873901,257.260284 
	C339.750153,256.998260 340.500275,256.999023 341.625519,257.000488 
	C342.968201,257.912537 343.890747,258.843353 343.229004,260.679749 
	C340.297577,260.877197 337.001129,258.662750 336.310455,264.078308 
	C336.087830,265.824066 332.443420,265.244019 330.503571,264.732880 
	C328.226807,264.132965 329.092896,262.238953 329.845947,260.347656 
	C331.804352,258.259399 334.008453,258.375122 336.622894,258.824646 
	C337.581787,258.506866 338.164795,258.014954 338.873901,257.260284 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M248.643814,472.007690 
	C253.727814,473.740692 251.348114,477.231262 251.009827,480.608643 
	C248.613464,480.711121 246.734818,479.230225 244.313950,478.142517 
	C243.154282,477.012360 243.056900,475.921814 243.776611,474.374847 
	C245.749588,474.030457 246.429840,471.748230 248.643814,472.007690 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M166.985474,475.623291 
	C166.248337,475.999176 165.499100,475.999603 164.375244,476.000610 
	C160.379837,475.991425 156.758240,476.023315 153.138733,475.950500 
	C151.764511,475.922852 150.225952,475.482361 150.484848,473.785736 
	C150.758102,471.994873 152.259262,472.125488 153.587143,472.752045 
	C155.993805,473.887543 158.286621,473.449463 160.714569,472.674774 
	C163.362839,471.829773 165.963531,471.785736 166.985474,475.623291 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M297.000916,284.375916 
	C297.311157,283.881775 297.566681,283.689911 297.884521,283.213379 
	C298.427765,282.184052 299.229095,281.826965 300.258362,281.244720 
	C301.146515,280.816010 301.769867,280.640411 302.720032,280.834595 
	C303.162720,280.980469 303.325348,280.935303 303.485535,280.942719 
	C305.246735,281.024078 306.784698,277.810669 308.126678,279.661041 
	C309.653503,281.766296 305.903839,281.765320 305.423645,283.406433 
	C305.333038,283.716034 305.202240,284.013885 305.046814,284.661011 
	C303.463501,288.674408 300.238739,286.312927 297.432587,286.998810 
	C297.000214,286.249390 297.000397,285.500000 297.000916,284.375916 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M250.000046,369.333130 
	C254.881302,367.298035 259.763519,365.578796 264.711578,370.283875 
	C259.935486,371.332977 255.451782,371.987518 250.408813,371.000885 
	C250.000443,370.554596 250.000275,370.110443 250.000046,369.333130 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M175.357010,305.368744 
	C178.083191,308.109924 176.990799,310.201935 174.470566,311.529510 
	C172.117249,312.769196 169.861176,311.481354 169.389221,309.198700 
	C168.375671,304.296539 172.001221,304.987946 175.357010,305.368744 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M318.935120,277.648163 
	C317.289093,277.997559 315.573669,277.998779 313.429749,278.000000 
	C313.005310,276.661926 312.960632,275.316711 311.717499,274.458435 
	C310.041229,273.301086 309.371948,271.894287 311.298828,270.583008 
	C313.064484,269.381470 314.913361,270.027191 316.023254,271.717987 
	C317.162170,273.453003 317.933533,275.429291 318.935120,277.648163 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M175.603271,440.999451 
	C180.113190,440.915985 178.715256,444.028412 178.998383,446.582031 
	C176.918762,447.582092 174.887680,448.137390 173.331497,445.862823 
	C172.530655,444.692291 170.756226,443.904968 171.336304,442.210602 
	C171.974930,440.345215 173.842438,441.221130 175.603271,440.999451 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M121.264175,362.865387 
	C122.701263,360.720032 123.850952,361.204865 124.839020,362.786377 
	C126.242966,365.033539 126.385010,367.508942 125.620995,369.945007 
	C125.291534,370.995453 124.047653,371.056641 123.042061,370.743561 
	C121.005363,370.109467 121.006989,368.378052 121.012306,366.696930 
	C121.015961,365.541443 121.087929,364.386200 121.264175,362.865387 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M226.064545,473.305817 
	C224.990082,474.479767 223.991837,475.041809 222.770950,474.962006 
	C220.761215,474.830658 218.184708,475.803436 217.324493,472.983978 
	C216.774384,471.180847 218.227020,469.860260 219.763245,470.177948 
	C222.058777,470.652557 226.128052,467.600006 226.064545,473.305817 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M299.000000,302.378174 
	C302.030609,302.002289 305.061188,302.000916 308.545898,301.999146 
	C310.569977,302.626587 310.987640,303.767151 310.237122,305.655609 
	C306.534088,305.769012 303.031616,306.506317 299.299957,305.277588 
	C299.000000,304.250885 299.000000,303.501770 299.000000,302.378174 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M238.082886,403.034851 
	C237.293839,402.649445 236.818466,402.478699 236.429016,402.191437 
	C235.237732,401.312836 232.626282,401.223419 233.515823,399.066071 
	C234.343109,397.059662 236.716949,398.179749 238.405182,398.039612 
	C240.053909,397.902710 241.813889,397.932648 241.947388,400.311340 
	C242.091095,402.872070 241.691971,404.773254 238.082886,403.034851 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M321.008118,249.378860 
	C323.166016,247.134384 325.764099,248.357193 328.570099,248.002396 
	C329.158417,250.807617 328.666077,253.444458 325.377777,253.842026 
	C322.702942,254.165405 321.274139,252.403732 321.008118,249.378860 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M291.382996,377.070435 
	C292.621185,374.216370 295.791992,374.164185 297.397827,371.897156 
	C298.288971,370.639160 299.835693,371.800415 299.531525,373.075653 
	C299.045380,375.113922 297.792114,376.988983 296.033600,378.234009 
	C294.373260,379.409607 292.648193,379.388000 291.382996,377.070435 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M268.335907,283.021912 
	C269.219208,283.007477 269.711945,282.979828 270.200684,283.012329 
	C271.820404,283.119995 274.109619,283.089813 274.205444,284.896515 
	C274.306183,286.795837 272.023468,286.808777 270.432922,287.006653 
	C269.786713,287.087097 269.096893,287.089355 268.511078,287.331085 
	C266.887268,288.001190 265.955139,287.294708 265.535400,285.835114 
	C264.948120,283.792847 266.359772,283.301178 268.335907,283.021912 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M233.857544,406.317383 
	C232.571289,406.709808 231.580750,407.028625 230.617783,406.960083 
	C228.768982,406.828369 226.218353,407.850037 225.552811,405.161743 
	C225.136032,403.478333 226.963074,402.814484 228.230301,403.109039 
	C230.383270,403.609467 234.350433,400.858307 233.857544,406.317383 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M220.169159,383.933380 
	C217.134598,382.827972 217.767975,380.689331 218.504257,378.966797 
	C219.081558,377.616211 220.782639,377.973907 222.066086,378.112305 
	C223.807724,378.300110 223.906876,379.633759 223.977982,381.011475 
	C224.105713,383.485962 222.709198,384.129089 220.169159,383.933380 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M143.759979,459.352814 
	C144.110992,462.049316 144.189651,464.281372 140.826691,463.922150 
	C138.475342,463.671021 137.548904,461.967468 138.169525,460.046326 
	C139.193832,456.875580 141.475662,457.716705 143.759979,459.352814 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M238.338989,336.957458 
	C236.610672,337.000153 235.221970,336.999634 233.416626,336.998352 
	C233.538239,332.741394 236.143417,330.781708 238.920456,332.682098 
	C240.908661,334.042664 241.854263,335.684204 238.338989,336.957458 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M372.444153,274.999054 
	C371.391876,272.379883 373.882996,271.437439 374.937683,269.343842 
	C375.444733,269.003296 375.888947,269.002930 376.666290,269.003113 
	C378.370636,270.655731 381.622253,271.091553 380.236389,274.686340 
	C377.629425,274.998962 375.258881,274.999146 372.444153,274.999054 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M343.430542,260.997681 
	C342.693848,259.894531 342.385223,258.791504 342.038635,257.344849 
	C342.000610,257.001221 342.499695,256.998718 342.749237,256.997528 
	C343.391602,256.558716 343.843048,256.209473 344.710571,256.025330 
	C346.387573,255.999084 347.776123,256.000366 349.581116,256.003265 
	C349.694611,257.426941 349.391693,258.849030 349.043152,260.634338 
	C347.284576,260.997620 345.571625,260.997711 343.430542,260.997681 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M237.000504,473.666687 
	C235.690857,474.742462 235.839584,478.214142 233.228638,476.328766 
	C231.620712,475.167633 230.587708,472.688293 231.484558,471.127533 
	C233.066010,468.375427 234.694092,471.300171 236.649841,471.931183 
	C236.999725,472.444458 237.000061,472.888885 237.000504,473.666687 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M380.392517,274.997986 
	C380.608307,272.379822 378.116882,271.437531 377.062317,269.343842 
	C378.417511,269.405457 379.928711,269.618988 381.227448,270.261871 
	C382.530945,270.907104 385.006104,270.505524 384.686584,272.931427 
	C384.403625,275.079956 382.439178,274.952332 380.392517,274.997986 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M130.004791,409.408569 
	C131.715591,409.001221 133.428741,409.001221 135.570038,409.001221 
	C135.762985,410.401367 138.165192,412.761383 134.635590,412.956268 
	C132.369293,413.081421 129.758347,413.494019 130.004791,409.408569 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M162.677826,432.541077 
	C161.601807,433.591980 160.586609,435.567719 159.556519,434.046844 
	C158.425049,432.376221 160.107590,430.411011 160.769775,428.642517 
	C161.142776,427.646332 161.993546,426.928558 163.021011,427.833313 
	C164.669632,429.285004 163.534164,430.786896 162.677826,432.541077 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M205.008911,441.376038 
	C205.582870,440.508942 206.165115,440.016632 206.873993,439.262177 
	C208.221115,439.382904 210.657776,438.153442 210.324432,440.486420 
	C210.119003,441.924164 209.549988,444.362427 207.516022,444.710052 
	C205.669662,445.025604 205.103180,443.388062 205.008911,441.376038 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M168.088226,440.734253 
	C167.614014,444.867981 164.896439,442.673340 163.085358,442.588257 
	C162.095276,442.541748 161.717163,440.917206 162.367676,440.168457 
	C164.183304,438.078613 166.092010,438.920990 168.088226,440.734253 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M148.998474,482.589905 
	C147.932007,482.996735 146.865845,482.998352 145.399841,483.000610 
	C145.000214,481.286499 145.000427,479.571747 145.000946,477.428528 
	C148.881897,476.763550 149.150330,479.312256 148.998474,482.589905 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M275.923218,319.855927 
	C276.308502,321.002319 277.350861,322.234772 275.840912,322.481354 
	C274.389130,322.718445 272.989563,321.766815 272.354248,320.305389 
	C271.896698,319.252808 271.655212,317.882111 273.060425,317.390350 
	C274.825684,316.772614 275.070129,318.552094 275.923218,319.855927 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M336.905914,258.627441 
	C335.075470,260.192291 332.930206,260.012665 330.405884,259.998230 
	C331.159058,255.066910 333.986572,256.663452 336.905914,258.627441 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M200.958344,434.661896 
	C200.771500,437.063232 199.236511,436.991425 197.374924,436.994263 
	C197.133240,435.325531 196.486221,433.250977 198.649231,432.483826 
	C199.969650,432.015442 200.583893,433.168610 200.958344,434.661896 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M167.236389,470.714294 
	C167.169846,467.781067 168.714005,467.764771 170.519638,468.466156 
	C171.340347,468.784943 171.935440,469.663788 171.457123,470.471954 
	C170.412186,472.237518 168.951630,472.478790 167.236389,470.714294 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M301.218079,281.650330 
	C300.442139,282.844025 299.586426,282.988037 298.336487,282.997498 
	C296.858490,282.157410 297.027252,280.912750 297.000305,279.363892 
	C298.066681,278.999756 299.133331,278.999512 300.600006,278.999634 
	C301.585785,279.682953 301.711426,280.453186 301.218079,281.650330 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M391.913818,278.632843 
	C389.858612,278.824921 387.659821,279.417542 385.282288,278.271179 
	C387.333710,276.592773 389.630310,276.127716 391.913818,278.632843 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M241.019623,409.236877 
	C239.579376,410.027283 238.459961,410.091614 238.214523,408.508942 
	C238.056335,407.488800 238.694565,406.462738 239.776321,406.450104 
	C241.475815,406.430267 242.039566,407.409149 241.019623,409.236877 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M127.540009,458.665558 
	C129.756073,459.441345 129.008453,460.893280 128.441360,462.226959 
	C128.362503,462.412384 127.427254,462.501526 127.151642,462.290863 
	C125.649368,461.142761 126.507584,459.972290 127.540009,458.665558 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M361.001038,263.665649 
	C360.442535,264.508484 359.883881,265.018158 359.162598,265.763916 
	C358.451508,265.823090 357.903015,265.646179 357.177246,265.234619 
	C357.732147,263.595337 358.221039,261.921356 360.652893,262.007141 
	C361.000275,262.444183 361.000580,262.888336 361.001038,263.665649 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M284.163422,286.953491 
	C283.089386,286.670868 281.925507,286.310638 283.019775,285.467590 
	C283.966003,284.738586 285.664673,284.375732 286.241974,285.950653 
	C286.608246,286.949799 285.235809,286.826599 284.163422,286.953491 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M203.595917,316.480530 
	C202.203552,316.446503 201.983337,315.717255 202.377914,314.859070 
	C202.678955,314.204315 203.466278,313.973541 204.041382,314.360931 
	C204.999878,315.006531 204.504684,315.731415 203.595917,316.480530 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M243.705841,474.160156 
	C244.000000,475.066681 244.000000,476.133331 244.000000,477.600006 
	C241.068970,477.210724 241.917908,475.817078 243.705841,474.160156 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M297.654968,299.007568 
	C297.741058,299.494934 297.482086,299.991058 297.111572,300.744202 
	C296.352173,301.690063 295.511414,301.937500 295.265228,300.863037 
	C294.920685,299.359253 296.119415,299.082001 297.654968,299.007568 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M301.256836,281.889557 
	C301.000000,281.250916 301.000000,280.500580 301.000000,279.375122 
	C301.894318,279.095215 302.662262,279.365692 302.950989,280.685852 
	C302.505371,281.260101 302.009521,281.519012 301.256836,281.889557 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M298.003540,289.321869 
	C298.581970,289.492523 299.165161,289.984619 299.874481,290.739594 
	C298.066803,292.410645 298.181519,290.815979 298.003540,289.321869 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M178.999695,463.000000 
	C178.705597,463.490845 178.411819,463.981720 178.118042,464.472595 
	C177.918655,464.301666 177.719254,464.130768 177.519852,463.959869 
	C177.831223,463.666473 178.142593,463.373077 178.726685,463.039856 
	C178.999390,463.000000 179.000000,463.000000 178.999695,463.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M332.642578,252.569794 
	C333.388947,252.360596 333.659424,252.621765 333.543945,253.201187 
	C333.519958,253.321579 333.349274,253.483490 333.227631,253.501633 
	C332.642303,253.588898 332.397064,253.304123 332.642578,252.569794 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M283.440033,640.000000 
	C278.464203,636.330383 281.890381,632.718628 283.283386,629.280273 
	C284.177948,627.072266 285.959503,628.163025 286.638855,629.559021 
	C288.644531,633.680237 292.246338,633.106323 295.700043,632.946777 
	C298.462341,632.819214 299.916992,631.211365 299.961853,628.437256 
	C300.003815,625.842285 298.426147,625.211914 296.113953,624.944458 
	C292.230072,624.495239 290.980530,621.885986 292.121979,617.278992 
	C293.338135,612.370483 290.578491,609.518066 285.261780,610.470032 
	C283.436127,610.796936 281.667633,611.217102 279.869904,610.745544 
	C278.780334,610.459717 277.445343,610.094421 277.308716,608.710754 
	C277.134125,606.943237 278.985168,606.035889 279.875031,606.310913 
	C285.332886,607.997986 290.745087,605.779480 296.052673,606.361572 
	C301.127472,606.918152 303.515381,611.903320 301.698059,616.575623 
	C301.067444,618.196899 300.979095,619.796875 301.032196,621.459106 
	C301.065308,622.495605 301.247620,623.510986 302.457001,623.785645 
	C303.522522,624.027527 304.515381,623.757019 304.872070,622.616882 
	C305.731140,619.871094 307.924988,619.998108 310.132782,619.989990 
	C313.998901,619.975708 317.102722,618.633057 319.471436,615.329285 
	C322.146912,611.597534 325.957428,609.782288 330.659027,609.981262 
	C332.815796,610.072632 334.998932,610.127991 337.137299,609.896606 
	C339.179901,609.675476 341.371246,609.006592 340.768250,606.267029 
	C340.271851,604.011536 338.387512,603.195557 336.166901,603.485840 
	C334.040955,603.763733 331.919952,604.129883 329.827911,604.596680 
	C328.664062,604.856323 327.481049,605.229126 326.451630,604.544128 
	C324.544769,603.275269 322.697937,600.933350 320.307831,602.244019 
	C317.161469,603.969543 313.347717,604.633423 310.947662,607.629211 
	C309.701508,609.184570 308.457764,611.413452 306.280853,610.597717 
	C303.635132,609.606201 306.290070,607.463257 306.001068,605.833191 
	C305.829926,604.867859 305.989258,603.843811 306.000000,602.423096 
	C308.755890,602.178406 311.033295,601.920349 312.765778,599.047852 
	C314.497009,596.177429 317.767395,596.076599 320.222290,597.971558 
	C325.421509,601.985046 331.525726,599.036621 337.073578,600.534973 
	C338.833038,601.010071 340.867706,598.440430 338.444000,595.951294 
	C336.623840,594.081970 334.515808,592.822327 331.826782,593.019104 
	C327.850555,593.310059 325.207489,591.026001 324.302795,587.759888 
	C323.397858,584.492737 321.012939,584.650757 318.988556,584.446045 
	C316.607117,584.205139 316.444458,586.068298 316.519836,588.177124 
	C316.575836,589.742615 314.876434,590.182434 313.476257,589.803589 
	C311.586029,589.292175 312.617310,588.023804 313.009338,587.025818 
	C313.432587,585.948486 314.160858,584.921143 313.742767,583.703674 
	C312.585419,580.333435 314.745026,580.074524 317.140930,580.004211 
	C317.307312,579.999329 317.474335,580.007385 317.640320,579.997498 
	C319.430908,579.891052 322.202942,580.078491 322.146942,578.086121 
	C322.078339,575.645508 319.208557,576.014709 317.300354,576.038025 
	C315.257965,576.063049 313.318909,575.736267 311.458099,574.976501 
	C309.835693,574.314087 307.474091,574.166931 307.477234,571.863220 
	C307.479736,570.027649 309.354279,569.222107 310.882111,568.592529 
	C314.839050,566.961975 315.817139,565.394775 311.047882,563.478882 
	C309.624725,562.907104 308.926453,562.232056 310.532745,560.639893 
	C313.962891,557.239929 320.352539,556.068970 325.283752,558.336243 
	C328.937531,560.016235 331.806610,565.739258 331.151215,570.125854 
	C330.704224,573.117432 331.483704,575.011169 334.955444,575.019348 
	C338.805237,575.028381 337.768585,576.693909 336.008087,578.484558 
	C334.490692,580.027954 332.943817,581.542480 331.434784,583.093872 
	C330.596649,583.955627 329.743927,584.946106 330.875549,586.087097 
	C331.917572,587.137756 333.245636,587.344360 334.459442,586.337219 
	C335.059326,585.839417 335.334442,584.742126 335.965271,584.526489 
	C338.393555,583.696228 347.756256,592.849365 347.021271,595.353455 
	C346.745483,596.292908 345.828156,597.250854 346.743134,598.045288 
	C349.786987,600.688171 348.729767,603.096680 346.828217,605.849304 
	C344.769958,608.828674 348.035767,609.329041 349.472443,610.574829 
	C350.470764,611.440430 352.999725,610.508667 352.684448,612.827820 
	C352.345062,615.324768 350.032166,614.655396 348.314056,615.048950 
	C343.156342,616.230530 337.342926,613.517456 332.673615,618.085266 
	C332.640808,618.511841 333.194672,619.065918 333.851318,618.849426 
	C340.567047,616.634277 347.186127,619.027344 353.842926,619.236328 
	C355.361725,619.283936 356.737946,620.136780 356.965668,621.942871 
	C357.211334,623.891663 358.228577,624.952698 360.967468,625.149231 
	C364.754395,629.226990 368.978699,632.187195 370.978088,636.964233 
	C371.595062,638.438354 373.128479,638.988220 374.574097,639.451416 
	C376.408569,640.039368 377.950012,640.977356 378.501068,642.942444 
	C379.418823,646.215332 381.446289,649.611023 378.388916,652.738586 
	C376.530182,654.640015 368.504272,654.937073 365.995422,653.055786 
	C363.640533,651.289917 361.270203,650.213745 358.315002,649.951294 
	C355.452179,649.697083 354.294586,647.314148 353.363647,644.894287 
	C352.058258,641.501160 349.120483,641.635132 346.244720,641.688110 
	C343.774353,641.733643 342.818909,643.242493 342.406311,645.469055 
	C341.953156,647.914307 342.555817,649.459656 345.304901,649.388550 
	C348.817749,649.297729 351.693817,650.572571 354.426910,652.768982 
	C357.415100,655.170349 361.231384,656.194275 364.823303,655.189270 
	C370.394836,653.630432 375.673767,654.478088 381.414124,655.750977 
	C382.007996,655.943542 382.235413,656.044006 382.756714,656.367188 
	C384.359009,658.937805 382.493835,659.814453 381.070831,660.936340 
	C378.897797,662.649597 376.798279,671.185852 378.120209,674.109314 
	C379.556396,677.285522 383.136810,676.971924 385.810455,678.043518 
	C387.844543,678.858765 389.123932,677.085693 390.465302,675.851257 
	C394.591309,672.054260 397.070953,672.255432 398.211151,677.449707 
	C399.465790,683.165222 401.963562,685.425598 407.821350,685.163574 
	C410.777893,685.031372 413.943726,686.482056 412.401794,691.128540 
	C407.536560,694.852173 402.366058,692.901917 397.155121,692.707275 
	C398.092041,694.181091 399.792725,693.436951 400.980438,694.600708 
	C400.757629,696.948242 400.757629,696.948242 398.571777,698.087097 
	C402.936066,697.487549 407.066223,696.977905 411.514404,698.159058 
	C414.217651,702.010681 417.637390,702.670227 422.004578,701.656494 
	C423.156799,701.684021 423.890167,701.881958 424.905640,702.434937 
	C427.355560,706.370178 425.794769,710.474854 426.382782,714.382812 
	C426.909088,717.880615 428.070831,719.510620 431.769348,719.053955 
	C435.018372,718.652771 438.428070,719.611206 441.493347,717.653625 
	C442.501648,717.009705 443.765472,717.169006 444.884949,717.617554 
	C450.102936,719.707703 450.883789,724.360352 451.286591,729.090698 
	C451.633118,733.160889 449.985504,736.201111 445.984039,737.709595 
	C440.102783,739.926697 434.038116,741.548950 427.637329,743.236938 
	C429.747864,743.694763 431.658264,743.454041 433.448181,744.970154 
	C434.893646,747.770203 434.855560,750.239441 433.305603,752.999023 
	C430.309082,755.031677 427.449341,754.677002 424.676514,753.616943 
	C422.992889,752.973328 421.438416,752.140259 419.304077,753.156555 
	C418.796265,753.484863 418.591736,753.608643 418.073120,753.891479 
	C413.617859,756.377930 413.555511,756.873657 417.369568,759.824890 
	C417.900970,760.055542 418.111481,760.158203 418.629517,760.437134 
	C420.401947,761.714539 421.992157,761.740112 423.521912,760.901917 
	C426.072449,759.504517 428.808746,759.460693 431.968018,759.523804 
	C435.265930,759.317322 438.172394,759.243896 441.366821,760.338745 
	C442.436920,760.842102 443.088257,761.288696 444.167664,761.760010 
	C445.224457,762.263000 445.858093,762.711853 446.765961,763.431702 
	C449.717010,765.371826 452.633240,764.001343 454.503143,762.924683 
	C460.314056,759.578918 466.471436,759.519165 472.775970,759.333618 
	C474.952606,759.269531 478.696320,760.464600 478.567078,756.791687 
	C478.458771,753.713745 474.945709,754.762512 472.823639,754.648926 
	C469.354065,754.463074 465.866119,754.633484 462.450714,753.790894 
	C456.865906,752.413025 455.846802,750.166260 458.726044,744.587891 
	C460.591278,743.470154 462.277466,743.354370 464.419434,743.450928 
	C468.558472,744.092224 472.060150,742.197693 476.174133,742.582642 
	C478.942291,742.780457 480.829346,743.802185 482.460388,746.014099 
	C483.993195,750.321777 486.405914,753.906067 486.647522,758.297485 
	C486.756683,760.281189 488.413544,761.285706 490.706787,761.689148 
	C492.697693,762.250000 494.198517,763.058777 496.088867,763.879761 
	C500.201172,765.051636 501.972443,768.283203 504.464355,771.291748 
	C506.195068,772.865723 507.236237,774.357361 506.492798,776.818604 
	C504.351379,778.715271 502.297516,778.075195 499.892822,777.347839 
	C496.492645,776.418091 495.216187,772.262939 491.055176,772.986572 
	C489.791016,773.220032 488.861725,773.211609 487.583923,773.058533 
	C486.004944,772.921204 484.831909,771.579285 483.587158,772.704834 
	C483.289490,772.974060 483.855347,773.569336 484.446930,774.144165 
	C486.097565,775.702820 488.138031,775.405273 489.981812,776.548340 
	C491.376770,778.558777 490.671448,780.088379 489.414978,781.898560 
	C489.103912,782.439819 488.961456,782.646118 488.542603,783.108704 
	C487.827271,783.607849 487.364685,783.788696 486.508667,783.943359 
	C484.234100,783.819397 482.306549,784.451965 480.073547,783.742432 
	C478.527252,782.716797 477.109131,782.449585 475.652252,782.197876 
	C473.783508,781.875000 471.813232,780.888306 471.671722,779.063232 
	C471.360016,775.043091 468.482635,774.210754 465.277985,772.998657 
	C463.935608,771.973328 463.131226,770.863647 461.760071,769.873413 
	C457.459259,767.919861 455.260773,770.881226 452.488708,773.263306 
	C451.622742,773.779541 450.985443,773.915649 450.011902,773.888611 
	C448.983643,773.729797 448.261017,773.395874 447.309265,774.039307 
	C446.775909,774.516785 446.464355,774.800903 445.883423,775.303955 
	C443.768646,779.931580 444.128845,784.912598 441.781219,789.259827 
	C440.577667,791.488525 439.535065,793.468628 436.826569,793.387634 
	C433.119995,793.276855 428.952942,794.800476 425.961639,791.460876 
	C424.092590,789.374451 424.810272,786.899048 426.825195,785.291321 
	C428.688171,783.804932 429.372040,782.114929 429.735931,779.844177 
	C430.379639,775.827026 433.119080,773.125305 436.713318,771.000305 
	C430.650787,769.715454 427.944824,770.429260 424.187317,774.237366 
	C420.024414,778.456360 415.228790,780.910156 409.072388,780.537354 
	C403.343842,780.190491 402.246338,779.390808 401.539490,773.623352 
	C401.437012,772.787170 401.296143,771.982361 400.644775,770.937256 
	C399.856201,769.512756 399.582184,768.287598 398.956238,766.804016 
	C397.785339,763.375305 393.774780,764.542603 392.196747,761.613403 
	C391.116302,758.539124 391.202332,755.793640 391.960602,752.658813 
	C393.098572,750.313721 395.146179,749.812317 396.871155,748.856445 
	C398.551056,747.925537 399.553162,746.636108 398.155090,744.849060 
	C396.909790,743.257324 395.412720,743.081055 394.040039,744.872437 
	C389.879303,750.302551 384.196594,748.557983 378.859100,747.903503 
	C376.993011,747.674683 375.418457,746.179077 375.590057,744.061584 
	C375.759796,741.967346 377.680939,741.628296 379.358856,741.572144 
	C381.309692,741.506775 383.012573,741.082764 384.127319,739.390320 
	C386.131500,736.347534 388.964478,735.898743 392.394501,736.052490 
	C399.812225,736.385132 407.238892,736.021912 413.310883,735.476257 
	C408.165192,735.336609 401.693970,735.602539 395.190186,735.115662 
	C386.715973,734.481262 379.824982,731.623169 375.138855,724.271484 
	C373.983551,722.459045 371.940125,722.661194 370.155060,722.578979 
	C366.362823,722.404053 363.435577,720.875549 361.256165,717.276245 
	C361.345581,714.362854 360.749176,712.117676 358.172729,710.312012 
	C356.824707,708.763672 357.862213,707.700989 358.213959,706.543274 
	C358.666687,705.053223 357.678436,705.554993 356.665894,705.926575 
	C355.505280,706.549866 354.803711,707.236328 353.624207,707.914917 
	C350.453644,708.937439 348.014923,710.738647 345.258392,711.591370 
	C342.203613,712.536438 338.605804,714.209778 336.782227,709.356934 
	C337.256348,708.506958 337.516357,708.011475 337.888367,707.259155 
	C339.707520,705.167603 342.422729,705.590942 344.661682,704.091919 
	C350.541382,701.455200 351.391602,699.339722 347.788788,695.420776 
	C344.976868,692.362122 347.252716,691.074646 349.185608,689.593933 
	C350.438934,688.633972 352.534393,689.173462 353.723328,687.210999 
	C354.651398,686.404053 354.841797,685.639893 354.934082,684.784729 
	C355.123810,683.026550 356.239014,682.154114 357.933350,682.071594 
	C359.805176,681.980347 361.096405,682.986816 361.865082,684.594299 
	C362.748993,686.442505 363.629639,688.226074 366.035034,688.311157 
	C368.137543,688.385620 370.301941,688.101807 370.725708,685.662231 
	C371.160583,683.158630 369.024933,682.798279 367.179962,682.530334 
	C363.163727,681.947327 359.349670,681.440186 360.936523,675.583496 
	C361.510193,673.466309 359.229401,673.913452 358.188232,674.153381 
	C353.208344,675.301270 348.163452,676.281433 343.251678,678.760132 
	C341.933655,679.000000 340.867310,679.000000 339.401093,679.000000 
	C335.574707,674.066589 330.554718,672.608948 324.883850,672.279724 
	C321.979950,672.111206 319.594727,670.541504 317.502472,668.508301 
	C315.745087,666.800537 314.206268,664.964417 315.308258,662.423035 
	C316.489746,659.698547 318.518280,657.702026 321.759552,657.805359 
	C325.357330,657.920105 328.659729,658.696167 330.510773,662.368408 
	C331.244659,663.824341 332.529358,665.697693 334.261078,664.262512 
	C335.639862,663.119873 334.165192,661.369080 333.131622,660.455505 
	C328.962677,656.770691 325.146027,652.857056 318.556824,653.304016 
	C314.056732,653.609192 309.577209,652.148193 305.853729,656.165955 
	C304.371277,657.765503 301.926910,657.752014 299.361816,657.089355 
	C297.933319,657.000000 296.866669,657.000000 295.399994,657.000000 
	C291.872894,655.140991 292.168274,652.539795 293.711823,649.365234 
	C295.714020,648.999634 297.428009,648.999207 299.570404,648.998169 
	C302.488922,646.643433 303.411560,644.188904 300.239807,641.243774 
	C294.575867,641.479309 289.251129,640.356323 283.440033,640.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M361.000000,717.333313 
	C365.035767,719.510742 369.028809,722.051697 374.186127,721.115234 
	C375.390991,720.896484 376.539185,721.753113 376.781708,723.017639 
	C377.450043,726.502319 380.492798,727.372742 382.700409,729.384277 
	C388.795746,734.937988 395.987122,732.975525 402.716400,733.911438 
	C406.305328,734.410583 410.021301,734.037415 413.678955,733.987061 
	C416.042847,733.954529 418.296814,734.218750 420.351349,735.667725 
	C419.684967,737.826660 417.887787,736.950684 416.649750,736.951477 
	C407.650238,736.956848 398.668671,738.919128 389.644135,737.217041 
	C388.937286,737.083740 387.760773,737.424927 387.369934,737.960022 
	C384.906982,741.332214 382.116180,743.716980 377.505219,743.244934 
	C376.190308,743.110474 376.377747,744.496887 377.061188,745.437561 
	C379.284119,748.497009 391.257172,747.563782 392.694275,744.026978 
	C393.461243,742.139526 393.832672,740.414917 396.309814,741.297668 
	C398.535919,742.090942 400.629059,743.144958 400.960785,745.928833 
	C401.244476,748.309631 400.081329,749.732788 397.962555,750.468689 
	C396.561737,750.955200 395.109619,751.293945 393.340210,751.849426 
	C386.758209,751.894531 380.484619,750.641541 374.341736,753.096558 
	C372.070221,754.004456 370.073700,754.817505 369.348816,757.408020 
	C368.934448,758.888794 368.096436,760.432129 366.592896,760.738220 
	C362.054779,761.662415 361.823853,765.170105 361.184113,768.972290 
	C359.203644,770.617004 357.154755,770.034241 355.220428,770.190002 
	C350.311462,770.585022 349.442505,771.721558 350.558441,776.440308 
	C351.744904,781.457642 352.377228,786.461304 350.295593,791.739136 
	C346.833710,791.143372 347.888519,788.460022 348.094177,786.645447 
	C348.817017,780.266602 345.947815,774.995667 343.221375,769.285583 
	C343.552734,763.188477 342.752289,757.225098 345.420715,751.658630 
	C347.013123,748.336792 345.804138,745.923096 342.195465,744.857788 
	C340.940460,744.487366 339.604034,744.354126 338.244965,743.288330 
	C338.374481,737.973511 335.067474,738.396851 332.176758,739.540283 
	C326.854248,741.645569 321.399139,740.791443 315.984253,740.981140 
	C314.257294,741.041687 312.413605,740.810791 312.241302,738.655579 
	C312.090240,736.765259 313.517639,735.302917 315.201080,735.244751 
	C318.263245,735.138855 319.143066,733.649963 319.268738,730.924255 
	C319.308899,730.054016 320.041504,728.625671 320.701843,728.466370 
	C326.556946,727.053650 330.959595,720.331909 338.198792,723.751892 
	C339.570557,724.399902 341.463318,723.945190 343.559082,723.998413 
	C344.552124,723.852783 345.006958,723.996033 345.512451,724.787781 
	C345.853699,728.235535 343.623688,728.959045 341.289062,729.469543 
	C338.595825,730.058533 335.929047,730.700684 332.367310,731.944885 
	C339.783600,733.648376 346.215057,734.201782 353.115906,732.833740 
	C355.355286,732.410645 357.214203,732.333496 359.387482,733.158997 
	C361.720764,735.777039 360.592133,737.469238 358.158447,738.231628 
	C352.994537,739.849304 352.284088,744.130127 351.766602,748.457886 
	C351.403900,751.491272 353.127380,753.117981 356.149628,753.257935 
	C359.098511,753.394409 360.908112,752.068726 361.221436,749.021851 
	C361.411041,747.178406 360.872681,745.391052 360.747192,743.572449 
	C360.371887,738.132751 362.656128,736.080688 367.927002,737.411438 
	C372.002136,738.440186 376.017639,738.842712 380.602386,737.880798 
	C376.777924,732.862122 373.667694,727.762024 366.445679,728.393005 
	C362.519470,728.735962 361.823120,725.298462 360.419678,722.298035 
	C360.096497,721.145935 360.158508,720.377563 360.760498,719.320679 
	C361.000000,718.555542 361.000000,718.111084 361.000000,717.333313 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M485.651978,783.077209 
	C486.443909,782.997253 486.887817,782.996948 487.664642,782.995972 
	C488.844574,782.377563 489.786407,782.534302 491.070312,782.730835 
	C492.092926,783.008240 492.700439,783.342834 493.476318,784.045288 
	C494.426849,785.374451 495.025482,786.613037 496.993347,786.467773 
	C499.071655,786.463684 500.806366,786.447754 502.760986,787.338013 
	C505.051697,792.306763 504.491577,794.023560 500.186584,796.288330 
	C498.358704,797.249756 496.338867,797.899719 493.893524,800.146423 
	C500.550385,797.751160 505.975220,795.217224 512.071594,795.607544 
	C515.026672,795.796631 517.545532,796.413025 516.026123,800.697876 
	C515.285706,801.920349 514.499390,802.602417 513.444824,803.573120 
	C511.297241,805.066528 509.233063,805.449707 506.669189,805.431458 
	C505.762878,805.456421 505.247711,805.463318 504.354004,805.459473 
	C502.384369,805.564758 500.289948,804.951050 499.619720,806.873413 
	C498.929230,808.853943 500.800079,810.112183 501.955170,811.448792 
	C503.830444,813.618958 504.812775,815.986389 503.234619,818.663330 
	C501.759186,821.166138 499.734314,823.257141 496.538635,822.915039 
	C493.664032,822.607300 491.708557,820.955505 491.474548,817.521667 
	C492.720032,815.448792 494.611328,816.385010 496.267517,815.849182 
	C494.154572,815.266785 492.363007,815.169006 490.865204,817.471558 
	C488.531372,818.439087 486.884949,817.893494 485.376221,815.867371 
	C484.911316,814.747437 484.853760,813.963257 485.045715,812.799377 
	C485.491241,811.102417 487.026306,810.601379 487.394012,809.276672 
	C482.688660,805.881653 478.811890,800.777344 471.572876,802.351379 
	C472.068207,805.577942 470.690002,808.554199 468.150269,811.418335 
	C466.731415,812.255981 465.524811,812.326111 463.927551,812.023621 
	C462.430023,811.585632 461.625000,810.567688 460.080200,810.239807 
	C458.835236,809.802734 458.008331,809.343750 457.173706,808.311768 
	C455.748077,804.243286 454.024323,806.538513 452.751282,807.831848 
	C450.764771,809.849915 449.890717,810.157288 448.273132,807.146973 
	C445.974243,802.868713 437.055023,800.039795 432.118683,801.238220 
	C431.044830,801.498962 430.526825,802.228699 430.145966,803.224976 
	C426.981171,811.503296 425.365112,812.256409 417.370972,809.045105 
	C411.858612,808.710205 406.690125,807.778992 401.316406,809.807373 
	C399.862122,810.030823 398.931854,810.453552 398.148071,811.716187 
	C396.699860,813.113464 395.289795,813.376709 393.369446,812.275513 
	C387.165100,812.205017 381.070770,811.024719 376.096588,815.580627 
	C375.258759,816.348083 374.272217,815.378479 373.770782,814.417053 
	C372.848877,812.649475 372.551575,810.895630 374.325928,809.483948 
	C375.965820,808.179260 377.938416,808.029846 379.983521,807.966858 
	C384.117615,807.839417 388.280731,808.506531 392.693207,807.280884 
	C393.321716,806.606750 393.691498,806.265564 394.451477,805.815308 
	C398.011444,804.303162 400.617218,801.500244 404.823822,801.555237 
	C407.006744,801.263611 408.791046,800.904358 410.991516,800.619873 
	C416.813873,800.932617 422.348816,801.968384 427.041565,797.926575 
	C427.394653,797.622437 427.992981,797.574768 428.482391,797.585510 
	C438.059967,797.794617 446.958130,793.927307 456.374573,792.956543 
	C462.141693,792.361938 467.599457,793.737976 473.212860,793.915833 
	C477.666962,794.056946 482.109711,795.173096 486.799591,793.741333 
	C487.554199,792.355713 486.920746,791.501831 486.104156,790.382080 
	C484.206238,787.927185 481.478821,785.819458 485.651978,783.077209 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M224.656296,587.258057 
	C230.487808,587.229065 236.006058,587.585083 240.640228,583.047485 
	C241.443863,585.613770 245.958939,585.477356 245.039200,589.116333 
	C244.715256,590.397949 244.909790,591.823730 243.510620,592.559509 
	C242.175507,593.261597 241.420746,592.261047 240.502670,591.527039 
	C239.351913,590.606995 238.123535,589.589661 236.230911,590.747681 
	C233.239822,593.583740 230.090576,593.970947 226.320663,592.328979 
	C219.531616,589.678772 218.312500,590.533936 215.816025,596.960266 
	C214.493149,600.365601 210.485931,600.490173 207.439041,600.841614 
	C203.331406,601.315430 203.174210,597.604065 202.621307,594.818115 
	C201.031586,594.289368 200.700943,595.496399 200.434692,596.318665 
	C199.311874,599.786255 197.097321,599.541260 194.631760,598.057495 
	C191.254715,596.025208 188.063187,595.540771 184.410583,597.695068 
	C182.077316,599.071167 180.491638,600.077148 182.384995,602.874939 
	C183.224289,604.115051 182.860779,605.420044 182.165497,606.633972 
	C180.797745,609.021912 180.731812,611.070862 182.736252,613.395569 
	C185.142548,616.186401 183.601517,617.457458 180.214951,617.232300 
	C179.080032,614.117920 176.218903,615.509521 174.228882,614.241028 
	C174.086166,611.716797 173.405807,610.058167 170.372986,610.004333 
	C167.830215,607.548279 164.733856,607.535400 161.560211,606.720520 
	C161.084503,606.360291 160.924683,606.176575 160.741760,605.625977 
	C161.899826,602.326843 164.603363,602.818726 166.956284,602.425659 
	C169.730087,601.962341 172.969742,602.592468 174.801056,599.035889 
	C177.180862,594.693665 180.244705,591.261230 180.375412,586.090820 
	C180.455566,582.919983 183.425446,581.259094 187.294067,582.173828 
	C189.985641,584.269836 187.355576,586.216980 187.515991,588.463074 
	C189.954681,588.520813 192.006516,587.438965 194.531479,586.828857 
	C196.745682,586.419434 198.601700,586.272888 200.890686,586.503784 
	C206.599243,586.131958 211.810043,586.447876 216.998459,587.735779 
	C219.360718,588.322144 221.895935,588.338196 224.656296,587.258057 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M350.000000,792.250000 
	C349.632172,786.267456 350.681030,780.532043 348.322174,774.815796 
	C346.507324,770.417847 350.236267,767.623291 356.299408,768.281616 
	C357.606415,768.423462 358.892944,768.753723 360.906372,769.274963 
	C362.920624,771.683777 362.605377,774.025818 362.479858,776.331055 
	C362.320068,779.265442 363.662689,780.580933 366.603943,780.552368 
	C370.413055,780.515442 374.224365,780.500732 378.458740,780.617554 
	C381.081024,780.894165 382.633972,782.064941 383.797729,784.384766 
	C384.339386,788.055786 382.462067,789.109375 379.604187,789.405212 
	C376.869232,789.688354 374.004364,788.750793 371.198425,790.532227 
	C370.859558,791.239624 370.780670,791.677002 370.836243,792.453247 
	C371.321411,793.785583 372.144470,794.269653 373.155121,794.540588 
	C374.585541,794.924133 376.367645,795.119934 376.330597,797.074524 
	C376.294250,798.993530 374.444946,799.103943 373.087372,799.626831 
	C372.621704,799.806213 372.226166,800.122925 371.533112,800.666138 
	C370.307220,801.435974 369.328308,801.834717 367.901550,802.230164 
	C365.483826,802.610779 363.519806,802.596130 361.363037,801.225586 
	C359.672180,795.514404 355.157257,794.449951 350.286072,793.241455 
	C350.000000,793.000000 350.000000,792.500000 350.000000,792.250000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M299.055237,657.396057 
	C303.043915,655.792542 306.962311,654.739746 310.385101,651.702576 
	C312.747437,649.606384 316.341858,652.654114 319.513306,652.026550 
	C327.097412,650.525818 330.932800,656.200745 335.294800,660.634521 
	C336.924316,662.290833 338.487701,665.099365 335.773590,666.524597 
	C333.133698,667.910828 330.316071,666.643616 329.035583,663.373535 
	C327.881409,660.426025 322.638367,658.190491 319.742493,659.293518 
	C315.559540,660.886902 314.984619,664.846985 318.416016,667.897705 
	C321.772888,670.882263 325.994904,671.323486 330.064911,671.410034 
	C335.003723,671.515137 337.532013,673.964050 338.952759,678.638550 
	C337.880768,679.966370 336.760315,680.932739 335.321167,681.949585 
	C333.164032,682.308533 331.306274,682.827454 329.308197,681.252686 
	C327.609558,681.000183 326.220337,681.000366 324.414337,681.001526 
	C322.693665,682.128967 321.263916,682.385376 319.344238,681.311768 
	C318.282776,678.620544 316.714142,676.512207 318.725220,673.732727 
	C319.919952,672.081543 317.928162,670.431335 316.627869,669.126160 
	C313.919891,666.407959 311.301483,663.733887 315.292633,659.954102 
	C316.332550,658.969299 316.276093,657.383240 315.401764,656.245300 
	C314.363129,654.893433 312.582977,653.553040 311.135590,654.327576 
	C308.650085,655.657532 305.511292,656.703613 305.025269,660.225098 
	C304.750916,662.212769 304.724854,664.708008 302.148834,664.758728 
	C298.913208,664.822449 300.464874,661.810425 299.870422,660.167786 
	C299.587708,659.386536 299.362000,658.584656 299.055237,657.396057 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M211.656891,742.964783 
	C206.999420,741.540894 201.376129,743.284485 199.087982,737.277832 
	C197.796539,733.887695 197.122086,730.834290 200.280777,728.257996 
	C203.580200,725.566833 203.637436,722.301880 202.064453,718.840149 
	C198.949417,711.984741 202.235458,704.764526 211.638123,704.099915 
	C214.002274,703.932739 216.582642,704.042175 216.901184,707.234131 
	C217.196274,710.190918 214.950684,710.879639 212.675476,710.932007 
	C209.407227,711.007324 207.124924,711.981140 207.013947,715.679871 
	C206.901321,719.433899 208.532822,721.723206 212.446121,721.996704 
	C214.002014,722.105530 214.928055,722.706177 214.954849,724.408386 
	C214.986099,726.393799 213.879471,727.068298 212.119690,726.980347 
	C208.689255,726.809021 206.722595,728.567383 206.293884,731.794067 
	C205.878036,734.923767 207.978470,736.528503 210.525726,737.821655 
	C212.058014,738.599609 214.352631,738.779358 214.524734,741.054077 
	C214.633926,742.497192 213.254211,742.796509 211.656891,742.964783 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M337.582947,743.000000 
	C340.110840,743.120972 342.237366,742.740295 344.308807,743.660339 
	C347.837006,745.227478 349.336517,748.366455 347.495911,751.226562 
	C344.732178,755.521301 345.027527,759.929993 345.013702,764.479736 
	C345.009033,766.016541 344.900940,767.442322 343.240326,768.695801 
	C341.285645,769.000000 339.571289,769.000000 337.428467,769.000000 
	C335.056671,770.564453 333.090637,770.295532 331.855438,768.349609 
	C330.462372,766.154907 330.165314,763.345947 331.943512,761.353638 
	C334.885468,758.057556 335.634888,755.617065 330.891815,753.448059 
	C329.477692,752.801392 328.848022,751.299255 329.766357,749.307861 
	C329.552673,743.612915 332.815002,742.656433 337.582947,743.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M269.753235,617.236328 
	C270.752747,617.002441 271.501831,617.002380 272.625458,617.002441 
	C273.795380,616.853088 274.587524,616.632935 275.700836,616.946167 
	C278.424805,617.254822 281.078644,616.226135 283.226746,617.914490 
	C284.415863,618.849182 286.356262,619.787354 285.338409,621.854614 
	C284.548279,623.459351 283.056976,624.180603 281.179352,623.855835 
	C279.400452,623.548157 277.613922,623.284485 275.415375,623.001953 
	C274.250305,623.001587 273.500610,623.000793 272.376068,623.000000 
	C271.073242,619.824585 268.550659,620.898254 266.690338,621.331177 
	C261.793915,622.470520 257.374603,622.793457 253.669540,618.352234 
	C252.255142,616.656799 249.921066,615.609192 249.012299,619.625854 
	C244.001953,620.154114 238.852600,620.810974 236.129364,614.957336 
	C235.815079,614.281799 234.357880,614.201416 233.528671,613.687012 
	C232.288254,612.917480 229.553192,613.283325 230.407913,610.780273 
	C231.239258,608.345764 234.340958,608.254272 235.467697,609.322449 
	C239.911407,613.535034 245.173264,611.747803 250.100021,611.899841 
	C253.047623,611.990723 255.621841,612.820312 258.614288,613.948853 
	C262.094543,616.386658 265.320160,618.376709 269.753235,617.236328 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M329.651306,749.078369 
	C329.990387,750.733582 330.301636,752.086914 332.237793,752.912170 
	C338.279449,755.487122 338.580505,757.000427 333.746704,761.086487 
	C331.878632,762.665649 331.878723,764.448486 332.121948,766.408325 
	C332.419159,768.803772 334.272797,769.015503 336.597198,768.998291 
	C335.029633,775.239258 336.795654,781.859802 335.045776,788.139465 
	C334.696106,789.394226 334.978180,790.966736 333.479126,791.491577 
	C331.922577,792.036499 331.260345,790.420227 330.202332,789.728699 
	C326.185516,787.103210 325.756531,783.429749 327.470154,779.446960 
	C330.392700,772.654419 330.502350,772.701599 324.204620,769.869446 
	C326.636292,768.517029 330.983673,769.012207 329.253906,764.238037 
	C327.947205,763.046143 327.977875,761.896851 328.698639,760.338501 
	C332.561371,758.726929 330.088715,757.113586 328.958282,756.190125 
	C327.785187,755.231750 326.076813,754.928650 323.139679,753.753845 
	C327.348297,753.515076 327.764069,750.815552 329.651306,749.078369 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M343.333313,679.000000 
	C343.864807,676.340942 345.609467,674.752991 348.488251,675.034119 
	C351.635071,675.341492 353.983856,673.125061 356.951324,672.614746 
	C359.829163,672.119934 361.946747,673.102661 364.479645,673.306763 
	C364.098419,675.916260 360.204712,676.215698 361.745300,679.012695 
	C362.993195,681.278259 365.340881,681.057434 367.507294,680.996765 
	C370.292328,680.918701 372.617065,681.710388 372.859161,684.849854 
	C373.063782,687.503662 371.454681,689.427917 368.884552,689.768005 
	C366.054901,690.142517 362.638580,690.984924 361.178528,687.302246 
	C360.531250,685.669617 360.965088,683.581421 358.524933,683.124023 
	C355.466095,682.550720 356.618744,686.262817 354.352478,686.920044 
	C353.752441,686.195190 353.504883,685.390442 353.128662,684.292847 
	C352.182892,679.707642 347.987427,681.007690 345.252441,679.236084 
	C344.555542,679.000000 344.111115,679.000000 343.333313,679.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M360.381042,733.840027 
	C357.958344,734.000000 355.916656,734.000000 353.437500,734.000000 
	C350.192047,733.988037 347.383850,734.027771 344.576202,733.973145 
	C343.385437,733.950012 342.151581,733.822144 341.738495,732.484619 
	C341.249176,730.899963 342.394409,730.063599 343.525665,729.324707 
	C345.061005,728.321960 346.804504,727.806824 348.888977,727.346924 
	C347.850067,726.135620 346.342163,726.362976 345.255920,725.229797 
	C344.882324,724.687561 344.690674,724.432129 344.214355,724.114441 
	C343.338684,720.996948 340.846466,722.340027 339.095398,721.956360 
	C338.242096,721.769470 337.395905,721.582703 337.357544,720.555054 
	C337.319214,719.528381 338.137146,719.002319 338.984650,719.101624 
	C346.069885,719.932312 353.114349,717.187012 360.600006,718.943848 
	C361.000488,719.750000 361.000946,720.500000 361.001953,721.625000 
	C361.122253,725.897461 363.878357,729.849182 360.381042,733.840027 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M237.394348,700.111023 
	C236.837341,700.031982 236.673141,700.059937 236.510971,700.096741 
	C234.881241,700.466736 233.779816,703.080383 231.715881,701.408081 
	C230.545044,700.459351 231.280243,699.139648 231.945389,698.267883 
	C233.227295,696.587585 233.527557,693.724670 236.882629,694.393127 
	C240.525528,695.118835 244.282974,696.082886 247.897858,693.384888 
	C254.262909,687.471313 258.825562,687.791931 267.761841,694.685669 
	C268.729797,697.020020 273.621521,699.267456 267.337280,700.963257 
	C264.942200,702.457214 263.197083,701.161743 261.504639,700.261719 
	C258.568329,698.700256 255.604614,698.361267 252.441315,699.430054 
	C247.638626,701.052734 242.723846,700.903259 237.394348,700.111023 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M223.951233,609.917725 
	C224.926498,613.896973 228.506790,613.111938 230.795334,614.441589 
	C232.020233,615.153137 233.961960,615.455261 233.449707,617.445129 
	C233.055145,618.977905 231.471863,618.983398 230.125519,618.987671 
	C226.974228,618.997803 223.820007,619.069641 220.672287,618.961487 
	C217.249512,618.843811 216.080078,616.931946 217.733780,614.016541 
	C219.803329,610.367859 217.475754,609.414062 214.838593,609.112549 
	C211.914017,608.778137 208.949081,608.923218 205.976028,609.709045 
	C203.568665,610.345154 201.196289,609.622131 201.062302,606.431091 
	C200.937622,603.461670 203.020523,602.572021 205.364975,603.078857 
	C211.783264,604.466370 218.034760,606.445984 223.951233,609.917725 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M271.697327,649.379333 
	C276.273865,648.592285 279.479065,649.016479 279.160492,654.803284 
	C278.944946,658.717957 282.834808,658.425293 285.428680,658.914185 
	C287.406372,659.286987 289.576508,658.993835 289.913910,661.732910 
	C290.221313,664.228149 289.263031,666.339233 287.427551,667.941467 
	C284.827332,670.211121 284.381134,666.409729 282.331512,666.058228 
	C280.777954,666.302246 279.593445,666.344727 278.272522,665.241882 
	C272.032288,665.962585 271.597839,662.267700 272.000000,657.434387 
	C270.101685,654.728333 269.992249,652.306763 271.697327,649.379333 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M268.174896,694.671814 
	C267.053101,694.820007 265.820801,694.960693 265.204224,694.410828 
	C260.035583,689.802429 254.564743,689.677795 248.377441,692.991760 
	C245.710251,692.850891 243.361649,693.404480 241.135025,692.502808 
	C237.901413,691.193481 237.191910,689.574585 240.797607,687.264832 
	C241.493393,686.433655 242.133759,686.198853 243.212372,686.219971 
	C244.437576,686.469421 245.124969,687.126892 246.342072,687.290161 
	C248.715500,686.803101 249.863586,685.168152 251.258804,683.830688 
	C253.543564,681.640442 255.965256,681.044434 258.745300,683.750366 
	C260.870392,684.804321 261.841400,687.204041 264.645233,686.994751 
	C266.300812,685.890381 267.708466,685.610840 269.630188,686.727051 
	C269.933105,689.558289 270.520813,692.261292 268.174896,694.671814 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M268.302673,656.620667 
	C263.547363,657.337769 262.041809,659.511597 263.872437,663.697876 
	C264.689484,665.566467 265.908112,667.714294 263.082550,668.653503 
	C260.962677,669.358154 258.186676,669.851929 257.174011,666.810608 
	C256.265137,664.080872 253.658752,662.837280 252.356293,660.539124 
	C251.655212,659.302063 250.387894,657.948730 251.679077,656.461365 
	C252.760101,655.216187 254.176971,654.996521 255.735825,655.865234 
	C258.930542,657.645630 260.860718,655.958435 260.845276,653.009705 
	C260.817535,647.723511 263.417053,647.656250 267.609314,648.990784 
	C269.897644,651.271423 270.007751,653.692932 268.302673,656.620667 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M237.012421,700.402222 
	C238.671478,698.346069 240.781570,699.141113 242.708435,699.020691 
	C246.138809,698.806458 249.436966,699.072693 252.867218,697.442017 
	C257.877380,695.060303 261.976807,698.983643 266.637482,700.958496 
	C269.086914,706.948120 264.934296,704.094727 262.375488,704.007874 
	C261.795685,704.165283 261.590240,704.330566 261.012054,704.629517 
	C257.013977,704.593262 253.364441,704.816162 249.386780,704.047241 
	C246.359085,703.998291 243.717346,703.972290 241.076233,704.002319 
	C238.839325,704.027710 237.131699,703.446716 237.012421,700.402222 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M297.649780,680.649170 
	C298.206177,684.469482 296.102417,685.054016 293.236847,685.024536 
	C285.804382,684.948242 278.370575,684.999634 270.468658,685.000000 
	C268.423401,685.813293 266.968872,685.537964 265.299561,684.254517 
	C264.148956,682.874878 262.482269,682.062866 263.264740,680.255798 
	C264.055603,678.429321 265.807617,679.196777 267.597137,679.000244 
	C270.563965,679.043518 273.101227,679.212585 275.703796,680.751587 
	C279.072815,680.824524 282.130249,679.065552 285.615234,680.945679 
	C289.623383,678.425049 293.426636,678.798645 297.649780,680.649170 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M414.695221,811.638550 
	C420.491028,814.746582 425.267487,818.837158 431.202637,820.687988 
	C428.479767,822.421021 428.479767,822.421021 421.392517,820.009644 
	C413.863251,821.419006 407.657745,818.653198 401.251465,815.331055 
	C401.238739,813.880920 401.477448,812.759460 401.858063,811.318970 
	C406.199066,808.037781 410.302551,809.312378 414.695221,811.638550 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M272.000610,623.000000 
	C271.532379,625.653931 268.841064,625.683899 267.186829,626.753540 
	C263.916992,628.867615 263.399200,631.010559 265.660156,634.073425 
	C266.471100,635.171936 268.530121,636.131592 267.285492,637.744751 
	C265.489594,640.072388 262.669037,638.769104 260.290741,638.932251 
	C258.637115,639.045776 257.408905,638.239929 257.114136,636.461121 
	C256.462677,632.529968 259.171783,629.207764 259.069672,625.140625 
	C259.019196,623.130859 263.759308,624.296326 266.206573,623.257935 
	C267.625214,622.656006 269.467529,623.052734 271.560211,623.000000 
	C272.001221,623.000000 272.000000,623.000000 272.000610,623.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M292.292267,726.024048 
	C288.593079,727.206299 286.781860,726.421265 286.980103,722.555420 
	C287.173340,718.786865 286.570984,715.001038 288.709167,711.434570 
	C290.104431,709.107239 291.937500,708.859497 294.172424,709.057251 
	C295.411407,709.166870 296.419769,709.684937 296.747742,711.004456 
	C297.021545,712.106262 296.582123,713.074219 295.647308,713.541565 
	C291.061493,715.833984 292.097809,718.241577 295.326935,720.878113 
	C296.519714,721.852051 297.417847,723.179993 296.679108,724.802246 
	C295.866180,726.587524 294.091614,725.789551 292.292267,726.024048 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M178.437500,657.000000 
	C177.842102,654.810608 175.562210,654.472717 174.527756,653.037231 
	C173.953033,652.239685 173.022079,651.549683 173.506165,650.455627 
	C174.045441,649.236816 175.296570,648.738403 176.420959,649.144409 
	C180.510300,650.620972 184.045746,649.916992 187.634933,647.470886 
	C189.168427,646.425781 192.354919,646.361938 192.365158,648.874634 
	C192.374527,651.179565 193.330963,654.879639 189.426636,655.886902 
	C188.168259,656.211609 186.843277,656.278076 185.274536,656.731323 
	C182.958542,657.000000 180.916763,657.000000 178.437500,657.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M336.630188,708.955444 
	C339.747833,712.654236 342.908966,711.272278 345.956573,709.599976 
	C348.252319,708.340332 350.270264,706.458740 353.596802,706.998047 
	C355.178986,706.990295 356.065765,707.612671 357.091858,708.613037 
	C357.337646,709.094177 357.393372,709.306580 357.223938,709.759521 
	C356.861847,714.293762 353.396545,713.801880 350.828186,713.917175 
	C345.209991,714.169495 339.572357,714.037720 333.943542,713.978333 
	C331.879883,713.956604 328.955933,714.407532 329.351532,711.150574 
	C329.641907,708.759705 332.026062,708.046326 334.379456,708.304565 
	C335.022003,708.375061 335.636597,708.700256 336.630188,708.955444 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M337.867920,793.317322 
	C338.964355,791.447510 339.890961,789.939636 340.841064,788.393555 
	C347.827789,795.287354 348.285858,797.184265 345.075897,803.283936 
	C343.959686,805.405029 342.341675,806.137024 340.199738,805.812805 
	C337.865204,805.459595 336.799927,803.961487 336.997253,801.564819 
	C337.213806,798.934631 336.487793,796.221924 337.867920,793.317322 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M400.849365,815.296936 
	C407.407013,816.632507 413.814026,818.262512 420.610718,819.946289 
	C421.447083,821.694031 425.276398,823.588318 420.350464,824.996338 
	C416.128998,827.572021 412.828949,825.661438 409.269226,823.340332 
	C407.562653,821.417664 405.632385,822.256409 403.931946,821.957092 
	C400.247986,821.308594 399.021027,818.867920 400.849365,815.296936 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M318.616730,680.947021 
	C320.389130,680.998779 321.777069,681.000000 323.581268,681.001831 
	C323.986786,682.998352 324.205841,685.028992 323.911407,686.982117 
	C323.468872,689.918030 321.614227,691.810425 318.569794,691.928040 
	C316.383636,692.012390 313.519745,692.914917 312.583862,689.863708 
	C311.664795,686.867310 311.037201,683.520752 313.111267,680.812317 
	C314.421509,679.101257 316.508820,680.411377 318.616730,680.947021 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M187.987427,715.978577 
	C186.991013,713.961060 184.313690,712.969971 185.427704,710.594849 
	C186.496277,708.316528 188.880829,709.037292 190.792343,709.030518 
	C193.490997,709.020935 196.107162,709.230347 197.619812,706.192261 
	C198.144135,705.139221 199.505585,704.563110 200.508804,705.805237 
	C201.251511,706.724792 200.754135,707.922180 199.846252,708.361877 
	C196.815613,709.829834 196.958221,712.445251 196.966110,715.163391 
	C196.971649,717.073547 197.691711,719.452454 195.439499,720.549500 
	C193.191177,721.644653 191.894028,719.274902 190.197128,718.441650 
	C189.368958,718.034912 188.831726,717.035583 187.987427,715.978577 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M251.448944,738.565430 
	C251.283691,735.334229 252.517700,732.209473 255.281754,732.426941 
	C259.139771,732.730408 264.119202,732.230591 266.353546,736.395935 
	C267.771271,739.039124 266.031128,741.585083 262.754700,741.927551 
	C258.713837,742.349854 254.890457,741.539246 251.448944,738.565430 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M298.312378,680.834473 
	C294.307709,680.999512 290.615448,680.998962 286.462799,680.997986 
	C283.692810,675.336487 283.692810,675.336487 290.639191,673.044678 
	C291.991486,672.179260 293.071014,671.930786 294.637878,672.748047 
	C297.411896,672.834778 299.667267,673.173523 300.686523,675.721191 
	C301.565155,677.917419 300.326965,679.439453 298.312378,680.834473 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M244.195312,687.205688 
	C243.247421,687.004089 242.498459,687.003235 241.375046,687.002441 
	C239.764099,685.052002 237.163208,685.495300 235.417450,684.119507 
	C236.905548,682.335876 238.684387,683.280640 240.601166,682.996338 
	C241.803787,682.094238 242.870682,681.545105 244.006699,681.668884 
	C247.230591,682.020508 248.188797,680.131531 248.745071,677.230347 
	C254.956528,674.897339 257.342010,678.481995 258.988007,683.629700 
	C255.548126,682.556946 252.728882,682.747681 250.793335,686.565613 
	C250.076401,687.979675 248.625443,689.071594 246.372421,688.988892 
	C245.475235,688.457275 244.933304,687.933350 244.195312,687.205688 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M249.000626,676.600037 
	C248.512787,679.013428 249.917419,680.634033 250.528351,682.997070 
	C247.398224,682.997070 244.648285,682.997070 241.451172,682.996094 
	C240.945557,681.511047 239.986664,680.750854 238.885483,679.884949 
	C235.299774,677.065613 235.615417,675.606445 239.804703,673.888367 
	C242.525681,672.772522 245.324524,673.057861 248.559616,673.000000 
	C248.999603,674.066711 248.999817,675.133362 249.000626,676.600037 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M408.859253,823.316528 
	C412.528625,822.963989 415.549683,825.499390 419.582214,825.005066 
	C420.232361,827.062134 421.766693,826.992004 423.627380,826.998840 
	C425.441162,827.242310 426.061615,828.337036 426.803436,829.754944 
	C427.108917,830.120422 427.206970,830.253296 427.328491,830.359253 
	C428.468262,831.352417 430.687073,831.111145 430.758698,833.315796 
	C430.790375,834.290527 430.161621,834.771790 429.273560,834.896606 
	C427.237549,835.182678 425.358490,835.090393 424.383392,832.814819 
	C423.898834,831.683960 423.046844,831.000366 421.372131,831.013062 
	C420.311371,831.578735 419.538513,831.744995 418.339661,831.248901 
	C414.537109,831.617737 413.454590,827.787292 410.345825,827.044434 
	C409.613922,827.380127 409.158081,827.515991 408.315735,827.204041 
	C408.240021,825.876831 408.478790,824.756165 408.859253,823.316528 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M248.959991,577.345032 
	C250.477310,576.960205 251.925827,577.065735 253.300552,576.178467 
	C254.929016,575.127319 256.438263,573.002014 258.374908,573.477051 
	C261.977325,574.360657 265.458008,575.839294 268.874207,577.333496 
	C270.209229,577.917358 269.406891,579.015198 268.550751,579.611389 
	C266.678528,580.915039 264.684357,581.475708 262.519867,580.241455 
	C259.148407,578.319092 255.875519,578.685181 252.339630,580.843628 
	C250.554962,580.854675 249.074356,582.685913 247.862366,581.486389 
	C246.389206,580.028442 249.027878,579.117065 248.959991,577.345032 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M318.475220,785.457886 
	C322.301178,785.290894 329.027527,791.352295 328.784363,794.447021 
	C328.559784,797.305542 326.213043,797.112427 324.272614,796.935181 
	C319.521851,796.501343 316.235779,790.351074 318.475220,785.457886 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M268.762756,722.344360 
	C270.740326,718.961121 279.388672,717.619202 281.299744,720.162659 
	C282.365265,721.580750 282.204163,723.346436 281.745392,724.929138 
	C281.083252,727.213135 279.002289,726.715088 277.254364,727.052368 
	C274.354187,727.611938 272.371979,725.308533 269.394958,725.008179 
	C268.337463,724.331848 268.248993,723.547974 268.762756,722.344360 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M312.364014,710.885498 
	C309.414154,710.612244 305.864197,712.388794 305.089447,708.141113 
	C304.682617,705.910645 302.317474,702.982422 305.308105,701.452332 
	C307.423920,700.369629 310.905457,699.850403 312.544220,702.571533 
	C314.003174,704.994141 316.601593,707.996521 312.364014,710.885498 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M272.623871,639.007935 
	C275.753693,639.221008 278.589020,638.443420 281.628784,639.837891 
	C281.503204,643.900757 273.147491,648.262512 269.906738,646.192017 
	C268.559265,645.331055 266.878662,644.178223 267.243347,642.298767 
	C267.742249,639.727417 270.083405,639.371033 272.623871,639.007935 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M344.945038,703.668823 
	C344.812561,708.807373 341.087311,706.370850 338.429626,707.001892 
	C336.456207,704.810242 334.180634,703.455627 331.944977,702.205078 
	C330.523193,701.409668 329.414490,700.628540 329.378754,699.008484 
	C329.350861,697.743713 330.330902,696.851318 331.429626,696.510376 
	C333.261749,695.941895 333.905029,697.622803 334.872894,698.660828 
	C335.302917,699.122070 335.844604,699.479187 336.667816,699.941772 
	C338.275513,699.288879 339.396454,699.636597 340.720886,700.751221 
	C342.610870,701.253479 344.154541,701.618713 344.945038,703.668823 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M268.664673,721.936523 
	C268.999390,722.749451 269.000000,723.498840 269.000916,724.622925 
	C268.867249,726.919617 271.595001,729.404480 268.668152,730.646912 
	C265.793549,731.867004 264.533386,728.646484 262.692566,727.174500 
	C260.406952,725.346863 261.466797,723.781067 263.165741,722.338989 
	C264.696686,721.039612 265.091217,719.360413 265.039978,717.449280 
	C265.006134,716.187134 265.404633,714.948486 266.971100,715.281250 
	C268.544708,715.615601 269.142487,716.695007 268.171143,718.242249 
	C267.389923,719.486572 266.178589,720.755249 268.664673,721.936523 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M227.584778,735.103882 
	C229.999069,735.028564 231.989624,734.984253 233.979019,735.015137 
	C235.464645,735.038208 237.242004,734.932861 237.538605,736.870544 
	C237.775604,738.419128 236.788513,739.688171 235.549789,740.640320 
	C233.444046,742.258850 227.282852,741.952209 225.833054,739.968994 
	C224.392273,737.998291 224.800507,736.238464 227.584778,735.103882 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M350.011047,793.401489 
	C351.315491,793.000000 352.631042,792.993591 353.946503,793.001099 
	C360.266449,793.036987 360.862915,793.643616 361.000000,800.579163 
	C359.591461,800.674744 358.108917,799.891663 356.787445,800.105286 
	C351.645172,800.936340 350.389069,797.921326 350.011047,793.401489 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M294.000793,771.586426 
	C292.675781,772.729248 291.272247,773.125549 289.373749,772.997803 
	C288.706177,772.433838 288.549561,771.684387 288.089661,771.340637 
	C286.587799,770.218079 283.061676,770.508240 284.629395,767.290466 
	C285.561493,765.377197 287.199677,762.871460 289.786774,763.310791 
	C292.351776,763.746338 293.941956,765.838867 293.990845,768.694336 
	C294.005005,769.520325 293.997772,770.346680 294.000793,771.586426 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M281.037567,772.651062 
	C279.931854,772.998352 278.866180,772.999207 277.400848,773.000000 
	C276.502899,773.004456 275.995728,773.071472 275.507751,773.002625 
	C273.378571,772.702576 269.832886,775.811401 269.394470,771.753906 
	C268.952881,767.667542 273.882843,767.704773 276.413818,766.444885 
	C278.224396,765.543579 281.169708,767.387207 281.620483,770.408020 
	C281.708801,771.000000 281.272125,771.670349 281.037567,772.651062 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M300.999573,763.115601 
	C300.831482,765.406311 301.676392,767.932434 299.156097,768.601868 
	C296.609497,769.278198 296.182343,766.508911 295.100586,765.059692 
	C293.103638,762.384338 294.404327,759.725769 295.630219,757.195251 
	C296.009583,756.412170 296.901855,756.070984 297.738190,756.574951 
	C300.026245,757.953491 301.330170,759.903748 300.999573,763.115601 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M277.958008,665.348755 
	C279.171997,665.160767 280.342743,665.321533 281.757385,665.741089 
	C282.327881,667.769165 282.751251,669.527222 282.955688,671.310425 
	C283.188812,673.343811 281.826569,674.323425 280.112885,674.768982 
	C278.068298,675.300659 276.179352,675.011353 274.794617,673.167114 
	C273.470062,671.402893 274.417847,670.121338 275.643188,668.855774 
	C276.551361,667.917786 277.644867,667.099792 277.958008,665.348755 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M293.612640,649.009155 
	C294.180573,651.427734 293.510376,653.934875 294.839050,656.635254 
	C294.037292,658.716125 293.411102,661.282959 291.262756,657.681946 
	C289.715729,655.088867 288.425262,652.006287 284.549774,651.801086 
	C283.092682,651.723938 282.779327,650.316345 283.351929,649.126038 
	C284.093628,647.584229 285.987000,647.581848 286.778229,648.315735 
	C288.897552,650.281189 291.012848,649.485413 293.612640,649.009155 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M193.481186,674.217773 
	C195.562439,672.287170 197.619492,670.649353 199.775208,669.153870 
	C201.847641,667.716125 203.907455,667.684204 205.330826,670.047913 
	C206.790161,672.471436 205.015915,673.887451 203.226807,674.788940 
	C200.027237,676.400940 196.683945,677.105286 193.481186,674.217773 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M415.183655,811.744629 
	C410.956879,811.782410 407.004761,810.463257 402.449829,811.000000 
	C401.881226,810.689575 401.689484,810.433838 401.212402,810.116455 
	C401.127625,808.767517 401.575256,807.375427 402.835663,807.350281 
	C407.500214,807.256897 412.287445,805.696533 416.895874,808.609863 
	C417.018036,810.196777 416.194702,810.845581 415.183655,811.744629 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M248.999390,619.998779 
	C250.927811,620.098022 253.266769,619.581726 253.796814,622.309021 
	C254.424606,625.539246 251.362808,624.699036 249.880508,625.578247 
	C247.823654,626.798340 245.582306,627.417603 243.222565,626.822083 
	C241.743790,626.448975 240.297333,625.571655 240.491470,623.803833 
	C240.674179,622.140198 242.234543,622.089417 243.582855,622.008850 
	C245.352356,621.903198 247.293930,622.421021 248.807495,620.325684 
	C248.999100,620.000000 249.000000,620.000000 248.999390,619.998779 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M266.110596,750.993896 
	C264.551910,747.421204 266.812958,745.086243 268.200592,742.654907 
	C268.740082,741.709656 270.272614,740.856140 271.679962,741.600769 
	C273.072510,742.337524 273.059418,743.708008 272.795105,745.064087 
	C272.545502,746.344666 272.114563,747.609802 272.030792,748.899109 
	C271.723907,753.622253 269.606201,753.807373 266.110596,750.993896 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M173.631256,614.047852 
	C175.257965,613.745911 176.504333,613.378967 177.773712,613.262268 
	C180.076920,613.050659 181.264191,613.883240 180.132080,616.691406 
	C178.815323,616.996704 177.762497,617.184326 176.841248,618.119385 
	C176.169174,618.801392 175.262527,619.415039 174.351791,619.668152 
	C171.783936,620.381714 169.215210,619.566650 168.748962,617.028015 
	C168.141724,613.721558 171.963501,615.486694 173.631256,614.047852 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M395.244934,806.605347 
	C394.554596,806.998230 394.110443,806.998962 393.333130,806.999817 
	C387.917511,804.804016 382.588104,806.484253 377.391754,806.030945 
	C375.255585,805.844604 373.089661,806.000000 370.468628,806.000000 
	C369.071960,805.526428 368.599915,804.845459 369.342529,803.475647 
	C377.180634,801.260193 384.810364,802.521606 392.366180,802.219299 
	C394.568848,802.131165 396.022125,803.646606 395.244934,806.605347 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M152.999542,613.666687 
	C146.369705,613.534851 144.443802,612.089355 145.024796,608.133728 
	C145.225906,606.764465 145.888260,606.111023 147.608871,606.015808 
	C148.808701,605.245483 149.751068,604.905579 151.229492,605.165894 
	C153.932083,606.800537 154.210983,608.855164 153.065460,611.628296 
	C152.999481,612.444458 152.999588,612.888916 152.999542,613.666687 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M225.802750,592.266357 
	C229.143005,592.471069 232.096603,591.209717 235.582031,591.001709 
	C237.794312,594.424377 236.576920,595.990479 232.894043,595.990234 
	C230.744873,595.990112 228.553726,596.186951 226.458191,595.836548 
	C224.321411,595.479126 223.532471,594.246582 225.802750,592.266357 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M396.117798,830.721558 
	C392.779175,831.469482 390.745911,835.557983 386.429199,833.998413 
	C385.760284,832.877686 385.521759,831.757812 385.141602,830.319275 
	C386.470917,828.179565 388.823639,829.630554 390.743408,828.260986 
	C391.816986,826.808228 392.880524,826.180054 394.684387,826.763611 
	C395.718109,828.038940 397.479309,828.700989 396.117798,830.721558 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M281.389618,752.666077 
	C277.978302,753.404968 276.643768,751.529541 276.428711,748.994812 
	C276.292175,747.385254 277.184204,745.552368 279.094269,745.275208 
	C281.230469,744.965332 283.098816,746.151672 284.139648,747.880127 
	C285.660248,750.405212 282.916077,751.154541 281.389618,752.666077 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M201.226196,658.351929 
	C202.187454,656.879944 203.391388,656.988892 204.536194,657.033630 
	C207.009445,657.130066 208.757614,658.319336 208.838196,660.879211 
	C208.913437,663.269653 206.882111,663.715515 205.036118,663.921875 
	C202.731262,664.179504 201.121552,663.340393 201.041397,660.725891 
	C201.021149,660.065796 201.077805,659.403259 201.226196,658.351929 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M276.039673,680.643188 
	C273.449860,680.615906 270.589386,682.008789 268.195129,679.328247 
	C268.217621,677.783691 266.477844,676.253479 268.008728,675.433716 
	C270.375885,674.166138 272.795135,675.404175 275.087799,676.210327 
	C277.388062,677.019165 276.555756,678.729309 276.039673,680.643188 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M328.641113,759.842163 
	C328.998962,761.066711 328.999146,762.133362 328.999054,763.600037 
	C328.848236,764.068726 328.694305,764.131042 328.547668,764.207275 
	C326.352692,765.347534 324.127930,766.961670 321.791168,764.632202 
	C320.596161,763.440979 320.612885,761.705444 321.521149,760.503174 
	C323.372498,758.052673 325.890472,758.888062 328.641113,759.842163 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M368.707886,803.670837 
	C369.258881,804.495544 369.517761,804.991089 369.888306,805.743286 
	C368.715851,808.671265 367.138458,810.755859 363.623230,809.937866 
	C360.624146,809.240051 361.075592,806.941711 361.000244,804.416992 
	C362.753571,802.775940 364.669586,802.265625 367.219543,802.800659 
	C367.900452,803.060730 368.158081,803.201172 368.707886,803.670837 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M224.102112,668.000000 
	C221.869827,667.561096 218.681824,669.099731 218.592224,666.601807 
	C218.494781,663.885010 221.680008,665.214233 223.413910,665.062012 
	C226.041794,664.831482 228.706345,664.987732 231.354279,665.036072 
	C232.135132,665.050293 233.176285,664.948364 233.339233,666.024475 
	C233.533066,667.304504 232.491608,667.790222 231.509003,667.853149 
	C229.200256,668.001160 226.879562,667.962891 224.102112,668.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M279.720093,732.301575 
	C281.369598,734.495667 281.477875,736.227234 279.074829,737.376648 
	C277.385712,738.184570 275.559784,738.476379 274.005585,737.156799 
	C272.694336,736.043457 272.729065,734.476929 273.624237,733.156006 
	C275.130920,730.932617 277.095612,730.262573 279.720093,732.301575 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M395.222839,826.747742 
	C393.895081,827.309631 392.791382,827.616821 391.343933,827.962036 
	C390.236847,826.434082 387.173523,827.707092 387.348083,825.416809 
	C387.505951,823.346008 389.764740,824.360779 390.997681,823.789185 
	C391.889008,823.376038 392.673523,822.683899 393.593201,822.390808 
	C395.360077,821.827881 397.556458,821.387878 398.386475,823.436768 
	C399.344727,825.802185 396.623901,825.621094 395.222839,826.747742 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M295.049683,672.622314 
	C293.933319,673.000000 292.866669,673.000000 291.399994,673.000000 
	C289.346497,669.120361 291.119537,665.870605 292.935364,661.748718 
	C294.726959,665.582642 297.151733,668.403687 295.049683,672.622314 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M153.364288,611.839233 
	C151.427780,610.442871 153.540695,608.244751 152.035416,606.336060 
	C154.593231,603.701782 157.414917,604.033630 160.658264,605.642212 
	C162.113312,607.299561 162.376129,608.709106 161.276230,610.628418 
	C158.572891,611.182678 156.064835,610.510010 153.364288,611.839233 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M275.992523,632.633057 
	C274.864716,633.056519 273.619751,632.872009 273.062622,634.659485 
	C270.092590,635.017700 268.147125,633.819702 268.111908,630.704651 
	C268.089142,628.689514 269.792694,627.556152 271.361176,627.526733 
	C274.250946,627.472534 275.598053,629.548645 275.992523,632.633057 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M345.000000,679.399963 
	C346.956604,679.014648 349.028778,678.631470 350.837006,679.156860 
	C352.615265,679.673401 356.044006,680.033508 353.183807,683.743591 
	C350.629425,684.000488 348.258881,684.000977 345.444153,684.001953 
	C344.872070,683.835876 344.744110,683.669250 344.806244,683.251343 
	C344.997589,681.933289 344.998810,680.866638 345.000000,679.399963 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M392.913147,812.362061 
	C394.388885,812.000000 395.777802,812.000000 397.583344,812.000000 
	C397.556335,815.083191 390.041534,819.106689 387.528107,817.553284 
	C387.039551,817.251282 386.532227,816.575684 386.490479,816.036987 
	C386.397400,814.835754 387.366699,814.415527 388.375092,814.119263 
	C389.866791,813.681030 391.823242,814.715149 392.913147,812.362061 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M161.430664,610.998230 
	C161.000504,609.609375 161.000397,608.221191 161.000458,606.416504 
	C164.013000,607.008606 168.634903,603.501160 169.987183,609.596924 
	C167.433411,611.527283 164.596405,610.852417 161.430664,610.998230 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M249.142166,704.276367 
	C252.698639,702.152954 256.473022,702.875732 260.624695,703.831848 
	C261.757416,705.159790 261.890991,706.276428 260.314514,707.441162 
	C257.482147,708.897034 255.074371,708.926636 252.323532,707.346375 
	C251.094238,706.185852 250.189011,705.369263 249.142166,704.276367 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M400.343597,837.958252 
	C399.555542,838.000000 399.111115,838.000000 398.333313,838.000000 
	C396.860870,837.610657 396.381378,836.819092 396.777039,835.314758 
	C396.999878,834.555542 397.000244,834.111084 397.000916,833.333313 
	C398.414276,833.032043 399.783142,832.948120 400.835327,831.300659 
	C401.683167,830.399170 402.460541,830.239075 403.662964,830.752380 
	C405.475677,834.543152 403.897186,836.620361 400.343597,837.958252 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M240.750107,753.000000 
	C241.328781,753.000000 241.669586,752.942871 241.984406,753.009155 
	C244.208008,753.477661 248.045059,751.690552 248.281433,754.830811 
	C248.549545,758.393127 244.459091,756.603943 242.380356,757.260132 
	C238.495926,758.486328 239.317444,755.890076 239.960495,753.348999 
	C240.000412,753.000000 240.500214,753.000000 240.750107,753.000000 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M251.783752,707.282043 
	C254.369110,707.002869 256.738770,707.003296 259.552399,707.003601 
	C260.146637,707.169006 260.296906,707.334534 260.223602,707.750000 
	C258.544586,710.579102 253.133942,712.226135 250.995850,710.636780 
	C249.280502,709.361694 250.629486,708.452759 251.783752,707.282043 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M404.419312,831.001465 
	C403.248199,831.001648 402.498810,831.000916 401.374725,831.000061 
	C400.444916,825.680908 404.385345,827.254272 407.567322,826.998535 
	C408.444855,826.998535 408.888519,826.999573 409.664856,827.001465 
	C410.593231,831.320251 407.702301,831.141235 404.419312,831.001465 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M232.452393,598.089722 
	C234.100311,598.760864 235.401718,599.373657 233.816971,600.960754 
	C233.593643,601.184448 233.267685,601.341248 232.960587,601.440063 
	C230.565674,602.211121 228.148972,602.397400 225.776047,601.423828 
	C225.507858,601.313721 225.235474,600.381958 225.396942,600.184509 
	C227.137253,598.057190 229.585495,597.968872 232.452393,598.089722 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M290.800354,780.333740 
	C291.166870,780.000000 291.333740,780.000000 291.750916,780.000000 
	C292.001007,781.066711 292.000793,782.133362 292.000916,783.600037 
	C289.240051,784.110352 286.632294,785.460999 283.795288,784.994202 
	C282.702606,784.814453 281.257629,785.017334 281.391144,783.331421 
	C281.490967,782.071045 282.700928,782.146057 283.664062,782.011047 
	C285.998596,781.683899 288.633514,782.886475 290.800354,780.333740 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M341.060303,700.665283 
	C339.879181,700.761292 338.758392,700.522522 337.318787,700.141846 
	C337.004852,699.512756 337.012421,699.025513 337.014099,698.538269 
	C337.020416,696.713562 337.650116,695.323242 339.661621,695.086121 
	C340.953491,694.933777 342.431396,694.789551 343.220306,696.061340 
	C344.706879,698.457703 341.350250,698.661682 341.060303,700.665283 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M268.398926,657.000000 
	C267.999054,654.629456 267.999329,652.258850 267.999207,649.444153 
	C269.066071,649.000000 270.133392,649.000000 271.600952,649.000000 
	C272.001129,651.370544 272.001038,653.741150 272.000488,656.555847 
	C270.933014,657.000000 269.866058,657.000000 268.398926,657.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M267.341705,712.222290 
	C265.097351,711.838928 263.628601,710.933105 262.264160,709.197388 
	C261.636841,708.307251 261.903900,707.755798 262.695770,707.126953 
	C265.498108,707.121155 268.258087,706.455872 269.927612,709.641357 
	C270.308990,711.839417 269.296143,712.426208 267.341705,712.222290 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M245.128265,638.601868 
	C245.041519,637.707397 245.006149,637.209045 245.018204,636.711853 
	C245.055954,635.155823 245.237473,633.635681 247.149750,633.297607 
	C248.111801,633.127502 248.795380,633.705322 248.847336,634.651611 
	C248.954407,636.601013 249.758896,638.877686 248.227325,640.365356 
	C247.021011,641.537109 245.830902,640.352478 245.128265,638.601868 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M333.661072,683.703003 
	C334.599884,685.635193 336.051971,687.421448 333.765137,688.762146 
	C332.720490,689.374512 331.365509,690.265808 330.067902,689.262390 
	C328.276978,687.877502 329.569366,686.255981 329.955139,684.363403 
	C330.954407,682.954163 332.102661,682.981262 333.661072,683.703003 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M344.597443,683.001221 
	C344.997162,683.167053 344.998016,683.334167 344.999420,683.751831 
	C345.000000,684.002441 345.000000,684.500000 345.000000,684.748779 
	C344.478363,685.421814 344.331909,685.947754 344.785828,686.789185 
	C344.368500,689.726257 342.594879,689.073608 340.858704,688.359680 
	C339.724792,687.893372 338.840393,687.040527 339.157776,685.745789 
	C339.810638,683.082458 342.078735,683.119080 344.597443,683.001221 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M249.000305,673.000000 
	C249.001373,671.533508 248.925858,670.061890 249.025391,668.602112 
	C249.116409,667.267212 248.519028,665.217896 250.795288,665.338257 
	C252.962067,665.453003 253.047562,667.377197 252.940186,669.050659 
	C252.810898,671.065796 251.605438,672.290222 249.363586,672.950684 
	C248.999390,673.000000 249.000000,673.000000 249.000305,673.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M267.073547,712.351196 
	C268.137695,711.968811 269.068085,711.546387 269.851776,710.283691 
	C270.916077,710.299866 271.862915,710.530029 272.740234,710.917664 
	C273.797150,711.384583 274.957367,712.047302 274.513184,713.414307 
	C274.033020,714.892212 272.609741,715.209045 271.291351,714.824768 
	C269.807281,714.392212 268.004517,714.415588 267.073547,712.351196 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M276.551300,757.662659 
	C275.492981,759.807678 274.320129,760.837280 272.890991,758.544128 
	C272.034790,757.170227 271.088470,755.202087 273.263611,754.356140 
	C275.613251,753.442383 276.404755,755.302185 276.551300,757.662659 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M231.116333,637.618164 
	C231.092148,635.648560 230.755692,633.792358 233.047760,633.459717 
	C233.963333,633.326904 234.546890,634.114136 234.748520,634.917419 
	C235.108810,636.352966 235.356293,637.929199 234.126068,639.042664 
	C232.686279,640.345703 231.819916,639.315613 231.116333,637.618164 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M300.007385,641.373169 
	C302.188812,641.264709 303.646606,642.475098 303.883453,644.645630 
	C304.127899,646.886047 302.750763,648.212769 300.355469,648.951416 
	C300.182770,646.580872 301.032928,644.165649 300.007385,641.373169 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M206.797089,616.502014 
	C205.918106,613.269531 207.824890,612.987610 210.010757,613.092285 
	C211.148804,613.146729 212.000916,613.815002 211.806854,614.970703 
	C211.277298,618.124268 208.893280,616.728088 206.797089,616.502014 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M280.998230,794.617798 
	C278.629700,795.002441 276.259399,795.002441 273.445007,795.002441 
	C274.436127,792.237000 277.307526,791.883423 279.830597,791.435974 
	C281.143799,791.203064 280.868591,793.145752 280.998230,794.617798 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M357.248779,710.000000 
	C356.998779,710.000000 356.997955,709.501221 356.997742,709.251831 
	C356.132599,708.042786 355.103149,707.153992 355.772095,705.315674 
	C357.205750,703.820923 358.925262,702.238464 360.216431,703.622009 
	C362.749695,706.336609 358.259674,707.145935 358.048279,709.645874 
	C357.998779,710.000000 357.498779,710.000000 357.248779,710.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M368.235962,803.656006 
	C365.958344,804.000000 363.916656,804.000000 361.437500,804.000000 
	C361.337036,803.186523 361.784393,802.381653 361.097595,801.247986 
	C363.041656,801.000000 365.083344,801.000000 367.562500,801.000000 
	C368.643250,801.671448 368.753296,802.451782 368.235962,803.656006 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M251.519409,632.863892 
	C248.993591,630.609863 251.021347,629.907104 252.531326,629.455872 
	C253.875076,629.054199 255.567413,629.751587 255.603333,631.170959 
	C255.672256,633.895630 253.320602,632.705933 251.519409,632.863892 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M251.280273,648.687256 
	C249.473541,648.767639 247.121338,649.862183 247.437729,647.061646 
	C247.608002,645.554443 249.513626,644.838989 251.026535,645.297607 
	C252.880692,645.859741 253.282089,647.118835 251.280273,648.687256 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M334.111481,683.745239 
	C332.933319,684.002441 331.866669,684.002441 330.400024,684.002441 
	C329.686493,683.213745 329.372986,682.425049 329.029114,681.318176 
	C330.817566,681.148743 332.693176,680.626587 334.728149,681.730896 
	C334.742615,682.496033 334.482788,682.992004 334.111481,683.745239 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M237.254623,630.256104 
	C238.767578,628.737305 240.038635,628.596252 240.781860,630.533569 
	C241.081543,631.314697 240.831909,632.288391 239.977997,632.602112 
	C238.212540,633.250549 237.116348,632.667236 237.254623,630.256104 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M220.878113,673.992432 
	C219.537613,673.925720 218.493469,674.187256 217.440659,672.988586 
	C219.507065,671.742615 221.481369,671.058167 223.163742,673.141235 
	C223.573257,673.648254 222.010345,673.855469 220.878113,673.992432 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M265.000000,684.377075 
	C266.478699,684.007874 267.978943,683.880188 269.662842,684.850952 
	C270.000000,685.444946 270.000000,685.889954 270.000000,686.668701 
	C268.611115,687.001770 267.222198,687.001160 265.416656,687.000244 
	C265.000000,686.250610 265.000000,685.501160 265.000000,684.377075 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M262.970001,706.682068 
	C262.741119,707.495544 262.482239,707.991089 262.111694,708.743286 
	C260.919464,709.899780 260.964294,708.428528 260.200012,708.120300 
	C260.000000,708.000000 259.998383,707.501709 259.997375,707.252563 
	C260.840912,706.444397 260.988770,705.587830 260.996490,704.333740 
	C261.166870,703.997864 261.333740,703.998230 261.750854,703.999268 
	C262.314117,704.788025 262.627045,705.576111 262.970001,706.682068 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M201.334747,680.958130 
	C201.000000,680.250000 201.000000,679.500000 201.000000,678.375000 
	C201.918655,678.136536 203.213669,677.692139 203.484894,678.994690 
	C203.741348,680.226074 202.642929,680.612366 201.334747,680.958130 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M345.000000,686.666260 
	C344.360931,686.899719 343.594666,686.844482 343.550995,686.042542 
	C343.534576,685.741211 344.114258,685.407471 344.711121,685.042969 
	C345.000000,685.442566 345.000000,685.887512 345.000000,686.666260 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M879.780518,331.000000 
	C882.403320,331.553406 886.305542,329.605774 886.259949,333.183044 
	C886.222290,336.139618 882.491394,334.870667 880.372192,334.942810 
	C875.216187,335.118439 870.038391,335.217194 864.891724,334.936157 
	C860.175964,334.678650 857.470276,336.607880 857.079102,341.218506 
	C856.811890,344.367767 855.146851,345.008636 852.445312,345.041016 
	C849.835449,345.072296 847.763977,344.856018 847.862183,341.469330 
	C847.884399,340.702576 847.270081,339.935425 847.051636,339.140167 
	C846.521423,337.210297 842.408875,336.582550 845.039917,333.497345 
	C847.424622,330.701050 850.445068,328.235138 854.041138,329.338501 
	C862.409912,331.906372 870.888306,330.605835 879.780518,331.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M821.312988,336.999512 
	C815.772827,335.965820 810.754028,334.004791 805.563721,336.885773 
	C804.532715,337.458038 802.688843,336.850647 802.500732,335.201111 
	C802.300781,333.447449 803.819275,333.028809 805.200195,333.054077 
	C809.429565,333.131439 813.486084,330.029022 817.970459,332.813049 
	C819.377686,333.686707 822.068359,333.606598 823.741211,332.416046 
	C828.959778,328.701904 833.521912,332.806976 838.355896,333.423798 
	C839.368042,333.552948 839.966614,334.643250 839.660522,335.756012 
	C839.329468,336.959595 838.218079,336.972931 837.220398,336.976837 
	C832.072144,336.997131 826.923767,336.994507 821.312988,336.999512 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M917.224304,407.800537 
	C913.313782,406.530426 912.501831,403.885406 913.316284,400.595490 
	C914.144714,397.249237 915.124390,394.181061 910.547852,392.490234 
	C908.273010,391.649780 908.819092,389.074341 909.243103,387.173157 
	C909.768982,384.815125 911.844849,385.003876 913.686707,385.036224 
	C915.958679,385.076141 917.034973,386.342468 916.963135,388.519104 
	C916.891968,390.678467 917.734131,392.197754 919.308899,393.710266 
	C921.462097,395.778442 920.097168,405.654358 917.224304,407.800537 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M912.963928,454.771912 
	C910.391357,462.311523 906.061218,465.585175 900.122986,465.014099 
	C898.488953,464.856964 897.129944,464.651917 897.044739,462.619476 
	C896.971375,460.870239 896.983398,459.267151 899.328003,458.957306 
	C902.333740,458.560120 904.003845,456.691772 904.591370,453.778229 
	C905.029968,451.602753 906.378845,450.882019 908.545105,450.963928 
	C910.935852,451.054291 912.924377,451.372650 912.963928,454.771912 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M855.950806,311.664459 
	C857.486694,313.050293 858.815857,313.814331 860.544312,314.157257 
	C862.338562,314.513245 864.306030,315.571289 863.305786,317.827972 
	C862.407104,319.855560 860.577942,320.631561 858.446411,318.968414 
	C856.854675,317.726501 855.081177,316.916809 852.987183,316.991821 
	C850.395325,317.084717 848.839905,316.028503 849.076294,313.214020 
	C849.312439,310.403320 851.521851,311.103699 853.209778,311.037659 
	C854.006104,311.006531 854.815063,311.297607 855.950806,311.664459 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M893.002930,380.983093 
	C888.499695,380.901642 888.997925,377.940369 889.039978,375.304138 
	C889.081482,372.706848 890.703430,372.001587 892.996460,372.001465 
	C895.286194,372.001343 896.916931,372.696838 896.959045,375.296906 
	C897.001770,377.930939 897.506348,380.894928 893.002930,380.983093 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M853.318726,399.860229 
	C852.509705,399.550598 852.039490,399.363586 851.677307,399.051208 
	C849.976685,397.584290 849.426331,395.849335 850.794739,393.891449 
	C851.678711,392.626648 852.844604,391.751587 854.539978,392.365479 
	C856.044189,392.910187 856.991699,393.838928 856.997437,395.594147 
	C857.005066,397.923584 856.700745,399.919098 853.318726,399.860229 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M894.439209,368.610413 
	C892.552551,369.080902 890.686707,369.060455 890.533997,367.280548 
	C890.376648,365.446716 890.934021,363.379944 891.755005,361.696075 
	C892.276184,360.627106 894.308838,360.830719 894.528137,362.000763 
	C894.916931,364.074951 896.640198,366.250366 894.439209,368.610413 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M832.570435,297.000000 
	C833.000244,297.444458 833.000427,297.888885 833.001587,298.666687 
	C832.453125,299.362305 831.893433,299.709930 831.356445,300.089661 
	C829.849487,301.155365 828.303528,301.414337 826.884705,300.050537 
	C826.484375,299.665741 826.153809,298.772888 826.323425,298.306702 
	C827.017456,296.398987 828.787292,297.178680 830.162598,297.013947 
	C830.813293,296.935974 831.480957,297.000183 832.570435,297.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M873.664368,494.561279 
	C870.843567,494.136475 870.445923,492.666626 871.959412,490.885040 
	C873.011963,489.646027 874.805237,489.553345 875.855042,490.752106 
	C877.925537,493.116364 875.350159,493.563507 873.664368,494.561279 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M867.611694,499.355347 
	C869.789368,499.039917 872.101196,498.896851 872.228394,501.184814 
	C872.308167,502.619263 870.086365,503.138031 868.588562,502.864624 
	C866.629150,502.506958 865.388855,501.531464 867.611694,499.355347 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M820.493042,327.464294 
	C819.399902,329.782928 817.835449,329.288757 816.482849,328.172760 
	C815.844299,327.645874 816.114136,326.556427 816.815857,325.964935 
	C818.585388,324.473328 819.428223,326.041412 820.493042,327.464294 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M822.299255,306.823059 
	C822.537720,305.298431 823.240417,304.839081 824.315308,305.604675 
	C824.599731,305.807312 824.789917,306.547607 824.645813,306.874664 
	C824.097473,308.119049 823.246948,307.965485 822.299255,306.823059 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M875.859741,480.448792 
	C876.901733,480.047882 877.755737,479.887970 878.299683,480.688416 
	C878.407715,480.847412 877.996277,481.657318 877.712708,481.729767 
	C876.786194,481.966461 875.702698,482.197906 875.859741,480.448792 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M919.000000,438.999390 
	C919.490906,439.293243 919.981750,439.587677 920.472656,439.882141 
	C920.301514,440.081451 920.130371,440.280792 919.959229,440.480133 
	C919.665955,440.168335 919.372620,439.856537 919.039673,439.271759 
	C919.000000,438.998779 919.000000,439.000000 919.000000,438.999390 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M842.429810,328.642792 
	C842.638916,329.388580 842.378052,329.659149 841.798889,329.543762 
	C841.678589,329.519775 841.516785,329.349152 841.498596,329.227570 
	C841.411499,328.642670 841.695923,328.397461 842.429810,328.642792 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M711.701782,773.697021 
	C712.880737,777.305359 715.614929,776.907898 718.064087,776.985474 
	C720.227661,777.054077 722.395630,777.033875 724.560669,776.995605 
	C726.970276,776.952942 728.976257,777.413635 728.958313,780.412109 
	C728.941589,783.195740 727.470215,784.853638 724.709595,784.938599 
	C720.215698,785.076904 715.696045,785.252136 711.222107,784.928833 
	C706.451843,784.584167 704.266418,786.254211 705.000000,791.570923 
	C704.504517,792.258911 704.008972,792.517822 703.256714,792.888306 
	C701.726440,794.090881 701.074951,795.415649 700.864929,797.139465 
	C700.585510,799.432251 699.297302,800.977356 696.367981,800.993164 
	C694.335876,801.002747 692.670593,801.046570 691.007874,800.998962 
	C688.342407,800.922668 686.754944,802.098511 687.049072,804.837463 
	C687.481628,808.864502 685.131775,809.310852 681.911621,810.427551 
	C676.226929,812.398926 671.629700,808.594727 666.428101,808.783447 
	C666.151489,808.793457 665.677979,808.536682 665.607056,808.307800 
	C663.327759,800.950928 657.196655,802.305542 651.676453,801.932190 
	C648.263550,801.701355 646.066223,803.228760 643.907593,805.499084 
	C642.587952,806.887085 640.999939,808.247192 638.416077,807.434875 
	C639.270996,804.913025 643.952515,801.132507 636.382324,801.000854 
	C632.300598,803.765991 628.184937,802.726440 623.691040,802.166504 
	C620.926636,800.444580 618.315186,800.257080 615.638550,800.522766 
	C613.467102,800.738342 611.345520,800.740845 608.993530,799.431763 
	C608.010315,794.733459 608.765137,793.646240 613.424744,793.371277 
	C619.899414,792.989075 626.394775,793.375000 632.875366,793.172974 
	C635.106628,793.103394 637.584778,793.809021 639.697937,791.490051 
	C640.229858,790.608459 640.615601,790.172913 641.418823,789.550903 
	C644.802429,788.039307 647.992493,788.623169 651.112122,788.580200 
	C656.001221,788.512878 660.545471,787.198120 664.769287,784.893921 
	C666.935486,783.711975 669.145874,783.386475 671.470398,783.526672 
	C676.045532,783.802551 679.667053,782.733337 680.905151,777.214111 
	C681.818359,775.950012 682.740662,775.357666 684.187927,774.851318 
	C690.559814,776.065125 696.634644,773.165344 702.642578,775.182190 
	C704.154785,775.689819 705.422363,774.381226 706.984680,773.311401 
	C708.736023,772.661316 710.093445,772.664978 711.701782,773.697021 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M625.750488,864.809509 
	C625.945068,872.140625 623.474609,873.720215 617.321777,870.171875 
	C615.049988,867.585449 612.249146,866.579895 609.256470,865.234985 
	C608.653625,861.066162 608.864380,857.999146 614.235474,857.553284 
	C617.267334,857.301575 620.236572,854.971436 619.892334,851.104797 
	C619.608398,847.914551 617.458496,846.239746 614.202942,845.993347 
	C610.388916,845.704651 607.006042,846.448486 606.166443,850.705566 
	C605.771179,852.709900 604.929871,853.924866 603.498657,855.144897 
	C602.657166,855.862183 602.013000,856.947144 601.585388,857.991699 
	C598.579590,865.334412 598.607056,865.280151 590.828979,865.027954 
	C587.333252,864.914612 585.830994,864.116638 588.643005,860.821350 
	C590.882141,858.197388 589.951172,857.015991 586.380493,857.003418 
	C584.481323,858.052368 582.711304,857.896362 580.685059,858.070801 
	C577.948914,858.325867 578.376343,859.692200 579.258667,861.499146 
	C579.399414,862.032715 579.412720,862.255615 579.214233,862.738037 
	C577.111145,868.104065 565.295715,871.132751 561.721558,867.428223 
	C561.073120,866.756165 560.964722,865.944458 561.305603,865.141052 
	C561.892029,863.758728 563.186584,863.327576 564.511047,863.112976 
	C566.736145,862.752563 569.173706,863.506470 571.048035,861.471375 
	C572.627258,859.756897 573.514404,858.670898 570.767029,856.918396 
	C567.874756,855.073425 564.852356,854.402039 561.835205,855.113281 
	C556.453857,856.381714 551.470642,855.678650 546.250427,854.155334 
	C538.832764,851.990784 531.032471,853.730408 523.508423,852.838684 
	C517.727539,852.153687 512.156738,849.913940 507.055298,846.371643 
	C507.247345,845.156860 507.804688,844.567932 508.912415,843.951538 
	C512.674988,842.678894 515.784668,842.808228 518.496826,845.524292 
	C521.495178,848.526978 525.446045,846.934631 529.374634,847.502441 
	C535.398987,847.093323 541.000427,847.040588 547.034668,847.525696 
	C555.501343,850.761658 563.521301,846.359314 571.969238,847.515625 
	C572.664368,847.524292 572.941650,847.524414 573.633301,847.518494 
	C575.137146,847.462280 576.225830,847.288696 577.710693,847.499512 
	C585.651123,846.999695 593.257324,847.917297 601.368164,846.913757 
	C599.804199,844.312988 597.641724,844.842102 595.897339,844.484070 
	C592.961670,843.881592 590.385803,842.969543 590.630005,838.665283 
	C598.096680,834.529236 603.569275,837.406494 608.335205,842.581909 
	C609.829712,844.204834 611.600159,843.961365 613.393372,843.993408 
	C621.999817,844.147339 621.999695,844.139465 622.000122,852.913818 
	C622.000305,855.577942 622.007446,858.242126 622.001587,860.906250 
	C621.997742,862.649902 622.179199,864.280151 624.610962,864.937012 
	C625.166992,864.872192 625.333984,864.744385 625.750488,864.809509 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M625.341431,827.354309 
	C619.147888,824.925781 613.736633,826.906067 608.339233,828.853088 
	C605.700928,829.804871 602.904846,831.574890 601.171326,827.715393 
	C598.861267,822.572144 600.275879,820.493835 605.975586,821.078247 
	C610.663086,821.558899 614.776428,822.481689 619.547424,819.474915 
	C623.109375,817.230164 627.861633,821.566345 632.334351,821.705078 
	C632.487488,821.709778 632.630493,821.871338 632.788940,821.899658 
	C638.839661,822.981934 645.737183,821.775696 649.662903,828.577271 
	C650.083435,829.305847 652.485657,828.907227 653.970642,829.000122 
	C655.459229,829.093201 657.272217,828.927490 657.536133,830.869507 
	C657.792603,832.756348 656.405457,833.902649 654.758789,834.544373 
	C652.968018,835.242126 651.264160,835.114136 649.534912,834.061035 
	C646.049072,831.938171 645.182373,833.948792 644.992737,837.061218 
	C644.851929,839.371948 646.486816,841.103821 646.809204,843.209412 
	C647.102661,845.125854 647.655701,847.407532 645.397583,848.550232 
	C643.456421,849.532410 641.534485,849.069763 639.949768,847.444519 
	C637.744324,845.182800 635.340393,843.094971 633.304260,840.692993 
	C630.760010,837.691650 631.756653,834.558044 635.254333,834.052856 
	C637.726318,833.695862 641.819031,834.603638 641.489014,831.184204 
	C641.132751,827.493103 637.340698,826.083618 633.234253,826.745239 
	C630.742371,829.097839 628.257996,828.870361 625.341431,827.354309 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M601.319458,817.696045 
	C597.870667,818.904480 594.513000,818.751465 592.882935,822.966858 
	C592.023376,825.189819 588.297791,823.698914 585.440552,824.000000 
	C584.805420,823.894409 584.610779,823.788818 584.183167,823.449097 
	C582.153259,820.666382 580.143921,820.914795 577.742065,822.996887 
	C576.854980,823.674683 576.172729,823.981567 575.052795,824.275757 
	C574.387146,824.381836 574.116028,824.410461 573.432739,824.478882 
	C569.328613,824.749451 565.638611,824.919312 561.565430,824.053894 
	C559.702759,823.109619 558.227783,822.493958 556.506958,822.322632 
	C553.845215,822.057800 551.167480,820.894714 551.602966,817.869812 
	C552.112549,814.330750 555.147278,814.592468 557.909119,815.232605 
	C558.232178,815.307495 558.555969,815.379639 559.289062,815.460876 
	C560.741333,815.496155 561.785767,815.337891 563.201050,815.473572 
	C567.075073,814.812988 570.174683,817.552124 573.940063,816.478149 
	C575.186829,815.294495 575.715027,814.010254 576.774719,812.681152 
	C580.408020,810.616760 583.439575,812.113708 586.700867,813.702332 
	C587.869385,810.935669 586.184448,809.205444 584.991882,807.338074 
	C583.110962,804.392578 580.953125,801.504333 582.385498,797.671265 
	C582.898499,796.298401 583.204712,794.545044 585.156189,794.660461 
	C586.834106,794.759705 586.977539,796.334534 587.337952,797.604553 
	C587.815857,799.288818 587.427979,801.389465 589.907959,802.380249 
	C591.000183,802.513916 591.717346,802.693237 592.788147,803.049438 
	C595.991089,805.065002 599.023499,806.669128 601.765747,809.362610 
	C602.699524,812.318481 602.899048,814.887207 601.319458,817.696045 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M733.429993,841.001587 
	C730.958313,841.001587 728.916687,841.000793 726.437500,841.000000 
	C724.794373,838.432434 724.742188,835.451111 721.299377,833.941650 
	C717.566406,832.304871 718.444885,828.605286 719.956238,825.799377 
	C721.739624,822.488586 725.277039,822.099243 728.243713,823.475952 
	C731.285156,824.887268 731.380493,827.542114 729.468018,830.549927 
	C728.201172,832.542358 726.961792,835.377808 729.551025,837.302490 
	C731.536194,838.778259 733.191040,836.937561 734.833862,835.309570 
	C735.381958,834.609924 735.841980,834.456665 736.690063,834.773804 
	C738.978821,837.169678 741.054138,834.779968 743.023438,835.123230 
	C748.341553,836.050354 753.662415,837.013489 758.911926,838.258423 
	C761.365784,838.840271 761.623352,842.676636 759.505798,844.358582 
	C757.940918,845.601685 742.683777,845.446594 741.704590,843.773804 
	C739.739746,840.417297 736.768494,841.049988 733.429993,841.001587 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M497.373474,834.225708 
	C496.582184,833.774719 495.977539,833.244080 495.773834,833.368713 
	C490.410767,836.650391 485.973328,834.445312 481.355042,831.044922 
	C478.678162,832.511353 475.983765,832.084167 473.016357,832.458008 
	C469.260590,832.311890 465.947906,833.573975 462.190796,832.546753 
	C460.611572,832.555054 459.539490,832.272156 458.283295,831.314331 
	C458.000977,829.932129 458.000732,828.866638 458.000854,827.401611 
	C458.601501,826.120361 459.548737,825.894287 460.855774,825.622314 
	C461.919708,825.545959 462.626892,825.507263 463.656128,825.205750 
	C464.479370,825.081726 464.955170,825.060913 465.790710,825.108337 
	C466.858337,825.248535 467.542328,825.507996 468.639282,825.471558 
	C470.762970,825.448853 472.520233,825.381287 474.660950,825.514648 
	C478.464600,825.170532 481.853882,825.736572 485.652985,825.759155 
	C486.862915,825.565063 487.681519,825.526062 488.926697,825.556763 
	C491.299561,825.643127 493.263672,825.289429 495.201843,825.707642 
	C499.378906,826.608948 500.928589,829.118774 499.709045,833.775940 
	C498.902618,834.423035 498.346191,834.539734 497.373474,834.225708 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M675.389771,830.824768 
	C671.287537,828.621582 674.642334,827.502747 675.909668,826.845398 
	C678.375488,825.566406 679.617554,824.658447 677.638489,821.721680 
	C676.152527,819.516724 676.629517,817.344055 680.030640,816.772339 
	C685.414307,815.867249 690.758057,814.752502 696.263611,815.007202 
	C697.824890,815.079468 699.380005,815.242859 699.727295,817.122253 
	C700.045105,818.842163 698.361328,819.200134 697.416809,820.021118 
	C695.873657,821.362610 693.889709,820.637390 692.171753,821.058228 
	C687.582520,822.182556 681.976807,821.698730 680.775879,828.417847 
	C680.344482,830.831970 677.392700,829.899475 675.389771,830.824768 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M487.651489,844.741028 
	C488.817505,845.102783 489.687531,845.063660 490.439789,845.342468 
	C491.488434,845.730957 492.858459,846.126953 492.665710,847.601807 
	C492.458984,849.183350 491.016754,848.971313 489.905396,848.979126 
	C484.932861,849.014038 479.959961,848.998596 474.987213,848.999695 
	C469.848694,849.000854 464.670624,848.608826 459.584015,849.122681 
	C455.450623,849.540283 452.704315,847.666870 449.960907,845.290161 
	C449.044098,844.495850 447.464478,843.620117 449.040100,842.144653 
	C450.332031,840.934753 451.987579,840.904297 452.859558,842.503418 
	C454.368683,845.270996 456.352600,846.169739 459.636047,845.006775 
	C463.142883,842.943726 466.764740,844.046692 470.137207,843.603333 
	C475.850098,842.852356 481.593384,843.853760 487.651489,844.741028 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M609.245361,814.744263 
	C608.849731,817.028198 607.709778,817.971008 605.362244,817.993408 
	C601.892151,814.781921 601.724976,811.433411 604.641541,808.337341 
	C606.208374,806.674072 608.097839,805.388794 610.552979,804.204468 
	C612.342651,803.585999 613.771484,803.573547 615.633667,803.743652 
	C618.497620,804.395081 621.136597,804.077148 623.712280,805.690796 
	C624.162415,807.405762 625.019104,807.986084 626.361145,807.990906 
	C627.872681,807.996338 628.944336,808.512939 628.985107,810.620728 
	C628.603638,811.349548 628.142883,811.494690 627.310059,811.218994 
	C623.971069,811.001648 620.939697,811.000793 617.454163,811.000000 
	C614.994995,813.234985 611.328491,811.893433 609.245361,814.744263 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M517.556519,858.053589 
	C522.370667,856.304016 526.973206,856.907959 531.533691,857.394836 
	C534.792480,857.742798 538.009155,858.476562 541.250244,859.003357 
	C542.199890,859.157654 543.503540,858.977234 543.416260,860.355896 
	C543.346436,861.458923 542.169250,861.639893 541.200012,861.974365 
	C533.891357,864.496704 526.642090,863.326477 519.449951,861.561462 
	C518.220886,861.259888 514.940918,861.405212 517.556519,858.053589 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M646.832458,821.232727 
	C646.149109,820.467651 645.502563,819.988342 645.400635,819.412537 
	C645.162842,818.069214 651.671875,811.057556 653.002014,811.097534 
	C655.082214,811.160095 655.932068,812.423218 655.961853,814.334717 
	C656.008301,817.319031 656.021484,820.304749 655.982056,823.289062 
	C655.962952,824.730652 656.276611,826.481445 654.207520,826.852234 
	C652.351501,827.184937 650.501587,827.198669 649.576721,825.043152 
	C648.994141,823.685486 648.651489,822.172058 646.832458,821.232727 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M462.689240,810.755249 
	C464.389221,810.999817 465.778442,810.999634 467.584442,810.998535 
	C469.873993,808.745483 472.995270,810.243042 475.423828,808.454834 
	C476.984161,807.761047 478.254059,807.654236 479.926788,807.872314 
	C481.755341,808.287109 482.812134,809.462585 484.568878,810.027222 
	C485.277893,810.503845 485.597351,810.865173 485.923035,811.644531 
	C486.000000,812.750000 486.000000,813.500000 486.000000,814.625000 
	C482.953949,817.640442 479.337250,816.665955 475.384338,816.581421 
	C469.475433,816.688049 463.997314,817.716736 458.345947,815.339355 
	C458.005493,813.928589 458.003632,812.864502 458.002747,811.401428 
	C458.337524,810.569580 458.766510,810.275757 459.686462,810.086731 
	C460.880341,810.040039 461.664276,810.100586 462.689240,810.755249 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M487.949707,844.638794 
	C478.939148,845.622498 469.877655,845.508118 460.407928,845.011963 
	C460.456299,841.974548 462.397064,840.894897 465.623108,840.999023 
	C470.293335,838.254517 474.733765,838.719666 479.613892,840.686035 
	C483.058167,841.062988 486.310516,840.666565 487.949707,844.638794 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M645.625977,864.709534 
	C649.004089,865.208618 649.339355,867.874268 650.348450,869.841736 
	C651.421753,871.934326 650.507690,872.964050 648.274536,872.979492 
	C645.626099,872.997803 642.973022,872.897522 640.330383,873.024414 
	C637.813538,873.145325 635.938660,872.185852 635.539673,869.734680 
	C635.113953,867.119568 636.344849,865.269714 639.629272,865.005737 
	C641.617798,863.598816 643.362610,863.352539 645.625977,864.709534 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M696.218018,801.273804 
	C698.121948,799.714661 700.098450,798.376587 700.118042,795.461609 
	C700.126526,794.195190 701.034424,793.416504 702.637207,793.052368 
	C700.797791,801.496826 702.149353,805.483093 709.090271,810.529846 
	C710.250122,811.373230 711.302551,812.139526 710.724609,813.707703 
	C710.112122,815.369995 708.548401,815.071472 707.307617,814.941406 
	C701.953247,814.379883 700.802307,809.790283 699.266174,805.895142 
	C698.577332,804.148499 698.212891,802.472473 696.218018,801.273804 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M455.359741,823.289917 
	C454.425079,821.890198 453.875610,820.778625 454.878174,819.282776 
	C458.435760,816.083191 462.568726,817.546936 466.407227,817.229065 
	C468.374054,817.066223 470.366638,817.220825 472.787781,817.520508 
	C475.292084,818.394043 477.401764,818.135071 479.522217,817.964417 
	C481.477753,817.807007 483.520996,817.559814 484.694336,820.249390 
	C484.627167,822.347961 483.643890,823.314880 481.723328,823.942993 
	C478.808105,824.163574 476.288269,823.197815 473.389374,824.320190 
	C467.210175,824.197205 461.385101,825.521240 455.359741,823.289917 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M480.048706,840.619141 
	C475.642517,840.997559 471.287476,840.997559 466.468262,840.997559 
	C465.694946,839.894226 465.385803,838.790833 465.038330,837.343750 
	C464.884247,836.774231 464.768524,836.548523 464.813446,835.976257 
	C466.762756,834.165039 468.905273,833.323669 471.302521,832.171204 
	C473.276489,832.045959 474.882538,831.786987 476.708649,832.718506 
	C478.166107,835.356873 483.073792,836.108521 480.048706,840.619141 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M624.451233,806.001465 
	C621.527039,805.805664 619.004944,806.451660 616.288879,805.273926 
	C614.821411,800.150635 615.597290,799.578369 622.586975,800.722961 
	C627.026184,800.998413 631.049988,800.999207 635.536865,801.000000 
	C636.392578,804.439636 634.644409,805.920532 631.398682,805.977905 
	C629.234680,806.016113 627.069519,805.995056 624.451233,806.001465 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M419.847656,833.647583 
	C421.549316,836.663452 421.579407,840.019836 423.828674,842.362366 
	C425.475067,844.076965 424.974579,845.625610 423.421173,847.054626 
	C422.484802,847.916077 421.575073,849.376221 420.112823,848.335999 
	C418.553986,847.227112 416.372894,845.913879 417.373260,843.634155 
	C418.676514,840.664368 419.195068,838.739502 414.907684,838.721497 
	C413.927582,838.717407 413.194305,837.694519 413.176758,836.590454 
	C413.141235,834.355957 415.166870,834.276917 416.766907,833.260010 
	C417.997192,832.189514 418.879120,832.442871 419.847656,833.647583 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M474.377899,818.831421 
	C467.978851,819.000000 461.957703,819.000000 455.468262,819.000000 
	C453.616180,816.430542 454.517395,815.177795 457.653564,815.000916 
	C463.359253,814.995728 468.711182,814.998840 474.530579,815.002197 
	C476.021179,816.295654 475.935394,817.515442 474.377899,818.831421 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M658.389221,844.925781 
	C657.172058,844.999573 656.342163,845.035828 655.516541,844.992004 
	C652.647156,844.839844 649.588623,844.717285 649.099060,841.008789 
	C648.572205,837.016541 652.437622,838.316406 654.616333,837.051636 
	C655.489136,837.000427 655.984375,836.950928 656.466370,837.009705 
	C658.757935,837.289001 662.122986,835.907104 662.840820,838.964600 
	C663.428284,841.466797 661.381042,843.723083 658.389221,844.925781 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M752.042603,853.840942 
	C751.680176,849.809998 753.658936,848.779114 756.861633,848.987793 
	C758.975830,849.125610 761.453552,848.644104 761.873352,851.671143 
	C762.141174,853.602112 762.491089,856.275269 759.992798,856.625305 
	C757.253235,857.009094 753.556213,858.766663 752.042603,853.840942 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M536.561951,869.000000 
	C537.926147,870.547058 540.290833,870.420105 540.947388,872.670044 
	C539.728699,874.880432 537.347351,873.831909 535.734680,874.929260 
	C536.434509,876.150635 537.933777,876.313538 538.843018,877.689087 
	C538.470520,880.713867 536.857666,879.841003 535.284607,879.128845 
	C533.196594,878.183594 531.266968,876.750610 528.393433,877.001099 
	C528.090271,876.511169 528.320068,876.001282 528.249878,875.536682 
	C527.260315,868.981323 531.109802,868.297424 536.561951,869.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M610.999268,804.552185 
	C607.348511,808.297424 601.528748,810.479126 604.840881,817.639038 
	C603.965942,819.387634 602.786438,818.896973 601.296692,818.131836 
	C600.998352,815.299988 600.999146,812.599976 601.000000,809.449951 
	C600.216553,806.401062 600.450073,803.880737 602.983459,802.558167 
	C605.855469,801.058716 608.688660,801.702576 610.999268,804.552185 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M414.690918,848.776611 
	C415.656372,852.113220 420.199493,852.556702 419.992493,856.633545 
	C419.998779,857.000000 420.000000,857.000000 419.999390,857.000000 
	C417.074768,859.739990 413.661377,860.068420 409.581482,858.829468 
	C407.427338,856.952148 407.786560,855.028809 408.725494,852.698181 
	C409.787201,850.993591 411.264008,850.259277 412.559998,848.810913 
	C413.377899,848.431335 413.878998,848.414307 414.690918,848.776611 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M455.007141,823.347168 
	C461.021149,823.000000 467.042297,823.000000 473.531738,823.000000 
	C475.574829,823.627869 475.981110,824.775146 475.239227,826.663208 
	C472.958344,826.999756 470.916656,826.999573 468.437500,826.998413 
	C467.607483,826.559387 467.158875,826.205566 466.288849,826.025391 
	C465.555542,826.000000 465.111115,826.000000 464.333313,826.000000 
	C463.403198,826.015869 462.866180,826.195801 462.202026,826.789490 
	C460.933624,826.998657 459.867279,826.999756 458.401062,827.001465 
	C456.597931,826.267700 454.921173,825.780151 455.007141,823.347168 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M624.736084,827.261597 
	C627.370544,826.998535 629.741150,826.998657 632.555847,826.998169 
	C633.852417,828.491638 637.314697,827.875610 636.431580,830.417053 
	C635.605469,832.794189 633.108093,831.478638 631.242188,831.709412 
	C626.983459,832.235962 623.518005,830.051392 618.916016,829.499573 
	C620.890808,827.331787 623.046814,828.545166 624.736084,827.261597 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M403.344971,848.038086 
	C403.001038,847.833313 403.000854,847.666687 403.000366,847.250000 
	C402.999878,846.250000 402.999756,845.500000 402.999207,844.375000 
	C402.999451,843.556091 403.000061,843.112122 403.000977,842.335327 
	C403.549347,841.824707 404.097443,841.646912 404.822784,841.234619 
	C406.617279,839.600586 408.361938,839.353149 410.625916,840.709595 
	C411.974670,842.616516 412.090027,844.397583 411.908508,846.921448 
	C411.376831,848.399292 410.794678,849.040100 409.723206,849.634033 
	C408.735016,849.892578 408.088928,849.818420 407.244080,849.227661 
	C405.896240,848.692017 404.792480,848.384094 403.344971,848.038086 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M637.799683,853.033936 
	C640.197083,853.338013 641.238892,854.568176 640.846558,856.480225 
	C640.384827,858.730835 639.681213,861.203796 637.600830,862.359924 
	C635.741943,863.393005 635.159302,861.270264 634.557922,860.030273 
	C633.518433,857.887146 631.635071,855.582520 634.534119,853.497925 
	C635.240173,852.990234 636.431152,853.157043 637.799683,853.033936 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M658.209778,845.268555 
	C658.074158,844.854858 658.148071,844.709656 658.222473,844.564636 
	C659.260193,842.543396 662.750061,841.428955 662.098511,839.336609 
	C661.240173,836.580322 657.611694,838.849854 655.269897,837.265076 
	C657.205566,835.270203 659.785278,835.650635 661.996765,836.462769 
	C663.611145,837.055603 665.677490,838.445007 663.702454,840.956848 
	C662.555359,842.415833 663.308655,843.685791 664.375488,844.890625 
	C665.319763,845.956726 666.831848,847.221436 665.090088,848.561951 
	C663.752014,849.591675 662.317200,848.693237 661.134705,847.640198 
	C660.281738,846.880737 659.328247,846.234070 658.209778,845.268555 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M627.004028,811.395386 
	C627.446350,811.001892 627.890198,811.001343 628.667053,811.000427 
	C631.952148,811.003540 634.904541,810.987427 637.856323,811.019653 
	C639.172607,811.033997 640.683105,810.996460 640.787109,812.826782 
	C640.912109,815.027100 639.204834,814.953003 637.719910,814.982117 
	C635.916260,815.017456 634.154358,815.408997 632.392944,815.593323 
	C629.814026,815.863037 626.932739,815.674866 627.004028,811.395386 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M453.406555,830.737793 
	C452.326874,831.244629 451.585052,831.693970 450.786621,831.846008 
	C447.425110,832.486267 445.251312,830.757874 444.251770,827.828186 
	C443.491272,825.599121 445.158936,824.945251 447.110321,825.011841 
	C450.506165,825.127686 453.729797,825.529297 454.343842,829.808472 
	C454.374084,830.019287 453.937592,830.296997 453.406555,830.737793 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M609.000000,865.441406 
	C612.336609,865.136047 616.306213,864.074707 616.989624,869.615601 
	C614.546692,870.628479 614.078735,875.314392 610.723022,873.564209 
	C607.583679,871.926880 609.471741,868.492371 609.000000,865.441406 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M584.691833,826.766235 
	C585.454163,827.204773 585.889282,827.491699 586.365356,827.601929 
	C594.131409,829.400085 594.132568,829.395020 591.153076,837.656799 
	C590.794373,838.119263 590.588806,838.238586 590.058594,838.212158 
	C588.160950,836.485168 589.382996,834.216675 588.416443,832.476807 
	C586.484314,831.947388 585.132996,834.098328 582.914429,833.353394 
	C581.142273,830.688721 576.614502,831.444031 576.691528,827.147827 
	C579.327209,825.658691 581.853394,826.038696 584.691833,826.766235 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M497.321228,843.327820 
	C496.835602,840.134155 493.644958,841.674438 492.481354,840.226318 
	C493.451508,838.265808 495.420410,839.523499 496.752197,838.254028 
	C500.708527,835.263000 504.436371,836.515137 508.526855,838.212769 
	C510.763855,842.141296 506.857483,841.980652 505.145386,843.743225 
	C502.337891,844.728577 499.958344,844.923340 497.321228,843.327820 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M465.224243,835.702271 
	C465.001221,836.002441 465.000977,836.501221 465.000488,836.750610 
	C462.871918,836.995361 460.741364,837.052795 458.616547,836.967224 
	C456.801422,836.894043 454.699432,836.722717 454.282104,834.588257 
	C453.880402,832.533752 456.032410,832.132080 457.681549,831.143799 
	C459.067535,830.998657 460.133881,830.999695 461.600098,831.001587 
	C463.109741,832.499817 465.872894,832.701782 465.224243,835.702271 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M509.446716,838.937439 
	C505.271759,838.935974 501.673859,837.420532 497.443817,837.997559 
	C496.999939,836.931580 496.999878,835.865540 496.999878,834.399780 
	C497.443909,833.999451 497.887817,833.998901 498.664642,833.997925 
	C502.636017,831.312195 506.203003,832.547668 509.574707,834.437988 
	C511.535492,835.537292 512.597168,837.200012 509.446716,838.937439 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M496.689758,843.052734 
	C499.371796,842.997131 501.739929,842.996765 504.551880,842.995728 
	C506.851685,841.895508 508.553619,841.385925 509.200226,844.572876 
	C508.503265,845.258728 508.007751,845.517395 507.255493,845.887573 
	C503.829926,846.593262 500.785919,849.547180 497.435455,846.627747 
	C496.429779,845.751404 494.129913,845.049377 496.689758,843.052734 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M737.278809,828.212402 
	C739.919067,825.031311 743.485168,825.484314 746.778625,825.089417 
	C747.821167,824.964355 748.700195,825.535095 748.842468,826.731506 
	C748.991394,827.984924 748.591980,829.368835 747.384399,829.516174 
	C743.978760,829.931885 740.410950,831.225159 737.278809,828.212402 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M626.437256,865.001404 
	C625.833313,865.001953 625.666687,865.001465 625.250000,865.000488 
	C625.087341,862.246948 624.318970,858.979248 628.823608,859.003174 
	C631.046875,859.014954 632.797424,859.458496 632.952881,862.159607 
	C633.108398,864.862732 631.356140,865.046875 629.368591,865.000244 
	C628.537720,864.980713 627.705872,864.999207 626.437256,865.001404 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M585.000000,826.625000 
	C582.590393,827.646423 580.183838,828.518555 577.373291,827.048401 
	C576.557373,826.947510 576.155151,826.790710 575.565857,826.291443 
	C574.843262,825.295959 574.541870,824.488037 574.835083,823.283630 
	C575.550659,822.825256 576.098816,822.648071 576.824585,822.236694 
	C579.531982,820.674744 582.005371,820.397705 584.678101,822.699097 
	C585.000000,823.000000 585.000000,823.500000 585.000000,823.750000 
	C585.000000,824.750000 585.000000,825.500000 585.000000,826.625000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M743.827637,822.334961 
	C740.897156,823.357300 738.220520,823.090698 735.595947,822.845642 
	C733.965515,822.693359 732.543213,821.749390 734.017822,819.848938 
	C735.359985,818.119141 743.224731,818.820007 744.505737,820.726501 
	C744.678223,820.983154 744.279541,821.623474 743.827637,822.334961 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M579.406982,862.997803 
	C579.002441,862.997559 579.001038,862.498779 579.000488,862.249390 
	C577.818115,860.394775 577.691345,858.835571 579.601562,857.173950 
	C581.815918,857.416321 583.519470,855.571228 585.684998,856.867676 
	C585.872070,860.999023 584.298889,863.501892 579.406982,862.997803 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M432.761719,839.865723 
	C432.395935,840.634277 432.171967,841.208557 431.780365,841.366150 
	C429.164093,842.418518 426.639954,842.559631 424.735107,840.009583 
	C424.019135,839.051086 424.358795,837.783386 425.372559,837.578979 
	C428.079193,837.033386 431.229950,835.439880 432.761719,839.865723 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M609.386108,815.009216 
	C608.642456,808.807739 613.383972,811.689819 616.559204,811.000000 
	C617.772156,815.728943 616.384766,816.500244 609.386108,815.009216 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M749.346436,830.916138 
	C750.446594,830.495178 751.212341,830.155334 752.007874,830.056824 
	C754.109924,829.796631 756.090698,830.047852 756.590271,832.570923 
	C756.861328,833.940063 755.910461,834.647583 754.587646,834.822571 
	C752.261292,835.130371 749.309937,833.160400 749.346436,830.916138 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M441.461792,804.095703 
	C443.525879,804.996765 445.765076,806.200806 444.408478,808.108215 
	C442.845581,810.305786 441.284546,808.152527 440.098541,806.821960 
	C438.903809,805.481384 436.768524,806.693848 435.462433,804.878784 
	C437.204102,803.386292 439.240234,804.243530 441.461792,804.095703 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M645.991211,864.619507 
	C644.285645,864.999756 642.571289,864.999512 640.428467,864.998413 
	C640.153748,862.903198 640.146606,860.428955 642.868103,860.210083 
	C645.419250,860.005005 645.680786,862.306702 645.991211,864.619507 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M638.478394,817.417725 
	C640.236328,819.198608 640.392578,820.561401 637.873657,820.899353 
	C636.548950,821.077148 634.448975,821.528992 634.335327,819.530090 
	C634.197327,817.103760 636.186523,816.900269 638.478394,817.417725 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M410.990601,840.616882 
	C409.285645,841.000000 407.571289,841.000000 405.428467,841.000000 
	C404.975342,839.095154 405.290802,837.334900 407.619537,837.109253 
	C409.797668,836.898254 410.897766,838.075256 410.990601,840.616882 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M427.208862,829.754639 
	C425.402954,829.977600 424.890839,828.723389 424.134277,827.297119 
	C425.321472,823.209717 427.520599,825.617004 428.794220,826.686829 
	C431.033600,828.568054 428.313538,828.744751 427.208862,829.754639 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M477.368500,833.000427 
	C475.282257,833.002380 473.569366,833.002319 471.428253,833.002380 
	C470.645508,832.304077 470.958038,831.814087 471.750885,831.198120 
	C474.700012,830.999756 477.400024,830.999451 480.550018,830.999573 
	C480.772339,833.065552 479.231873,832.990845 477.368500,833.000427 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M737.130981,834.690918 
	C736.554260,835.002625 736.110840,835.002747 735.334961,835.003906 
	C734.440735,833.947815 732.529297,833.487366 733.449585,831.797302 
	C733.886230,830.995544 734.822571,830.950073 735.648193,831.138611 
	C737.465698,831.553711 738.153320,832.587463 737.130981,834.690918 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M460.219818,810.690002 
	C459.556335,811.000732 459.112671,811.001465 458.336334,811.002319 
	C455.975708,810.899597 454.068420,810.726624 456.667542,808.062988 
	C457.806610,808.245789 458.610748,808.494019 459.707458,808.871155 
	C460.378418,809.386108 460.524994,809.846130 460.219818,810.690002 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M420.248779,833.785278 
	C419.211121,833.688843 418.423431,833.375183 417.317871,833.031250 
	C417.258881,832.506042 417.517761,832.011230 417.889374,831.260620 
	C418.750977,831.004089 419.499878,831.003235 420.623169,831.002441 
	C420.831299,831.857666 420.665039,832.712830 420.248779,833.785278 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M415.213806,848.783569 
	C414.553589,849.002441 414.110474,849.002502 413.335022,849.002502 
	C411.945282,849.180847 411.640289,848.553650 411.787018,847.334717 
	C411.998779,847.000000 412.000000,847.000000 411.999390,847.000000 
	C413.193695,847.410034 415.239044,845.954712 415.213806,848.783569 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M521.335083,872.988647 
	C520.998352,872.551758 520.999146,872.108459 521.000000,871.332520 
	C521.873718,871.131653 523.153748,870.700745 523.371338,871.912170 
	C523.570618,873.021545 522.388611,872.831909 521.335083,872.988647 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M161.199951,605.665710 
	C158.299667,606.000244 155.599350,606.000488 152.448898,606.001587 
	C150.932785,606.001343 149.866806,606.000244 148.401093,605.998291 
	C147.214874,603.450928 145.186020,602.512634 142.824188,602.037781 
	C142.341614,601.940796 141.828812,601.984375 141.329819,601.980652 
	C139.940048,601.970337 137.899857,601.544250 137.801605,600.597778 
	C137.176834,594.579651 129.899673,594.600830 128.318375,589.951355 
	C127.232811,586.759521 129.458481,584.516357 132.778030,584.118958 
	C135.240601,583.824219 137.762009,584.020264 140.257202,583.998779 
	C142.485397,583.979553 144.944046,584.042480 144.844711,580.890686 
	C144.745346,577.738220 142.193878,578.091003 140.037308,577.993286 
	C135.946045,577.807983 131.857651,579.308289 127.764931,578.009460 
	C127.613251,577.961365 127.433174,578.010193 127.267776,577.996460 
	C126.262650,577.913208 112.499779,568.222168 112.148056,567.287109 
	C110.707153,563.456665 111.570625,559.822449 114.582260,557.454834 
	C117.458900,555.193298 121.181732,554.834778 124.141037,557.744446 
	C126.185463,559.754639 128.085236,559.947266 130.852112,559.366333 
	C135.234314,558.446167 139.769821,558.700989 144.577850,559.988525 
	C145.463852,560.013733 145.911346,560.105774 146.635681,560.459900 
	C150.996033,562.248169 155.103714,561.887390 159.494507,560.703247 
	C159.956299,560.384644 160.149109,560.268066 160.671570,560.076965 
	C167.285095,559.448181 173.518906,559.104431 178.531738,564.044922 
	C176.015518,564.188538 173.583191,561.637756 171.213364,563.636719 
	C169.316132,565.237061 166.396439,567.269897 167.897522,569.993103 
	C169.513702,572.925232 172.663940,573.850342 176.090408,571.998169 
	C177.209259,571.393372 178.524033,569.154297 179.776825,571.079041 
	C181.035309,573.012634 184.967590,573.723145 183.037292,577.660400 
	C180.894531,580.893127 177.704422,581.678406 174.473557,582.363892 
	C172.331406,582.818481 170.026810,583.072998 170.701599,586.567505 
	C170.335495,589.511292 170.773834,592.022522 171.629623,594.802246 
	C170.880844,598.685242 174.548447,597.040283 175.801331,598.748047 
	C176.225388,601.458557 175.388443,602.422852 172.719315,603.273254 
	C168.962524,604.470154 164.740631,602.531982 161.199951,605.665710 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M208.649841,638.321655 
	C205.284668,636.064209 202.651993,637.581177 199.978287,638.992493 
	C198.338211,639.858154 196.614960,640.615723 194.924011,639.113281 
	C193.941681,638.240417 193.872025,637.043518 194.283600,635.903381 
	C194.999405,633.920532 196.794601,634.171997 198.435623,633.961365 
	C203.038925,633.370422 207.428543,630.879028 212.323029,633.723999 
	C214.300217,634.873230 216.642838,635.192566 215.701553,637.970276 
	C214.731995,640.831482 212.431000,640.093506 210.338196,639.149353 
	C209.885773,638.945251 209.444382,638.716675 208.649841,638.321655 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M156.691406,656.782532 
	C158.388855,656.999756 159.778915,656.999573 161.586029,656.998413 
	C165.278885,654.560425 168.715958,654.798584 172.616425,656.690430 
	C174.601715,657.832275 175.937363,659.376160 175.417786,660.908020 
	C174.716736,662.974854 172.926407,661.057678 171.614990,661.119934 
	C168.057907,661.288757 164.567383,660.461426 160.952805,662.200867 
	C157.164749,664.023804 155.895508,660.738647 155.007477,657.370911 
	C155.397430,656.650452 155.858582,656.505493 156.691406,656.782532 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M114.291939,547.240601 
	C112.592247,545.633545 112.814690,544.083069 113.744041,542.240845 
	C118.024025,541.401733 121.847282,543.089111 125.837509,543.050293 
	C127.133461,543.037720 129.318390,542.208313 129.433380,544.437012 
	C129.541946,546.541138 128.485794,548.342773 125.388824,547.997925 
	C125.000305,548.000000 124.500443,547.999268 124.250519,547.998413 
	C120.971848,545.823120 117.624146,549.464233 114.291939,547.240601 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M161.000839,559.626831 
	C161.001221,560.002441 161.000168,560.501221 160.999664,560.750610 
	C156.324738,562.746216 151.557724,562.066589 146.397522,561.272217 
	C145.688354,560.883179 145.432922,560.691223 145.115448,560.214539 
	C144.999176,558.935120 144.999588,557.867737 145.000000,556.400208 
	C147.370743,555.999512 149.741470,555.999023 152.556412,555.998047 
	C155.558777,554.255615 157.961182,555.057068 160.663483,556.721924 
	C160.999756,557.748779 161.000107,558.499939 161.000839,559.626831 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M113.003693,618.802063 
	C114.741379,622.112793 113.522285,624.188477 110.770233,625.521973 
	C108.815979,626.468811 107.171806,625.347595 105.866432,624.087646 
	C104.836891,623.093933 103.780281,621.754578 104.646332,620.136292 
	C105.506584,618.528870 107.026176,617.875916 108.822380,618.020691 
	C110.122986,618.125488 111.413116,618.360413 113.003693,618.802063 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M172.992249,656.629761 
	C169.638687,656.999390 166.278580,656.998779 162.460785,656.997864 
	C162.002243,655.931580 162.001434,654.865540 162.000610,653.399780 
	C162.167175,652.871948 162.333740,652.743896 162.750153,652.807922 
	C167.030258,651.936890 171.640976,649.095581 172.992249,656.629761 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M156.991196,656.621704 
	C156.554733,657.000000 156.110703,657.000000 155.333633,657.000000 
	C154.513000,656.999939 154.015091,657.064148 153.539719,656.987854 
	C152.053299,656.749207 150.005600,657.783813 149.312408,655.661560 
	C148.849075,654.243042 150.082581,653.222168 151.208038,652.571960 
	C153.020203,651.525269 154.723541,652.000366 155.952972,653.600891 
	C156.506012,654.320862 156.652603,655.352966 156.991196,656.621704 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M162.990662,652.617981 
	C163.000000,653.000000 162.500305,653.000000 162.250458,653.000000 
	C160.311951,652.843872 158.196121,653.543518 157.478058,651.229187 
	C157.140121,650.139893 158.088013,649.403015 159.101440,649.207031 
	C161.485214,648.745972 162.846527,649.742310 162.990662,652.617981 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M124.999847,553.000000 
	C125.323700,553.000305 125.657623,552.949341 125.970016,553.009644 
	C127.381020,553.282471 129.516998,552.211792 129.719208,554.798767 
	C129.802261,555.861389 128.972763,556.635254 127.920235,556.750732 
	C125.757133,556.988159 125.141754,555.573059 125.008362,553.377075 
	C124.999695,553.000000 125.000000,553.000000 124.999847,553.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M206.140106,642.551147 
	C205.098129,642.952026 204.244202,643.111816 203.700272,642.311401 
	C203.592255,642.152405 204.003799,641.342590 204.287415,641.270142 
	C205.213898,641.033447 206.297394,640.802063 206.140106,642.551147 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M130.013672,651.525269 
	C130.856339,651.996948 130.957687,652.583618 130.560593,653.158081 
	C130.239883,653.621948 129.729080,653.617676 129.421890,653.140991 
	C129.045181,652.556458 129.152893,651.971375 130.013672,651.525269 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M212.541382,775.975830 
	C209.960434,775.989502 207.814560,775.995972 205.668839,775.981140 
	C203.590271,775.966797 202.057571,775.232361 202.127121,772.858154 
	C202.188202,770.773132 203.591095,770.054993 205.459213,770.031067 
	C207.769592,770.001465 210.080673,770.008972 212.391327,770.022583 
	C213.984177,770.031982 215.424774,770.422180 215.783844,772.226929 
	C216.223450,774.436523 215.210464,775.691162 212.541382,775.975830 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M224.567657,587.001587 
	C221.802917,591.004211 217.370178,589.034485 213.714203,588.692749 
	C209.779572,588.324951 205.860474,588.108093 201.460480,588.000000 
	C200.174850,586.369202 200.190277,584.041504 197.237289,584.407410 
	C195.257996,584.652649 194.041931,585.160095 194.151794,587.692444 
	C193.280273,588.341736 192.554596,588.983643 191.841904,588.969482 
	C189.597443,588.924988 187.453003,592.361816 185.576538,590.090271 
	C183.449966,587.516052 187.482010,586.029297 187.954636,583.358276 
	C188.507187,582.058411 189.339417,581.700806 190.680389,581.840942 
	C194.479584,581.515259 193.891144,579.653503 192.666946,577.389954 
	C191.570755,575.363159 189.251358,575.215149 187.566803,573.849670 
	C191.680939,573.432373 195.597794,571.160278 199.577377,573.075256 
	C204.171860,575.286255 206.279205,571.791870 207.999405,569.125366 
	C209.964172,566.079712 206.796204,566.014587 204.941681,566.056213 
	C199.881607,566.169678 195.028244,566.164795 191.050446,562.131348 
	C189.705383,560.767456 188.208084,559.481018 188.198013,557.680054 
	C188.174377,553.451965 185.814438,552.709106 182.336395,552.979736 
	C180.027206,553.159485 177.730286,552.946472 175.415329,553.637390 
	C172.823257,554.410889 170.390182,554.316101 169.998703,550.608337 
	C169.638275,547.194702 170.800049,545.579651 174.413147,545.967102 
	C176.225204,546.161438 178.077225,545.947754 179.907913,546.011841 
	C182.580292,546.105408 185.145004,545.771179 185.797989,542.689209 
	C186.440445,539.656860 186.451370,536.594421 182.331726,535.152039 
	C180.811462,531.690674 183.649689,531.336365 185.438904,530.979126 
	C191.694168,529.730164 197.789474,527.656250 204.602127,527.109131 
	C205.782181,527.205627 206.574493,527.379333 207.344467,527.623474 
	C209.043381,528.162231 210.272461,527.633972 210.775864,525.937195 
	C211.300140,524.170227 210.408096,523.149597 208.691452,522.787659 
	C206.647858,522.356873 204.598495,521.953369 202.275818,521.268982 
	C200.121704,517.643799 197.695221,515.453369 193.438644,515.354309 
	C189.564270,515.264160 187.465866,516.441345 187.225311,520.737549 
	C184.910675,521.414856 182.905792,522.129211 180.690399,522.037781 
	C177.668106,521.913025 175.910446,520.110291 175.547256,517.575073 
	C175.204514,515.182556 175.900665,512.863586 178.933868,511.806915 
	C183.798248,510.112335 185.665588,505.150238 188.992615,501.375854 
	C195.539520,498.701416 202.089981,496.711853 209.094696,500.588684 
	C209.991287,503.171326 208.900635,504.811676 207.282257,506.685120 
	C205.584061,507.647552 204.965927,508.777802 205.003922,510.653656 
	C204.762207,511.230133 204.503510,511.443024 204.302521,511.701447 
	C203.975449,512.122131 204.062622,512.287415 204.756653,512.078918 
	C209.376541,513.821899 213.751114,513.647949 218.502930,513.410645 
	C225.227661,513.074768 232.259491,512.729004 238.896286,515.108521 
	C240.949615,515.844727 242.838959,516.909973 241.534195,519.397583 
	C240.315231,521.721558 238.329010,521.171997 236.428955,519.951233 
	C232.959290,517.722046 229.335251,516.810059 225.131256,517.880981 
	C219.954437,519.199646 214.665436,516.214661 209.417862,517.920593 
	C208.412674,518.247375 206.723038,517.533142 206.606339,519.186829 
	C206.503372,520.645630 207.959122,520.735229 209.113205,520.992493 
	C213.199554,521.903503 216.555115,522.821167 213.611938,528.522949 
	C212.144150,531.366455 214.293884,534.179443 217.930145,533.925476 
	C223.574356,533.531189 228.042908,538.193726 233.818817,537.250366 
	C235.163208,537.030701 236.258469,538.623413 235.330902,540.124817 
	C233.755814,542.674255 233.021378,546.190247 228.835266,546.030212 
	C224.631516,545.869385 222.319794,549.005005 219.938873,551.727417 
	C218.343567,553.551453 219.027954,555.149658 221.320770,555.864380 
	C223.834702,556.648010 226.360367,557.432861 228.931519,557.978821 
	C230.361771,558.282593 231.401550,558.820129 232.038391,560.129456 
	C233.592865,563.325378 234.704453,563.545532 236.300995,559.954102 
	C237.522873,557.205444 245.500061,555.773865 248.129105,557.408875 
	C249.016205,557.960571 249.612762,558.804749 249.459885,559.877625 
	C249.306519,560.953735 248.319321,560.925293 247.510605,560.963989 
	C246.348801,561.019653 245.181717,560.963440 244.019684,561.016479 
	C242.892288,561.067993 241.500122,560.853271 241.275558,562.455261 
	C241.079132,563.856384 242.048340,564.533325 243.635651,564.947266 
	C244.600876,565.683167 244.760742,566.458801 244.245117,567.662476 
	C241.235382,566.998291 238.868332,567.593140 236.012985,568.583557 
	C230.417603,570.524536 224.422928,571.328613 218.381500,571.020508 
	C216.224854,570.910461 214.028732,570.827148 211.900223,571.107727 
	C209.396744,571.437805 207.187561,573.056763 207.359787,575.557861 
	C207.523163,577.930481 210.182510,577.841431 212.180557,578.005432 
	C216.117706,578.328552 220.204254,577.096130 223.975677,579.024536 
	C224.919281,579.507080 226.564850,579.660889 226.309845,581.172363 
	C226.093048,582.457275 224.660553,582.647400 223.543915,582.894348 
	C221.128311,583.428650 216.668427,582.126404 216.904190,585.158569 
	C217.176178,588.656616 221.611862,586.342896 224.567657,587.001587 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M144.625031,485.750275 
	C150.544220,488.963379 156.412552,486.366608 162.545929,487.000000 
	C162.723465,489.144989 163.816879,490.498383 165.789642,491.732452 
	C166.225494,495.269073 163.944031,495.033844 161.403076,495.000671 
	C160.639069,495.331360 160.219467,495.526825 159.385162,495.484711 
	C157.579346,493.857788 156.023590,492.452209 153.776428,492.196533 
	C152.517700,492.053345 150.811646,491.413757 150.269562,492.864105 
	C149.660309,494.494080 151.847031,494.525208 152.802124,495.724884 
	C152.739120,496.954437 152.130966,497.964050 152.272491,498.854523 
	C153.367722,505.745850 147.481873,506.777435 143.501953,509.206421 
	C140.476288,511.053040 136.681641,509.673767 135.849884,507.131927 
	C135.141937,504.968567 137.040665,501.538879 140.336578,500.370209 
	C142.410507,499.634827 146.318008,500.771332 145.714951,496.937317 
	C145.210114,493.727905 141.742783,494.217712 139.200668,494.003540 
	C136.087448,493.741272 133.076065,492.539459 129.443542,492.999146 
	C127.144348,492.106201 126.058685,490.614319 126.592613,488.602203 
	C127.122459,486.605469 128.995407,485.830994 130.854065,485.660461 
	C135.306732,485.251831 139.776169,484.811859 144.625031,485.750275 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M145.000702,485.666473 
	C140.711685,487.528900 136.254562,486.895050 131.858154,486.996033 
	C127.782257,487.089661 126.445488,488.618835 128.957214,492.650543 
	C129.000397,493.443512 129.000198,493.888214 129.000000,494.666473 
	C123.965912,494.666656 118.931816,494.333313 113.449173,494.000000 
	C113.174438,483.468231 110.748253,482.705109 123.964821,483.045837 
	C130.700851,483.219452 137.504410,482.035248 144.586517,483.938934 
	C145.000473,484.443512 145.000336,484.888214 145.000702,485.666473 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M190.997955,581.610107 
	C190.212280,582.313354 189.424576,582.626770 188.319046,582.970093 
	C181.839081,583.158875 180.347137,584.802246 181.504623,590.802917 
	C181.921829,592.965820 181.764771,594.434753 179.509384,594.959167 
	C177.515747,595.422791 177.220001,597.101868 176.258606,598.767395 
	C174.517349,598.991638 173.019333,599.118835 171.556656,598.946350 
	C168.632126,598.601501 168.130814,597.292114 170.642517,595.079590 
	C171.339676,594.676147 171.695862,594.377441 172.385101,593.949585 
	C175.290405,593.373352 176.436401,592.272766 174.277069,589.632019 
	C172.774170,588.881531 171.456207,588.477295 170.243088,587.237793 
	C166.634232,583.695190 168.795624,581.659180 171.993164,581.342224 
	C175.759048,580.968994 178.915436,579.386108 182.632599,578.045776 
	C186.336823,577.405945 190.372330,575.077026 190.997955,581.610107 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M209.312897,502.349731 
	C206.905807,498.643921 203.485321,499.777435 200.823929,500.224976 
	C197.170059,500.839447 193.566757,500.970947 189.458206,501.000000 
	C187.675079,500.271912 186.271469,499.873779 184.372299,500.000305 
	C183.881104,499.689484 183.689270,499.433685 183.212250,499.115662 
	C182.179642,498.008484 181.931702,496.930084 182.748489,495.365112 
	C184.388824,495.001740 185.777039,494.999847 187.581711,494.997131 
	C193.217087,491.865387 198.993195,493.031891 204.348724,493.714752 
	C209.606705,494.385254 214.930725,495.851105 220.163116,498.526031 
	C221.131439,499.996368 221.186447,501.186798 220.537415,502.820740 
	C216.621719,505.486145 213.113892,504.292328 209.312897,502.349731 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M167.007843,539.869202 
	C167.315002,537.743286 166.082184,535.489807 168.080078,534.459656 
	C169.936432,533.502380 170.907715,535.645386 172.186859,536.529907 
	C174.381500,538.047546 176.828354,538.052124 179.325378,538.011230 
	C181.556076,537.974670 183.929550,538.006531 183.853790,541.131226 
	C183.786652,543.899658 181.626236,543.994385 179.552429,543.995422 
	C176.742371,543.996826 173.931534,543.951538 171.122498,544.004700 
	C168.551682,544.053345 166.974915,543.101196 167.007843,539.869202 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M187.466125,521.000000 
	C186.864304,518.741638 184.320480,515.483154 187.777496,514.552490 
	C191.553314,513.535950 196.304718,512.386963 199.392075,515.687134 
	C200.343140,516.703796 205.326813,516.722351 202.183655,520.746094 
	C197.310745,521.000000 192.621490,521.000000 187.466125,521.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M204.699402,526.721924 
	C204.799530,528.724976 203.537949,528.813538 202.162079,529.059509 
	C196.883682,530.003235 191.613876,531.010986 186.376450,532.157471 
	C184.859726,532.489502 183.050583,532.565857 182.078674,534.639282 
	C181.250000,535.000000 180.500000,535.000000 179.375000,535.000000 
	C178.069504,534.697876 177.086075,534.515869 176.273010,533.931885 
	C175.155502,533.129211 174.235046,532.096680 174.653198,530.652283 
	C175.080002,529.178040 176.394073,528.784790 177.800583,528.917786 
	C182.407852,529.353394 186.822571,528.014221 191.663422,527.253784 
	C196.065704,525.315918 200.232178,525.878296 204.699402,526.721924 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M158.691406,494.781006 
	C159.443832,494.998352 159.888290,494.999146 160.666061,495.000610 
	C161.452225,497.533417 163.523056,497.698578 165.467026,498.077606 
	C167.190552,498.413635 168.005402,499.513824 167.884766,501.243683 
	C167.760071,503.031677 166.626480,503.846130 164.950241,503.947235 
	C163.637711,504.026428 162.304062,504.114319 161.004578,503.971954 
	C158.694992,503.718994 155.730545,505.125641 154.359299,502.190491 
	C153.063431,499.416656 155.672012,497.738770 156.934448,495.350647 
	C157.397079,494.651703 157.857834,494.505737 158.691406,494.781006 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M191.552200,527.000000 
	C189.861023,530.802917 185.681488,528.245361 182.820831,529.908569 
	C182.200943,530.268921 181.185883,529.873291 180.390076,530.023315 
	C178.741730,530.334106 176.254639,528.939514 175.622330,531.181030 
	C175.089157,533.071045 178.400345,532.545288 178.932434,534.655762 
	C175.903534,535.965454 170.294510,532.739868 170.236115,529.796631 
	C170.177933,526.864380 172.648834,526.574402 174.463684,527.099121 
	C180.076584,528.721985 185.566483,526.743958 191.552200,527.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M160.999542,556.664673 
	C158.511475,557.334229 156.187927,556.458313 153.402679,556.009949 
	C153.000412,555.333008 152.983856,554.667908 153.002884,554.003906 
	C153.081848,551.248901 152.338745,548.088501 156.823334,547.983337 
	C161.611374,547.871033 160.967072,551.179626 161.000351,554.574402 
	C161.000305,555.443909 161.000000,555.887817 160.999542,556.664673 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M192.917969,566.927246 
	C193.228333,567.573853 193.510345,567.903015 193.465042,568.178223 
	C192.602448,573.418884 188.535568,570.293884 185.966507,570.894348 
	C183.544785,571.460449 182.989288,569.622070 182.982437,567.509094 
	C182.974594,565.091736 183.665817,563.319580 186.400864,564.380005 
	C188.494400,565.191650 191.225021,564.190491 192.917969,566.927246 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M158.987305,494.619690 
	C158.555344,494.998627 158.111298,494.999695 157.334229,495.001587 
	C155.880112,495.240570 154.759018,495.478668 153.318954,495.858398 
	C151.862747,495.884125 150.668640,495.945221 149.604034,495.603210 
	C148.508743,495.251373 147.544220,494.415863 147.424774,493.156494 
	C147.288330,491.717529 148.520447,491.261932 149.547684,490.765747 
	C152.489197,489.344971 157.035690,491.035065 158.987305,494.619690 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M177.546646,559.609985 
	C178.653061,555.503479 181.616272,557.067993 183.940994,557.429565 
	C185.162003,557.619507 185.049744,559.256348 184.840622,560.409424 
	C184.623581,561.606140 183.677994,562.059998 182.659500,561.864746 
	C180.914032,561.529968 179.110748,561.217468 177.546646,559.609985 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M174.990311,570.241699 
	C172.911804,571.118225 171.069733,571.504517 170.481506,569.021301 
	C170.222351,567.927246 171.290100,567.248535 172.312637,567.177979 
	C174.226959,567.045898 176.059265,567.200012 174.990311,570.241699 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M170.243286,496.706024 
	C170.420975,495.440887 171.043243,494.995483 171.954193,495.335999 
	C172.241882,495.443573 172.579468,496.194427 172.463226,496.467194 
	C172.027863,497.488708 171.321915,498.049316 170.243286,496.706024 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M173.561768,690.127625 
	C176.141357,691.495544 176.371887,692.753601 173.493851,692.906433 
	C170.276520,693.077209 166.984634,693.397095 162.836777,692.344604 
	C166.595261,690.207275 170.186096,691.388062 173.561768,690.127625 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M640.930847,162.647476 
	C639.897705,162.719406 638.807068,162.275452 637.690491,162.196335 
	C635.998474,162.076447 634.938599,163.234314 634.365906,164.634003 
	C633.651611,166.379913 634.784363,167.395065 636.225403,168.073547 
	C637.987976,168.903412 639.782227,169.665726 641.781372,170.728622 
	C641.432495,172.096878 640.580322,173.023804 639.322876,172.902481 
	C634.800415,172.466125 632.424561,173.982773 632.851990,178.960251 
	C633.029602,181.028885 631.063843,180.847458 629.577271,181.012589 
	C625.898010,181.421265 623.566406,178.645767 620.232422,177.286713 
	C622.256531,174.818588 624.153809,172.786926 627.560364,172.978638 
	C631.429077,173.196350 632.686279,171.391113 631.414307,167.718704 
	C630.410095,164.819595 631.168762,162.523697 633.971924,161.038574 
	C636.604309,159.643982 639.005920,159.458038 640.930847,162.647476 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M114.090149,547.369385 
	C117.258240,547.879578 120.616997,545.334717 123.922852,547.641846 
	C120.746895,547.175232 117.370087,551.025574 114.090149,547.369385 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M159.853729,525.215759 
	C159.931717,527.203979 159.309113,528.176636 157.548981,527.634949 
	C156.273285,527.242371 155.116425,526.595520 155.102493,524.972778 
	C155.091614,523.706421 155.573257,522.631714 156.820969,522.321228 
	C158.836227,521.819763 159.179703,523.547302 159.853729,525.215759 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M305.273102,245.266586 
	C306.825653,243.244461 309.104980,244.290665 311.585632,244.002350 
	C310.556488,247.491928 307.873260,245.760742 305.273102,245.266586 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M640.694214,603.744141 
	C641.829773,607.340454 637.327454,606.730103 637.044312,609.635254 
	C636.216980,611.405945 634.460388,611.475769 633.127441,612.773438 
	C627.061951,616.912292 621.002014,615.668518 614.582092,613.929504 
	C610.725769,611.238220 610.725769,611.238220 604.330811,612.065186 
	C605.954529,612.596313 606.747437,613.184937 607.421753,614.358337 
	C607.272095,617.874390 605.487122,619.019104 602.119446,618.342407 
	C601.594360,618.097412 601.399170,617.981323 600.953186,617.651123 
	C600.151306,616.854126 599.833862,616.090271 598.986938,615.453247 
	C598.183533,615.248230 597.670837,615.394348 596.892517,615.737427 
	C595.621399,616.400024 593.962158,616.143738 594.095215,618.336548 
	C594.161926,619.388184 594.130127,620.097656 594.001831,621.180176 
	C593.018066,624.147583 594.080200,626.435364 595.148743,628.665039 
	C596.600830,631.695129 597.458374,634.853027 597.420105,638.203979 
	C597.401306,639.848511 597.314880,641.844116 595.372437,642.300903 
	C593.205017,642.810669 592.710144,640.722961 592.108154,639.263916 
	C590.087036,634.365540 589.179443,629.131165 587.692932,624.069519 
	C587.414429,623.121216 587.358337,622.100342 586.150269,621.225342 
	C585.670471,620.806152 585.493469,620.625366 585.084290,620.152588 
	C582.769348,616.110962 579.051453,618.868530 576.271362,617.721191 
	C573.469910,616.565063 574.268555,612.109192 570.201355,611.930481 
	C566.319885,611.759949 562.571350,610.223389 558.557129,611.739929 
	C556.020630,612.698242 554.211548,614.054443 553.367065,616.594788 
	C550.659912,624.738525 552.502747,634.242615 562.738892,637.713257 
	C563.324585,637.886780 563.553101,637.976135 564.104248,638.242920 
	C565.915344,640.073975 568.187683,640.492981 569.850708,641.847534 
	C571.053528,642.827271 572.041504,644.119690 571.188232,645.720398 
	C570.479431,647.050110 569.028503,647.092834 567.656311,646.924011 
	C566.658752,646.801331 565.766418,646.317871 564.814636,646.049683 
	C563.305542,645.624512 561.552612,644.116638 560.258545,645.626953 
	C558.902344,647.209961 558.220459,649.274048 558.893433,651.632141 
	C560.172424,656.114075 563.799133,659.249939 568.506775,659.540955 
	C570.160522,659.643127 571.821228,659.578491 573.841431,659.555298 
	C577.129883,659.887085 579.901917,658.937134 583.046387,658.473511 
	C583.827881,658.251526 584.440796,658.242493 584.311279,657.204956 
	C584.170593,656.716187 584.112610,656.514282 583.996582,655.990479 
	C583.905151,655.215027 583.929260,654.763977 584.100647,653.973877 
	C584.326416,653.427307 584.449463,653.221863 584.855469,652.791626 
	C587.451172,651.800110 589.253052,653.609680 591.730469,653.884460 
	C593.022461,654.234863 593.936523,654.616516 595.323730,654.555542 
	C597.015320,654.610229 598.332764,654.625305 599.735291,655.715576 
	C600.018799,656.181274 599.994446,656.374268 600.072998,656.505615 
	C604.053040,663.157471 603.857727,663.562866 595.938232,665.000671 
	C595.614014,665.059570 595.103088,664.915222 594.971375,665.088074 
	C591.993591,668.998230 588.881409,672.826233 586.219727,676.944458 
	C585.429565,678.167053 587.403076,678.955566 588.441711,679.758667 
	C590.482117,681.336304 593.218933,680.476562 596.004700,681.954773 
	C590.170471,684.866150 584.510010,684.499390 579.167358,683.658569 
	C574.007629,682.846558 568.926331,683.268677 563.817688,683.024536 
	C560.826111,682.881470 559.083374,685.075317 556.815491,687.049622 
	C556.135071,687.548950 555.736206,687.759827 554.944519,687.895508 
	C553.911682,687.846497 553.298096,687.586487 552.518982,686.930786 
	C551.866333,686.233582 551.575867,685.654968 550.814697,685.082397 
	C550.065430,684.537598 549.570618,684.166504 548.813721,683.620239 
	C547.935303,682.857300 547.323120,682.266663 546.354736,681.579346 
	C538.112671,675.029602 530.656128,668.075928 522.586792,661.314575 
	C522.115601,660.991455 521.938354,660.848022 521.523438,660.462402 
	C520.508118,659.160828 519.816162,658.022644 518.362915,657.146606 
	C517.928467,656.809998 517.762817,656.664795 517.371887,656.278259 
	C516.400269,654.961731 515.672974,653.868591 514.200928,653.026978 
	C513.763672,652.674377 513.597046,652.521545 513.207031,652.111328 
	C509.467682,646.517639 505.202545,641.818176 500.380676,637.148987 
	C499.939667,636.778931 499.776215,636.615967 499.406921,636.175293 
	C497.534637,632.393311 494.802917,629.681824 492.151489,626.494263 
	C491.774933,626.079773 491.631134,625.908386 491.286682,625.469482 
	C490.572144,624.203552 489.837799,623.396484 488.711243,622.479797 
	C488.353241,622.040771 488.225555,621.851868 487.941956,621.357788 
	C486.335510,617.361938 483.971405,614.342102 480.738983,611.520630 
	C480.369354,611.056824 480.240448,610.855530 479.963196,610.326538 
	C478.223999,604.937622 473.928192,601.631470 471.482025,597.098877 
	C465.952972,586.853882 459.611023,577.054382 454.695343,566.469971 
	C453.924896,564.811096 453.093414,563.170227 451.598785,561.531738 
	C450.528320,559.816528 450.132141,558.224060 449.674133,556.300171 
	C449.574341,555.074646 449.207397,554.309387 448.515198,553.332947 
	C448.078766,552.484253 447.901520,551.877441 447.667145,550.964478 
	C447.538177,549.814575 447.082184,549.142883 446.465088,548.204895 
	C446.044098,547.366760 445.872498,546.765930 445.644775,545.854248 
	C445.503662,544.669312 444.952606,543.993103 444.504333,542.917419 
	C444.347748,541.895752 444.545776,541.280518 445.344299,540.605469 
	C446.830566,540.058594 447.533966,538.933777 448.668427,538.472046 
	C447.962311,538.619873 447.417145,539.108521 446.415985,539.393799 
	C445.223938,538.997986 444.236420,538.862610 443.378113,537.803711 
	C442.231781,535.943481 441.903870,534.056946 440.502289,532.327759 
	C439.927368,531.198669 439.781097,530.297119 439.542236,529.075989 
	C439.381439,527.841980 438.960510,527.084839 438.199158,526.113037 
	C436.993073,524.200684 436.680634,522.381104 436.539825,520.175293 
	C436.495117,518.786011 436.578094,517.706482 435.668823,516.541260 
	C435.357086,515.874023 435.236847,515.472473 435.113129,514.752014 
	C434.982697,513.843933 434.913086,513.238892 434.544800,512.390625 
	C434.295868,511.714996 434.200867,511.314789 434.089966,510.603210 
	C433.956238,509.693512 433.864655,509.088867 433.491852,508.231018 
	C433.255432,507.527130 433.181976,507.108185 433.135681,506.360657 
	C433.324432,504.919189 432.937775,503.953857 432.198853,502.738800 
	C431.599670,501.126465 431.580017,499.760986 431.517700,498.069946 
	C431.597168,496.646240 431.445831,495.614319 430.574280,494.450745 
	C430.165985,493.579803 430.048309,492.957153 429.983276,492.007507 
	C430.031830,490.632080 429.706360,489.665161 429.115295,488.418213 
	C428.695282,486.915405 428.745331,485.747681 429.353210,484.277832 
	C429.703491,483.786621 429.876465,483.616699 430.361938,483.266998 
	C434.011261,482.025726 437.656036,482.980469 440.943573,481.425079 
	C443.239288,480.338898 444.219666,479.146301 442.029327,477.043152 
	C441.550323,476.583191 440.786041,476.062042 440.803711,475.517914 
	C441.059143,467.642395 435.801910,468.633240 430.768555,469.155609 
	C430.126801,469.222168 429.499695,468.808197 428.668396,468.189453 
	C427.256958,465.832001 426.872894,463.584320 427.182190,460.864014 
	C429.694397,457.746979 432.052094,459.033295 434.113251,460.835388 
	C436.794403,463.179626 438.787964,462.552917 440.889343,459.655518 
	C445.115631,458.343964 449.424744,457.936523 451.287354,453.301239 
	C451.647583,452.404724 452.854370,452.132843 454.285034,452.298462 
	C456.286255,453.228333 456.711792,454.979980 457.795837,456.666443 
	C458.723572,458.928833 460.735535,458.895691 462.570587,459.785461 
	C463.756500,460.341156 464.550079,460.864838 465.424622,461.868835 
	C466.129211,463.252869 466.052582,464.419861 466.169006,465.563416 
	C466.663635,470.421539 468.706024,472.585846 473.541718,473.297089 
	C476.321289,473.705963 479.137268,473.380341 482.304932,473.571594 
	C483.183960,473.596283 483.688049,473.633087 484.571136,473.760132 
	C485.195129,473.915894 485.438660,474.002014 486.019867,474.278564 
	C488.753601,477.153076 489.898346,480.580200 491.747375,483.591461 
	C492.740326,485.208557 492.676697,486.984161 491.722382,489.043488 
	C491.386292,491.588959 491.500275,493.680450 492.399780,495.757965 
	C493.794006,498.978088 493.841095,502.246704 492.079285,505.367889 
	C490.807617,507.620636 491.435547,509.129211 493.654724,510.182739 
	C500.588593,513.474548 500.538788,520.732849 502.655975,526.620728 
	C503.617737,529.295349 500.277985,530.936340 496.614197,529.926880 
	C494.289276,529.286316 492.629089,526.953979 489.524750,527.274536 
	C493.035614,530.817749 497.271637,532.109375 501.570099,533.224609 
	C504.316162,533.937134 506.860657,534.961975 506.765900,538.287659 
	C506.665497,541.812378 503.717651,542.261902 501.060883,542.674988 
	C498.162445,543.125793 495.197083,544.292725 492.108459,542.099304 
	C488.330811,535.112061 482.111206,534.285339 475.596191,534.851746 
	C471.107880,535.241943 466.643005,535.562622 462.138245,535.510620 
	C472.142731,535.607849 482.570435,533.531860 491.361084,541.396851 
	C495.298279,545.593933 500.845978,544.801208 505.258240,546.977966 
	C508.412018,548.533875 510.980194,549.916016 508.949829,554.069763 
	C511.426605,554.913574 513.943481,554.184753 516.680176,554.832703 
	C517.987671,555.271973 518.749329,555.859314 519.381348,557.090454 
	C519.585510,558.310059 519.137817,558.935364 518.656433,559.641541 
	C517.823730,560.863220 514.756409,560.612183 515.889221,562.847839 
	C516.750244,564.547119 519.015930,564.340393 520.863220,564.114136 
	C521.354187,564.054077 521.847839,564.022278 522.695679,564.037354 
	C524.765442,564.343140 526.104492,565.455566 527.938599,566.356079 
	C529.587219,567.993591 530.936890,569.418701 531.770630,571.627808 
	C530.471863,577.186157 530.633362,577.162354 536.166992,576.608582 
	C539.587830,576.266357 541.775757,573.143494 545.581543,573.228210 
	C546.649902,573.437378 547.283936,573.770874 548.139038,574.436157 
	C552.542114,579.505188 556.665283,584.354736 563.776978,585.558716 
	C568.382202,585.158569 571.752258,587.488464 575.609009,589.343262 
	C576.415344,589.766296 576.913025,590.095520 577.782227,590.227417 
	C578.252502,590.218445 578.442200,590.208618 578.921021,590.208984 
	C579.401855,590.256653 579.593506,590.294006 580.060181,590.439758 
	C580.679749,590.744812 581.006531,590.967712 581.543457,591.409180 
	C582.351746,592.142578 582.868530,592.746155 583.664856,593.490112 
	C584.461853,594.280518 585.165649,594.606689 586.291565,594.765991 
	C587.448486,595.045105 588.216675,595.432434 589.216431,596.080322 
	C590.539368,597.375366 591.659912,597.225098 593.131714,596.315063 
	C594.140686,596.032654 594.827698,596.055176 595.834900,596.383972 
	C598.897034,598.987183 602.756165,599.969727 604.756958,603.502136 
	C605.584045,604.962402 607.317200,604.435608 608.682922,603.447876 
	C611.857666,601.151794 614.971130,602.266968 617.057739,604.728577 
	C619.431396,607.528748 622.355713,608.411865 625.929199,609.061768 
	C629.204102,607.603271 630.804382,604.771729 633.295288,602.337036 
	C636.092834,601.505371 638.349854,601.937195 640.694214,603.744141 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M434.089966,505.667816 
	C434.221466,506.369202 434.310944,506.733612 434.444153,507.376038 
	C434.482391,508.278107 434.860413,508.798584 434.979645,509.697388 
	C435.117828,510.365265 435.234436,510.730469 435.411194,511.376953 
	C435.480621,512.286743 435.864136,512.803894 436.030884,513.700439 
	C436.179382,514.364380 436.286041,514.725952 436.459167,515.362244 
	C436.593781,516.706970 437.248138,517.574707 437.492126,518.878174 
	C438.038177,521.114624 438.629181,523.041260 439.307495,525.330688 
	C439.584442,526.346313 439.919189,526.950623 440.077728,527.964722 
	C440.449982,529.213196 440.784760,530.123169 441.213806,531.386719 
	C442.055298,533.329956 442.718719,534.951111 443.213562,536.981689 
	C443.677460,538.201904 444.081177,539.098572 444.795837,540.202393 
	C445.746735,539.590576 446.125275,539.702209 445.683197,540.714661 
	C445.150940,541.200684 445.181976,541.701843 445.286804,542.456604 
	C445.453705,543.489014 446.059357,544.026917 446.352539,545.010437 
	C446.605774,545.917969 446.816956,546.546448 447.114868,547.495605 
	C447.532654,548.416870 447.931610,548.981812 448.237427,549.971558 
	C448.546204,550.928589 448.776215,551.551086 449.104736,552.491638 
	C449.528687,553.412903 449.929718,553.976257 450.244080,554.962158 
	C450.979767,556.806396 451.632538,558.317383 452.269104,560.181030 
	C460.370544,577.579285 469.338593,594.135254 480.665802,609.744995 
	C480.900024,610.022400 481.152618,610.332825 481.147003,610.532837 
	C483.244995,614.222839 486.359100,616.961853 488.604492,620.769226 
	C488.809296,621.039734 489.130646,621.298767 489.141388,621.504883 
	C489.663025,622.884827 490.724579,623.609375 491.671875,624.826782 
	C491.853912,625.077881 491.933716,625.053467 491.928467,625.094849 
	C494.278046,628.746582 497.218658,631.919495 499.952576,635.622681 
	C500.260590,635.768555 500.044525,635.954163 500.140320,635.848755 
	C504.255951,641.361328 509.294647,646.111694 513.929077,651.628174 
	C514.235107,651.789551 514.041260,651.958679 514.125610,651.861572 
	C515.015625,653.324158 516.408386,654.348633 517.916992,655.678223 
	C518.196960,655.833679 518.045166,655.954590 518.105713,655.878784 
	C519.054199,657.296021 520.393860,658.366577 521.926270,659.670776 
	C522.209106,659.820007 522.048218,659.954712 522.113586,659.872681 
	C529.632935,667.528015 537.791992,674.468994 546.521606,681.274658 
	C547.421753,681.754822 548.021606,682.072876 548.832520,682.696533 
	C549.568481,683.191528 550.029297,683.488892 550.671387,683.994019 
	C551.554626,684.522461 552.058777,685.098083 552.865112,685.745605 
	C553.550598,686.212402 554.004517,686.516296 554.669983,686.993958 
	C555.542969,687.542725 556.067200,688.089966 556.870728,688.739380 
	C557.097778,688.907104 557.486206,689.040405 557.665283,689.140869 
	C558.550110,689.542236 559.067566,690.100586 559.899536,690.702698 
	C560.584473,691.163269 561.023743,691.486145 561.734009,691.764587 
	C562.688171,691.486084 563.295105,691.708313 564.152649,692.217896 
	C564.916077,692.797241 565.338745,693.281006 566.162231,693.761353 
	C566.616272,694.016785 566.794739,694.123962 567.232178,694.403381 
	C567.996277,694.917847 568.435547,695.362183 569.325562,695.656372 
	C570.408936,696.026917 571.140625,696.413940 572.066406,697.085266 
	C572.928650,697.784180 573.410645,698.466003 574.476318,698.894409 
	C575.530151,699.370300 576.271606,699.775024 577.256348,700.411499 
	C578.929626,702.022644 580.629272,702.945557 582.851135,703.589966 
	C583.606750,703.748535 584.026978,703.882385 584.736877,704.190674 
	C585.809753,704.741455 586.417358,705.435547 587.629028,705.760254 
	C588.382141,706.070312 588.778564,706.319946 589.377686,706.895386 
	C589.948730,707.854492 590.063538,708.571472 589.915283,709.680054 
	C588.218994,712.809021 586.013000,711.592957 584.213440,710.585754 
	C573.993896,704.865784 562.824280,701.147705 553.090393,694.193787 
	C547.687256,690.333679 541.640198,687.003235 536.170410,683.075623 
	C526.057678,675.814209 516.729431,667.565552 507.907837,658.758545 
	C502.775269,653.634399 498.225189,647.791748 491.132263,645.030396 
	C490.051086,644.609436 489.429657,643.499268 488.723877,642.561218 
	C484.485352,636.927979 481.006805,630.661682 475.014221,626.029968 
	C474.654907,625.586975 474.529236,625.401306 474.260376,624.923462 
	C473.797516,623.957764 473.737823,623.106445 472.584961,622.765015 
	C471.463165,622.599915 470.803680,622.189209 470.158661,621.240784 
	C469.665100,620.261963 469.459442,619.476501 468.731842,618.627930 
	C468.439392,618.221924 468.327759,618.055420 468.060608,617.631714 
	C467.621521,616.846863 467.338867,616.318604 466.750000,615.626831 
	C466.445312,615.220642 466.329712,615.053040 466.054443,614.625122 
	C465.610291,613.825989 465.307983,613.298706 464.712433,612.595398 
	C464.402161,612.185059 464.284241,612.015991 464.004272,611.584412 
	C463.570679,610.768860 463.223755,610.266296 462.633606,609.557312 
	C461.971191,608.646484 461.623169,607.908997 461.129150,606.910583 
	C460.687988,605.910889 460.279572,605.248047 459.466736,604.498779 
	C458.709015,603.652161 458.317413,602.931152 457.876465,601.883789 
	C457.574249,601.016968 457.381256,600.440002 456.786926,599.722717 
	C456.487427,599.307434 456.372192,599.137390 456.094849,598.705444 
	C455.563019,597.691589 455.038300,597.057800 454.217590,596.236694 
	C453.422668,595.267456 453.055908,594.439270 452.705994,593.217896 
	C450.361176,586.100708 446.321899,580.176514 443.461823,573.690613 
	C441.869659,570.079956 440.149078,566.519287 438.881744,562.414124 
	C438.786987,561.186523 438.674683,560.320740 437.767090,559.385498 
	C432.006500,547.520386 427.368134,535.620911 425.359863,522.480347 
	C425.741455,520.498535 425.007965,519.242798 423.860535,517.711670 
	C420.928314,508.474152 420.403625,499.166962 418.509705,490.122009 
	C417.379547,484.724487 417.959503,479.215057 416.916351,473.411407 
	C416.279114,470.709320 416.326813,468.402435 416.655396,465.674347 
	C418.114197,461.919769 417.266144,458.372772 417.739319,454.581268 
	C418.083099,452.628967 420.128143,451.301086 418.360779,449.124084 
	C417.941956,448.168976 417.804840,447.487671 417.718323,446.433411 
	C417.716797,445.597198 417.762360,445.121613 417.904541,444.296387 
	C418.493073,442.602936 418.643372,441.203278 418.642395,439.420258 
	C418.699463,438.588013 418.773468,438.117981 418.970642,437.308838 
	C420.773438,433.596832 420.212982,429.576355 422.312683,425.969757 
	C425.287445,423.456757 426.241760,420.671814 425.036072,416.885498 
	C424.755219,414.445282 425.394897,412.600861 426.821167,410.641846 
	C429.635345,408.884521 431.312805,406.819550 431.674438,403.759705 
	C432.027100,400.775848 433.643463,400.193817 436.435425,401.894531 
	C438.250977,404.046173 438.170349,405.973328 436.988678,408.069244 
	C435.693451,410.366699 434.888947,412.870911 434.485718,415.869019 
	C434.595428,417.532410 434.394379,418.782227 433.940155,420.373291 
	C432.394989,423.041016 431.548248,425.535278 431.427582,428.629333 
	C431.435455,429.742615 431.340729,430.477753 431.149658,431.577698 
	C430.694550,433.126282 430.351593,434.315155 430.453552,435.960938 
	C430.509430,437.635742 430.455017,438.924835 430.192749,440.596436 
	C429.544250,442.491150 429.367645,444.053223 429.490356,446.062317 
	C429.769867,448.416504 429.672729,450.366241 429.604187,452.743195 
	C429.684265,455.204834 429.857178,457.253204 428.826447,459.594849 
	C427.851624,462.420258 428.654480,464.838104 428.857056,467.629822 
	C429.947479,468.965851 430.279144,470.127350 430.199585,471.784546 
	C430.088928,473.070312 429.886993,473.990356 430.114685,475.270081 
	C430.382477,477.005096 430.431213,478.407959 430.450684,480.164185 
	C430.409882,481.455048 430.417664,482.397980 430.072266,483.655640 
	C430.176178,485.279297 430.353912,486.558777 430.551971,488.224915 
	C430.483612,489.259064 430.876862,489.792053 431.038940,490.696899 
	C431.210480,491.657654 431.337952,492.311920 431.510345,493.296539 
	C431.700745,494.658600 432.068542,495.646729 432.204437,496.999512 
	C432.565948,498.857147 432.879303,500.405090 433.256165,502.343933 
	C433.682068,503.566589 434.038086,504.400146 434.089966,505.667816 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M640.283752,428.906311 
	C638.597778,428.976227 637.223755,428.985107 635.437561,429.001831 
	C634.802795,429.242737 634.563782,429.457581 634.140442,429.827148 
	C632.833130,433.821991 629.512878,435.511688 626.441528,437.174103 
	C616.829834,442.376709 610.608643,451.048553 603.726868,459.020752 
	C601.813965,461.236694 601.277039,465.853882 602.678467,466.818298 
	C604.771423,468.258514 608.052246,471.889984 611.575134,467.765808 
	C613.024292,466.069305 616.249573,467.432770 616.170776,468.561218 
	C615.875610,472.790131 619.423645,472.896393 621.500916,473.845825 
	C629.304993,477.412689 636.508484,482.020233 643.625122,486.660248 
	C647.386780,489.112854 650.467896,492.836273 653.816223,496.007111 
	C658.477051,500.420868 661.311279,506.102692 665.539795,510.734863 
	C666.387390,511.663361 666.742126,512.551208 667.034607,513.714111 
	C668.007080,517.581299 668.944824,521.685791 671.838135,524.495544 
	C674.623779,527.200745 671.546082,533.291504 677.544434,533.871094 
	C677.634583,533.879822 677.836609,534.878540 677.653137,535.251282 
	C674.867554,540.912842 678.295349,546.609863 677.603760,552.095093 
	C676.722656,559.083862 679.722229,567.146240 672.613770,572.826233 
	C670.024963,574.894775 670.334961,578.764282 668.181091,581.546082 
	C661.676453,589.946838 652.897827,594.321472 643.190613,597.460449 
	C642.570557,597.660889 642.024902,598.091431 641.210388,598.684265 
	C638.959229,600.971069 636.208618,600.496765 633.344177,601.168701 
	C631.760010,601.482666 630.593811,601.610962 628.962891,601.512939 
	C618.243774,601.633606 607.819824,603.104309 597.953491,597.720093 
	C600.814575,594.182068 604.740662,595.294922 608.080750,595.785095 
	C619.436096,597.451416 630.515259,594.602966 641.733093,594.285400 
	C643.977844,594.221802 646.103027,592.841125 648.383057,591.326172 
	C649.094055,590.735413 649.523315,590.447815 650.321045,590.067688 
	C651.494263,589.595581 652.440125,589.487000 653.327759,588.521118 
	C654.089966,587.857117 654.652771,587.486877 655.542480,586.990601 
	C660.448608,585.044495 662.825012,580.946045 665.843018,577.041077 
	C666.325562,576.108887 666.693542,575.512512 667.216553,574.594482 
	C669.389038,571.755066 670.197632,568.738342 671.041504,565.321472 
	C673.493347,555.588257 672.531982,546.200378 671.208374,536.438232 
	C670.616089,534.620544 670.092834,533.195007 669.245178,531.500671 
	C667.567200,525.050842 664.114990,519.803528 661.316284,513.876099 
	C657.881531,507.137115 653.720825,501.387329 648.022827,496.382111 
	C647.433899,495.844208 647.112061,495.523712 646.560364,494.956512 
	C645.700562,494.079010 645.114075,493.405365 644.198364,492.584290 
	C638.891602,488.201447 633.664368,484.299744 627.361084,481.470093 
	C626.828308,481.366913 626.616394,481.326324 626.096375,481.201355 
	C625.588989,481.050720 625.389709,480.984436 624.898560,480.810974 
	C624.414307,480.632141 624.221924,480.560547 623.743408,480.382385 
	C623.268677,480.202301 623.080078,480.128754 622.614868,479.932556 
	C621.799622,479.537170 621.312134,479.188293 620.617920,478.620728 
	C619.826843,477.885437 619.225891,477.398621 618.131470,477.140564 
	C617.633484,476.976898 617.433533,476.903656 616.936401,476.692200 
	C616.446960,476.440125 616.254822,476.326294 615.791138,476.010132 
	C612.051819,474.196777 608.421448,473.107910 604.709534,472.196167 
	C601.521484,471.413055 599.228027,472.182404 597.625061,475.129547 
	C593.981384,481.828918 589.556763,488.076294 586.232361,494.968109 
	C583.825684,499.957245 584.116943,500.614136 589.819946,501.617493 
	C595.538086,502.623505 601.296509,503.403992 606.979675,504.602356 
	C609.898804,505.217896 612.834229,505.851990 615.535278,508.012207 
	C616.408264,509.342590 617.250244,510.303894 617.182922,511.954895 
	C611.761719,512.915466 606.503967,515.102234 601.714111,510.131714 
	C598.864075,507.174164 594.283020,507.590485 590.367554,507.014282 
	C587.592773,506.605896 584.768860,506.956055 581.940369,506.491364 
	C578.659485,505.952423 577.378723,508.232208 577.730469,511.119751 
	C578.055847,513.790405 578.044006,516.014832 575.465210,517.637939 
	C573.782715,518.696960 574.884888,519.832642 575.641846,520.976562 
	C578.389832,525.129517 577.875488,526.581665 573.168335,526.897949 
	C569.694824,527.131348 569.778931,528.014893 570.058838,531.457092 
	C570.775940,540.277710 577.025208,542.455322 583.509521,545.161560 
	C595.177185,550.030945 607.060181,553.344666 619.764038,550.508423 
	C631.675598,547.849182 634.971069,540.468933 630.383728,530.834229 
	C628.379395,526.624390 626.263428,522.591248 621.951721,520.020996 
	C619.468384,518.540588 616.961914,516.522949 617.418213,512.608826 
	C618.234619,511.223206 619.260193,511.459167 620.611572,511.545959 
	C621.746033,511.587341 622.483887,511.732147 623.562256,512.091370 
	C624.368225,512.463989 624.779846,512.756531 625.409546,513.383179 
	C625.977051,514.062256 626.249023,514.487610 626.703125,515.245605 
	C632.453918,523.647217 638.237366,531.641174 637.361816,542.630615 
	C637.280640,543.551025 637.161804,544.063049 636.817322,544.919678 
	C629.333130,555.192139 615.256104,559.434143 602.853577,556.746643 
	C590.079895,553.978699 577.980225,550.002686 567.201904,542.499268 
	C564.486816,540.609131 564.057922,542.819336 563.589600,544.489502 
	C562.640442,547.874451 561.783081,551.242249 558.076233,553.248657 
	C555.342163,552.576111 556.155090,550.824585 556.557983,549.311462 
	C556.925903,547.929504 557.977905,546.631653 556.602722,544.868286 
	C556.398132,540.022156 558.913025,536.374817 560.714844,532.533752 
	C563.314758,526.991394 566.216492,521.591797 568.174927,515.368408 
	C573.843384,505.573944 577.384644,495.162292 582.751038,485.235901 
	C584.623962,481.828308 586.224121,478.686218 587.714600,475.096191 
	C587.929443,474.544861 588.032227,474.331116 588.326050,473.817841 
	C591.990906,467.778015 596.418701,462.696320 600.124146,456.679932 
	C607.061401,447.447754 614.665466,439.187256 623.543152,432.511353 
	C632.129944,426.054199 641.444641,420.388733 652.509033,417.645508 
	C655.103455,417.181824 657.174438,415.857147 659.888672,416.675293 
	C661.074829,421.023621 656.953552,419.991730 655.043579,421.785645 
	C653.428040,422.749359 652.011780,423.153168 650.190308,423.415894 
	C647.728149,423.359497 646.222229,424.860199 644.151733,425.734436 
	C642.658203,426.741974 641.904602,428.108612 640.283752,428.906311 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M474.973145,625.160339 
	C482.314575,628.820068 485.391602,636.124146 490.068756,642.038330 
	C491.197968,643.466125 491.984192,645.014648 494.405609,644.503601 
	C497.039429,643.947693 497.104218,646.991577 498.591675,648.157104 
	C509.165283,656.442383 517.715454,666.908569 528.290710,675.310974 
	C533.819946,679.704224 539.491455,684.681091 545.303223,687.884644 
	C553.056091,692.158142 559.697083,698.288086 568.330017,701.433472 
	C572.735840,703.038696 577.501526,705.153564 581.789185,707.724609 
	C583.997131,709.048706 586.113464,711.709595 589.602905,709.681396 
	C590.240540,708.904114 590.736572,708.590271 591.609253,708.320068 
	C593.714478,708.277100 595.220642,709.069336 596.873901,710.297852 
	C597.562439,711.260254 598.008423,712.002441 599.019104,712.627930 
	C599.710144,712.767944 600.089722,712.821655 601.035889,713.027222 
	C602.022522,713.635925 602.360535,714.166992 603.162720,714.541382 
	C608.366272,716.379028 613.259033,718.234436 618.503906,720.225708 
	C626.480225,723.188171 634.401978,724.906982 642.674927,726.744995 
	C646.844360,727.358398 650.653442,727.886536 654.935181,728.408997 
	C656.623718,727.901550 657.847778,727.889648 659.324707,728.539307 
	C663.020081,728.920898 666.470398,729.105103 670.371704,729.358765 
	C672.546875,733.209167 669.572998,733.057556 667.407288,733.376038 
	C663.994873,733.877869 660.421265,732.697998 656.732910,734.545532 
	C654.753906,735.460449 653.078796,735.423035 651.003052,735.127808 
	C648.448608,733.777649 646.229736,732.818665 643.323853,733.972900 
	C641.786072,734.318604 640.593994,734.343689 639.061768,734.067993 
	C637.708252,733.638245 636.769653,732.988586 635.279053,733.202515 
	C632.344543,733.573975 629.843872,733.088013 627.056885,732.199707 
	C625.737427,731.653320 624.807190,731.014648 623.301147,731.079468 
	C608.395325,728.597412 594.799377,723.178101 581.487244,717.072449 
	C559.718811,707.088379 539.972107,693.850464 521.895386,678.233887 
	C505.895874,664.411926 490.829346,649.601013 477.914764,632.759583 
	C476.251862,630.591064 473.759644,628.704773 474.973145,625.160339 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M694.780945,413.258301 
	C695.746338,413.003326 696.497559,413.001740 697.624390,413.000061 
	C697.934204,416.656738 699.106689,419.715332 701.758423,422.385834 
	C704.120911,424.764984 705.231384,427.738342 701.361389,429.705811 
	C697.839844,431.496063 700.450684,434.075073 699.999207,436.613556 
	C698.052551,439.096466 695.451050,438.541718 693.034668,438.695465 
	C689.280884,438.934387 685.439331,438.179443 681.400696,439.853455 
	C679.879211,439.761169 678.758423,439.522369 677.318848,439.141785 
	C675.971252,438.028015 675.694031,436.751740 675.480225,435.420776 
	C674.744263,430.838409 672.060913,428.057220 667.580261,426.930420 
	C665.807983,426.484680 664.038940,425.980011 662.736450,424.031464 
	C661.898865,421.736755 662.836914,419.586945 661.864014,417.290253 
	C661.745972,416.258057 661.818420,415.576141 662.035950,414.573303 
	C662.731445,412.883240 662.404602,411.886597 661.100647,411.070190 
	C659.361206,409.981201 659.018677,411.712158 657.848267,412.591980 
	C655.256897,413.806030 653.113647,413.032257 650.636963,412.095551 
	C648.552979,410.154663 646.424561,408.875336 644.402222,407.438019 
	C641.950745,405.695740 638.418945,403.252533 638.899963,400.793182 
	C639.828552,396.045624 637.199341,391.998291 637.751526,387.405243 
	C638.070801,384.748932 635.956421,382.143036 633.736511,380.127350 
	C629.467712,376.251312 623.863892,374.682434 618.941650,371.254883 
	C618.351074,370.648071 618.072815,370.251129 617.705566,369.477997 
	C617.570618,368.881866 617.564514,368.633514 617.697510,368.037872 
	C618.326843,367.102142 618.950378,366.706116 620.015503,366.342560 
	C621.778320,366.116791 622.982727,366.692474 624.107178,367.403076 
	C640.799072,377.951996 656.889526,389.281036 670.868286,403.827515 
	C671.568787,406.637451 673.391174,408.369598 674.848633,410.705170 
	C674.490662,416.128265 676.139771,418.034729 680.689941,417.106323 
	C682.661133,416.964142 683.633850,418.307190 684.949951,418.958801 
	C686.168945,419.562408 687.453918,420.268524 688.442566,418.719666 
	C689.218140,417.504700 689.401123,415.656219 688.202332,415.101593 
	C684.274353,413.284485 684.063232,407.943756 679.573486,406.597107 
	C677.915100,406.099731 675.988037,404.571259 676.350342,402.123932 
	C676.496765,401.135132 677.532288,400.957428 678.347107,401.067535 
	C680.770996,401.395050 684.258240,401.322784 684.593628,404.016510 
	C685.162781,408.587830 689.035828,409.995392 691.646240,412.952942 
	C693.528076,414.154663 692.400330,416.473755 693.857178,417.822968 
	C695.358765,416.379517 693.838379,414.795074 694.780945,413.258301 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M597.163940,711.517700 
	C595.131409,710.655701 593.430542,709.816162 591.387634,708.875000 
	C590.377686,708.614197 589.879761,708.159790 589.016968,707.691101 
	C588.347168,707.427063 587.994873,707.259644 587.362671,707.005127 
	C586.544067,706.490601 586.010620,706.054871 585.026367,705.726318 
	C584.331909,705.487183 583.974121,705.315247 583.333130,705.048096 
	C581.404968,703.434448 579.265015,702.742981 577.118286,701.421570 
	C576.104919,700.898254 575.382812,700.529785 574.388672,700.023560 
	C573.492554,699.471680 572.944641,698.952209 572.023132,698.509766 
	C571.071350,697.995056 570.393921,697.540588 569.429504,696.971924 
	C568.488586,696.480225 567.951172,695.943115 567.055298,695.442627 
	C566.787292,695.355469 566.452942,695.107727 566.266235,695.016052 
	C565.489990,694.464478 564.939941,693.953735 564.036255,693.477783 
	C563.278748,693.024658 562.792603,692.642578 562.155762,691.990356 
	C562.378601,691.215698 562.913391,690.216309 563.102478,690.277893 
	C571.700500,693.079834 579.753540,687.528137 588.403015,688.741638 
	C592.501831,689.316650 596.836609,686.424866 601.400940,686.841614 
	C602.849121,686.973816 603.810669,685.862671 603.807922,684.251404 
	C603.805969,683.091125 602.560242,681.447388 604.801880,681.129944 
	C606.215515,680.929688 607.845520,680.541077 608.823181,682.204712 
	C610.904541,685.746460 613.834229,685.475342 616.994080,683.995728 
	C620.977051,682.130798 625.529297,684.228394 629.218933,682.799133 
	C635.531921,680.353577 639.351624,684.324341 643.810547,686.942078 
	C647.088013,688.866333 646.433655,691.144836 644.006470,693.338318 
	C643.029907,694.220886 641.993958,695.039429 640.965149,695.862183 
	C638.786133,697.604797 637.480164,697.353821 636.989075,694.337891 
	C636.164368,689.273193 633.538757,687.091492 628.479980,687.003235 
	C627.316467,686.982971 626.152283,686.999817 624.988403,687.000000 
	C615.753784,687.001282 615.454834,687.333618 616.771057,696.748047 
	C617.059875,698.813965 616.772644,700.749207 614.406006,700.792114 
	C612.237244,700.831482 609.346008,702.491272 608.055908,698.952820 
	C606.329346,694.217407 600.768860,690.950012 596.059998,692.257446 
	C593.355530,693.008423 590.830505,694.299072 587.898438,694.075317 
	C586.865234,693.996399 585.727722,694.473999 585.580200,695.558899 
	C585.387268,696.977722 586.707458,696.833313 587.654236,697.012573 
	C591.888245,697.814148 595.029724,699.383484 593.133789,704.648621 
	C592.864319,705.396973 593.278015,706.698303 594.260498,706.317261 
	C599.084534,704.446716 597.303955,708.495483 597.994385,710.710205 
	C597.826904,711.245789 597.661194,711.392883 597.163940,711.517700 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M673.804443,644.688354 
	C673.462708,644.755981 673.198303,644.715149 673.091064,644.795166 
	C668.447998,646.346680 663.789551,647.006653 659.028625,646.934937 
	C657.604004,646.913452 656.197327,646.287109 655.738342,645.043518 
	C652.843201,637.197876 648.693237,629.740845 648.294922,620.788452 
	C651.026062,618.455383 652.225830,620.418274 652.861389,622.290161 
	C654.254639,626.393677 656.112000,630.324341 657.354492,634.471069 
	C659.427185,641.388184 664.092896,643.683289 670.875732,640.521362 
	C673.571960,639.264526 676.069031,638.764954 679.002380,638.949097 
	C683.698059,639.244080 686.011108,635.474548 683.886719,631.329041 
	C682.720215,629.052795 680.967773,627.291992 678.670837,626.114685 
	C678.226013,625.886780 677.730347,625.775513 677.270142,625.584900 
	C672.392944,623.565735 669.700562,620.339722 670.512756,617.508362 
	C671.320679,614.691833 674.431519,614.099060 680.518188,615.785767 
	C684.392090,619.337341 681.838318,620.050537 678.732422,620.864990 
	C685.239136,620.828247 686.239258,630.138794 693.462952,629.200195 
	C687.410095,626.533508 682.980835,622.570129 682.641846,615.453003 
	C682.421021,610.815002 684.692261,607.495850 688.476135,606.921631 
	C695.420288,605.867737 697.992126,607.312927 699.603882,612.633850 
	C700.596497,615.910339 699.545227,617.659851 696.094360,618.437439 
	C699.303650,619.887512 701.302856,622.331116 703.714111,624.821472 
	C703.935303,625.090820 703.988647,625.013489 703.947571,625.036255 
	C705.812683,628.979065 709.405212,632.665222 705.332275,637.689331 
	C704.460632,638.488770 703.871521,638.974182 703.132690,639.703613 
	C702.143860,641.008362 700.874695,640.982117 699.366943,641.155029 
	C698.562012,641.035583 698.107910,641.000549 697.315186,641.017151 
	C694.072327,642.006165 691.951904,638.920837 689.904419,639.142273 
	C687.872009,639.362000 686.869446,643.048279 684.095947,643.778015 
	C681.987976,644.332642 679.890259,644.569092 677.780640,644.088684 
	C676.450439,643.785767 675.221436,643.487732 673.804443,644.688354 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M648.846252,620.008118 
	C651.316284,628.112610 653.871643,635.921997 656.399658,643.740234 
	C657.124817,645.982910 658.880493,646.145996 660.776367,645.973938 
	C664.550659,645.631165 668.319763,645.231873 672.537476,644.865540 
	C668.860596,648.646729 663.639343,647.857910 658.833740,647.745361 
	C656.300232,647.686035 654.258118,647.760925 651.841370,648.747986 
	C647.337708,650.587158 642.533691,651.042603 637.926758,648.596619 
	C636.255981,647.709595 634.764282,647.940186 633.342590,649.178894 
	C627.187744,654.541321 620.540955,654.200195 614.009705,650.257324 
	C610.879822,648.367920 609.885315,649.553894 609.028687,652.625488 
	C608.068542,653.976685 607.179260,655.010620 605.336426,655.022461 
	C603.579163,655.324402 602.156250,655.658752 600.364258,656.005127 
	C598.608337,656.011841 597.221558,656.006653 595.418579,656.000732 
	C594.640564,655.616211 594.465210,655.161499 594.594971,654.280151 
	C595.681519,652.829468 597.033447,652.770569 598.349976,652.565735 
	C604.942749,651.539795 606.651489,649.689819 606.939636,643.251953 
	C607.005615,641.778442 606.511536,639.793823 608.108948,639.225952 
	C610.144958,638.502136 610.720581,640.723938 611.625610,641.999756 
	C616.811523,649.310791 622.910034,651.411316 628.834473,647.928223 
	C629.707092,647.415222 630.791565,646.984375 631.086853,646.047424 
	C632.727295,640.842834 635.598389,642.522095 638.690491,644.502930 
	C641.633850,646.388306 644.860779,647.084595 648.225403,645.828064 
	C650.848083,644.848511 652.409241,643.063782 651.438660,640.025574 
	C650.434875,636.883240 648.349426,637.831909 646.216919,638.630127 
	C642.285950,640.101562 639.441040,638.497437 637.270752,635.241699 
	C634.476685,631.050293 633.813599,624.965515 635.867737,622.514282 
	C637.215088,620.906494 638.955078,620.876526 640.873230,621.088806 
	C644.529907,621.493408 645.253113,620.447876 643.807495,617.179199 
	C643.181763,615.764526 641.990417,614.814453 640.911865,613.303711 
	C641.120728,610.479370 643.085571,611.774902 644.679993,611.223389 
	C645.160461,610.989868 645.330627,610.982239 645.755981,610.961121 
	C646.705994,610.185303 647.655029,609.834412 648.773499,609.192810 
	C653.226807,609.457153 657.575134,608.033264 661.862061,610.202515 
	C660.519897,613.499573 657.528198,611.875854 654.968811,612.858337 
	C653.615601,613.293823 652.600525,613.362061 651.245605,613.481201 
	C648.986023,613.395874 648.222595,614.257629 648.769409,616.439819 
	C648.912354,616.933533 648.961914,617.132751 649.067200,617.633240 
	C649.139465,618.136902 649.155884,618.339294 649.138428,618.842041 
	C649.043457,619.333801 648.982300,619.525085 648.846252,620.008118 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M648.753601,422.266968 
	C650.480469,422.014069 651.985291,422.143341 653.666748,421.149475 
	C655.698608,420.003143 657.034729,420.353668 658.156616,422.412994 
	C658.950623,426.296661 663.795898,425.659454 664.526245,429.422180 
	C664.040283,431.389343 661.620789,431.599091 661.698120,433.652405 
	C663.809204,434.940857 665.003601,432.636963 667.074463,432.431030 
	C669.395813,433.001495 670.640625,434.119171 670.218994,436.669861 
	C667.641541,436.809967 666.818481,438.050140 667.001465,440.613312 
	C666.671875,441.000000 666.343628,441.006683 666.015686,440.998993 
	C661.810059,440.900574 657.088318,442.265411 654.343750,437.002380 
	C652.882446,435.282776 651.216553,434.310699 649.206055,433.260742 
	C648.799500,427.458191 648.568420,427.281860 643.618286,429.196136 
	C642.565979,429.603088 641.586243,430.232391 640.210754,429.228577 
	C640.790222,427.741516 641.608643,426.515656 642.712280,425.145264 
	C644.853149,424.213379 647.234924,424.594025 648.753601,422.266968 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M703.923401,624.732544 
	C700.011719,622.533020 696.394470,619.509277 692.031616,618.066956 
	C692.388123,616.531921 693.036316,616.461609 693.717896,616.509338 
	C695.573181,616.639221 698.411743,619.030945 698.782043,615.375000 
	C699.056763,612.661926 698.917114,609.001587 694.983948,608.228271 
	C691.586243,607.560242 687.754578,606.738037 685.252502,609.949219 
	C682.926514,612.934509 683.449341,616.526855 684.872314,619.901978 
	C686.923523,624.767212 691.299622,626.819763 695.727051,628.794739 
	C696.762085,629.256531 698.279419,629.570129 697.532043,631.098816 
	C697.028137,632.129517 695.813232,631.561157 694.842590,631.299377 
	C690.670166,630.174072 686.330750,629.943237 683.792114,625.190735 
	C682.044800,621.919678 677.073486,622.153870 674.353577,619.339233 
	C675.943604,617.402100 677.233521,618.817017 678.458862,619.294067 
	C680.471619,620.077759 681.713806,619.832825 681.508789,617.102295 
	C680.817871,616.606934 680.427246,616.248108 679.997070,615.393311 
	C679.646301,613.903503 679.735352,612.834900 680.545166,611.518555 
	C681.125977,611.097046 681.676697,611.030518 681.710266,610.861267 
	C682.864075,605.051819 686.991699,605.190002 691.737305,605.066956 
	C696.875366,604.933716 702.902039,610.142395 700.588013,617.305725 
	C699.359619,621.108093 703.786316,621.590088 703.923401,624.732544 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M851.732788,577.731750 
	C852.480469,580.700439 851.073242,582.612549 849.162231,584.690674 
	C848.436707,585.695801 847.757996,586.245789 846.656677,586.786377 
	C844.592834,587.575256 843.589905,589.048279 842.273682,590.704102 
	C840.964539,591.726074 839.843384,592.052002 838.293762,591.234131 
	C834.180908,593.014099 830.778503,596.298157 825.432129,595.001465 
	C822.628479,595.001343 820.259399,595.000244 817.446411,594.998352 
	C815.598511,592.476257 816.776550,591.365356 819.224487,591.078369 
	C820.840393,590.888916 822.477661,591.016479 824.552673,591.001831 
	C833.395874,591.010803 839.661255,586.427429 844.377625,580.375549 
	C846.670227,577.433838 848.266418,576.473999 851.732788,577.731750 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M680.832581,611.201538 
	C680.896484,612.221863 680.976562,613.232178 681.037842,614.624451 
	C678.945007,616.558533 677.770020,614.131409 676.091370,613.871094 
	C674.074402,613.558350 672.160522,613.223877 670.610901,615.045837 
	C668.996399,616.944092 669.728210,618.714966 670.755554,620.528809 
	C672.181030,623.045471 674.009644,624.979736 676.849426,626.048340 
	C678.913818,626.825073 681.437866,627.493774 681.369019,630.832214 
	C676.032471,632.342346 670.745728,629.802429 667.229065,624.202148 
	C665.827332,621.969971 664.671814,619.724121 661.455383,621.415649 
	C663.718567,622.236450 667.013184,621.962402 666.086060,626.196350 
	C663.545410,627.522827 661.304810,626.242249 659.023926,626.259277 
	C656.228333,626.280151 654.247742,624.960876 653.839539,622.000061 
	C653.397888,618.797241 654.895203,616.985046 658.059814,616.479065 
	C658.543518,616.401855 659.008728,616.214539 659.283691,615.606140 
	C657.828735,613.828308 654.650208,614.973999 653.733643,612.092041 
	C656.146484,610.404175 658.729309,611.037598 661.597290,610.628540 
	C662.339966,610.734192 662.660767,610.920410 663.256592,611.155518 
	C665.092041,611.598816 665.408813,612.801086 665.468994,614.051208 
	C666.156311,611.582214 667.431213,609.540955 670.676758,609.049683 
	C671.460388,609.062195 671.903870,609.076355 672.676025,609.033203 
	C675.538391,609.134583 678.300720,608.216248 680.618103,610.850037 
	C680.861389,611.159790 680.848694,611.191650 680.832581,611.201538 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M745.574280,690.426147 
	C749.594666,682.133240 753.745300,674.041931 757.525269,665.353882 
	C757.547424,663.950989 758.047241,663.146484 759.053528,662.972595 
	C760.573425,662.709961 760.683899,663.985657 760.920105,665.399292 
	C760.744080,666.939148 760.373718,668.083313 760.396912,669.637878 
	C760.377136,670.694580 760.267273,671.388245 760.075806,672.424316 
	C759.848938,673.448425 759.557556,674.090027 759.365845,675.124268 
	C759.050293,676.627075 758.612549,677.724365 758.083435,679.167603 
	C756.221497,683.692688 755.170410,688.206055 751.884827,692.011414 
	C749.347595,693.429810 747.937256,691.272461 745.574280,690.426147 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M689.050354,727.803040 
	C695.629333,724.633972 702.775452,723.925842 709.849487,721.996094 
	C709.874939,724.695068 707.544617,724.739197 705.951965,725.722534 
	C703.310974,727.353516 700.103882,727.930908 697.507996,730.353455 
	C696.615845,730.886841 695.959839,731.087219 694.958496,731.302185 
	C693.980164,731.437378 693.326843,731.491943 692.404846,731.835083 
	C690.878235,732.286316 689.646240,732.602539 688.150757,733.153809 
	C687.436768,733.405334 687.030579,733.562622 686.327271,733.887634 
	C685.390869,734.337097 684.749207,734.617493 683.781372,735.091492 
	C683.198608,735.346252 682.956299,735.437683 682.342529,735.671387 
	C676.874084,736.476440 671.935242,738.029297 666.817627,738.546814 
	C665.002930,738.730347 663.160522,738.609619 661.118530,737.516479 
	C660.250549,736.550049 660.109680,735.748108 660.373657,734.509460 
	C665.555603,732.159302 671.085571,732.818848 676.238586,731.075012 
	C680.253723,729.716187 684.589294,729.266479 688.849365,728.797302 
	C689.509705,728.724609 688.704773,728.986206 689.283020,728.692322 
	C689.704346,728.478333 689.537720,728.129028 689.050354,727.803040 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M775.097107,679.682190 
	C772.562927,681.287598 769.458130,681.310364 767.346924,683.933655 
	C765.622070,683.069763 765.461914,681.404846 764.510559,679.843750 
	C763.867737,678.652710 763.593140,677.599976 762.393127,676.786987 
	C760.773682,675.422852 759.786316,674.091003 761.230774,672.422180 
	C762.299805,671.187256 762.143005,670.109375 761.201660,668.558044 
	C761.615601,663.349670 762.218933,662.917175 766.682068,664.677124 
	C766.991333,666.289246 767.041809,667.617920 766.998657,668.943665 
	C766.926208,671.166992 767.707153,671.751404 770.014038,671.251770 
	C773.221680,670.556946 776.538025,670.249451 779.823669,670.075867 
	C781.499817,669.987305 783.539795,669.215881 784.684814,671.890259 
	C781.607727,674.485840 777.242798,671.982056 774.722168,675.929932 
	C773.564697,677.508179 774.526306,678.408325 775.097107,679.682190 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M670.247131,437.263855 
	C669.963867,435.265900 668.855225,434.231110 667.296875,433.148834 
	C666.036987,432.238037 664.881592,431.693237 664.112305,430.287292 
	C663.017517,426.539459 658.346313,427.847595 657.075989,424.339294 
	C657.175964,423.834290 657.334778,423.673462 657.727356,423.264191 
	C658.296631,422.694733 658.678650,422.442566 659.434448,422.143799 
	C660.727356,421.881561 661.644836,421.945557 662.730591,422.763489 
	C664.132080,424.232422 665.503418,424.847076 667.177246,425.282318 
	C674.881836,427.285614 676.995483,430.170715 677.000000,438.563690 
	C674.672424,439.209442 672.558411,438.474152 670.247131,437.263855 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M733.192017,467.629700 
	C731.843384,467.303131 730.866821,467.028412 729.888550,467.022003 
	C728.093445,467.010254 726.594055,466.505402 725.705566,464.873108 
	C725.521973,464.535675 725.590698,463.683136 725.811829,463.563110 
	C727.543518,462.623444 729.295654,462.869232 731.032898,463.678680 
	C735.377991,465.703369 739.465942,466.262848 743.100891,462.101776 
	C744.404907,460.609039 746.251343,459.572449 748.454956,460.094208 
	C749.710754,460.391510 751.357239,460.535980 751.427917,462.186401 
	C751.490234,463.639771 750.060425,463.833038 748.898560,464.011719 
	C747.875732,464.169037 746.952576,464.475250 746.494568,465.514130 
	C744.544067,469.937622 741.849365,471.124146 737.169434,469.500519 
	C735.921265,469.067474 734.762939,468.375610 733.192017,467.629700 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M736.731201,422.210938 
	C739.273438,423.915863 741.597778,425.612854 744.870850,425.024475 
	C747.924744,424.475494 748.831543,428.237488 751.764648,428.740082 
	C748.130005,433.247589 742.734314,432.837646 737.371887,433.835846 
	C736.529480,434.185242 736.151489,434.060608 735.925903,433.334656 
	C735.898682,429.464417 732.191833,425.768799 736.731201,422.210938 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M729.796143,707.503479 
	C734.916443,701.821228 739.743896,696.138794 744.813965,690.242798 
	C745.056702,690.029358 745.021667,690.013733 745.033936,690.028809 
	C748.213867,695.184387 743.635010,698.343994 741.271912,701.151001 
	C737.083374,706.126282 732.693481,711.119751 727.368347,715.061035 
	C726.031433,716.050598 724.734192,717.128113 722.474609,715.654785 
	C722.433960,714.544983 722.824707,714.035583 723.491577,713.351807 
	C723.689270,712.963867 723.610901,712.750488 723.789246,712.761719 
	C725.653137,711.524353 727.260437,710.062500 729.107666,708.334351 
	C729.399475,707.879883 729.451355,707.691589 729.796143,707.503479 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M681.308167,440.279846 
	C680.813538,437.119690 682.687500,436.961487 684.843201,436.985291 
	C689.582642,437.037628 694.323181,437.000671 699.531616,436.999451 
	C701.385315,437.002686 702.770630,437.006592 704.571533,437.011658 
	C705.575867,437.690613 705.737854,438.458282 705.237183,439.658325 
	C697.249268,441.951447 689.425415,440.947998 681.308167,440.279846 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M781.007690,647.368347 
	C783.444275,648.612183 786.103027,649.977844 788.289307,651.885193 
	C793.739197,656.639832 793.361877,658.316040 786.356873,660.765625 
	C788.099243,662.967407 791.852112,661.308716 792.928284,664.642700 
	C788.773315,665.400757 785.014404,664.565125 782.506836,660.778809 
	C781.808899,659.724792 780.758057,658.563232 781.664917,657.153809 
	C782.490234,655.871094 783.660583,656.515259 784.802612,656.745972 
	C786.037354,656.995544 787.728394,657.634583 788.346558,656.114990 
	C788.966187,654.592041 787.235352,653.981628 786.273926,653.140137 
	C784.377930,651.480652 781.133972,651.124939 781.007690,647.368347 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M688.628296,727.594238 
	C690.087280,727.985596 691.445129,727.363770 692.384094,728.706055 
	C682.455078,732.118286 672.286438,733.687988 661.522705,734.512634 
	C659.549255,735.311401 658.011047,735.548645 656.213135,734.143799 
	C656.933472,730.743408 659.519226,732.481445 661.360535,731.945679 
	C664.427856,731.053284 668.177612,733.755859 670.769836,729.793884 
	C672.388245,729.186523 673.953796,728.944763 675.996826,728.657898 
	C678.810669,727.439697 681.317749,728.251343 684.101013,727.974487 
	C685.703674,727.868896 686.937561,727.776917 688.628296,727.594238 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M673.863403,644.826782 
	C674.015564,643.003967 674.708496,642.134155 677.022095,642.505676 
	C684.683655,643.735962 685.984436,642.893372 689.524231,635.577332 
	C690.794739,638.820557 693.362000,640.095154 696.638184,640.954956 
	C692.137512,640.781006 688.150574,643.236755 684.032593,645.180969 
	C680.672485,646.767456 677.438110,647.942993 673.863403,644.826782 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M766.985168,664.581299 
	C763.898315,663.879517 761.700623,664.153809 761.984131,668.606689 
	C761.667847,670.038086 760.948792,670.495728 759.579468,670.225098 
	C758.856323,668.767578 758.916870,667.520386 759.611084,665.992798 
	C760.211182,660.247925 761.180542,654.927551 763.591919,649.556274 
	C768.350769,650.621582 765.124939,653.442810 764.906433,655.598938 
	C764.707397,657.563965 763.191467,660.080383 767.210388,657.966309 
	C769.103516,656.970520 769.064026,658.708740 768.689209,659.746338 
	C768.151184,661.236145 766.624939,662.347412 766.985168,664.581299 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M697.336548,730.180908 
	C700.096191,725.358948 705.857910,725.559692 710.017212,722.421509 
	C712.102478,721.010803 714.035950,719.840698 716.266235,718.458313 
	C717.181396,716.501892 718.847595,716.180054 720.602051,715.475891 
	C721.106384,715.318298 721.272644,715.204041 721.741028,715.072083 
	C722.043152,715.054321 722.029114,715.049500 722.035156,715.055725 
	C723.078674,716.520203 722.862854,717.962524 721.575928,719.013916 
	C714.535950,724.765625 707.103943,729.745850 697.336548,730.180908 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M667.006775,441.369507 
	C671.874939,440.951538 675.104004,445.116913 679.651611,445.962463 
	C680.439453,446.108948 680.962646,446.974731 680.604675,447.937561 
	C680.249329,448.893402 679.354004,448.838348 678.545532,448.979187 
	C672.864502,449.968811 669.306702,447.743286 667.006775,441.369507 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M824.707336,590.726379 
	C823.747253,592.419800 822.059509,591.919434 820.516052,591.990479 
	C818.908264,592.064514 817.186340,591.872620 817.020508,594.600952 
	C812.789612,595.172241 808.645081,594.898254 804.661560,592.854187 
	C811.128479,591.042236 817.618774,589.622864 824.707336,590.726379 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M642.881836,726.524048 
	C638.983582,728.118591 635.512146,725.774780 631.774475,725.064697 
	C627.374878,724.228821 623.138550,722.955750 618.984558,720.676270 
	C626.813416,722.313660 634.770813,724.265747 642.881836,726.524048 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M763.507080,649.274414 
	C763.478394,654.761047 761.435486,659.928955 760.165710,665.513428 
	C759.767944,665.076782 759.891113,664.140076 759.531921,663.886414 
	C758.860168,663.412048 758.505798,664.304077 757.901001,664.888733 
	C758.730591,659.515137 759.892395,654.041565 763.507080,649.274414 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M735.644653,433.007324 
	C736.118591,433.312103 736.309875,433.567169 736.785767,433.883820 
	C737.164124,434.856201 737.330688,435.712433 737.747437,436.785889 
	C738.671509,438.018829 739.023438,439.083466 738.246826,440.639679 
	C735.893616,440.869629 733.718323,441.398193 731.360229,440.156799 
	C729.849487,438.704132 729.689697,437.285339 730.734192,435.374298 
	C732.115234,433.658691 733.586670,433.085846 735.644653,433.007324 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M733.472046,453.002014 
	C734.545410,452.999969 735.237854,452.864777 735.852722,453.025879 
	C737.527161,453.464569 740.431213,451.746399 740.602539,454.781830 
	C740.745483,457.313629 737.824280,457.363922 736.095520,457.665924 
	C733.670410,458.089539 731.404419,456.829926 730.656006,454.448395 
	C730.129761,452.773834 732.043640,453.198151 733.472046,453.002014 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M730.646240,435.069641 
	C731.000366,436.388428 731.000732,437.776855 731.001770,439.581696 
	C728.699097,441.106812 726.524048,442.603638 723.721985,442.009064 
	C722.610779,441.773285 721.273987,441.923492 721.286987,440.297028 
	C721.298035,438.931732 722.312256,438.075653 723.506226,438.162262 
	C726.378540,438.370605 728.447266,437.078156 730.646240,435.069641 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M747.957214,444.643036 
	C746.324707,446.115204 741.477173,442.319275 742.960083,448.633179 
	C741.032410,448.840332 738.148804,448.839996 738.426331,446.454163 
	C738.728027,443.860718 742.142883,443.029816 744.125793,441.484924 
	C745.707825,440.252319 747.199768,442.506531 747.957214,444.643036 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M632.742065,600.320557 
	C635.146545,598.146667 637.742310,599.326660 640.550110,598.961670 
	C640.984131,600.357300 640.992065,601.760925 641.000000,603.584717 
	C638.604675,603.671814 636.209351,603.338745 633.406982,603.004028 
	C632.332458,602.332031 632.241577,601.541626 632.742065,600.320557 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M705.403625,637.954590 
	C706.859192,633.746277 705.410339,629.790527 703.915527,625.448608 
	C709.680542,629.440613 710.329041,633.941772 705.403625,637.954590 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M833.221191,605.688721 
	C831.465942,609.678284 828.237793,607.312988 825.432617,608.000000 
	C825.000000,608.000000 825.000000,607.500000 825.000000,607.250000 
	C825.559753,601.690491 829.722534,604.936646 832.561890,604.000000 
	C833.393677,604.380066 833.541077,604.839233 833.221191,605.688721 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M786.666687,672.000000 
	C787.630127,673.116577 788.260254,674.233154 788.945190,675.674805 
	C784.938904,677.972107 780.762939,678.057129 776.169189,676.491699 
	C774.886597,673.481689 776.958191,673.367004 778.662170,673.077454 
	C780.531189,672.759827 782.499939,673.347717 784.662598,672.253906 
	C785.444458,672.000000 785.888916,672.000000 786.666687,672.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M644.658569,610.998291 
	C644.157654,612.326355 642.593628,611.724304 641.225525,612.522522 
	C639.068909,615.110962 636.626160,614.661316 633.743286,613.799927 
	C633.143066,613.328979 632.947876,613.088013 632.876343,612.398804 
	C633.243469,609.898621 634.788818,610.014648 636.623169,609.993347 
	C638.059570,609.993591 639.126465,609.997009 640.593262,610.001404 
	C641.558289,610.110901 642.044495,610.415527 642.782593,610.815918 
	C643.442322,610.998108 643.884583,610.998535 644.658569,610.998291 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M721.193604,467.469543 
	C723.193604,467.439087 724.215332,468.320618 723.586182,469.887512 
	C722.871948,471.666229 721.005188,470.920715 719.617676,470.957428 
	C718.569763,470.985138 716.729980,471.460510 716.697876,470.002106 
	C716.638062,467.284821 719.445007,468.434357 721.193604,467.469543 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M716.237061,435.658081 
	C715.836914,436.005188 715.665649,436.038940 715.506653,436.008698 
	C713.420715,435.612122 709.794067,438.878754 709.523865,434.781525 
	C709.315552,431.621704 713.177490,433.602600 715.568359,433.001923 
	C716.576660,433.686920 716.740356,434.456604 716.237061,435.658081 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M670.792114,608.786926 
	C668.497742,610.737549 666.294189,612.683044 665.664307,616.501709 
	C664.835815,614.533325 664.275513,613.202148 663.623413,611.537598 
	C666.191467,611.137878 667.310974,607.025269 670.792114,608.786926 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M605.002563,655.380981 
	C605.838196,653.877441 607.056091,653.422668 608.655212,653.021545 
	C608.977783,653.959595 609.018921,654.939209 608.957581,655.912415 
	C608.845398,657.692383 607.986633,659.506958 606.252808,659.388916 
	C604.201233,659.249390 605.309265,657.058716 605.002563,655.380981 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M649.000000,433.000000 
	C650.678406,434.116486 655.528870,430.308777 654.036804,436.632904 
	C652.607605,436.990509 651.224915,436.989838 649.427246,436.989075 
	C648.182007,436.004730 647.922424,434.930176 648.747925,433.362213 
	C649.000000,433.000519 649.000000,433.000000 649.000000,433.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M794.999512,666.369202 
	C798.215576,667.135681 797.205383,669.634644 796.428467,671.578064 
	C795.852539,673.018799 794.317078,673.678223 793.070862,671.349121 
	C794.883423,670.151611 795.073181,668.506897 794.999512,666.369202 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M684.229858,727.745605 
	C682.227905,729.819763 679.610535,728.478760 676.832214,728.713989 
	C678.719910,726.448730 681.478516,727.847229 684.229858,727.745605 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M680.858032,610.770447 
	C678.470886,610.526672 676.080444,609.893494 673.347290,609.118164 
	C675.925476,607.745178 678.833252,606.587524 680.858032,610.770447 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M648.646484,433.048584 
	C649.000916,434.064362 649.001892,435.128204 649.007507,436.590515 
	C647.960632,436.884277 646.608154,437.231934 646.279053,435.774445 
	C645.931091,434.233063 646.955261,433.491882 648.646484,433.048584 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M720.795898,715.213989 
	C720.172424,717.032471 718.531616,717.418701 716.855103,718.203613 
	C716.733032,715.661987 718.961426,715.667053 720.795898,715.213989 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M659.568359,728.450317 
	C658.345276,729.440918 657.093872,728.946350 655.629272,728.611633 
	C656.700073,726.687195 658.135254,727.455811 659.568359,728.450317 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M600.928711,712.952148 
	C601.215698,711.899475 600.580811,710.197266 602.532959,710.572510 
	C603.650330,710.787292 603.956360,711.831482 602.841858,713.039001 
	C602.223877,713.459412 601.842163,713.559998 601.144653,713.377136 
	C600.868103,713.242371 600.813599,712.999512 600.928711,712.952148 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M838.160767,572.293884 
	C839.067688,572.000061 840.134827,572.000366 841.602173,572.001587 
	C841.209595,574.928894 839.816650,574.081604 838.160767,572.293884 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M738.321899,440.941956 
	C737.998108,439.932343 737.998596,438.867157 737.998352,437.402527 
	C739.524780,438.078552 741.093811,439.146973 738.321899,440.941956 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M699.254883,641.310730 
	C700.107422,640.708313 701.198669,640.346069 702.636475,639.965759 
	C702.638855,642.264526 701.211487,642.223755 699.254883,641.310730 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M716.328186,435.942505 
	C716.001404,435.251495 716.000427,434.501740 715.998535,433.377228 
	C718.040527,433.547302 718.772522,434.390808 716.328186,435.942505 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M705.328247,439.941833 
	C704.999756,439.253479 704.997131,438.506348 704.990784,437.385986 
	C707.049744,437.541779 707.766724,438.383575 705.328247,439.941833 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M755.061218,497.345154 
	C756.131592,497.055847 757.373108,496.871643 757.941528,498.652771 
	C756.871338,498.943359 755.628113,499.130951 755.061218,497.345154 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M648.657349,609.009155 
	C648.523804,610.016052 647.796509,610.723328 646.327271,610.924316 
	C646.129456,609.532593 646.987793,608.998352 648.657349,609.009155 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M557.068970,688.607666 
	C556.038330,689.226501 555.445984,688.602905 554.904175,687.499695 
	C555.234924,687.162537 555.588379,687.157288 556.212280,687.196655 
	C556.668579,687.596985 556.854370,687.952576 557.068970,688.607666 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M560.068298,690.533325 
	C559.062134,691.358276 558.432373,690.754822 557.868286,689.585449 
	C558.852539,688.931946 559.381836,689.666565 560.068298,690.533325 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M596.952332,711.785034 
	C597.019714,711.411621 597.207092,711.327881 597.693604,711.171387 
	C598.393494,711.462036 598.794312,711.825378 599.262878,712.451477 
	C598.417297,713.067810 597.712585,712.685181 596.952332,711.785034 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M600.973694,713.562012 
	C601.246094,713.250854 601.624023,713.259338 602.284241,713.239014 
	C602.802368,713.480591 603.038330,713.750977 603.373840,714.272583 
	C602.516663,714.901611 601.729736,714.646057 600.973694,713.562012 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M642.898193,610.769714 
	C642.450134,610.822144 641.900208,610.646729 641.171753,610.236938 
	C641.947754,609.000610 642.318542,609.950134 642.898193,610.769714 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M615.532471,716.479614 
	C615.552979,716.519043 615.512024,716.440125 615.532471,716.479614 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M634.192688,429.887238 
	C634.099609,429.683746 634.302307,429.427063 634.802979,429.119873 
	C634.892029,429.321289 634.687927,429.576202 634.192688,429.887238 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M729.881470,707.429199 
	C730.044128,707.747131 729.908325,707.918335 729.514587,708.042725 
	C729.215149,707.719727 729.323914,707.481934 729.881470,707.429199 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M723.956299,712.845581 
	C724.045837,712.986206 723.877014,713.147095 723.822327,713.162231 
	C723.767639,713.177307 723.594604,712.893494 723.594604,712.893494 
	C723.594604,712.893494 723.866760,712.704895 723.956299,712.845581 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M351.421783,305.233398 
	C353.095215,303.799286 354.658691,303.095673 356.486725,302.607361 
	C358.745605,302.003998 360.894836,301.601776 362.314392,303.734100 
	C363.180176,305.034637 359.184753,309.852264 357.251465,309.981873 
	C354.940887,310.136810 352.994781,310.115417 353.529053,313.604065 
	C353.895020,315.993622 351.686829,316.939117 349.504120,316.962128 
	C343.517151,317.025238 337.529053,316.977661 331.541412,316.988892 
	C330.124146,316.991547 328.947388,316.510498 328.025513,315.426727 
	C327.473694,314.777924 326.978363,314.074249 327.315125,313.185272 
	C327.728943,312.092743 328.590088,312.434418 329.390259,312.651398 
	C333.605865,313.794586 337.577606,311.992584 341.650909,311.529114 
	C343.160492,311.357361 345.503052,311.058105 345.652252,309.128418 
	C345.948639,305.295898 348.356049,305.268982 351.421783,305.233398 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M299.451111,328.997803 
	C299.000153,328.247894 298.999084,327.498260 298.997192,326.373688 
	C301.967010,322.505768 305.583893,325.028442 308.531097,325.563812 
	C314.218903,326.597015 319.564117,326.543335 324.960297,324.583832 
	C328.172058,323.417572 331.527924,324.164124 334.818237,324.055511 
	C335.689056,324.026764 336.524048,324.381317 336.703217,325.320587 
	C336.914917,326.430237 336.281616,327.214996 335.306641,327.682892 
	C332.530182,329.015320 329.563477,329.155518 326.563354,328.991211 
	C323.330719,328.814178 320.262726,329.099030 317.844055,331.628906 
	C316.002441,333.555176 313.649139,333.438843 312.015503,331.897675 
	C308.427643,328.512817 304.158203,329.008392 299.451111,328.997803 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M313.433960,301.000000 
	C313.018066,299.657562 312.957916,298.201538 314.365082,297.491852 
	C318.918213,295.195374 323.232239,292.430359 328.124268,290.788818 
	C330.376312,290.033142 332.576630,290.369080 333.231201,292.314484 
	C334.044861,294.732819 331.127014,294.526123 329.900909,294.726807 
	C327.106567,295.184204 324.524689,296.565155 322.936218,298.297791 
	C320.208954,301.272552 317.135193,300.980988 313.433960,301.000000 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M385.124756,303.692780 
	C381.542114,308.390228 377.989685,304.609680 374.599518,303.918915 
	C372.806946,303.553711 371.396851,301.718750 369.148376,302.395050 
	C366.447968,303.207245 366.050781,301.340515 366.060913,299.351929 
	C366.073273,296.920837 367.897461,296.673187 369.532623,297.173279 
	C372.160065,297.976807 374.839081,298.910004 377.155487,300.340363 
	C379.106689,301.545258 380.991058,302.109131 383.591919,302.001892 
	C384.700745,302.205719 385.091064,302.690826 385.124756,303.692780 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M326.214264,347.586945 
	C321.908447,350.207977 317.178558,348.717926 312.532074,348.650391 
	C312.054565,348.643494 311.564880,348.404541 311.108398,348.208984 
	C309.253998,347.414520 305.409027,349.042267 305.656586,346.606140 
	C305.971680,343.505035 309.752441,345.227844 311.972351,345.079010 
	C316.769531,344.757385 321.645508,344.565979 326.214264,347.586945 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M385.537170,310.001190 
	C386.028748,311.767731 385.816956,313.393005 383.355286,313.951538 
	C379.916290,314.320221 376.922424,315.407135 373.744171,315.011322 
	C372.417450,314.846069 370.841400,314.918060 370.529266,313.354309 
	C370.139893,311.403503 371.962097,310.847412 373.291229,310.484558 
	C377.155975,309.429535 381.144745,310.221069 385.537170,310.001190 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M340.451172,296.583405 
	C341.935730,293.664673 350.807800,292.775085 352.226166,295.033386 
	C352.657288,295.719818 352.480011,296.223083 351.959991,296.760040 
	C349.444061,299.358063 343.917358,299.447601 340.451172,296.583405 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M305.022583,318.411011 
	C305.001221,318.000000 305.500610,318.000031 305.750305,317.999390 
	C309.672272,318.257507 313.257294,317.626617 316.866699,317.071747 
	C318.503723,316.820038 320.567505,316.991669 320.642273,319.235687 
	C320.719208,321.545868 318.605804,320.925232 317.256714,320.967285 
	C314.265381,321.060577 311.269104,320.989197 308.274841,320.995728 
	C306.700714,320.999146 305.196075,320.921906 305.022583,318.411011 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M336.841003,303.324799 
	C335.244354,306.013519 327.372711,306.544159 325.802765,304.457245 
	C325.331360,303.830536 325.547852,303.278168 326.058716,302.761536 
	C327.339569,301.466156 335.017120,301.578461 336.841003,303.324799 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M309.460358,312.999756 
	C308.687286,312.212921 308.374573,311.425842 308.033356,310.321228 
	C311.589935,309.777222 315.210724,310.474121 318.773132,309.315125 
	C320.215790,308.845734 322.096222,309.280884 322.257080,310.988953 
	C322.402222,312.529938 320.525116,312.822479 319.288177,312.886993 
	C316.172211,313.049438 313.044006,312.977478 309.460358,312.999756 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M329.409546,336.914490 
	C328.684204,336.977356 328.353088,337.000946 328.022614,336.993713 
	C325.893188,336.947235 323.613770,337.215240 322.372375,334.893341 
	C322.013672,334.222351 322.478882,333.428955 323.124878,333.315979 
	C325.734192,332.859619 328.503235,332.351990 330.775055,334.085815 
	C332.545135,335.436707 331.080841,336.253845 329.409546,336.914490 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M310.359985,305.941528 
	C309.758423,304.872559 309.520477,303.754242 309.141296,302.317322 
	C309.844177,300.857574 311.088318,301.027069 312.636261,301.000061 
	C312.927032,302.949860 313.387909,305.148102 310.359985,305.941528 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M385.256714,303.888428 
	C384.740936,303.504974 384.481842,303.009949 384.110779,302.258667 
	C384.117676,301.691437 384.309631,301.435181 384.787354,301.116852 
	C385.854980,301.166077 386.709991,301.332184 387.782471,301.747437 
	C387.881683,302.307861 387.690063,302.564331 387.212555,302.882996 
	C386.504456,303.258942 386.008942,303.517883 385.256714,303.888428 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M671.012573,404.037903 
	C660.927917,396.334961 651.644714,387.541107 641.101868,380.309357 
	C634.408936,375.718384 627.846680,370.936615 620.359863,367.005859 
	C619.216187,366.992188 618.426270,366.996002 617.349182,366.932190 
	C616.708862,366.740753 616.385620,366.552460 615.950928,366.085510 
	C615.678162,365.572083 615.631104,365.261139 615.434326,364.914612 
	C614.887939,364.868378 614.569458,364.822510 613.999390,364.691895 
	C613.401794,364.529846 613.061157,364.422089 612.457642,364.227814 
	C611.481140,363.917206 610.788696,363.635468 609.831787,363.252319 
	C609.399109,363.064209 609.228333,362.984070 608.805420,362.776398 
	C608.385864,362.562653 608.218506,362.476471 607.799561,362.260254 
	C607.380005,362.042755 607.211914,361.955292 606.791504,361.735901 
	C606.196533,361.426605 605.853333,361.250519 605.236694,360.954651 
	C604.216370,360.492676 603.478577,360.131012 602.441772,359.634460 
	C599.717529,357.898499 597.619446,355.700714 594.194946,355.252808 
	C593.643860,355.127472 593.426086,355.068756 592.888550,354.904968 
	C591.127014,354.234039 589.664734,353.728149 587.855713,353.200836 
	C587.325256,353.025879 587.115784,352.947266 586.599731,352.732605 
	C585.104248,351.997162 583.965149,351.313629 582.592102,350.396484 
	C581.794617,349.902222 581.320801,349.479462 580.392334,349.280457 
	C579.900696,349.153168 579.705261,349.100128 579.220398,348.961334 
	C578.178345,348.626923 577.429321,348.363892 576.333862,348.266357 
	C575.641357,348.176422 575.249023,348.101624 574.570068,347.928070 
	C573.899841,347.719452 573.526367,347.573608 572.879211,347.298126 
	C572.412903,347.105225 572.226196,347.026489 571.757324,346.831207 
	C571.087341,346.562927 570.698303,346.414459 569.999084,346.165771 
	C568.625549,345.662933 567.607178,345.186035 566.640991,344.029846 
	C566.365662,343.255402 566.373962,342.790039 566.656921,342.018494 
	C572.716187,339.325195 577.565369,342.739685 582.335938,344.987854 
	C588.197876,347.750366 594.411438,349.541748 600.258545,352.281311 
	C601.883301,353.042542 603.953552,353.829071 605.343384,352.081177 
	C606.891296,350.134583 605.305176,348.165466 604.322388,346.612030 
	C602.682251,344.019562 599.630920,342.995514 597.213196,341.258392 
	C594.919739,339.610657 592.370483,338.157013 592.776611,334.452881 
	C599.818237,336.904907 605.363953,341.745270 611.729736,345.845825 
	C612.705750,346.761444 613.594299,347.216492 614.720215,347.840668 
	C614.932190,348.053406 614.995544,348.003082 614.974609,348.037659 
	C615.683716,348.767761 616.575745,349.200256 617.652100,349.862122 
	C618.821045,350.813446 619.810974,351.526764 620.964661,352.501862 
	C621.379395,353.386688 621.950256,353.492249 622.714661,353.819458 
	C623.270752,354.122498 623.621338,354.223236 624.245239,354.331207 
	C624.518616,354.338470 624.546936,354.307556 624.566650,354.313171 
	C624.759216,354.332336 624.931946,354.345886 625.292786,354.550476 
	C625.786926,354.980286 626.131592,355.143280 626.812378,355.365753 
	C631.994141,358.547821 636.097595,362.663269 641.323120,365.830872 
	C641.848328,366.086182 642.051086,366.204041 642.532959,366.536987 
	C648.083862,373.958588 655.813782,378.637848 662.002197,385.366302 
	C662.429871,385.691711 662.599976,385.817688 662.997070,386.155609 
	C663.627014,386.802826 663.980713,387.279968 664.571289,387.922058 
	C665.027222,388.384399 665.284424,388.649780 665.738647,389.106567 
	C666.343445,389.675629 666.740234,390.064697 667.354614,390.642517 
	C667.946411,391.289795 668.291016,391.768829 668.872314,392.454895 
	C669.356384,393.011383 669.624878,393.343994 670.079834,393.959045 
	C671.336487,396.110809 670.856262,398.366821 671.869080,400.624146 
	C672.120544,401.879669 672.802856,402.783600 671.667236,403.813293 
	C671.313416,403.914429 671.043152,403.906189 671.012573,404.037903 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M632.772339,357.299622 
	C635.830750,355.822510 639.174805,355.679749 640.669922,352.068573 
	C641.302551,350.540588 643.183899,351.202606 644.659546,351.810303 
	C648.314636,353.315491 650.493408,356.464233 653.000366,359.200470 
	C654.403442,360.731842 654.110779,362.545776 652.479980,364.017334 
	C650.758240,365.570984 649.164307,367.231781 648.252502,369.778564 
	C644.662659,371.894623 644.295105,367.540253 641.743408,367.458435 
	C641.451111,367.747345 641.379395,367.660950 641.326172,367.643158 
	C640.163574,364.865906 637.483521,363.646484 635.497253,361.748535 
	C634.244873,360.551849 632.326477,359.846222 632.772339,357.299622 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M680.622192,360.994263 
	C681.249390,361.807983 681.496338,362.612305 681.871643,363.709534 
	C680.981873,367.768585 679.505737,368.590881 676.643799,365.225922 
	C673.395752,361.406921 669.168640,358.743134 664.875244,356.188019 
	C663.980164,355.655365 661.910400,355.262054 662.845947,353.432159 
	C663.361023,352.424835 664.890442,351.680237 665.877136,352.260132 
	C669.474304,354.374237 675.748779,352.020844 676.655334,358.799164 
	C676.842041,360.195190 678.572021,361.008606 680.622192,360.994263 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M665.065002,390.156555 
	C664.476135,390.050659 664.238220,389.923370 664.039062,389.452698 
	C663.644714,388.642487 663.531555,387.930756 662.812378,387.348083 
	C662.463867,387.363434 662.594116,387.431549 662.528809,387.465240 
	C662.185852,383.788666 657.055786,382.754517 657.782227,378.303467 
	C660.954285,377.761017 662.308655,379.471191 664.291992,381.572388 
	C667.994385,385.494812 672.021545,389.854095 679.306946,389.308533 
	C677.031555,391.840332 675.054016,392.691345 672.377319,392.992188 
	C670.823853,393.393280 669.606812,393.351044 668.119019,393.205200 
	C667.458923,392.800079 667.072266,392.526764 666.721130,391.999512 
	C666.743286,390.815430 666.358643,390.240997 665.065002,390.156555 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M657.871582,377.695801 
	C658.069275,381.736908 663.574829,382.504456 662.618835,387.109894 
	C655.492798,381.119659 648.522095,374.740387 641.501221,368.054230 
	C644.465515,365.563599 645.172363,369.626984 647.638428,369.950470 
	C650.297913,372.619415 652.230530,376.692932 656.675293,372.054810 
	C657.468384,371.819305 657.851807,371.931427 658.076538,372.667725 
	C658.042297,374.473846 656.609802,375.858795 657.871582,377.695801 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M725.379272,363.990784 
	C724.913086,362.843658 724.954712,361.120300 723.537048,361.244598 
	C717.264221,361.794464 711.070862,359.026306 704.397949,360.940918 
	C705.122498,356.122437 709.166565,357.848114 711.770447,358.207977 
	C717.244629,358.964569 722.629028,359.419922 728.077759,358.459991 
	C729.939758,358.131958 731.096436,359.092957 731.508301,360.582794 
	C731.988342,362.319122 730.422058,362.734436 729.115784,363.098602 
	C728.001953,363.409180 726.879517,363.689270 725.379272,363.990784 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M632.613892,356.983826 
	C634.376282,360.519073 638.341919,361.541595 640.314087,364.532013 
	C640.823792,365.304932 641.885925,365.718262 641.450928,367.268005 
	C636.321289,363.850800 631.369690,360.076233 626.092163,356.192566 
	C625.631775,355.750946 625.386169,355.544403 624.854980,355.274994 
	C624.665222,355.103699 624.593872,354.713257 624.590149,354.516022 
	C628.033081,352.619751 629.577393,356.405975 632.613892,356.983826 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M680.845642,416.703735 
	C680.962830,417.482330 681.004944,417.993164 680.864258,418.447357 
	C680.257629,420.406097 678.553833,420.281128 677.269043,419.787933 
	C674.276184,418.639069 674.106995,415.182861 672.346985,412.961060 
	C671.487976,411.876556 673.002563,411.102448 674.636475,411.011505 
	C676.895447,412.804108 678.790955,414.607025 680.845642,416.703735 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M658.357239,372.954895 
	C657.882690,372.690582 657.690063,372.434326 657.212524,372.114929 
	C657.996765,369.434540 665.311951,367.642853 667.244019,369.502869 
	C667.932373,370.165558 667.929688,370.978638 667.433228,371.731628 
	C666.944763,372.472534 666.031189,373.080322 665.364258,372.441620 
	C662.893860,370.075958 660.853271,371.791351 658.357239,372.954895 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M694.621338,413.004211 
	C695.269958,413.758667 695.410278,414.603912 695.847778,415.247009 
	C696.735657,416.552032 697.458740,417.812408 696.062561,419.151642 
	C694.997253,420.173492 693.673218,420.216217 692.441833,419.520264 
	C690.806091,418.595703 691.026855,417.176056 691.603027,415.682159 
	C691.832703,415.086609 691.863037,414.414154 691.994507,413.390198 
	C692.752441,413.003601 693.500000,413.003571 694.621338,413.004211 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M686.356567,378.071594 
	C685.392639,378.708466 684.451233,378.973755 683.532043,379.301300 
	C681.621277,379.982269 679.701660,380.584167 677.918457,379.081512 
	C677.631470,378.839661 677.508423,377.836975 677.606018,377.789001 
	C679.511719,376.852264 681.438477,375.889038 683.664307,376.749664 
	C684.564209,377.097656 685.480408,377.403259 686.356567,378.071594 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M667.931335,393.319885 
	C669.049927,392.637299 670.154175,391.544586 671.785767,392.752808 
	C671.084106,396.539551 674.588745,400.042664 671.700439,403.754852 
	C671.183228,403.166565 671.053040,402.418732 670.941895,401.385437 
	C669.665955,399.046661 671.571411,396.336639 669.246582,394.236023 
	C668.607056,393.887543 668.312012,393.726990 667.931335,393.319885 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M690.307007,371.225586 
	C689.245728,371.005737 688.496338,371.004150 687.372253,371.001892 
	C686.515076,370.599854 685.954590,370.266022 685.890930,369.297668 
	C687.742859,368.672882 689.485718,368.346985 691.611816,368.012970 
	C692.577332,368.687775 692.734314,369.458313 692.236816,370.658295 
	C692.002441,371.001221 692.000000,371.000000 692.001221,371.001221 
	C691.614502,371.379425 691.153320,371.526550 690.307007,371.225586 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M679.056885,353.321289 
	C680.420593,352.674561 681.843689,352.345398 683.635620,352.012390 
	C682.957275,354.297455 681.501953,355.362915 679.056885,353.321289 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M685.792358,368.760712 
	C686.258606,369.494812 686.517212,369.990845 686.886719,370.744049 
	C685.570374,370.913513 683.726379,371.162567 683.528137,369.248474 
	C683.376221,367.782593 684.643372,368.105286 685.792358,368.760712 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M692.341187,370.993835 
	C692.000977,370.252045 691.999512,369.502899 691.996582,368.379303 
	C693.186462,368.145569 694.446167,368.374756 694.520996,369.838379 
	C694.579041,370.975769 693.465393,370.860565 692.341187,370.993835 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M689.208618,352.245544 
	C689.557007,351.158691 690.414185,351.013794 691.668579,351.011292 
	C691.761780,352.589996 691.052368,353.350006 689.208618,352.245544 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M690.041382,371.295441 
	C690.441223,371.005341 690.887268,371.003326 691.667847,371.001892 
	C691.900635,371.640381 691.843811,372.405548 691.044250,372.449951 
	C690.742371,372.466675 690.407532,371.889832 690.041382,371.295441 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M591.461792,254.014618 
	C593.940979,254.288437 593.944946,255.328171 592.756409,256.672119 
	C591.530029,258.058716 590.223999,259.492310 588.084290,258.694275 
	C586.877930,258.244385 586.348328,257.088165 586.283813,255.913757 
	C586.173584,253.905670 587.774719,254.090271 589.083069,254.014893 
	C589.746765,253.976654 590.414307,254.005524 591.461792,254.014618 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M611.715698,259.480072 
	C611.543213,258.113281 612.267029,258.078857 613.083923,258.375458 
	C613.273621,258.444336 613.491272,258.931915 613.421631,259.130554 
	C613.167175,259.855560 612.559143,259.873993 611.715698,259.480072 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M623.817566,238.037674 
	C627.342651,239.479095 630.392761,238.512390 633.515320,238.098373 
	C635.684204,237.810791 638.732178,237.346741 638.790466,240.858154 
	C638.839294,243.803482 636.178467,243.781815 634.018921,243.994583 
	C628.827393,244.506073 623.783325,243.210342 618.687439,242.638382 
	C617.585022,242.514633 615.632690,242.480469 615.582031,240.766312 
	C615.535889,239.205246 617.232361,238.715469 618.444519,238.428864 
	C620.034729,238.052902 621.732178,238.130859 623.817566,238.037674 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M546.539307,311.043396 
	C546.678772,311.492035 546.478455,311.711639 546.045166,312.008789 
	C545.625061,314.141296 547.353210,313.894928 548.452087,314.318054 
	C550.363342,315.053894 552.100159,315.888428 551.892456,318.714172 
	C551.530029,319.826019 551.030762,320.394348 550.259644,320.755432 
	C549.658508,321.036896 549.265991,321.315979 549.820496,320.945862 
	C550.375061,320.575775 549.771729,321.503632 550.492920,320.979370 
	C552.493774,320.914490 553.891052,321.722137 554.252319,323.248260 
	C555.073853,326.718323 555.544495,330.082886 558.075378,333.092194 
	C559.679016,334.998993 557.165710,335.950653 555.798645,336.230499 
	C554.658508,336.396698 555.800903,337.330841 555.416992,336.785828 
	C558.300293,335.377686 560.426575,335.924377 561.429932,339.522461 
	C561.037781,340.338379 560.636292,340.661926 559.756653,340.987579 
	C558.829224,341.180023 558.287231,341.196625 557.335510,341.098816 
	C556.126404,340.856720 555.350647,340.555084 554.097168,340.486755 
	C543.235901,337.820435 532.496765,338.924835 521.579529,337.746338 
	C518.547668,335.328033 521.081482,332.051056 519.489563,329.256958 
	C519.027588,323.265656 523.149536,325.041412 525.783630,324.760895 
	C524.600220,324.319427 522.867615,324.624542 521.117432,323.568390 
	C520.676575,323.179932 520.530029,322.992676 520.246338,322.476410 
	C520.971252,317.456940 522.459839,316.843414 529.583557,318.297363 
	C529.125916,317.697449 528.435852,317.698792 527.905640,317.411316 
	C525.927307,316.338684 523.026855,316.197113 522.656067,313.380829 
	C522.295654,310.643646 525.455261,310.452393 526.733704,308.763611 
	C526.182617,307.508026 523.948853,307.198151 524.880737,304.934265 
	C525.852112,304.471436 526.598694,304.506348 527.654297,304.806396 
	C534.256836,306.335602 540.275085,308.507263 546.539307,311.043396 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M419.671417,302.870605 
	C420.068726,302.962158 420.516022,302.925903 420.738220,302.957245 
	C421.561401,302.331055 422.331665,301.957642 423.473511,301.606323 
	C424.003845,301.522583 424.219116,301.511536 424.754211,301.453918 
	C425.878754,301.090637 426.698212,300.821014 427.831787,300.777710 
	C428.120972,300.924835 428.528168,300.896271 428.729004,300.932770 
	C429.281647,300.650421 429.688049,300.434113 430.495422,300.376160 
	C431.768127,300.005280 432.694336,299.578339 434.125549,299.099426 
	C449.930573,295.358612 465.442596,294.787292 481.066071,295.915497 
	C483.377411,296.082397 485.680176,296.368256 488.387085,296.682800 
	C489.006897,296.713623 489.226471,296.660706 489.737793,296.779999 
	C491.433655,297.068756 492.837769,297.185303 494.646484,297.426971 
	C495.587463,297.447296 496.114136,297.507385 496.876831,297.762085 
	C497.117432,297.878845 497.578644,297.903351 497.799835,297.969788 
	C498.275360,298.325256 498.574799,298.553833 499.173340,298.568054 
	C500.375824,298.543701 501.331543,298.663574 502.636444,298.878662 
	C503.142700,298.860779 503.299805,298.747681 503.648773,298.852570 
	C504.996490,299.290222 506.152374,299.509918 507.649963,299.825134 
	C508.646820,299.993439 509.260437,300.192596 510.155823,300.531219 
	C512.029907,300.815765 513.578552,301.093719 515.485474,301.534241 
	C516.590088,301.962738 517.100708,302.476654 517.491943,303.567261 
	C517.377625,305.131622 516.568176,305.678406 515.097473,305.982422 
	C510.674927,304.928955 506.890076,303.012512 502.287933,302.436646 
	C492.317047,300.171082 482.565979,299.895386 472.386932,299.544891 
	C464.390900,299.703979 456.840454,300.086731 448.909149,300.937683 
	C441.363159,301.637512 434.587433,304.353821 427.024414,304.856232 
	C426.382172,305.089447 426.122223,305.174957 425.464172,305.360657 
	C422.655518,305.888397 420.265594,306.767548 417.452087,305.560364 
	C416.391266,303.255005 418.111511,303.253448 419.671417,302.870605 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M514.728027,305.345642 
	C515.850830,304.904633 516.588318,304.658173 516.971436,303.465332 
	C517.209961,302.958649 517.381104,302.748627 517.955566,302.588196 
	C518.739319,302.720154 519.119751,302.802521 519.744934,303.046082 
	C520.312927,303.466461 520.656555,303.695038 521.308228,304.001038 
	C522.002014,304.204987 522.411194,304.230225 523.141113,304.200134 
	C523.882996,304.259766 524.276550,304.404236 524.797546,304.903809 
	C525.694214,308.242859 528.808105,306.153046 530.566284,307.896698 
	C529.354126,309.905762 526.938171,310.376740 525.269409,311.748596 
	C524.689453,312.225372 523.500977,312.064270 523.527527,313.162445 
	C523.557129,314.383087 524.610596,314.583740 525.549377,314.944550 
	C528.557434,316.100830 531.532593,317.342865 535.139771,318.802399 
	C532.585388,320.566559 530.627930,319.604218 528.608459,319.478760 
	C526.021301,319.318024 522.708069,317.066376 521.008911,321.613190 
	C517.745972,324.076080 514.819763,322.611694 514.505432,319.631805 
	C514.024597,315.074097 512.014099,310.323517 514.728027,305.345642 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M525.328369,305.147949 
	C524.531128,305.119110 524.109924,305.056427 523.369141,304.982269 
	C522.929565,304.760284 522.809692,304.549774 522.775879,303.986237 
	C524.562683,303.119324 525.991699,303.990509 527.723022,304.786133 
	C527.209595,305.059662 526.456970,305.086884 525.328369,305.147949 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M522.566528,303.493347 
	C522.869995,303.935059 522.914185,304.231537 523.021973,304.746704 
	C522.592896,305.635223 522.053833,305.978424 521.120728,305.209900 
	C520.665039,304.678192 520.537415,304.304291 520.778564,303.640106 
	C521.412415,303.388458 521.841797,303.370972 522.566528,303.493347 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M427.892212,300.704712 
	C427.299438,301.286407 426.477936,301.647980 425.317200,302.000061 
	C424.796082,301.828461 424.614136,301.666351 424.696899,301.252197 
	C424.961639,301.000153 425.141479,300.685822 425.147217,300.505676 
	C425.989807,300.378540 426.826630,300.431580 427.892212,300.704712 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M430.800415,300.166290 
	C430.383423,300.633392 429.924927,300.834564 429.198151,301.002502 
	C429.429230,300.424225 429.453094,299.065460 430.800415,300.166290 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M510.338135,300.324219 
	C509.801300,300.515076 509.121277,300.492340 508.216492,300.195099 
	C508.800323,299.123901 509.500885,299.579803 510.338135,300.324219 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M518.217285,302.357361 
	C518.146545,302.744873 517.934082,302.851929 517.380188,303.063843 
	C516.693604,302.828186 516.348389,302.487732 515.923523,301.922028 
	C516.707153,301.122681 517.418701,301.439545 518.217285,302.357361 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M520.755859,303.250458 
	C520.981079,303.759247 520.979126,304.112549 520.967102,304.730896 
	C520.652527,304.577606 520.347900,304.159271 520.016541,303.474121 
	C520.169373,303.169861 520.349060,303.132446 520.755859,303.250458 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M496.905518,297.678864 
	C496.580414,297.866608 496.043427,297.854370 495.278748,297.697113 
	C495.598602,297.527679 496.146118,297.503296 496.905518,297.678864 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M489.916656,296.810120 
	C489.816650,296.961456 489.603790,296.970673 489.089111,296.873230 
	C489.126129,296.733673 489.464935,296.700836 489.916656,296.810120 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M503.796692,298.916351 
	C503.840637,299.070496 503.403625,299.105133 503.194611,299.039490 
	C503.160278,298.628906 503.416016,298.558350 503.796692,298.916351 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M434.623138,298.909302 
	C434.630554,299.047455 434.474457,299.095612 434.434937,299.102936 
	C434.395447,299.110260 434.348541,298.837616 434.348541,298.837616 
	C434.348541,298.837616 434.615723,298.771149 434.623138,298.909302 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M499.224091,298.255188 
	C499.073364,298.429871 498.726624,298.435974 498.200470,298.239136 
	C498.356720,298.053040 498.692413,298.069824 499.224091,298.255188 
z"/>
<path fill="#FADD14" opacity="1.000000" stroke="none" 
	d="
M377.584839,324.905121 
	C377.946198,324.330017 378.192902,324.101257 378.672028,323.768860 
	C381.653015,322.600464 383.595520,320.270081 386.353760,318.714294 
	C386.597595,318.241028 386.705688,318.060913 387.088318,317.739746 
	C388.121582,317.635529 388.954224,317.937378 389.322144,316.754822 
	C389.197937,316.491821 389.385345,316.293457 389.519897,316.270813 
	C390.087677,316.550690 390.397003,316.430115 390.810242,315.701569 
	C394.991669,313.425873 398.844635,311.142395 402.982239,309.491028 
	C404.889526,308.729767 406.905670,307.423889 409.394867,308.952026 
	C411.959198,320.846680 416.208923,332.057556 417.274902,343.963135 
	C417.388214,345.228638 417.905182,346.165863 418.810181,346.955566 
	C421.224823,349.062500 421.824890,351.644135 420.729767,354.602722 
	C420.218475,355.984039 420.425171,357.147827 421.228729,358.350006 
	C422.658417,360.489014 422.782562,363.040924 423.330505,365.459595 
	C424.290741,369.698151 422.842712,374.884064 428.324341,377.366547 
	C429.349304,377.830780 429.099182,379.807007 429.612427,381.410797 
	C429.768433,382.234985 429.777283,382.708221 429.608215,383.511047 
	C428.707550,385.305389 426.137787,385.302277 426.337341,387.959717 
	C424.236389,395.365875 420.014740,401.488037 417.423340,408.701904 
	C417.260803,409.260956 417.187134,409.481323 416.977112,410.019165 
	C416.737335,410.539856 416.634003,410.743164 416.367310,411.242889 
	C416.086700,411.729309 415.969452,411.919250 415.665955,412.383362 
	C414.198822,413.841125 414.493011,415.437744 414.352020,417.267761 
	C414.315704,419.076782 413.755157,420.421417 413.324524,422.176758 
	C412.170074,425.100983 412.012451,427.933716 410.680450,430.787231 
	C405.965576,432.450165 405.215302,431.663025 403.819519,427.501740 
	C401.503021,420.595551 399.082153,413.797852 398.916748,406.385925 
	C398.877563,404.628784 398.101196,402.371216 396.629120,401.518860 
	C390.273651,397.838715 391.490387,391.671112 391.255676,386.051514 
	C391.162689,383.825775 391.106476,381.770294 389.805664,379.886688 
	C388.945099,378.640533 388.190918,377.236786 388.198456,375.735138 
	C388.239349,367.620422 383.333771,360.610046 383.108795,352.561981 
	C383.070038,351.175751 381.974884,350.183228 381.381165,349.001648 
	C380.470795,347.189911 379.277008,344.893555 380.341949,343.310547 
	C382.577972,339.986816 380.582275,337.916443 378.733826,335.964874 
	C376.252106,333.344727 375.681335,330.584991 376.878326,326.946167 
	C377.020569,326.107941 377.206360,325.663422 377.584839,324.905121 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M377.403687,326.696960 
	C377.885345,327.113281 378.387665,327.424072 378.350159,327.516998 
	C376.004974,333.326660 380.509705,335.982391 383.775482,339.111816 
	C384.894196,340.183838 385.282776,341.390381 383.806763,341.662292 
	C379.665924,342.425171 381.018799,345.688934 382.138031,346.814758 
	C385.166473,349.861053 385.454376,353.959930 385.488464,357.258331 
	C385.536957,361.948029 388.822021,365.161560 389.161560,369.576782 
	C389.458618,373.438873 388.489471,377.892792 392.503265,380.942444 
	C393.284729,381.536194 393.341614,383.961548 392.850220,385.188660 
	C391.346344,388.944275 394.488434,392.434509 393.132751,396.141907 
	C392.775848,397.117889 393.842224,397.997101 394.816833,398.325195 
	C400.282715,400.165314 400.521698,404.949554 400.957794,409.457703 
	C401.408020,414.112122 400.877289,418.904419 404.349579,422.839478 
	C405.253448,423.863831 406.062622,425.294586 405.519104,426.969910 
	C404.389679,430.451202 406.603516,430.944824 409.625122,431.033844 
	C410.409332,431.444061 410.657135,431.915039 410.770660,432.867523 
	C410.156311,436.389343 409.535522,439.507904 408.563110,442.922729 
	C403.149597,445.058868 401.465149,444.521759 400.583557,439.360229 
	C399.671600,434.020874 397.326172,428.976501 395.574219,424.188019 
	C393.564514,418.695038 392.762146,413.359741 391.802277,407.819275 
	C390.920166,402.727448 384.797363,398.633789 379.577637,399.175934 
	C370.159058,400.154266 360.738708,401.085602 350.876587,400.342712 
	C350.267090,400.213165 350.029236,400.146088 349.459045,399.926636 
	C347.955780,399.086121 347.463684,397.493927 346.284393,397.061371 
	C339.236206,394.476288 340.230713,388.483643 340.531158,383.082336 
	C340.668182,380.619049 340.235687,378.931763 338.170105,377.244202 
	C338.057678,376.449554 338.208405,375.873627 338.583435,375.094299 
	C341.961884,374.433624 341.771118,372.247070 341.427979,369.615204 
	C341.519470,369.057129 341.546112,368.832001 341.718445,368.299561 
	C341.864136,367.992279 341.866119,367.897156 341.909912,367.878601 
	C343.282074,367.043610 343.898926,365.705017 344.673523,364.093811 
	C344.881439,363.408630 344.932922,362.997040 345.041046,362.279419 
	C345.239929,361.809875 345.382141,361.646301 345.749451,361.248230 
	C346.246307,360.832184 346.479095,360.609894 346.801636,360.141479 
	C347.470581,359.518677 347.939453,359.030121 348.568420,358.226410 
	C348.772644,357.955200 348.794647,357.814270 348.863312,357.795044 
	C350.172211,357.021637 349.994873,355.545441 350.786011,354.208862 
	C350.958496,353.943054 351.369476,353.844299 351.580719,353.838379 
	C358.169159,353.725037 363.898315,357.143646 370.248932,357.220245 
	C371.801910,357.238983 373.363525,358.407410 375.528046,356.764648 
	C368.352905,353.865662 360.776642,355.202545 353.880829,352.211670 
	C353.703766,350.295532 354.152557,348.983032 356.422485,348.739197 
	C361.641022,347.881744 366.222992,349.746521 371.899231,350.264160 
	C366.811859,348.650665 362.276825,349.108337 357.947845,347.233704 
	C358.033875,345.271454 358.675964,343.807098 360.596497,342.842957 
	C362.243439,343.168152 363.625000,343.351440 364.629974,342.277435 
	C366.681549,340.084869 369.399567,339.591919 372.391968,339.081085 
	C370.823700,337.926605 368.883148,337.787872 367.809082,335.856232 
	C367.741699,334.788696 368.030212,334.150208 368.711731,333.307007 
	C368.947235,333.038544 369.121368,332.574951 369.198151,332.339539 
	C369.361877,331.725067 369.473877,331.353516 369.742310,330.728882 
	C370.085205,330.137756 370.347931,329.854797 370.936188,329.519226 
	C371.595947,329.017853 371.981293,328.616608 372.605347,328.071472 
	C372.979218,327.793945 373.114349,327.660309 373.533356,327.549377 
	C374.123901,327.742676 374.218140,328.268890 374.424500,328.296021 
	C375.077057,328.381866 374.377167,328.574799 374.495117,327.621216 
	C374.753082,326.982391 375.036835,326.707214 375.672546,326.395020 
	C376.382996,326.266449 376.784576,326.334625 377.403687,326.696960 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M409.552887,308.800293 
	C402.909363,309.841461 397.565094,313.487213 391.394043,316.121399 
	C390.786621,316.241455 390.547668,316.294800 390.042847,316.094727 
	C394.258942,311.163849 400.446045,309.515442 406.070374,306.993256 
	C410.356598,305.071167 414.812012,303.278900 419.809662,302.754761 
	C419.178894,303.471405 418.289032,303.980621 417.195740,304.716858 
	C416.183624,306.682831 414.352600,307.193451 412.707733,308.444458 
	C411.595978,309.015564 410.745392,309.240173 409.552887,308.800293 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M386.974731,317.949951 
	C385.306824,321.340088 382.572815,323.223022 379.056519,323.964111 
	C380.174957,320.782043 382.905304,319.640808 385.714874,318.078735 
	C386.275665,317.823303 386.523224,317.709564 386.974731,317.949951 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M376.330719,326.656921 
	C375.700104,327.054962 375.416656,327.284210 374.961365,327.727081 
	C374.684265,328.083649 374.578918,328.226593 374.230560,328.260651 
	C373.791992,328.140442 373.596497,328.129150 373.122559,328.022736 
	C373.434479,326.064423 374.714417,324.923157 376.845215,324.418640 
	C377.615692,325.278534 377.261261,325.908783 376.330719,326.656921 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M424.231140,301.726105 
	C423.241364,302.324646 422.434265,302.671295 421.293762,303.003265 
	C421.061646,302.632477 421.162933,302.276367 421.219421,301.645111 
	C421.885437,301.292969 422.596222,301.216003 423.659027,301.069458 
	C424.145355,301.157959 424.279602,301.316101 424.231140,301.726105 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M388.964722,316.421509 
	C389.571167,317.353546 390.010742,318.246735 388.195740,318.012543 
	C387.802032,317.697327 387.615021,317.537933 387.650146,317.084442 
	C388.111877,316.552429 388.398285,316.406036 388.964722,316.421509 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M370.904694,329.369995 
	C370.993866,329.914581 370.777130,330.410065 370.336365,331.072693 
	C370.112335,331.239807 369.700287,331.353485 369.490356,331.313477 
	C369.280426,331.273438 369.139832,331.136169 369.069519,331.067566 
	C369.193329,330.116333 369.730103,329.560425 370.904694,329.369995 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M376.260010,326.854980 
	C376.305817,326.252563 376.628021,325.679352 377.039368,324.783661 
	C377.128479,324.461182 377.437958,324.372284 377.568726,324.465515 
	C377.753571,325.084259 377.807648,325.609741 377.748352,326.519714 
	C377.268768,326.897522 376.902588,326.890869 376.260010,326.854980 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M387.582031,316.811035 
	C387.902771,316.966980 387.933289,317.143677 387.976410,317.588562 
	C387.989014,317.856750 387.595123,318.098785 387.386902,318.201416 
	C386.987457,318.240631 386.796234,318.177155 386.316528,318.025391 
	C386.449341,317.568634 386.870575,317.200226 387.582031,316.811035 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M389.690918,315.926331 
	C389.983551,315.906342 390.190063,315.971344 390.711060,316.112244 
	C390.711090,316.307343 390.396576,316.426605 389.868256,316.397034 
	C389.654449,316.248169 389.604828,316.011322 389.690918,315.926331 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M482.363373,236.046112 
	C481.249084,236.001389 480.499420,236.000946 479.374878,236.000854 
	C477.382629,231.547073 481.486359,232.103622 483.102783,232.269775 
	C486.872620,232.657288 490.547974,233.528687 494.333252,232.428543 
	C495.448975,232.104294 496.282623,232.711670 496.688965,233.728149 
	C497.102722,234.763123 496.783661,235.809723 495.886932,236.361786 
	C493.086151,238.085968 490.320679,240.129532 486.772980,237.929947 
	C485.524414,237.155838 484.081909,236.694534 482.363373,236.046112 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M465.444092,240.000458 
	C465.000000,238.934204 465.000000,237.867203 465.000000,236.400085 
	C467.370575,235.999863 469.741119,235.999741 472.555847,235.999191 
	C472.999664,237.065338 472.999329,238.131897 472.998291,239.598312 
	C470.627777,239.998672 468.257996,239.999176 465.444092,240.000458 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M481.336426,242.148834 
	C481.843597,240.857422 483.088440,241.028503 484.636230,240.999939 
	C485.630920,241.083206 486.549866,240.935638 486.838257,241.294632 
	C487.672729,242.333374 486.567596,242.664963 485.889679,242.828613 
	C484.449432,243.176315 483.004517,243.059738 481.336426,242.148834 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M551.166748,319.266174 
	C551.339661,316.525696 549.511963,316.046112 547.778931,315.391510 
	C546.513855,314.913696 544.524353,314.918701 545.628662,312.350800 
	C550.231750,313.349457 554.097717,315.995514 559.035400,316.656616 
	C559.425110,316.552460 559.791260,316.544281 559.956909,316.622467 
	C560.821716,318.213470 562.508179,317.726471 563.919495,318.562988 
	C564.228394,318.703461 564.179749,318.891449 564.228027,318.807190 
	C564.814148,320.438019 566.581665,319.711609 567.939331,320.538574 
	C568.248291,320.664825 568.258728,320.780548 568.287964,320.730347 
	C568.317139,320.680115 568.255859,321.150665 568.255859,321.150665 
	C568.255859,321.150665 568.681702,321.359772 568.845154,321.187744 
	C569.806458,321.336243 570.604370,321.656799 571.645142,322.194580 
	C572.989624,322.975494 574.127014,323.436890 575.505554,323.897095 
	C581.606934,326.789185 587.216431,330.038452 592.834229,333.852142 
	C594.590149,339.845612 600.036987,341.343689 604.237610,343.896301 
	C607.407410,345.822510 605.845032,349.555634 608.360474,351.343475 
	C606.922668,353.049438 605.930725,355.695923 602.996582,354.769104 
	C599.418152,353.638763 596.007202,351.606079 592.504089,350.754578 
	C583.911255,348.665955 577.050476,341.830444 567.310547,342.062317 
	C566.714539,342.204559 566.540161,342.324982 566.049377,342.487030 
	C564.671692,342.435272 563.620605,342.265625 562.250183,342.021820 
	C561.731689,341.847168 561.531738,341.753357 561.081665,341.439453 
	C560.757568,341.009552 560.683472,340.799713 560.799500,340.315552 
	C560.034546,336.133667 556.541443,337.500244 553.394165,336.534851 
	C557.122620,335.349335 559.350769,333.865479 554.806885,331.458221 
	C552.774109,330.381317 552.730225,328.597290 553.260010,326.748657 
	C553.970825,324.268097 553.631470,322.489319 550.339966,322.026917 
	C549.234680,320.533234 551.367493,320.630890 551.166748,319.266174 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M568.090759,320.376770 
	C566.716797,320.974365 565.169006,321.337921 564.283936,319.098511 
	C565.662537,318.730408 566.890747,319.160919 568.090759,320.376770 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M564.073975,318.404480 
	C562.651306,319.111938 561.301880,318.838623 560.231323,317.052734 
	C561.492249,316.887634 562.842224,317.128143 564.073975,318.404480 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M575.516846,323.885620 
	C574.352295,324.138885 573.020569,324.208923 571.995117,322.711945 
	C573.305298,322.066742 574.343506,322.835602 575.516846,323.885620 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M617.712769,349.808533 
	C616.686401,350.080841 615.613098,349.916412 614.999146,348.399780 
	C616.138977,347.996307 616.921570,348.656342 617.712769,349.808533 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M614.830566,347.747192 
	C613.754944,348.113556 612.685181,347.931213 611.987488,346.446014 
	C613.118347,346.015778 613.975586,346.618469 614.830566,347.747192 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M592.994080,329.008057 
	C592.402039,329.129089 591.719360,329.725555 591.282776,328.936310 
	C591.119507,328.641113 591.289734,327.866943 591.560730,327.682281 
	C592.310974,327.171204 592.693176,327.740692 592.962402,328.706482 
	C593.010132,328.989166 592.999146,329.000977 592.994080,329.008057 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M622.827087,353.736633 
	C622.391357,353.790558 621.862549,353.559357 621.231079,353.045898 
	C621.663635,352.992920 622.198853,353.222198 622.827087,353.736633 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M568.870544,320.860046 
	C568.766724,321.398193 568.516479,321.536102 568.280762,320.861084 
	C568.317139,320.680115 568.732483,320.704376 568.870544,320.860046 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M920.044678,522.443176 
	C920.724304,522.815674 920.732483,523.190552 920.246948,523.521667 
	C920.145264,523.590942 919.909851,523.588257 919.810852,523.516113 
	C919.337280,523.171021 919.354248,522.796448 920.044678,522.443176 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M424.281006,301.966187 
	C424.048431,301.977997 423.973083,301.491089 423.992065,301.245453 
	C424.011078,300.999817 424.487427,300.997986 424.724548,300.999084 
	C424.998718,301.164673 425.035828,301.329224 425.025452,301.742157 
	C424.977997,301.990570 424.513611,301.954407 424.281006,301.966187 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M696.628601,729.951233 
	C704.732971,727.598267 712.273621,724.924988 718.556396,719.649292 
	C719.876465,718.540833 721.980774,718.071655 722.005859,715.417969 
	C723.097046,715.376526 724.111877,716.216858 725.242798,715.184998 
	C732.293091,708.751465 739.261353,702.233154 744.539062,694.189514 
	C745.092468,693.346191 744.825928,691.964844 744.993042,690.438477 
	C747.539246,688.477173 748.864441,690.315552 750.618286,691.913330 
	C751.643494,692.671997 751.720459,693.479065 751.070435,694.657410 
	C750.497070,695.183838 750.366333,695.472778 750.431396,696.052002 
	C748.282898,703.834900 743.423035,709.980591 739.598633,716.672363 
	C738.947510,717.811646 738.188843,718.918945 737.349548,720.373779 
	C737.082825,720.899475 736.963196,721.103088 736.638306,721.594727 
	C730.427917,728.755371 725.192078,736.315613 718.129822,742.245422 
	C716.608093,743.523193 715.379822,745.158813 713.148743,746.051758 
	C711.810486,746.448792 710.753357,746.678711 710.212524,748.166992 
	C709.961487,748.655029 709.847351,748.845337 709.517822,749.294556 
	C708.782471,750.022217 708.179199,750.315796 707.146729,750.388916 
	C704.934265,749.264221 704.758362,750.739258 704.195435,752.238159 
	C699.064148,754.578857 695.282532,758.906433 689.378357,759.537964 
	C687.741211,758.264343 687.472351,756.683777 688.133545,755.310364 
	C690.691772,749.996216 688.941895,746.364868 684.144043,743.537354 
	C681.950378,742.244629 681.913330,740.366516 683.763794,738.594055 
	C684.945374,737.462219 686.545349,736.645874 686.440247,734.310852 
	C686.477173,733.744324 686.515381,733.519348 686.681030,732.976807 
	C688.307251,732.077209 689.619995,730.951843 691.773560,731.130005 
	C692.574829,731.063538 692.954712,730.921387 693.579956,730.580994 
	C694.633667,730.271851 695.428345,730.123840 696.628601,729.951233 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M709.043518,749.342773 
	C709.125061,749.050476 709.101990,748.624329 709.118164,748.411499 
	C709.393494,747.791687 709.716492,747.443970 710.423462,746.998047 
	C716.380920,746.005493 719.032654,740.985291 722.642578,737.655640 
	C727.668274,733.020203 732.276855,727.702026 735.755005,721.215210 
	C735.924194,720.911194 735.973145,721.006287 735.936707,720.967041 
	C738.066772,719.894653 738.032776,716.123291 741.671631,716.745300 
	C744.783936,717.597839 746.603027,718.664185 747.430420,722.058472 
	C748.118896,724.882751 750.482483,727.758118 754.619507,725.370483 
	C756.026245,724.558533 757.952026,724.632568 759.457703,725.474915 
	C761.445312,726.586731 761.353027,728.589172 760.609619,730.316162 
	C759.590698,732.683044 757.696045,731.655823 756.495789,730.623413 
	C753.715454,728.232239 751.025696,727.826538 747.964294,729.979919 
	C747.008240,730.652466 745.950439,730.895142 744.709473,730.194946 
	C740.077454,727.581848 737.181030,728.610352 736.542969,734.248596 
	C736.462036,734.963135 735.367432,735.450623 735.842651,736.530212 
	C738.805969,737.746826 742.974121,736.343079 744.947327,740.634216 
	C743.786438,742.179321 742.469177,743.052185 740.329590,742.234009 
	C734.964783,742.946960 733.066284,745.620117 736.123291,748.626404 
	C740.333557,752.766602 739.789856,756.145935 737.009399,760.628418 
	C737.550842,761.637817 737.791809,762.371704 737.590454,763.578613 
	C735.153748,766.315979 732.545654,766.439453 729.956177,764.603943 
	C727.702698,763.006409 727.872620,760.790100 728.832275,758.434814 
	C731.102966,752.861694 730.832336,752.498352 724.793213,752.432495 
	C723.973755,752.423523 723.154297,752.438293 721.974365,752.514465 
	C720.203247,752.944458 718.977539,753.815735 717.184570,754.205322 
	C714.533264,754.587097 714.970703,755.975281 715.464844,757.517456 
	C716.517517,760.802673 714.706482,762.211975 711.802490,762.358337 
	C706.908142,762.605103 701.989868,763.001404 696.782715,761.860229 
	C694.452148,758.651245 698.394531,758.295166 699.104065,756.099487 
	C699.710876,755.540344 700.100037,755.288086 700.825439,754.961060 
	C702.117004,754.562134 703.143799,754.355103 703.852539,753.069458 
	C704.120361,752.636475 704.218750,752.457153 704.424316,751.988647 
	C704.661499,751.297119 704.902832,750.975464 705.538513,750.644836 
	C707.068115,750.889343 708.265503,751.101685 709.043518,749.342773 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M760.564758,747.000000 
	C760.861328,750.141418 760.754089,753.208862 765.302795,753.126953 
	C766.499146,753.105469 766.890930,754.140869 766.983826,755.612732 
	C764.970703,756.149170 764.028687,757.290039 764.006348,759.637695 
	C764.128052,760.166687 764.256104,760.333313 764.192078,760.750000 
	C762.565613,761.325623 761.146240,761.807434 759.693970,761.947876 
	C755.183289,762.384094 752.586487,760.336121 752.051025,755.381409 
	C751.559509,754.609070 751.210510,754.157288 751.026978,753.288696 
	C751.650574,747.435669 752.302551,747.007263 760.564758,747.000000 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M801.397217,751.269409 
	C801.310425,745.462524 797.100403,746.406250 793.415894,746.000244 
	C792.118225,739.598389 797.419067,741.621582 800.720459,740.269653 
	C801.444458,740.000000 801.888916,740.000000 802.666687,740.000000 
	C807.818909,740.797546 808.680542,742.366638 807.975830,748.510864 
	C807.475830,752.869934 804.611267,752.079895 801.397217,751.269409 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M736.694702,764.416016 
	C737.000366,763.250549 737.000793,762.501160 737.001831,761.377075 
	C738.842407,760.832642 740.279419,759.810120 741.771545,758.844727 
	C743.195435,757.923462 744.703430,757.320496 745.926147,759.654175 
	C746.577942,760.685852 746.741394,761.456116 746.235840,762.657349 
	C746.000000,764.066711 746.000000,765.133362 746.000000,766.600037 
	C745.417419,767.491699 744.834778,767.983337 744.126099,768.737549 
	C742.039368,770.923523 739.780212,770.878296 737.607178,769.631836 
	C735.727844,768.553650 735.630066,766.731201 736.694702,764.416016 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M758.753723,733.740723 
	C759.481628,738.322876 755.910278,737.678406 753.257507,738.737671 
	C752.178650,738.974426 751.250122,739.188354 750.556213,738.877991 
	C748.541687,737.976990 744.477844,738.473572 745.539429,735.069763 
	C746.371277,732.402466 749.758911,731.033508 752.957581,731.597595 
	C754.859192,731.932800 756.660889,732.834473 758.753723,733.740723 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M764.008423,761.381531 
	C764.000000,761.000000 764.000000,760.500000 764.000000,760.250000 
	C767.542969,758.055603 771.119629,758.307068 774.689026,759.889343 
	C775.944458,760.445862 777.035461,761.255920 776.757568,762.856506 
	C776.482971,764.438965 775.231201,765.074768 773.843201,764.928528 
	C770.366821,764.562256 765.321350,768.866577 764.008423,761.381531 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M775.688538,675.777161 
	C780.088379,676.580933 784.160706,675.006470 788.625732,675.953247 
	C789.000000,676.000000 789.500000,676.000000 789.750000,676.000000 
	C791.569214,679.388367 790.157471,681.009705 786.382568,681.228516 
	C782.711792,679.835022 779.298950,679.931091 775.444336,680.001648 
	C773.959656,678.396606 771.852417,679.569885 770.618408,678.318665 
	C770.760315,676.377686 772.768188,677.402710 773.769287,676.247925 
	C774.380493,675.605286 774.839478,675.458313 775.688538,675.777161 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M755.521484,710.984314 
	C757.937622,711.015686 759.930054,710.984009 761.916992,711.074036 
	C763.324219,711.137817 763.996948,712.010925 763.855164,713.399292 
	C763.724792,714.674988 763.058777,715.909119 761.695862,715.546082 
	C756.507629,714.163879 752.007446,714.672729 748.691223,721.294922 
	C748.881836,713.544495 751.204529,710.444397 755.521484,710.984314 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M801.096924,751.377258 
	C803.067993,750.392944 806.520020,752.593628 806.876221,748.565735 
	C807.148743,745.483765 808.192627,741.689880 803.248474,740.267944 
	C804.181335,739.118896 805.259827,738.010742 806.571167,737.416504 
	C808.066284,736.739014 809.990540,736.418457 811.257996,737.628784 
	C812.772766,739.075073 811.032532,740.301086 810.304199,741.456787 
	C808.186523,744.816956 809.281555,748.533508 809.000000,752.547913 
	C806.419250,752.451172 803.465271,754.242004 801.096924,751.377258 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M741.979492,716.717529 
	C739.104919,716.803711 739.145203,720.609436 736.252808,720.921753 
	C740.567566,712.831116 745.234863,704.734497 750.201782,696.461548 
	C750.609131,696.563721 750.716919,696.842163 750.918396,697.558472 
	C751.956970,699.980896 750.666992,701.213318 749.246033,702.749756 
	C748.991394,703.012207 748.983337,703.483398 748.986206,703.718994 
	C748.585083,704.404419 748.015991,704.479065 747.264648,704.806396 
	C747.029785,705.738831 747.006409,706.485474 746.979126,707.605652 
	C746.806091,709.643188 745.405457,710.434692 744.223145,711.766052 
	C743.995483,711.989929 744.000000,711.999817 743.995972,711.999084 
	C743.763611,712.211548 743.550903,712.439209 743.182922,712.841187 
	C743.012207,713.001099 742.991455,713.492920 742.984131,713.738953 
	C743.039185,714.610474 742.865051,715.153320 742.198669,715.805664 
	C741.984314,716.012207 741.978455,716.482422 741.979492,716.717529 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M764.695312,678.764648 
	C766.868286,679.827576 767.075623,681.475159 766.999512,683.628906 
	C767.000000,684.000000 767.000000,684.500000 767.000000,684.750000 
	C766.613647,685.378296 766.153381,685.525513 765.309570,685.220764 
	C763.798889,684.981506 763.152527,685.809814 762.250854,686.818787 
	C760.101074,688.251892 758.916504,686.991455 757.495178,685.443481 
	C756.652954,683.833862 756.768372,682.528809 757.161621,680.853638 
	C758.468994,678.540039 760.741394,679.424866 762.704834,678.487061 
	C763.514832,678.339844 763.974792,678.389709 764.695312,678.764648 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M786.088379,681.367859 
	C788.473572,680.644226 789.821655,679.237976 789.993652,676.364014 
	C793.285522,677.498108 794.577759,680.911438 796.988342,683.630676 
	C793.230225,684.019653 789.472412,683.982422 786.088379,681.367859 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M777.147278,696.446899 
	C778.310669,694.629333 779.743835,693.554932 781.471130,694.461975 
	C783.185791,695.362488 785.099121,696.591675 784.702576,698.989014 
	C784.395020,700.847900 782.711853,700.924622 781.158264,700.995850 
	C778.210022,701.131104 776.764832,699.847717 777.147278,696.446899 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M757.612793,684.774841 
	C759.102539,685.634766 760.224609,686.283142 761.672119,686.966980 
	C762.555725,689.145325 763.273499,691.247620 759.622375,691.129272 
	C758.030273,691.077698 756.416748,691.688660 754.404053,692.001831 
	C752.771362,688.516785 754.460083,686.300598 757.612793,684.774841 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M757.982178,684.571777 
	C755.363037,686.258179 755.152100,689.050598 754.005981,691.631897 
	C753.868591,693.593933 753.142212,694.666931 751.115173,694.941895 
	C750.834778,694.210632 750.916016,693.469421 750.968994,692.355286 
	C753.600586,688.171997 754.587891,683.645325 756.559631,679.178162 
	C757.329651,678.649719 757.756348,678.760071 758.044556,679.629761 
	C757.997864,681.390625 757.990906,682.773926 757.982178,684.571777 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M765.276855,705.498413 
	C761.236511,705.844421 757.510498,708.131897 753.232605,706.290588 
	C756.713684,703.043640 760.357056,703.040222 764.599976,703.998657 
	C765.319092,704.257568 765.497131,704.593445 765.276855,705.498413 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M761.683838,694.032959 
	C763.845398,694.410828 764.373901,695.538513 763.276489,696.864197 
	C762.115234,698.266724 760.499634,699.328674 758.507935,698.572693 
	C757.425171,698.161682 756.392700,697.319946 756.443787,696.050049 
	C756.512939,694.328857 757.947632,694.115601 759.314880,694.028992 
	C759.971863,693.987305 760.633301,694.014893 761.683838,694.032959 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M739.959351,742.351807 
	C741.421143,741.671082 742.842224,741.342102 744.631653,741.006592 
	C744.982666,741.992126 745.070923,742.999817 744.926819,743.973206 
	C744.591614,746.237000 742.891235,747.107849 740.975769,746.705383 
	C738.513855,746.187988 739.470703,744.266235 739.959351,742.351807 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M749.341919,702.963989 
	C749.567627,701.545044 750.143921,700.077942 750.866150,698.303589 
	C753.622009,700.109375 753.516052,700.504517 749.341919,702.963989 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M768.637817,705.747681 
	C769.964722,706.809448 769.930420,707.766174 768.910706,708.302124 
	C767.110168,709.248413 765.956604,708.194580 765.056641,706.329590 
	C765.991272,705.178650 767.070312,704.927246 768.637817,705.747681 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M744.328003,711.955017 
	C744.048584,710.270691 745.144836,709.228943 746.687073,708.134399 
	C748.789062,710.811340 746.073853,710.983398 744.328003,711.955017 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M792.665405,706.064697 
	C793.313354,706.788025 793.626709,707.575989 793.970093,708.682007 
	C791.977844,708.895386 790.053650,708.733826 792.665405,706.064697 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M768.923706,705.648071 
	C767.933289,706.000000 766.866638,706.000000 765.399963,706.000000 
	C765.000000,705.555542 765.000000,705.111084 765.000000,704.333313 
	C766.369568,704.173401 768.030396,703.482422 768.923706,705.648071 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M742.312744,716.002197 
	C742.159546,715.457947 742.334839,714.903748 742.743469,714.167236 
	C743.481750,714.296204 744.406494,714.595886 744.415405,714.920715 
	C744.446533,716.050842 743.389404,715.860901 742.312744,716.002197 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M746.325867,762.941528 
	C746.000000,762.250000 746.000000,761.500000 746.000000,760.375000 
	C748.038940,760.549255 748.772888,761.393494 746.325867,762.941528 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M765.044678,685.288879 
	C765.444458,685.000000 765.888916,685.000000 766.666687,685.000000 
	C766.899719,685.638855 766.844116,686.404663 766.042847,686.448669 
	C765.741943,686.465210 765.408203,685.885559 765.044678,685.288879 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M750.788086,753.178955 
	C751.315430,753.470520 751.630859,753.940979 751.973145,754.705688 
	C751.118042,754.805786 749.993408,754.822021 750.788086,753.178955 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M747.343262,704.965576 
	C747.505737,704.670227 747.958374,704.348206 748.700073,703.990417 
	C748.537231,704.282715 748.085327,704.610779 747.343262,704.965576 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M743.221802,712.883667 
	C743.119873,712.687439 743.305115,712.429504 743.779907,712.112793 
	C743.879883,712.308960 743.693054,712.564941 743.221802,712.883667 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M818.117737,640.965088 
	C814.657654,634.442932 809.108032,629.164612 810.816223,620.865845 
	C811.559082,617.256653 812.871277,615.956482 816.174072,616.024780 
	C819.807129,616.099976 818.147705,618.533447 818.535583,620.498901 
	C819.397522,624.867371 819.895142,629.673340 823.234741,633.030273 
	C825.418762,635.225708 825.492432,637.473083 824.371338,639.745239 
	C823.141479,642.237732 820.864990,642.529480 818.117737,640.965088 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M838.003540,591.406128 
	C839.121948,590.762329 840.241394,590.522217 841.679199,590.139771 
	C843.254456,588.886658 844.691772,587.956665 845.776855,586.224976 
	C846.557861,585.156494 847.413757,585.011902 848.664551,585.003418 
	C851.172180,586.955017 855.772583,585.677795 855.989746,590.620239 
	C854.729919,592.309570 853.288757,592.316467 851.374390,591.280762 
	C848.918945,591.081482 847.964233,592.656677 846.825684,594.059570 
	C845.183289,596.083313 842.883606,596.215332 840.549316,595.880188 
	C838.182251,595.540405 837.987671,593.710144 838.003540,591.406128 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M842.780518,614.668701 
	C843.270325,618.228882 841.140198,619.430054 838.541443,619.847290 
	C836.177734,620.226807 833.755371,619.929077 833.031555,617.037170 
	C832.455688,614.736694 830.752869,615.091553 829.148987,614.992432 
	C827.542725,614.893005 825.225098,615.095581 825.561707,612.804932 
	C825.910339,610.432251 828.327698,610.835693 830.042908,611.224426 
	C833.013794,611.897644 835.088501,615.001831 838.776611,614.239136 
	C840.300171,612.908997 841.446777,613.460083 842.780518,614.668701 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M857.251831,603.191650 
	C854.928284,603.000000 852.854126,603.000000 850.586548,603.000000 
	C850.906799,599.826294 854.192688,599.853638 854.939941,597.338257 
	C855.396362,596.650452 855.857117,596.505310 856.689941,596.781006 
	C858.818909,597.032715 860.658569,596.917969 862.457153,597.138733 
	C865.057495,597.457642 865.692566,599.929321 865.395203,601.619446 
	C864.944031,604.182556 862.900635,602.601562 861.435547,602.210510 
	C860.328003,601.914856 859.135681,600.325134 858.059692,602.676147 
	C857.833740,603.127747 857.667480,603.255554 857.251831,603.191650 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M833.413269,606.059814 
	C833.000000,605.555542 833.000000,605.111084 833.000000,604.333313 
	C835.242004,602.624451 837.738525,603.070496 840.582642,602.998535 
	C842.301147,601.883850 843.710388,601.624207 845.628601,602.722107 
	C847.650513,603.214844 849.085876,603.862427 848.659180,605.798889 
	C848.343079,607.233215 846.826416,607.028320 845.698914,606.976013 
	C841.735107,606.792236 837.691467,607.662292 833.413269,606.059814 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M843.417297,615.001526 
	C841.894409,614.694580 840.791199,614.386719 839.344482,614.041870 
	C838.254639,612.879944 836.366028,612.195679 837.518982,610.331055 
	C838.330933,609.018005 839.691589,608.974792 841.019165,609.019836 
	C842.159790,609.058472 843.427979,608.932312 844.406250,609.384277 
	C846.209290,610.217407 849.164429,610.403320 848.740784,613.241028 
	C848.311340,616.117615 845.567322,614.595947 843.417297,615.001526 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M857.238098,609.653198 
	C857.907227,611.868408 858.796997,614.004211 855.929932,614.688171 
	C853.930298,615.164978 851.896362,614.042480 851.264709,611.966675 
	C850.617065,609.838440 850.767334,607.600220 853.719299,607.081604 
	C854.516235,606.941650 855.351990,607.022400 856.583740,607.002136 
	C857.661865,607.666870 857.750732,608.450134 857.238098,609.653198 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M856.999084,596.620850 
	C856.553650,596.998352 856.109802,596.999146 855.332947,597.000000 
	C851.548950,596.859192 849.015869,596.007019 850.956665,591.362915 
	C852.390015,591.000793 853.777527,590.999207 855.581299,590.996338 
	C859.577515,592.124573 855.948853,594.631531 856.999084,596.620850 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M846.041748,602.634888 
	C844.609985,602.997559 843.222473,602.997559 841.418640,602.997559 
	C840.968933,602.836853 840.903198,602.675415 840.906982,602.515625 
	C840.949890,600.725586 837.105408,600.962463 838.579407,598.976990 
	C840.093628,596.937134 842.864197,597.866760 845.006104,598.300232 
	C847.431946,598.791199 846.540833,600.709839 846.041748,602.634888 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M857.378845,609.990479 
	C857.001160,609.248779 857.000000,608.500000 856.998169,607.376831 
	C856.998657,605.935120 856.999756,604.867737 857.001648,603.400208 
	C857.002441,603.000000 857.501221,603.000000 857.750610,603.000000 
	C860.061890,603.480347 862.674927,603.835266 862.711792,606.376831 
	C862.753662,609.259155 859.996948,609.552368 857.378845,609.990479 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M872.933105,616.671021 
	C872.556091,617.000610 872.112122,617.001221 871.335327,617.002075 
	C870.236023,616.814697 869.003906,616.452698 869.764587,615.624329 
	C870.746216,614.555237 872.124146,614.796936 872.933105,616.671021 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M826.316650,696.171875 
	C825.527771,695.686401 825.057983,695.370422 824.294067,695.027222 
	C823.764832,692.669189 824.232544,690.669006 826.782715,689.271851 
	C829.072388,688.794556 830.569336,689.716614 831.840454,691.695923 
	C832.184631,693.699829 832.853088,695.204163 834.086243,696.366699 
	C837.189941,699.292480 836.497498,702.402283 834.330322,705.803711 
	C833.012390,706.621948 832.214905,706.204285 831.231567,705.268066 
	C829.212463,704.174927 828.892029,702.618103 829.006226,700.889038 
	C829.139343,698.874390 828.235352,697.435059 826.316650,696.171875 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M852.059448,704.431885 
	C853.835022,703.917908 856.323608,701.609863 855.239380,705.854858 
	C854.998413,706.798279 855.006287,707.821594 855.008728,708.807739 
	C855.015198,711.390259 854.228943,713.749451 851.363586,713.724182 
	C848.618530,713.699890 846.279053,712.382751 846.028809,709.006897 
	C845.763428,705.426880 848.052002,704.956482 850.765381,704.863220 
	C851.084351,704.852234 851.397949,704.685181 852.059448,704.431885 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M850.469421,665.027710 
	C851.547424,665.006592 852.217957,664.948730 852.876648,665.012512 
	C854.554138,665.174927 857.146912,664.423157 856.737976,667.354614 
	C856.398621,669.787781 854.048828,670.316772 852.044495,669.734558 
	C850.215454,669.203247 847.379211,668.843567 848.725220,665.582825 
	C848.852966,665.273499 849.598083,665.219055 850.469421,665.027710 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M833.598572,706.776367 
	C835.181274,707.691040 836.455444,708.528870 835.227112,709.992737 
	C833.899963,711.574219 832.611816,710.238770 831.197388,709.256104 
	C830.008362,706.636414 831.319092,706.339905 833.598572,706.776367 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M834.000000,706.750000 
	C832.865723,707.057495 831.619446,706.871826 831.063599,708.661621 
	C827.595642,708.130432 830.207520,706.760925 830.857300,705.283142 
	C831.804810,705.247498 832.609619,705.494995 833.707214,705.871216 
	C834.000000,706.000000 834.000000,706.500000 834.000000,706.750000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M870.354492,724.989685 
	C870.630066,723.881714 871.260132,722.765930 871.945129,721.325073 
	C874.300964,722.798767 874.049744,723.573547 870.354492,724.989685 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M879.782471,726.753540 
	C879.165527,728.125854 877.943237,728.580994 876.330078,728.965454 
	C876.349609,726.969666 877.095093,725.505432 879.782471,726.753540 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M865.049927,723.315186 
	C865.892944,723.095276 866.661194,723.363953 866.950378,724.682617 
	C866.106262,724.904114 865.338562,724.634949 865.049927,723.315186 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M736.628662,764.007202 
	C736.014038,769.376953 739.521362,769.248291 743.563232,769.000000 
	C745.844788,771.529053 747.521484,774.614746 744.895264,777.009888 
	C742.605347,779.098633 739.279602,778.624329 736.785461,776.289978 
	C735.270142,774.871765 733.461060,775.021545 731.643677,774.997986 
	C725.368286,774.916809 719.059387,775.460999 712.428345,774.002258 
	C710.611084,774.000000 709.222229,774.000000 707.416626,774.000000 
	C702.805237,776.561279 701.399048,776.151611 700.877014,772.226318 
	C700.718140,771.031494 701.731995,769.068970 699.920166,768.880493 
	C697.784790,768.658203 695.205261,767.611084 693.406433,769.643555 
	C691.156982,772.184998 688.117615,773.550781 685.244751,775.758911 
	C684.195251,776.247620 683.390442,776.495239 682.292847,776.871460 
	C674.236206,783.367188 664.325928,784.945374 655.398499,788.670349 
	C651.553345,790.274719 646.863037,788.332947 642.317505,789.752197 
	C641.880737,790.310669 641.688416,790.566406 641.210327,790.883606 
	C635.949829,793.840271 630.549316,793.114624 625.527893,791.599182 
	C617.961182,789.315491 611.060669,792.601440 603.866638,793.239563 
	C601.995850,793.405518 600.677246,795.288513 601.257568,797.398865 
	C601.886169,799.685059 603.819397,798.967896 605.426819,798.686218 
	C606.409973,798.513855 607.376343,798.223206 608.688965,798.775146 
	C613.577515,799.631226 618.388550,797.629639 622.897217,800.610413 
	C620.597290,801.897339 616.336609,798.719116 616.003479,804.590210 
	C614.611084,805.000000 613.222229,805.000000 611.416626,805.000000 
	C603.618103,801.874756 602.819641,802.167725 601.264282,808.737915 
	C597.512939,809.148987 595.790710,806.219788 593.158569,804.298340 
	C594.528015,801.958618 595.085022,799.867126 593.083740,797.346985 
	C591.268311,799.067078 591.927307,801.804626 590.232849,803.752930 
	C584.247986,803.655823 586.879211,798.488831 585.293091,795.309204 
	C581.471558,800.053406 583.824951,803.705139 586.673645,807.060974 
	C588.150146,808.800232 589.255310,810.425598 589.012817,812.781616 
	C588.878296,814.088196 589.154175,815.594971 587.758423,816.320435 
	C586.052185,817.207214 585.498230,815.649597 584.751709,814.613403 
	C582.922729,812.074524 580.210205,813.306274 577.430115,813.001709 
	C576.514709,812.893005 576.094788,812.660889 575.580139,811.976501 
	C575.195923,810.600525 575.570679,809.732788 576.492859,808.708740 
	C577.438049,807.096191 581.102539,808.820618 579.548584,805.367065 
	C578.361267,804.931519 577.580566,804.463196 576.683044,803.568787 
	C574.887390,799.557617 573.513916,801.770569 571.813721,803.644714 
	C570.274231,804.339417 569.027588,804.330322 567.408569,803.791870 
	C565.826050,801.709778 562.834656,801.412109 562.601074,798.308838 
	C563.221985,797.332703 563.899475,797.008423 564.996948,796.718018 
	C569.098694,797.067444 572.700195,796.430359 576.215820,795.131348 
	C573.212280,795.064331 570.555237,798.125671 567.028381,795.970337 
	C565.259277,794.827087 564.158630,793.508728 562.903442,791.822998 
	C561.060852,789.206421 556.919617,788.247742 559.048523,783.898438 
	C562.907043,784.101501 565.597656,786.036377 567.716003,788.663696 
	C569.258850,790.577087 570.791626,790.743774 572.926392,789.892700 
	C576.749084,788.368835 580.882080,787.734680 584.266907,784.999023 
	C586.545776,783.157043 589.607666,783.401550 592.442261,783.392883 
	C603.230713,783.360046 614.015198,783.617371 624.808594,783.451233 
	C637.643738,783.253601 650.234131,782.041138 662.155640,776.913147 
	C664.104187,776.075012 665.963745,774.938049 667.868042,773.258301 
	C670.685486,771.167786 672.940063,768.837036 676.506104,768.096436 
	C677.644836,767.895874 678.464722,767.779358 679.312256,766.946045 
	C679.693665,766.596252 679.852966,766.460632 680.270752,766.141479 
	C681.291870,765.551575 681.989258,765.046448 682.977478,764.367676 
	C683.906616,763.914734 684.573303,763.737183 685.605042,763.565979 
	C689.291626,763.103882 691.742798,759.898010 695.688965,760.754395 
	C701.114624,760.990540 706.261597,760.986877 711.408630,760.999512 
	C714.661865,761.007507 716.089111,760.052856 713.504333,757.022583 
	C712.493958,755.838074 711.498779,754.677063 712.671936,753.090942 
	C713.778381,751.595032 715.077515,752.724854 716.650940,752.959839 
	C719.131287,754.685364 721.411377,755.862427 724.589417,755.205811 
	C723.274231,753.669861 720.282776,754.279968 720.784424,751.267517 
	C723.941772,751.171448 726.911255,750.623047 729.830933,751.441589 
	C732.966187,752.320557 734.021729,753.872253 731.760559,756.713501 
	C730.356934,758.477051 728.196777,760.241150 729.618835,762.727478 
	C731.147827,765.400818 733.844299,764.546997 736.628662,764.007202 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M823.224976,761.183350 
	C830.925537,764.961975 831.065308,765.253601 828.863037,773.595032 
	C828.569092,768.841919 826.027588,766.083496 822.052673,764.163635 
	C820.465759,763.397156 821.469727,761.927307 823.224976,761.183350 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M797.915771,637.011414 
	C797.596558,638.553894 796.948059,639.039795 795.881042,638.079590 
	C795.170471,637.440125 795.157349,636.599854 795.867493,635.952637 
	C796.931885,634.982422 797.579834,635.481323 797.915771,637.011414 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M507.203918,182.228638 
	C507.679871,180.575760 509.191772,179.534607 508.995239,177.350311 
	C510.171021,177.161041 511.342041,177.322083 512.756531,177.742493 
	C510.575775,178.782913 510.542236,182.565979 507.203918,182.228638 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M529.435303,178.998856 
	C530.948914,175.763763 533.324951,176.468231 535.909302,178.631012 
	C533.957703,178.999451 531.915344,178.999496 529.435303,178.998856 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M495.043884,182.290955 
	C495.443939,182.002350 495.889069,182.002258 496.668121,182.003815 
	C496.899750,182.643173 496.842072,183.408051 496.041718,183.449738 
	C495.740051,183.465424 495.407471,182.887039 495.043884,182.290955 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M344.820190,255.786285 
	C344.528687,256.313599 344.058350,256.629425 343.293396,256.970764 
	C343.193573,256.114929 343.181213,254.994110 344.820190,255.786285 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M539.325073,877.857910 
	C536.589905,877.218689 532.327087,877.044739 532.855225,874.408020 
	C533.485596,871.261414 537.626160,873.706360 540.559875,872.996277 
	C542.235291,873.317322 544.543762,872.247498 544.412842,874.344849 
	C544.259583,876.800903 541.426208,876.803650 539.325073,877.857910 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M369.349060,873.279053 
	C368.555298,872.999756 368.110596,872.999512 367.332336,872.998413 
	C366.999176,871.609375 366.999603,870.221191 367.000000,868.416504 
	C368.661285,868.002380 369.554993,869.326111 370.772797,870.123901 
	C372.756439,871.423279 374.886993,871.596375 376.546234,869.646912 
	C379.015259,866.745850 382.137024,865.875854 385.780762,865.956909 
	C387.274811,865.990112 389.387573,866.666931 389.792938,864.276550 
	C390.164001,862.088379 388.301208,861.648621 386.371704,861.046814 
	C386.307037,859.894226 386.615295,858.790833 386.961609,857.343750 
	C389.139587,856.791809 390.916748,857.217285 391.782166,859.505798 
	C392.271423,860.799561 393.180603,861.692322 394.601410,862.035461 
	C396.718781,862.546875 398.101471,863.630432 398.099457,866.111816 
	C398.098755,866.985046 398.669739,868.048828 399.313751,868.690186 
	C400.414429,869.786316 401.266052,871.931030 403.087677,871.311768 
	C404.776489,870.737671 403.760559,868.700867 403.987366,867.321533 
	C404.066589,866.839722 404.000000,866.334045 404.000000,865.419617 
	C407.261108,864.966614 408.850311,866.575012 409.028168,869.838928 
	C409.126373,871.640686 409.700073,873.414612 409.824982,875.217285 
	C410.078217,878.872192 409.441620,881.896973 404.751251,882.036621 
	C401.266083,882.140259 399.422272,883.946106 400.368927,887.562134 
	C401.063019,890.213501 399.651947,891.209290 397.579254,890.915710 
	C386.225037,889.307678 374.830261,888.679993 363.374512,888.943359 
	C362.040344,888.974121 361.184418,888.317932 361.080780,886.878235 
	C360.991547,885.638306 361.249603,884.611389 362.400696,883.887085 
	C363.909821,882.937378 365.567902,883.024719 367.229340,882.997375 
	C374.684875,882.874512 382.172791,883.524536 389.571625,882.015015 
	C390.799164,881.764587 392.590546,882.324036 392.684082,880.429260 
	C392.779602,878.494690 391.161957,877.995972 389.560303,878.054016 
	C387.918457,878.113403 386.288696,878.452881 384.647949,878.610474 
	C382.109436,878.854187 379.450867,880.049316 377.351624,877.084106 
	C382.090363,874.668152 386.965393,874.207153 392.075256,873.122314 
	C393.550720,872.863525 394.735809,873.554993 395.935150,872.719910 
	C395.404663,871.066040 394.083160,871.020386 392.826477,871.389282 
	C390.157928,872.172791 387.667938,872.266418 385.135773,870.799133 
	C382.452942,869.244568 382.388458,872.219238 380.934143,873.537048 
	C379.911163,874.156189 379.139618,874.353821 377.901001,874.430908 
	C374.823883,874.663818 372.144745,875.115723 369.349060,873.279053 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M408.749817,858.332275 
	C412.428131,858.062317 415.855621,858.133179 419.584534,857.012329 
	C419.970276,858.530823 418.365540,860.283264 420.815735,861.747070 
	C420.881836,862.312439 420.689850,862.567871 420.213165,862.885620 
	C419.475220,863.542969 418.928558,864.062500 418.195801,864.795898 
	C414.448792,865.555237 412.208374,862.873169 409.212494,861.266602 
	C408.401672,860.313538 408.223633,859.539001 408.749817,858.332275 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M409.002075,857.611206 
	C409.000793,858.747620 409.000366,859.497620 409.000000,860.622559 
	C408.555542,860.997742 408.111115,860.997925 407.333313,860.997803 
	C406.505768,859.797668 406.011505,858.597839 405.258026,857.198975 
	C404.999298,856.250000 404.999847,855.500000 405.000793,854.375000 
	C407.670074,853.545227 406.812103,851.417725 406.999878,849.393066 
	C407.750000,848.999634 408.500000,848.999329 409.625000,848.998291 
	C410.663757,849.665161 410.757599,850.449585 410.238037,851.656860 
	C409.668915,853.743225 409.335938,855.484009 409.002075,857.611206 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M369.000000,873.375000 
	C371.700012,872.999939 374.400024,872.999817 377.550018,872.999878 
	C379.033447,874.304199 378.557251,875.406799 377.270447,876.719849 
	C374.511627,877.336365 372.187714,876.461304 369.402161,876.011780 
	C369.000000,875.250000 369.000000,874.500000 369.000000,873.375000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M431.571533,862.000000 
	C432.000000,862.750000 432.000000,863.500000 432.000000,864.625000 
	C430.383545,865.974854 428.602112,866.088684 426.390930,866.000000 
	C426.000000,864.933289 426.000000,863.866638 426.000000,862.399963 
	C427.714355,862.000000 429.428711,862.000000 431.571533,862.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M425.111694,867.743286 
	C425.000214,868.000000 424.998779,868.498779 424.998169,868.748169 
	C423.931580,868.998413 422.865570,868.999207 421.399780,869.000000 
	C421.424622,866.577393 423.324371,866.263489 425.634460,866.006836 
	C425.741058,866.495483 425.482117,866.991028 425.111694,867.743286 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M418.312958,864.948608 
	C418.095764,864.105042 418.365997,863.336975 419.685791,863.051392 
	C419.904053,863.895752 419.633270,864.663330 418.312958,864.948608 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M442.044556,892.364624 
	C445.124878,891.281128 446.356689,895.167419 449.630951,894.996338 
	C446.423553,899.218384 444.212860,898.581848 442.044556,892.364624 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M495.049133,881.315186 
	C495.892670,881.095337 496.661499,881.364380 496.950378,882.684692 
	C496.106964,882.903992 495.338470,882.634583 495.049133,881.315186 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M705.735718,873.265930 
	C711.403076,873.721191 716.775818,870.437012 722.594238,872.943848 
	C722.968750,874.179138 723.019165,875.534485 721.717712,876.009338 
	C716.559570,877.891785 711.242126,877.105957 706.005920,876.518799 
	C704.754639,876.378418 704.936035,874.684998 705.735718,873.265930 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M396.673279,835.059082 
	C397.247253,835.804810 397.494995,836.609558 397.871399,837.707153 
	C397.139282,838.998108 396.279938,838.829956 395.609802,837.853699 
	C394.837372,836.728516 394.892883,835.703613 396.673279,835.059082 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M345.346985,577.915466 
	C345.184601,577.209290 345.128571,576.853943 344.968903,576.247620 
	C339.859711,566.146973 336.142151,555.817200 332.784241,545.295959 
	C330.225464,537.278748 327.585938,529.290955 325.376129,521.170715 
	C323.992859,516.087585 323.074982,510.895660 322.135376,505.329254 
	C322.146515,503.140991 322.296783,501.322235 321.334869,499.279602 
	C318.122162,496.289032 320.610352,492.341644 318.712219,489.041260 
	C318.394409,488.459991 318.291565,488.213318 318.116119,487.569946 
	C318.008667,486.905029 317.973846,486.636780 317.844055,485.971497 
	C317.461731,484.496124 317.433655,483.400024 317.529297,481.878021 
	C317.315063,477.546936 318.094818,473.586853 316.831055,469.309448 
	C314.819000,461.638641 316.396759,454.355682 316.634033,446.652252 
	C317.970459,441.850037 317.610535,437.327606 317.845673,432.461456 
	C317.696472,431.518341 317.675720,430.977997 317.763062,430.042236 
	C317.925110,429.137848 318.062134,428.634705 318.331970,427.771179 
	C318.538727,426.641510 318.719086,425.895752 318.983704,424.772034 
	C319.829926,422.798889 319.462982,420.646484 321.818420,419.614502 
	C324.624969,420.560547 324.260620,422.657013 324.121124,424.588745 
	C323.991516,426.383362 323.723938,428.183685 323.295349,429.925934 
	C322.401794,433.558044 323.284851,435.717468 327.850647,435.495117 
	C330.708405,435.290802 333.150146,435.232208 335.670898,436.831970 
	C336.938354,441.139008 336.310120,442.069366 332.640900,441.779510 
	C330.660980,441.623108 328.742798,440.819550 326.714172,441.131317 
	C325.720093,441.284119 324.634735,441.523071 324.331360,442.626129 
	C323.967712,443.948517 325.137878,444.266296 326.022766,444.665253 
	C329.091125,446.048676 330.065277,447.686737 326.761597,449.966431 
	C326.491394,450.152832 325.428772,450.413269 326.027802,450.595734 
	C329.851074,451.760132 327.507996,456.357483 330.911560,458.347839 
	C334.664612,460.542603 334.393646,470.829681 331.407532,473.934174 
	C329.803284,475.601990 331.987457,482.658936 334.487488,483.361694 
	C337.522888,484.214966 340.527252,485.265900 343.706879,485.542603 
	C345.554840,485.703430 347.282990,486.187347 348.854767,487.256622 
	C349.940552,487.995300 351.435272,488.401520 351.351562,490.035461 
	C351.256439,491.891785 349.647400,492.430664 348.235809,492.814453 
	C344.378967,493.863129 340.735657,495.346863 337.230804,497.396667 
	C342.059326,501.676758 342.018402,501.953827 336.120148,505.551605 
	C337.542236,509.301575 346.737213,510.325836 340.268280,516.917603 
	C346.062897,520.805603 346.005920,521.964966 339.884979,525.381165 
	C338.194550,526.324646 336.373718,527.134399 334.795319,529.070984 
	C338.893250,530.940674 342.878143,530.938354 346.713867,531.547241 
	C348.017151,531.754150 349.388031,531.540344 350.657471,532.029846 
	C351.630768,532.405151 352.473022,532.980408 352.356293,534.184387 
	C352.266846,535.106873 351.558136,535.574585 350.766022,535.929626 
	C348.826752,536.798889 346.412689,536.203918 344.686493,537.868164 
	C345.001068,539.462463 346.435120,539.148438 347.413055,539.477661 
	C349.333252,540.124023 351.569366,540.591736 351.441986,543.150146 
	C351.317749,545.645264 349.057220,546.045959 347.118561,546.576111 
	C346.958038,546.619995 346.792877,546.648010 346.634125,546.697144 
	C341.641632,548.241760 341.270660,549.293213 344.248474,553.564148 
	C344.626801,554.106750 345.422150,554.595459 345.365234,555.172913 
	C344.719696,561.720764 350.251160,563.527344 354.061401,566.658569 
	C357.746307,569.686829 357.814056,571.333191 353.737762,573.687134 
	C350.732544,575.422546 350.920135,577.422302 352.197662,579.917786 
	C353.134430,581.747620 355.005463,582.751892 356.244476,584.282104 
	C358.785736,587.420776 362.736298,589.486877 360.959198,595.208008 
	C359.479462,599.971558 366.460693,606.987000 371.779114,607.568115 
	C373.556671,607.762390 375.738739,607.531860 376.342865,609.770996 
	C376.860931,611.691223 375.273529,612.825745 373.891815,613.734985 
	C371.799866,615.111633 371.297119,616.587708 372.993958,618.630432 
	C375.028687,621.080017 375.875702,624.258667 378.094757,627.004639 
	C379.229736,628.567993 379.750824,630.084717 380.797485,631.688110 
	C381.681732,633.367371 382.792175,634.393066 384.283813,635.524658 
	C384.658295,635.942627 384.793854,636.123413 385.097229,636.599304 
	C388.718506,642.506958 393.493683,647.187012 396.843781,653.300903 
	C403.918213,661.279602 410.606384,669.132690 417.391632,676.901062 
	C418.809845,678.524780 420.425629,680.116516 419.373169,682.924866 
	C417.478882,684.131897 416.362122,683.051636 414.940125,681.861511 
	C407.795166,673.439148 400.476898,665.640503 393.519257,657.067017 
	C392.972961,656.449646 392.680573,656.079163 392.190735,655.410339 
	C384.058441,644.516357 376.141327,633.906860 369.330353,622.529358 
	C363.812164,613.311401 358.562653,603.942139 353.356873,594.140015 
	C353.165192,593.839233 353.059418,593.883911 353.116119,593.892944 
	C350.898468,588.542603 347.745941,583.616699 345.346985,577.915466 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M318.055725,445.651855 
	C318.745270,453.394135 316.858185,460.772705 318.114990,468.560242 
	C318.182709,473.116394 317.458038,477.284698 318.777252,481.686218 
	C319.135376,483.067078 319.239105,484.151489 319.304016,485.643311 
	C318.853088,486.124084 318.703644,486.373779 319.038605,486.850708 
	C319.297668,486.926788 319.440277,487.201935 319.377136,487.343445 
	C320.489197,491.025452 319.821259,494.757538 320.217194,498.677124 
	C316.176208,498.040802 313.652985,493.102295 315.735809,489.536163 
	C316.906158,487.532379 316.778107,486.181885 315.737518,484.589935 
	C312.173035,479.136658 312.131378,473.423920 314.233246,467.473450 
	C315.745422,463.192444 312.914948,459.681396 308.565857,460.725281 
	C305.251740,461.520782 302.359222,459.945831 299.240448,459.821411 
	C298.377441,459.786957 297.518524,459.139465 297.578400,458.262970 
	C297.670746,456.910645 298.922028,457.031952 299.887573,457.021912 
	C303.381134,456.985626 306.875244,457.004761 310.368988,456.979828 
	C311.957947,456.968506 313.490143,456.648468 313.754211,454.771545 
	C314.018463,452.893341 312.499969,452.229401 311.115448,451.618225 
	C308.565765,450.492706 305.856415,451.205200 303.225891,450.994385 
	C300.226044,450.753998 296.299347,449.899475 296.584869,446.860046 
	C296.937988,443.101257 301.089386,443.898956 304.032623,444.217896 
	C305.501709,444.377075 306.929382,444.913696 308.377258,445.273682 
	C311.851593,446.137543 313.195984,444.447388 312.543854,441.300507 
	C311.857025,437.986053 312.561279,436.070190 316.623932,435.994446 
	C319.384338,438.866302 316.622681,442.299896 318.055725,445.651855 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M320.092590,424.706909 
	C320.081238,425.775696 320.034119,426.514374 319.892273,427.611450 
	C319.542938,428.207428 319.287781,428.444427 319.188660,428.888794 
	C319.322021,429.532745 319.298706,429.968658 319.200134,430.723877 
	C318.393402,431.430603 318.119385,432.238495 317.268616,432.883270 
	C314.507050,428.843842 310.345062,428.867279 306.233704,428.515533 
	C304.926422,428.403717 303.823547,428.430328 303.130615,429.440186 
	C300.143707,433.793365 295.683807,433.182434 291.410126,432.924561 
	C289.281006,432.796112 287.231262,432.376190 285.051666,432.877777 
	C283.732117,433.181427 281.879791,433.498840 281.247192,431.552521 
	C280.841125,430.303131 280.613190,428.823883 281.599731,427.753174 
	C282.777527,426.474884 284.055573,427.752350 285.290619,427.941986 
	C295.158356,429.456940 304.132538,426.724365 312.650940,422.049438 
	C314.437195,421.069153 314.698120,418.500885 313.369019,417.841858 
	C305.835083,414.106384 313.265564,410.360168 314.335480,409.957825 
	C317.141418,408.902557 318.935425,406.548065 321.804199,405.789429 
	C322.722961,406.588379 323.262512,407.288177 323.639282,408.487244 
	C323.801880,409.230713 323.824890,409.659271 323.731506,410.394348 
	C323.430847,411.442719 323.011444,412.135010 323.030273,413.249512 
	C323.117096,414.381531 323.108887,415.210968 322.833801,416.344116 
	C322.623901,416.847260 322.516296,417.039215 322.199127,417.486633 
	C319.755798,419.490601 319.868683,421.922729 320.092590,424.706909 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M338.210938,366.153442 
	C339.048553,369.562988 335.540955,371.263336 335.066986,374.663818 
	C332.226013,375.924744 328.752228,376.381897 331.674805,380.926605 
	C332.042786,382.758575 331.505005,384.110840 330.867371,385.801819 
	C329.242432,387.696259 328.946655,390.182373 326.388123,391.094116 
	C322.075165,391.447845 320.710785,387.730194 318.925873,385.650574 
	C317.458618,383.941101 320.783569,382.472321 322.436127,382.227539 
	C325.423828,381.785034 326.515289,381.215454 324.284546,378.418243 
	C322.685364,376.412964 322.769440,374.544495 325.274902,373.089172 
	C326.247589,372.524170 327.420013,371.632355 327.701172,370.658417 
	C329.300964,365.116577 333.592224,365.862457 338.210938,366.153442 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M353.111633,594.224548 
	C357.736023,598.145752 359.578339,604.136597 362.659302,609.353821 
	C371.862274,624.937988 381.959259,639.907776 392.917999,654.666992 
	C392.603668,655.343384 392.197479,655.546204 391.379211,655.588257 
	C389.234283,654.882141 388.118500,653.393555 386.654053,651.806519 
	C385.797241,650.657288 385.230499,649.712158 384.294434,648.626709 
	C382.747864,646.314697 381.157867,644.457703 379.365387,642.325562 
	C374.597137,635.143799 370.249023,628.097778 365.338074,620.593994 
	C365.311859,615.117920 361.157440,612.260010 359.675354,608.170837 
	C357.959137,603.435730 354.127258,599.692017 353.111633,594.224548 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M321.887634,505.166077 
	C324.229401,509.334351 324.711761,514.156250 325.891418,518.786804 
	C329.497070,532.940247 333.991699,546.805115 339.158081,560.459290 
	C341.030640,565.408142 343.851776,569.984863 344.908661,575.616455 
	C340.310699,573.481689 339.662201,568.479370 338.134827,564.369019 
	C332.485321,549.165466 327.306641,533.769714 323.621277,517.953979 
	C322.668518,513.865112 321.293640,509.734070 321.887634,505.166077 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M220.342346,499.235840 
	C209.818115,496.321808 199.600266,493.924652 188.466705,494.997253 
	C187.998581,493.931610 187.998993,492.866852 187.998779,491.402893 
	C193.686996,491.002625 199.375824,491.001587 205.532623,491.000854 
	C206.425262,491.510590 206.935791,491.700531 207.785919,491.211853 
	C208.858246,489.917206 209.895660,490.171326 210.476593,491.070709 
	C213.343063,495.508209 217.558441,495.674744 222.120834,495.020447 
	C223.098648,494.880219 224.113739,495.000000 225.555832,495.000000 
	C227.923111,496.542633 227.918198,497.752289 225.195496,498.763184 
	C224.555618,499.000031 224.111252,499.000061 223.333588,499.000366 
	C222.314346,499.579712 221.541672,499.732056 220.342346,499.235840 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M244.272812,568.216675 
	C244.000092,567.250000 244.000214,566.500000 244.000778,565.375000 
	C248.412613,564.561951 253.426849,566.647217 256.723633,561.574219 
	C257.475250,560.417603 259.096619,561.259521 260.368286,561.860107 
	C263.750916,563.457520 266.976227,565.552002 271.054749,565.065979 
	C272.327942,564.914246 274.619141,564.332275 274.568054,566.552307 
	C274.525116,568.417908 272.778473,569.980652 270.832550,569.859985 
	C267.891785,569.677551 264.680389,569.513855 262.138763,568.237488 
	C258.113953,566.216370 254.805634,566.742737 251.530182,569.295288 
	C248.843658,571.388855 246.489655,571.848267 244.272812,568.216675 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M288.502441,449.028992 
	C290.664154,449.258881 291.274536,450.439972 290.769440,451.877197 
	C290.022858,454.001617 288.531281,455.708771 286.154663,455.911133 
	C282.300842,456.239288 278.708710,456.928253 275.550201,459.479736 
	C274.297943,460.491302 272.332184,460.363312 270.812195,459.454773 
	C269.839050,458.873138 269.978546,457.698730 270.086151,456.720062 
	C270.314606,454.641876 271.759583,454.122437 273.602661,453.954590 
	C274.545044,453.868713 275.959534,453.623749 276.289154,452.994293 
	C278.994934,447.826965 283.734650,449.063721 288.502441,449.028992 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M224.675003,503.247986 
	C228.738831,501.013977 232.819656,501.739044 236.566193,502.332214 
	C239.194855,502.748352 241.713486,504.211792 244.585983,504.161743 
	C245.592972,504.144165 246.514938,504.800049 246.519531,505.997650 
	C246.524170,507.207031 245.583191,507.800934 244.580414,507.847534 
	C241.778900,507.977722 238.851700,508.452179 236.186981,507.849518 
	C230.438736,506.549438 224.680283,507.240448 218.465027,506.998260 
	C216.837097,504.642822 218.576462,504.420898 220.155350,503.946045 
	C221.542618,503.528778 223.020554,504.241882 224.675003,503.247986 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M323.316528,402.254059 
	C320.911926,400.334564 318.372955,399.571869 315.827606,398.784485 
	C314.926453,398.505737 313.713104,398.210175 313.660736,397.046265 
	C313.604645,395.798828 314.936340,395.280273 315.750641,395.337280 
	C319.187958,395.577911 322.012909,393.611115 325.592834,393.000427 
	C326.640289,393.263580 327.065735,393.763275 327.529785,394.658569 
	C327.746765,395.389099 327.781250,395.818848 327.680725,396.562439 
	C327.320801,397.635925 326.963104,398.380981 326.863037,399.534912 
	C326.680389,400.766327 326.292847,401.549683 325.282410,402.333191 
	C324.515381,402.615845 324.055756,402.595428 323.316528,402.254059 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M241.429871,499.000793 
	C237.845459,498.943939 235.504684,497.561768 236.286667,494.211578 
	C236.979034,491.245300 239.586655,490.870422 242.648071,491.960236 
	C243.834793,493.125397 245.056656,493.580597 246.668823,493.966248 
	C247.425278,494.401367 247.786819,494.844910 247.972992,495.711792 
	C247.813629,500.807770 244.087082,498.370331 241.429871,499.000793 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M312.072449,554.209106 
	C309.637665,555.839233 307.363342,556.804260 304.943634,555.226746 
	C302.850952,553.862305 302.498077,551.738953 303.409363,549.590515 
	C304.058411,548.060425 305.293457,546.548401 307.219696,547.705566 
	C309.608368,549.140442 313.786194,549.301392 312.072449,554.209106 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M224.666672,503.000000 
	C224.716827,505.080933 223.143463,504.951813 221.632477,505.010315 
	C220.364792,505.059387 218.907135,504.681427 218.089157,506.629456 
	C214.637344,506.997955 211.277191,506.998383 207.459137,506.998779 
	C206.960678,505.319214 206.843628,503.612549 208.786194,502.280151 
	C212.379181,502.334045 215.758331,502.666901 219.568756,502.999542 
	C220.685806,502.420319 221.458328,502.267975 222.657410,502.764160 
	C223.444443,502.999573 223.888901,502.999786 224.666672,503.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M281.412354,485.945496 
	C281.279480,484.453400 281.810760,483.401337 282.854187,483.353149 
	C286.814636,483.170349 290.787354,483.110321 294.750336,483.209412 
	C295.969879,483.239929 296.904663,484.247528 296.684998,485.626434 
	C296.488922,486.857452 295.361389,487.020660 294.353027,486.960876 
	C290.094238,486.708405 285.770813,487.731171 281.412354,485.945496 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M277.754333,443.705933 
	C276.740509,445.053619 276.036713,446.185547 275.180573,447.187286 
	C273.570526,449.071167 271.632965,449.846039 269.639526,447.864746 
	C267.480530,445.718872 268.369659,443.596680 270.306458,441.878113 
	C272.622040,439.823486 277.582245,440.900024 277.754333,443.705933 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M189.476868,446.903442 
	C187.440460,446.142212 185.433365,445.817078 185.214401,443.543945 
	C185.109009,442.449799 185.785873,441.577850 186.818512,441.330200 
	C188.908371,440.829010 191.132980,440.514313 193.082840,441.538940 
	C194.422791,442.243042 194.103836,443.910095 193.866455,445.164642 
	C193.422699,447.509766 191.446457,446.865814 189.476868,446.903442 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M305.991089,601.614624 
	C303.908813,601.877441 301.381134,604.867065 299.808411,600.922485 
	C298.866394,598.559814 298.984894,596.632568 301.331482,595.535461 
	C304.037262,594.270386 304.690826,596.419800 305.253418,598.397888 
	C305.519928,599.334961 305.740723,600.285095 305.991089,601.614624 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M263.783569,498.639191 
	C267.134125,497.356598 267.887939,499.604767 268.662292,501.647125 
	C269.071991,502.727692 268.626678,503.634521 267.397491,503.863037 
	C265.277344,504.257233 263.286194,503.666748 261.939636,502.103638 
	C260.400665,500.317108 262.164764,499.553162 263.783569,498.639191 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M345.197388,577.971802 
	C348.809845,582.281372 351.612213,587.387878 353.163818,593.518372 
	C349.273071,589.361450 346.410980,584.284790 345.197388,577.971802 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M264.351532,531.045288 
	C264.935394,531.013489 265.104553,530.989563 265.267456,531.011658 
	C267.014252,531.247559 268.978210,530.327148 270.467957,531.841675 
	C270.967529,532.349548 270.766815,533.125122 270.227722,533.618713 
	C268.448303,535.248169 266.156738,535.404053 264.113556,534.848145 
	C261.013306,534.004333 261.191559,532.474060 264.351532,531.045288 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M268.117035,545.930664 
	C266.515106,543.815186 266.854401,542.238708 268.918945,541.287231 
	C269.957886,540.808472 271.299866,540.715149 272.202759,541.563232 
	C273.436798,542.722412 273.309631,544.433289 272.684357,545.717041 
	C271.666199,547.807434 269.953033,546.820557 268.117035,545.930664 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M316.998169,433.000000 
	C317.097626,432.067261 317.390045,431.280609 318.791748,431.065247 
	C318.950500,435.821503 318.776062,440.599762 318.346069,445.700012 
	C314.310577,443.350647 317.639862,439.849579 317.013611,436.400116 
	C317.012817,435.554108 317.013702,435.118011 317.011932,434.354706 
	C317.139526,433.855682 317.269775,433.683868 317.200012,433.257904 
	C317.000000,433.003754 317.000000,433.000000 316.998169,433.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M315.572723,615.069275 
	C315.084442,615.876587 314.653687,616.613708 314.140442,616.676575 
	C311.958313,616.943665 309.629517,617.815979 307.675354,616.126038 
	C307.103119,615.631165 307.768097,614.811768 308.292145,614.518372 
	C310.733276,613.151306 313.236816,611.555176 315.572723,615.069275 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M271.121582,504.886047 
	C272.667023,502.125305 274.823578,502.444763 276.442474,503.905487 
	C277.592651,504.943268 276.308075,506.356415 275.242859,507.090363 
	C273.000183,508.635620 271.643219,507.775146 271.121582,504.886047 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M327.365173,395.245300 
	C326.784058,394.564575 326.426208,394.089813 326.029907,393.305725 
	C325.999847,392.556946 326.008270,392.117584 326.024475,391.348999 
	C327.966125,389.940002 327.211945,386.933990 329.881958,386.101562 
	C330.810669,386.424469 331.194550,386.944366 331.569855,387.867493 
	C331.739899,388.855377 331.618561,389.491669 331.166992,390.374817 
	C330.668823,391.164642 330.293762,391.656372 330.038818,392.543945 
	C329.931763,393.238892 329.869293,393.637238 329.706573,394.326843 
	C329.510864,394.793182 329.402405,394.966217 329.038818,395.308350 
	C328.382874,395.579529 327.984314,395.576935 327.365173,395.245300 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M276.046509,616.632080 
	C275.277893,617.980835 274.412933,618.175415 273.192657,617.238770 
	C273.000092,616.518982 272.956757,616.030884 273.008453,615.553040 
	C273.158447,614.167053 272.495148,612.561951 273.843872,611.488403 
	C274.545197,610.930237 275.382080,611.210449 276.031891,611.799133 
	C277.641937,613.257812 276.412506,614.770264 276.046509,616.632080 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M269.604919,617.000244 
	C267.486237,619.551819 262.607697,618.531494 259.050720,614.361145 
	C262.491119,614.696167 265.467285,617.145447 269.604919,617.000244 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M361.384277,625.278381 
	C360.873199,622.534729 361.911377,620.852844 364.659363,620.032959 
	C365.012421,619.982422 365.003540,619.997131 365.009644,619.992981 
	C366.320068,621.370544 367.569458,622.883484 366.231110,624.797058 
	C365.114410,626.393677 363.437378,626.347473 361.384277,625.278381 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M320.287384,498.989929 
	C319.395966,495.488037 318.670624,491.988037 319.217590,487.938293 
	C322.767029,490.487701 320.363983,494.564178 321.212463,498.484985 
	C320.978851,498.948456 320.781525,498.964752 320.287384,498.989929 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M325.044098,552.739624 
	C326.833954,551.932312 328.355560,551.561401 328.826904,553.715759 
	C329.062042,554.790344 328.533661,555.798279 327.307709,555.828979 
	C325.476257,555.874878 323.826233,555.525208 325.044098,552.739624 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M240.489594,578.602356 
	C238.495804,578.880310 237.079498,578.536011 237.557144,576.425476 
	C237.792053,575.387512 238.891235,574.904236 239.924759,575.234558 
	C241.722717,575.809143 242.331131,576.879272 240.489594,578.602356 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M204.999786,511.750305 
	C204.842987,513.196594 205.254730,514.853760 203.221390,514.703186 
	C202.108353,514.620728 201.591263,513.551331 201.519547,512.626465 
	C201.351425,510.458130 203.158081,511.251099 204.628540,511.002258 
	C204.999390,511.001221 204.999588,511.500610 204.999786,511.750305 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M323.025879,402.402924 
	C323.463348,402.023590 323.893158,402.003235 324.645935,401.986938 
	C326.340027,403.233307 325.938263,404.713531 325.462280,406.563904 
	C325.296936,407.432281 325.127319,407.904053 324.651764,408.647491 
	C324.199677,409.066467 323.979095,409.187012 323.410278,409.162842 
	C322.163452,408.489502 322.023376,407.620056 322.013794,406.350891 
	C323.125763,405.169312 322.976501,403.936035 323.025879,402.402924 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M223.000000,502.599243 
	C222.250000,502.999573 221.500000,502.999786 220.375000,502.999695 
	C219.999985,501.933167 219.999969,500.866974 219.999817,499.400696 
	C220.749832,499.000427 221.500000,499.000244 222.625244,499.000336 
	C223.000214,500.066803 223.000107,501.132996 223.000000,502.599243 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M265.570068,613.357422 
	C265.360962,612.611511 265.621613,612.340637 266.201050,612.456238 
	C266.321442,612.480225 266.483307,612.650818 266.501434,612.772400 
	C266.588715,613.357422 266.304199,613.602722 265.570068,613.357422 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M248.212433,495.820953 
	C247.684814,495.529816 247.369019,495.059631 247.026001,494.295227 
	C247.881302,494.194122 249.005905,494.176880 248.212433,495.820953 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M317.001251,433.259705 
	C317.103546,433.278076 317.207092,433.552399 317.159973,433.927124 
	C317.007019,433.856934 317.004761,433.686310 317.001251,433.259705 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M164.214066,484.210510 
	C164.000168,483.833344 164.000320,483.666656 164.000854,483.250000 
	C166.777466,483.026123 169.558517,482.964355 172.327972,483.115143 
	C173.731689,483.191589 174.946396,483.896332 174.512955,485.629974 
	C174.169266,487.004669 173.461807,487.776978 171.682205,487.444672 
	C169.462784,487.030212 167.148254,487.125061 164.438004,487.000000 
	C164.000992,486.833344 164.000748,486.666656 164.000259,486.250000 
	C164.522018,485.576508 164.667908,485.051025 164.214066,484.210510 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M182.636459,495.009583 
	C183.000397,496.069000 183.000198,497.134369 183.000000,498.599243 
	C181.559692,498.978241 179.797043,499.068085 179.472107,497.348358 
	C179.111084,495.437622 180.770035,495.132935 182.636459,495.009583 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M285.961426,319.775208 
	C283.632690,319.233551 281.541229,318.539307 282.861755,315.853180 
	C283.583649,314.384827 285.054382,313.697815 286.599487,314.906738 
	C288.724579,316.569427 288.349060,318.215118 285.961426,319.775208 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M207.666763,491.000000 
	C207.868561,491.873718 208.298996,493.152802 207.088745,493.372009 
	C205.979507,493.572937 206.166687,492.391541 206.008133,491.338409 
	C206.444916,491.000793 206.889221,491.000397 207.666763,491.000000 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M329.741699,390.293213 
	C330.161194,389.486145 330.408386,389.011017 330.742737,388.279388 
	C331.205017,384.812714 331.575623,381.600616 334.777222,379.311401 
	C335.793396,379.087311 336.454651,378.974670 337.450500,378.836426 
	C339.664886,380.559875 338.097015,382.103058 337.450775,383.656952 
	C335.107758,389.290680 335.841309,391.618988 341.224426,394.420227 
	C343.696228,395.706543 346.215302,396.885651 348.742310,398.756775 
	C348.999359,398.993134 349.493744,399.006775 349.740997,399.010498 
	C351.877594,401.439941 350.074097,403.056915 348.628723,404.603455 
	C346.909851,406.442688 347.394714,408.056335 347.843903,410.395111 
	C348.840820,415.586121 353.100128,419.839996 352.045929,425.517029 
	C351.805695,426.810730 352.669769,427.297668 353.562805,427.773163 
	C354.802246,428.433105 355.895447,429.236084 355.623901,430.830109 
	C355.297180,432.748413 353.615570,432.787750 352.166168,433.094025 
	C351.516693,433.231262 350.856140,433.344849 350.341370,434.260681 
	C351.395752,436.179108 353.470001,436.195770 355.322784,436.520172 
	C357.972839,436.984161 360.551849,437.589417 362.726837,439.281403 
	C363.659607,440.007080 364.568512,440.841217 364.379272,442.161377 
	C364.180603,443.547241 362.972717,444.249695 361.854492,444.162567 
	C356.503540,443.745667 357.298645,447.219116 357.504761,450.795471 
	C356.750824,451.963776 355.405457,451.638031 354.889832,452.661499 
	C355.993195,454.766479 358.385559,455.069916 360.102631,456.285217 
	C365.293335,459.958984 365.610718,465.896545 367.325378,471.105499 
	C368.225525,473.840179 365.478302,474.241028 363.503296,474.372345 
	C360.686859,474.559601 360.110413,475.448486 361.434601,478.080963 
	C362.811859,480.819031 361.924042,483.587677 360.123016,486.377747 
	C355.595856,488.297241 353.673462,485.859985 352.186768,482.022614 
	C351.899902,481.137451 351.789062,480.625824 351.679260,479.735352 
	C351.513641,476.552002 351.452454,473.827301 353.906128,471.696564 
	C355.984375,469.891846 355.594116,467.866699 353.583954,466.287079 
	C351.962799,465.013031 351.309235,463.118805 349.765106,461.467133 
	C345.164429,456.829529 343.329041,451.150330 342.604767,445.269348 
	C342.146851,441.551239 346.449188,441.950684 349.021362,440.103119 
	C346.628143,439.263672 344.536713,438.798279 341.936432,439.043457 
	C340.853363,439.099518 340.145203,438.992157 339.137512,438.587067 
	C336.508606,435.701294 335.620972,433.088745 337.341614,429.366577 
	C338.810272,426.189484 340.648163,422.003204 335.391724,419.758423 
	C335.104950,419.635925 334.748444,419.243652 334.793427,419.002411 
	C335.998657,412.540375 331.553528,405.766479 335.715759,399.113617 
	C334.746613,397.682190 332.386414,397.657898 330.831970,395.864410 
	C330.140656,394.985199 330.012115,394.253540 329.683594,393.200012 
	C329.390289,392.107666 329.496063,391.366486 329.741699,390.293213 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M348.956451,398.645752 
	C343.961273,397.840149 339.308319,395.874908 335.652893,392.110779 
	C332.679932,389.049347 334.952454,385.966736 336.093231,383.065247 
	C336.550507,381.902161 337.670319,380.962006 337.818604,379.195740 
	C337.809113,378.414093 337.833130,378.017395 337.882019,377.323120 
	C341.701263,377.342865 344.249817,378.461487 341.732635,382.900269 
	C340.280182,385.461548 343.552216,387.619537 342.166199,390.445679 
	C340.321899,394.206390 344.890442,393.329895 346.149353,394.967255 
	C347.010437,396.087250 348.530792,396.715179 348.956451,398.645752 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M353.432892,352.056824 
	C360.694672,352.625824 367.729614,353.322021 374.761383,354.048828 
	C377.026978,354.282959 377.929382,355.839905 377.708038,357.847473 
	C377.503296,359.704437 375.867401,360.291077 374.333435,359.825867 
	C367.128235,357.640625 359.230804,358.144165 352.140442,354.013550 
	C352.262909,353.286896 352.733856,352.741364 353.432892,352.056824 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M367.746399,334.803223 
	C370.180450,337.515991 373.746490,337.108551 376.845520,339.020874 
	C374.699219,340.335541 372.664825,340.807129 370.611786,340.936920 
	C368.513000,341.069580 366.945496,341.366791 365.779114,343.588074 
	C364.626221,345.783569 362.646637,345.666199 361.019073,343.140594 
	C362.848724,340.341003 364.762756,337.928833 366.848175,335.228882 
	C367.184875,334.833527 367.350159,334.725891 367.746399,334.803223 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M357.534912,347.113098 
	C363.781555,346.900818 369.683716,347.701996 375.394806,349.988617 
	C369.238525,353.460083 363.359833,349.485443 357.076111,349.023560 
	C356.971130,348.224121 357.152252,347.749146 357.534912,347.113098 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M331.924835,381.048584 
	C328.366852,380.059967 328.469818,377.170776 329.187622,374.994720 
	C330.448853,371.171478 332.467865,376.116669 334.544830,374.986633 
	C334.931732,375.435760 334.955078,375.875153 334.972443,376.645874 
	C333.777557,378.103210 333.831512,380.078369 331.924835,381.048584 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M331.492676,381.509216 
	C332.523865,379.829620 333.455933,378.503601 334.677216,377.077393 
	C334.995209,377.470459 335.024017,377.963654 335.092468,378.828400 
	C333.900391,382.009918 332.668640,384.819885 331.133392,387.826416 
	C330.647888,387.548279 330.465912,387.073639 330.256805,386.334778 
	C330.617615,384.667999 331.005585,383.265411 331.492676,381.509216 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M341.707855,368.816467 
	C342.072540,369.784241 342.227783,370.577423 342.317596,371.377899 
	C342.471252,372.747192 345.466187,372.856415 344.025513,374.752411 
	C342.831970,376.323273 341.075500,375.543427 339.168213,374.957275 
	C339.425964,373.129456 340.044189,371.368042 340.825409,369.295227 
	C341.148590,368.870941 341.308777,368.758026 341.707855,368.816467 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M344.517090,363.921906 
	C345.076416,365.677429 344.911621,367.315002 342.316406,367.854248 
	C342.084564,366.217926 342.732971,364.879425 344.517090,363.921906 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M350.655914,354.098633 
	C350.796661,355.309204 351.571808,356.995209 349.293335,357.768982 
	C348.632019,356.289642 349.130310,355.125671 350.655914,354.098633 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M348.461212,357.959229 
	C348.883575,358.914032 348.615936,359.637054 347.252563,359.907227 
	C346.995758,359.067688 347.166992,358.264648 348.461212,357.959229 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M368.198792,334.806458 
	C367.977325,334.988190 367.497650,334.975006 367.258606,334.958069 
	C367.106384,334.076385 367.448608,333.398590 368.647034,333.083160 
	C368.771576,333.567261 368.595917,334.095978 368.198792,334.806458 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M345.762756,361.105469 
	C345.883606,361.307983 345.719086,361.552856 345.289368,361.860840 
	C345.133362,361.647308 345.284454,361.388580 345.762756,361.105469 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M369.246338,331.480896 
	C369.280426,331.273438 369.692963,331.185547 369.902649,331.212677 
	C369.964081,331.466766 369.789337,331.671112 369.431519,331.978516 
	C369.274933,332.104156 369.212250,331.688354 369.246338,331.480896 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M341.940430,368.737732 
	C341.946716,368.987793 341.467590,368.988922 341.228027,368.986389 
	C341.041504,368.661713 341.192383,368.394897 341.652588,368.087830 
	C341.887482,368.157379 341.910797,368.322510 341.940430,368.737732 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M164.000000,484.333313 
	C164.638840,484.100372 165.404861,484.155640 165.448761,484.957153 
	C165.465256,485.258209 164.885666,485.591919 164.288910,485.955383 
	C164.000000,485.555542 164.000000,485.111115 164.000000,484.333313 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M515.325562,801.280518 
	C515.956604,798.223572 515.596130,796.774475 512.029907,796.921204 
	C506.657074,797.142456 501.660889,798.470154 497.016418,801.018005 
	C495.077850,802.081543 492.674316,802.840210 491.661835,801.166443 
	C490.116486,798.611816 493.086151,797.964417 494.760712,797.262085 
	C496.715790,796.441895 498.290253,794.991760 500.524902,794.553772 
	C503.675079,793.936218 502.916077,791.084229 503.000000,788.418579 
	C503.120636,787.851929 503.241302,787.703796 503.576538,787.392944 
	C504.122864,787.036621 504.250061,786.754578 504.133667,786.396912 
	C504.060699,786.172791 503.919678,786.070496 503.504578,785.910095 
	C501.521454,784.703552 499.677795,783.890930 497.642761,782.698242 
	C495.542816,781.056458 492.803192,780.732727 491.876068,777.745361 
	C491.576660,775.782715 490.917877,774.261841 490.857788,772.318848 
	C495.190002,769.514221 496.207947,773.991577 498.798523,775.729309 
	C501.017365,777.346313 502.427307,781.284790 505.818054,777.275818 
	C506.218933,775.236572 505.418671,773.866577 504.158875,772.305908 
	C504.119873,766.988586 501.032196,765.338623 496.339691,765.007263 
	C494.539093,764.426270 493.078186,763.852539 491.308655,763.139404 
	C488.100311,761.536499 486.486633,759.343262 486.479370,755.934509 
	C486.472198,752.582886 484.010651,750.253540 482.252777,747.266602 
	C480.721527,745.784912 479.586945,744.356995 477.338348,744.038025 
	C475.783661,742.567078 474.238800,742.179871 472.595032,742.982849 
	C470.332794,744.088013 467.778900,743.654114 465.247498,744.765869 
	C463.285645,745.000000 461.571289,745.000000 459.428467,745.000000 
	C454.095978,744.888611 450.698669,741.326538 450.848114,735.926514 
	C451.461578,735.325073 451.890839,735.084595 452.335938,734.864258 
	C454.185059,733.948608 457.024628,733.813965 457.051025,731.177124 
	C457.073822,728.898499 454.237671,728.710449 452.595581,727.127197 
	C451.304962,724.608337 451.404144,722.364807 452.545288,719.792603 
	C452.954498,719.292725 453.145691,719.117432 453.685425,718.772339 
	C455.412598,718.420898 456.492432,718.985291 457.622742,720.242065 
	C459.549805,723.768005 461.665710,726.492554 466.081177,726.770142 
	C467.598816,727.136169 468.515411,727.814941 469.412415,729.074707 
	C470.197021,731.773865 471.872437,732.828796 474.543640,733.150085 
	C475.055176,733.392273 475.250732,733.510803 475.702789,733.859863 
	C479.588470,738.907654 484.292145,742.480164 489.223785,745.856689 
	C492.710052,748.243652 495.390198,751.360413 495.727966,756.144409 
	C496.839905,752.637512 499.069000,750.711426 502.825592,750.107056 
	C504.144196,749.942444 505.068146,750.076660 506.177368,750.809204 
	C507.574341,751.883423 507.342682,754.698914 510.267639,753.399170 
	C512.962158,753.752502 515.089417,754.721558 517.293091,756.346497 
	C518.240540,757.570312 518.508240,758.721069 518.906860,759.810425 
	C521.912964,768.025818 526.137024,770.140259 534.326538,767.220520 
	C536.478699,766.453186 538.302307,766.498596 539.855774,768.023010 
	C541.505676,769.642029 543.059387,769.411499 545.150513,768.258423 
	C547.860840,768.146240 549.880005,768.692017 550.928894,771.527344 
	C550.983826,772.496033 550.867310,773.028870 550.398560,773.859863 
	C542.819946,774.949341 535.543823,773.376038 528.224060,773.616821 
	C526.013062,773.689575 523.432617,773.172852 522.508667,776.619507 
	C522.414551,777.803040 522.200012,778.556091 521.637085,779.605347 
	C518.070984,782.521362 515.658813,779.748962 512.702698,778.296875 
	C512.154236,777.954407 511.937561,777.705994 511.671021,777.555298 
	C511.194763,777.285950 511.108551,777.355103 511.670319,777.857300 
	C512.837219,779.283203 513.724548,780.546570 513.549072,782.559204 
	C513.054810,784.149475 510.348663,784.837219 511.939209,786.392151 
	C513.756897,788.169067 515.034302,785.797119 516.764648,784.775208 
	C517.317749,784.604614 517.553223,784.591064 518.120300,784.699219 
	C520.455933,788.661255 519.500916,791.066040 515.534180,792.116760 
	C514.403748,792.416199 513.234558,792.360168 512.076111,792.418701 
	C511.171295,792.464355 510.158844,792.015320 509.042572,793.189331 
	C512.271973,793.443848 515.257690,792.443481 518.459106,793.480713 
	C520.084839,793.609924 521.376221,793.995850 522.641113,793.814026 
	C525.042114,793.468994 522.617004,792.746155 522.576660,791.855896 
	C522.384644,790.988464 522.479431,790.493774 523.018250,789.760742 
	C526.499573,788.682434 529.649963,789.193298 532.656433,790.110596 
	C534.763184,790.753418 537.706909,791.439453 537.221130,794.443481 
	C536.817993,796.936279 534.220154,797.228821 532.013245,797.391724 
	C528.516663,797.650024 525.116150,798.229553 521.729004,800.358765 
	C519.596558,801.626221 517.748901,802.360168 515.325562,801.280518 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M485.766693,782.760620 
	C483.257782,785.539307 484.967072,787.457886 486.945618,789.672119 
	C486.756195,790.370972 486.449066,790.682495 485.781219,791.059265 
	C485.321075,791.674927 483.287994,791.045715 485.034729,792.482483 
	C486.395691,792.337463 487.460022,792.058777 488.754089,792.779297 
	C489.605194,796.511414 486.823059,795.772339 485.065491,795.953491 
	C479.088989,796.569336 473.113831,795.794983 467.280975,794.809570 
	C463.071655,794.098450 459.142487,794.640198 455.034851,794.891235 
	C445.823364,795.454346 437.269470,800.044250 427.850220,799.103760 
	C427.270599,799.045898 426.396942,799.351868 426.065308,799.793152 
	C422.986633,803.888916 419.027222,803.416504 414.826630,802.189331 
	C413.897583,801.917908 412.849792,802.052917 411.428345,802.000427 
	C408.667786,799.546021 405.960022,797.986633 402.543396,797.226318 
	C397.812347,796.173401 395.879700,792.572266 396.583984,787.344849 
	C397.174896,782.958618 399.587433,780.674805 404.290588,780.544617 
	C410.401672,780.375427 416.567932,780.037964 422.778351,782.749390 
	C423.408020,783.049988 423.645508,783.192688 424.175171,783.622803 
	C424.815887,784.477600 425.083801,785.076233 425.139160,785.748718 
	C425.386780,788.757935 423.528778,792.770081 428.688995,793.478760 
	C438.107208,794.772278 440.896698,793.690247 442.782471,787.731567 
	C443.661102,784.955200 444.906342,782.194702 444.109375,779.069275 
	C443.693542,777.438538 444.061279,775.772766 445.736511,774.279541 
	C446.118774,773.689514 446.310547,773.433838 446.787659,773.116455 
	C447.385895,772.619751 447.846313,772.472473 448.691223,772.779114 
	C449.750916,773.000000 450.500610,773.000000 451.625153,773.000000 
	C454.986969,771.210693 457.208344,767.815247 461.693420,768.762756 
	C463.515167,769.094299 464.580353,769.753174 464.958893,771.662659 
	C465.269470,772.609802 465.250580,771.745361 465.298157,772.382874 
	C465.924988,780.779724 472.255402,781.407898 478.515686,782.154297 
	C478.841522,782.193237 479.142761,782.355042 479.716431,782.763550 
	C481.844452,782.841248 483.688934,782.682495 485.766693,782.760620 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M383.317932,785.268738 
	C382.248077,783.609192 381.289185,782.454834 379.327515,782.035889 
	C375.449219,779.963928 371.580963,781.453247 367.886658,781.002869 
	C366.008667,780.773987 363.712341,781.686523 362.538177,779.456360 
	C361.376740,777.250244 363.050110,775.963440 364.303528,774.348511 
	C366.975922,770.905212 361.912811,771.476440 361.264893,769.261658 
	C359.240936,762.250061 359.248383,762.269653 365.661835,759.832397 
	C366.379150,759.559875 367.524628,758.888062 367.507599,758.437805 
	C367.247986,751.575989 373.058014,751.622986 377.083435,750.723694 
	C382.064026,749.611084 387.445679,748.509460 392.695129,751.758911 
	C393.000000,755.030579 393.000000,758.061218 393.000000,761.545898 
	C394.501373,764.405457 397.668243,763.735901 399.752258,765.761475 
	C401.142426,766.843628 400.972351,768.088196 400.999786,769.636230 
	C401.215271,770.630981 401.079376,769.767273 401.303162,770.393494 
	C404.162415,778.393921 396.405151,780.691406 393.041382,785.176331 
	C390.686096,788.316589 386.878815,787.688721 383.317932,785.268738 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M453.792480,718.687012 
	C452.627991,721.248230 453.068970,723.740051 453.000000,726.580627 
	C454.932129,729.942871 452.665039,732.618591 452.232605,735.712891 
	C451.730194,740.703064 455.007507,742.682922 458.757202,744.743286 
	C457.156281,749.554565 458.057556,751.909424 463.568298,752.515015 
	C467.879974,752.988770 472.138733,752.865906 476.414703,752.950195 
	C479.434052,753.009705 481.101776,753.898254 481.018616,757.231567 
	C480.939087,760.418213 479.111603,760.966248 476.449982,761.031982 
	C471.350708,761.157715 466.229645,760.478699 461.157806,761.886841 
	C457.272522,762.965576 453.902557,765.272644 449.812286,765.987915 
	C447.862305,766.328918 446.774017,766.864868 446.044373,764.344849 
	C446.405548,763.225342 447.066345,762.706787 447.840179,762.386536 
	C451.226166,760.984924 454.249908,759.483765 453.240601,754.812866 
	C451.826599,748.269165 449.082397,745.095398 443.921906,744.312439 
	C440.456787,743.786743 437.010437,742.946838 433.349670,744.943481 
	C430.507935,744.992676 428.015564,745.004822 425.523987,744.966858 
	C424.711487,744.954529 423.719971,744.950562 423.583008,743.887634 
	C423.459564,742.929504 424.274689,742.502014 425.042938,742.144409 
	C428.372101,740.594482 432.102264,740.726868 435.596161,739.553528 
	C438.663055,738.523621 441.657257,737.056458 444.919922,736.497559 
	C448.693237,735.851257 450.363617,733.234253 449.911041,729.681213 
	C449.359680,725.352722 449.378326,720.495850 444.072174,718.589905 
	C443.620514,718.427673 442.808014,718.209961 442.667572,718.396973 
	C439.382019,722.770996 434.615570,719.738098 430.649597,720.759338 
	C427.307434,721.619934 424.858215,720.317139 424.949921,716.088318 
	C425.038361,712.011108 424.123535,707.924927 424.997864,703.423584 
	C426.631866,699.516907 429.600159,698.933472 431.486847,701.856689 
	C436.877380,710.208801 446.378174,712.692444 453.792480,718.687012 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M382.978516,785.409546 
	C386.066864,784.352722 389.833557,788.025757 392.209900,784.137024 
	C394.991791,779.584534 401.929810,777.760681 400.999878,770.404846 
	C403.716003,770.541199 403.027100,772.744446 402.953369,774.410217 
	C402.797607,777.929260 404.452393,778.892212 407.768738,779.081604 
	C414.809967,779.483765 420.439728,777.349670 424.403503,771.351135 
	C425.154205,770.215027 426.107574,769.190002 427.548431,769.117188 
	C430.694092,768.958496 433.825836,768.923340 436.929932,768.151917 
	C438.325714,767.804932 439.564362,768.665039 439.776031,770.264404 
	C439.955505,771.620605 439.165405,772.531006 437.895782,772.804626 
	C433.018555,773.856018 431.251892,777.266846 430.987427,781.803101 
	C430.864624,783.909241 429.577301,785.089905 427.936584,786.042908 
	C426.468964,786.895447 425.790436,788.111877 426.198914,789.791931 
	C426.638428,791.599304 428.054260,791.961853 429.657257,791.983948 
	C429.823700,791.986267 429.990662,791.983887 430.156555,791.995422 
	C438.794739,792.597290 442.670746,788.967041 443.023926,780.076904 
	C443.110413,777.900818 442.119446,775.218079 445.616272,774.060913 
	C444.207306,777.731323 445.987549,781.692932 445.116821,785.600525 
	C443.255371,793.954163 433.787659,798.737061 426.325195,794.440430 
	C422.446289,792.207153 424.482941,788.158691 424.000000,784.446777 
	C424.860107,783.050720 426.087402,782.329224 425.991333,780.708862 
	C424.981110,781.419922 425.309448,782.807678 424.224548,783.762573 
	C417.668701,783.340698 411.536499,781.282227 405.000519,781.920471 
	C399.706818,782.437378 398.084808,783.642334 398.001648,788.693848 
	C397.913391,794.056091 399.234802,795.731384 404.393921,795.945496 
	C408.068695,796.098022 410.299652,797.595947 410.992432,801.622742 
	C409.257904,802.332214 407.515808,802.664429 405.386963,802.997070 
	C395.441620,800.637573 385.984406,797.077026 375.943817,800.749756 
	C374.528290,801.267517 372.998444,801.334045 371.284302,800.285156 
	C371.158997,797.091980 374.306610,798.756714 375.580994,796.887024 
	C373.691925,795.258850 370.250732,796.572449 369.071686,793.355713 
	C371.797302,791.210205 374.464630,792.889465 377.166687,793.334473 
	C379.382507,793.699524 381.614502,795.006958 384.107452,792.778931 
	C379.253174,790.066956 374.189941,792.599854 369.267975,791.223267 
	C369.501465,787.264526 372.468628,788.432922 374.754730,787.927917 
	C377.501648,787.321106 381.186279,790.142212 382.978516,785.409546 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M361.041382,625.352722 
	C363.877075,625.030579 367.226837,625.515442 365.057434,620.362122 
	C365.770782,620.284302 366.874359,620.356750 367.227783,620.909180 
	C371.542938,627.653809 375.747986,634.468933 379.987152,641.632019 
	C380.961273,644.286011 382.527191,646.117554 384.684357,647.867432 
	C386.029236,648.462952 386.745087,649.189758 386.973389,650.666016 
	C388.337067,655.103088 384.219574,654.815186 382.271942,656.750916 
	C379.337891,657.052124 376.687408,657.150024 374.263550,655.676819 
	C372.296783,654.481567 370.515198,655.550598 368.582001,656.119324 
	C362.383362,657.942810 356.446075,657.655334 351.744812,652.520142 
	C349.660309,650.243286 347.117096,651.053528 344.749908,651.059326 
	C341.337524,651.067749 339.379822,650.773132 340.602295,646.316589 
	C342.217010,640.429993 346.655792,638.016541 352.338623,640.692078 
	C354.002411,641.475403 354.877441,642.581604 355.034607,644.485291 
	C355.324585,647.998230 357.257233,649.719666 360.764709,648.518066 
	C362.885376,647.791565 363.824554,648.781311 364.831665,650.232849 
	C366.407593,652.504211 368.624786,653.226196 371.335938,653.029114 
	C374.091522,652.828857 377.488556,653.443909 378.666229,650.354431 
	C379.974518,646.922302 376.550842,641.990967 372.825989,640.751160 
	C371.893494,640.440796 370.359222,639.986389 370.265381,639.409058 
	C369.287964,633.394836 362.640015,631.250366 361.041382,625.352722 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M390.624878,655.041626 
	C391.436340,654.998108 391.867645,655.007019 392.622070,655.013428 
	C393.205505,655.247314 393.465851,655.483765 393.819702,655.970032 
	C395.158661,657.740479 395.680542,659.652100 396.766541,661.658325 
	C397.054779,665.847107 395.113434,666.131287 392.139313,664.905396 
	C390.728638,664.323853 389.766449,662.504089 387.090576,663.491333 
	C390.613312,665.660828 393.371155,668.647705 396.998962,668.885193 
	C400.879761,669.139282 402.227264,670.678711 402.634521,674.177429 
	C402.958344,676.959412 404.731354,677.729675 407.638000,676.515015 
	C410.040009,676.021851 411.072021,677.442749 412.343872,679.081421 
	C413.575775,680.727966 412.835571,683.231812 415.621338,683.743652 
	C416.125305,683.931091 416.318115,684.020447 416.770874,684.276855 
	C417.333862,684.699524 417.655548,684.928467 418.292175,685.171448 
	C418.994446,685.262817 419.393951,685.359253 420.063171,685.680298 
	C420.475128,686.053650 420.608276,686.242249 420.873230,686.760620 
	C421.191498,687.819092 421.214233,688.557434 421.055756,689.688843 
	C418.829681,694.227661 415.691742,692.958679 412.269958,691.309021 
	C411.461670,687.288330 407.904266,685.353333 402.716522,686.085266 
	C399.680939,686.513611 395.558746,688.876587 398.396057,682.179871 
	C399.131653,680.443665 396.963531,677.315063 395.792450,674.996094 
	C395.128784,673.681885 393.923676,674.025696 392.976624,675.149841 
	C391.801331,676.544983 390.508636,677.840515 389.296692,679.205750 
	C388.022247,680.641357 386.766846,681.176819 385.057251,679.715881 
	C383.788635,678.631653 382.161530,678.276367 380.487305,677.936340 
	C377.064423,677.241028 374.381561,675.841553 376.821198,671.594788 
	C377.047272,671.201172 377.120544,670.549927 376.963379,670.131531 
	C374.633118,663.927429 377.820007,660.375732 382.933136,657.348022 
	C385.035065,654.927124 387.774506,655.521790 390.624878,655.041626 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M433.000000,745.428833 
	C433.211456,742.677063 434.701721,741.868408 436.779602,742.028320 
	C440.723877,742.331787 444.665863,742.697693 448.591980,743.175903 
	C449.308289,743.263123 450.290527,743.856995 450.560577,744.474670 
	C452.130829,748.066345 454.082001,751.622620 454.845184,755.404602 
	C455.930450,760.782410 454.721436,761.717896 446.383667,763.991455 
	C445.504456,763.741089 445.008911,763.482178 444.256683,763.111633 
	C443.608368,762.560303 443.159454,762.206360 442.289032,762.025513 
	C439.104034,760.354431 435.932953,761.215637 432.440063,760.998413 
	C429.493561,758.719238 428.971130,756.279907 431.699768,753.300537 
	C433.867462,750.834045 432.643402,748.235168 433.000000,745.428833 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M411.582703,691.010986 
	C414.481567,689.629456 417.893768,693.969177 419.952942,689.358704 
	C420.334595,688.662598 420.744263,688.468018 421.565613,688.449768 
	C422.124695,688.606384 422.334778,688.711975 422.811676,689.046509 
	C431.806854,697.771667 440.521179,706.274719 450.514771,713.342407 
	C452.206482,714.538940 453.952942,715.992188 454.137390,718.678528 
	C454.000000,719.000000 453.999329,719.000000 453.994751,719.002991 
	C450.829346,718.538208 448.041809,717.359924 445.901276,714.873169 
	C445.375641,714.262512 444.708130,713.297913 444.108521,713.299866 
	C437.434357,713.322083 434.460175,708.030334 431.381500,703.716125 
	C429.180267,700.631409 427.912354,700.990723 425.340912,702.939209 
	C424.248810,703.002930 423.500031,703.000916 422.376862,702.998291 
	C421.268982,702.234863 420.903381,701.326294 420.948730,699.900146 
	C422.753845,694.785950 419.186707,696.537781 416.802643,696.416016 
	C415.165955,696.444031 413.896393,696.429871 412.226990,696.451660 
	C411.007294,696.461243 410.187469,696.457336 408.941650,696.501282 
	C406.162384,696.765015 403.824890,696.893372 401.349609,695.283936 
	C399.529053,695.004822 398.048523,695.076904 396.580017,694.975037 
	C395.239044,694.882019 393.221985,695.476807 393.342010,693.150513 
	C393.439392,691.263672 395.132599,690.963562 396.660492,691.037354 
	C401.498169,691.270874 406.330414,693.010376 411.582703,691.010986 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M479.913330,782.637695 
	C475.248871,783.831726 470.998413,781.463989 466.469849,780.852539 
	C464.425964,780.576538 463.898773,779.174194 464.398071,777.192139 
	C464.754517,775.777100 464.809875,774.286133 464.999817,772.414551 
	C466.768982,772.353271 468.534058,772.727722 470.308136,773.053162 
	C471.762024,773.319885 473.140930,774.103516 472.759216,775.584839 
	C471.539032,780.320068 474.299561,780.980713 477.875122,781.042480 
	C478.763794,781.057861 479.460724,781.414185 479.913330,782.637695 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M384.573914,712.944336 
	C386.166840,709.708191 388.237335,707.770264 391.383728,709.867859 
	C393.741150,711.439331 394.482819,714.380676 393.285645,716.836365 
	C391.761475,719.962830 388.340698,719.407410 385.746918,718.744934 
	C382.805481,717.993591 384.153839,715.327332 384.573914,712.944336 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M431.567291,753.000000 
	C431.354187,755.408936 430.480316,757.814880 431.951416,760.624512 
	C429.092163,761.099915 426.212677,760.687561 423.446289,762.478821 
	C421.965942,763.437439 419.668030,763.831482 418.089264,761.367065 
	C418.180603,760.723999 418.404449,760.496765 418.663757,760.307495 
	C420.281952,759.125854 423.547211,759.698669 423.589874,757.101135 
	C423.638397,754.147278 420.055054,754.947388 418.216492,753.272888 
	C419.164490,749.760742 421.862061,750.595032 423.605804,751.612915 
	C426.088043,753.061829 428.555634,752.958191 431.567291,753.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M417.750000,753.000000 
	C418.325958,753.000183 418.652222,752.991394 418.977875,753.001892 
	C421.942535,753.097351 425.645905,753.051819 425.734222,756.840881 
	C425.833374,761.095886 421.924530,760.879761 418.407745,760.999939 
	C418.000000,761.000000 417.500000,761.000000 417.250000,761.000000 
	C414.598999,758.711548 413.953308,756.285828 416.711365,753.308289 
	C417.000000,753.000000 417.500000,753.000000 417.750000,753.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M407.341003,713.020508 
	C407.901398,713.005676 408.067444,713.001709 408.233398,713.004333 
	C410.751526,713.043640 413.432251,713.048523 414.500549,715.863525 
	C414.991882,717.158264 413.465942,717.773132 412.420074,718.240967 
	C409.440369,719.573792 406.125671,719.668945 404.145355,717.160950 
	C402.054199,714.512573 404.844513,713.666260 407.341003,713.020508 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M400.907013,695.381409 
	C403.372345,695.007324 405.740356,695.006104 408.552124,695.006104 
	C410.116760,696.596436 413.274475,695.281738 413.385986,698.519104 
	C413.079559,699.024475 412.878082,699.171082 412.338318,699.158813 
	C405.937134,699.000000 399.874298,699.000000 393.016083,699.000000 
	C395.397919,695.482666 399.218414,698.588135 400.907013,695.381409 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M482.008331,747.383545 
	C485.945160,748.391907 487.567932,751.758057 487.825256,755.373779 
	C488.030579,758.259338 488.875275,760.405273 490.929321,762.644043 
	C486.975311,762.989685 484.917297,760.924622 485.030029,756.948425 
	C485.128357,753.480835 482.161713,751.103088 482.008331,747.383545 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M492.654816,776.763794 
	C492.789612,780.495605 496.838348,779.196960 497.927063,781.656128 
	C497.881561,783.392334 497.295166,784.607117 496.315430,786.007874 
	C495.030945,786.600037 494.242462,786.142883 493.256470,785.243958 
	C492.529480,784.684631 492.058960,784.369324 491.294189,784.026978 
	C490.322205,783.193481 489.386627,782.799988 488.224365,782.204041 
	C487.966400,780.250549 490.251373,779.572754 489.989319,777.359131 
	C490.663513,776.338379 491.448730,776.249695 492.654816,776.763794 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M412.008362,699.378845 
	C412.000000,699.000000 412.499115,698.998901 412.748657,698.998230 
	C413.824738,698.247803 414.753815,697.718689 416.303345,697.622375 
	C417.524750,697.570984 418.339355,697.577393 419.559479,697.684998 
	C420.506256,697.854309 421.005463,698.069092 421.731781,698.689880 
	C422.000366,700.066223 422.000702,701.132263 422.001770,702.597900 
	C418.278229,703.137024 413.593445,706.237061 412.008362,699.378845 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M496.226135,765.276245 
	C499.189941,763.324219 502.297272,765.616638 505.512848,765.237061 
	C506.794434,765.085815 506.700348,766.530640 505.979156,767.413147 
	C505.038605,768.564148 503.914154,769.589844 504.007019,771.630188 
	C502.898590,771.911011 501.196564,772.041565 501.285675,770.684021 
	C501.526031,767.022583 498.323364,766.991882 496.226135,765.276245 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M492.999115,776.635620 
	C492.249420,777.000610 491.498840,777.001221 490.372925,777.003418 
	C488.055450,776.596069 485.718231,778.000122 484.090210,775.368530 
	C484.165558,773.530884 485.088684,772.679504 486.678345,772.053467 
	C488.066437,771.997681 489.132874,771.997803 490.599182,771.997803 
	C492.210632,773.167114 493.349243,774.370728 492.999115,776.635620 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M390.859680,654.706299 
	C389.476379,658.682861 386.239777,656.312439 383.431702,657.001404 
	C382.998779,657.002441 382.499390,657.000671 382.249695,657.000366 
	C381.924286,653.661804 385.989685,654.113281 386.944763,651.323730 
	C388.241760,652.130859 389.478058,653.277100 390.859680,654.706299 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M505.601685,776.996948 
	C506.478302,778.444275 508.068329,780.457703 505.238037,780.819397 
	C502.352600,781.188171 499.519409,780.508545 499.008698,776.379089 
	C501.067780,776.330505 503.135559,776.663391 505.601685,776.996948 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M465.434082,745.000000 
	C468.116150,741.806396 474.569275,740.875793 476.950256,743.641846 
	C473.289398,744.333374 469.578766,744.666687 465.434082,745.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M461.931458,768.658325 
	C458.053070,768.331238 456.273468,772.697937 452.369263,772.993164 
	C454.354095,768.540283 458.056915,766.782043 461.931458,768.658325 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M486.760925,771.737183 
	C486.184814,772.907043 485.369629,773.816528 484.277313,774.863037 
	C481.864075,774.760986 480.391968,773.404663 481.274567,771.488281 
	C482.677277,768.442566 484.737518,771.378113 486.760925,771.737183 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M416.643433,753.046875 
	C416.353455,755.409607 415.481384,757.816162 416.951569,760.626709 
	C414.810944,760.735657 413.353424,759.525024 413.116211,757.354614 
	C412.871307,755.114075 414.249542,753.787842 416.643433,753.046875 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M399.948547,765.639465 
	C397.401428,765.533203 394.457306,765.792786 393.049927,762.358643 
	C395.252319,763.191223 398.659027,761.954285 399.948547,765.639465 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M355.684937,705.049866 
	C356.307678,706.104675 356.615387,707.209351 356.960327,708.658203 
	C355.860168,708.968872 354.930023,708.545349 354.147736,707.281738 
	C354.095581,706.104309 354.366058,705.337341 355.684937,705.049866 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M487.998596,782.248779 
	C489.133362,782.057556 490.380219,781.871216 490.936462,783.661499 
	C490.194427,783.751709 489.388824,783.503357 488.290405,783.125122 
	C487.997559,782.995239 487.998444,782.497620 487.998596,782.248779 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M442.177307,762.234619 
	C442.901825,760.986206 443.523163,761.224548 443.963745,762.715881 
	C443.451569,762.823059 442.903107,762.646118 442.177307,762.234619 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M448.956177,772.711121 
	C448.556488,773.000000 448.111786,773.000000 447.333527,773.000000 
	C447.100494,772.361206 447.155792,771.595032 447.957336,771.551270 
	C448.258484,771.534851 448.592224,772.114380 448.956177,772.711121 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M359.984039,734.386047 
	C362.685608,730.446899 358.557404,726.349243 360.950073,722.384277 
	C362.098328,722.172058 362.946869,722.719666 362.927063,723.893738 
	C362.879608,726.709717 364.589050,727.216675 366.846283,726.989380 
	C372.180206,726.452148 376.304352,728.126709 378.667023,733.227600 
	C378.932281,733.800232 379.389954,734.444397 379.927551,734.700867 
	C381.698425,735.545471 384.253571,736.164001 383.285828,738.646240 
	C382.424713,740.854980 379.772186,739.957947 377.873108,739.962524 
	C374.095795,739.971619 370.350555,740.315735 366.665070,738.521606 
	C363.634003,737.046021 361.880280,738.771667 362.055969,742.057312 
	C362.143219,743.689453 362.784607,745.288330 362.913177,746.922791 
	C363.121826,749.575195 363.409393,752.458618 360.622284,754.045654 
	C357.843262,755.628052 354.862000,755.447876 352.051605,753.959412 
	C348.940277,752.311462 349.614502,749.159607 350.192902,746.850403 
	C351.342163,742.261719 351.829803,736.641968 358.790161,736.722717 
	C359.694733,736.733215 359.882965,735.620544 359.984039,734.386047 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M344.942902,725.374023 
	C345.658539,724.998474 346.316956,724.974731 346.972931,725.005005 
	C349.039154,725.100220 352.121155,724.614868 352.259003,727.134216 
	C352.407532,729.849304 349.252502,728.642334 347.520233,729.081177 
	C345.606079,729.566040 343.905029,730.382385 342.116608,731.999756 
	C345.637390,734.086182 349.276489,732.101196 352.702026,733.723389 
	C345.654388,736.431946 338.349243,734.396851 331.036957,733.760132 
	C330.214233,733.688477 329.497253,733.056152 329.464783,732.097595 
	C329.423401,730.876526 330.292267,730.372986 331.315948,730.139709 
	C334.527588,729.407837 337.727234,728.589355 340.972626,728.053345 
	C342.667084,727.773499 344.325012,727.801819 344.942902,725.374023 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M509.386475,844.999146 
	C508.772400,842.934082 507.230988,843.010498 505.368958,842.998108 
	C504.959503,840.348389 508.328339,841.667175 508.929077,839.338257 
	C510.087463,837.801392 511.656372,836.646484 508.910126,835.486938 
	C506.000488,834.258545 502.998657,833.768188 499.430573,833.998535 
	C499.926666,829.299622 498.423126,827.259644 493.797424,827.013794 
	C492.490997,826.944336 491.177826,827.000732 489.433289,826.998413 
	C488.960358,822.073364 488.960358,822.073364 484.243958,821.235718 
	C483.547668,818.605469 481.910217,818.878845 480.257904,819.418152 
	C478.350647,820.040771 476.470490,820.329102 474.274902,819.268433 
	C474.330353,817.900452 474.660706,816.800842 474.994568,815.351868 
	C478.359985,815.001648 481.721893,815.000854 485.541931,815.000000 
	C486.848633,816.885925 488.494720,817.071777 490.631226,816.999573 
	C491.167023,816.872009 491.333618,816.743958 491.750092,816.807983 
	C492.559448,819.716980 494.156006,821.690979 496.916046,821.845764 
	C499.759033,822.005249 501.338013,819.581299 502.530487,817.475098 
	C504.034607,814.818542 501.665253,813.258423 500.091919,811.586670 
	C498.532593,809.929871 496.133331,808.482544 497.365112,805.728149 
	C498.508972,803.170288 501.108612,804.220215 503.580353,804.001892 
	C505.440948,805.128235 503.506104,806.819946 504.645630,808.051392 
	C506.314941,807.179749 504.989136,805.602905 505.781738,804.258728 
	C508.133453,804.112976 510.228851,803.956238 512.611694,803.052490 
	C515.547729,801.997559 518.734802,803.148804 521.173889,800.496643 
	C525.990784,799.722534 529.853821,795.982300 535.033081,797.956482 
	C534.059998,802.584656 534.059998,802.584656 540.917114,804.571533 
	C542.292603,805.939636 542.946716,807.574219 544.878784,807.775513 
	C549.033752,808.208252 549.049011,810.653748 547.423096,813.977234 
	C542.748840,814.550354 538.773499,813.693604 535.524719,810.304199 
	C531.690735,806.304260 521.321045,805.133606 516.440369,807.953430 
	C515.656433,808.406372 515.061768,808.954773 515.302429,809.899536 
	C515.543457,810.845825 516.489441,811.335083 517.225342,811.058289 
	C522.709473,808.995117 526.345154,811.646240 529.658875,815.571716 
	C530.645630,816.740784 532.296448,817.191162 533.794067,816.776733 
	C537.809692,815.665466 541.997498,816.066040 546.315002,815.015869 
	C548.026001,814.093445 549.127319,812.948120 550.575317,811.690796 
	C553.315063,810.184448 556.815735,810.732483 558.378784,807.127686 
	C558.990540,806.568176 559.415344,806.364014 560.229858,806.178345 
	C562.220886,806.203430 563.872681,806.007019 565.797852,806.538635 
	C566.943115,809.456116 569.041565,810.687744 571.949463,811.459229 
	C573.386658,813.050598 573.911499,814.809387 574.923340,816.671692 
	C574.384888,819.729980 572.533264,819.147034 570.875488,818.365723 
	C568.930237,817.449097 566.990173,816.797668 564.411377,817.000122 
	C562.890625,816.409241 563.600342,814.250122 561.947571,814.341858 
	C560.970520,814.396118 561.015625,815.822510 560.218994,816.761414 
	C558.731506,816.739136 557.464844,816.468872 556.194031,816.219727 
	C554.660767,815.919067 552.886597,815.878052 552.519592,817.629333 
	C552.116089,819.554993 553.800415,820.389465 555.452209,820.897278 
	C557.218323,821.440247 559.471619,820.062500 560.910156,822.627808 
	C559.222900,825.013123 556.764648,824.195251 554.389893,824.594910 
	C558.489807,824.585938 562.399597,824.619080 566.067688,827.169983 
	C566.827637,831.535767 567.467957,835.592407 571.935059,838.064575 
	C572.348328,838.520386 572.498108,838.721924 572.817383,839.268188 
	C573.666016,842.479309 573.499939,845.380676 573.173340,848.640747 
	C567.923523,849.222046 562.829956,848.400757 557.856445,850.711365 
	C554.858276,852.104187 551.759338,849.959045 548.380371,849.048706 
	C546.607117,847.664612 544.632751,846.736877 544.993591,844.066772 
	C546.291565,842.364197 548.225403,841.919495 549.615417,840.098999 
	C546.773682,839.068298 544.150208,839.751343 541.660583,839.383057 
	C539.482788,839.060730 536.816895,839.255310 537.154053,835.587280 
	C542.080017,833.273315 547.128906,835.245239 552.110352,833.662231 
	C554.173523,830.774231 557.969482,833.618042 560.069336,830.086304 
	C556.585999,829.108887 553.848572,828.741211 552.166626,833.117126 
	C549.190979,834.248413 547.105530,832.868408 544.852722,831.051270 
	C544.345703,829.977844 544.482544,829.165833 544.413757,828.372803 
	C544.303284,827.099304 543.661865,826.187622 542.327087,826.192261 
	C541.028076,826.196716 541.009216,827.356995 540.850769,828.284729 
	C540.741821,828.922791 540.593567,829.537964 539.970581,830.312500 
	C536.049194,831.203979 532.596863,830.312378 529.146790,829.324524 
	C526.584839,828.590942 524.033447,828.169128 521.543579,830.459473 
	C519.113037,833.266357 515.390625,830.032654 512.711426,833.524231 
	C516.683533,834.952576 518.928467,832.635559 521.576904,830.505127 
	C524.009399,829.438477 525.990173,829.295166 527.460327,831.927979 
	C527.615051,834.178162 523.869385,834.448853 525.421936,836.651489 
	C526.634277,838.371521 528.975098,838.638733 530.897461,838.174805 
	C535.892456,836.969116 539.722412,840.262146 544.126099,841.276245 
	C545.133362,841.508179 545.639282,842.497864 545.080811,843.935120 
	C542.363525,846.229919 539.218750,845.432983 536.435608,846.185242 
	C534.097412,846.817261 531.579407,846.862061 529.288696,848.735596 
	C526.530579,848.995789 524.056274,849.006531 521.582031,848.993042 
	C520.273315,848.985901 518.649048,849.235596 518.067444,847.793030 
	C516.344055,843.518250 513.000244,844.537354 509.386475,844.999146 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M370.680939,800.029846 
	C375.473785,800.168579 379.605652,798.267639 383.978851,797.647827 
	C388.707062,796.977600 392.871490,797.337402 396.695923,799.552124 
	C399.324554,801.074280 402.621216,799.945496 404.910889,802.631470 
	C401.060638,802.303284 398.875641,805.777344 395.351929,806.953796 
	C394.892609,805.148865 394.961212,803.040710 392.173981,803.475220 
	C384.778625,804.627991 377.345123,803.683655 369.468628,804.000000 
	C369.000000,804.000000 368.500000,804.000000 368.250000,804.000000 
	C368.000000,803.250000 368.000000,802.500000 368.000000,801.375000 
	C368.787689,800.686523 369.575378,800.373108 370.680939,800.029846 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M486.321289,811.857178 
	C485.689819,811.880798 485.434235,811.688965 485.116638,811.212219 
	C483.961304,809.822876 482.492737,809.297546 480.907410,808.341614 
	C479.009033,807.614990 477.328949,804.808838 475.940796,808.679810 
	C474.464233,812.671814 471.240814,810.315063 468.433594,810.997864 
	C467.814667,809.190796 468.582306,807.804321 469.855164,806.595032 
	C470.845215,805.654480 470.911377,804.646301 470.004395,803.529419 
	C469.325592,802.693481 467.814728,801.909851 468.721649,800.670166 
	C469.602386,799.466248 471.182800,800.386475 472.426636,800.237427 
	C479.793121,799.355164 483.770630,805.880981 489.727295,808.092712 
	C491.559418,808.773010 490.819092,810.000793 486.321289,811.857178 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M457.789673,803.858276 
	C455.876495,804.028809 454.353210,804.063843 453.406525,802.571350 
	C452.890350,801.757507 452.168396,800.910278 452.610535,799.853882 
	C452.998077,798.927917 453.950806,799.067688 454.751648,799.015442 
	C458.070160,798.798889 461.274109,799.241455 464.587830,800.617615 
	C463.200287,803.610901 460.131561,802.585144 457.789673,803.858276 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M495.772217,786.306519 
	C496.173035,784.630310 495.482605,782.969727 497.648071,782.076416 
	C500.113037,782.256714 501.837524,783.391541 503.793091,784.735840 
	C504.600464,785.682251 504.765167,786.456482 504.244507,787.660522 
	C503.998779,787.997620 503.499390,787.998840 503.249695,787.999390 
	C500.958679,788.000000 498.917358,788.000000 496.438629,788.000000 
	C495.623962,787.611816 495.471771,787.149475 495.772217,786.306519 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M492.389862,817.009644 
	C492.000000,817.000000 491.500183,817.000000 491.250305,817.000000 
	C492.054504,814.195251 497.185852,812.774841 498.986298,814.839722 
	C499.338226,815.243347 499.525696,816.035400 499.400360,816.554504 
	C499.196167,817.400330 498.370361,817.926331 497.538727,817.842834 
	C495.940948,817.682373 494.364838,817.306030 492.389862,817.009644 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M505.666382,804.002319 
	C505.698578,805.760986 509.225739,806.684143 507.200226,808.630432 
	C506.175720,809.614807 504.820282,811.640259 503.073853,810.203796 
	C500.673035,808.229187 502.862610,806.442383 503.933228,804.348022 
	C504.444672,804.002380 504.888947,804.002319 505.666382,804.002319 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M521.191650,800.748169 
	C519.780151,805.597473 516.578064,803.939819 513.350342,803.154785 
	C513.094788,802.106934 513.363037,801.338257 514.682495,801.049805 
	C516.742493,800.672363 518.487427,800.344727 520.616211,800.008545 
	C521.127747,800.166260 521.255554,800.332520 521.191650,800.748169 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M488.970032,792.680908 
	C487.897919,793.307800 486.793488,793.615540 485.345123,793.961670 
	C484.182800,793.008362 483.918671,791.931213 484.748505,790.362061 
	C485.445923,789.999817 485.890594,789.999634 486.668884,789.998535 
	C488.028778,790.473572 488.749847,791.199463 488.970032,792.680908 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M495.707214,785.871338 
	C496.000275,786.444458 496.000549,786.888916 496.001038,787.666687 
	C494.485687,787.906006 493.419678,787.247681 493.041077,785.337402 
	C493.804810,785.247498 494.609619,785.495056 495.707214,785.871338 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M460.375000,811.000000 
	C460.000000,810.555542 460.000000,810.111084 460.000000,809.333313 
	C461.134888,809.056335 462.380646,808.871887 462.936584,810.661560 
	C462.250000,811.000000 461.500000,811.000000 460.375000,811.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M194.437500,588.000000 
	C192.932281,586.685913 190.671860,584.997437 192.865860,583.672058 
	C195.488266,582.088013 199.072037,582.353333 201.828674,583.459778 
	C204.408142,584.495300 201.312653,585.945435 201.069092,587.646606 
	C198.958328,588.000000 196.916672,588.000000 194.437500,588.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M361.000000,769.460938 
	C362.112122,769.301697 363.208344,769.706299 364.340088,769.881165 
	C366.068268,770.148132 367.689331,770.493286 367.885162,772.618652 
	C368.068970,774.613281 367.014465,775.702820 365.088867,776.011841 
	C363.739624,776.228455 362.965485,776.939026 363.206299,778.370972 
	C363.461334,779.887207 364.713287,779.820679 365.870392,780.001587 
	C370.153992,780.671265 374.702515,778.534729 378.895020,781.611450 
	C374.711212,781.999756 370.413940,781.831726 366.136200,782.048828 
	C362.258484,782.245667 360.661835,780.704956 360.967468,776.847290 
	C361.149292,774.552368 361.000275,772.231201 361.000000,769.460938 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M369.000000,791.333313 
	C373.483582,789.439209 378.128235,790.029419 382.713837,790.075439 
	C384.467316,790.093018 386.806793,790.383240 386.662476,792.771118 
	C386.519135,795.142456 384.031036,796.062866 382.229645,795.824402 
	C378.033173,795.268860 373.923981,794.054138 369.389709,793.053223 
	C369.000000,792.555542 369.000000,792.111084 369.000000,791.333313 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M326.153320,678.099609 
	C324.338837,679.224609 322.922058,679.033203 322.474945,677.211365 
	C322.143097,675.859314 323.363312,675.187500 324.587036,675.128906 
	C326.555573,675.034607 327.311279,675.925964 326.153320,678.099609 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M244.047699,687.319580 
	C244.890884,687.095093 245.658859,687.362427 245.952148,688.680420 
	C245.108871,688.904968 244.341125,688.637451 244.047699,687.319580 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M609.003540,798.580566 
	C608.096252,799.380005 607.198059,799.773926 606.287720,800.137451 
	C603.574707,801.221008 600.510193,802.345947 599.248840,798.593506 
	C598.079102,795.114014 599.860474,791.456726 603.315369,791.356140 
	C609.679260,791.170837 615.843689,787.556702 621.961548,789.229553 
	C628.031433,790.889221 633.987915,790.908875 640.531616,791.000000 
	C641.350281,793.965088 640.131226,795.111816 637.069153,795.051575 
	C629.139954,794.895508 621.205566,795.015686 613.273376,794.990967 
	C611.017883,794.983948 608.771301,794.869812 609.003540,798.580566 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M685.425293,776.000244 
	C685.138000,773.904785 686.475830,772.653748 688.224121,771.942810 
	C691.177490,770.741821 693.124939,768.771301 693.778992,765.006592 
	C695.203430,768.598999 697.610718,767.078552 699.725769,767.031921 
	C702.064697,766.980286 704.695679,766.343262 702.528015,770.567566 
	C700.806396,773.922729 702.376282,775.683594 706.629395,774.047852 
	C706.625488,776.255615 704.576477,777.427307 703.094971,776.797241 
	C697.359680,774.358154 691.468018,778.380005 685.425293,776.000244 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M642.466309,790.000000 
	C642.426819,787.607239 644.334473,787.896790 645.918335,788.033142 
	C653.290771,788.667419 659.896118,786.545410 666.040894,782.663147 
	C667.417053,781.793701 668.930969,782.037231 670.409180,782.008667 
	C671.929932,781.979248 673.591431,782.094421 674.232788,780.357056 
	C675.494324,776.939758 678.213257,776.788086 681.582764,777.000000 
	C681.656860,778.604187 681.191101,780.194092 681.002136,781.816223 
	C680.774780,783.767578 679.829590,785.068298 677.886108,784.917908 
	C671.924683,784.456604 666.275208,785.363464 660.898499,788.034058 
	C655.165100,790.881775 648.976929,789.844238 642.466309,790.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M589.609314,837.751770 
	C590.000000,838.000000 590.500000,838.000000 590.750000,838.000000 
	C590.971436,841.585632 592.902466,843.057251 596.322449,843.009094 
	C597.812012,842.988159 599.362854,842.861511 600.779602,843.212097 
	C602.684326,843.683411 604.087585,845.018066 603.808411,847.162231 
	C603.489075,849.614014 601.370728,848.958313 599.846497,848.971802 
	C592.876526,849.033447 585.905640,848.998230 578.466309,848.996460 
	C574.950562,842.982117 576.439087,839.596252 582.878235,838.126160 
	C584.947876,837.653625 587.017212,836.791138 589.609314,837.751770 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M590.000000,837.585266 
	C586.965881,838.505310 584.144592,839.732605 581.038208,840.179260 
	C576.003174,840.903259 578.697021,845.308899 577.998413,848.553589 
	C576.932190,848.995728 575.866882,848.996277 574.401978,848.995972 
	C571.979614,846.137512 573.834167,842.631287 572.221069,839.334106 
	C572.000000,839.000000 571.500000,839.000000 571.250000,839.000000 
	C569.821899,837.255859 570.118652,835.579041 571.539185,833.599976 
	C575.173767,831.066406 578.649475,830.746948 582.642334,832.714539 
	C583.586243,832.747070 584.296082,832.627014 584.736145,832.217102 
	C586.218506,830.836548 587.893799,829.323120 589.880310,830.383850 
	C592.171936,831.607361 589.711609,833.574524 590.008789,835.217468 
	C590.122681,835.847351 590.009705,836.518311 590.000000,837.585266 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M544.552979,844.000000 
	C545.974976,845.445679 546.949890,846.891357 547.961182,848.667297 
	C541.978455,848.997375 535.959412,848.997192 529.472595,848.996033 
	C530.263672,843.007324 535.763367,845.677979 539.185547,844.140259 
	C540.567627,843.519226 542.450806,844.013306 544.552979,844.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M572.000000,839.440063 
	C575.586914,841.397888 573.379028,845.067627 574.001587,848.555725 
	C573.835388,848.996155 573.668274,848.997131 573.250610,848.999023 
	C571.353821,846.104004 572.215149,842.933044 572.000000,839.440063 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M579.775269,856.767883 
	C579.272217,858.325867 578.874146,859.729126 579.000610,861.627808 
	C576.083252,859.570007 576.235718,858.056519 579.775269,856.767883 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M566.339355,828.269043 
	C562.110657,826.477722 558.130310,825.489380 553.921082,826.195557 
	C552.550842,826.425476 550.858948,826.685730 550.694580,825.068054 
	C550.538025,823.527283 552.404602,823.222595 553.664490,823.114197 
	C555.801575,822.930481 557.962524,823.023132 560.557922,822.998535 
	C565.025452,822.998840 569.048523,823.000061 573.534546,823.001831 
	C574.540527,823.688354 574.771729,824.483215 574.751831,825.766724 
	C574.154114,827.637268 573.142395,828.594421 571.271423,829.199463 
	C569.379395,829.474548 567.946960,829.458862 566.339355,828.269043 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M590.374756,804.000000 
	C589.640137,801.324280 590.623901,798.842834 591.138855,796.297302 
	C591.342468,795.290955 591.870972,794.400208 593.064636,794.452087 
	C594.233826,794.502869 595.108582,795.570801 594.817200,796.388062 
	C593.928711,798.880127 600.327759,802.419861 593.365601,803.992798 
	C592.248169,804.000000 591.498840,804.000000 590.374756,804.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M560.399963,817.000000 
	C560.119263,815.520874 558.302917,813.897461 560.259827,812.570557 
	C561.414917,811.787292 562.858643,811.677795 563.969604,812.345276 
	C565.776611,813.431274 564.246704,814.932190 564.047729,816.629333 
	C562.933289,817.000000 561.866638,817.000000 560.399963,817.000000 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M575.354187,816.925659 
	C572.314209,816.782593 571.926147,814.935608 572.000854,812.380310 
	C572.960571,811.009216 574.069824,810.965698 575.632629,811.742432 
	C576.309082,812.117432 576.565735,812.310303 576.885010,812.789307 
	C576.827271,814.371216 577.517883,816.031799 575.354187,816.925659 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M584.944397,822.630127 
	C582.650085,822.385803 580.308044,821.714844 577.417358,822.001831 
	C579.726440,819.168823 582.371277,818.767700 584.944397,822.630127 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M574.314331,826.197693 
	C573.999756,825.250549 573.999512,824.501160 573.998413,823.377075 
	C574.165039,823.002075 574.332520,823.001770 574.751221,823.001953 
	C575.315063,823.789795 575.627686,824.577148 575.970154,825.682251 
	C575.595093,826.312805 575.137939,826.444519 574.314331,826.197693 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M483.853699,821.285889 
	C484.641724,820.848206 485.315460,820.776123 485.914154,820.525146 
	C488.262878,819.540344 490.152954,820.096130 491.456512,822.242126 
	C493.065674,824.891174 489.472504,824.701172 489.066528,826.656189 
	C488.249084,826.998352 487.499420,826.999207 486.374878,827.000000 
	C484.081940,826.047485 481.044342,826.911987 480.776489,823.321960 
	C482.137665,822.969299 483.068237,822.547119 483.853699,821.285889 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M480.654449,822.847046 
	C481.333221,825.728760 484.334839,824.935730 485.787781,826.733398 
	C482.627899,827.000000 479.255798,827.000000 475.441833,827.000000 
	C474.761078,825.879272 474.522217,824.758484 474.141663,823.318848 
	C476.025818,821.306702 478.162415,821.898499 480.654449,822.847046 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M471.585815,831.000000 
	C471.740906,831.496033 471.481842,831.992065 471.111389,832.745239 
	C469.840637,835.057495 467.912811,835.754517 465.362091,835.995422 
	C465.204834,833.781006 462.723389,833.345093 462.057861,831.332764 
	C465.057556,832.332703 468.114319,830.569092 471.585815,831.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M466.177307,826.234497 
	C466.900330,824.987244 467.522064,825.223206 467.963623,826.713745 
	C467.451538,826.821350 466.903076,826.645142 466.177307,826.234497 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M462.294250,826.971436 
	C462.194366,826.115784 462.180084,824.992859 463.821198,825.788147 
	C463.529510,826.315063 463.058990,826.630188 462.294250,826.971436 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M476.422180,809.004028 
	C474.765076,808.870239 473.142120,809.174011 473.263397,807.169556 
	C473.343628,805.842651 474.378723,805.182922 475.606567,805.110168 
	C477.672272,804.987854 479.976715,804.458313 480.943695,807.628113 
	C481.128571,808.169556 481.255890,808.336670 481.192169,808.754272 
	C479.613495,809.004272 478.225861,809.003723 476.422180,809.004028 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M481.166138,809.304810 
	C481.001129,808.837830 481.001190,808.670715 481.001221,808.253052 
	C482.769531,807.970581 484.393341,808.184875 484.952576,810.646851 
	C483.588409,811.031006 482.219635,810.949402 481.166138,809.304810 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M410.338989,851.960754 
	C410.001251,851.251221 410.000641,850.500000 410.000000,849.373169 
	C410.095398,848.104614 410.365784,847.337830 411.683868,847.049805 
	C412.176727,847.549072 412.354675,848.098083 412.767639,848.824829 
	C412.904633,850.515076 412.246277,851.580750 410.338989,851.960754 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M573.746704,826.183105 
	C574.444458,826.000000 574.888916,826.000000 575.666687,826.000000 
	C576.310547,826.118530 576.566223,826.310242 576.883545,826.787598 
	C577.243103,829.040100 578.830933,829.589661 580.450256,829.894897 
	C581.877869,830.163940 582.978577,830.552856 582.986450,832.625610 
	C579.592773,833.162964 576.111023,832.468201 572.406250,833.942139 
	C570.761108,834.864563 569.457458,835.005737 568.671326,833.584351 
	C567.485474,831.440369 568.711731,829.900879 570.676270,828.299377 
	C572.199463,828.017578 572.846008,827.191284 573.746704,826.183105 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M157.689697,595.026550 
	C159.726990,595.291565 161.018692,595.982056 160.720383,597.867249 
	C160.428665,599.710815 158.884521,600.017395 157.342865,599.970276 
	C154.823471,599.893127 150.738678,600.694580 150.650772,597.826843 
	C150.547653,594.463806 154.684769,595.278931 157.689697,595.026550 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M166.283661,577.011047 
	C164.942169,576.895996 163.572617,577.124512 163.578430,575.814087 
	C163.584702,574.396912 164.879486,573.907776 166.093124,574.269287 
	C169.330048,575.233337 172.557983,575.296143 175.817551,574.549194 
	C176.927292,574.294861 178.242111,573.632324 178.651688,575.268066 
	C179.059570,576.896973 177.861374,577.672607 176.430801,577.880920 
	C173.126312,578.362305 169.890991,577.823730 166.283661,577.011047 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M169.958801,587.344849 
	C171.424377,587.305542 172.848282,587.608704 174.635773,587.958008 
	C176.634140,590.603699 175.972748,592.703979 173.300461,594.713806 
	C172.555084,594.995728 172.110916,594.996338 171.333618,594.996033 
	C169.739487,592.692566 166.864258,590.629333 169.958801,587.344849 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M146.103302,561.389343 
	C150.687988,561.001648 155.377533,561.000793 160.533127,561.000000 
	C156.241180,564.542603 151.241180,563.473816 146.103302,561.389343 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M148.416656,587.116760 
	C149.875824,587.121094 151.204971,586.731140 151.631485,588.216858 
	C151.911148,589.191040 151.232498,589.753967 150.350266,589.860413 
	C149.090118,590.012390 147.539902,590.468323 146.723724,589.280396 
	C146.020599,588.257019 147.113937,587.596375 148.416656,587.116760 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M140.878113,569.992432 
	C139.537567,569.925842 138.493454,570.187195 137.440506,568.988708 
	C139.506989,567.742554 141.481369,567.058044 143.163788,569.141174 
	C143.573410,569.648376 142.010330,569.855347 140.878113,569.992432 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M202.316177,560.761353 
	C200.664124,558.727844 200.233124,556.936035 202.941330,555.929260 
	C205.580917,554.947998 208.250534,554.035339 210.945236,553.218811 
	C212.751373,552.671509 214.215530,553.430420 215.242188,554.940979 
	C216.262329,556.441956 216.356445,558.138367 215.431519,559.639465 
	C214.352982,561.389893 212.597717,561.151917 210.990723,560.476746 
	C208.135391,559.277283 205.454681,560.654907 202.316177,560.761353 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M200.471527,540.750488 
	C198.616074,539.735718 196.480164,540.017700 195.475403,537.789246 
	C199.980087,535.804321 204.661240,535.918396 209.336990,536.018372 
	C211.104935,536.056213 212.835007,536.432861 212.746887,538.630920 
	C212.672745,540.480652 211.273483,541.678833 209.522781,541.839172 
	C206.598145,542.106812 203.590851,542.502380 200.471527,540.750488 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M216.772125,547.166870 
	C215.447525,545.666687 213.061523,544.605774 215.072418,542.577026 
	C216.942322,540.690613 218.271210,537.639832 221.702560,538.187927 
	C223.775467,538.518982 224.580536,540.418579 223.717087,541.910034 
	C222.248611,544.446655 220.833374,547.492249 216.772125,547.166870 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M173.383286,594.986816 
	C173.150238,592.753662 175.589722,591.246643 174.998703,588.385498 
	C178.088531,591.693787 177.834045,593.134460 173.383286,594.986816 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M486.360352,475.109924 
	C485.865387,474.988373 485.696259,474.991333 485.273438,474.996277 
	C484.711517,474.697723 484.411285,474.389374 483.919128,473.819885 
	C483.156586,472.636505 482.469818,471.823883 481.261841,471.052979 
	C480.826385,470.706848 480.659210,470.557983 480.263855,470.161102 
	C479.178894,468.540375 478.090179,467.473969 476.105652,467.047363 
	C475.392029,466.703796 475.019379,466.449677 474.442017,465.910217 
	C473.686218,464.939484 473.135620,464.241577 471.981873,463.676117 
	C468.394257,461.476776 464.728241,460.294434 461.237000,458.738129 
	C457.898529,457.249969 457.364197,454.999847 458.773468,451.862671 
	C462.410187,443.767120 473.025116,439.074951 481.885986,441.334198 
	C496.777618,445.131134 507.012604,455.171051 516.158508,466.613861 
	C524.807068,477.434448 529.676941,490.226074 534.593262,503.370850 
	C535.261841,507.150879 536.711487,509.797424 540.073608,511.448944 
	C542.503296,512.642456 544.380493,514.657471 543.684937,517.830383 
	C543.262024,519.759766 544.947388,520.338440 545.863525,521.402344 
	C548.109985,524.010986 548.732544,526.645386 545.905212,529.223145 
	C543.735840,531.201050 541.277344,533.648560 543.116821,536.378784 
	C544.932068,539.073181 546.415222,543.083801 551.024414,542.660278 
	C551.840820,542.585266 552.654846,542.888184 553.712646,543.457886 
	C554.889526,544.822754 554.410095,546.078003 554.191040,547.671753 
	C553.984070,548.264832 553.876648,548.491760 553.544922,549.018433 
	C551.650452,550.272034 550.106201,551.400940 548.341858,552.826904 
	C543.860596,553.489258 539.252319,555.089844 537.866699,548.823730 
	C532.660583,547.134216 528.331421,543.687683 523.372559,541.563293 
	C521.123291,540.599670 518.466003,541.147705 515.774048,542.302612 
	C515.189087,542.246155 514.963623,542.158264 514.502319,541.792358 
	C513.530823,540.249756 513.599731,538.685547 512.672607,537.106384 
	C512.628296,536.514160 512.668823,536.276306 512.940613,535.762817 
	C517.570557,534.101624 522.223267,533.700928 526.518494,531.839600 
	C528.409851,531.020020 529.437622,530.458557 527.904846,528.398621 
	C526.287109,526.224548 525.957825,523.003235 522.556458,522.312988 
	C521.363220,522.070862 519.650269,521.784180 520.082336,519.912415 
	C520.414368,518.473999 521.811218,518.033630 523.135254,517.696350 
	C524.379761,517.379395 525.718445,517.685730 526.834290,517.184387 
	C524.136902,517.722717 521.317566,517.533447 518.518005,517.371277 
	C516.901001,517.277588 514.945801,517.214172 514.629272,515.077637 
	C514.363708,513.284973 515.806885,512.516785 517.255981,511.936615 
	C517.841431,511.702209 518.552795,511.657959 518.999695,510.556732 
	C514.122131,506.846375 509.191986,512.014587 504.049500,510.518250 
	C501.944550,509.455048 500.967590,508.058685 501.299194,505.632782 
	C504.727905,502.327667 509.024170,503.929260 512.543335,502.611877 
	C509.810516,499.479767 509.810516,499.479767 499.787415,499.345154 
	C499.272064,499.065887 499.080658,498.927155 498.646484,498.528900 
	C497.753113,497.292755 497.276520,496.203735 496.309631,495.005432 
	C496.029785,494.480499 495.934387,494.262299 495.748108,493.700439 
	C495.503876,492.544281 495.527527,491.703949 495.172272,490.923187 
	C492.336670,484.690979 493.027405,483.637848 500.065674,483.452362 
	C502.055756,483.399933 504.055908,483.512390 506.033142,482.873901 
	C503.252136,482.468231 500.442780,482.603149 497.645111,482.548004 
	C495.812103,482.511871 493.932037,482.588074 492.239960,481.807281 
	C490.452728,480.982635 488.127045,480.076569 488.748993,477.652466 
	C489.312653,475.455444 491.584717,475.695160 493.404663,476.061615 
	C494.925873,476.367920 496.384888,475.663605 497.467987,476.007294 
	C494.120422,476.110901 490.419830,475.432587 486.360352,475.109924 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M534.186401,503.626709 
	C531.950134,502.635559 531.902832,500.639587 531.336670,498.954193 
	C524.558228,478.774567 513.527283,461.616119 496.072723,448.955414 
	C491.261444,445.465546 485.848572,443.371368 480.151794,442.152924 
	C472.081573,440.426880 462.605316,445.600250 459.203430,453.146271 
	C458.169708,455.439301 458.622559,456.677429 461.173004,457.610748 
	C465.053528,459.030792 468.754333,460.942108 472.836517,462.813690 
	C473.406311,463.372742 473.553650,463.801147 473.454834,464.602997 
	C469.809662,466.755829 468.199402,463.098633 465.294006,462.104309 
	C464.180969,461.748779 463.388733,461.501709 462.308105,461.127319 
	C461.137054,459.029449 458.767242,459.720520 457.240723,458.233643 
	C456.327209,456.579926 454.816040,455.545349 455.027100,453.370239 
	C455.121002,450.956757 456.385223,449.368164 457.370056,447.724335 
	C458.927307,445.125153 460.448975,442.803131 457.760803,439.891449 
	C456.648438,438.686615 457.301086,437.110809 459.124695,436.027527 
	C460.990875,435.457550 462.508545,435.504395 464.363586,436.130066 
	C465.119751,436.576935 465.468719,436.925476 465.905579,437.660339 
	C466.404846,438.693909 466.541931,439.674683 468.059113,439.113953 
	C469.678802,438.411865 471.034546,437.994141 472.790161,437.632050 
	C477.032867,435.920929 480.231323,436.194092 484.230103,438.573822 
	C489.099457,441.471558 495.226654,442.004120 499.919098,445.857788 
	C501.662537,447.289581 504.432709,444.670746 503.634125,441.853516 
	C502.306885,437.171326 502.768555,431.358490 496.238220,429.433563 
	C493.788422,428.711426 493.217560,426.013733 495.585236,424.338043 
	C498.419067,422.332428 498.644348,420.360291 496.897461,417.631378 
	C495.175751,414.941895 495.416565,412.253113 497.401886,409.760254 
	C498.892365,407.888824 499.518707,405.649384 500.310211,403.455078 
	C502.842743,396.434296 506.653564,390.325256 513.064575,386.148865 
	C513.906738,385.600281 515.080750,385.032867 515.205688,384.142273 
	C515.990234,378.551422 519.965881,378.979523 524.092590,378.846283 
	C528.067200,378.717896 531.880249,377.179932 535.800415,376.403931 
	C544.820862,374.618286 553.625000,376.115997 562.563232,377.273102 
	C571.938660,378.486847 580.399841,382.411774 589.310669,384.967682 
	C596.320007,386.978149 601.984863,391.493896 607.725647,395.769440 
	C608.948303,396.680023 609.845642,397.897949 609.695007,399.941406 
	C608.184265,402.483673 606.585083,401.389130 604.663635,400.411072 
	C604.114807,400.147034 603.902466,400.031250 603.392944,399.715332 
	C598.119690,395.797333 592.977539,392.372589 586.658325,390.334167 
	C585.828369,390.136444 585.367188,389.964844 584.581177,389.583893 
	C576.736450,385.630280 568.567139,384.245605 560.391663,383.041473 
	C551.219116,381.690521 542.026428,380.527252 532.753357,382.610870 
	C524.408203,384.485962 517.322510,388.356567 511.561646,395.229736 
	C511.227386,395.762451 511.092316,395.972595 510.720917,396.467682 
	C504.310150,402.999146 503.128113,411.219391 504.076782,419.004974 
	C506.458191,438.548645 514.563843,455.634674 529.404053,469.063873 
	C537.292969,476.202759 537.704468,476.094727 543.090149,467.299927 
	C543.698914,466.305817 544.273010,465.301483 544.843201,463.909058 
	C545.760193,462.308350 546.915527,461.288757 547.773987,459.672882 
	C548.050537,459.233398 548.167725,459.062622 548.474976,458.648376 
	C549.227356,457.807648 549.720276,457.161652 550.218628,456.129333 
	C550.499084,455.667755 550.623108,455.486786 550.964233,455.051605 
	C551.530945,454.443085 551.841370,454.054596 552.163391,453.675903 
	C556.121155,449.021088 556.121643,449.021515 550.920654,444.709961 
	C549.676392,443.364777 548.615173,442.343842 547.337158,440.989868 
	C547.001648,440.418396 546.891174,440.171875 546.683044,439.525208 
	C541.732117,422.141907 547.608826,414.459595 565.420105,414.311035 
	C570.769287,414.266388 575.983093,414.933655 581.105042,416.377899 
	C583.485718,417.049164 585.941345,417.705811 587.156128,420.667236 
	C585.767883,422.501099 584.006409,422.553467 581.802368,422.510498 
	C580.544556,422.412079 579.705078,422.428070 578.505737,422.119263 
	C571.773376,418.221802 564.934875,419.283112 557.845825,420.199158 
	C555.468933,421.575226 553.344543,422.715393 551.880920,425.145935 
	C550.803833,426.601013 550.393616,427.947418 550.432495,429.751160 
	C550.356995,431.391663 550.820496,432.608459 551.261719,434.161926 
	C551.957214,436.839081 553.307861,438.808319 554.908569,441.006775 
	C560.262695,443.843170 563.488647,443.546997 567.508850,440.047089 
	C568.984009,438.762848 570.562805,437.591980 572.125977,435.891846 
	C574.826172,433.623138 577.343018,431.667603 579.977661,429.300751 
	C580.600647,428.743408 581.006348,428.488647 581.786743,428.159851 
	C582.872131,427.906799 583.594971,427.965057 584.622864,428.375793 
	C585.270691,428.902924 585.492676,429.311249 585.560974,430.133667 
	C585.313049,431.376373 584.684448,432.023438 583.924927,432.956573 
	C583.148254,433.782074 582.497803,434.285858 581.763733,435.135162 
	C581.285461,435.655121 580.991455,435.931915 580.448120,436.385864 
	C579.721497,436.933380 579.200623,437.245056 578.467834,437.785950 
	C577.559204,438.460205 576.991516,439.048615 576.285889,439.951843 
	C575.950195,440.374573 575.811829,440.542603 575.455383,440.959473 
	C575.085510,441.372040 574.933777,441.535767 574.546875,441.941711 
	C568.370300,447.581848 563.209778,453.677917 558.166870,460.331543 
	C554.265503,466.585175 549.718506,472.034698 546.679932,478.808075 
	C546.405457,479.276672 546.304993,479.467163 546.029297,479.923096 
	C545.609070,480.521851 545.362061,480.853607 544.956665,481.439789 
	C544.406860,482.391266 544.093384,483.120728 543.782654,484.186157 
	C543.480530,485.081787 543.244934,485.671295 542.842896,486.548340 
	C541.166687,488.836243 540.429382,491.150848 539.846191,493.908325 
	C537.424255,497.043671 537.607361,501.221649 534.186401,503.626709 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M632.611633,612.986938 
	C635.372742,612.923157 637.733521,612.896667 640.530029,612.794922 
	C644.433960,613.349609 645.794800,615.647705 646.289612,618.945312 
	C647.055725,624.051147 646.637878,624.473999 641.537659,622.873962 
	C637.434692,621.586792 635.763489,622.742371 635.989685,626.914124 
	C636.153931,629.943054 637.072144,632.801208 638.900940,635.267761 
	C640.958008,638.042297 643.420532,639.078308 646.598633,636.895081 
	C648.329773,635.705872 650.440308,633.730774 651.964661,636.739197 
	C653.294739,639.364014 655.482361,642.284485 652.357361,645.330811 
	C649.102722,648.503540 643.068665,649.006714 638.423279,646.265869 
	C636.883972,645.357666 635.482605,644.215698 633.544922,642.846130 
	C633.076233,648.212463 629.582642,650.253784 625.312622,650.830322 
	C616.887939,651.967834 612.277954,646.595520 608.343750,639.421204 
	C609.321533,650.840820 606.492676,654.004028 595.388062,654.397461 
	C594.513733,654.657898 594.055054,654.759521 593.237244,654.708130 
	C589.334778,653.202393 586.389709,651.095215 584.894897,647.349121 
	C584.810364,650.405823 583.990417,653.227539 582.304688,656.152893 
	C581.826660,656.823364 581.505554,657.169312 580.878235,657.715637 
	C579.768921,658.428406 579.001160,659.009949 577.860779,659.688782 
	C576.532532,660.016174 575.533691,659.982727 574.156738,659.997437 
	C570.492981,659.959167 567.354675,659.808167 564.126953,658.509277 
	C560.610657,657.094299 559.526611,654.507874 558.814697,651.422729 
	C558.361145,649.457397 558.460632,647.334229 560.036560,645.872559 
	C561.786499,644.249451 563.732178,645.156738 565.558716,646.039185 
	C566.003784,646.254150 566.434937,646.565918 566.905945,646.650757 
	C568.737305,646.980408 571.032043,648.474304 572.115417,645.893860 
	C573.188904,643.336914 570.575378,642.610352 569.017212,641.615784 
	C567.641907,640.738037 565.985413,640.341797 564.281921,639.223267 
	C563.991638,638.967468 563.754883,638.803528 563.611938,638.821289 
	C555.338806,635.458618 551.235352,629.638000 551.954773,620.577148 
	C552.314331,616.048218 554.450073,613.126343 558.557617,611.540771 
	C564.996033,609.055359 572.603394,611.292603 574.559387,616.186035 
	C576.769836,621.716064 574.562622,624.681152 568.719116,623.884094 
	C566.692993,623.607666 564.734741,623.054871 563.095337,625.471924 
	C559.846863,627.665161 558.950012,625.428894 558.345337,623.283020 
	C557.551697,620.466736 558.796387,618.407471 561.409912,617.228882 
	C563.798950,616.151550 566.239075,616.992004 569.617310,617.700500 
	C564.369873,614.961426 561.069214,615.322144 558.497681,618.420227 
	C555.839050,621.623230 556.320190,624.787781 558.661560,627.981201 
	C561.471252,631.813416 564.451355,635.090881 569.629211,635.861084 
	C574.624268,636.604187 576.617065,640.707092 577.583374,645.080994 
	C578.400635,648.780701 575.169800,652.097473 571.123840,652.271729 
	C569.265320,652.351685 567.538757,651.653015 565.646973,651.242737 
	C567.362793,654.246704 571.590332,655.648254 574.651306,654.356018 
	C577.892151,652.987793 580.166931,647.977478 579.214600,644.851929 
	C577.435364,639.012146 572.706055,636.318481 567.823669,633.697510 
	C565.216980,632.298218 562.208679,631.164978 562.731689,627.013184 
	C567.816833,626.059692 571.255249,629.246887 575.096558,631.339294 
	C576.690002,632.207275 577.920166,633.592529 579.583801,634.797546 
	C578.618286,631.481018 577.641357,628.397034 576.727783,625.293823 
	C574.901672,619.091492 576.868774,616.944031 583.357056,617.936218 
	C584.179871,618.062073 584.999146,618.174622 585.738770,619.120239 
	C585.838196,619.457947 585.814026,619.756409 585.898438,619.880005 
	C589.252441,625.456299 589.531067,631.911682 591.882751,637.677612 
	C592.246460,638.569275 592.311279,639.601746 592.751709,640.498230 
	C593.423401,641.865540 594.084351,643.214111 596.040649,642.758850 
	C598.111328,642.277039 597.844177,640.789734 597.674683,639.261414 
	C597.273315,635.641113 596.351318,632.169128 594.916077,628.822083 
	C593.946960,626.562012 592.733093,624.359558 592.935059,621.403687 
	C592.931519,620.268311 592.956543,619.492859 592.941589,618.331055 
	C594.882080,615.145264 597.514099,615.612854 600.576538,616.780762 
	C600.926392,617.077698 601.002441,617.005859 600.963257,617.038818 
	C603.297119,619.270203 603.901917,622.151123 604.070862,625.047302 
	C604.460815,617.951599 607.956299,614.370483 614.053406,615.466248 
	C615.238525,618.132568 613.303223,618.995605 611.876831,620.017090 
	C608.902100,622.147461 608.149841,624.855408 609.127319,628.330872 
	C610.527283,633.307983 611.730347,638.335205 615.248718,642.384033 
	C620.173584,648.051331 620.761536,648.159058 627.603516,644.186768 
	C625.441406,644.922668 623.471863,646.911621 620.983459,645.573364 
	C618.731934,644.362366 616.750854,642.888184 616.563110,639.725586 
	C618.939575,637.199036 621.702515,640.953918 624.244873,638.614746 
	C625.046204,638.069153 625.617859,637.766785 626.461121,637.285706 
	C627.779358,636.604370 628.502441,635.965088 627.511963,634.425537 
	C627.164368,633.707825 627.008057,633.276306 626.800476,632.487915 
	C626.680481,631.905212 626.643982,631.667908 626.561707,631.069580 
	C626.491394,630.213013 626.486572,629.717285 626.456665,628.839966 
	C624.458740,619.996338 625.957153,616.005798 632.611633,612.986938 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M492.010498,542.396118 
	C495.696930,542.508545 499.217560,541.169617 502.847168,540.961304 
	C504.509979,540.865845 505.878632,540.210022 505.765778,538.311890 
	C505.673767,536.764160 504.980713,535.284851 503.196747,535.093811 
	C497.604889,534.494873 492.888428,531.837341 488.179016,529.060608 
	C487.344086,528.568359 486.153809,528.590149 486.244720,527.272827 
	C486.339355,525.901611 487.312988,525.283386 488.572418,525.091614 
	C490.887756,524.738953 492.875397,525.585999 494.505798,527.098022 
	C495.939240,528.427429 497.450867,529.142334 499.425812,528.999207 
	C501.330688,528.861145 502.550262,527.965515 501.446777,526.186646 
	C499.749542,523.450623 499.303284,520.383850 498.293182,517.461365 
	C497.201477,514.302795 495.159180,511.884521 491.568970,510.944244 
	C488.442932,510.125610 488.234863,507.735474 490.082794,505.575348 
	C492.788910,502.411987 492.760254,499.017456 490.806335,495.927795 
	C488.908417,492.926636 489.347687,490.745697 491.938232,488.204895 
	C495.201569,488.415253 495.295654,491.058014 496.355774,493.346985 
	C496.459320,494.619446 496.636688,495.490601 497.735352,496.316406 
	C498.216400,496.979126 498.394836,497.414551 498.658325,498.207581 
	C502.058563,508.092163 505.875671,517.438171 509.403748,527.272827 
	C510.287842,528.889587 510.547546,530.338867 511.101379,532.069580 
	C511.939880,534.124695 511.507446,536.250427 513.338013,537.925781 
	C513.689148,538.422180 513.781311,538.654846 513.919922,539.264404 
	C514.705200,543.415344 516.729553,546.799133 517.237488,550.660645 
	C517.445679,552.243042 518.261841,553.755005 517.291382,555.694946 
	C513.891724,555.991272 510.745758,555.998962 507.600220,555.968750 
	C506.595825,555.959106 505.490875,555.864563 505.334320,554.592834 
	C505.211090,553.591797 506.017151,553.207031 506.886414,553.029785 
	C507.909729,552.821228 508.870209,552.495361 508.741791,551.187744 
	C508.606476,549.810059 507.578125,549.183838 506.387054,549.001221 
	C502.656281,548.429138 499.554047,545.699707 495.571838,545.965088 
	C493.425690,546.108032 492.102722,545.082825 492.010498,542.396118 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M562.462280,626.173950 
	C561.777710,621.015564 564.493103,620.952148 567.919617,622.053589 
	C570.014587,622.726990 572.275330,624.739929 573.948181,621.690552 
	C575.406860,619.031555 573.299194,613.995422 570.505615,612.793579 
	C564.308777,610.127686 556.920837,611.823059 553.909973,616.602173 
	C551.029541,621.173950 553.274414,630.411438 558.341553,634.631348 
	C559.869629,635.903931 561.507446,637.044739 563.281982,638.542480 
	C557.452942,638.406250 551.448364,632.130188 550.953064,626.329834 
	C549.800415,612.831970 555.715210,607.000610 570.353394,610.590332 
	C572.742249,611.176086 575.192261,611.038513 575.275269,614.713379 
	C575.327820,617.038086 577.552979,617.252014 579.500183,616.655273 
	C582.116089,615.853516 584.314026,616.143860 585.839539,619.062378 
	C585.365662,619.299500 584.904297,619.065918 584.418945,618.993896 
	C576.937073,617.883057 576.096313,618.905212 578.422974,626.113708 
	C579.681030,630.011536 580.855713,633.936279 581.596619,638.287170 
	C576.755249,632.548401 570.020874,630.142944 563.456909,626.806396 
	C562.909302,626.635010 562.708862,626.562622 562.462280,626.173950 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M459.245667,436.658081 
	C458.160126,437.751892 457.655273,439.016693 458.669891,439.472107 
	C463.616821,441.692566 461.381500,444.416168 459.324280,447.050720 
	C457.928772,448.837799 457.932526,451.520935 455.328247,452.856445 
	C453.644928,452.965210 452.121521,453.089844 451.791962,454.631165 
	C450.560272,460.392029 446.284363,460.264771 441.156921,459.752319 
	C438.823669,455.441315 440.947723,453.183716 444.348480,451.680573 
	C447.606110,450.240692 450.424072,448.383453 452.528656,445.385284 
	C454.309174,442.848816 454.032257,441.203217 451.181000,439.930817 
	C449.037140,438.974060 446.806458,438.355164 444.533539,437.823822 
	C442.822784,437.423889 442.009613,436.293976 442.233246,434.526855 
	C442.438354,432.906189 443.571350,432.269073 445.033722,432.247986 
	C448.689331,432.195282 452.293396,431.146393 456.370605,431.767090 
	C458.825958,432.622528 460.165344,433.847504 459.245667,436.658081 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M429.578888,480.925110 
	C429.423035,479.007324 429.289246,477.403107 429.091187,475.399841 
	C429.010895,474.556244 428.981873,474.112579 428.896118,473.339233 
	C428.812256,471.602570 428.773834,470.196289 428.888367,468.396027 
	C431.820312,467.905609 434.566711,468.196259 437.295654,466.947968 
	C440.667053,465.405670 442.332550,467.596466 441.528778,470.466095 
	C440.598724,473.786530 441.887665,475.205872 444.241638,476.957764 
	C446.671051,478.765808 446.570953,480.581055 443.443665,482.081451 
	C439.715881,483.869904 435.881256,484.205994 431.444275,483.997711 
	C430.099823,483.297607 430.018646,482.183044 429.578888,480.925110 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M597.790894,596.750488 
	C602.825439,600.157227 608.461670,599.250244 613.731506,599.872009 
	C618.450317,600.428772 623.286926,599.986816 628.540649,599.987915 
	C630.768982,601.943176 630.039795,603.987976 629.011536,605.865112 
	C628.218750,607.312439 627.630920,608.762939 627.210327,610.687439 
	C622.927307,609.521912 618.000916,610.170288 615.420593,605.060242 
	C614.744812,603.721924 610.624329,601.146362 608.818909,605.764771 
	C608.236084,607.255737 606.936279,607.088684 605.960144,606.284912 
	C602.597107,603.515686 599.325623,600.635254 596.000488,597.411865 
	C596.120850,596.885376 596.259888,596.744263 596.687073,596.530029 
	C597.170288,596.492249 597.365295,596.527588 597.790894,596.750488 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M633.015991,612.699829 
	C626.928833,616.509766 625.565125,621.764160 627.466919,628.606201 
	C625.687317,630.231323 623.914307,629.483276 622.302551,628.914001 
	C618.983459,627.741699 617.242615,628.277710 617.886230,632.240662 
	C618.043518,633.208801 617.895508,634.181885 616.976807,635.225342 
	C611.745239,633.701843 612.748169,629.927979 613.678711,625.884766 
	C614.032043,625.313293 614.197205,625.101257 614.675842,624.638977 
	C616.169250,623.724731 617.468079,623.662048 619.143921,623.641357 
	C619.983459,623.662415 620.452087,623.705322 621.240234,623.812012 
	C622.135986,623.891602 622.726440,624.030396 623.302246,623.299927 
	C623.571411,621.983948 623.489441,620.967468 623.026428,619.669800 
	C622.243774,616.682068 619.988037,617.317566 617.825684,617.047119 
	C616.460815,616.789917 615.384766,616.719360 614.218628,616.013977 
	C607.738403,615.837097 605.618591,618.504395 604.614319,628.643250 
	C603.191162,624.363464 602.104431,621.095398 600.970825,617.449524 
	C603.097595,617.528992 605.355286,618.312012 606.856567,615.281799 
	C608.580139,612.779785 610.788696,612.296143 613.662903,612.828552 
	C614.996094,613.016296 616.037354,612.815979 616.943909,613.079224 
	C622.196655,614.604553 627.325195,614.890381 632.624329,611.972534 
	C633.000000,611.950500 633.020020,612.449890 633.015991,612.699829 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M496.214966,494.269653 
	C494.775024,492.209961 494.564178,489.836792 492.573059,488.130615 
	C491.368835,483.325897 487.745819,480.019562 486.137177,475.326447 
	C492.242218,472.510498 498.123657,476.146729 504.270325,475.878845 
	C499.438934,477.142700 494.572021,477.515930 489.110260,477.092590 
	C490.834167,480.222504 492.918091,481.048157 495.573700,481.010712 
	C499.565643,480.954437 503.559418,480.971100 507.551483,481.026459 
	C508.627899,481.041382 510.231262,480.603058 510.398468,482.201172 
	C510.537140,483.526428 509.093079,484.002502 508.024567,484.407074 
	C504.966064,485.565277 501.755737,485.025360 498.634613,484.934601 
	C494.456146,484.812988 493.207764,485.928925 496.039459,489.536163 
	C496.929199,490.669525 497.041748,491.960419 496.961212,493.705322 
	C496.763428,494.239502 496.595459,494.379456 496.214966,494.269653 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M464.973877,462.374756 
	C468.609894,460.021912 469.709106,464.801849 472.947083,464.839661 
	C473.670197,464.567139 474.066589,464.465576 474.759399,464.740265 
	C475.271362,465.230103 475.529083,465.524353 476.093658,465.846741 
	C477.687805,467.154572 479.281921,468.077209 480.788513,469.625671 
	C481.057037,469.837128 481.100189,469.967316 481.162903,469.939514 
	C481.823212,470.866974 482.669495,471.619812 483.476501,472.827057 
	C483.669220,473.599060 483.623047,474.044830 483.233765,474.737610 
	C479.730957,474.990753 476.424622,475.230713 473.170258,474.922333 
	C466.460754,474.286530 463.648651,469.953430 464.973877,462.374756 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M492.000427,541.593567 
	C488.901581,542.185547 486.058533,541.902100 484.188660,538.852966 
	C482.982330,536.885864 480.859314,536.984497 478.828186,536.991943 
	C472.186920,537.016418 465.545410,537.005371 458.904175,536.975403 
	C457.956909,536.971191 456.708649,537.119690 456.610840,535.740540 
	C456.545532,534.820068 457.465179,534.117432 458.240082,534.190002 
	C468.198395,535.121826 478.293488,530.717346 488.196655,535.115601 
	C491.078674,536.395569 492.421265,538.101318 492.000427,541.593567 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M465.110992,553.000000 
	C461.534912,552.825989 458.290009,553.657593 455.471436,551.647339 
	C454.626099,551.044495 453.927307,550.288147 454.333679,549.167847 
	C454.723511,548.093018 455.608002,548.612061 456.405151,548.625732 
	C465.663483,548.785950 474.923553,548.848999 484.181183,549.037720 
	C485.699402,549.068665 488.188446,548.168274 488.476990,550.221985 
	C488.851013,552.884460 485.758484,552.450806 484.424744,552.540344 
	C478.165527,552.960693 471.873535,552.893311 465.110992,553.000000 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M564.053528,639.315369 
	C567.033997,639.325806 569.555359,640.964417 572.144470,642.382690 
	C573.784790,643.281189 574.398987,644.917969 573.745056,646.630188 
	C573.065063,648.410889 571.401855,648.988342 569.650818,648.473877 
	C567.604980,647.872681 565.656494,646.945923 563.650940,646.200562 
	C561.123962,645.261230 559.941223,646.215149 559.766479,648.862976 
	C559.407471,654.300781 561.320312,656.859375 567.362732,658.430054 
	C569.258545,658.922791 571.266174,658.985107 573.635193,659.363159 
	C574.340576,659.782349 574.474304,660.138550 574.215942,660.776978 
	C563.419434,661.017944 559.896240,659.060059 557.635254,652.473450 
	C556.514160,649.207825 557.459351,646.320007 559.275269,644.085571 
	C561.134949,641.797424 563.464539,644.338501 565.573914,644.822266 
	C566.208374,644.967773 566.764709,645.433838 567.396423,645.615540 
	C568.478882,645.926758 569.869019,646.525146 570.398743,645.063782 
	C570.881348,643.732239 569.568665,642.999512 568.590881,642.445435 
	C567.058533,641.577087 564.935852,641.684082 564.053528,639.315369 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M592.656982,621.312317 
	C595.659729,626.540771 597.182434,632.472229 598.907349,638.322937 
	C599.470154,640.231812 600.898071,642.723450 597.764404,643.775635 
	C594.673035,644.813660 592.489441,643.229797 591.600403,640.356812 
	C589.596802,633.881897 587.847107,627.328369 585.988525,620.405273 
	C588.382568,620.123901 588.673340,621.847351 589.165955,623.744507 
	C590.720581,629.731812 591.901367,635.864014 595.113708,642.543884 
	C596.751343,637.228699 595.727844,633.354736 594.035339,629.602661 
	C592.873047,627.025879 591.368286,624.579224 592.656982,621.312317 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M480.906067,609.670166 
	C477.223602,609.453003 476.860596,605.779358 475.300781,603.506104 
	C466.188263,590.225708 458.078156,576.344421 452.183350,560.922302 
	C455.852203,564.766541 457.363922,570.125000 459.935944,574.911072 
	C466.307220,586.766968 473.329285,598.209167 480.906067,609.670166 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M548.207214,575.251831 
	C547.419250,574.836060 546.887390,574.657104 546.178955,574.253784 
	C545.073486,573.160950 544.579468,572.082581 544.425903,570.461853 
	C544.440796,569.859558 544.485229,569.620911 544.725830,569.076477 
	C545.350342,568.545166 545.804626,568.429382 546.625732,568.543762 
	C549.742676,570.811035 552.113403,573.558838 555.888916,574.893677 
	C558.592529,576.233826 560.370117,578.377380 563.164429,579.576538 
	C563.650452,579.823242 563.839783,579.928711 564.300537,580.207275 
	C565.266113,580.842407 565.867065,581.439270 566.897827,581.948120 
	C567.697388,582.381287 568.219727,582.685730 569.046997,583.055481 
	C569.885864,583.398254 570.441711,583.637634 571.314575,583.910400 
	C572.795166,584.411072 573.966248,584.853638 575.302246,585.688599 
	C576.360352,586.530762 577.188477,587.154724 578.355835,587.854248 
	C578.781372,588.176514 578.941223,588.320679 579.284241,588.732910 
	C579.615479,589.415833 579.658386,589.842346 579.449219,590.561646 
	C579.077209,590.876709 578.867371,590.909302 578.341309,590.979370 
	C577.847900,591.140503 577.670776,591.264038 577.232239,591.196472 
	C572.584290,590.722961 569.591675,586.254944 564.469604,586.973328 
	C558.452393,583.706177 553.077026,580.114990 548.207214,575.251831 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M517.294067,556.124756 
	C515.670349,550.695068 514.303162,545.391785 512.930237,539.703247 
	C514.480042,538.357788 514.336304,539.705872 514.699707,540.896851 
	C514.823792,541.260193 514.840637,541.653809 514.954895,541.814331 
	C515.650879,542.272766 516.227295,542.578430 516.885132,543.327942 
	C517.349792,543.949280 517.588989,544.324768 517.985229,545.001770 
	C521.203308,551.113708 522.662476,557.783203 527.537903,562.634399 
	C528.345886,563.438354 528.677979,564.641907 528.290588,566.450195 
	C526.227539,567.198242 524.868591,566.427429 523.349731,565.147095 
	C521.171692,563.222656 519.813232,561.155273 519.149048,558.284668 
	C518.523926,557.398560 518.037292,556.824890 517.294067,556.124756 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M544.713623,569.800781 
	C546.131348,570.831665 545.974670,572.088623 546.004517,573.659851 
	C541.734131,574.916748 538.599792,578.677856 533.728333,578.430786 
	C531.702393,578.328125 530.139526,578.954773 528.828979,576.752502 
	C527.201599,574.017883 530.586792,574.189331 530.936768,572.316040 
	C531.959961,570.908813 533.193481,570.701294 534.904541,570.952271 
	C537.101013,572.347595 538.888306,573.026917 540.888306,571.195435 
	C541.829956,570.333069 543.017273,569.683105 544.713623,569.800781 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M547.044800,681.870728 
	C537.407410,676.208923 529.315430,668.861023 522.143005,660.147034 
	C530.283875,666.720520 538.388794,673.650391 546.734192,680.809204 
	C547.086670,681.241028 547.198730,681.443909 547.044800,681.870728 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M428.998108,459.991974 
	C427.787079,457.981812 428.378448,455.897125 428.570129,453.441162 
	C430.710602,451.348846 432.973969,451.644440 433.625519,453.801697 
	C434.725372,457.443207 437.578430,458.300385 440.703369,459.754089 
	C441.001221,460.001221 441.000000,460.000000 440.999390,459.999390 
	C439.373962,465.487579 437.809448,466.044128 433.949432,462.427551 
	C432.615051,461.177216 431.413940,459.787689 428.998108,459.991974 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M445.406219,465.170410 
	C447.348511,465.118805 448.531372,464.413757 449.717194,463.376831 
	C450.963501,462.287018 452.740173,461.278046 454.186066,462.584351 
	C455.672852,463.927582 455.277863,466.020874 454.476562,467.673004 
	C452.809143,471.110931 449.940247,469.537598 447.566345,468.854340 
	C446.097168,468.431458 442.873047,468.534637 445.406219,465.170410 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M547.966370,575.398315 
	C553.846985,577.968811 559.038025,581.923706 564.008057,586.624268 
	C560.835815,587.487244 557.980957,586.116821 556.131958,583.926819 
	C553.622986,580.955078 548.798706,580.345337 547.966370,575.398315 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M518.784546,558.233093 
	C521.120300,559.575684 522.574219,561.596985 523.034424,564.622437 
	C518.823975,566.852966 515.437866,565.513550 512.217407,561.854004 
	C514.389160,560.703247 516.473816,559.598633 518.784546,558.233093 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M581.537231,656.327148 
	C582.695251,652.900024 583.647583,649.581604 584.218140,645.547607 
	C586.984741,648.669495 589.052673,651.929565 592.799683,653.773682 
	C593.187378,654.526855 593.066711,654.923767 592.338562,655.138306 
	C589.574890,655.488647 587.736694,654.037903 585.378418,653.046265 
	C584.883423,653.166626 584.752136,653.333252 584.807922,653.757263 
	C584.991821,654.448242 584.988586,654.881836 584.986572,655.640625 
	C584.071899,656.798462 583.036255,657.040283 581.537231,656.327148 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M552.715454,679.596924 
	C550.916626,679.949951 550.092651,679.415955 550.139648,677.894226 
	C550.176941,676.687134 551.015259,675.846130 552.036194,676.167786 
	C555.026672,677.110046 557.897583,675.663574 560.827148,676.002563 
	C562.081787,676.147766 563.762329,675.793030 563.685852,677.791443 
	C563.623230,679.429077 562.271851,679.972900 560.865112,679.937195 
	C558.269470,679.871277 555.678162,679.635986 552.715454,679.596924 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M613.991699,612.596802 
	C611.924377,613.634460 609.831665,614.266479 607.373718,614.941772 
	C605.453979,612.813782 602.648254,613.342102 600.597900,611.891052 
	C605.118286,611.458740 609.591187,607.093262 613.991699,612.596802 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M552.063843,672.013611 
	C550.352051,671.512085 548.291931,671.504395 548.608521,669.533264 
	C548.896973,667.737366 551.049988,668.297363 552.330688,667.708191 
	C554.649658,666.641602 556.514465,667.384705 558.184692,668.952881 
	C558.863525,669.590271 558.703674,670.412048 558.186768,671.188721 
	C556.578003,673.605896 554.494080,672.700562 552.063843,672.013611 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M514.151611,651.457764 
	C509.457214,649.974976 507.363464,645.255310 503.898071,642.024170 
	C502.365448,640.595154 500.620697,638.823242 500.179077,636.103027 
	C504.846832,640.870911 509.457489,645.998413 514.151611,651.457764 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M597.229370,596.443420 
	C597.086548,596.842651 596.866577,596.884277 596.314209,596.976196 
	C595.242554,597.018127 594.503296,597.009827 593.394104,597.003174 
	C592.016479,597.819397 590.925049,598.074829 589.342529,597.248169 
	C588.203003,596.680908 587.430481,596.366699 586.345093,596.022583 
	C585.366272,595.130249 584.234009,595.047668 583.200134,594.216919 
	C582.380066,593.488098 581.780518,592.973816 581.037964,592.194702 
	C580.820862,591.687134 580.746765,591.444336 580.891602,590.888428 
	C581.992004,589.939819 582.991943,589.844299 584.406311,589.994507 
	C585.507874,590.236633 586.201721,590.516846 587.240723,590.922119 
	C590.940918,592.412476 594.409119,593.559387 597.229370,596.443420 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M627.296387,610.832031 
	C624.682312,606.999084 629.821228,604.469238 629.005371,600.380005 
	C630.065918,599.986084 631.122009,599.981995 632.573242,599.971863 
	C632.978638,600.725220 632.989014,601.484558 632.999695,602.623169 
	C633.277344,607.022888 628.891785,607.759155 627.296387,610.832031 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M477.586914,480.757019 
	C477.268463,479.281677 477.954346,478.101654 478.917847,478.427094 
	C482.706146,479.706635 485.383972,482.797058 488.647095,484.960693 
	C489.114014,485.270294 488.770813,486.317230 488.118347,486.624969 
	C486.901703,487.198822 485.628113,486.627167 484.911774,485.848328 
	C482.890778,483.651001 480.756561,481.751190 477.586914,480.757019 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M438.721893,490.125824 
	C440.519897,491.019653 443.049194,491.063568 442.388214,493.558289 
	C441.940552,495.247833 440.046387,494.986847 438.595428,494.978088 
	C437.053619,494.968781 435.488983,494.686646 435.268158,492.853119 
	C435.008209,490.694763 436.500671,490.095520 438.721893,490.125824 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M581.001221,656.217346 
	C582.218140,656.031921 583.223999,656.008728 584.608826,655.975708 
	C584.991882,656.137512 584.995911,656.309265 585.002441,656.738586 
	C585.578430,657.684692 585.742126,658.455688 585.235596,659.657043 
	C581.622742,660.333435 578.245422,660.666809 574.437744,660.997681 
	C574.018921,660.657776 574.030579,660.320496 574.044922,659.732544 
	C574.960571,659.252014 575.873474,659.022095 577.139832,658.766968 
	C578.367432,658.641296 578.907410,657.990234 579.779358,657.302490 
	C580.273499,656.864807 580.531799,656.622192 581.001221,656.217346 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M500.214569,635.427124 
	C495.873871,633.810913 494.005951,629.725281 491.903778,625.495117 
	C495.444122,627.812622 497.262848,631.899597 500.214569,635.427124 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M488.817749,620.692383 
	C485.580627,618.372498 482.396149,615.669495 481.102509,611.100342 
	C484.675781,613.159180 486.299561,617.113098 488.817749,620.692383 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M536.405029,572.022949 
	C534.626160,571.992920 533.230957,571.993958 531.417175,571.988525 
	C530.019226,570.551880 529.039917,569.121704 528.009644,567.366821 
	C527.958740,567.042053 528.244812,566.757874 528.387939,566.615845 
	C531.541992,566.691589 534.158203,568.386230 537.337158,569.071655 
	C537.865051,570.427979 537.750305,571.361145 536.405029,572.022949 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M600.943359,616.724487 
	C598.484070,617.048340 596.005249,616.547852 593.275757,617.792969 
	C591.187012,613.807007 594.617188,615.422485 596.601196,614.980103 
	C597.399109,614.549866 597.855530,614.201050 598.733887,614.041443 
	C600.462219,614.151917 601.011719,615.014038 600.943359,616.724487 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M566.669434,672.394653 
	C565.806274,670.364746 566.151306,669.112488 568.280701,669.126404 
	C569.544128,669.134644 570.770874,669.794800 570.676941,671.132996 
	C570.487549,673.833435 568.509521,672.902222 566.669434,672.394653 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M445.530029,540.743652 
	C445.119873,540.699585 445.128754,540.486572 445.117737,540.448059 
	C446.596924,539.119629 448.038239,537.766357 449.597107,536.565613 
	C450.421600,535.930481 451.669769,535.732178 452.250275,536.706421 
	C452.932434,537.851257 451.847687,538.439087 450.903076,538.825562 
	C449.256805,539.499268 447.595306,540.135681 445.530029,540.743652 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M518.133728,655.511841 
	C515.846558,655.925659 514.738281,654.507324 514.168152,652.125000 
	C515.966736,652.376648 516.847046,653.976685 518.133728,655.511841 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M522.140503,659.499207 
	C519.854187,659.948792 518.737122,658.531128 518.128967,656.163635 
	C519.468201,656.928101 520.770081,658.053223 522.140503,659.499207 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M585.038208,590.975220 
	C583.816833,591.370911 582.778625,591.325439 581.351440,591.299927 
	C580.779175,591.340637 580.595947,591.361267 580.207520,591.196655 
	C580.002258,591.011414 579.659546,590.863403 579.473267,590.853760 
	C579.194580,590.450500 579.102173,590.056885 578.985535,589.360779 
	C581.619568,587.125610 583.675354,587.748718 585.038208,590.975220 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M443.339539,536.958618 
	C441.405670,536.188721 441.622925,534.130005 441.187347,532.080566 
	C443.156281,532.869446 442.918243,534.896790 443.339539,536.958618 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M600.080566,604.854858 
	C599.088806,606.051331 598.038086,606.211426 596.930603,605.558350 
	C596.740295,605.445984 596.578430,604.847046 596.675781,604.752014 
	C597.752136,603.701538 598.934326,603.746216 600.080566,604.854858 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M589.052490,597.334839 
	C590.055054,596.994934 591.134521,596.994690 592.619080,596.999634 
	C592.137756,599.623413 590.808716,599.667358 589.052490,597.334839 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M568.856018,591.445435 
	C569.901794,591.040161 570.759155,590.880249 571.303772,591.685974 
	C571.412781,591.847168 571.000610,592.659790 570.715820,592.733093 
	C569.784607,592.972473 568.698120,593.201660 568.856018,591.445435 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M457.065186,458.340729 
	C458.740204,458.531738 460.766724,458.466309 461.949982,460.661011 
	C460.619080,459.746216 458.162170,460.717346 457.065186,458.340729 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M491.788391,624.761536 
	C490.183136,624.963684 489.372192,623.945740 489.102051,622.061523 
	C490.566406,622.098328 491.078064,623.334351 491.788391,624.761536 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M585.326172,659.942078 
	C585.000488,659.249084 585.001038,658.498169 585.003174,657.371704 
	C587.041443,657.550659 587.775330,658.397156 585.326172,659.942078 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M583.049133,594.342529 
	C584.125366,594.049194 585.373535,593.869263 585.960083,595.653931 
	C584.882996,595.952209 583.630554,596.141052 583.049133,594.342529 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M432.407104,497.003540 
	C430.914825,496.625183 431.194214,495.383820 431.374817,493.932770 
	C432.640991,494.404663 432.540833,495.571075 432.407104,497.003540 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M429.383179,480.950562 
	C430.781830,481.195892 430.363892,482.466156 430.861389,483.705139 
	C430.830719,484.030426 430.664276,484.061493 430.248138,484.046204 
	C428.967682,483.319885 429.107208,482.299316 429.383179,480.950562 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M434.345734,505.812042 
	C433.101624,505.429596 433.044922,504.444153 433.184174,503.084045 
	C434.795441,503.239624 434.585907,504.468781 434.345734,505.812042 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M592.223022,655.274231 
	C592.306152,654.906372 592.560303,654.718750 592.879883,654.256104 
	C593.442749,654.093567 593.892090,654.140869 594.648560,654.334595 
	C594.966553,654.817932 594.977417,655.154968 594.995361,655.745972 
	C594.148987,655.839905 593.295593,655.679810 592.223022,655.274231 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M437.596985,518.884521 
	C436.310913,518.506409 436.195648,517.388367 436.353424,515.932129 
	C437.674133,516.310913 437.618011,517.484375 437.596985,518.884521 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M553.069092,685.584595 
	C552.122681,686.052673 551.399841,685.663818 550.862549,684.531128 
	C551.835205,684.031860 552.493774,684.531677 553.069092,685.584595 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M431.302490,490.860382 
	C430.352661,490.629578 430.206421,489.980286 430.411957,488.926147 
	C431.713715,488.941528 431.681946,489.800568 431.302490,490.860382 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M446.476166,544.988708 
	C445.317169,545.051697 445.097504,544.239563 445.212463,543.006470 
	C446.357117,543.007812 446.381561,543.893799 446.476166,544.988708 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M440.303436,528.043823 
	C439.214355,527.891541 439.147003,527.069702 439.246948,525.965942 
	C440.377106,526.067017 440.469238,526.907471 440.303436,528.043823 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M483.347931,475.003662 
	C483.005157,474.567566 482.996552,474.136108 482.991760,473.380981 
	C483.386658,472.624664 483.866821,472.473969 484.660828,472.936584 
	C484.933594,473.653351 484.980682,474.036194 485.023743,474.708679 
	C484.573853,475.001617 484.127991,475.004974 483.347931,475.003662 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M547.029785,682.310913 
	C546.812256,681.918762 546.845581,681.742737 546.926758,681.302429 
	C547.929138,681.000732 548.502380,681.601501 549.071655,682.581665 
	C548.498657,682.771851 547.889648,682.649475 547.029785,682.310913 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M598.844727,613.806519 
	C598.543823,614.327026 598.063232,614.632202 597.286743,614.956299 
	C597.191528,614.081482 597.211609,612.969360 598.844727,613.806519 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M450.457336,554.997803 
	C449.382843,555.016785 449.146759,554.255249 449.121002,553.108032 
	C450.318329,552.961243 450.464996,553.821899 450.457336,554.997803 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M435.200592,509.859741 
	C434.270813,509.641357 434.120880,508.997894 434.319336,507.960144 
	C435.512726,508.024231 435.388092,508.901978 435.200592,509.859741 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M448.456970,550.013916 
	C447.218567,550.137939 447.052246,549.292603 447.069458,548.113037 
	C448.268158,548.010864 448.461975,548.845032 448.456970,550.013916 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M436.257202,513.842773 
	C435.757812,513.424744 435.442902,512.846680 435.299683,511.963379 
	C436.540283,511.975739 436.389893,512.877625 436.257202,513.842773 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M577.072083,591.159119 
	C577.146423,591.003479 577.322083,591.001709 577.761353,591.008423 
	C577.741089,591.115540 577.457214,591.214233 577.072083,591.159119 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M584.999207,653.760986 
	C584.839722,653.842712 584.665405,653.682861 584.542236,653.490295 
	C584.511230,653.441772 584.643921,653.288513 584.858032,653.091675 
	C585.010864,653.169128 585.007080,653.338196 584.999207,653.760986 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M580.104492,591.207397 
	C580.180725,591.004578 580.359192,590.997742 580.750000,591.155457 
	C580.962341,591.319946 580.928650,591.625916 580.911804,591.777954 
	C580.607544,591.830200 580.378113,591.654724 580.104492,591.207397 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M574.351440,346.787659 
	C574.896118,346.427002 575.179932,346.328796 575.744629,346.599945 
	C576.589355,347.910400 577.829712,347.298889 579.122498,347.758789 
	C579.503052,347.847290 579.767090,347.714417 579.874756,347.815613 
	C580.593079,348.725250 581.518677,348.420746 582.550659,348.787811 
	C584.369873,349.318848 585.854919,349.847351 587.142700,351.328735 
	C584.703003,354.304169 580.600708,353.902100 577.670532,356.565369 
	C573.844116,357.242737 571.213257,355.392975 568.730835,353.435272 
	C566.630859,351.779083 564.866882,351.732513 563.112671,353.770813 
	C561.901001,355.178802 560.381836,356.197052 558.846985,357.201904 
	C556.770630,358.561310 555.263550,360.022369 556.365295,362.932861 
	C557.228210,365.212402 555.787415,366.967377 553.764465,367.636597 
	C550.762695,368.629639 547.924072,369.516205 545.529236,371.894684 
	C543.886841,373.525726 541.218994,372.249817 541.678040,370.238129 
	C542.720459,365.670776 538.962036,361.391724 542.297791,356.264191 
	C538.374084,356.827118 535.656677,357.773804 533.017639,359.018616 
	C531.177490,359.886536 529.251709,360.613983 527.237000,360.038300 
	C524.684082,359.308868 522.062317,358.316528 524.625916,354.950500 
	C525.707336,353.530487 525.538391,352.306213 524.122620,351.654022 
	C519.318542,349.440948 514.611023,347.012238 509.698792,345.042633 
	C509.144775,344.820526 508.370636,345.056427 507.889191,345.718567 
	C508.681610,346.947510 509.980682,346.344940 511.039246,346.537781 
	C512.795166,346.857727 515.179565,346.577393 515.349915,348.972473 
	C515.531982,351.532135 513.882629,353.522644 511.367371,354.012817 
	C508.182220,354.633545 504.793243,354.364746 503.485321,358.459961 
	C503.197235,359.362030 501.431213,359.356415 500.260773,359.407928 
	C496.437897,359.576202 492.593048,359.330444 488.794403,359.930969 
	C486.622284,360.274323 485.616882,358.664185 484.486298,357.282959 
	C483.007935,358.784027 482.275604,360.451538 481.536560,362.133942 
	C480.623077,364.213440 481.793884,366.934113 479.506195,368.583588 
	C478.382172,369.394073 472.159332,370.861664 469.206146,370.911926 
	C471.228302,374.098053 474.747406,372.532227 477.275635,373.728973 
	C479.513672,374.788391 483.491669,373.905518 483.483429,377.482910 
	C483.474884,381.169250 479.554504,380.213593 477.226501,380.989594 
	C475.499420,381.565277 473.690552,382.114166 471.871307,382.160736 
	C467.963959,382.260834 464.878601,382.554779 464.718872,387.914948 
	C464.633698,390.773590 461.503326,392.695374 458.529144,392.840851 
	C455.012482,393.012878 454.304596,394.635071 454.747986,397.657623 
	C455.119873,400.192902 455.392731,403.199768 451.942322,403.502808 
	C448.520172,403.803345 446.721344,401.420044 446.967590,398.078705 
	C447.151825,395.578735 445.946564,394.411469 443.699493,393.280457 
	C442.547058,391.500488 442.664886,389.962341 443.558868,388.082306 
	C444.340454,387.177795 445.011200,386.803894 446.133484,386.556580 
	C447.284424,386.569092 447.867035,387.131958 448.578400,387.384064 
	C448.811249,386.668854 448.174103,386.329712 447.729340,385.534607 
	C447.475220,385.015656 447.398682,384.792694 447.284393,384.211975 
	C447.366699,382.303802 448.038025,381.033234 449.512360,379.820312 
	C450.064697,379.583069 450.302185,379.536011 450.895477,379.540100 
	C451.875732,379.836029 452.417450,380.209412 452.931671,380.635712 
	C454.053558,381.565826 455.209137,383.125092 456.765198,381.890839 
	C458.749359,380.317017 456.472961,379.407104 455.800934,378.318787 
	C455.164673,377.288422 454.818604,376.230988 454.939972,374.704834 
	C455.122345,373.974762 455.300690,373.594055 455.748779,373.037903 
	C456.371185,372.524536 456.880157,372.298828 456.952606,371.411621 
	C456.938354,370.523499 457.013550,369.924957 457.221191,369.010681 
	C458.705017,364.757233 462.445007,363.044342 465.217041,360.413879 
	C461.822906,363.216888 457.716675,365.142609 454.765076,369.011963 
	C451.968262,371.462616 449.264526,373.519684 447.355103,376.782776 
	C447.135529,377.288971 447.032837,377.483368 446.741821,377.945129 
	C445.573975,379.295105 444.147034,379.918610 443.270264,381.534180 
	C443.007324,381.980804 442.896973,382.156616 442.615234,382.592285 
	C441.961090,383.527893 441.476257,384.202454 441.061279,385.290253 
	C440.809204,385.771362 440.695190,385.959839 440.371643,386.410034 
	C438.222137,388.078644 437.352753,390.191467 436.359924,392.600220 
	C436.175507,393.108276 436.089691,393.304108 435.849060,393.774445 
	C435.285309,394.736053 434.624908,395.261078 434.311707,396.350891 
	C434.097992,397.020447 433.945190,397.393707 433.630249,398.031433 
	C432.893219,399.147034 432.268402,399.973938 431.944794,401.328308 
	C431.761108,401.851013 431.666809,402.055847 431.386475,402.549316 
	C426.879578,408.679657 424.998413,415.476776 423.474426,422.790710 
	C423.362701,423.628937 423.246613,424.096893 422.916443,424.876038 
	C420.924011,428.669983 420.731018,432.554260 419.895691,436.645874 
	C419.766266,437.369843 419.686707,437.736633 419.585388,438.384460 
	C418.883270,440.157349 419.048309,441.766388 418.866333,443.669769 
	C418.750366,444.375732 418.684631,444.746185 418.600769,445.398499 
	C417.581573,451.816620 418.440857,458.039795 417.537689,464.572571 
	C417.334595,467.354095 417.312408,469.752197 417.329529,472.598511 
	C418.875732,478.553650 417.961945,484.242462 418.778320,489.866486 
	C421.284058,507.129120 424.917725,524.055664 430.451477,540.617310 
	C436.473145,558.639099 444.599335,575.686340 453.695007,592.748657 
	C454.331146,593.788208 454.726288,594.508484 455.298096,595.481506 
	C455.546570,596.540161 456.119537,597.049011 456.785950,597.819519 
	C456.980530,598.021179 457.167328,598.409058 457.297211,598.580688 
	C457.546814,599.541748 458.114014,600.060242 458.740906,600.869873 
	C459.305115,601.813477 459.700745,602.531799 460.287170,603.491882 
	C460.555786,604.534912 461.117310,605.062134 461.724426,605.885803 
	C462.282257,606.825989 462.681274,607.533936 463.241089,608.501099 
	C463.536926,609.546204 464.112366,610.054077 464.764496,610.842468 
	C464.947449,611.055847 465.115112,611.438843 465.230225,611.613403 
	C465.530304,612.554993 466.109314,613.050659 466.801422,613.801758 
	C467.004181,613.995422 467.241730,614.375000 467.396301,614.536926 
	C467.524231,615.540894 468.131195,616.033813 468.807312,616.793091 
	C469.014709,616.983215 469.237183,617.368408 469.365051,617.550415 
	C469.686462,618.408142 470.051331,618.982239 470.791504,619.714600 
	C471.494629,620.616638 471.939880,621.290710 472.657562,622.103210 
	C473.818115,622.412109 474.158752,623.172485 474.712646,624.088074 
	C474.808807,624.369385 474.888977,624.783875 475.083252,624.866455 
	C477.727081,631.078247 482.463776,635.607910 486.579987,640.477966 
	C508.585876,666.514160 533.800171,688.857910 563.369812,706.070007 
	C582.141724,716.996765 601.937134,725.381287 623.582764,730.173096 
	C624.554199,730.089111 625.104126,730.150085 625.878723,730.549133 
	C629.079285,731.513428 632.060730,732.127258 635.562134,732.139282 
	C636.558350,732.056580 637.111206,732.123413 637.901855,732.466797 
	C639.501953,732.789246 640.836426,733.015625 642.577576,733.200989 
	C645.128662,733.003845 647.275391,732.766418 649.759644,733.531128 
	C651.780457,733.776123 653.439148,733.842407 655.507324,733.836304 
	C657.206299,734.790283 658.756836,734.367676 660.650269,734.596313 
	C660.990234,735.158325 660.880859,735.659363 660.728271,736.416504 
	C660.072327,737.947571 658.888977,738.052246 657.259155,738.092834 
	C652.065552,736.866211 647.219299,737.716553 642.000427,737.435425 
	C641.024109,737.414368 640.468872,737.387085 639.509155,737.279358 
	C637.083496,736.308655 634.938843,736.424622 632.404541,736.451172 
	C630.923767,736.530945 629.851624,736.468262 628.389404,736.212402 
	C620.223145,734.076233 612.224670,733.035217 604.481323,730.846313 
	C600.513000,729.724548 596.625000,728.390381 592.468018,726.652344 
	C591.680847,726.244995 591.242371,726.002930 590.478882,725.602417 
	C586.920776,724.079407 583.276428,723.799805 580.058594,721.510010 
	C579.033569,720.658630 578.635986,719.584778 577.232605,719.258179 
	C576.749573,719.117126 576.555908,719.059143 576.072021,718.908752 
	C575.393738,718.686218 575.007263,718.551941 574.326294,718.316528 
	C572.462097,717.634644 570.944824,716.940247 569.237732,715.936523 
	C568.323608,715.346741 567.799805,714.728149 566.768066,714.359985 
	C566.121582,714.152893 565.762085,714.007141 565.150513,713.710815 
	C564.225647,713.204163 563.710510,712.586487 562.661987,712.289673 
	C561.614624,711.946228 560.889038,711.599976 559.903809,711.074280 
	C559.264771,710.717346 558.898010,710.510803 558.235229,710.169434 
	C556.539062,709.361572 555.059448,708.831482 553.625977,707.565369 
	C552.905212,706.951660 552.486755,706.475891 551.638916,706.054321 
	C550.826538,705.603638 550.328308,705.238281 549.616150,704.642212 
	C548.415833,703.324219 547.285095,702.486511 545.443237,702.225586 
	C537.669556,698.679749 531.478027,693.353394 525.063538,688.394470 
	C512.044128,678.329224 500.279053,666.870239 488.774963,655.138489 
	C484.252014,650.526001 480.641113,645.089050 475.531128,640.513062 
	C464.270752,625.409180 453.544556,610.381348 444.254395,594.374634 
	C433.692108,576.176147 425.428253,556.965576 418.612305,536.664673 
	C414.410248,526.413269 412.667542,515.973145 410.674316,505.561188 
	C408.460785,493.998383 407.205658,482.320526 406.682434,470.623596 
	C406.142578,458.554535 408.299896,446.592499 410.144684,434.252563 
	C411.785095,430.414673 411.465027,426.535217 412.880981,422.675964 
	C412.968597,420.763214 413.653809,419.382538 414.008667,417.551666 
	C414.358856,416.022369 414.539825,414.826813 415.040710,413.343567 
	C415.795776,412.262787 416.010468,411.324402 416.515564,410.132782 
	C420.655304,400.858643 424.921112,392.043091 429.993469,383.278076 
	C430.113403,382.741486 430.172913,382.532166 430.391083,382.043884 
	C430.811035,381.442413 431.084534,381.130737 431.567505,380.607910 
	C431.888153,380.233185 432.014984,380.083527 432.332947,379.712158 
	C432.787567,379.201752 433.061005,378.922882 433.553650,378.447937 
	C434.184448,377.825958 434.646942,377.449066 435.178101,376.706451 
	C435.498657,376.284912 435.631531,376.115265 435.981079,375.696655 
	C443.709076,366.165680 452.475067,358.331207 463.148926,352.133850 
	C463.661621,351.777832 463.872742,351.637909 464.421143,351.302246 
	C465.000977,350.997284 465.243469,350.888153 465.874329,350.666931 
	C481.175903,342.392090 497.309692,337.824951 514.098267,337.004333 
	C524.209473,336.510071 534.574951,336.904236 544.705811,338.495148 
	C547.633118,338.954834 550.595459,339.255127 553.905762,339.908508 
	C554.831726,339.998962 555.354370,340.056274 556.232788,340.261475 
	C557.914734,341.115540 559.469482,340.863251 561.190369,341.438904 
	C562.476868,341.480896 563.333557,341.880951 564.563354,342.123291 
	C565.470520,342.541473 565.986267,342.972046 566.872070,343.431274 
	C568.254639,344.020935 569.335388,344.475586 570.730042,345.091980 
	C571.361206,345.424255 571.669800,345.609131 572.236938,345.838196 
	C572.505432,345.865875 572.766724,345.732300 572.874084,345.832397 
	C573.265442,346.295868 573.608337,346.590881 574.351440,346.787659 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M440.999847,459.594452 
	C436.996552,459.990356 432.414886,461.034668 432.725555,454.377258 
	C432.802002,452.738800 430.767548,453.108276 429.137878,453.038239 
	C427.846954,451.131866 428.638519,449.162384 428.564941,446.803650 
	C429.038788,444.845764 429.043274,443.266632 428.944458,441.342072 
	C429.086304,439.712433 429.250671,438.421295 429.447205,436.740784 
	C429.886078,435.143951 430.081909,433.906830 430.004669,432.315399 
	C430.114746,431.333771 430.223267,430.667786 430.380981,429.668030 
	C431.285889,427.510010 430.717377,425.256683 432.480042,423.405670 
	C438.476746,422.538757 443.942169,422.863251 449.070435,426.644073 
	C451.125732,427.353394 452.944977,427.498749 454.950012,428.414856 
	C456.313477,429.656372 456.866211,430.836273 456.228149,432.656677 
	C452.186737,432.130859 448.527893,434.593933 444.686646,433.481689 
	C443.714447,433.200195 443.141418,433.877136 443.115570,434.872009 
	C443.084412,436.070587 443.559998,436.523071 444.899872,436.549286 
	C448.644287,436.622498 451.827087,438.332489 454.924561,440.301300 
	C456.831329,441.513275 456.769226,442.422363 455.408569,444.187683 
	C452.259491,448.273193 449.227173,452.174286 443.713348,453.398865 
	C440.998566,454.001801 440.849030,456.745575 440.999847,459.594452 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M454.019226,592.696899 
	C448.412231,586.950867 445.643829,579.245483 442.212463,572.002197 
	C428.287354,542.607727 419.093414,511.870209 417.063141,479.224579 
	C416.949860,477.403534 416.987671,475.573151 417.161987,473.396912 
	C419.492157,474.229431 418.600220,476.311981 418.681122,477.979065 
	C419.327179,491.294678 421.813660,504.316589 424.834045,517.656921 
	C426.182037,518.908508 425.946594,520.463562 426.639404,521.942871 
	C426.806519,530.526062 430.497864,537.820557 432.985809,545.422974 
	C434.475616,549.975342 436.429352,554.375916 438.355347,559.165161 
	C439.154480,559.371887 439.472809,559.629089 439.354675,560.613525 
	C443.299805,571.839294 449.323303,581.787354 454.019226,592.696899 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M434.638428,393.954895 
	C434.711945,393.680786 434.833893,393.319244 434.973694,393.189453 
	C436.825012,390.955994 437.603607,388.246246 439.569794,385.941650 
	C439.618835,385.607025 439.792969,385.270630 439.949127,385.163452 
	C440.769928,384.310577 441.201050,383.421234 441.906250,382.392517 
	C442.161438,382.241486 442.150269,382.143402 442.199310,382.148987 
	C443.084839,380.650208 444.525848,379.661530 445.640106,378.004425 
	C445.746521,377.705505 445.907318,377.346710 446.064880,377.229187 
	C447.476807,375.383453 448.984497,373.878357 450.596588,372.506775 
	C452.489075,370.896637 454.136353,370.785095 454.859375,374.058044 
	C454.700378,375.423096 454.186768,376.238770 453.364258,377.274109 
	C452.363678,378.352936 451.338074,378.823975 450.181580,379.670105 
	C449.183899,380.990417 448.451233,382.121216 447.512329,383.519379 
	C447.073730,385.206848 445.974274,386.172821 445.183075,387.679077 
	C444.331116,389.262695 443.701294,390.583771 443.064697,392.273834 
	C443.349426,394.199921 443.328827,395.706451 442.141235,397.298828 
	C441.520935,397.974823 441.024384,398.330200 440.555054,399.089386 
	C440.364502,399.749573 440.265533,400.133514 440.067932,400.809448 
	C439.889709,401.291809 439.801392,401.479980 439.519470,401.916382 
	C438.785583,402.564941 438.184601,402.673706 437.257690,402.378662 
	C435.003357,402.305389 432.659332,401.250000 432.749634,405.113098 
	C432.804138,407.442719 431.165375,409.756958 428.332458,410.948700 
	C426.925476,407.595673 429.211517,405.125153 430.476013,401.968384 
	C430.596680,401.596375 430.737549,401.252777 430.876282,401.129395 
	C431.730286,400.207581 431.915894,399.116486 432.605133,397.956573 
	C432.808716,397.304840 432.938019,396.959137 433.230103,396.388184 
	C433.661987,395.444641 434.133667,394.849060 434.638428,393.954895 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M432.351807,421.217346 
	C432.446838,419.605530 432.787811,418.328613 433.244629,416.671387 
	C434.523254,413.750488 434.391022,410.690643 436.856873,408.401215 
	C441.127502,409.469940 443.107208,412.590454 443.492950,416.153687 
	C444.044434,421.247772 440.852264,423.096313 433.693481,422.130035 
	C433.066742,421.847137 432.832245,421.699677 432.351807,421.217346 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M430.307312,401.773376 
	C431.016571,404.859558 428.392944,407.213287 427.995972,410.611176 
	C427.292572,412.744232 426.607635,414.503998 425.840912,416.619934 
	C426.038239,419.485870 426.100250,421.915192 423.045654,423.250000 
	C423.320953,415.477173 426.707214,408.726807 430.307312,401.773376 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M417.730927,464.764893 
	C416.201538,458.631653 417.079071,452.371246 418.265869,445.895447 
	C418.760895,446.484528 418.939117,447.288727 419.127808,448.514496 
	C419.839203,449.985657 420.083618,451.080872 419.188507,452.601746 
	C418.349792,456.799133 419.216522,460.754639 417.730927,464.764893 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M420.152954,436.885162 
	C418.854401,432.921539 420.634796,429.254150 421.869598,425.244507 
	C422.620148,424.935394 422.992157,425.058167 423.226685,425.752167 
	C422.274109,429.637177 421.367096,433.202240 420.152954,436.885162 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M436.856415,408.768372 
	C435.618958,411.223083 434.787781,413.601227 433.658569,416.135254 
	C432.590271,411.330017 436.154938,407.451202 436.852661,402.521606 
	C437.361786,402.054657 437.740906,401.890259 438.309387,401.826294 
	C440.719269,402.984467 439.540649,404.718384 439.215393,406.645813 
	C438.673340,407.813202 437.996918,408.284088 436.856415,408.768372 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M423.522400,425.992554 
	C422.994812,425.794464 422.808502,425.516815 422.395691,425.133820 
	C422.251465,424.606873 422.333832,424.185211 422.550964,423.470703 
	C424.693420,421.784973 424.182739,419.243347 425.529999,417.210388 
	C428.196655,420.606018 426.982605,423.461517 423.522400,425.992554 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M432.803284,423.733826 
	C431.893341,425.550842 431.448792,427.282867 430.717194,429.174561 
	C429.530609,426.453339 430.826752,423.860046 431.801727,421.033661 
	C432.105865,420.882446 432.586639,420.765747 432.807617,420.876923 
	C433.638031,421.827240 433.735748,422.712555 432.803284,423.733826 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M576.863281,701.608643 
	C579.243042,701.493469 581.158569,702.725708 582.978760,704.622803 
	C580.584778,704.802185 578.754089,703.351868 576.863281,701.608643 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M428.614777,441.271484 
	C429.846649,442.638794 429.400970,444.312897 428.813385,446.184235 
	C427.599609,444.815918 428.013428,443.174469 428.614777,441.271484 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M419.145081,443.760986 
	C417.473663,442.282745 418.559265,440.636383 419.268860,438.817230 
	C420.620911,440.302643 419.886169,441.906677 419.145081,443.760986 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M419.248108,452.890198 
	C418.911499,451.867126 418.916473,450.792542 419.029877,449.326996 
	C421.505493,449.980560 421.603119,451.287567 419.248108,452.890198 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M429.717072,432.268982 
	C430.954346,433.345276 430.379089,434.688293 429.739502,436.191528 
	C428.696503,435.090637 428.877899,433.816895 429.717072,432.268982 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M474.893250,623.994141 
	C473.822968,624.320435 473.351562,623.613892 472.916443,622.559937 
	C472.969330,621.450623 473.044952,620.391052 474.096375,620.738464 
	C475.451294,621.186035 474.833008,622.597229 474.893250,623.994141 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M426.766602,521.883911 
	C425.191803,521.550598 424.947388,520.191711 424.914948,518.387329 
	C427.175690,518.305969 426.971771,519.921387 426.766602,521.883911 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M588.696716,707.877258 
	C589.618835,707.056824 590.248230,707.607788 590.959717,708.473450 
	C590.771606,708.990112 590.497559,709.206787 590.039917,709.401855 
	C589.468933,708.973450 589.081421,708.566772 588.696716,707.877258 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M458.972107,600.773804 
	C457.772400,601.093201 457.462555,600.275818 457.326233,599.069580 
	C458.587769,598.728271 458.808228,599.593201 458.972107,600.773804 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M461.990601,605.804932 
	C460.715302,606.111938 460.466095,605.243164 460.355164,604.046753 
	C461.622925,603.762207 461.851929,604.634766 461.990601,605.804932 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M465.030548,610.733337 
	C463.843567,611.054871 463.469879,610.300415 463.320038,609.079834 
	C464.571442,608.689209 464.859100,609.532837 465.030548,610.733337 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M563.808289,693.728760 
	C564.750793,693.080200 565.374329,693.665222 566.031128,694.606323 
	C565.080750,695.286316 564.445618,694.721008 563.808289,693.728760 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M457.080292,597.704468 
	C455.836945,598.059937 455.460541,597.289001 455.352783,596.048706 
	C456.610596,595.700134 456.949860,596.487732 457.080292,597.704468 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M428.638031,473.155579 
	C429.730621,473.333954 429.575256,474.023407 429.236908,474.852051 
	C428.163483,474.669403 428.262695,473.997131 428.638031,473.155579 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M471.139709,619.632935 
	C469.956635,619.966187 469.522644,619.241882 469.365662,618.039917 
	C470.547913,617.743042 470.938141,618.479492 471.139709,619.632935 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M469.100037,616.666870 
	C467.901520,616.984558 467.564087,616.198792 467.436829,615.007202 
	C468.689545,614.661804 469.001465,615.442810 469.100037,616.666870 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M467.085510,613.671143 
	C465.882629,614.074646 465.520142,613.275208 465.270203,612.110229 
	C466.482574,611.688232 466.876160,612.457397 467.085510,613.671143 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M584.740234,705.983521 
	C585.614258,705.264771 586.313049,705.673157 587.017273,706.606750 
	C586.138428,707.392578 585.411743,707.048889 584.740234,705.983521 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M439.551270,560.898804 
	C439.019623,560.610168 438.820862,560.250854 438.579163,559.688599 
	C439.128052,559.549133 439.719971,559.612610 440.311829,559.676086 
	C440.169281,560.060120 440.026733,560.444214 439.551270,560.898804 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M571.814331,698.764465 
	C572.720215,698.103333 573.371704,698.662903 574.065125,699.567627 
	C573.129395,700.287415 572.504578,699.683533 571.814331,698.764465 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M566.822998,695.681152 
	C567.777710,695.003479 568.453735,695.474915 569.085571,696.534424 
	C568.189087,697.089905 567.438232,696.778320 566.822998,695.681152 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M617.227417,511.955231 
	C617.976379,515.964661 620.975220,517.900330 623.687988,519.613770 
	C629.102722,523.033813 634.882568,534.278564 633.923096,540.439941 
	C633.201111,545.076477 626.983704,550.943359 622.056763,552.001526 
	C613.284119,553.885864 604.057922,554.035583 595.949646,551.493042 
	C587.309082,548.783691 578.087463,546.166199 570.713013,540.134521 
	C569.299744,538.978577 567.812439,537.911743 569.459961,535.647888 
	C570.554138,534.144226 570.033264,532.428955 568.459351,531.272461 
	C566.783875,530.041382 566.665588,528.563232 567.746521,526.940369 
	C568.763428,525.413696 570.218445,524.707214 572.053223,525.181824 
	C573.624756,525.588440 575.550049,526.742798 576.353638,524.546997 
	C577.020325,522.724915 574.705566,522.378296 573.697449,521.448608 
	C571.032227,518.990784 571.336609,517.114014 574.730469,516.061401 
	C576.947021,515.373962 577.563538,514.140686 576.285156,512.445801 
	C574.144897,509.608246 576.518921,507.541840 577.639832,505.780365 
	C578.794861,503.965271 581.200256,504.607605 583.496094,505.033997 
	C588.424561,505.949249 593.604370,505.212891 598.482666,506.913300 
	C599.729065,507.347778 601.559692,507.337128 602.157776,508.199585 
	C605.935791,513.647339 611.809387,510.342651 617.227417,511.955231 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M623.801025,513.484070 
	C622.888855,513.414856 622.314148,513.163696 621.443542,512.808716 
	C619.885681,511.688751 618.584839,510.716980 617.281128,509.221741 
	C616.425903,506.665710 613.349243,506.216797 612.928833,504.154266 
	C611.896667,499.090057 610.594543,494.254333 608.795227,489.343628 
	C607.405518,485.550995 607.783081,480.796173 605.168701,476.933319 
	C603.859253,474.998535 604.307129,473.413116 607.352173,473.241943 
	C609.881714,474.306244 611.846741,476.099518 614.899048,475.162292 
	C615.699280,475.108643 616.152527,475.168152 616.930176,475.384949 
	C617.262573,475.480713 617.321594,475.588135 617.293457,475.642578 
	C617.335632,476.369659 617.833191,476.664917 618.641052,477.033020 
	C619.098145,477.229126 619.273193,477.327454 619.678101,477.621948 
	C620.712952,478.764313 621.554382,479.619629 623.080505,478.766113 
	C623.191772,478.483002 623.621765,478.437958 623.758057,478.605713 
	C624.097046,479.107910 624.350525,479.398041 624.921875,479.759827 
	C625.188965,479.875854 625.082092,479.732056 625.156250,479.681854 
	C625.353210,480.126892 625.645508,480.492706 626.214111,481.089935 
	C626.292114,481.668488 626.238647,481.905029 625.926758,482.394531 
	C622.672729,484.244110 619.911072,482.863098 617.150452,481.872742 
	C616.111511,483.059113 617.045349,484.159302 617.124146,485.233673 
	C617.601929,491.755493 617.926819,492.096313 624.298035,491.679260 
	C629.423218,491.343750 634.551147,491.827209 640.010376,491.379822 
	C641.048218,491.226196 641.737671,491.287720 642.751038,491.513550 
	C643.486206,491.791962 643.893799,491.976624 644.588745,492.319550 
	C645.400879,492.858307 645.893005,493.285400 646.714172,493.799469 
	C647.227966,494.198914 647.498291,494.388428 647.885498,494.799988 
	C650.650391,497.739624 652.365356,501.231415 655.261658,503.747437 
	C656.171570,504.537903 656.818176,505.483490 656.208923,507.098297 
	C653.150024,509.151154 650.109131,508.505280 647.056885,508.047638 
	C648.692749,510.985229 648.692749,510.985229 640.594238,514.903259 
	C640.740356,516.661621 642.228760,516.314636 643.209412,516.361328 
	C646.071350,516.497559 648.822144,516.877136 651.352417,518.360962 
	C653.020691,519.339233 654.836487,519.346924 656.671204,518.830811 
	C657.797546,518.513977 658.970764,518.148499 660.096497,518.608582 
	C661.877869,519.336487 664.457947,519.684692 664.243713,522.180969 
	C664.042969,524.520081 661.570557,524.557190 659.854126,524.422668 
	C653.755188,523.944641 648.157898,526.254578 642.470703,526.921753 
	C642.091736,527.832764 642.491699,528.459717 642.846497,528.421570 
	C648.431152,527.821533 653.695923,531.321655 659.380188,529.650574 
	C660.791504,529.235596 662.364563,529.397461 663.849548,529.616455 
	C665.320374,529.833374 667.118164,529.964661 667.309998,531.829163 
	C667.524841,533.916382 665.708435,534.519592 664.117554,535.058044 
	C662.846069,535.488342 661.491760,535.587097 660.168518,535.511292 
	C657.292480,535.346375 655.742126,536.298340 656.119019,539.497986 
	C656.324707,541.244873 655.296570,542.587280 654.026245,543.601624 
	C657.257263,544.722961 660.484314,543.945312 663.739807,543.563416 
	C665.880859,543.312317 668.067871,543.324951 670.195190,543.829712 
	C673.097961,544.518433 673.460876,546.804993 673.477112,549.252258 
	C673.496216,552.127258 671.317627,552.352661 669.317261,552.372864 
	C667.182983,552.394470 665.044250,551.853760 662.756775,552.568848 
	C665.539612,551.852112 667.936218,552.343445 670.168335,553.871948 
	C671.702820,554.922668 673.403564,555.922668 673.372009,558.007385 
	C673.339111,560.177002 671.142334,560.145020 669.570557,561.218872 
	C668.719971,562.161865 668.294861,562.980225 667.592224,564.030396 
	C664.922791,565.504883 662.969116,567.821289 659.897278,567.618408 
	C658.760864,567.543274 657.421631,567.216919 656.904541,568.593628 
	C656.269897,570.283264 657.556091,571.334473 658.618774,572.336914 
	C660.384521,574.002502 663.473694,573.673462 664.734070,576.630493 
	C664.154968,582.146118 659.193176,583.990601 656.393005,587.967896 
	C655.791809,588.579712 655.343750,588.859192 654.655640,589.249512 
	C653.544373,589.387512 653.051819,590.059265 652.210938,590.712524 
	C651.681030,591.075867 651.376892,591.249451 650.817078,591.486328 
	C649.211548,591.712524 647.921753,592.310181 646.146729,591.997437 
	C643.527954,590.582947 644.494690,588.774292 645.050537,587.066406 
	C645.617371,585.324707 646.355103,583.652954 645.195251,581.828003 
	C640.589539,574.581360 638.325623,566.091248 633.191040,559.126221 
	C631.673035,557.067017 632.398926,555.029846 633.004150,552.610779 
	C633.157959,549.735474 634.728577,547.778564 635.782593,545.262451 
	C635.913818,544.525391 635.987732,544.111816 636.131104,543.390808 
	C637.550964,539.210938 637.017212,535.526550 635.837891,531.619812 
	C634.389038,526.820129 631.002441,523.115356 629.290588,518.505798 
	C628.779175,517.128662 627.314636,516.343140 625.439331,516.126648 
	C625.072144,515.551819 624.901489,515.268250 624.591187,514.778992 
	C624.775574,514.029907 624.663757,513.610718 623.801025,513.484070 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M644.656128,591.036377 
	C646.544922,591.160950 648.101196,591.199097 650.109436,591.393433 
	C647.494324,594.135681 644.052368,595.483643 639.969360,595.954102 
	C631.580505,596.920654 623.227722,598.054016 614.754272,597.950806 
	C609.455322,597.886230 604.268250,596.331421 598.478271,596.897217 
	C598.021484,596.938049 597.674438,596.812744 597.490479,596.806885 
	C594.014709,595.425049 590.722839,594.049133 587.183594,592.419067 
	C587.071716,589.473877 589.154968,590.027649 590.434265,590.506042 
	C593.721741,591.735474 596.434753,591.281128 598.596863,588.575439 
	C596.694275,587.933533 593.259888,589.271423 592.745361,586.059387 
	C592.304688,583.308411 595.526367,583.363525 597.264221,582.344116 
	C594.285339,578.727600 594.485779,577.987854 599.358154,575.666260 
	C600.523621,575.573242 601.355286,575.532959 602.403259,575.537598 
	C601.602905,575.346069 600.985229,575.453430 600.047729,575.307129 
	C599.250305,575.151550 598.818970,574.978271 598.131958,574.530212 
	C598.010254,569.086548 601.637085,566.392090 605.736694,564.725342 
	C613.039185,561.756775 620.507874,560.811646 627.650208,565.553345 
	C628.751526,566.284546 629.981140,566.839600 631.215881,567.314148 
	C636.021118,569.160706 636.039062,569.145264 633.578979,573.398926 
	C632.120117,575.921387 633.501038,577.351440 635.843384,578.628906 
	C639.881287,579.574707 640.391541,582.476562 640.469299,585.583435 
	C640.536987,588.287170 641.147034,590.518127 644.656128,591.036377 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M557.064514,460.286987 
	C561.346252,452.750458 567.135986,446.750671 573.432495,440.767273 
	C574.131165,440.890167 574.542053,441.229279 574.746826,440.110596 
	C574.837952,439.841064 574.832275,439.913055 574.848511,439.882202 
	C576.166321,439.155212 577.667480,438.915894 579.068359,437.820038 
	C579.330505,437.373627 579.433899,437.191071 579.756592,436.777832 
	C580.727173,436.072601 581.296692,435.413300 582.152161,434.586182 
	C582.944702,434.401978 583.402405,434.470795 584.143677,434.839661 
	C586.919250,439.998047 592.012390,443.560944 591.550049,450.182037 
	C591.411499,452.166168 595.176453,454.028290 593.130066,457.342651 
	C589.667114,459.230835 586.366394,458.457245 583.095825,457.992706 
	C578.525818,457.343597 573.974121,456.933228 568.147034,458.336182 
	C572.457458,461.171814 576.657349,460.471130 580.106445,461.998291 
	C581.447266,462.591980 583.037476,462.519440 584.507385,462.788971 
	C592.699890,464.291107 593.685791,466.494232 589.375366,474.187317 
	C589.306396,474.564545 589.356262,474.840912 589.259888,474.942993 
	C584.853455,476.377472 585.462036,480.727325 583.668579,484.023193 
	C581.250305,487.488220 580.770569,491.664520 577.372192,494.503540 
	C571.835022,494.621216 564.906799,487.773895 565.632324,483.104706 
	C566.137085,479.855988 568.928589,482.108124 570.436829,481.469818 
	C563.307739,480.764252 561.846069,478.198914 565.422791,472.461914 
	C566.947205,470.016815 565.128174,465.652771 561.876648,464.549957 
	C559.710938,463.815460 557.818848,463.034424 557.064514,460.286987 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M668.041626,561.339111 
	C668.100891,560.682800 668.216187,560.370239 668.283691,560.047668 
	C668.744019,557.846558 673.519897,560.191223 672.067017,557.231201 
	C671.051819,555.162659 668.030334,553.200378 665.000610,553.588562 
	C662.284424,553.936523 660.040405,553.039185 657.664124,552.337341 
	C657.674744,551.891541 657.685364,551.445801 657.695984,551.000000 
	C661.548401,551.000000 665.401123,551.025208 669.253174,550.988098 
	C670.875244,550.972473 672.930237,551.275879 672.842468,548.752747 
	C672.765381,546.535217 671.677124,544.812195 669.128967,545.052429 
	C665.919739,545.355042 662.773315,545.156067 659.446228,546.001831 
	C656.392822,546.778076 652.448730,544.696045 648.744263,544.759521 
	C648.839539,542.491882 650.359558,543.081421 651.324158,543.031616 
	C654.865051,542.848511 655.980835,540.499329 654.541260,537.980225 
	C652.317566,534.089050 654.652649,534.257690 657.193787,533.992981 
	C660.295654,533.669861 663.786926,535.068604 667.005676,531.734924 
	C663.770081,530.535339 660.941956,530.714539 658.341919,531.225830 
	C654.989136,531.885193 651.833252,532.770874 648.980103,530.057495 
	C647.960571,529.087891 647.259521,530.022461 646.390564,530.472290 
	C644.370789,531.517639 642.579407,530.884094 640.874817,529.531738 
	C639.774536,528.658813 638.726624,527.848145 639.307129,526.268860 
	C639.826843,524.854919 641.140930,525.114624 642.271973,525.010620 
	C649.322876,524.362366 656.113586,521.877930 663.386230,522.394165 
	C661.157104,520.972473 659.310608,518.574463 656.529602,520.801453 
	C656.102661,521.143250 655.173584,521.140991 654.585693,520.954407 
	C649.497620,519.339355 644.485535,517.540283 639.033142,517.349121 
	C637.500671,517.295349 636.895325,515.889832 638.094849,514.406555 
	C638.915100,513.392456 639.511414,512.236084 641.080322,512.008423 
	C643.242493,511.694672 646.897217,513.146118 647.169861,510.419891 
	C647.454834,507.570068 643.099487,510.229828 641.373840,507.722046 
	C646.083069,506.311707 650.654785,507.336792 655.584229,507.004181 
	C659.257935,507.456604 659.500549,510.809601 661.668152,512.753906 
	C666.018494,518.022095 667.779541,524.216064 670.375427,530.374695 
	C669.778931,532.298462 670.310974,533.587158 671.539734,534.925293 
	C675.257202,545.117554 675.556946,555.239502 672.258301,565.710938 
	C670.142578,568.522644 669.965210,571.817017 668.135193,574.704102 
	C667.894836,575.572205 667.681335,576.109192 667.229004,576.815552 
	C666.387634,577.556335 665.679077,577.768311 664.531860,577.409180 
	C662.432251,573.782166 657.035339,575.720825 655.718750,571.368042 
	C655.328674,570.078552 653.486755,569.123779 655.026184,567.578552 
	C656.210449,566.389832 657.193909,564.276917 659.462219,565.976990 
	C662.606262,568.333435 663.394165,562.614258 666.626953,564.000854 
	C667.668213,563.367004 668.315552,562.727905 668.041626,561.339111 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M653.271423,418.686493 
	C631.419922,425.462158 615.348755,439.513855 601.255676,456.830505 
	C600.546021,457.391602 600.043579,457.539001 599.110229,457.293365 
	C597.992859,456.231537 597.870544,455.198242 598.159912,453.727173 
	C598.844604,452.176331 600.042419,451.640564 601.172241,450.525269 
	C604.203613,447.622772 606.107483,444.241211 608.794556,441.501007 
	C610.760437,439.496216 610.262268,437.213593 608.101990,436.020660 
	C604.319763,433.932068 601.304626,431.470947 600.254578,427.058502 
	C600.080750,426.327728 598.956177,426.090668 598.042969,425.339783 
	C597.528870,422.447662 599.774719,422.191437 601.337952,421.178528 
	C603.334351,419.884949 606.158081,420.440979 607.877930,417.911377 
	C608.515137,417.003479 609.090820,416.578094 610.146240,416.262512 
	C613.995544,416.409485 617.245544,415.589752 620.576660,413.669067 
	C621.321106,413.341156 621.770569,413.228271 622.581787,413.167358 
	C624.955505,413.617554 626.959595,413.900848 629.064819,412.263000 
	C630.353271,411.782166 631.341797,411.745972 632.719543,411.870789 
	C636.316101,412.893097 639.572571,412.709686 643.210327,412.173584 
	C644.078796,412.063934 644.574585,412.068787 645.423584,412.208984 
	C648.434753,414.096558 651.519897,413.724640 654.827026,414.078186 
	C655.391418,414.265320 655.607361,414.371216 656.076294,414.736786 
	C657.204895,417.599243 654.632263,417.426239 653.271423,418.686493 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M608.283264,474.252747 
	C606.796997,474.282562 604.214783,473.490723 606.294556,476.249329 
	C609.231689,480.145050 611.669800,484.030701 609.522034,489.340973 
	C608.665039,491.459808 610.845276,492.421448 612.616699,493.035492 
	C614.521729,493.695892 615.509216,494.988739 614.484131,496.815674 
	C612.184082,500.915039 612.634399,503.963593 617.168152,506.050568 
	C617.664307,506.278961 617.699036,507.510010 617.941956,508.653625 
	C617.235229,509.698212 616.419495,509.818512 615.128235,509.348572 
	C606.593750,504.924377 597.513855,504.550232 588.746765,502.968750 
	C581.316040,501.628387 581.169495,501.763519 584.845154,494.862244 
	C588.818298,487.402557 592.998657,480.052307 597.151978,472.690094 
	C598.395325,470.486206 600.149231,469.284058 602.901611,470.220367 
	C607.573425,471.809662 612.515381,472.586639 617.106201,475.187988 
	C616.779358,475.622498 616.296204,475.764282 615.434082,475.929993 
	C612.715759,476.257812 610.694824,475.153259 608.283264,474.252747 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M635.494263,544.980225 
	C638.505310,548.025635 633.543457,548.809631 633.874146,551.561523 
	C632.047668,556.060364 627.734680,556.341125 624.033386,558.237793 
	C618.973633,559.611084 614.223694,560.375488 608.980164,560.287476 
	C598.562561,558.740906 588.985596,555.696411 579.525391,552.079163 
	C576.194397,550.805542 573.432312,548.799316 570.424194,547.099121 
	C567.916016,545.681458 565.839844,545.214661 564.960999,549.018921 
	C564.355530,551.639587 562.317444,553.571289 560.575195,556.006653 
	C559.452454,557.580078 558.548035,558.875549 557.947266,560.729004 
	C556.881897,562.589172 556.188416,564.264099 555.102417,566.094666 
	C554.378723,567.334717 553.056030,567.924744 553.646423,569.592712 
	C554.930176,571.130371 556.505798,572.008240 557.325195,573.962402 
	C557.295227,575.022949 556.945068,575.573547 556.043945,576.157349 
	C552.375244,575.081177 549.302856,573.259705 547.363708,569.270996 
	C549.848511,564.073914 553.618469,560.193787 554.814087,554.529419 
	C555.280823,553.507080 555.767456,552.978088 556.797852,552.991699 
	C560.836670,550.641235 561.813477,546.461182 562.699951,542.459961 
	C563.558655,538.584229 564.793457,538.736328 567.571350,540.856689 
	C579.851440,550.230530 594.041077,554.388977 609.224365,555.768127 
	C615.873962,556.372070 621.965393,554.131714 628.130981,552.298462 
	C631.750916,551.222168 633.040771,547.676819 635.494263,544.980225 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M598.604126,456.692444 
	C599.422485,456.984497 599.869446,456.984558 600.650513,457.005371 
	C598.103088,463.274536 593.221680,468.237946 589.620117,474.394928 
	C589.268677,474.235168 589.134155,473.882416 589.207336,473.579834 
	C591.321594,464.833252 591.274780,464.813843 581.769470,463.963043 
	C577.544189,463.584900 573.676270,461.456635 569.233643,461.694946 
	C566.921997,461.818939 566.869446,459.145020 566.862549,457.399750 
	C566.853821,455.203796 568.789490,456.242340 569.904907,456.030731 
	C577.371399,454.614624 584.660767,457.377045 592.540039,457.013947 
	C594.599731,455.617157 596.339111,455.350464 598.604126,456.692444 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M553.609375,548.561646 
	C552.948425,547.179016 553.043335,545.952332 553.901855,544.450317 
	C552.598328,542.649841 553.169128,541.067871 553.874451,539.554199 
	C557.334229,532.129333 562.262573,525.439209 564.832642,517.558655 
	C565.237183,516.318115 566.302979,515.394165 567.928711,514.725220 
	C568.557922,514.669128 568.812683,514.709167 569.232727,515.098999 
	C566.573547,525.575317 560.863037,534.440430 556.883423,544.719482 
	C556.832214,545.992859 556.601562,546.549988 556.145813,547.345093 
	C555.848999,547.758484 555.715576,547.913391 555.336121,548.252686 
	C554.709229,548.600220 554.310913,548.672058 553.609375,548.561646 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M576.809753,494.160004 
	C578.811340,490.675476 580.574280,487.440613 582.674316,484.113647 
	C583.218079,484.131409 583.424683,484.241272 583.799011,484.659424 
	C579.299622,494.999054 574.632507,505.030365 569.681763,515.255188 
	C569.398010,515.448730 569.173340,515.206116 569.058350,515.087402 
	C567.297974,514.196655 567.569153,512.888123 568.143799,511.649841 
	C570.836853,505.847046 571.246765,498.924194 576.809753,494.160004 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M625.254333,516.193237 
	C626.739502,515.571655 628.223328,515.473022 629.210327,516.994568 
	C633.982422,524.351624 638.817932,531.695068 637.765259,541.106812 
	C637.699219,541.697449 637.199341,542.239563 636.550842,542.943787 
	C636.304260,532.696899 632.269592,524.011536 625.254333,516.193237 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M557.058655,552.850647 
	C556.836487,553.691345 556.596558,554.138428 556.214111,554.798706 
	C553.696716,556.804504 553.687500,560.018921 551.816956,562.103699 
	C550.622437,563.435181 549.612854,565.008789 547.375610,565.590210 
	C546.764221,565.564575 546.523071,565.505615 545.989136,565.215576 
	C545.107788,563.806824 545.331543,562.629089 546.154907,561.769714 
	C549.857483,557.905334 551.367371,552.694580 554.655090,548.152466 
	C554.855469,547.842712 554.978027,547.969971 554.905151,547.920044 
	C555.861938,547.374817 556.165527,546.346619 556.722961,545.137573 
	C556.823547,544.844238 556.826172,544.807800 556.813354,544.791870 
	C558.659668,545.079407 560.815308,545.146851 558.727112,547.929749 
	C557.740234,549.244934 556.977112,550.715698 557.058655,552.850647 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M661.859497,512.654907 
	C658.673462,512.522095 658.395813,509.225555 656.307434,507.209900 
	C652.964905,503.551575 650.122925,499.924103 648.039185,495.377869 
	C654.369995,499.374237 658.276917,505.694153 661.859497,512.654907 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M626.213135,482.236725 
	C626.022644,481.788483 626.003723,481.621674 625.949707,481.205505 
	C625.830200,480.724274 625.745850,480.492432 625.923889,479.999634 
	C633.363220,482.023376 638.874084,486.963409 644.676758,491.891235 
	C644.223328,492.465149 643.816528,492.534576 643.149597,492.784180 
	C642.450989,492.676025 642.015564,492.405334 641.353027,492.014923 
	C640.906921,491.823883 640.687805,491.752594 640.162048,491.521301 
	C636.785645,489.439728 632.997864,488.648865 630.422607,485.325806 
	C629.077148,483.994904 627.573364,483.485870 626.213135,482.236725 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M663.044556,422.688995 
	C661.928833,422.999176 660.864990,422.999573 659.402039,423.000000 
	C658.802002,423.249512 658.568054,423.462799 658.143188,423.818115 
	C657.985352,423.996338 657.500977,423.996185 657.259033,423.995667 
	C657.030518,422.411713 656.802612,421.013123 654.371948,421.010010 
	C655.328979,418.825806 658.181946,419.677338 659.907593,417.767700 
	C660.087036,417.214172 660.153687,416.967438 660.562500,416.547424 
	C661.421143,416.280853 661.919556,416.340027 662.692017,416.799591 
	C663.577332,418.813019 665.259338,420.555664 663.044556,422.688995 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M664.116333,577.529358 
	C664.896240,577.132324 665.590820,577.059509 666.637695,576.985840 
	C664.813232,581.776306 661.257568,585.324646 656.576538,588.230957 
	C655.951782,585.002563 659.184814,584.173401 660.632385,582.091675 
	C661.662231,580.610718 663.405396,579.679321 664.116333,577.529358 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M584.232849,484.758240 
	C583.676147,484.848755 583.431946,484.667358 583.122803,484.222595 
	C582.015015,481.856628 587.727417,478.368896 580.969849,477.322144 
	C583.522156,475.317413 586.295105,476.348450 588.756836,475.072021 
	C588.300720,478.552643 585.955933,481.332794 584.232849,484.758240 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M668.408813,574.864868 
	C667.695923,571.541382 669.628723,568.887695 671.635010,566.059204 
	C671.828003,569.237488 670.621094,572.098022 668.408813,574.864868 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M614.843201,509.455872 
	C615.538025,509.112396 616.339417,509.066528 617.538452,509.025970 
	C619.257385,509.760071 620.837769,510.217560 621.124146,512.379578 
	C620.178711,513.095520 619.421204,512.578796 618.317688,512.066711 
	C617.985901,512.010376 617.666992,511.866608 617.494629,511.840790 
	C616.531372,511.127808 615.740540,510.440643 614.843201,509.455872 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M661.382080,416.668701 
	C661.175049,417.123566 660.937317,417.165619 660.359924,417.334290 
	C657.921265,417.962341 655.822144,418.463837 653.358948,418.943420 
	C653.159363,416.891541 656.133057,417.326324 656.315186,414.860779 
	C658.550110,413.681671 660.167053,414.364105 661.382080,416.668701 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M648.653503,422.059387 
	C649.078674,426.131805 645.958069,424.706635 643.414551,425.001312 
	C644.768188,424.034607 646.538818,423.068420 648.653503,422.059387 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M671.813843,534.740295 
	C671.298157,535.391602 670.795227,535.710571 670.292297,536.029480 
	C669.857178,534.439636 668.326355,532.962952 670.082886,530.966675 
	C670.858704,531.973572 671.342651,533.190796 671.813843,534.740295 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M623.020020,478.315338 
	C621.951843,480.594147 620.644592,480.096069 619.142090,478.280273 
	C619.457458,477.569763 620.016113,477.367889 621.097534,477.461792 
	C621.980103,477.750458 622.414185,477.949066 623.020020,478.315338 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M621.363281,477.268188 
	C620.979065,477.677216 620.412170,477.802582 619.409180,477.952667 
	C618.973145,477.977356 618.561584,477.944275 618.355103,477.946655 
	C617.646301,477.571594 617.455139,477.074280 617.844727,476.123688 
	C619.353333,475.633392 620.298401,476.228241 621.363281,477.268188 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M652.301392,590.929688 
	C652.059265,589.820496 652.520325,589.131226 654.067993,589.276062 
	C654.140137,590.257263 653.492798,590.735474 652.301392,590.929688 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M646.882568,493.727417 
	C646.395752,493.818085 645.833801,493.626801 645.066040,493.211487 
	C644.860229,492.987427 644.503052,492.687347 644.618286,492.484558 
	C644.733521,492.281769 644.891418,492.117950 644.971191,492.036865 
	C645.636536,492.452301 646.222046,492.948853 646.882568,493.727417 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M617.910217,475.699524 
	C618.105347,476.270477 618.095215,476.751160 618.116882,477.590393 
	C617.816528,477.421753 617.484375,476.894501 617.208801,476.032104 
	C617.265320,475.696991 617.705017,475.609192 617.910217,475.699524 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M625.122253,479.556519 
	C624.861511,479.675629 624.534058,479.475372 624.050476,479.024292 
	C624.281433,478.928009 624.668457,479.082581 625.122253,479.556519 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M623.555359,513.888428 
	C623.804932,513.765320 624.146301,513.864624 624.481201,514.261719 
	C624.131592,514.534119 623.855774,514.384583 623.555359,513.888428 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M625.956177,479.632446 
	C626.134644,479.940338 626.083008,480.142090 625.973022,480.650024 
	C625.694946,480.693237 625.475342,480.430328 625.243103,479.899536 
	C625.395752,479.596527 625.560974,479.561432 625.956177,479.632446 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M650.040771,733.341187 
	C647.930603,734.627136 645.731018,734.087341 643.258423,733.453918 
	C645.255615,731.092957 647.594421,731.536011 650.040771,733.341187 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M626.118408,730.371460 
	C625.554688,731.169434 624.948853,731.015930 624.159424,730.458008 
	C624.620422,728.849670 625.344421,729.156799 626.118408,730.371460 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M638.138733,732.271240 
	C637.584106,733.081482 636.956787,733.017944 636.148315,732.408813 
	C636.607727,730.716675 637.345398,731.137695 638.138733,732.271240 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M432.758789,423.865082 
	C432.463379,423.097412 432.588867,422.375977 432.871460,421.321350 
	C434.336395,421.001312 435.661682,421.152618 436.947144,420.989685 
	C438.286438,420.819916 440.571350,423.026276 440.806702,420.112183 
	C440.984772,417.907104 444.530975,415.535431 440.836182,413.344177 
	C439.099396,412.314148 439.466125,409.566162 436.831665,408.915131 
	C436.619507,408.287140 436.788910,407.729340 437.155029,406.952026 
	C442.606079,405.819366 442.797150,405.368225 439.351379,401.238525 
	C439.153290,400.628998 439.181549,400.257660 439.249573,399.607300 
	C439.415802,398.540955 440.043488,398.028687 440.467773,397.084167 
	C441.213501,395.525238 441.864838,394.235077 442.787079,392.793884 
	C446.642853,392.912537 450.546967,392.774658 448.171875,398.457733 
	C447.537567,399.975525 448.618469,401.315216 450.157867,402.042755 
	C451.066223,402.472046 451.995728,403.031311 452.938049,402.266144 
	C454.216766,401.227722 453.725128,399.961334 453.416107,398.616333 
	C453.085785,397.178619 453.091156,395.657532 453.013489,394.169495 
	C452.892853,391.858185 453.771545,390.898438 456.303467,391.209900 
	C461.648102,391.867310 464.287903,389.292694 463.423004,384.027710 
	C462.932831,381.043854 464.495605,380.816956 466.455627,381.040619 
	C471.410797,381.606171 475.885223,379.188782 480.695465,378.950195 
	C481.530609,378.908783 482.462769,378.794220 482.623840,377.762665 
	C482.840759,376.373505 481.788239,376.069000 480.758331,376.057892 
	C477.651764,376.024261 475.013000,373.928070 471.548584,374.728119 
	C468.454071,375.442780 468.222382,370.845306 465.566803,369.792450 
	C465.178314,369.638428 466.815948,367.771210 467.871033,368.672180 
	C471.837860,372.059631 474.932434,366.969910 478.619904,367.740875 
	C479.483246,367.921356 479.938324,366.778839 479.734283,365.892487 
	C478.377594,359.997589 482.840424,357.128998 486.370239,353.058411 
	C485.211212,357.976532 486.424072,359.576233 491.150909,358.160065 
	C493.600220,357.426239 496.456696,358.162567 499.105957,357.947662 
	C501.014496,357.792786 504.667023,359.570526 502.763580,354.455139 
	C501.946564,352.259521 505.167847,353.123260 506.539398,353.060303 
	C509.833282,352.909149 513.417725,353.629578 514.291504,348.513672 
	C512.132690,347.506409 509.637268,348.212097 507.278412,347.988403 
	C506.070984,347.873901 504.812592,347.692291 504.501434,346.348969 
	C504.157898,344.865692 505.360413,344.401093 506.509094,343.995300 
	C509.237732,343.031250 511.816559,342.027191 513.966797,345.288879 
	C515.632263,347.815247 519.068054,347.977966 521.431702,348.504578 
	C525.114807,349.325134 526.365112,351.900665 528.473633,353.906250 
	C527.632263,355.778046 523.865845,355.194153 524.850403,357.340057 
	C525.895874,359.618622 529.056824,359.736969 531.044067,358.308319 
	C533.846252,356.293793 537.382141,356.483093 540.136108,354.587494 
	C541.438660,353.690948 542.767456,354.162720 543.817078,355.068451 
	C545.399414,356.433868 543.629578,356.946045 542.989136,357.717987 
	C541.491699,359.522736 542.475769,361.430511 542.502563,363.373566 
	C542.531311,365.453247 545.990662,367.560852 542.593628,369.717285 
	C542.068481,370.050690 542.544373,370.984161 543.161072,371.342499 
	C544.267334,371.985291 545.340332,371.496826 545.568604,370.476624 
	C546.361206,366.934296 548.968994,367.182739 551.710388,366.873230 
	C554.465210,366.562195 557.588196,365.585907 554.055847,361.988373 
	C552.360901,360.262054 553.737061,359.649017 554.780945,358.465912 
	C557.263000,355.652832 561.272949,354.788788 563.244934,351.161560 
	C564.082336,349.621277 567.225220,348.974274 568.497070,350.901031 
	C570.458435,353.872528 573.492126,354.552887 576.631470,355.948212 
	C582.063171,359.359619 587.315491,359.504181 593.390076,358.777466 
	C598.504944,358.165558 603.881714,360.819000 608.805420,364.005859 
	C609.687866,369.598633 614.603149,367.251923 617.568909,369.072540 
	C618.025757,369.401733 618.229004,369.502930 618.711548,369.799194 
	C624.550354,373.477661 631.271118,375.047150 635.809875,380.218933 
	C636.712891,381.247986 638.536560,381.109344 637.788025,383.819305 
	C637.134155,386.186646 640.554260,383.236725 641.535522,384.962677 
	C641.539307,386.506256 640.271729,387.148315 639.232361,387.916473 
	C638.086121,388.763672 637.646667,390.002014 638.685059,390.853455 
	C641.789185,393.398773 640.522888,395.912933 639.918579,399.188904 
	C639.277832,402.662415 643.246216,404.792847 645.903137,406.899292 
	C647.281189,407.991821 649.430908,408.150909 649.947632,410.657867 
	C647.454041,413.085571 644.660156,410.838440 641.631348,411.474060 
	C640.152039,411.456909 639.079590,411.443176 637.613770,411.442902 
	C636.189819,411.426575 635.160461,411.393311 633.786743,411.103302 
	C631.127869,409.721313 628.831970,408.473389 626.297974,407.624146 
	C623.028931,406.528687 623.074341,406.393097 620.492432,408.524109 
	C616.840027,406.170746 613.813660,403.419342 609.450928,401.523254 
	C609.072876,399.485260 608.710754,398.222687 607.320374,397.164154 
	C599.528198,391.231750 591.098389,386.711121 581.596497,384.009796 
	C575.811157,382.365112 570.146545,380.565613 564.203674,379.300446 
	C552.608948,376.832092 541.302734,376.247040 529.825256,379.214325 
	C526.512695,380.070709 523.319763,381.671844 519.676575,380.224731 
	C518.223816,379.647675 517.018555,380.396912 517.679504,382.371246 
	C518.342224,384.350922 517.252380,385.739838 515.741455,386.502960 
	C507.279175,390.777100 503.438110,398.325867 500.877289,406.881927 
	C500.373871,408.563873 499.887299,410.182373 498.124329,411.390045 
	C496.238434,412.681885 496.622314,414.889221 497.934235,416.647522 
	C501.199432,421.023743 500.987640,422.446289 496.354401,425.168732 
	C495.503784,425.668549 495.084473,426.248688 495.317780,427.137390 
	C495.537750,427.975250 496.281952,428.939087 497.022766,428.687042 
	C502.621002,426.782318 502.840607,432.030548 503.305756,434.459961 
	C504.109375,438.657196 508.732452,443.231171 503.268066,447.625671 
	C501.702576,448.884613 500.416656,449.631592 499.111145,447.904785 
	C495.825745,443.559113 490.967957,442.432831 486.133423,441.242737 
	C484.037048,440.726654 482.178101,440.381683 480.723267,438.273438 
	C478.315887,434.784851 476.290436,440.145294 473.398224,439.013458 
	C471.112152,435.177399 468.026459,432.018677 466.682220,427.865845 
	C466.312714,426.724274 465.734589,425.441193 464.315369,425.834625 
	C459.511414,427.166229 454.316132,425.437042 449.331970,427.894501 
	C444.281097,424.489685 438.627258,424.599274 432.758789,423.865082 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M640.777283,410.340820 
	C643.811462,409.739929 646.498108,410.337799 649.584473,410.996124 
	C652.388306,411.342316 654.786377,411.678497 657.597107,412.009827 
	C660.080139,410.701080 661.686340,411.416046 663.014282,413.580200 
	C662.981934,414.757782 662.990173,415.520172 662.991333,416.664978 
	C662.633728,417.056702 662.283264,417.065979 661.672729,417.078369 
	C660.673950,415.067535 658.799683,414.951630 656.760132,414.499268 
	C656.235352,414.469421 656.069153,414.453125 655.655396,414.474670 
	C652.435730,414.652527 649.544128,414.046082 646.296143,413.352386 
	C645.515198,413.074554 645.076843,413.011810 644.305908,412.955292 
	C642.961609,413.772430 641.930969,413.582458 640.673157,412.666077 
	C640.312683,411.759399 640.339478,411.214722 640.777283,410.340820 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M618.194580,369.753845 
	C616.646667,369.312103 615.120239,368.439880 613.769043,369.303894 
	C608.918518,372.405365 609.025574,369.085815 609.002075,365.401855 
	C608.372742,364.213959 608.244995,363.351807 608.961243,362.108032 
	C610.341187,362.160309 611.378479,362.520294 612.729248,362.998840 
	C613.317688,363.362946 613.610657,363.584564 614.196045,363.812988 
	C614.828003,363.937439 615.185730,364.031036 615.763428,364.299683 
	C616.135376,364.639252 616.287476,364.803741 616.515015,365.295654 
	C616.590454,365.623108 616.922668,365.291718 617.029846,365.346466 
	C617.306213,366.077057 617.661743,366.679169 617.942810,367.672028 
	C617.986328,368.016083 617.995605,368.508636 617.999023,368.754944 
	C618.136292,369.170105 618.270081,369.338898 618.194580,369.753845 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M689.000000,427.574371 
	C687.705811,427.801300 686.280579,427.875610 685.151062,427.333679 
	C683.789795,426.680542 682.693115,425.407562 683.355164,423.673645 
	C683.909790,422.220947 685.276001,422.003571 686.687683,422.043884 
	C688.407166,422.093018 688.973694,423.118011 688.981384,424.653839 
	C688.985535,425.485474 688.993713,426.317108 689.000000,427.574371 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M663.287720,413.944763 
	C661.831543,412.725067 660.128479,412.713409 658.353821,412.047668 
	C658.311890,410.609863 657.424011,407.697693 659.755493,408.562378 
	C661.308044,409.138184 665.630676,409.836731 663.287720,413.944763 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M690.754822,429.334503 
	C691.326599,428.999878 691.678284,428.919800 691.975708,429.012878 
	C693.601685,429.521606 696.743164,427.753448 696.561523,430.829681 
	C696.393311,433.678680 693.655701,433.181213 691.261353,432.264954 
	C690.479797,431.298828 690.279968,430.529053 690.754822,429.334503 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M665.189453,404.324463 
	C665.811523,404.446808 666.367249,404.791382 666.768616,405.266327 
	C667.517883,406.152893 668.361267,407.122314 667.405396,408.323029 
	C667.180115,408.605957 666.466980,408.748016 666.093445,408.619659 
	C664.889038,408.205872 665.078857,407.066925 665.018494,406.100952 
	C664.987549,405.605133 665.039185,405.104218 665.189453,404.324463 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M690.822693,428.765381 
	C691.000000,429.750275 691.000000,430.500580 691.000000,431.626038 
	C689.583313,431.352570 688.965515,430.222687 689.004272,428.347412 
	C689.548462,428.176910 690.096924,428.353851 690.822693,428.765381 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M618.283508,367.983978 
	C616.531677,367.923401 616.444275,367.119354 617.039917,365.705841 
	C617.972656,365.731232 618.808289,366.061249 619.825073,366.689056 
	C619.531006,367.308533 619.055847,367.630219 618.283508,367.983978 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M618.236694,369.998260 
	C617.985352,370.000000 617.992554,369.500580 617.997498,369.250916 
	C618.309631,369.119873 618.562744,369.310333 618.876282,369.783447 
	C618.823242,369.994965 618.655640,369.995728 618.236694,369.998260 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M680.385864,631.913574 
	C681.188965,628.210754 678.163208,627.847290 676.263428,627.343140 
	C671.522156,626.084717 670.034790,622.233459 668.380127,618.498596 
	C667.394409,616.273499 668.294556,613.927490 670.162354,612.806152 
	C672.620728,611.330383 675.535461,611.445129 678.146606,612.913269 
	C678.970520,613.376587 679.587219,614.208557 680.658936,614.939087 
	C681.104248,615.413452 681.189514,615.820496 681.294678,616.538696 
	C679.190552,616.570679 677.072205,616.228821 674.940369,616.035095 
	C673.595398,615.912964 672.141663,616.009277 671.507385,617.469238 
	C670.983337,618.675659 671.795105,619.744446 672.443604,620.674805 
	C673.813660,622.640442 675.643311,623.549133 678.104187,624.204712 
	C682.271545,625.314880 684.871521,628.627441 686.412109,632.722595 
	C687.507507,635.634583 685.857727,637.472961 684.131958,639.307251 
	C683.579163,639.894897 682.660889,640.804810 682.152222,640.667786 
	C674.874573,638.706421 668.575806,644.594543 661.284851,643.122803 
	C658.674622,642.595886 657.375183,641.632812 657.046021,639.202209 
	C656.233704,633.203064 652.523560,628.156555 651.506714,622.217468 
	C651.346802,621.283081 650.705078,620.368103 649.144287,620.267273 
	C648.771301,620.299866 648.794067,620.226562 648.825684,620.204834 
	C648.769592,619.841553 648.599731,619.549255 648.122803,619.164490 
	C647.910217,619.015259 647.768188,618.537170 647.776367,618.287964 
	C648.697571,616.314819 647.333618,613.882385 649.768738,612.274597 
	C651.058228,611.910767 652.072571,611.818726 653.465942,611.738770 
	C655.316895,613.724792 657.512329,612.690063 659.365479,613.077637 
	C661.124634,613.445679 662.633545,614.377991 662.675232,616.218872 
	C662.727295,618.520203 660.663513,617.977417 659.328735,617.956909 
	C656.727417,617.917053 654.709290,618.184631 654.997681,621.619446 
	C655.274414,624.916687 657.432983,625.480652 659.863342,624.790833 
	C662.135925,624.145752 663.474854,626.377441 665.870850,625.981689 
	C667.210632,626.469666 667.633545,627.275513 667.514526,628.679993 
	C666.244812,630.702393 664.477051,631.065186 662.609741,631.089233 
	C659.932312,631.123596 660.139648,632.621826 661.063843,634.646851 
	C661.451904,635.027222 661.610840,635.176636 662.012573,635.545166 
	C664.203613,637.251953 667.117554,636.978638 668.340637,636.164551 
	C671.944092,633.766357 675.594604,634.491211 679.432739,634.305420 
	C679.672913,633.362244 679.887390,632.751343 680.385864,631.913574 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M649.638428,611.939636 
	C649.490295,613.872437 648.936584,615.742065 648.083679,617.825195 
	C646.957397,615.676819 644.783020,612.998230 649.638428,611.939636 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M647.948425,619.290161 
	C648.179138,619.233337 648.448059,619.451416 648.787109,619.926270 
	C648.567139,619.977112 648.276855,619.771118 647.948425,619.290161 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M663.640747,430.041687 
	C665.516296,430.093872 666.582275,430.752838 666.962769,432.661194 
	C666.765137,433.216370 666.442017,433.389984 666.298828,433.659271 
	C665.099915,435.915009 663.186951,436.381470 660.913513,435.759674 
	C659.910217,435.485291 659.132751,434.701660 659.190613,433.632385 
	C659.326233,431.124207 661.495544,430.791534 663.640747,430.041687 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M845.658813,586.040771 
	C846.085327,588.282715 845.408752,589.943359 842.372559,589.990601 
	C842.203430,587.928467 843.382812,586.684753 845.658813,586.040771 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M680.246338,631.537231 
	C680.665771,632.169678 680.744202,632.681702 680.849915,633.454956 
	C680.318726,633.987000 662.297302,636.520325 661.398071,636.115662 
	C661.131714,635.814270 661.090515,635.914673 661.139648,635.891602 
	C659.365723,633.656860 660.518860,632.217529 662.573486,631.300232 
	C663.879578,630.717102 665.551086,630.958618 666.757080,629.237061 
	C666.861511,628.141235 666.695129,627.335632 666.388977,626.251465 
	C665.559753,622.097534 662.601562,622.948364 660.020264,622.954224 
	C658.914062,622.956726 657.406372,623.277771 657.228882,621.693909 
	C657.044312,620.046997 658.558777,620.202698 659.665649,619.989624 
	C663.362854,619.277527 666.509460,618.477234 668.351685,623.635193 
	C670.083435,628.483887 675.502930,629.587402 680.246338,631.537231 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M758.358948,680.047607 
	C757.773499,679.722107 757.542175,679.436890 757.057556,679.036255 
	C757.116394,677.811768 757.428589,676.702759 757.934692,675.302979 
	C758.499146,674.560974 758.826599,674.090149 758.770569,673.187012 
	C758.812866,672.172302 758.911987,671.441772 759.150085,670.367676 
	C760.009521,669.759216 760.729919,669.494263 761.715576,669.107422 
	C764.851196,669.682190 766.196289,671.009766 762.857666,672.806091 
	C760.735596,673.947815 761.543579,674.548401 762.851501,675.793152 
	C763.586060,676.719727 763.743774,677.468323 763.254150,678.634399 
	C762.349487,682.271057 760.302002,680.296204 758.358948,680.047607 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M758.484375,673.094116 
	C759.826599,673.580627 760.016418,674.257080 758.419678,674.971802 
	C758.170776,674.436646 758.212891,673.861023 758.484375,673.094116 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M627.633606,735.021484 
	C629.045959,735.072815 630.108887,735.111877 631.561157,735.240234 
	C633.949951,736.539917 636.190918,735.729736 638.611938,736.140503 
	C639.390625,736.237549 639.849731,736.281494 640.618286,736.485291 
	C646.031372,737.340515 651.151062,736.960449 656.661865,736.788330 
	C658.026672,736.681030 658.996033,736.659424 660.325195,736.655151 
	C668.160400,738.258850 675.049683,734.780701 682.681519,734.148682 
	C683.245239,734.214600 683.398804,734.305908 683.753540,734.220093 
	C684.581177,733.667664 685.207703,733.292297 686.309692,732.814819 
	C689.542786,735.189026 688.916199,737.258301 685.911560,738.840027 
	C684.745728,739.453918 683.114136,739.618286 683.488586,741.392456 
	C683.766052,742.707275 685.078064,742.932983 686.295288,742.984741 
	C688.805603,743.091431 688.699036,745.241577 689.757324,746.721863 
	C692.912842,751.135376 689.971191,754.501587 689.011719,758.662231 
	C689.213318,759.537048 689.215820,760.012756 688.860596,760.809082 
	C688.499756,761.297607 688.327332,761.469604 687.851807,761.839478 
	C682.312073,765.030457 675.984924,765.456482 670.730225,769.257690 
	C669.855713,769.757446 669.218750,769.915405 668.248779,769.999634 
	C667.170898,770.017883 666.390991,769.952698 665.427856,770.499390 
	C665.007385,770.759766 664.836853,770.866211 664.400757,771.126587 
	C663.337524,771.623169 662.747131,772.271057 661.720459,772.894165 
	C659.398743,773.945374 657.298828,774.456909 654.720093,774.413208 
	C653.879822,774.378296 653.403015,774.345337 652.577454,774.266296 
	C648.549866,774.048462 645.022766,774.493164 641.655029,776.961426 
	C639.854065,778.361694 638.103760,778.445374 635.922241,778.181641 
	C634.823792,777.942871 634.138367,777.655212 633.104492,777.281372 
	C630.048340,776.219543 627.395386,775.641174 624.971191,778.598389 
	C623.419983,779.348694 622.123352,779.385071 620.409485,779.215271 
	C616.600647,778.125610 613.167969,778.164673 609.330505,779.058594 
	C607.597412,779.435059 606.266602,779.417908 604.612244,778.822144 
	C601.873840,775.598267 604.029968,774.169128 606.405518,772.970642 
	C608.858398,771.733276 611.615540,772.087830 614.246826,771.770020 
	C612.867493,771.964783 611.483459,771.984863 610.097534,772.020996 
	C608.446045,772.064026 606.531921,772.513550 605.787048,770.508240 
	C605.107605,768.678894 606.982971,768.002563 607.953125,766.967224 
	C608.874390,765.984070 609.461365,764.772705 610.264771,763.715881 
	C611.470520,762.129761 611.235535,761.430786 609.656372,759.799255 
	C606.135925,756.161987 603.007385,752.732483 597.242859,754.146118 
	C596.035461,754.442261 594.644470,753.450195 594.376038,751.880371 
	C594.075195,750.120972 595.302856,749.218811 596.482727,748.222046 
	C597.906494,747.019348 601.087463,747.022095 600.537781,744.598145 
	C600.063538,742.506714 597.326782,743.751587 595.638855,743.322632 
	C594.317871,742.987061 592.938293,742.744202 592.493896,741.246094 
	C591.672302,738.476257 594.424011,737.776001 595.718445,736.089783 
	C593.454041,735.494202 591.343628,736.657837 589.213196,736.171387 
	C586.427124,735.535217 585.539368,733.671936 587.020874,731.475891 
	C588.977295,728.575867 593.000061,727.299927 596.298889,728.428162 
	C604.618958,731.273376 613.226318,732.846802 621.915405,733.976624 
	C623.739441,734.213806 625.408264,735.137207 627.633606,735.021484 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M693.586914,730.357056 
	C693.742126,731.235962 693.555481,731.936768 692.325195,731.323853 
	C692.564331,730.873169 692.949585,730.583679 693.586914,730.357056 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M683.737793,734.050598 
	C683.954651,734.043030 683.678284,734.399048 683.678284,734.399048 
	C683.678284,734.399048 683.263062,734.373901 683.177368,734.248596 
	C683.091675,734.123291 683.520874,734.058167 683.737793,734.050598 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M686.803955,732.701294 
	C686.785217,732.712646 686.667786,732.736267 686.615784,732.739380 
	C686.563782,732.742493 686.683899,732.586731 686.746155,732.622986 
	C686.808411,732.659241 686.822632,732.689941 686.803955,732.701294 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M776.098267,675.619568 
	C775.555542,675.997864 775.111084,675.998230 774.333313,675.998047 
	C773.369995,674.888245 770.588440,673.615845 774.420105,672.955200 
	C777.639893,672.400024 780.823914,671.764709 784.555054,672.000000 
	C784.599304,674.378601 782.718933,673.837280 781.141113,674.020630 
	C779.451904,674.216919 777.439087,673.217590 776.098267,675.619568 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M763.350647,678.973816 
	C763.026733,678.238159 763.026184,677.508057 763.029419,676.413879 
	C764.444275,676.139954 764.979370,676.982361 764.965454,678.641602 
	C764.536194,678.983337 764.105103,678.981323 763.350647,678.973816 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M658.201782,423.883057 
	C658.109131,423.685699 658.304993,423.430328 658.787964,423.115143 
	C658.882629,423.313232 658.687744,423.569824 658.201782,423.883057 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M609.299072,361.817841 
	C609.207397,362.599030 609.110901,363.397766 609.007202,364.599487 
	C604.561157,363.380341 600.106079,361.800842 595.688904,360.121643 
	C592.570190,358.936035 589.981262,361.335663 586.859131,361.197815 
	C582.734192,361.015717 579.518250,360.050018 577.057617,356.338562 
	C579.424194,353.042786 582.411804,351.349335 586.800171,351.755188 
	C587.232788,351.666351 587.189697,351.679413 587.210205,351.682190 
	C588.505005,353.717712 590.652283,353.285522 592.916565,353.523346 
	C593.286499,353.479095 593.436829,353.384888 593.521301,353.411926 
	C593.418274,353.821198 593.445251,354.178680 593.897705,354.744995 
	C594.424500,355.460968 594.911926,355.762604 595.738525,356.093506 
	C598.194458,357.055267 600.059937,358.666016 602.833923,358.712555 
	C604.022461,358.663361 604.939880,358.583649 605.744507,359.705261 
	C606.241577,360.260895 606.540344,360.546478 607.206665,360.814056 
	C607.516418,360.857574 607.763306,360.739807 607.868530,360.827087 
	C608.196960,361.207855 608.445923,361.477081 608.995728,361.770630 
	C609.266418,361.823090 609.294128,361.835388 609.299072,361.817841 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M593.216919,356.195679 
	C593.095398,355.409363 593.173706,354.831543 593.428894,353.846375 
	C597.231262,353.848114 599.981262,355.993835 602.959595,358.343323 
	C600.112488,360.716949 597.853638,359.014923 595.321899,357.037201 
	C594.407593,356.974030 593.873779,356.794067 593.216919,356.195679 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M614.243530,351.210144 
	C615.349487,351.152985 616.512573,350.968597 616.371033,352.122284 
	C616.242432,353.170441 615.054138,353.769104 614.109009,353.496826 
	C612.651550,353.076996 613.148254,352.117523 614.243530,351.210144 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M593.063721,353.196472 
	C591.169739,353.786285 588.880249,355.155426 587.238403,352.090729 
	C589.100830,352.094574 590.970886,352.504181 593.063721,353.196472 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M669.006470,394.408264 
	C672.655762,395.074738 670.346130,398.157318 670.980469,400.667542 
	C670.344299,398.989471 669.727600,396.878937 669.006470,394.408264 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M567.152222,343.314575 
	C566.580017,343.496307 565.986084,343.426331 565.196777,343.112946 
	C564.836609,342.409027 564.962585,342.034912 565.652710,341.845154 
	C565.967102,341.955292 566.426514,342.036926 566.657715,342.060547 
	C566.969360,342.410400 567.049927,342.736664 567.152222,343.314575 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M579.212524,347.592133 
	C578.433716,347.954224 577.353210,348.135620 576.178589,347.060638 
	C576.937317,346.936768 577.929626,347.136871 579.212524,347.592133 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M582.597656,348.747253 
	C581.975891,348.814697 581.164368,348.630402 580.167603,348.181458 
	C580.790955,348.109741 581.599426,348.302643 582.597656,348.747253 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M664.913757,390.364899 
	C665.796326,389.762543 666.963501,389.171661 666.808350,391.418640 
	C666.184814,391.392853 665.612854,391.040161 664.913757,390.364899 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M662.499878,387.691223 
	C663.283875,387.342407 663.750916,387.761688 663.968018,388.892090 
	C663.515564,388.836273 663.025757,388.427643 662.499878,387.691223 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M624.584229,355.274475 
	C624.964111,355.268768 625.263062,355.433868 625.664062,355.841187 
	C625.345215,355.870728 624.924255,355.658020 624.584229,355.274475 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M609.164978,361.540192 
	C608.936829,361.674500 608.607239,361.525879 608.125732,361.145844 
	C608.337036,361.028687 608.700317,361.143005 609.164978,361.540192 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M572.323364,345.644257 
	C572.149109,345.790131 571.792786,345.714355 571.240234,345.446167 
	C571.409790,345.310028 571.775574,345.366333 572.323364,345.644257 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M614.303894,363.601196 
	C614.114197,363.745239 613.758118,363.646667 613.222473,363.332733 
	C613.407776,363.197784 613.772644,363.278168 614.303894,363.601196 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M574.494263,346.478424 
	C574.265503,346.654236 573.838196,346.550659 573.196167,346.189758 
	C573.419556,346.021301 573.857605,346.110168 574.494263,346.478424 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M616.776611,365.457275 
	C616.455750,365.449463 616.320984,365.275787 616.084717,364.788422 
	C616.609436,364.387573 616.880920,364.725708 616.776611,365.457275 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M607.364868,360.564484 
	C607.134583,360.710358 606.752686,360.563141 606.181885,360.168945 
	C606.399658,360.038452 606.806519,360.154938 607.364868,360.564484 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M514.654236,304.923584 
	C514.219849,309.498993 515.950623,313.914398 515.328796,318.508484 
	C514.970520,321.155518 517.655945,322.079163 520.594360,322.015137 
	C520.982910,322.016968 520.995972,322.505432 521.002869,322.749634 
	C522.021729,324.258484 521.382141,325.000061 520.189392,325.551300 
	C518.657532,326.259247 518.389526,327.231354 519.783020,328.743896 
	C521.788452,329.378235 523.585022,329.850769 521.837219,332.158997 
	C521.044800,333.205475 521.054260,334.300598 521.061035,335.841736 
	C519.650452,337.258636 518.017212,336.936676 516.062256,336.893066 
	C513.878906,336.159058 513.170776,334.603729 513.106567,332.914062 
	C513.015991,330.534149 512.186829,328.824493 510.073578,327.617554 
	C508.868835,326.929504 508.177551,325.763000 508.695465,324.228882 
	C509.821686,320.892975 505.426453,317.550720 508.702118,314.213562 
	C504.710907,311.535583 507.624237,305.221436 502.785187,302.948456 
	C502.432190,302.782654 502.477234,302.045593 502.787659,301.272888 
	C506.822021,302.135345 510.975830,302.277588 514.654236,304.923584 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M514.766113,335.861816 
	C516.774475,336.149292 518.489929,336.153503 520.639038,336.190369 
	C522.685730,337.440796 524.568909,336.995758 526.318054,336.921021 
	C535.686707,336.520905 544.954529,337.311371 554.592773,339.003113 
	C555.339478,339.275757 555.491577,339.620483 555.294312,340.294678 
	C546.034363,340.066803 537.117188,337.997559 527.949402,337.898651 
	C506.116089,337.663116 485.413818,341.988647 465.656860,352.188477 
	C463.401825,350.124329 465.143158,349.033997 466.584473,348.086090 
	C468.197998,347.024994 470.084747,346.425415 472.098022,345.243317 
	C477.073425,342.940887 482.150360,341.945007 486.972290,339.237488 
	C496.281921,337.175201 505.194611,334.947845 514.766113,335.861816 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M520.039124,328.570068 
	C518.360168,328.907532 516.756775,328.097961 516.629089,326.583618 
	C516.381958,323.651672 520.396301,326.135559 520.928223,323.344299 
	C524.475647,322.925690 527.780945,323.349976 530.742493,325.890320 
	C527.076416,327.518799 522.648865,323.414429 520.039124,328.570068 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M550.735107,319.258179 
	C551.499084,319.300354 552.411865,319.589081 552.424377,319.912537 
	C552.474304,321.207062 550.970581,320.847260 550.172607,321.773926 
	C548.486816,321.879700 546.842285,322.393890 545.416809,321.173737 
	C546.882935,319.154694 549.061890,320.626312 550.735107,319.258179 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M562.213623,341.965271 
	C560.397278,342.011169 558.937317,341.789001 557.230347,341.294006 
	C556.857910,340.841217 556.732422,340.661285 556.789490,340.207581 
	C557.406128,339.963043 557.840271,339.992279 558.600708,340.017670 
	C559.378052,340.423065 559.938599,340.505615 560.751099,340.705750 
	C560.988892,340.865692 561.458923,340.996796 561.700623,341.030212 
	C562.151550,341.274872 562.360779,341.486053 562.213623,341.965271 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M556.793335,339.716583 
	C556.963806,340.115143 556.955505,340.296478 556.965332,340.749512 
	C556.562500,340.948547 556.141663,340.875946 555.426025,340.675232 
	C555.108093,340.203247 555.084961,339.859344 555.043701,339.256714 
	C555.555176,339.165131 556.084900,339.332245 556.793335,339.716583 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M560.988831,340.659546 
	C560.431335,340.729675 559.873718,340.593628 559.121582,340.235718 
	C559.385864,340.017639 559.844604,340.021454 560.646484,340.033234 
	C560.989563,340.041168 560.988770,340.453430 560.988831,340.659546 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M533.477539,308.470551 
	C533.518311,308.449463 533.436768,308.491638 533.477539,308.470551 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M514.999084,335.763000 
	C506.072601,337.509827 497.086151,338.874634 487.693634,340.238007 
	C481.505615,340.278717 479.296448,338.278442 480.037506,333.207703 
	C480.342957,331.117462 480.084381,329.337189 479.291229,327.465393 
	C477.742432,323.810150 476.520844,320.238342 477.515076,315.994507 
	C478.344238,312.455261 474.685089,311.672729 472.124512,309.979248 
	C471.719391,309.516632 471.581818,309.308075 471.320160,308.741516 
	C471.477234,305.125122 469.983643,301.812103 471.732178,298.288086 
	C482.155457,297.876282 492.310791,298.116547 502.617065,300.931396 
	C503.122070,301.858337 503.377319,302.768433 504.243744,303.004883 
	C507.359558,303.855103 509.124603,304.574127 507.322357,308.774384 
	C505.994476,311.869049 510.396576,313.116089 512.594788,314.991486 
	C507.120026,317.139038 507.121063,318.228210 512.388611,322.994141 
	C512.112793,324.610046 509.534454,323.405945 509.569336,325.165375 
	C509.604431,326.934387 511.354706,326.826111 512.502380,327.311096 
	C514.208923,328.032257 515.469421,328.980194 514.462341,331.065277 
	C513.706177,332.631012 514.616333,333.962128 514.999084,335.763000 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M426.143616,304.289185 
	C433.067169,302.874298 439.799011,299.924377 447.538025,299.990417 
	C453.818237,299.806396 459.045380,301.753510 463.749176,304.987885 
	C466.144867,306.635132 469.074860,306.869781 471.703491,308.750641 
	C471.998779,309.000000 472.000000,309.000000 472.000000,309.000610 
	C473.466736,311.325134 472.496521,312.930237 470.228241,313.590973 
	C468.144775,314.197937 466.396790,314.704773 466.597595,317.373535 
	C466.802094,320.091797 467.304504,322.660400 469.696808,324.389160 
	C470.233734,324.777130 470.924652,324.905304 471.502136,325.222656 
	C473.417206,326.275024 476.758026,326.390381 476.175446,329.421906 
	C475.698151,331.905609 472.684113,331.357910 470.635925,331.836121 
	C473.992523,333.578522 476.493896,336.576599 480.037140,338.040070 
	C480.949707,338.416992 481.685913,339.236664 481.317383,340.434052 
	C480.981934,341.523926 480.045563,342.019257 479.055450,342.134735 
	C475.278046,342.575256 471.462433,343.235687 467.608826,341.032410 
	C466.306396,340.188171 466.227570,335.738190 463.893280,340.063660 
	C462.963562,341.786499 460.666809,341.447235 458.867615,341.244995 
	C456.413727,340.969086 453.988007,340.447205 451.158752,340.378357 
	C445.634094,340.334930 442.720947,337.542664 441.081940,332.816986 
	C440.388824,330.818573 440.538391,329.344452 442.607391,329.019318 
	C445.661835,328.539307 448.536713,326.936829 450.643921,327.199341 
	C448.506836,327.124329 445.243866,327.006714 442.025299,326.251251 
	C438.050751,325.318359 436.382477,323.210022 437.344360,319.098114 
	C437.700897,317.573883 438.531708,315.891846 436.796204,314.229797 
	C436.324524,313.910034 436.140869,313.773132 435.709229,313.396667 
	C433.686310,308.934692 430.183746,306.560669 426.143616,304.289185 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M411.673645,307.930908 
	C412.994202,306.160187 414.597046,305.485718 416.661774,304.993408 
	C419.487732,305.358856 421.804749,304.394714 424.599731,304.007050 
	C429.144409,306.048431 431.242981,310.612427 435.745850,312.762024 
	C435.993896,313.004883 436.000000,313.000000 435.997253,313.003052 
	C437.357117,315.528625 435.170868,315.731445 433.752808,316.299500 
	C432.349396,316.861664 430.747711,317.192383 430.611511,319.036560 
	C430.471985,320.925842 432.034607,321.514374 433.412262,322.071106 
	C436.861389,323.465057 438.860870,325.078705 435.994141,329.055817 
	C434.308960,331.393707 436.262177,333.428375 437.977539,335.063843 
	C443.153656,339.998932 443.519501,342.252350 439.960480,348.302887 
	C439.626038,348.871399 438.820404,349.640198 439.143127,350.011780 
	C441.488892,352.712494 442.330780,355.471161 440.034698,358.610321 
	C443.435822,360.134918 441.168701,363.088593 441.987610,365.570770 
	C443.346008,367.929138 441.716187,369.052826 440.627289,370.424072 
	C439.120728,372.321167 437.507721,374.151672 436.212769,376.658325 
	C435.999939,376.963806 436.007385,377.006500 435.985931,377.003357 
	C435.445190,377.521698 434.882935,377.986267 433.882629,378.465576 
	C433.313141,378.694519 433.080963,378.775330 432.481720,378.897339 
	C428.982849,378.562500 428.245697,376.534180 428.068512,373.699158 
	C427.745941,368.538086 429.055573,362.975494 424.621796,358.677032 
	C423.795349,357.875824 424.751678,356.348236 424.522278,355.338501 
	C423.176117,349.413177 423.023621,343.368835 422.296112,337.386047 
	C422.052673,335.384247 421.034790,333.343781 420.576111,331.232208 
	C419.780182,327.568481 419.682556,323.590118 416.513855,320.811798 
	C416.037415,320.394073 415.794769,319.640167 415.795990,318.990204 
	C415.803741,314.833405 413.827911,311.487213 411.673645,307.930908 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M472.051270,308.614258 
	C467.929565,309.056824 464.806854,307.477142 461.411469,305.000732 
	C457.889069,302.431671 452.968658,301.779419 448.317261,300.140869 
	C455.530548,297.802887 463.290192,297.933990 471.504089,297.975281 
	C471.386627,301.407562 474.068115,304.787048 472.051270,308.614258 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M435.987549,312.635498 
	C430.229950,312.987579 428.233002,308.255890 425.208740,304.277161 
	C425.002502,304.002472 425.494934,303.992828 425.740997,303.988464 
	C430.936646,304.792084 434.803314,306.906799 435.987549,312.635498 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M411.554016,307.678833 
	C416.277344,310.003448 415.883514,314.598999 417.423431,318.508667 
	C418.801666,322.007935 421.606750,325.192719 421.709869,329.186188 
	C421.828766,333.793182 426.190033,337.581360 424.793457,341.973053 
	C423.555023,345.867493 423.719604,348.666656 426.559479,351.416779 
	C426.770233,351.620911 426.970490,352.153839 426.857727,352.327606 
	C424.066559,356.628235 428.208466,359.487549 429.024353,363.031342 
	C429.943359,367.022919 429.683563,371.097687 429.460999,375.139008 
	C429.339935,377.337006 431.032501,377.340302 432.666626,377.972412 
	C433.386475,378.501801 433.511108,379.037689 433.308411,380.049774 
	C433.221863,380.469910 433.247131,380.803467 433.126862,380.919678 
	C432.744537,381.245880 432.499939,381.474548 432.189941,381.959167 
	C432.107208,382.196594 431.944122,382.401184 431.816772,382.371338 
	C431.119812,382.507202 430.540619,382.554291 429.577606,382.252411 
	C427.506805,381.837158 426.861481,380.950958 427.395081,379.292847 
	C427.755707,378.172272 427.575134,377.086151 426.261383,377.137970 
	C422.016144,377.305328 422.852600,375.098633 422.499084,371.899628 
	C421.968384,367.097351 423.418823,361.158081 418.001617,357.662140 
	C416.624329,356.773315 417.463043,355.966095 418.439636,354.970154 
	C420.991913,352.367401 421.135315,349.769592 417.438507,347.989807 
	C416.134735,347.362152 415.184967,346.745697 415.213318,345.115936 
	C415.378357,335.630615 412.765808,326.766449 409.501068,317.964172 
	C408.575073,315.467529 409.199402,312.395844 409.195984,309.098114 
	C409.947418,308.325348 410.615601,308.042358 411.554016,307.678833 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M429.140106,382.357086 
	C429.757324,382.066162 430.311218,382.110077 431.277222,382.247742 
	C427.067261,391.495697 421.539581,400.198730 417.709167,410.090088 
	C417.268799,410.387604 417.088928,410.472198 416.698059,410.346252 
	C416.230957,405.396088 419.429626,401.861938 420.890778,397.722870 
	C421.980347,394.636353 423.640228,391.751160 425.115784,388.415222 
	C426.073151,386.707001 426.547455,384.997620 428.634460,384.247223 
	C428.974731,383.595062 429.025726,383.143494 429.140106,382.357086 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M413.727234,421.792480 
	C412.998474,425.488586 413.410828,429.251190 411.528290,432.831909 
	C411.051697,433.207123 410.865540,433.352814 410.439423,433.237488 
	C410.181488,432.545197 410.163513,432.113922 410.080414,431.366333 
	C410.665649,428.272308 411.316040,425.494568 412.104553,422.363281 
	C412.574280,421.654999 412.980896,421.508545 413.727234,421.792480 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M416.402344,413.064697 
	C416.101532,414.503479 415.845490,415.569336 415.382965,416.892273 
	C414.759979,417.412903 414.308411,417.526886 413.523956,417.257385 
	C413.415741,415.372375 412.381226,413.359955 414.922668,412.125671 
	C415.842346,411.939728 416.231323,412.167511 416.402344,413.064697 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M428.537476,384.036560 
	C428.029419,385.459045 427.329254,387.029083 425.485016,387.983826 
	C424.821289,385.715454 425.026642,383.796143 428.537476,384.036560 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M413.151367,417.405670 
	C413.664001,417.056091 414.096497,417.065857 414.852722,417.112488 
	C414.881653,418.550629 414.586792,419.951874 414.141968,421.683838 
	C413.602264,422.010742 413.212555,422.006927 412.532745,422.006409 
	C412.518829,420.594788 412.794983,419.179901 413.151367,417.405670 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M416.591858,413.188660 
	C416.138885,413.101288 415.920166,412.764984 415.490448,412.219177 
	C415.303833,411.820618 415.328247,411.631470 415.585754,411.002411 
	C415.818878,410.562469 416.149506,410.348724 416.318237,410.242188 
	C416.487000,410.135651 416.973480,410.184814 417.211060,410.243896 
	C417.241150,411.181885 417.033661,412.060791 416.591858,413.188660 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M415.708496,410.532379 
	C415.818878,410.562469 415.660004,410.826385 415.592682,410.861664 
	C415.525360,410.896942 415.598114,410.502289 415.708496,410.532379 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M356.778229,451.311646 
	C356.973541,450.499573 357.094116,449.940918 356.908936,449.515320 
	C354.278198,443.468994 354.268738,443.480865 361.520721,442.958893 
	C362.124237,442.915466 362.713654,442.677002 363.706360,442.430725 
	C361.671722,439.135864 358.629761,438.605621 355.614624,438.024536 
	C354.001007,437.713531 352.386017,437.408752 350.776550,437.077179 
	C348.700226,436.649445 347.411591,435.145630 347.528931,433.254547 
	C347.651764,431.274506 350.031860,432.293945 351.173706,431.463470 
	C352.241455,430.686829 354.264038,432.667084 354.592804,430.797363 
	C355.012421,428.411255 352.618713,429.307648 351.385071,428.904297 
	C348.278595,427.888733 349.151886,426.070984 350.611694,424.410522 
	C352.063904,422.758728 352.470367,421.562927 349.867584,420.962982 
	C348.144226,420.565674 348.059357,419.308258 347.996216,417.926758 
	C347.866425,415.087158 348.537292,412.311676 345.554932,409.926147 
	C344.044525,408.718018 344.452179,405.526611 347.003998,404.005615 
	C348.622437,403.040924 350.000488,401.883972 349.982788,399.386475 
	C360.584808,399.119354 371.154419,398.979034 381.650055,397.135223 
	C385.773804,396.410767 394.494843,403.685974 393.586639,407.629211 
	C392.809204,411.004730 396.863708,414.185791 393.349579,417.396759 
	C392.628113,418.056000 394.579193,418.583923 395.085724,419.249084 
	C396.912750,421.648315 400.371368,423.685486 397.566345,427.514008 
	C397.009521,428.273987 396.948608,429.422211 397.640961,429.762817 
	C402.583557,432.194031 401.092468,437.315430 402.368073,441.187531 
	C403.428589,444.406799 405.646393,442.297699 407.572388,443.011658 
	C408.334656,443.353638 408.835449,443.740997 408.762695,444.205719 
	C407.782104,450.470947 407.284882,456.776093 406.964752,463.105255 
	C406.911438,464.159454 405.754059,464.546539 404.334320,464.689636 
	C395.043823,461.252441 393.755127,461.157043 381.565186,463.023987 
	C382.101227,464.145416 383.395020,464.278870 384.164642,465.081665 
	C385.252808,466.216827 386.076660,467.446228 385.603699,469.080231 
	C385.106079,470.799225 383.598663,471.261353 382.085571,471.364319 
	C378.604980,471.601105 375.108856,471.653259 371.630951,471.335785 
	C369.634949,471.153595 368.112366,470.086761 367.786743,468.011536 
	C366.817566,461.834686 363.540192,457.415588 358.013947,454.554871 
	C356.927795,453.992645 356.363495,452.941681 356.778229,451.311646 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M403.768616,464.316528 
	C405.234955,463.908051 406.689972,463.541595 406.325226,462.109802 
	C404.712616,455.779144 406.679840,449.839844 407.989502,443.415527 
	C407.919739,439.736389 408.436340,436.557739 409.913818,433.236389 
	C410.372223,432.993652 410.544983,433.010803 410.977783,433.044678 
	C405.250275,468.200134 408.963959,502.523560 419.919861,536.613037 
	C419.741425,537.161377 419.551666,537.336060 418.993958,537.632263 
	C418.107056,537.799133 417.603821,537.730530 416.799286,537.338135 
	C414.180603,533.917053 414.226288,529.682068 411.724670,526.167419 
	C411.162720,523.548828 409.294159,522.496948 407.420410,520.956787 
	C406.304047,518.993835 405.626892,517.087219 406.400330,515.392090 
	C409.705719,508.148041 407.055237,501.082642 405.963165,493.577209 
	C405.365295,490.642670 405.492340,488.017456 404.916931,485.093781 
	C404.771667,483.992859 404.791656,483.266693 404.989410,482.220642 
	C405.476013,481.198639 405.824097,480.488892 405.429871,479.350708 
	C405.464935,477.569427 405.077271,476.202972 404.302948,474.583160 
	C404.608459,470.978790 402.105255,467.907898 403.768616,464.316528 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M374.094513,328.264648 
	C373.987518,328.151764 374.382202,328.014893 374.585876,327.977814 
	C374.789581,327.940735 374.618286,328.348450 374.618286,328.348450 
	C374.618286,328.348450 374.201508,328.377563 374.094513,328.264648 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M368.462708,344.522339 
	C368.439240,344.479034 368.486176,344.565643 368.462708,344.522339 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M562.164917,342.330872 
	C561.884827,342.039124 561.912415,341.844910 561.941162,341.357178 
	C563.118713,341.193726 564.295105,341.323792 565.719299,341.704590 
	C565.678711,342.071075 565.423950,342.238129 565.102173,342.663025 
	C564.158447,342.722473 563.315552,342.575439 562.164917,342.330872 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M735.603271,721.022095 
	C736.286804,724.282166 733.805176,726.351379 732.105713,728.630432 
	C726.895447,735.617554 720.342346,741.356201 713.996399,747.267578 
	C713.466248,747.761475 712.482605,747.768494 711.344116,747.995850 
	C710.401550,746.933716 710.941589,746.197388 712.031311,745.433716 
	C719.914368,739.680237 726.046631,732.653687 731.818115,725.273682 
	C732.926147,723.856934 734.125305,722.511414 735.603271,721.022095 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M688.741699,761.564697 
	C688.543152,760.783081 688.663818,760.203125 688.899170,759.344727 
	C694.274780,757.744324 697.902771,753.225708 703.489990,751.752808 
	C704.036804,751.561646 704.230896,751.469727 704.596924,751.650146 
	C704.768921,751.922424 704.883850,752.372681 704.899780,752.604492 
	C704.161316,753.939941 703.186218,754.821411 701.792236,755.643921 
	C701.130615,755.714722 700.797180,755.753052 700.234741,755.901123 
	C696.870117,758.904053 693.722107,761.773987 688.741699,761.564697 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M704.995972,751.821899 
	C704.768921,751.922424 704.301758,751.810608 704.072266,751.732056 
	C704.267029,750.350708 701.520630,749.659607 702.971069,748.457153 
	C704.036011,747.574280 705.680847,747.588928 706.886719,749.302917 
	C707.385620,750.145203 707.365051,750.648254 706.547668,751.344055 
	C706.174927,751.554138 705.681091,751.535950 705.681091,751.535950 
	C705.681091,751.535950 705.222961,751.721436 704.995972,751.821899 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M692.708191,735.383423 
	C693.238770,733.085388 694.702759,733.119629 696.737915,733.481873 
	C695.722046,735.391724 694.256470,735.354858 692.708191,735.383423 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M706.422668,751.693359 
	C706.383545,751.137756 706.592163,750.721436 706.891479,749.964966 
	C707.507080,749.439697 708.031921,749.254639 708.840942,749.060059 
	C710.405701,751.829651 708.450256,751.753662 706.422668,751.693359 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M712.003540,745.266968 
	C711.978394,746.038696 711.580200,746.729614 711.080322,747.705200 
	C710.570801,748.064636 710.162964,748.139343 709.444763,748.206421 
	C708.992004,746.387146 709.734497,745.308716 712.003540,745.266968 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M720.674805,751.058594 
	C721.506104,753.684570 723.668640,752.815735 725.322510,753.007751 
	C726.470947,753.141113 727.752808,753.106995 727.717834,754.757751 
	C727.693848,755.891174 726.879517,756.715088 725.898193,756.768982 
	C722.595764,756.950073 718.822754,758.234497 717.051392,753.370605 
	C718.116577,752.370789 719.233093,751.741516 720.674805,751.058594 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M688.369019,761.361755 
	C692.806885,761.150696 695.570251,757.615906 699.651794,756.033936 
	C699.920227,758.411560 697.993286,759.313049 696.172485,760.727539 
	C692.402649,761.444763 689.844421,764.573181 685.768860,764.587891 
	C685.190613,762.799744 686.373047,762.177856 687.916382,761.463867 
	C688.215393,761.323975 688.315674,761.360535 688.369019,761.361755 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M701.850159,755.745361 
	C702.045593,754.410583 702.861511,753.384705 704.585083,752.892700 
	C704.912842,754.629944 704.159851,755.748840 701.850159,755.745361 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M549.755005,774.200562 
	C550.003113,773.544373 550.006226,773.088684 550.014893,772.291382 
	C552.690063,769.764099 555.643982,770.298523 558.551208,771.187439 
	C572.227295,775.369202 586.249817,777.291443 600.458862,778.775879 
	C615.153687,780.310974 629.630859,779.162964 644.078613,777.299927 
	C650.408081,776.483765 656.716003,774.744751 662.932556,772.999817 
	C664.688965,772.506775 666.419189,771.818481 668.642883,772.739258 
	C668.831238,777.097717 664.475708,778.297546 662.574585,778.482910 
	C658.513306,778.878967 655.320496,780.814880 651.916992,782.268860 
	C644.347351,785.502563 636.354248,784.406250 628.601013,784.819763 
	C616.051086,785.489258 603.439087,785.049194 590.854004,784.966980 
	C587.523193,784.945251 584.916748,785.843079 582.321777,788.169250 
	C578.891541,791.244263 573.617188,790.322083 569.747070,792.862427 
	C569.432800,793.068665 568.130981,792.559937 568.037537,792.192688 
	C566.901794,787.729309 562.349487,786.997986 559.304932,784.158630 
	C558.642090,784.339172 558.217712,784.531921 557.373901,784.507812 
	C555.931152,783.871338 555.300781,782.834778 554.261353,781.720886 
	C552.695740,779.334412 550.995361,780.672852 549.310791,780.817993 
	C546.606934,781.050781 543.738220,781.003845 545.227051,776.568726 
	C546.758728,775.568054 548.277283,775.280945 549.755005,774.200562 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M669.371338,772.991516 
	C649.948120,778.436523 630.562378,781.671448 610.709290,780.926880 
	C593.781921,780.291931 576.981689,778.372986 560.711853,773.321899 
	C557.463928,772.313599 554.255432,771.817871 550.461060,771.962036 
	C549.615784,769.456848 547.225830,769.929260 545.371033,769.026917 
	C541.286926,770.141846 538.372192,767.413269 535.304871,766.385437 
	C529.069275,764.296082 522.516724,762.354126 517.227173,757.360229 
	C514.928467,756.014587 512.463806,755.814270 510.343536,754.062866 
	C508.483734,754.280579 507.444580,753.459595 506.239990,752.236694 
	C505.453247,751.162598 504.600830,751.015625 503.361694,750.982727 
	C492.789276,747.265625 483.927124,741.256836 475.260193,734.244141 
	C475.006165,733.990234 475.000000,734.000000 475.003906,733.996948 
	C473.318787,732.564453 470.199310,733.453796 469.209412,730.275269 
	C468.214722,729.361084 467.431885,728.719238 466.342163,728.030640 
	C462.589478,726.604370 459.701996,724.479187 457.213318,721.267700 
	C456.187378,720.415344 455.373535,719.833130 454.279846,719.125488 
	C449.657349,712.740234 442.645782,709.585754 437.189545,704.600830 
	C431.906586,699.774170 426.729309,694.896851 422.424042,688.903320 
	C423.177124,688.188416 423.863831,688.122437 424.913513,688.337402 
	C428.943298,690.740784 431.129700,694.873169 435.535645,696.851074 
	C436.012756,697.175842 436.194641,697.318665 436.619965,697.707642 
	C439.854828,702.353027 444.513367,704.955750 448.568085,708.695618 
	C449.061584,708.982117 449.252258,709.108337 449.713867,709.444946 
	C454.480072,714.154663 459.690552,717.755371 465.090637,721.658447 
	C465.719513,722.124939 466.076477,722.392334 466.710724,722.844055 
	C468.442200,724.029785 469.836121,725.105652 471.333801,726.558350 
	C472.195801,727.322937 472.887268,727.768372 473.940338,728.220337 
	C475.456360,729.106384 476.488251,730.140015 477.990692,731.048462 
	C478.944153,731.658020 479.578888,732.185791 480.556458,732.760498 
	C481.001953,733.024536 481.177612,733.135254 481.611664,733.420410 
	C482.377319,733.957581 482.860321,734.355835 483.698853,734.796021 
	C484.171967,735.046692 484.361115,735.150452 484.833557,735.416748 
	C486.980103,736.788391 488.831543,738.017578 491.156494,739.127808 
	C492.478088,739.906982 493.467102,740.566284 494.852295,741.295532 
	C514.932617,753.323059 536.202576,761.504028 558.146973,768.283264 
	C571.573303,772.431030 585.500305,773.423706 599.124023,776.232239 
	C600.577332,776.531799 602.112549,776.517151 603.710205,777.663147 
	C605.401550,777.977722 606.775208,778.057068 608.561401,778.108276 
	C612.403381,777.953918 615.832947,777.963684 619.655640,778.116211 
	C621.417358,778.144897 622.786072,778.126038 624.557251,778.021912 
	C626.750000,776.272949 628.962830,776.490540 631.534668,776.613220 
	C632.691406,776.912964 633.478638,776.991882 634.658569,777.098022 
	C636.855103,777.007690 638.661621,776.872681 640.779236,776.514343 
	C643.932861,774.280701 647.280151,774.069336 650.906067,773.699951 
	C652.162659,774.008240 653.037537,773.918457 654.199158,773.447632 
	C656.900818,773.677612 659.004395,772.569702 661.606689,772.007019 
	C662.605103,771.378601 663.224792,770.846191 664.222534,770.414490 
	C664.528442,770.421326 664.799988,770.516479 664.910339,770.424133 
	C665.246521,769.531799 665.845337,769.308777 666.919434,769.429688 
	C667.948120,769.453796 668.650024,769.363831 669.694580,769.183960 
	C672.198364,767.891968 674.326538,766.571899 677.355530,767.346802 
	C677.892578,767.764038 678.059937,767.986450 678.113647,768.610718 
	C674.478149,768.730469 672.916321,772.532166 669.371338,772.991516 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M566.344177,807.278198 
	C564.452637,806.879700 563.096375,808.575439 561.219849,807.248840 
	C560.108948,805.947266 559.877686,804.770264 560.587158,803.062866 
	C562.590637,801.426941 564.391113,801.365845 566.619385,802.700684 
	C568.386719,802.995850 569.778320,802.996582 571.587402,802.996216 
	C573.304321,801.880615 574.713928,801.625183 576.630981,802.724731 
	C577.788025,803.313354 578.576050,803.626648 579.682007,803.969971 
	C581.594910,806.658264 580.046875,807.666382 577.320068,808.329529 
	C573.433960,808.751526 569.968872,809.257568 566.344177,807.278198 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M565.962952,807.357056 
	C569.466492,807.234497 572.968872,806.495056 576.699951,807.723267 
	C577.177124,808.167297 577.354309,808.333801 577.265747,808.750183 
	C576.686401,809.787415 576.372742,810.574890 576.028320,811.679932 
	C574.931580,811.998352 573.865540,811.999146 572.399780,812.000000 
	C569.491028,811.252258 564.628723,813.836731 565.962952,807.357056 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M563.681885,790.760132 
	C564.906311,792.145752 565.812683,793.291565 566.859497,794.718628 
	C567.622864,795.994141 567.159607,796.766418 566.244995,797.748291 
	C565.249023,798.000732 564.498047,798.001465 563.371582,798.003540 
	C562.602234,798.352966 562.176514,798.656433 561.338501,799.028564 
	C560.146179,799.245667 559.359131,799.141541 558.231018,798.684143 
	C557.218506,797.980591 556.761719,797.315552 556.288330,796.179749 
	C555.973328,794.664917 556.171326,793.628235 557.168945,792.428467 
	C559.327209,791.238892 560.966125,789.503357 563.681885,790.760132 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M678.178711,767.794434 
	C675.518433,768.207825 673.177979,768.685730 670.437378,769.128906 
	C670.055237,766.723145 672.499634,765.885681 673.652161,765.690430 
	C678.524780,764.865051 682.632507,761.958374 687.824219,761.217773 
	C688.009766,762.768066 686.465088,762.993225 685.583374,764.244141 
	C684.788025,764.633179 684.188538,764.778259 683.299194,765.040527 
	C682.371155,765.660217 681.746948,766.181030 680.769714,766.674988 
	C680.467896,766.715210 680.217773,766.631287 680.116455,766.715759 
	C679.690796,767.412598 679.205811,767.794861 678.178711,767.794434 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M566.424255,797.999756 
	C566.247559,797.195190 566.495178,796.390381 566.871338,795.292786 
	C569.395081,795.694885 571.740784,796.531921 573.674255,793.895142 
	C574.535645,792.720398 575.737976,791.548584 577.401550,792.995422 
	C578.464111,793.919556 579.258789,795.058777 578.822388,796.465210 
	C578.115234,798.743958 576.492004,797.574097 575.153809,797.206787 
	C572.278198,796.417480 569.686951,798.484009 566.424255,797.999756 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M560.659973,798.264832 
	C561.447388,798.008179 561.889893,798.006592 562.664246,798.004944 
	C562.893799,800.757080 567.344360,799.251099 566.983521,802.625488 
	C565.282898,802.997192 563.570740,802.999329 561.430481,803.001953 
	C559.787476,804.070984 558.412109,804.390869 556.472473,803.682373 
	C553.451111,798.370850 558.205627,799.574646 560.659973,798.264832 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M563.934082,790.658203 
	C561.848694,790.561401 560.502991,792.355835 558.354614,792.954285 
	C556.709656,792.018127 555.759216,790.726196 554.612549,789.144409 
	C554.031128,786.882568 554.958923,785.500488 556.667969,784.148560 
	C557.450134,783.996155 557.893005,783.997131 558.667908,783.999023 
	C558.356201,787.852295 562.124268,788.304321 563.934082,790.658203 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M576.997681,802.608704 
	C575.612366,802.999207 574.224670,802.998413 572.420959,802.996338 
	C571.981201,801.016602 572.333313,799.233093 574.789795,799.122620 
	C576.974548,799.024414 576.964111,800.679749 576.997681,802.608704 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M577.392090,808.998901 
	C577.000000,809.000000 577.000000,808.500366 577.000000,808.250610 
	C578.404358,807.268005 580.078857,806.779175 579.992981,804.347168 
	C581.620605,804.195251 583.205261,804.891052 582.692749,806.690735 
	C582.042542,808.974121 579.765381,808.927856 577.392090,808.998901 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M677.908081,768.049072 
	C678.373962,767.429626 678.889099,767.129272 679.709717,766.814575 
	C679.725525,767.825745 680.657715,769.584900 678.213623,769.228699 
	C677.985779,768.797668 677.971558,768.582886 677.908081,768.049072 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M680.822205,766.771240 
	C680.845276,765.881042 681.399780,765.256409 682.685669,765.135864 
	C682.833618,766.191956 682.320740,766.856079 680.822205,766.771240 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M381.308350,873.324707 
	C381.236542,867.267395 381.813904,866.874390 386.364563,869.897034 
	C388.140503,871.076721 389.683716,871.130981 391.478394,870.255676 
	C392.368011,869.821777 393.307526,869.284180 394.254517,869.216797 
	C396.203064,869.078308 397.155273,870.562805 397.982513,872.045044 
	C398.380493,872.758179 399.015259,873.749573 398.349640,874.324707 
	C396.902618,875.574951 395.240601,875.006836 393.340820,874.150269 
	C389.227478,873.197998 385.354858,875.606567 381.308350,873.324707 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M380.625000,873.000000 
	C384.795074,873.243225 388.629089,872.483154 392.698792,873.721069 
	C388.007294,875.266602 383.105286,876.997131 377.430481,877.000000 
	C377.307953,875.895935 377.615875,874.791809 377.961914,873.343872 
	C378.750000,873.000000 379.500000,873.000000 380.625000,873.000000 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M425.315613,689.069458 
	C424.369202,688.877258 423.760864,688.743591 422.844604,688.581055 
	C422.536621,688.552063 422.461487,688.561707 422.426575,688.576294 
	C420.990356,688.567688 420.953461,687.264587 420.244263,686.174866 
	C420.099365,685.893494 420.079254,686.008972 420.101501,685.953979 
	C419.345551,685.384766 418.730042,684.762085 418.803436,683.433594 
	C419.068878,678.486328 415.068451,676.186707 412.592499,673.575134 
	C406.816620,667.483032 402.029602,660.561035 395.979675,654.346191 
	C396.258942,653.569214 396.698059,653.242188 397.619202,652.930115 
	C401.693237,653.004456 403.834015,652.768005 404.761230,647.700195 
	C406.024200,640.796814 412.601105,638.097778 419.124420,637.802185 
	C429.915924,637.313110 440.600525,635.584229 451.401947,635.411011 
	C452.730835,635.389709 454.062378,635.477173 455.342560,635.905945 
	C456.514587,636.298462 457.460541,636.946411 457.581451,638.283508 
	C457.702271,639.619812 456.953369,640.452454 455.790344,640.979492 
	C455.029541,641.324402 454.222839,641.527710 453.428375,641.768127 
	C452.445160,642.065613 451.366241,642.441772 451.410339,643.627319 
	C451.469849,645.227356 452.907379,645.250305 454.019531,645.281799 
	C459.165710,645.427490 464.314423,645.469299 469.820251,645.131592 
	C471.862671,644.317932 473.584106,643.765808 475.752808,643.356934 
	C480.566467,644.428833 483.277161,647.231018 484.207794,651.979126 
	C481.619446,654.529175 478.571533,654.351257 475.601776,654.512817 
	C472.344574,654.690063 469.006165,654.029541 465.945068,655.274536 
	C467.758881,659.086792 469.112915,662.979431 472.354645,665.935425 
	C473.940582,667.381592 473.780884,670.093262 472.242188,671.920105 
	C470.664062,673.793701 468.134155,673.995178 466.289459,672.879578 
	C461.904694,670.227966 461.661682,672.902344 461.534637,676.103027 
	C461.495178,677.096924 461.489014,678.092163 461.400330,679.083923 
	C461.053864,682.959412 459.453918,685.341553 455.042511,684.749451 
	C453.623840,684.559021 451.674042,684.301392 451.124664,685.991760 
	C450.597168,687.614807 452.643524,687.866333 453.593872,688.632629 
	C456.279480,690.798462 456.786224,693.514648 455.774872,697.016846 
	C450.969604,702.002686 442.136810,702.668945 436.021118,698.673218 
	C435.642212,698.530457 435.539825,698.469421 435.499146,698.425842 
	C432.864105,694.583496 428.896667,692.239563 425.315613,689.069458 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M338.655518,437.733612 
	C339.761505,437.971283 340.506683,437.985229 341.624451,438.002197 
	C344.002838,441.094116 341.269257,442.063049 339.511780,443.329407 
	C335.801361,446.002960 336.008820,448.175934 338.117157,452.027618 
	C340.610382,456.582336 345.664825,459.173737 346.713562,464.853973 
	C347.739166,470.409058 346.627563,473.100677 340.392944,474.937012 
	C344.320007,477.247284 344.621216,481.871002 348.189270,484.494263 
	C350.931061,485.190765 353.404144,485.531525 355.592041,486.891724 
	C359.371857,489.241730 359.533539,490.790314 355.693420,492.723877 
	C352.861572,494.149658 352.653961,495.714233 352.210388,498.960419 
	C351.270966,505.835297 352.450195,510.460022 358.773926,513.554504 
	C359.706177,514.010620 359.750366,515.464294 358.964752,516.211548 
	C356.630493,518.431641 356.422821,521.437439 355.410004,524.213013 
	C354.264709,527.351685 350.038788,527.077271 347.892792,529.391113 
	C353.023926,529.905579 358.463959,529.189331 363.363281,532.586548 
	C364.893860,535.989258 362.058380,537.182373 360.428436,539.223511 
	C363.293762,540.463257 364.941071,542.310547 363.593292,545.805664 
	C363.071289,546.451111 362.685272,546.723633 361.920593,547.049927 
	C361.115356,547.275330 360.620789,547.283081 360.157806,547.402649 
	C358.424500,547.850220 355.526337,546.868103 355.245026,549.104736 
	C354.890259,551.925232 357.044861,553.702637 360.352539,554.136169 
	C360.849731,554.438660 361.029907,554.589417 361.371796,555.053894 
	C361.013092,557.729004 360.310211,559.797729 363.790192,560.962402 
	C366.194183,561.766968 368.100708,564.030884 369.978241,565.901917 
	C372.867706,568.781494 372.548157,571.333252 368.702118,572.738037 
	C365.437592,573.930359 366.106903,575.169067 367.426666,577.365906 
	C369.650726,581.068115 373.094788,583.870728 374.881592,587.900696 
	C375.581451,589.479187 376.433380,590.966614 375.572906,592.603760 
	C372.443817,598.557373 375.647919,602.826599 379.460297,606.722229 
	C383.276978,610.622375 383.447601,610.501282 379.355865,614.565369 
	C378.566589,615.349304 377.807953,616.175110 378.703278,617.067322 
	C382.388031,620.739075 379.400146,624.140625 378.165710,627.859863 
	C373.558228,625.236328 372.904388,619.768066 369.438721,616.152588 
	C367.825165,614.469238 371.284424,614.188721 372.284332,613.121582 
	C373.276428,612.062683 375.623322,611.833496 375.348236,610.198547 
	C375.015961,608.223816 372.832764,608.972412 371.526703,609.092163 
	C366.709686,609.533630 363.576447,608.023071 362.299164,602.953186 
	C361.711609,600.621033 360.082520,598.544495 358.878387,596.378357 
	C358.469696,595.643127 357.739868,594.748047 358.322815,594.106262 
	C363.257172,588.673523 356.203278,587.797363 354.656738,585.029114 
	C353.464630,582.895142 350.728790,581.777100 349.886627,578.940491 
	C348.864136,575.496704 348.985413,573.143494 353.127686,572.383423 
	C354.151062,572.195679 355.657776,572.085388 355.741852,570.534119 
	C355.818848,569.112732 354.412109,568.733154 353.531494,568.065735 
	C351.285217,566.363220 348.769867,564.965515 346.735992,563.049133 
	C345.192902,561.595093 341.137604,560.739441 344.571381,557.103699 
	C345.549896,556.067627 344.066956,555.051147 343.007782,554.532959 
	C340.433014,553.273254 340.331635,550.610962 340.353241,548.523987 
	C340.378510,546.085815 343.315887,546.406738 344.969421,545.480896 
	C346.750031,544.483887 350.290863,546.009033 350.365326,543.162598 
	C350.454132,539.767639 346.578979,541.693909 344.817017,540.455505 
	C343.698151,539.669067 341.355988,540.598022 341.320129,538.448608 
	C341.289978,536.640381 343.240051,536.223145 344.563690,535.814941 
	C346.727478,535.147705 349.019623,534.896729 351.390869,533.572144 
	C347.503876,533.057556 343.616211,532.547852 339.730103,532.026611 
	C337.051666,531.667358 333.198364,532.364685 332.449066,529.403442 
	C331.736267,526.586426 336.023743,526.631714 337.655151,524.868225 
	C337.983063,524.513733 338.533783,524.371826 338.887115,524.033142 
	C340.241516,522.734985 343.601990,523.603149 343.301453,520.927917 
	C343.073456,518.898315 340.365234,519.312744 338.704987,518.736084 
	C337.815369,518.427124 336.570435,518.191162 336.616913,516.956177 
	C336.661530,515.769836 337.880676,515.399231 338.781250,515.237732 
	C343.859436,514.326904 341.163086,512.176819 339.503174,510.423676 
	C338.742432,509.620270 337.422424,509.378296 336.493317,508.695007 
	C335.043304,507.628784 333.067291,506.591248 333.296661,504.566162 
	C333.574524,502.112457 335.892029,503.235291 337.365479,502.967163 
	C338.349121,502.788147 339.570587,502.863861 339.632874,501.563202 
	C339.696136,500.240936 338.514862,500.177002 337.504120,500.038818 
	C336.144928,499.853027 334.057465,500.450439 334.285492,498.083344 
	C334.460571,496.266144 336.037445,495.537170 337.742035,494.963257 
	C342.019958,493.522919 346.227203,491.872772 350.988983,490.112213 
	C345.636383,486.211548 339.541565,486.867096 334.215179,485.037292 
	C331.418945,484.076691 327.803284,483.867004 330.477631,479.312134 
	C331.358368,477.812134 330.174438,477.248322 328.906250,476.994049 
	C327.502441,476.712585 326.915009,475.863922 327.175812,474.491364 
	C327.409302,473.262634 328.429382,473.119446 329.451630,472.979797 
	C332.372559,472.580719 334.073639,471.582306 331.600281,468.536194 
	C331.307037,468.175049 330.976685,467.517090 331.114258,467.181305 
	C333.863281,460.471313 329.304779,457.699982 324.490051,455.466309 
	C324.934052,453.519958 326.798279,454.370392 327.330322,453.122681 
	C326.693146,451.391296 325.105316,452.262909 323.946228,451.987213 
	C322.806458,451.716095 321.403076,451.601440 321.620209,450.032745 
	C321.801727,448.721161 323.157593,449.151703 324.073578,448.969543 
	C325.380951,448.709534 327.586487,449.650909 327.536560,447.363281 
	C327.497253,445.562897 325.493805,446.133942 324.280182,446.001129 
	C322.597626,445.816986 322.012817,444.869171 321.994568,443.329803 
	C321.975891,441.755219 322.191742,440.634247 324.061798,439.907898 
	C326.975098,438.776306 329.611206,439.592834 332.314331,440.255280 
	C333.970367,440.661133 335.662018,441.593597 335.948669,438.413330 
	C336.619904,437.344757 337.416107,437.247345 338.655518,437.733612 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M471.128967,645.700317 
	C464.345612,648.078308 457.539795,646.730957 450.805939,646.802185 
	C448.744049,646.824036 449.037720,644.751038 449.049408,643.287903 
	C449.065125,641.321655 450.079620,640.334778 452.060303,639.998047 
	C453.654541,639.726990 456.694916,640.980774 456.453400,638.410400 
	C456.214691,635.869690 453.129242,637.089050 451.333160,637.059143 
	C440.706329,636.882141 430.260956,639.408997 419.586243,639.214417 
	C416.718872,639.162109 413.826050,641.053284 410.616241,641.283752 
	C408.377106,641.444458 410.562408,645.558350 407.394867,646.424805 
	C404.084229,647.330444 405.093567,649.110840 407.115356,650.990723 
	C408.429993,652.213013 406.934845,653.053223 405.880798,653.486023 
	C403.584991,654.428650 401.253357,655.489075 398.370056,654.046875 
	C396.761108,652.710754 397.060944,651.364075 397.637115,649.851685 
	C399.329254,645.410034 397.631042,642.019531 393.010040,640.290771 
	C390.213745,639.244751 387.124176,639.089966 384.243866,637.253784 
	C383.973297,637.024414 383.941132,636.548950 383.937164,636.310791 
	C383.411224,635.365967 383.151642,634.591370 383.486237,633.314819 
	C383.862976,632.733032 384.073059,632.542358 384.668579,632.170349 
	C385.720612,631.984192 384.956604,631.662781 385.527130,631.907654 
	C393.712097,635.420532 402.409058,633.678528 410.819977,633.969482 
	C416.916046,634.180420 423.332306,634.524780 429.760376,632.226685 
	C440.268799,631.471558 450.093842,628.484131 460.684814,628.412537 
	C467.414307,628.190308 470.002045,632.643188 472.493805,637.708618 
	C473.146179,640.687988 469.406036,642.528259 471.128967,645.700317 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M377.917908,628.432495 
	C377.738861,627.281372 377.183197,626.264832 377.481964,625.701965 
	C379.367889,622.149292 380.153168,619.209717 375.942963,616.363403 
	C374.746704,615.554688 377.115448,613.585938 378.627991,613.164551 
	C382.487091,612.089417 382.680084,610.873779 379.372894,608.561279 
	C375.011444,605.511536 372.667419,601.244324 372.773468,595.786987 
	C372.782715,595.309753 372.281158,594.619019 372.468201,594.378601 
	C377.108612,588.414490 371.495605,585.653320 368.640381,582.068542 
	C366.688354,579.617737 365.393219,576.752930 363.612335,574.179749 
	C362.193817,572.130066 364.764465,572.146484 366.141022,572.019226 
	C367.983337,571.848877 370.162079,571.830627 370.670197,569.747437 
	C371.196045,567.591736 369.091949,566.747620 367.582214,565.747498 
	C364.789581,563.897400 363.093201,560.330444 358.803802,560.806030 
	C357.076660,560.997498 357.146332,559.500122 358.303436,558.509766 
	C359.294373,557.661682 360.658661,557.201233 360.969330,555.340576 
	C363.361969,553.041199 366.278351,553.785828 366.891785,555.912842 
	C368.120667,560.173462 371.563751,561.660156 374.486755,563.929749 
	C375.011627,564.337219 375.577423,564.696838 376.052002,565.163086 
	C378.892761,567.954102 378.769348,570.386658 375.217346,572.207092 
	C372.832550,573.429382 372.469238,574.580078 373.626007,576.844727 
	C374.694763,578.936951 375.904968,580.802795 377.573914,582.509705 
	C380.797119,585.806274 384.191986,588.903625 381.235901,594.553284 
	C379.516479,597.839478 382.498749,603.661011 385.800201,605.737427 
	C386.783630,606.355835 387.889679,606.784729 388.801666,607.511292 
	C391.502563,609.662964 391.500366,611.597168 388.343903,613.111328 
	C385.734619,614.363098 384.999359,615.489990 386.913147,617.887085 
	C388.324249,619.654541 388.178802,621.708801 387.034576,623.501343 
	C385.274017,626.259277 384.684967,629.282532 384.234741,632.714783 
	C384.013702,632.985352 384.005066,632.994263 384.001923,632.998352 
	C383.017578,633.830750 381.939545,633.964905 380.397980,633.232056 
	C379.300781,631.613525 377.809845,630.593994 377.917908,628.432495 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M339.002960,437.583679 
	C338.252869,437.974243 337.489380,437.991150 336.343689,438.026001 
	C333.727661,436.693115 331.243164,437.092743 328.406555,437.018494 
	C325.289825,434.310333 322.674042,431.699463 327.108795,428.155151 
	C328.338837,427.172119 328.303284,425.117859 329.365051,423.799316 
	C330.662048,422.188660 330.130066,421.111633 328.301544,420.460907 
	C323.752289,418.842010 324.087280,415.295258 325.371399,411.895081 
	C326.286682,409.471313 326.751495,407.199615 326.175781,404.356873 
	C326.027557,403.073547 326.020660,402.144745 326.673401,400.999207 
	C327.512421,399.915741 328.343781,399.192413 328.031799,397.648926 
	C328.041168,396.653381 328.121552,395.995758 328.524658,395.063538 
	C328.650879,394.750427 328.755310,394.735107 328.773987,394.784485 
	C329.263397,394.546570 329.770325,394.451050 330.641541,394.771606 
	C333.396515,396.053589 335.878876,397.002838 338.228119,398.210907 
	C339.518036,398.874237 340.504150,400.452393 338.261322,400.815216 
	C332.867889,401.687805 334.526642,405.846802 335.607727,407.964966 
	C336.944122,410.583405 336.718048,412.636627 335.978821,414.968750 
	C335.147583,417.591034 335.370026,419.532562 338.564941,419.945526 
	C340.118286,420.146332 341.666382,421.350098 340.829163,422.384308 
	C338.623291,425.109100 341.391602,429.028198 337.892914,431.515686 
	C335.462463,433.243622 339.539795,435.073853 339.002960,437.583679 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M326.684204,403.870544 
	C328.733795,406.642792 327.997772,409.108063 326.877411,411.855194 
	C325.585724,415.022430 324.168091,418.575714 329.996338,419.289917 
	C332.941376,419.650787 334.056366,421.704834 331.323456,424.041901 
	C328.869263,426.140625 329.674713,430.458160 325.766815,431.756622 
	C325.169098,431.955200 329.046265,433.787354 327.990723,436.635315 
	C320.544891,437.035339 320.784851,437.066437 321.729889,429.773834 
	C322.122620,426.743164 323.668793,423.770172 322.451233,420.288086 
	C321.392731,419.424042 321.215515,418.624664 321.547974,417.300262 
	C321.716095,416.899689 322.012482,417.011261 322.165009,417.053802 
	C323.208252,414.723938 323.410889,412.186554 324.134094,409.418701 
	C324.233612,408.625244 324.275726,408.173340 324.442322,407.403595 
	C325.178162,405.979645 325.112396,404.504913 326.684204,403.870544 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M384.015167,637.372070 
	C389.426605,637.926758 395.337189,637.201538 398.975189,643.190063 
	C400.858948,646.290955 402.292511,648.455750 398.466339,650.879272 
	C398.004791,651.171631 398.142487,652.410034 398.001465,653.603760 
	C397.535706,654.001038 397.070892,654.004456 396.255981,654.010193 
	C391.956238,648.581604 388.006653,643.150696 384.015167,637.372070 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M412.201447,680.267090 
	C410.886322,679.031982 409.772644,678.053894 408.340698,677.018860 
	C402.684845,673.345398 399.695343,667.838318 396.209503,662.283569 
	C394.676880,660.567444 394.387054,658.716248 393.939911,656.568420 
	C400.918610,664.232361 407.923981,672.244873 414.774292,680.821411 
	C413.632019,681.737610 412.938660,681.333679 412.201447,680.267090 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M319.082855,481.525726 
	C315.443665,480.873138 317.345093,477.902618 317.095184,475.783844 
	C316.855713,473.753357 316.578308,471.578247 317.780884,469.265747 
	C319.910889,472.879456 318.678284,477.047455 319.082855,481.525726 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M321.415466,416.923340 
	C321.832397,417.673065 321.948700,418.446442 322.139374,419.604309 
	C320.582275,421.126190 321.419769,423.115997 320.384094,424.792206 
	C318.324036,422.080200 318.242004,419.333435 321.415466,416.923340 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M418.587219,683.075928 
	C419.311798,683.836243 419.710815,684.558472 420.116791,685.589905 
	C419.653931,685.934326 419.184082,685.969543 418.361053,686.003418 
	C417.612152,685.988708 417.377350,685.787659 417.153564,685.199951 
	C417.003662,685.001099 417.000000,685.000000 416.999481,684.998291 
	C415.876495,684.156494 414.260681,683.706116 414.655334,681.487671 
	C414.831665,681.168640 414.955353,681.044922 415.017517,680.982727 
	C416.140381,681.626282 417.201019,682.332092 418.587219,683.075928 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M380.153687,633.311401 
	C381.084137,633.007568 382.143585,633.003784 383.600922,633.001221 
	C383.993225,633.769287 383.987671,634.536194 383.957642,635.687805 
	C382.233185,635.968933 381.194885,634.883728 380.153687,633.311401 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M319.517731,428.958496 
	C318.542511,428.735046 318.380310,428.372742 319.571045,427.989685 
	C319.914185,428.272614 319.878357,428.556030 319.517731,428.958496 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M319.315063,486.706787 
	C318.913879,486.810913 318.322571,486.819611 319.052307,486.106934 
	C319.265228,486.050659 319.332489,486.486816 319.315063,486.706787 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M323.909241,409.060303 
	C325.848663,411.998901 324.225220,414.403381 322.640564,416.980652 
	C322.247803,416.014069 322.178040,414.931793 322.105225,413.440125 
	C322.054810,412.456146 322.158020,411.917938 322.641174,411.199463 
	C322.881531,410.535065 322.969147,410.105957 323.059418,409.347473 
	C323.250366,409.026550 323.438660,409.034973 323.909241,409.060303 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M322.522522,411.014343 
	C322.719513,411.531769 322.645081,412.099396 322.336395,412.848877 
	C321.053192,412.292145 321.086639,411.635498 322.522522,411.014343 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M384.859772,647.696411 
	C380.279358,648.969666 380.872864,644.748047 379.763611,642.206787 
	C382.205658,643.238892 383.669342,645.136719 384.859772,647.696411 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M327.004517,403.653595 
	C326.354370,404.946075 325.719849,405.906891 324.826050,406.976715 
	C323.893768,405.543793 325.164856,404.217194 325.008728,402.363953 
	C325.333588,401.218628 325.698212,400.446228 326.073120,399.350769 
	C326.046906,398.452728 326.155273,397.913940 326.631622,397.191833 
	C326.889893,396.533142 326.998932,396.112366 327.124939,395.365479 
	C327.476959,394.968536 327.812042,394.897705 328.399017,394.788666 
	C328.773468,395.561249 328.896057,396.372101 329.020874,397.593109 
	C328.983246,398.874695 329.016235,399.761993 328.261688,400.774353 
	C327.221252,401.573029 327.063721,402.421936 327.004517,403.653595 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M326.511230,397.006500 
	C326.704102,397.522522 326.627319,398.091095 326.317017,398.843689 
	C325.046753,398.283997 325.098969,397.627747 326.511230,397.006500 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M329.681152,390.084045 
	C329.948395,390.715881 329.982758,391.470490 330.008545,392.603363 
	C329.806976,393.132538 329.613983,393.283478 329.106628,393.243988 
	C328.291718,391.938934 328.938843,391.088776 329.681152,390.084045 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M328.722412,393.348511 
	C328.993500,393.041260 329.194763,393.028900 329.697998,392.999084 
	C330.309723,393.456696 330.619476,393.931732 330.949463,394.701569 
	C330.485748,394.977936 330.001740,394.959564 329.155212,394.887512 
	C328.745972,394.437012 328.699280,394.040222 328.722412,393.348511 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M405.823608,480.761383 
	C406.041779,481.755249 406.037079,482.512299 406.032654,483.647888 
	C405.030457,485.276367 403.563721,485.738800 402.132660,486.125092 
	C393.524872,488.448639 384.761200,489.796234 375.841827,489.973175 
	C373.479767,490.020020 371.766785,488.495544 370.398315,486.799683 
	C368.267761,484.159393 366.448181,484.165283 364.340088,487.282227 
	C362.630463,488.066254 361.382202,487.906281 360.168945,486.376099 
	C359.868317,482.635956 362.177155,479.030090 358.379974,476.005035 
	C357.272125,475.122467 358.280060,473.150208 360.322083,473.052216 
	C361.813629,472.980621 363.311493,473.034241 364.802063,472.951477 
	C365.887787,472.891235 367.181976,472.193573 366.276367,471.196838 
	C362.865570,467.442841 364.939453,461.235535 359.897095,458.025269 
	C358.266083,456.986908 356.437012,456.349670 354.921967,455.214142 
	C353.388824,454.065125 351.855835,452.789917 351.205414,450.899872 
	C353.203644,449.359924 354.655121,451.265808 356.621765,450.987854 
	C357.092041,452.024048 357.415741,453.459503 358.326752,453.446381 
	C364.033203,453.364349 364.450256,459.603119 368.340027,461.644043 
	C368.749908,461.859161 369.033203,462.871765 368.927277,463.429321 
	C367.582031,470.509827 372.071320,470.409119 376.887085,470.016205 
	C378.203857,469.908783 379.542145,470.073456 380.860840,469.978668 
	C382.248383,469.878906 384.320343,470.607635 384.640869,468.647430 
	C384.988129,466.523926 383.214355,465.551544 381.310455,465.024445 
	C380.211151,464.720123 379.028931,464.459351 378.102722,463.847809 
	C376.835815,463.011383 376.959198,461.276825 378.319885,461.294647 
	C386.693207,461.404175 395.378632,457.591156 403.365814,463.045135 
	C403.496948,463.134644 403.577972,463.297577 403.860168,463.752869 
	C403.152405,467.598206 407.413635,470.567566 405.079590,474.603699 
	C404.986725,476.033691 404.541382,477.010651 404.346924,478.351837 
	C404.287903,479.594727 404.910004,480.083130 405.823608,480.761383 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M359.598297,486.001709 
	C361.096344,486.327637 362.195099,486.654114 363.642639,486.984314 
	C366.171448,486.588654 367.165314,488.010376 367.365540,489.717194 
	C367.605316,491.761688 365.813263,492.392700 364.231842,493.039490 
	C362.475891,493.757629 359.279358,492.877411 359.152771,495.156158 
	C358.994537,498.004120 362.350128,497.924713 364.415375,498.741669 
	C365.863831,499.314697 367.276123,499.973297 367.392273,501.724609 
	C367.505188,503.427216 366.075195,503.879974 364.794067,504.280853 
	C363.269348,504.757935 361.913727,505.302307 362.270325,507.316986 
	C362.572083,509.021667 363.188049,510.406647 364.930176,511.237183 
	C369.949768,513.630005 368.853760,513.339844 367.125000,518.771301 
	C365.910950,522.585632 361.012177,526.724243 365.384918,531.907349 
	C365.097961,533.090088 364.500702,533.447510 363.327087,533.218140 
	C356.777252,530.747253 350.176971,531.048218 343.589691,530.318237 
	C347.241608,528.989624 349.350739,525.325867 353.291718,524.904358 
	C354.597412,524.764648 354.544373,523.831238 354.409119,522.686951 
	C354.009949,519.308716 354.976135,516.530334 359.426392,515.114075 
	C355.453308,513.072449 351.905731,511.677094 350.524109,507.896057 
	C350.298889,507.279663 349.520203,506.597992 349.660400,506.157471 
	C350.914825,502.215698 351.861938,498.431366 349.634277,494.369934 
	C349.174286,493.531311 351.654968,493.113342 352.809967,492.556793 
	C354.387726,491.796509 355.975433,491.056854 357.627350,490.276031 
	C355.260925,487.079163 351.790466,487.085541 348.385468,486.044556 
	C347.106110,481.682861 347.663391,480.959778 352.614380,480.749207 
	C354.608429,483.230286 354.935425,487.055756 359.598297,486.001709 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M352.996521,480.665588 
	C350.090485,480.953888 348.294281,482.210388 348.010010,485.617859 
	C345.927521,485.797058 344.527924,484.643005 344.143433,482.667908 
	C343.556091,479.651001 342.167023,477.479248 339.125336,476.487152 
	C338.204895,476.186920 337.133301,475.629791 337.313568,474.419434 
	C337.550659,472.827240 338.964661,473.131073 340.080444,473.006927 
	C345.936615,472.355225 346.516174,469.664154 344.679230,464.102325 
	C343.204407,459.636963 337.949463,458.198792 337.711914,453.286774 
	C337.654999,452.110443 336.283386,452.157745 335.305206,451.974121 
	C332.939575,451.530060 334.373566,450.335388 334.208160,449.017517 
	C333.416656,442.711426 339.356934,442.780243 341.791473,439.710541 
	C341.971985,439.482910 341.919250,439.070282 341.986023,438.374451 
	C345.872681,436.463562 348.979187,438.902100 352.575073,440.534790 
	C350.334045,441.361328 348.115753,442.259369 345.841461,442.981110 
	C344.860840,443.292328 343.323975,443.727386 343.556488,444.477692 
	C344.362762,447.079620 346.985229,449.253082 345.097229,452.391327 
	C347.950439,454.080414 348.489685,457.085693 349.680054,459.954071 
	C350.022430,464.835907 353.638641,469.492554 350.329742,474.544037 
	C349.201355,476.266693 351.831085,477.130768 352.751770,478.761536 
	C352.992706,479.448395 352.994720,479.891022 352.996521,480.665588 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M352.993378,478.580200 
	C350.963806,478.660614 349.100128,477.995728 348.074005,476.045166 
	C347.644226,475.228180 346.745361,473.789551 347.937103,473.469818 
	C353.555267,471.962372 350.248322,467.720947 349.372070,466.049652 
	C348.088959,463.602478 349.380585,462.412567 349.950958,460.356079 
	C351.186249,460.174011 353.002197,459.673004 352.481140,461.902466 
	C352.018250,463.882874 353.226593,464.315002 354.616394,465.111511 
	C357.991760,467.045929 358.101501,470.969269 354.681122,472.981079 
	C352.277130,474.395081 353.321411,476.371429 352.993378,478.580200 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M521.427979,800.998291 
	C521.000000,800.997559 521.000000,800.498779 521.000000,800.249390 
	C522.448608,795.054810 527.031494,797.189331 530.139832,796.116089 
	C532.172363,795.414246 534.773499,796.466553 536.458496,794.177490 
	C533.151611,789.813477 527.874695,791.833130 523.402466,790.057129 
	C519.379272,790.982849 520.294067,788.241333 520.151611,786.005859 
	C520.370667,785.324463 520.509644,785.070923 520.981689,784.541748 
	C523.717041,783.137085 526.257202,783.376038 529.187744,783.519165 
	C531.907959,783.379150 534.201111,783.488403 536.761475,784.340210 
	C538.270996,784.964294 539.447449,785.520386 540.957031,784.370361 
	C541.480042,784.147644 541.700623,784.076233 542.272217,783.951904 
	C543.368469,783.875916 544.075195,784.032593 545.051514,784.578003 
	C546.026794,785.631653 546.238281,786.620056 546.357788,788.023315 
	C546.364014,789.156189 546.306641,789.904236 546.016235,790.982666 
	C545.791931,791.529968 545.679626,791.733887 545.333740,792.184082 
	C544.582703,792.780640 544.010559,793.005432 543.201172,793.226440 
	C542.991821,793.286682 542.695129,793.381104 542.695129,793.381104 
	C542.695129,793.381104 542.731079,793.682617 542.937500,793.719727 
	C543.669189,793.827209 544.187744,793.932983 545.023499,794.146973 
	C546.700012,794.757751 547.996216,795.409302 549.652954,796.219482 
	C552.275696,797.908813 553.682312,799.775574 552.410767,803.017456 
	C550.462463,804.625916 548.521484,804.533936 546.164307,804.262817 
	C544.254211,803.797302 542.874207,803.065918 541.073059,802.379761 
	C538.854736,801.581970 536.851624,801.172913 535.223511,799.296143 
	C533.381897,797.282959 531.561279,797.945190 530.155334,799.021912 
	C527.616211,800.966125 524.822754,801.192383 521.427979,800.998291 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M475.044769,734.335144 
	C484.231140,739.453247 493.456116,744.916260 502.854858,750.676880 
	C499.642700,751.961731 497.368958,753.933838 497.020782,757.664368 
	C496.926727,758.672180 496.678436,759.802979 495.376526,759.675842 
	C494.118835,759.552979 493.981964,758.386292 494.035339,757.389160 
	C494.408569,750.415955 489.482819,747.633850 484.465576,744.532532 
	C480.593536,742.139099 477.586823,738.562500 475.044769,734.335144 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M517.015015,757.433289 
	C526.143860,760.936523 534.945435,765.630981 544.768799,768.720215 
	C543.356079,771.533691 541.410583,771.735596 539.342712,769.532959 
	C538.308472,768.431274 537.553589,767.220337 535.658447,767.934143 
	C524.305115,772.210388 519.904541,769.798218 517.015015,757.433289 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M549.667908,773.852173 
	C550.192139,776.363892 548.947327,777.180725 546.386597,776.998474 
	C543.182678,776.058228 540.913696,776.687805 539.072754,779.550781 
	C537.266968,780.695496 535.639160,781.173462 533.487915,780.721924 
	C531.784424,779.668091 530.636108,778.308105 528.363037,778.484497 
	C525.837402,778.371094 523.590271,778.941162 521.324646,777.257935 
	C520.438721,773.153320 521.931030,771.776917 525.941162,771.909180 
	C533.775330,772.167480 541.598877,772.281189 549.667908,773.852173 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M520.663452,784.747498 
	C521.303284,786.642944 520.190063,788.659668 522.637634,789.916138 
	C523.000000,790.444458 523.000000,790.888916 523.000000,791.666687 
	C522.293579,793.515564 520.465088,793.468384 519.223755,794.775269 
	C515.779785,794.028870 512.565308,794.685730 509.334259,794.956360 
	C507.916046,795.075134 505.984894,795.200867 506.300903,792.902893 
	C506.547089,791.112610 507.492706,790.130676 509.777313,790.659302 
	C511.793823,791.125916 514.120239,791.171570 516.100708,790.633423 
	C518.363098,790.018677 519.667297,788.244263 518.145996,785.329224 
	C518.667175,784.331787 519.458862,784.237427 520.663452,784.747498 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M521.249329,784.685669 
	C520.250000,785.000000 519.500000,785.000000 518.375000,785.000000 
	C517.832947,785.000122 517.665833,785.000305 517.248169,785.001465 
	C515.325256,785.747070 514.336182,784.637878 513.153931,783.318481 
	C512.361389,781.557556 511.720367,780.120056 511.038452,778.341248 
	C510.813477,777.530090 510.939056,777.150574 511.666626,776.923462 
	C515.346558,776.341309 516.824097,780.809692 520.906128,780.275757 
	C522.276794,781.842224 522.287598,783.115784 521.249329,784.685669 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M452.369080,735.992798 
	C451.002869,733.085144 452.422974,730.458557 452.993469,727.364990 
	C456.292358,726.843811 459.203308,728.677795 459.110626,731.482483 
	C459.018219,734.278320 455.721436,735.622742 452.369080,735.992798 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M512.627563,783.005005 
	C514.446655,782.954590 515.372070,783.949646 516.707336,784.854187 
	C516.869446,786.842163 515.848450,788.126892 514.204773,788.702881 
	C511.969818,789.486023 510.188049,788.435669 509.424316,786.411865 
	C508.530060,784.042053 510.153717,783.196045 512.627563,783.005005 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M519.367737,795.005798 
	C519.149963,792.971436 520.290405,792.029053 522.637756,792.006470 
	C524.516052,792.486023 526.032166,792.972107 528.420715,793.737793 
	C525.051147,795.572327 522.403076,795.355835 519.367737,795.005798 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M521.000000,777.375000 
	C523.370544,777.000000 525.741150,777.000000 528.555847,777.000000 
	C529.389709,781.474854 527.552246,782.400696 521.394165,780.316833 
	C521.000000,779.250000 521.000000,778.500000 521.000000,777.375000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M457.009827,721.359985 
	C459.901306,723.131592 462.801392,725.265625 465.868347,727.691772 
	C461.460480,728.156372 458.956451,725.346924 457.009827,721.359985 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M465.759644,733.062805 
	C464.634705,736.151794 463.301208,734.570129 462.103058,733.476074 
	C461.502869,732.928101 460.957336,731.954834 461.971863,731.418823 
	C463.526276,730.597473 464.841034,731.164551 465.759644,733.062805 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M469.008545,730.391846 
	C470.893402,731.136536 472.789276,732.270081 474.846497,733.698730 
	C472.547974,733.787109 469.364380,734.929199 469.008545,730.391846 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M506.043884,752.354858 
	C507.200745,752.496399 508.399567,752.990295 509.799805,753.740845 
	C509.817474,755.012817 509.505341,756.051575 508.204956,755.728760 
	C506.728088,755.362244 506.503571,753.922974 506.043884,752.354858 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M511.923889,776.651733 
	C511.689606,777.117737 511.433624,777.310242 511.114838,777.787537 
	C510.028351,777.925476 508.922241,778.120850 508.371613,777.036438 
	C508.029419,776.362549 508.303802,775.628784 508.948029,775.355103 
	C510.112762,774.860046 511.236755,774.910645 511.923889,776.651733 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M504.294983,788.123474 
	C503.999176,787.248230 503.999573,786.498840 504.000000,785.374756 
	C505.573273,785.186584 506.958252,785.748901 506.774414,787.606812 
	C506.623871,789.127930 505.495270,788.599609 504.294983,788.123474 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M467.281128,788.600586 
	C469.600220,789.609802 473.490784,787.821899 473.289490,791.010254 
	C473.080170,794.326721 469.315643,792.874817 467.115570,792.897461 
	C464.524780,792.924072 461.919861,792.276428 459.334656,791.840515 
	C458.074799,791.628174 457.169342,790.839111 457.288727,789.481567 
	C457.397949,788.239380 458.481476,788.026733 459.504547,788.040222 
	C461.965118,788.072571 464.453522,787.676208 467.281128,788.600586 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M484.655396,790.040161 
	C485.000977,791.066711 485.000732,792.133362 485.000854,793.600037 
	C483.569946,793.918945 481.721069,794.154297 481.532196,792.243835 
	C481.373383,790.637512 483.158356,790.544495 484.655396,790.040161 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M386.217834,781.972046 
	C384.743805,781.547607 383.556335,781.314453 382.627930,780.693726 
	C381.060944,779.646057 379.811554,778.049011 380.447296,776.136108 
	C381.046234,774.334045 382.997375,775.081238 384.385681,775.026794 
	C388.092010,774.881592 392.304810,775.938354 393.239563,770.385315 
	C393.491241,768.890198 395.023865,768.746094 396.021851,769.374878 
	C397.710724,770.438843 398.765564,772.513550 397.332672,774.006226 
	C394.244629,777.223022 392.265991,782.152344 386.217834,781.972046 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M424.386963,783.989990 
	C424.000183,783.349121 423.957764,782.694946 424.008850,782.048096 
	C424.142761,780.352112 423.440887,778.178345 425.761292,777.508484 
	C427.126404,777.114380 427.747589,778.363403 427.898102,779.577209 
	C428.193237,781.957153 427.690796,783.844971 424.386963,783.989990 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M395.987946,662.354492 
	C400.960480,665.928406 403.869324,671.523804 407.859528,676.666382 
	C407.472260,679.670593 405.340637,679.145935 403.659729,678.819519 
	C401.463623,678.393066 400.996674,676.394958 401.064178,674.529724 
	C401.184265,671.211426 400.468781,670.639648 396.645966,670.461182 
	C392.834167,670.283203 389.051178,666.885254 385.531036,664.493530 
	C384.319763,663.670471 385.470886,662.066833 386.473114,661.090759 
	C387.488770,660.101501 388.478577,660.156738 389.544586,661.069763 
	C390.798950,662.143982 392.046021,663.254395 393.428162,664.144409 
	C394.985718,665.147583 395.927673,664.592346 395.987946,662.354492 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M414.537537,681.086243 
	C415.457825,682.254578 416.083984,683.340454 416.854553,684.711487 
	C413.960968,685.231995 411.708618,684.531982 412.001221,680.405151 
	C412.747803,680.341370 413.495605,680.672607 414.537537,681.086243 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M420.056702,686.281555 
	C421.262848,686.137695 421.894531,686.960754 422.374390,688.279419 
	C421.864471,688.716187 421.337311,688.841431 420.405060,688.987000 
	C420.004669,688.228088 420.009338,687.448853 420.056702,686.281555 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M417.012360,685.222473 
	C417.316833,685.117065 417.573883,685.308472 417.891357,685.788635 
	C417.194244,686.672791 417.122803,686.031555 417.012360,685.222473 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M422.271362,698.780762 
	C421.555267,699.000183 421.110565,699.000122 420.332306,699.000000 
	C419.189301,697.999878 417.918365,697.581482 416.867767,696.393188 
	C416.654327,695.916565 416.620483,695.705505 416.795135,695.250854 
	C417.958862,694.955566 418.918732,694.946472 419.868042,694.841431 
	C421.065887,694.708862 422.396942,693.422607 423.414764,694.698730 
	C424.536346,696.104858 423.245972,697.353577 422.271362,698.780762 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M417.222992,698.705017 
	C415.933807,698.998291 414.866364,698.998962 413.398590,698.998657 
	C413.040375,696.347717 409.675415,697.665833 409.068939,695.346802 
	C409.746857,695.005310 410.497833,695.003296 411.624207,695.001892 
	C413.431488,695.986877 415.282990,695.682068 417.045959,696.767578 
	C417.519775,697.450439 417.554657,697.926086 417.222992,698.705017 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M417.188629,696.750183 
	C415.282288,697.110779 413.776703,696.575073 412.215149,695.271118 
	C413.389557,695.002747 414.779449,695.003113 416.586517,695.005371 
	C417.003662,695.007324 417.002472,695.504883 417.002441,695.753662 
	C417.128998,696.169250 417.255585,696.335999 417.188629,696.750183 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M416.996704,697.330933 
	C416.995117,696.997559 416.998779,696.500000 417.000610,696.251221 
	C418.517792,696.094543 419.580475,696.754761 419.958130,698.662720 
	C419.249390,699.000000 418.500000,699.000000 417.375916,698.998779 
	C417.000244,698.553162 416.999268,698.108704 416.996704,697.330933 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M547.192139,814.750610 
	C547.001709,816.947144 546.536682,818.453918 544.113037,817.525330 
	C541.430969,816.497742 538.836548,817.035645 536.353210,817.983398 
	C531.848999,819.702454 528.674561,818.079590 526.196350,814.353210 
	C524.568298,811.905212 522.861145,810.359497 519.709473,812.411926 
	C518.024719,813.509094 515.817261,813.483093 514.068970,812.198914 
	C512.684326,811.181946 512.789490,809.511108 513.247192,808.072083 
	C514.062683,805.508057 516.389343,806.275391 518.272583,805.953674 
	C521.701233,805.368042 525.103149,804.817078 528.613464,804.960693 
	C530.692993,805.045654 532.998352,805.236511 534.090576,806.828918 
	C537.091309,811.204041 541.892883,812.087646 546.625854,813.949219 
	C547.128174,814.168701 547.256287,814.334961 547.192139,814.750610 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M521.408142,831.000000 
	C520.874084,826.170288 524.604431,826.332275 527.202209,827.230103 
	C531.153625,828.595825 535.290588,828.656494 539.602783,829.942139 
	C541.307922,828.866150 542.746216,828.623657 544.664673,829.675537 
	C547.096985,830.971130 549.194031,831.942322 551.646729,832.955505 
	C552.169067,832.870544 552.335632,832.743591 552.751099,832.808350 
	C553.148987,835.402710 551.830566,836.062683 549.663696,836.018188 
	C545.755432,835.937927 541.844177,835.998352 537.467102,836.000000 
	C533.602478,836.094971 530.558044,834.799744 527.246460,833.291565 
	C526.224976,829.858948 523.646973,831.399170 521.408142,831.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M526.623779,833.006470 
	C530.445862,832.784607 533.620117,833.631226 536.773071,835.722656 
	C539.190247,839.451599 542.664062,837.424683 545.527588,838.010498 
	C547.880737,838.491943 551.863525,836.536316 552.155945,839.856018 
	C552.363098,842.207031 548.602844,843.580261 545.373901,843.992432 
	C541.316040,841.640137 537.478760,839.285706 532.991577,839.634766 
	C530.437195,839.833435 528.091187,839.934937 525.678589,839.070740 
	C524.018860,838.476257 522.342285,837.670410 522.297119,835.841736 
	C522.236267,833.378174 524.469238,833.339478 526.623779,833.006470 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M516.140991,823.696289 
	C516.073303,826.702881 514.909546,828.852844 512.152954,828.839233 
	C508.669678,828.822144 504.682434,830.516663 501.828430,827.103027 
	C500.251282,825.216492 501.073730,823.364563 502.696472,821.826599 
	C505.438019,819.228333 508.484009,817.910583 512.126587,819.892090 
	C512.958923,820.344910 513.575134,821.194946 514.644165,821.932312 
	C515.704346,822.208374 516.107361,822.694946 516.140991,823.696289 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M547.386719,814.998291 
	C547.000000,815.000000 547.000000,814.501221 547.000000,814.251831 
	C546.937073,811.414429 548.403442,807.841309 543.003235,809.464905 
	C541.079285,810.043335 541.099792,807.368774 541.196167,805.120239 
	C542.260742,803.753723 543.343018,803.523987 544.686279,803.087891 
	C547.040955,803.001465 549.081970,803.000366 551.560242,802.998413 
	C552.990662,802.178101 554.071472,801.930176 555.639648,802.745972 
	C557.391296,802.998596 558.780090,802.999695 560.585693,803.001587 
	C561.001160,804.068420 560.999939,805.134460 560.998108,806.600220 
	C560.553101,807.000183 560.108704,807.000427 559.330933,807.001526 
	C557.405640,808.746277 555.125427,808.250732 552.857910,808.913452 
	C552.029907,809.332642 551.455750,809.589355 551.488037,810.640625 
	C551.480896,811.150269 551.454407,811.353394 551.213989,811.777039 
	C551.190552,814.359436 549.949097,815.180603 547.386719,814.998291 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M553.374573,833.048828 
	C553.000000,833.000000 552.501221,832.998901 552.251831,832.998230 
	C552.036377,831.381531 550.036926,829.037415 552.857605,828.382996 
	C555.862305,827.686096 559.187744,827.177124 562.049927,828.923523 
	C563.331299,829.705383 562.469543,831.013672 561.557861,831.861938 
	C559.240845,834.017761 556.634827,834.472717 553.374573,833.048828 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M520.608521,830.946045 
	C520.965515,831.987732 521.096130,833.127319 520.173035,833.730286 
	C517.652832,835.376465 515.073853,837.003052 511.970764,834.961853 
	C510.979401,834.309753 509.712982,833.502136 510.668060,832.274841 
	C512.629456,829.754517 515.453857,829.685364 518.334167,830.285950 
	C518.976013,830.419800 519.590088,830.686523 520.608521,830.946045 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M534.756409,799.255676 
	C536.785767,799.570190 538.571472,800.141968 540.678589,800.856873 
	C541.822937,801.990906 542.077454,803.069458 541.252380,804.637695 
	C538.894958,804.905273 536.748108,805.031616 534.698730,804.643250 
	C533.169617,804.353516 531.338867,803.685242 531.253418,801.702087 
	C531.158569,799.501343 533.502625,800.383728 534.756409,799.255676 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M570.583374,828.000000 
	C572.086060,829.946838 565.854492,832.234802 571.639404,833.989685 
	C571.671143,835.421082 571.342224,836.842224 571.006653,838.631653 
	C569.053650,837.900940 568.182373,835.447144 565.680725,834.978027 
	C564.030151,834.668518 564.191284,833.530823 565.053955,832.447571 
	C565.928528,831.349426 566.023804,830.082275 565.999146,828.384644 
	C567.388916,828.000000 568.777771,828.000000 570.583374,828.000000 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M550.781982,808.350769 
	C553.165649,806.133179 555.763245,807.355225 558.569031,807.002014 
	C559.393433,812.646545 555.313171,811.884033 551.422974,811.998291 
	C551.000000,811.997559 551.000000,811.498779 551.000000,811.249390 
	C550.413147,810.317627 550.288391,809.547546 550.781982,808.350769 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M516.411865,823.999878 
	C515.740601,823.505005 515.481201,823.010071 515.109680,822.258789 
	C514.938904,819.428284 515.843506,817.682678 518.765442,818.178650 
	C520.443848,818.463684 522.139526,819.205139 521.858154,821.361267 
	C521.452820,824.467346 518.918823,823.901794 516.411865,823.999878 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M545.186951,829.742615 
	C543.611755,829.999512 542.223450,829.999023 540.418823,829.998047 
	C539.937439,827.973938 537.520813,825.371399 541.138367,824.226257 
	C543.655396,823.429443 545.070068,825.379272 546.346619,827.099304 
	C547.190613,828.236511 546.041077,828.822632 545.186951,829.742615 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M515.993896,840.666382 
	C515.020630,838.580688 516.647766,838.318481 517.716919,837.685669 
	C518.938110,836.962952 519.991028,837.371338 520.771912,838.396423 
	C521.130493,838.867004 521.529358,839.657837 521.366699,840.106506 
	C520.936951,841.291748 519.723938,840.936707 518.783142,840.977356 
	C517.958984,841.013123 517.128174,840.897034 515.993896,840.666382 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M576.674500,493.933472 
	C574.159607,500.687561 570.816956,507.281952 568.867065,514.687683 
	C568.129944,515.770569 566.865845,516.435120 566.572998,517.395691 
	C563.740051,526.687927 557.547852,534.351562 554.097290,543.725647 
	C552.240845,544.073792 550.368225,544.280762 548.605774,543.931702 
	C547.198853,543.653015 543.372314,545.673523 545.326233,541.116028 
	C545.708679,540.223938 544.255249,540.146790 543.608826,539.723694 
	C540.175476,537.476868 539.829712,532.382812 542.960571,529.684326 
	C544.512695,528.346558 547.472229,527.704773 546.576172,525.044189 
	C545.763184,522.630188 544.128967,520.269714 539.632202,521.039307 
	C545.179199,515.979004 541.966614,513.348511 538.068542,511.867004 
	C534.236145,510.410461 533.634888,507.921478 533.883301,504.089600 
	C535.192139,500.320740 536.563110,496.997528 538.248901,493.463959 
	C541.538147,495.416504 543.732483,498.026459 544.799316,501.724487 
	C546.282349,506.865204 551.043884,508.751984 555.271729,510.864502 
	C556.983032,511.719666 558.762146,510.374023 560.012329,508.898071 
	C561.219604,507.472778 560.529541,506.385498 559.721497,504.932739 
	C558.674255,503.049774 558.761047,500.438263 555.427124,500.941833 
	C554.522522,501.078461 553.586243,500.145325 553.618347,498.957825 
	C553.650391,497.773407 554.577393,497.303864 555.539307,496.964600 
	C557.444580,496.292694 559.498718,496.785889 561.759521,496.199371 
	C557.727173,489.245880 554.014648,482.264893 545.080688,480.102051 
	C547.173828,472.404968 552.059570,466.519470 556.679565,460.128479 
	C559.193115,461.410522 561.114075,462.933075 564.098633,463.454224 
	C568.129517,464.158051 566.615295,469.085266 568.261475,471.886505 
	C569.599304,474.162994 566.764404,473.474609 565.716553,474.146606 
	C562.386169,476.282471 565.010742,478.070862 566.437866,479.547913 
	C568.415649,481.594879 571.426575,480.669617 573.869751,481.808807 
	C571.478577,481.965820 569.087402,482.122864 566.125793,482.317352 
	C569.764282,486.635376 569.461853,493.339172 576.674500,493.933472 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M537.393066,569.305420 
	C533.786804,570.593689 531.188171,569.495605 528.801514,566.676147 
	C523.464966,560.270142 519.377258,553.513184 516.843994,545.499268 
	C516.987915,544.762817 516.869873,544.501343 516.291565,544.173889 
	C515.752197,543.528564 515.424805,543.046204 515.083252,542.269287 
	C517.016907,538.822083 519.829834,539.439453 522.830017,539.933594 
	C525.576538,540.385864 529.060669,538.956665 529.343506,543.753296 
	C529.425049,545.135742 531.145630,545.123657 532.246582,544.962646 
	C535.601379,544.471985 537.809753,546.950134 540.426758,548.164734 
	C543.493225,549.587952 539.053528,549.826355 539.826416,551.591675 
	C541.990601,552.487732 544.648438,551.781311 547.629150,552.069336 
	C548.936401,552.757202 549.028625,553.693115 548.909790,555.020264 
	C548.739868,555.572815 548.644043,555.782104 548.337158,556.258057 
	C546.647949,557.494141 545.553040,558.773254 544.790833,560.735596 
	C544.496155,561.191406 544.362854,561.363403 543.998535,561.764282 
	C542.786499,562.797363 541.710938,563.518677 541.164429,565.111267 
	C540.745728,565.983337 540.382324,566.513062 539.782776,567.267212 
	C538.975708,568.102051 538.348145,568.663208 537.393066,569.305420 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M510.291809,532.832642 
	C509.168701,531.429749 509.202118,530.010315 508.856384,528.346680 
	C506.897034,522.303162 504.586761,516.733276 503.460999,510.472595 
	C507.225677,508.484283 511.283752,508.807007 515.021667,507.232147 
	C517.973694,505.988434 519.843079,509.495605 522.111206,510.995636 
	C522.958740,511.556152 522.532227,512.582581 521.538757,512.815918 
	C520.584717,513.039978 519.556458,512.933289 518.564026,513.012207 
	C517.250671,513.116577 515.523804,513.095825 515.660095,514.879395 
	C515.780334,516.452393 517.473022,515.950317 518.534058,515.972290 
	C522.024597,516.044495 525.518127,515.969604 529.008850,516.037109 
	C530.090271,516.057922 531.341187,515.620667 532.655212,517.353271 
	C528.538513,518.151855 524.723816,518.891846 520.909180,519.631836 
	C529.560913,518.748535 526.615601,529.352661 532.542297,531.074707 
	C526.149048,532.862061 520.519104,536.860718 513.136658,536.045776 
	C510.948059,535.853271 511.333832,533.974243 510.291809,532.832642 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M498.779694,498.365631 
	C500.265808,498.660767 501.397705,498.893066 502.513214,497.556885 
	C503.073364,496.885864 504.391693,496.057007 504.821014,497.582703 
	C505.808899,501.093536 506.823578,498.631287 508.011963,497.799866 
	C509.347961,496.865173 511.383240,496.446014 511.647491,498.347961 
	C512.154358,501.995728 515.072083,501.783722 517.441467,502.970978 
	C512.529114,504.207397 507.678741,505.146332 502.201080,505.044067 
	C499.454895,503.543030 499.090607,501.075500 498.779694,498.365631 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M543.357300,561.329895 
	C543.710205,561.318665 543.888367,561.500061 544.004761,561.448853 
	C545.049561,559.594788 546.190613,557.946106 547.950806,556.374756 
	C548.251160,556.221008 548.247803,556.051208 548.233154,556.134583 
	C549.389038,553.785522 550.034302,551.015625 552.816528,549.229004 
	C553.070496,549.002686 553.247742,548.753174 553.284546,548.606018 
	C553.653564,548.296631 553.985779,548.134399 554.586731,547.907471 
	C554.419922,552.943665 551.298950,556.769043 548.629883,560.788757 
	C547.831116,561.991821 546.395752,562.745300 545.984131,564.641235 
	C545.576355,566.837463 543.886292,567.497620 542.401794,568.814392 
	C540.684448,569.549561 539.200928,570.306763 538.874390,567.663208 
	C539.336609,566.848511 539.587952,566.338867 539.977417,565.575134 
	C540.976013,563.919983 542.081787,562.697083 543.357300,561.329895 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M498.980988,498.034546 
	C499.738586,500.229248 500.492279,502.410461 501.490997,504.825958 
	C501.688202,506.742188 502.526215,508.118774 503.442078,509.816833 
	C505.350769,515.825928 507.092926,521.544128 508.775818,527.644836 
	C508.416840,527.899780 507.917725,527.841431 507.845978,527.634644 
	C504.525360,518.066956 501.242065,508.486298 497.957764,498.516693 
	C498.103271,497.909241 498.245453,497.692261 498.672974,497.739868 
	C498.958282,498.004456 498.977081,498.021057 498.980988,498.034546 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M552.694763,549.000488 
	C552.290527,551.715698 550.577637,553.866089 548.532837,556.111938 
	C548.160583,555.114746 548.102722,554.011475 548.060547,552.499512 
	C549.174744,550.626648 549.790405,548.500305 552.694763,549.000488 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M481.000763,469.504150 
	C478.767639,469.774078 477.789551,467.871735 476.407227,466.200867 
	C478.645203,465.967285 480.153473,467.074860 481.000763,469.504150 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M510.162537,532.894897 
	C511.514679,533.132080 511.657928,534.518066 512.459534,535.839661 
	C512.695068,536.232849 512.713501,536.400208 512.856750,536.787109 
	C513.081482,537.234314 513.181458,537.461975 512.949097,537.858887 
	C510.368500,537.188049 510.659576,535.014221 510.162537,532.894897 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M484.809387,472.942261 
	C484.468903,473.183960 484.051300,473.097473 483.314636,473.034149 
	C482.142365,472.409943 481.425690,471.670227 481.258362,470.224304 
	C482.921082,470.129120 483.952423,471.208405 484.809387,472.942261 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M512.734863,538.340820 
	C512.688660,537.861816 512.760498,537.695435 512.906860,537.267883 
	C515.178162,537.289551 514.996765,538.916260 514.881897,540.866333 
	C514.349609,540.699707 513.875488,540.139160 513.162964,539.448364 
	C512.900635,539.096497 512.876831,538.874939 512.734863,538.340820 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M498.977295,497.665405 
	C498.791565,498.020020 498.624878,498.035553 498.209656,498.088623 
	C497.825653,497.828918 497.690186,497.531586 497.340790,497.101501 
	C496.650055,496.611115 496.473053,496.140808 496.880920,495.267944 
	C498.546692,495.159851 499.014954,496.053284 498.977295,497.665405 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M474.975433,464.631531 
	C474.635010,464.911133 474.256439,464.886444 473.593964,464.842865 
	C473.262451,464.415619 473.214813,464.007233 473.154358,463.290710 
	C474.012146,463.067535 474.694794,463.403473 474.975433,464.631531 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M529.438660,504.955872 
	C529.814758,504.272156 530.193542,504.261444 530.528442,504.751282 
	C530.598328,504.853607 530.595154,505.091400 530.522278,505.191315 
	C530.173950,505.669067 529.795715,505.651489 529.438660,504.955872 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M497.062469,494.770447 
	C497.146973,495.419830 497.128510,495.861420 497.118439,496.635864 
	C496.169983,496.503876 495.994690,495.643127 495.990540,494.360840 
	C496.002441,494.019897 496.469116,494.037262 496.700256,494.068420 
	C496.931366,494.099548 496.959473,494.562592 497.062469,494.770447 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M548.072388,439.635742 
	C548.512512,439.904816 548.622498,440.048553 548.800659,440.455139 
	C548.228455,442.098694 550.204407,442.138519 550.134094,443.540039 
	C549.659607,444.856628 548.623718,445.326141 547.837219,446.442749 
	C546.388672,450.315491 550.066528,449.539581 551.625305,451.088867 
	C552.034912,451.479370 552.175171,451.658203 552.416382,452.161133 
	C552.529358,452.936371 552.453491,453.370728 552.158875,454.091919 
	C551.940002,454.819580 551.791931,455.225464 551.491272,455.919189 
	C551.373352,456.219513 551.329041,456.255554 551.312378,456.278778 
	C550.062988,456.572205 550.202698,457.929871 549.301514,458.855286 
	C549.127991,459.110382 549.009338,459.024475 549.061035,459.076477 
	C548.399536,459.582794 547.815552,460.193604 546.924316,460.923035 
	C545.393677,461.735504 545.041016,462.894135 544.815369,464.498596 
	C544.037415,466.562225 542.381531,467.547058 541.442993,469.151642 
	C538.268005,474.580017 536.248657,474.843292 531.352783,470.770142 
	C530.586914,470.132935 529.903259,469.404846 529.147034,468.752625 
	C524.562744,464.798950 524.652161,462.411133 529.548035,458.533691 
	C527.884705,455.938599 523.833191,455.798553 522.867310,452.942017 
	C521.499878,448.897736 519.851990,444.995789 518.214417,441.075043 
	C516.899902,437.927917 517.344604,434.318573 515.054199,431.320892 
	C513.631958,429.459442 515.565369,426.722504 513.692200,424.070496 
	C510.861816,420.063141 510.813904,414.316437 505.887970,411.358398 
	C504.788086,410.697845 504.949707,408.749146 505.652374,407.623505 
	C507.821014,404.149261 507.701996,399.985199 509.397339,396.026886 
	C509.497040,395.693176 509.552765,395.573334 509.605957,395.534058 
	C513.894226,392.986908 517.030396,389.047089 521.336182,386.606628 
	C523.774292,385.224762 526.221252,383.973907 528.998230,383.463745 
	C531.057129,383.085510 532.627197,383.428955 532.723267,386.303650 
	C532.513855,390.299713 536.709106,391.606567 537.117615,395.171570 
	C537.315918,396.903015 538.622864,398.734467 539.641479,400.815247 
	C539.956055,401.875397 539.946716,402.597839 539.579224,403.649353 
	C539.057251,404.326599 538.649170,404.584229 537.821289,404.791351 
	C536.683655,404.958282 535.532166,404.391724 535.208191,405.042236 
	C534.934265,405.592438 536.121277,405.588593 536.978943,405.968445 
	C537.512146,406.199707 537.715149,406.315613 538.166138,406.680115 
	C538.834900,407.678589 539.092346,408.533295 538.782104,409.312073 
	C535.458740,417.654266 540.581604,424.807312 542.011292,432.474121 
	C542.279297,433.911102 543.460022,434.618866 544.948608,435.451843 
	C545.727478,436.233521 545.968201,436.908203 546.099060,437.995697 
	C546.019775,438.822571 545.849792,439.254181 545.629822,439.673889 
	C545.320496,440.264130 545.531738,439.420319 545.365845,440.065857 
	C545.324341,440.227173 545.416260,440.123932 546.050232,439.610718 
	C546.825867,439.340240 547.287598,439.357300 548.072388,439.635742 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M545.246704,439.274109 
	C545.081970,438.211487 545.080811,437.456360 545.046753,436.324951 
	C542.923828,434.161041 542.573608,431.727875 542.546875,429.195160 
	C542.509277,425.637878 543.991455,421.992889 541.670715,418.563934 
	C541.119507,417.749481 541.845215,416.651093 542.329895,415.779938 
	C543.764404,413.201385 543.511597,410.713715 541.802734,407.967896 
	C540.732971,404.585419 542.837402,404.056000 545.044067,403.589264 
	C547.531921,403.063049 545.946289,402.279755 544.913574,401.315186 
	C543.305908,397.398712 543.379150,393.082520 538.903442,390.917725 
	C537.247498,390.116760 538.822632,388.002991 539.650330,386.184479 
	C537.097900,386.569092 535.105652,388.921997 532.361450,387.232300 
	C532.129700,384.934326 531.599182,384.290771 529.163757,384.593872 
	C522.554932,385.416412 518.684509,390.486755 513.678589,393.782593 
	C512.605347,394.489197 511.899780,395.796600 510.015198,395.610168 
	C516.568665,385.678345 526.669006,381.035858 538.026550,380.157166 
	C554.249878,378.902039 570.080872,381.807587 585.469238,388.203430 
	C582.940369,391.552246 579.338989,389.148895 576.092468,390.140717 
	C577.488464,393.486603 578.075928,397.242889 579.893555,400.574860 
	C581.265564,403.089966 580.342041,404.584198 577.412659,405.402039 
	C581.366516,405.999786 584.324646,408.219330 587.718628,409.377625 
	C588.840332,409.760406 589.762024,410.879059 589.476135,412.100128 
	C588.513977,416.209839 591.681213,415.665680 594.238037,416.363647 
	C594.984802,416.782196 595.284363,417.158051 595.542969,417.979065 
	C595.544678,418.594238 595.495972,418.839600 595.261902,419.412415 
	C593.512390,421.637054 591.554321,422.528778 588.716675,421.974701 
	C588.147583,421.721283 587.937073,421.584137 587.533691,421.123230 
	C576.892334,415.377258 565.856018,414.501587 554.618835,417.267731 
	C548.391052,418.800720 545.119141,425.372803 546.438721,432.329193 
	C546.867432,434.589050 547.719360,436.768677 548.390137,439.373047 
	C547.869934,439.819244 547.337280,439.877441 546.403564,439.941864 
	C545.805054,439.825897 545.607666,439.703735 545.246704,439.274109 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M509.190674,395.802795 
	C508.995239,399.489929 510.658295,403.534058 507.264587,407.019043 
	C505.597351,408.731110 505.752136,411.359436 508.891754,412.506592 
	C511.904694,413.607544 513.353821,415.781372 512.986145,419.094299 
	C512.697876,421.692139 514.491089,423.441559 515.924622,425.203857 
	C517.164062,426.727539 521.098267,427.757233 515.960632,429.425629 
	C514.780334,429.808899 515.586060,431.240631 516.595093,431.620300 
	C517.784912,432.068085 519.092590,432.202606 520.237427,432.448914 
	C520.953674,434.279358 513.044861,435.413330 520.051208,437.364105 
	C520.298889,437.433075 520.665771,438.016724 520.613464,438.086060 
	C516.895203,443.012787 524.150940,445.335663 523.334656,450.173126 
	C522.567322,454.719635 529.112488,454.534515 531.392883,457.629761 
	C531.797119,458.178406 532.745422,458.326202 533.497864,458.685486 
	C532.619629,460.574249 531.021790,459.739166 529.879761,460.156982 
	C526.563110,461.370300 525.846436,464.297791 528.435425,466.661072 
	C530.146729,468.223175 532.195251,469.438171 533.792786,471.097412 
	C536.479919,473.888184 538.836121,472.153229 539.613892,469.973022 
	C540.481873,467.539886 542.047424,466.178192 543.885132,464.336090 
	C544.298767,463.615601 544.576965,463.226379 545.240601,462.751282 
	C545.815796,462.677216 546.051270,462.722107 546.361084,463.118896 
	C545.367493,465.526581 544.402527,467.644104 543.213562,469.627350 
	C538.373108,477.701691 537.964294,478.134491 530.974854,472.150024 
	C512.440491,456.280701 502.219147,436.258026 502.834106,411.453796 
	C502.976440,405.711578 505.051147,400.386169 509.190674,395.802795 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M595.711060,417.501282 
	C593.473328,417.053284 591.360718,418.322845 589.597107,417.009583 
	C588.175476,415.951050 583.863220,415.783966 588.095032,412.612335 
	C589.208130,411.778076 588.212891,410.364502 587.340088,410.436066 
	C583.277283,410.769043 580.944458,406.183960 576.765259,406.940704 
	C575.490723,407.171448 573.627380,406.935303 573.632141,405.324585 
	C573.637817,403.375336 575.616028,404.106140 576.789490,404.032440 
	C579.078308,403.888702 580.878662,403.403809 578.438049,401.034576 
	C576.477417,399.131287 576.201721,396.285583 575.877075,394.327911 
	C575.438171,391.681610 573.814819,390.629547 571.974060,388.702393 
	C576.677124,388.702393 580.765137,388.702393 585.274292,388.598206 
	C586.027832,388.400696 586.345398,388.437012 586.845520,388.752075 
	C592.547913,392.054596 597.659546,395.875671 603.757324,398.720612 
	C604.077026,398.874939 604.075623,398.944916 604.100220,398.919983 
	C606.562073,401.985901 610.403809,403.176941 613.556946,405.881836 
	C613.980530,406.272522 614.132629,406.448181 614.438232,406.938141 
	C614.855103,408.197418 614.662720,409.094635 614.150146,410.277985 
	C613.858887,410.761810 613.724243,410.942413 613.343750,411.349976 
	C612.202454,412.187408 611.204102,412.642548 610.107544,413.549774 
	C609.619629,413.814362 609.413513,413.903656 608.879639,414.086060 
	C604.608215,414.285126 600.716064,414.603363 597.001343,417.065796 
	C596.479858,417.294769 596.263672,417.367340 595.711060,417.501282 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M544.878296,479.837677 
	C551.016235,477.704834 553.314148,483.051361 557.030518,485.884064 
	C560.858582,488.801849 560.259583,494.929169 566.183960,496.616516 
	C562.171692,499.265625 558.132874,496.939423 554.378906,498.771484 
	C557.493774,501.325592 561.272644,498.884430 564.441711,500.698120 
	C563.463745,502.893036 561.007874,501.316223 559.520203,502.889130 
	C562.151245,504.526794 564.713501,506.331696 561.715210,509.792908 
	C558.719971,513.250610 555.402832,513.748901 551.872681,511.009064 
	C547.838379,507.877930 542.529785,505.966400 542.577942,499.285919 
	C542.590332,497.569550 538.474487,497.125641 538.684204,493.692993 
	C538.523071,490.621460 539.577637,488.385315 541.335938,486.094666 
	C542.520447,485.702820 542.476990,484.907837 542.642212,483.887207 
	C542.922363,482.774170 543.201782,482.019653 543.746460,481.113586 
	C544.232117,480.739075 544.460449,480.524597 544.799988,480.107574 
	C544.903259,479.896576 544.882568,479.859009 544.878296,479.837677 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M577.605835,420.918823 
	C578.769409,420.974091 579.505615,420.977570 580.609924,420.983154 
	C582.802124,423.153961 583.513611,425.552704 582.233887,428.662415 
	C581.680664,429.134857 581.435974,429.320709 581.126221,429.774750 
	C577.807068,430.922211 574.294189,431.414337 573.077271,435.692383 
	C568.802673,443.799347 561.700867,446.323090 554.183838,442.334839 
	C555.108215,440.584930 557.756104,439.427307 555.507080,437.198090 
	C552.115112,433.835999 552.315247,430.663116 555.580261,427.577515 
	C554.367920,425.971405 552.521606,426.501556 551.166321,425.226196 
	C551.647461,422.171234 553.291626,420.403717 556.577026,419.991821 
	C562.411194,417.837738 567.880432,419.809052 573.339661,420.099213 
	C574.580994,420.165161 575.926514,420.466675 577.605835,420.918823 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M449.271729,428.272217 
	C448.838989,425.497589 450.286530,425.029114 452.447693,424.962463 
	C456.715118,424.830902 461.103058,425.810181 465.181641,423.551849 
	C466.474548,422.836029 467.637207,423.805206 467.764221,425.045013 
	C468.135773,428.671051 469.233093,431.671295 472.425659,433.913971 
	C473.222870,434.474030 472.842865,436.709869 473.006256,438.594177 
	C471.596771,439.345459 470.184967,439.678040 468.407166,440.019257 
	C467.025208,440.642853 466.238403,440.173126 465.238373,439.243103 
	C464.977020,438.559662 464.973877,438.123474 464.970581,437.360107 
	C464.155060,435.881653 463.702881,434.480408 461.909485,434.226288 
	C458.980286,433.811279 456.994507,431.914429 455.233337,429.244690 
	C453.178467,428.854736 451.304779,429.373016 449.271729,428.272217 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M550.596313,425.046204 
	C552.442139,424.974945 553.928223,424.988312 555.413513,425.027588 
	C556.437683,425.054718 557.455322,425.207977 557.777893,426.382660 
	C558.062134,427.417542 557.840515,428.443207 556.843994,428.965698 
	C551.967041,431.522858 554.767517,433.864075 556.871765,436.464355 
	C559.781189,440.059540 559.309570,441.112640 554.370483,442.004181 
	C552.676758,439.896149 551.365051,437.783325 550.017212,435.322388 
	C551.608093,433.258881 550.629517,431.848022 549.217407,430.237427 
	C549.262756,428.355103 548.198486,426.349030 550.596313,425.046204 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M582.409790,429.020325 
	C581.648071,426.614258 581.320557,424.213348 580.985535,421.398865 
	C582.795471,420.970734 584.612915,420.956177 586.885620,420.870544 
	C587.340820,420.799438 587.711304,420.827454 587.854370,420.945923 
	C590.673828,422.722046 592.744995,421.750061 594.795959,419.332947 
	C595.056152,419.106293 595.044800,419.024597 595.080811,419.044739 
	C597.079468,417.467407 598.942139,416.402954 599.916382,420.363953 
	C598.952087,423.145447 596.859192,423.932922 594.315796,424.660461 
	C592.800903,425.172302 591.536987,425.450226 590.669434,426.941467 
	C590.304443,427.313019 590.143677,427.446503 589.712463,427.737122 
	C588.713196,428.275452 587.865356,428.372009 586.906372,428.999481 
	C586.441833,429.221649 586.244873,429.287018 585.738770,429.316406 
	C584.567749,429.195465 583.705872,429.110443 582.409790,429.020325 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M592.927734,424.195435 
	C595.001587,423.251007 596.791260,422.417297 598.810852,421.337128 
	C601.439270,418.131042 604.490234,416.797760 608.619019,417.633484 
	C609.016174,419.663513 608.339539,420.605499 606.418335,420.845520 
	C603.425354,421.219391 600.779663,422.637634 597.991882,424.449677 
	C597.920105,425.813232 597.171753,426.337219 596.151428,427.030975 
	C594.650635,427.646515 593.600220,428.342194 592.505981,429.553955 
	C590.607239,431.148010 588.708679,431.867432 586.207886,431.816101 
	C585.654968,431.637970 585.447388,431.528168 585.041382,431.127350 
	C584.973572,430.490845 585.104248,430.145355 585.332275,429.540161 
	C585.429626,429.280457 585.737732,429.201019 585.864624,429.105103 
	C586.674988,428.183960 587.656555,427.830231 588.836914,427.214020 
	C589.099426,427.052216 589.544678,426.920166 589.728760,426.778595 
	C590.841125,425.881287 591.387573,424.678070 592.927734,424.195435 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M577.841187,420.653076 
	C573.533447,423.104706 569.130066,420.552032 564.660828,420.548004 
	C562.463562,420.545990 560.133240,420.053345 557.389038,419.992615 
	C557.001404,419.384003 557.086609,418.249847 557.125671,418.251434 
	C564.007141,418.530609 571.179260,416.097229 577.841187,420.653076 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M547.032654,446.324768 
	C547.142944,444.595612 547.965759,444.029388 549.820374,444.075409 
	C550.352478,444.116302 550.731140,443.981018 550.710571,443.772644 
	C550.689941,443.564270 551.098999,443.473877 551.304199,443.515625 
	C558.140747,449.052734 558.140747,449.052734 551.682373,456.138855 
	C551.272278,455.737122 551.171143,455.254730 551.078979,454.400238 
	C551.226318,453.852692 551.364746,453.677216 551.733582,453.258118 
	C551.974060,452.845123 551.984131,452.675781 551.998291,452.252014 
	C551.690186,451.708344 551.444397,451.369629 551.146118,450.687622 
	C550.839050,449.778778 550.537903,449.255829 549.587585,448.920654 
	C548.114807,448.526855 547.282471,447.884399 547.032654,446.324768 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M455.034698,429.347168 
	C458.114716,430.999786 460.864838,433.286774 465.123474,433.289337 
	C468.099457,433.291138 464.938232,435.267548 465.036804,436.699280 
	C463.266937,437.027008 461.563446,437.021088 459.433777,437.014099 
	C459.024200,435.244751 458.822632,433.604767 456.348511,433.043396 
	C455.687531,431.892853 455.380768,430.791534 455.034698,429.347168 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M603.949829,398.561462 
	C601.371948,399.234772 599.292908,397.684937 597.225830,396.405701 
	C593.780029,394.273193 590.494629,391.881531 587.092224,389.250061 
	C593.355530,390.725098 598.459412,394.719360 603.949829,398.561462 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M573.366821,435.985291 
	C572.123108,435.389404 570.510925,434.030579 571.815918,433.599823 
	C574.335144,432.768250 573.295532,428.331390 576.861267,429.166321 
	C577.981750,429.428711 579.099243,429.703400 580.614563,429.974609 
	C578.878723,432.333313 576.439026,434.313049 573.366821,435.985291 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M614.522461,406.126465 
	C609.792297,406.023193 606.926697,402.858307 604.124146,399.247253 
	C605.868591,398.864136 606.779236,400.510742 608.618469,401.011597 
	C608.952881,401.062164 608.966858,401.024414 608.952332,401.033630 
	C611.000793,402.405518 613.998291,402.654846 614.522461,406.126465 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M579.735962,437.734558 
	C581.122192,438.304718 582.550110,438.926117 580.472229,439.788025 
	C579.103149,440.355927 577.319336,439.923828 575.292969,439.895447 
	C575.122070,438.423553 576.099792,437.600128 577.611206,436.879822 
	C578.511353,436.934418 579.060791,437.136749 579.735962,437.734558 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M548.988892,430.380432 
	C552.972351,430.397217 552.770508,432.280273 550.342957,434.902344 
	C549.652893,433.564972 549.324646,432.155579 548.988892,430.380432 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M545.791382,460.360291 
	C546.845520,459.713287 547.651428,459.385956 548.785034,459.093567 
	C548.956848,460.754211 548.069763,461.992828 546.714478,463.301056 
	C546.435425,463.470795 546.174316,463.205536 546.044312,463.072388 
	C545.348755,462.258728 545.248352,461.501801 545.791382,460.360291 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M464.987915,439.353760 
	C465.801147,439.250305 466.622192,439.504730 467.742188,439.893494 
	C467.860931,440.986267 467.534882,441.908997 466.351990,441.783539 
	C465.183289,441.659607 465.064545,440.657715 464.987915,439.353760 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M584.528198,434.601135 
	C583.530823,435.000031 583.083557,435.018463 582.302063,435.068695 
	C581.843201,435.216492 581.718567,435.332550 581.337158,435.437500 
	C580.940613,435.325439 580.800964,435.224487 580.650574,434.865234 
	C581.140564,434.076508 581.641174,433.546082 582.413086,432.941223 
	C583.067993,432.614685 583.487244,432.450958 584.273010,432.428406 
	C585.358154,432.908386 585.387695,433.518494 584.528198,434.601135 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M549.986572,443.992157 
	C550.199829,442.534332 547.338501,442.738403 548.591919,440.802246 
	C550.144409,440.988281 550.699280,442.028015 551.410522,443.281860 
	C551.509460,443.557343 551.213684,443.820404 551.064331,443.950165 
	C550.755188,444.227753 550.595520,444.375580 550.227905,444.271667 
	C550.020081,444.019928 549.998840,444.000488 549.986572,443.992157 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M549.459412,459.071594 
	C548.946838,457.828857 549.428955,456.904816 550.953613,456.330139 
	C551.369873,457.529541 550.866699,458.438965 549.459412,459.071594 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M579.975403,437.701782 
	C579.380554,437.740204 578.812805,437.540283 578.087463,437.071411 
	C578.298584,436.429596 578.667358,436.056732 579.296509,435.613525 
	C579.719910,435.604523 579.882996,435.665894 580.223755,435.918762 
	C580.414307,436.283569 580.427124,436.456970 580.249756,436.808716 
	C580.059509,436.987061 580.002563,437.463440 579.975403,437.701782 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M585.131653,432.749512 
	C584.405029,432.976685 583.913269,432.963348 583.052917,432.908386 
	C582.937927,432.026581 583.360779,431.327454 584.539612,430.921112 
	C584.842896,430.836334 585.032532,431.031403 585.127686,431.128632 
	C585.443237,431.634247 585.490967,432.061981 585.131653,432.749512 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M574.594604,439.861145 
	C574.723877,440.333038 575.307129,441.301025 573.888550,440.808746 
	C573.830994,440.274780 574.036377,440.031738 574.594604,439.861145 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M542.383545,483.747864 
	C542.881165,484.287567 542.928467,485.018524 541.964539,485.767761 
	C541.772949,485.242493 541.948608,484.604767 542.383545,483.747864 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M544.652222,479.965759 
	C544.882141,480.248291 544.733887,480.534058 544.235229,480.857941 
	C544.141602,480.652985 544.271423,480.343964 544.652222,479.965759 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M579.687561,435.678223 
	C579.691284,435.231476 579.903503,434.987366 580.416626,434.708862 
	C580.639893,434.606934 580.867737,434.865326 580.979858,434.996094 
	C581.234619,435.579651 581.099609,435.938721 580.408875,436.142822 
	C580.093689,436.069122 579.818298,435.813232 579.687561,435.678223 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M562.028931,625.856567 
	C562.662476,625.955261 562.849976,626.119873 563.044250,626.529419 
	C563.209717,629.667236 565.183777,631.048340 567.617126,632.014648 
	C573.949463,634.529236 578.800171,638.562317 581.411804,645.051575 
	C582.533386,647.838623 577.717346,656.664185 574.491516,656.401367 
	C570.617859,656.085876 565.686768,657.493042 563.374756,652.281250 
	C563.005615,651.449158 561.770813,650.823669 562.478149,649.799194 
	C563.263184,648.662170 564.547729,648.969849 565.664856,649.406616 
	C567.357056,650.067932 568.914001,651.030518 570.857056,651.010864 
	C575.159912,650.967285 577.446106,647.592896 575.852844,643.663330 
	C574.242432,639.691467 571.912109,637.356934 567.186584,636.688782 
	C560.567871,635.752869 557.523132,630.004761 555.255554,624.537415 
	C553.786987,620.996521 556.052917,617.697815 559.147583,615.568420 
	C562.458801,613.289978 566.041992,613.320862 569.501953,615.334290 
	C570.774414,616.074829 572.357239,617.167053 571.633301,618.838135 
	C570.701904,620.988037 569.131653,619.327087 567.842163,618.934814 
	C567.050964,618.694153 566.300964,618.240356 565.498230,618.129639 
	C563.291443,617.825195 560.903931,617.746216 559.752136,620.030334 
	C558.585754,622.343323 559.280396,624.473328 562.028931,625.856567 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M622.223206,625.187988 
	C621.440613,625.215210 621.023987,625.160950 620.310974,624.996704 
	C618.579285,623.774231 617.071350,623.730042 615.286621,624.851074 
	C615.070496,625.121704 615.034058,625.036621 615.076050,625.056152 
	C612.083191,628.736267 612.431702,632.596802 616.416870,637.344482 
	C616.775818,637.791992 616.919373,637.971375 617.194458,638.469177 
	C619.052917,644.852722 621.844238,645.982422 626.193787,642.345520 
	C627.117432,641.573181 628.050537,640.705505 629.253784,641.506226 
	C630.180054,642.122681 630.083313,643.295837 629.920837,644.264221 
	C629.629944,645.997314 628.857849,647.410095 626.840698,647.571594 
	C624.239258,647.779846 621.711365,650.521545 619.083923,648.287720 
	C614.518066,644.405762 610.692017,639.904297 609.036316,633.942444 
	C608.905396,633.471130 609.088989,632.877380 608.878296,632.468323 
	C605.265320,625.453918 607.263611,620.239136 613.984680,616.120056 
	C614.899719,615.590088 615.759521,615.427185 616.847961,615.410034 
	C618.965698,617.367798 622.215942,616.161743 624.201172,618.688599 
	C624.888000,620.299622 625.196594,621.820862 625.451904,623.732788 
	C624.496094,624.548584 623.617554,625.041687 622.223206,625.187988 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M616.628540,635.865112 
	C616.416748,625.933899 616.417053,625.932861 625.830017,628.475830 
	C626.149109,628.562012 626.449036,628.719238 627.079956,628.920776 
	C627.557068,629.436646 627.712036,629.875122 627.859192,630.664917 
	C627.770813,631.206543 627.690125,631.396912 627.831177,631.804199 
	C628.205078,632.317688 628.287109,632.632629 628.247620,633.206177 
	C628.844177,634.849976 628.870178,636.153076 627.258667,637.511536 
	C626.561890,638.173889 626.189270,638.568848 625.543091,639.028931 
	C622.519104,640.843811 620.151794,639.760010 617.514526,637.887573 
	C616.756714,637.293579 616.539307,636.817139 616.628540,635.865112 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M616.950806,637.246582 
	C619.607544,637.722656 621.667114,639.622803 624.854736,639.062805 
	C622.833191,642.559998 620.386536,640.547791 617.633484,638.947754 
	C617.326050,638.787659 617.277771,638.809265 617.252502,638.817017 
	C616.714111,638.532227 616.531799,638.100220 616.950806,637.246582 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M627.299866,637.799438 
	C627.306458,636.589172 627.678345,635.399414 628.123169,633.828003 
	C629.818298,635.122681 631.713318,636.832092 627.299866,637.799438 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M579.695312,657.118347 
	C579.850403,658.139587 579.373779,658.847107 577.852295,658.791382 
	C578.120728,658.204346 578.748108,657.666809 579.695312,657.118347 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M628.056152,631.759033 
	C627.897034,631.763489 627.741150,631.505981 627.718384,631.132324 
	C627.920715,631.176514 627.990051,631.336792 628.056152,631.759033 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M632.508057,641.500061 
	C632.534424,641.485962 632.481689,641.514160 632.508057,641.500061 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M617.087769,636.734863 
	C617.227051,637.378235 617.233093,637.791992 617.233154,638.515259 
	C611.828003,636.025024 610.298767,628.770020 614.727905,625.096130 
	C613.867371,628.563171 614.175049,631.888428 616.449646,635.258179 
	C616.814270,635.819580 616.884399,636.162476 617.087769,636.734863 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M624.502808,618.431335 
	C621.821411,618.949158 619.681824,617.356934 617.224304,615.903076 
	C619.729919,615.807495 622.961792,614.377197 624.502808,618.431335 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M615.482544,625.115295 
	C616.092529,621.893066 617.877502,622.598450 619.925171,624.542175 
	C618.641296,624.960815 617.267883,625.034851 615.482544,625.115295 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M622.122192,625.507080 
	C622.482544,624.227356 623.470276,623.995361 624.992065,624.086121 
	C625.171021,626.106018 624.081238,626.486755 622.122192,625.507080 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M553.713745,566.286743 
	C554.664917,564.410767 555.453857,562.842163 556.514282,561.082092 
	C559.946045,561.260620 562.575012,563.024109 565.394165,564.258545 
	C567.003235,564.963135 568.161621,566.425049 567.663879,568.275696 
	C566.789551,571.526245 568.788757,571.461487 570.923462,571.451721 
	C572.918701,571.442566 574.914062,571.457520 576.909363,571.451965 
	C584.124268,571.432007 586.597778,569.627502 588.981812,562.902161 
	C589.849426,560.454712 588.524048,559.156616 586.584595,558.968445 
	C580.402649,558.368713 574.415283,555.298645 567.945435,557.812500 
	C565.089722,558.922058 562.030518,558.367065 559.156860,556.215942 
	C560.747925,552.376465 564.114929,549.652954 564.218445,545.051636 
	C564.268188,542.841431 566.013062,543.180481 567.923950,544.009338 
	C573.186340,546.291992 577.629639,549.928833 583.222778,551.849670 
	C591.211548,554.593079 599.177429,557.059143 607.819885,558.605652 
	C608.775635,561.087585 606.886780,561.646301 605.622742,562.533936 
	C603.184204,564.246338 600.853088,566.106506 598.816895,568.253845 
	C597.500427,569.642212 596.264038,571.352844 597.788574,573.742798 
	C598.477905,573.973206 598.915588,573.973328 599.681519,573.976929 
	C600.600464,574.674683 600.760376,575.454529 600.243591,576.672363 
	C598.319397,577.090576 596.356873,577.063232 596.321838,579.357666 
	C596.291748,581.335815 598.140747,580.813416 599.366028,580.986084 
	C600.325073,581.121155 601.622498,581.013062 601.421631,582.445557 
	C601.267212,583.547302 600.151123,583.907288 599.105530,583.962097 
	C597.127197,584.065796 595.084106,583.918884 593.386841,585.938354 
	C596.489258,588.216797 600.244263,585.964722 603.613892,587.707703 
	C601.932556,590.538269 596.286621,588.931396 596.836365,593.767090 
	C596.045532,593.589111 595.197327,593.537476 594.474426,593.209717 
	C592.204102,592.180176 589.906433,591.375427 587.148682,591.893433 
	C586.379395,592.047302 585.822571,591.929565 585.060425,591.614136 
	C583.841003,589.144287 581.782776,589.074585 579.347046,589.022949 
	C578.961304,589.058350 578.964355,589.034180 578.955994,589.037598 
	C578.064087,587.896057 576.789246,587.569397 575.157593,587.218628 
	C573.712402,586.615417 572.563049,586.163757 571.190186,585.487915 
	C570.660095,584.530701 569.960571,584.514221 569.009216,584.291626 
	C568.200623,584.006531 567.715393,583.797180 567.064087,583.377319 
	C566.374878,582.159912 565.364136,581.831238 564.130676,581.316528 
	C563.829468,581.239807 563.816895,581.137634 563.792725,581.183044 
	C561.470093,579.193481 559.595520,576.742432 557.109619,574.424072 
	C555.560608,573.013367 554.235168,571.850525 552.817505,570.365845 
	C554.028259,569.140076 553.418274,567.778076 553.713745,566.286743 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M538.758484,567.368774 
	C539.305115,569.183228 540.347290,568.762878 541.697632,568.102051 
	C542.968567,567.516296 543.849182,567.787720 544.852661,568.763184 
	C545.042786,569.075928 545.033203,569.510620 545.030029,569.727295 
	C543.214294,571.252380 541.364380,572.512695 539.599426,573.882568 
	C537.729614,575.333801 536.720215,574.628174 536.057373,572.328125 
	C536.358887,571.326233 536.696411,570.660583 537.068848,569.712402 
	C537.104492,568.460083 537.418762,567.693542 538.758484,567.368774 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M556.758545,574.453186 
	C562.090088,573.103333 560.996094,578.944824 563.792480,580.858643 
	C560.707703,580.739136 558.698364,578.466248 556.065857,576.626831 
	C556.090210,575.857178 556.360657,575.293701 556.758545,574.453186 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M545.411499,569.073730 
	C544.216370,568.801453 543.390015,568.526917 542.262024,568.118896 
	C542.602600,566.464600 544.082397,565.986755 545.645020,565.121826 
	C546.116760,564.996765 546.302185,565.005554 546.765869,565.027100 
	C548.110779,566.008423 548.026611,567.173645 547.420166,568.804688 
	C547.222900,569.214844 547.220886,569.259216 547.229004,569.281738 
	C546.751465,569.226685 546.265869,569.149109 545.411499,569.073730 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M575.042725,587.353027 
	C576.312561,587.083679 577.951416,586.499939 578.893921,588.676758 
	C577.494141,589.137207 576.375427,588.344116 575.042725,587.353027 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M563.940002,581.542114 
	C565.091797,580.994873 566.322693,580.829407 566.875488,582.801147 
	C565.746460,583.161804 564.835754,582.638184 563.940002,581.542114 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M568.873901,584.497925 
	C569.332031,584.363403 569.978210,584.510925 570.795532,584.961060 
	C570.331726,585.102478 569.696899,584.941284 568.873901,584.497925 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M516.104858,544.250793 
	C516.488953,544.221130 517.426392,543.760986 516.979736,544.993408 
	C516.492798,545.021851 516.264221,544.806213 516.104858,544.250793 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M484.127869,652.329224 
	C482.221924,648.803284 480.093445,645.866943 476.343445,644.039490 
	C475.297729,643.325684 475.061249,642.537537 475.105896,641.237305 
	C475.310394,640.569214 475.442719,640.319275 475.929596,639.836426 
	C482.761047,644.409180 486.903046,651.388000 492.516815,657.022949 
	C508.745392,673.312866 525.690674,688.693298 545.607788,701.000183 
	C546.968140,702.094727 548.129761,702.918945 549.742493,703.626587 
	C550.486206,704.157837 550.979187,704.509338 551.769348,704.925781 
	C552.450562,705.514587 553.118835,705.487793 553.768921,706.047852 
	C554.881836,707.949402 556.831177,707.914856 558.518555,708.944397 
	C559.011353,709.180420 559.204468,709.306519 559.681519,709.630127 
	C560.678894,710.240173 561.392334,710.652649 562.414429,711.102783 
	C563.359375,711.426208 563.871521,711.898193 564.725342,712.479492 
	C565.388550,712.802002 565.766357,712.930847 566.443604,713.081787 
	C567.431763,713.424316 567.940857,713.988525 568.771606,714.588501 
	C570.632263,715.517883 572.263062,716.286926 574.227051,717.092896 
	C574.931274,717.174438 575.260803,717.318115 575.766479,717.722229 
	C575.978210,717.897583 576.419189,718.025330 576.648193,718.041016 
	C577.604309,718.810669 578.654419,718.952209 579.764038,719.621338 
	C582.897522,722.366516 586.693237,722.657532 590.491089,723.935913 
	C591.021545,724.169495 591.199280,724.295715 591.668091,724.571167 
	C603.009399,729.598267 614.727051,732.033203 626.526611,734.171021 
	C626.844971,734.228699 627.145569,734.384766 627.718750,734.764404 
	C622.865295,736.249146 617.981934,734.446228 613.042542,733.729431 
	C608.958740,733.136902 604.996277,731.726379 600.783081,731.975220 
	C599.388062,732.057678 598.288147,731.434021 597.135498,730.652405 
	C593.875000,728.441406 589.688293,729.205505 587.742432,732.430420 
	C586.390320,734.671448 588.184509,735.039612 589.786438,734.930542 
	C592.086792,734.773865 594.361084,734.261108 596.660095,734.046814 
	C597.841309,733.936707 599.169006,734.224365 599.441589,735.590515 
	C599.714844,736.960266 598.282654,736.916382 597.462341,737.329712 
	C596.570862,737.778992 595.652893,738.179321 594.782288,738.665222 
	C593.913513,739.150208 592.949219,739.662781 593.392883,740.934692 
	C593.731018,741.904114 594.641235,741.934448 595.465149,741.951294 
	C596.794861,741.978455 598.148743,741.773132 599.449707,741.961182 
	C600.491333,742.111633 602.630493,739.616882 602.483093,742.995422 
	C602.411987,744.625000 604.722656,747.162354 600.938293,747.569763 
	C600.782715,747.586548 600.619690,747.685181 600.491943,747.786011 
	C598.776123,749.138672 594.631958,749.319763 595.604126,751.673767 
	C596.775208,754.509521 600.760498,753.321777 603.212891,752.537537 
	C606.012085,751.642273 608.145081,749.484253 607.198181,754.899048 
	C606.722168,757.620789 609.061401,758.766602 611.730469,759.044739 
	C614.996094,759.384888 615.759949,760.637573 612.776978,762.853516 
	C611.385376,763.887146 610.463196,765.409302 610.192871,767.019775 
	C609.834961,769.151672 607.409607,768.517578 606.671387,770.014832 
	C610.678650,773.860291 614.454956,767.474976 618.689331,769.705383 
	C617.004211,772.254089 614.873657,772.825806 612.251892,773.095032 
	C609.146240,773.413879 605.392334,773.028198 604.047852,777.531616 
	C594.860474,778.070679 585.923401,776.020447 577.021667,774.435608 
	C554.211182,770.374634 532.621826,762.321472 511.744476,752.404541 
	C505.760986,749.562439 500.107849,746.024841 494.088593,742.546387 
	C494.412140,738.984009 497.071930,739.480225 498.581879,740.506042 
	C504.476318,744.510315 512.454407,743.707153 517.693665,749.484070 
	C520.289429,752.346191 524.247986,753.663635 527.574829,749.384583 
	C528.728882,747.900269 530.672913,747.348999 532.469543,747.076660 
	C539.757568,745.971985 546.400574,742.768433 553.413696,740.779602 
	C554.862305,740.368774 557.320435,740.401794 556.764404,738.055481 
	C556.221619,735.765137 554.861450,733.712402 551.724670,734.678955 
	C549.412476,735.391418 547.132812,735.535156 545.233704,733.535156 
	C543.896790,732.127197 542.493958,733.103516 541.068298,733.477295 
	C535.286438,734.992798 529.528870,736.833008 523.425415,735.285034 
	C521.910889,734.900940 520.456543,734.447449 520.350159,732.557068 
	C520.273438,731.193604 521.122986,730.122986 522.330994,730.056213 
	C526.842529,729.807007 529.648987,726.302490 533.324707,724.486267 
	C539.696594,721.337891 544.288818,715.261658 551.533691,713.273743 
	C548.271729,713.239807 545.356506,714.648193 542.191345,714.510803 
	C539.689453,714.402100 537.193970,714.419983 534.786560,713.598206 
	C529.403198,711.760498 527.839294,707.361267 531.682312,703.213257 
	C534.262329,700.428528 533.680237,698.942505 531.256836,696.805603 
	C527.812012,693.768005 525.010254,689.450378 519.360779,692.898621 
	C518.151367,693.636902 516.405029,692.941345 515.002319,692.362854 
	C512.730103,691.425659 511.536438,690.090637 513.856506,687.989075 
	C514.961609,686.988037 515.699036,686.051941 514.477478,684.328979 
	C511.623871,681.270508 508.958862,678.585510 504.815765,678.118958 
	C503.172424,677.933838 501.999298,676.787537 502.117462,675.065369 
	C502.356445,671.582642 500.094910,669.697205 497.910858,667.608643 
	C493.013977,662.925781 487.917542,658.413513 484.127869,652.329224 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M436.320801,376.867584 
	C435.581360,372.149017 440.435150,370.390472 441.987732,366.348938 
	C442.563110,363.690308 443.180695,361.524261 443.201721,358.982422 
	C443.261536,351.763885 445.193176,344.923706 450.735107,339.250427 
	C454.164612,338.544342 457.137726,339.873993 460.231812,339.965302 
	C461.285858,339.996399 462.750092,340.526703 463.296783,339.170044 
	C463.834167,337.836365 461.207001,338.235962 461.760468,336.265594 
	C463.704254,337.664062 467.050262,335.420837 467.987122,339.634766 
	C466.827240,342.377258 468.800018,342.181580 470.061401,342.539490 
	C471.721527,343.010468 473.667603,343.151398 473.242615,345.929993 
	C471.222656,349.151184 466.519257,347.805115 465.247803,351.848877 
	C465.296906,352.216248 465.255829,352.194397 465.233398,352.188934 
	C464.850159,352.222748 464.566803,352.395325 464.203186,352.898926 
	C453.428192,359.273926 444.644928,367.570709 436.320801,376.867584 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M476.179321,639.289856 
	C476.226471,639.839478 476.168793,640.075378 476.057129,640.671509 
	C474.192139,642.170349 473.162903,641.189819 472.186035,639.320801 
	C469.176819,635.764526 468.510925,630.327026 462.494080,629.960815 
	C460.138458,628.309692 458.912659,626.161072 458.350525,623.684814 
	C457.240601,618.795593 454.082947,615.141418 451.075775,611.403992 
	C449.550964,609.508911 447.515106,608.490601 445.011322,608.475769 
	C443.522583,608.466980 442.016541,608.477783 440.568451,608.039368 
	C439.383820,607.680664 438.437134,607.029541 438.367554,605.665955 
	C438.297241,604.288513 439.050415,603.381714 440.381165,603.299316 
	C443.942169,603.078796 443.362213,601.546448 442.182587,599.076965 
	C438.332916,591.017822 434.523193,582.937500 430.763611,574.834778 
	C430.133026,573.475769 429.696747,571.898865 428.348877,571.152588 
	C425.780212,569.730286 425.136169,567.725952 425.556152,564.930481 
	C425.858917,562.915161 424.518616,561.135864 423.870239,558.900635 
	C423.701904,558.116150 423.647980,557.665283 423.594482,556.884583 
	C423.504547,555.085327 423.288666,553.668701 421.851990,552.411560 
	C421.137299,551.737671 420.776062,551.191650 420.315765,550.309448 
	C420.100739,549.791992 420.028778,549.579102 419.880829,549.037476 
	C419.666931,547.822205 419.633179,546.924622 419.496765,545.697449 
	C419.286102,544.418457 418.631622,543.709595 418.100403,542.521973 
	C417.550842,540.611816 417.497498,539.084290 418.626038,537.329346 
	C419.038055,536.998596 419.216522,536.985779 419.663086,536.979858 
	C425.636414,552.382019 431.454834,567.741272 439.172363,582.268127 
	C448.616150,600.044250 459.400055,616.976746 471.811249,632.852112 
	C473.340820,634.808655 474.658600,636.930786 476.179321,639.289856 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M451.769897,377.229675 
	C452.446625,376.334778 452.952942,375.700928 453.630310,374.812225 
	C454.476288,374.491577 454.711029,373.849030 455.462189,373.415894 
	C456.084259,375.219513 455.663452,377.366913 457.967316,378.510406 
	C459.862000,379.450867 461.167542,381.155823 458.847137,382.621490 
	C457.364990,383.557678 455.174622,386.480835 453.178009,382.803833 
	C452.711700,381.945038 451.916565,381.264862 451.127930,380.194946 
	C450.995636,379.038910 451.263245,378.255981 451.769897,377.229675 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M453.891174,369.021179 
	C457.843292,363.976410 462.616974,360.190277 469.599213,357.034668 
	C466.621399,362.904602 461.034790,364.304230 458.592560,369.068115 
	C458.126709,369.287018 457.925262,369.295776 457.421783,369.346649 
	C456.512512,369.553375 455.893829,369.642853 454.943481,369.634521 
	C454.425446,369.502533 454.229889,369.408173 453.891174,369.021179 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M455.832581,373.105225 
	C455.298431,373.722473 454.848541,374.121368 454.099976,374.538818 
	C453.664581,373.270691 454.414551,370.768951 451.684174,372.732025 
	C449.949371,373.979279 448.483276,375.600372 446.559570,377.082703 
	C447.368073,373.417328 450.868500,371.725189 453.440979,368.855774 
	C453.748047,368.728577 454.004120,368.993286 454.133209,369.124573 
	C455.385620,370.167542 456.182159,369.160858 457.399170,368.769409 
	C457.713867,368.763641 458.020660,369.021057 458.174408,369.149658 
	C458.008575,369.857239 457.688965,370.436249 457.273499,371.334534 
	C457.798676,371.902191 457.859619,372.239044 457.000122,372.509644 
	C456.337189,372.423065 456.088623,372.601593 455.832581,373.105225 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M656.716431,736.469299 
	C651.877075,738.895142 646.596741,738.226807 641.116699,736.949097 
	C646.060181,735.875183 651.206665,735.631531 656.716431,736.469299 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M489.655518,352.091461 
	C491.532227,349.670288 493.825073,349.502319 496.274323,350.637054 
	C496.523926,350.752686 496.718475,351.738495 496.551575,351.887787 
	C494.445648,353.771057 492.078857,353.091217 489.655518,352.091461 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M531.753296,347.515686 
	C529.981567,347.370392 529.846558,346.326935 530.496765,345.450897 
	C532.069641,343.331757 534.179932,343.817200 536.688721,344.627533 
	C535.501648,346.498322 533.468567,346.462616 531.753296,347.515686 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M447.371338,385.176025 
	C448.095734,385.070160 448.675018,385.040833 449.039154,385.306427 
	C450.476410,386.354706 451.159607,387.805725 450.795654,389.586487 
	C450.722565,389.943970 450.131531,390.497589 449.844696,390.460114 
	C448.044067,390.224915 447.919373,388.447571 446.970459,386.949158 
	C446.801178,386.173645 446.895447,385.763885 447.371338,385.176025 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M543.793945,344.427612 
	C545.618530,346.130432 544.324768,346.662445 543.005920,347.103638 
	C541.973328,347.449066 540.881836,348.334259 539.819397,347.510040 
	C538.829529,346.742188 539.064514,345.737427 540.176514,345.264099 
	C541.203247,344.827057 542.351929,344.676483 543.793945,344.427612 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M550.978882,346.769348 
	C551.081726,345.450500 550.727112,343.999084 551.761475,343.794342 
	C553.171143,343.515350 552.873962,344.998566 552.984070,345.887939 
	C553.044189,346.373413 553.023682,346.874573 552.988403,347.365021 
	C552.895142,348.662231 552.872742,350.624207 551.288269,350.330170 
	C549.345093,349.969574 551.243164,348.236755 550.978882,346.769348 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M518.380249,340.646545 
	C519.157959,342.646179 518.859070,343.892487 516.724487,343.834656 
	C515.807312,343.809845 515.181458,343.179230 515.170715,342.262085 
	C515.146057,340.136749 516.389038,339.837494 518.380249,340.646545 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M439.322205,385.760925 
	C439.671021,388.750854 437.353973,390.660919 435.451080,393.011047 
	C435.417572,390.130524 437.203491,388.012787 439.322205,385.760925 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M638.681274,735.886719 
	C636.650330,737.344543 634.400085,737.533325 632.078247,735.653320 
	C634.130981,734.885010 636.301880,734.689819 638.681274,735.886719 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M445.429688,377.804596 
	C446.139252,380.020081 444.339325,380.918732 442.588623,382.111755 
	C442.255859,380.099243 443.896179,379.144196 445.429688,377.804596 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M549.930298,703.489258 
	C548.235229,703.967529 546.790771,703.539978 546.019165,701.389404 
	C547.579041,701.166260 549.027527,701.606750 549.930298,703.489258 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M447.590515,384.655884 
	C447.355042,385.354187 447.094757,385.744415 446.829865,386.373627 
	C446.390320,386.999817 445.955353,387.387054 445.240662,387.857971 
	C444.765289,386.283447 445.531189,385.059631 447.016846,383.908508 
	C447.392578,383.973755 447.479126,384.160767 447.590515,384.655884 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M579.928589,719.468994 
	C578.846069,719.942261 577.790161,719.809692 577.000610,718.364746 
	C578.006592,718.038147 579.218201,717.795166 579.928589,719.468994 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M464.475372,374.014832 
	C464.003052,374.859375 463.415497,374.961151 462.839935,374.563324 
	C462.374786,374.241821 462.380493,373.730133 462.857269,373.422424 
	C463.442688,373.044556 464.028687,373.152374 464.475372,374.014832 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M441.842407,382.273560 
	C442.217163,383.426849 441.792786,384.366516 440.429688,385.024048 
	C440.103577,383.894775 440.665894,383.024139 441.842407,382.273560 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M432.360291,397.767578 
	C433.074951,398.927063 432.525513,399.896606 431.341187,400.885925 
	C430.877655,399.807922 431.204956,398.762634 432.360291,397.767578 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M451.627808,377.046631 
	C451.683411,377.743530 451.426575,378.518463 451.076416,379.590393 
	C450.804626,379.911713 450.626099,379.935974 450.182068,379.909912 
	C449.821014,378.660675 450.472870,377.843964 451.627808,377.046631 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M551.430786,355.639771 
	C551.640686,356.388184 551.378845,356.659393 550.797791,356.543732 
	C550.677124,356.519714 550.514954,356.348480 550.496643,356.226440 
	C550.408752,355.639343 550.694641,355.393829 551.430786,355.639771 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M434.426331,393.804504 
	C435.132751,394.643280 434.772827,395.376831 433.712524,396.080841 
	C433.119446,395.243134 433.636780,394.587891 434.426331,393.804504 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M564.929565,712.374268 
	C564.103088,712.829407 563.432800,712.485779 562.846680,711.422302 
	C563.604797,711.057983 564.417297,711.133179 564.929565,712.374268 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M568.930664,714.442322 
	C568.087219,714.848816 567.408630,714.502869 566.862427,713.392517 
	C567.639648,713.056091 568.463013,713.158447 568.930664,714.442322 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M433.769531,378.402954 
	C434.403687,377.659424 434.884308,377.333221 435.664703,377.003662 
	C436.025330,377.931458 435.547150,378.591583 434.586914,379.276245 
	C434.067963,379.204041 433.842010,379.012146 433.769531,378.402954 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M456.829163,372.644592 
	C456.674988,372.136414 456.760437,371.956055 457.048187,371.731995 
	C457.618469,372.087616 458.505920,372.673401 458.419373,372.927734 
	C458.117065,373.816010 457.491486,373.447357 456.829163,372.644592 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M433.697510,377.993988 
	C433.974609,378.223022 434.026123,378.460449 434.185791,379.046875 
	C434.103485,379.689911 433.912994,379.983948 433.472198,380.373932 
	C433.158630,379.926758 433.095367,379.383636 433.026489,378.431213 
	C433.020844,378.021973 433.471924,378.002350 433.697510,377.993988 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M553.811768,706.011658 
	C553.411194,706.028687 552.901367,705.752747 552.229004,705.233765 
	C552.611816,705.233398 553.157104,705.476013 553.811768,706.011658 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M464.324219,353.071869 
	C464.264740,352.837799 464.473785,352.561371 464.946899,352.234192 
	C465.004913,352.465485 464.798859,352.747498 464.324219,353.071869 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M575.801880,717.679443 
	C575.631226,717.783508 575.284180,717.669434 574.748657,717.342651 
	C574.915283,717.240356 575.270447,717.350769 575.801880,717.679443 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M432.374451,382.119324 
	C432.245056,381.881805 432.382904,381.567047 432.763672,381.144104 
	C432.884979,381.371277 432.763367,381.706665 432.374451,382.119324 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M559.826172,709.633057 
	C559.616699,709.843140 559.325623,709.721008 558.955200,709.257812 
	C559.152588,709.081177 559.442078,709.209290 559.826172,709.633057 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M548.536560,343.477112 
	C548.559875,343.520386 548.513245,343.433868 548.536560,343.477112 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M591.814941,724.548828 
	C591.626831,724.765503 591.343628,724.667969 590.976685,724.235474 
	C591.155518,724.065491 591.431213,724.176819 591.814941,724.548828 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M440.319519,431.778778 
	C439.736084,432.750214 438.306824,433.672638 438.432434,434.001892 
	C440.817108,440.252991 433.883545,438.870514 432.667694,442.084381 
	C431.707764,431.755890 432.778076,430.379547 440.319519,431.778778 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M447.399231,446.350220 
	C446.061310,447.608887 445.360046,449.208618 443.557831,448.947632 
	C441.747925,448.685547 439.080750,450.149933 438.374725,447.569641 
	C437.825806,445.563538 439.906311,444.584503 441.697113,443.896423 
	C442.432007,443.614075 443.210571,443.124481 443.669342,442.506531 
	C444.875214,440.882355 446.645264,440.409332 448.002167,441.453156 
	C449.701660,442.760559 449.349457,444.641266 447.399231,446.350220 
z"/>
<path fill="#151515" opacity="1.000000" stroke="none" 
	d="
M438.875031,401.168762 
	C444.468414,402.090698 444.468414,402.090698 441.371155,409.323792 
	C439.611633,409.476990 439.859833,406.821716 437.739380,406.844604 
	C437.730164,405.368591 438.108582,404.004669 438.492920,402.283752 
	C438.540863,401.730225 438.582916,401.533691 438.875031,401.168762 
z"/>
<path fill="#969696" opacity="1.000000" stroke="none" 
	d="
M440.252136,396.910126 
	C440.321228,397.560089 440.080322,398.304779 439.564392,399.188873 
	C438.952881,398.398163 439.249420,397.645874 440.252136,396.910126 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M623.746704,557.231079 
	C627.020264,555.307312 630.075317,553.668518 633.466492,551.969788 
	C634.041992,552.132629 634.520996,552.405945 634.486206,552.570801 
	C633.226257,558.544312 636.066101,562.665222 640.171692,566.578430 
	C641.519165,567.862854 641.614380,570.482422 642.231873,572.506714 
	C643.237366,575.803528 644.383606,578.743347 647.012268,581.457458 
	C649.791870,584.327332 646.047302,587.400696 645.037720,590.746338 
	C640.320251,593.242554 639.597839,592.740356 638.956421,587.597595 
	C638.636353,585.031311 640.466248,581.732849 636.371216,580.080811 
	C634.843628,577.428467 636.039246,574.822510 636.074951,572.238342 
	C636.110962,569.624634 635.386353,568.811646 632.918274,567.702942 
	C628.572205,565.750549 623.652588,563.646179 623.746704,557.231079 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M625.609131,481.971436 
	C627.743591,481.867188 629.240601,482.401001 630.773438,483.723724 
	C630.433289,484.643311 629.726746,485.049438 628.979980,485.420380 
	C628.268372,485.773895 627.306763,486.038483 627.485657,487.095642 
	C627.652039,488.079498 628.600342,488.166718 629.384888,488.369354 
	C633.028137,489.310425 636.810120,489.562714 640.720337,490.789276 
	C641.005310,490.984650 641.068481,491.440338 641.097229,491.667786 
	C640.151184,493.066132 638.795166,493.085052 637.474854,492.983612 
	C631.070740,492.491638 624.628967,494.110931 618.277161,492.809509 
	C616.053955,492.354004 612.942688,491.736450 615.629883,487.966125 
	C616.739319,486.409515 615.725342,484.458130 614.917297,482.780670 
	C614.431885,481.773010 613.580688,480.632172 614.688477,479.614105 
	C615.768433,478.621613 617.075378,479.281525 617.805725,480.067230 
	C619.911255,482.332458 622.545532,482.040009 625.609131,481.971436 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M608.068909,474.362854 
	C610.341797,473.720886 612.445374,474.547699 614.793762,475.702209 
	C612.297058,478.063202 610.215637,476.417816 608.068909,474.362854 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M667.755371,561.215088 
	C668.923584,561.527466 670.457947,561.778687 670.372009,562.940125 
	C670.261780,564.430969 668.691162,563.829163 667.351562,563.998779 
	C667.169312,563.141785 667.336182,562.286011 667.755371,561.215088 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M644.471863,492.228973 
	C644.733521,492.281769 644.850525,492.625122 644.855347,492.806274 
	C644.422913,492.985596 643.985596,492.983765 643.217346,492.964355 
	C643.327698,492.689911 643.768982,492.433044 644.471863,492.228973 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M623.553955,556.954712 
	C624.596130,562.148499 629.137207,564.166016 632.646667,566.479858 
	C637.607178,569.750366 639.297668,574.179932 636.055664,579.671265 
	C630.868347,581.453369 632.417480,575.363647 629.533142,574.043884 
	C629.148499,573.867798 631.737183,573.354187 632.637817,572.495361 
	C633.333618,571.831787 634.350159,571.062866 634.413086,570.275879 
	C634.520447,568.934753 633.123474,569.079468 632.177979,569.017944 
	C630.092957,568.882202 628.302246,567.884766 626.685425,566.789062 
	C619.061523,561.622314 611.577454,563.799438 604.092834,567.010010 
	C601.236023,568.235474 600.751099,571.772705 598.103333,573.636108 
	C596.879272,573.731262 594.448242,574.342285 594.859619,573.008789 
	C595.527710,570.842529 595.781616,568.190247 597.985779,566.624878 
	C601.274292,564.289368 604.503479,561.870544 607.943054,559.172668 
	C613.238770,559.064575 618.164124,557.802551 623.553955,556.954712 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M600.444580,577.022095 
	C600.009033,576.261719 600.005798,575.501404 600.006165,574.360840 
	C600.334045,573.979614 600.670288,573.921936 600.980713,573.987183 
	C602.889587,574.388062 606.394409,572.927979 606.387756,575.316101 
	C606.379272,578.347656 602.812561,576.459656 600.444580,577.022095 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M602.343018,594.969849 
	C600.830261,594.754211 599.950745,594.227234 600.790833,593.023376 
	C601.648071,591.794983 603.090088,591.896790 604.409302,592.125305 
	C605.300476,592.279663 605.969910,592.838196 605.645325,593.830139 
	C605.163452,595.302673 603.805481,594.866760 602.343018,594.969849 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M598.983521,456.586945 
	C597.266785,456.998047 595.557922,457.011597 593.420898,457.019531 
	C594.853638,452.947968 588.284241,452.751892 589.337341,449.882935 
	C590.692017,446.192230 589.425232,444.120544 587.021606,441.763733 
	C585.410828,440.184296 584.075256,438.210632 583.992432,435.358673 
	C583.978088,434.981567 583.995300,434.993866 583.991455,434.986694 
	C586.518250,433.769012 588.627136,431.705505 591.919800,431.319397 
	C593.498230,431.890350 594.147949,432.897888 594.230469,434.071869 
	C594.676758,440.419708 597.880615,446.036346 598.974792,452.608215 
	C598.993958,454.067200 598.992737,455.128296 598.983521,456.586945 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M580.074341,436.298218 
	C580.367004,435.919403 580.606140,435.727203 580.951538,435.309692 
	C581.092041,435.126862 581.529541,435.085876 581.748657,435.093170 
	C581.887695,435.958374 581.577881,436.648590 580.358948,436.940308 
	C580.059509,436.987061 580.054932,436.527283 580.074341,436.298218 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M594.773438,426.254822 
	C595.725708,425.646423 596.481934,425.346466 597.503296,424.888184 
	C600.900146,424.576447 602.948303,425.290833 602.716797,429.207367 
	C602.599365,431.193237 603.954346,432.487061 606.395874,433.112762 
	C612.681885,434.723724 613.548706,438.714325 609.117676,443.435394 
	C606.870850,445.829285 604.828247,448.414886 602.357483,450.979248 
	C597.501160,448.053314 596.766174,443.012207 596.084351,438.425140 
	C595.502808,434.511963 596.392273,430.374023 594.773438,426.254822 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M594.664673,426.064575 
	C593.924927,429.955780 600.056091,428.159393 599.397522,431.095367 
	C598.915771,433.243011 596.488708,434.726807 597.045715,437.597717 
	C597.928223,442.145844 600.437134,446.082672 601.758911,450.790405 
	C601.872498,452.444275 601.023560,453.004669 599.343750,452.996460 
	C595.129333,448.166504 594.297485,442.224274 593.018250,436.453583 
	C592.737366,435.186523 592.694336,433.866730 592.264771,432.285950 
	C591.419067,431.313995 591.238708,430.549500 591.674072,429.303955 
	C591.985596,427.371368 593.240967,426.840363 594.664673,426.064575 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M609.139404,417.701111 
	C605.567322,418.032135 602.832947,420.001984 599.409424,421.065002 
	C599.401978,418.094208 597.490967,418.741333 595.450806,419.062500 
	C595.179993,418.733032 595.243103,418.401184 595.340454,417.816010 
	C595.374573,417.562683 595.377319,417.494781 595.363892,417.463562 
	C597.355042,415.642426 599.662964,415.130981 602.539429,415.921204 
	C603.179871,416.241425 603.273743,416.830811 603.710205,416.792236 
	C604.035095,416.763489 603.972961,416.448425 603.546448,415.942627 
	C604.609558,413.318024 607.027039,414.067291 609.135864,413.297485 
	C609.476562,413.262726 609.802795,413.302124 609.912354,413.179749 
	C610.681641,412.253967 611.575378,411.851959 612.924072,411.560608 
	C614.752014,411.948792 615.172180,413.135254 615.469849,414.800110 
	C614.846191,416.993439 613.083557,416.747925 611.360962,417.183044 
	C610.442627,417.248688 609.895142,417.378632 609.139404,417.701111 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M632.613708,409.966919 
	C634.088623,410.023102 635.138062,410.018250 636.580994,410.009186 
	C637.356201,410.380280 637.538513,410.835602 637.401611,411.733124 
	C636.285767,413.174011 634.967590,412.935883 633.337402,412.955566 
	C631.926941,413.000763 630.895569,413.061462 629.482910,413.191040 
	C628.573059,413.516541 628.022644,413.577850 627.100159,413.200317 
	C626.685913,408.592010 629.707275,409.730835 632.613708,409.966919 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M633.261536,413.210846 
	C633.884033,411.888519 635.179810,412.291290 636.672241,411.995667 
	C638.027710,411.155731 639.104004,410.945587 640.640259,411.711151 
	C641.790222,412.212494 642.578735,412.472961 643.670288,412.847473 
	C640.576294,414.585388 637.064209,413.906281 633.261536,413.210846 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M611.255981,417.337677 
	C612.183228,416.572296 613.376343,416.025909 614.794189,415.244019 
	C615.238892,414.664093 615.523560,414.387970 616.159546,414.073303 
	C617.004028,413.771912 617.588623,413.651154 618.451782,413.417847 
	C619.133850,413.269043 619.530518,413.242004 620.220459,413.341003 
	C620.674438,413.527130 620.838379,413.640045 620.987854,414.051270 
	C618.471802,417.331238 614.900513,417.160034 611.255981,417.337677 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M646.077881,413.464752 
	C648.978333,412.604797 651.854065,413.421265 655.075989,414.310242 
	C652.233032,415.630371 649.169495,415.326447 646.077881,413.464752 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M626.614502,412.508514 
	C627.439941,412.819733 627.922546,412.914886 628.753296,413.135010 
	C627.712463,415.834351 625.533386,414.887787 623.230469,414.258301 
	C622.887817,413.742432 622.798828,413.488525 622.943726,412.904327 
	C624.159180,411.933228 625.192749,411.864044 626.614502,412.508514 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M623.040222,412.627441 
	C623.023743,413.025055 622.996521,413.510437 622.986694,413.753357 
	C622.523926,414.036713 622.071045,414.077148 621.295776,414.233582 
	C620.973328,414.349579 621.017578,413.959442 621.035278,413.763916 
	C620.531860,411.342316 621.489197,411.329437 623.040222,412.627441 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M558.691406,556.233215 
	C563.315796,556.769958 567.527710,555.998169 571.953857,555.403259 
	C577.302795,554.684326 583.099670,556.249451 588.528992,557.550598 
	C592.042603,558.392517 592.456177,561.570862 590.050171,564.445129 
	C589.517273,565.081726 588.557312,565.644714 588.470520,566.327576 
	C587.425171,574.559265 581.223999,572.931580 575.909302,572.995117 
	C573.580139,573.022949 571.247437,573.069336 568.921448,572.978149 
	C566.066467,572.866150 561.935120,574.027039 566.230957,568.660583 
	C568.461426,565.874268 564.248901,565.234680 562.462463,564.434021 
	C560.606628,563.602112 558.055664,564.139648 557.088623,561.125366 
	C556.900269,559.255981 556.820862,557.546387 558.691406,556.233215 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M547.541748,569.111450 
	C547.158325,568.100037 547.093689,566.985229 547.036560,565.455200 
	C551.539917,563.160034 551.789124,557.738770 555.710938,555.045654 
	C554.731262,560.496704 551.844177,565.074036 547.541748,569.111450 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M553.616882,566.131165 
	C552.932678,567.224976 557.552002,569.134216 553.060913,570.020264 
	C550.135559,568.319214 552.693176,567.460266 553.616882,566.131165 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M556.530823,544.979614 
	C556.950989,546.072327 557.184204,547.344971 555.197388,547.852905 
	C554.782654,546.687317 555.346680,545.817688 556.530823,544.979614 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M640.854126,490.693359 
	C637.191406,491.357971 633.487793,490.495850 629.741638,490.040527 
	C627.527466,489.771423 624.849060,490.561249 625.012573,486.805145 
	C625.180664,482.943665 628.078918,484.307007 630.550293,483.999146 
	C633.455139,487.295258 637.626953,488.016632 640.854126,490.693359 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M623.419067,413.047607 
	C622.373474,412.134674 621.738220,411.991028 621.090088,413.315674 
	C620.607971,413.705505 620.162964,413.842590 619.367432,413.993774 
	C618.457214,414.007751 617.919983,414.103424 617.181396,414.510681 
	C616.517700,414.760986 616.098022,414.881531 615.348633,415.005310 
	C614.534119,414.142181 614.049316,413.275848 613.332031,412.211487 
	C612.894409,411.863922 612.689331,411.714386 612.754395,411.296509 
	C613.024536,411.028198 613.000000,411.000000 613.013916,411.012390 
	C613.657654,410.314758 614.453735,409.891876 615.688843,409.596436 
	C617.448730,409.577057 618.106079,408.139862 619.639221,407.870056 
	C624.602112,406.798737 619.501038,404.354584 620.828613,402.461182 
	C623.312622,402.644592 623.648376,406.016022 626.330505,406.375702 
	C628.570740,406.676178 631.341980,406.804993 632.863953,409.711914 
	C631.551392,412.032776 628.635803,409.941742 627.073730,412.395752 
	C625.909729,412.839752 624.862061,412.954956 623.419067,413.047607 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M625.003235,394.411560 
	C627.557251,393.265259 630.138306,392.697723 632.823669,393.048340 
	C635.091187,393.344360 635.995605,394.921448 635.705994,396.909790 
	C635.406433,398.966309 633.718872,398.982239 632.249939,398.211609 
	C629.882812,396.969788 625.319092,400.388824 625.003235,394.411560 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M625.000244,393.568115 
	C622.904602,393.639191 620.806458,393.293427 618.714722,392.912537 
	C617.174866,392.632141 616.422852,391.495941 616.433289,390.111176 
	C616.446167,388.402039 617.885864,388.196655 619.250366,388.016968 
	C623.165588,387.501343 625.833679,388.339691 625.000244,393.568115 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M619.910034,407.610901 
	C618.790649,408.668762 619.112854,410.952179 616.689453,410.604797 
	C615.508606,409.558502 615.391357,408.223724 614.542358,406.947327 
	C614.490356,406.641968 614.493469,406.555328 614.478149,406.514099 
	C613.378052,404.139954 610.659851,403.585571 609.074219,401.346100 
	C613.741028,401.083008 616.846924,404.104370 619.910034,407.610901 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M640.998657,411.627380 
	C639.939026,411.970184 638.876282,411.988342 637.418762,411.956604 
	C637.012817,411.484467 637.001648,411.062195 636.982483,410.322449 
	C638.053528,410.003143 639.132507,410.001312 640.616333,410.001404 
	C641.012573,410.436462 641.004028,410.869598 640.998657,411.627380 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M661.152344,636.187012 
	C664.986145,635.729187 669.076660,636.549866 671.833496,632.622192 
	C672.658325,631.447021 673.898193,631.905762 674.968079,632.455017 
	C676.585815,633.285706 678.167053,634.223511 680.493042,633.776489 
	C681.168335,636.881775 679.579529,637.848267 677.084290,636.281982 
	C674.692200,634.780518 673.239807,635.396545 670.960632,636.692688 
	C667.991760,638.380859 664.391418,639.181091 661.152344,636.187012 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M666.645874,629.007568 
	C667.554199,631.067871 666.519470,631.905029 664.541077,632.050964 
	C662.644775,632.190674 660.957458,632.626953 661.183167,635.493408 
	C657.500061,635.169312 658.604065,631.794922 658.748474,629.975342 
	C658.894958,628.130127 661.158875,630.056946 662.471313,629.881226 
	C663.748840,629.710205 665.000671,629.347107 666.645874,629.007568 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M633.191711,777.390015 
	C630.592957,777.794495 628.219116,777.954956 625.402405,778.026001 
	C625.966187,772.651062 629.720581,775.594177 632.738525,775.699341 
	C633.363098,776.291443 633.489685,776.686829 633.191711,777.390015 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M652.195679,774.463989 
	C648.603699,775.252258 645.242615,775.788269 641.485901,776.307617 
	C643.590210,772.317566 647.966125,773.537720 651.781128,772.751953 
	C652.361694,773.328857 652.494141,773.737854 652.195679,774.463989 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M619.774841,777.847900 
	C616.617493,779.686890 613.088013,778.937195 609.275513,778.348755 
	C612.391663,776.144043 615.949768,776.902954 619.774841,777.847900 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M633.246704,777.739136 
	C632.978577,777.263123 632.990295,776.892212 633.019409,776.240723 
	C633.601440,776.175842 634.166077,776.391663 634.889648,776.875122 
	C634.541138,777.376587 634.033752,777.610474 633.246704,777.739136 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M652.279419,774.782959 
	C651.977051,774.332336 651.989258,773.948303 652.015198,773.274536 
	C652.591797,773.043762 653.154724,773.102844 654.124512,773.303101 
	C654.150818,774.278259 653.560974,774.823853 652.279419,774.782959 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M664.177063,770.319824 
	C664.184998,771.294495 663.592224,771.863037 662.301758,771.937866 
	C662.136719,770.836914 662.625793,770.136414 664.177063,770.319824 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M667.080322,769.242554 
	C666.711853,769.821167 666.177490,770.098572 665.331909,770.353882 
	C665.197449,769.249268 665.049927,767.725098 667.080322,769.242554 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M593.047424,356.273529 
	C593.560791,356.165253 594.104553,356.343323 594.825806,356.752869 
	C594.103699,358.012390 593.480835,357.784576 593.047424,356.273529 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M473.399872,346.106506 
	C472.335907,342.600098 468.901337,344.444244 466.954254,343.204681 
	C464.497620,341.640808 464.257294,340.817352 467.633698,340.026215 
	C472.088165,342.072235 476.190643,340.583618 480.449982,340.168579 
	C474.426300,339.232239 472.592133,331.083649 465.205139,333.003357 
	C465.332245,332.503906 465.459320,332.004425 465.586426,331.504944 
	C468.829712,330.824127 472.072968,330.143280 476.463104,329.221710 
	C474.052368,328.035431 472.423828,327.449310 471.047974,326.497772 
	C469.606750,325.501007 467.024963,327.143066 466.440094,324.555115 
	C465.622650,320.937927 464.202087,317.349915 465.188660,313.544189 
	C465.647156,311.775482 467.112335,311.880188 468.427887,312.327301 
	C470.923309,313.175323 471.831268,312.030334 471.992096,309.374878 
	C473.757019,310.729248 477.352386,308.749908 478.373779,312.650330 
	C478.824829,314.372772 480.083923,314.907013 478.839447,317.427185 
	C477.017487,321.116821 479.998901,325.063538 481.571655,328.695923 
	C481.922974,329.507263 483.336761,330.186401 482.576538,331.137543 
	C478.088013,336.753448 483.222992,337.936005 486.908417,340.110840 
	C483.513855,343.879425 478.218109,343.956879 473.399872,346.106506 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M450.610840,338.952271 
	C448.584015,342.345581 448.735748,346.755737 445.668732,350.190765 
	C444.463135,351.541077 443.972717,356.731018 444.665619,360.115509 
	C445.183685,362.645691 444.605042,364.278381 442.336792,365.832916 
	C440.405670,365.613495 437.723480,367.051178 437.413757,364.522064 
	C437.129120,362.197662 440.214935,363.653290 441.472412,362.182281 
	C440.775299,359.831238 437.549927,360.171906 436.516083,358.213379 
	C436.935211,356.609344 438.442444,356.929779 439.339874,356.303680 
	C441.300659,354.935760 441.442169,353.986664 439.078217,352.851471 
	C436.346954,351.539917 434.975098,350.145691 438.467529,347.606171 
	C442.532928,344.649963 441.846985,339.623718 437.417816,336.861298 
	C433.693481,334.538513 432.946106,330.231598 435.716553,327.057037 
	C437.231476,325.321167 436.985504,325.018463 433.115387,323.542053 
	C430.549164,322.563019 427.267670,322.188080 428.850342,317.906891 
	C429.510742,316.120422 430.374695,315.065796 432.344513,314.999512 
	C433.609985,314.957001 435.096100,315.337097 435.906616,313.369568 
	C439.089539,312.875031 441.484802,312.330048 439.336578,317.294037 
	C436.690643,323.408234 437.402496,323.790466 444.343140,325.076874 
	C447.923370,325.740448 451.504700,326.397675 455.085266,327.059418 
	C455.946777,327.218628 456.749664,327.518005 456.689758,328.573700 
	C456.627655,329.668304 455.588257,330.159332 454.946747,329.703522 
	C450.312897,326.410858 446.260864,331.467712 440.864868,329.747498 
	C444.155121,333.769135 444.245697,339.269043 450.610840,338.952271 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M362.857635,449.448547 
	C363.900269,449.048523 364.754395,448.888672 365.299438,449.688263 
	C365.407166,449.846375 364.995117,450.656830 364.711456,450.729065 
	C363.785278,450.964844 362.700500,451.198608 362.857635,449.448547 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M412.720795,524.788025 
	C414.633026,528.612488 416.530273,532.145203 416.970428,536.595215 
	C416.700592,539.888611 414.242584,540.279846 412.261261,540.305359 
	C404.100067,540.410400 396.003693,542.088318 387.842590,541.465820 
	C383.582275,541.140930 379.208862,540.776001 377.151611,535.689453 
	C376.270020,533.509705 373.568756,532.897400 371.273865,532.340759 
	C369.708282,531.960938 368.383514,531.260071 368.348969,529.442627 
	C368.311737,527.486816 369.620972,526.464722 371.300293,525.867737 
	C372.876190,525.307556 374.573975,525.262085 376.175690,524.792542 
	C378.217407,524.194031 379.865021,523.258179 379.985474,520.797913 
	C380.157227,517.290100 382.339661,515.914001 385.658142,515.617554 
	C382.334900,514.957825 379.069824,514.771057 375.789673,514.598267 
	C369.427948,514.263062 366.687927,511.251953 367.533203,504.887207 
	C367.925415,501.933868 367.473907,499.992981 365.296661,497.842133 
	C362.163269,494.746735 362.946777,492.844727 367.241455,491.801727 
	C370.663605,490.970673 374.267548,490.707825 377.650085,490.875000 
	C387.226166,491.348297 396.901306,490.323120 406.705200,492.717896 
	C410.102081,498.740967 409.065979,505.207489 409.770111,511.330200 
	C410.081421,514.037415 406.418121,516.738831 408.037079,520.603027 
	C405.346161,523.786133 401.332764,521.508728 397.548462,523.676514 
	C403.046600,524.483582 407.864868,522.947266 412.720795,524.788025 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M407.021606,492.540649 
	C398.585480,492.631531 390.153381,493.389648 381.667084,492.235474 
	C376.706238,491.560760 371.509064,493.327454 366.340057,493.057648 
	C364.524109,492.962891 363.591522,494.313568 365.167084,495.479370 
	C369.509308,498.692322 369.168945,503.131134 369.006866,507.634186 
	C368.870544,511.420624 370.802612,513.292603 374.501099,512.959839 
	C379.201141,512.536804 383.711823,514.041809 388.359009,514.034973 
	C389.666351,514.033020 391.401093,514.060791 391.331238,515.828979 
	C391.266968,517.456665 389.562286,516.865906 388.508362,516.977173 
	C386.863617,517.150757 385.175140,517.056091 383.567230,517.381470 
	C382.500336,517.597412 380.825073,518.234802 381.310791,519.554688 
	C384.191467,527.382568 377.420013,525.515930 373.997803,526.960083 
	C373.427612,527.200684 372.678284,526.990845 372.016327,527.045166 
	C370.567963,527.163940 369.301392,527.704468 369.270325,529.340759 
	C369.239258,530.978455 370.736053,530.683350 371.788208,531.062683 
	C375.155579,532.276794 380.239929,531.436646 379.637054,537.643860 
	C379.530334,538.742920 381.387238,538.791748 382.536346,539.033875 
	C388.117645,540.209961 393.631958,540.365967 399.293884,539.224792 
	C404.935974,538.087524 410.955322,540.264832 416.755249,537.262085 
	C417.432922,536.991272 417.841675,536.996399 418.554993,537.006470 
	C418.933289,538.683655 419.006989,540.356018 419.084503,542.446533 
	C419.419861,543.380554 419.540527,543.941406 419.242004,544.935730 
	C415.764679,547.411926 412.313812,546.821777 408.958862,545.762573 
	C406.311005,544.926453 403.856934,544.901855 401.186798,545.734131 
	C395.546570,547.492065 389.635529,546.399475 383.491241,546.980957 
	C382.294098,546.650330 381.509857,546.214844 381.360382,545.361023 
	C380.779083,542.041321 378.485718,541.462036 375.606384,541.342163 
	C372.631439,541.218201 370.000336,540.542480 370.634308,536.448975 
	C370.990845,534.146667 368.706299,534.223877 367.041107,533.384277 
	C366.248169,533.109497 365.846832,532.853821 365.244141,532.265503 
	C359.803589,529.502930 359.702240,528.849365 363.794586,524.806702 
	C364.222626,524.383850 364.869080,523.618958 364.735840,523.277405 
	C363.226471,519.408203 364.637054,517.257263 368.195129,515.329651 
	C369.827942,514.445007 368.096680,512.912170 366.664520,513.093506 
	C361.993103,513.685120 361.354645,510.619537 360.469666,507.169464 
	C359.635834,503.918823 360.613068,502.849670 363.746613,502.964783 
	C364.791107,503.003143 366.459778,503.362244 366.399719,501.649567 
	C366.352020,500.288727 364.900787,500.094055 363.697021,500.013672 
	C363.200867,499.980560 362.666840,500.022919 362.218170,499.852112 
	C359.765656,498.918457 356.399567,498.006531 356.415192,495.263885 
	C356.434662,491.838348 359.894806,492.106903 362.587402,491.945801 
	C366.051910,491.738464 367.897186,490.597168 364.253082,487.183868 
	C364.622009,485.212524 364.300781,482.726288 366.706024,482.167389 
	C369.260834,481.573700 369.824707,484.123566 371.069916,485.533051 
	C373.379150,488.147034 375.718231,490.555511 379.364594,487.469238 
	C379.597748,487.271942 380.024933,487.086914 380.280487,487.164398 
	C389.065735,489.828522 396.858948,484.702332 405.622009,484.019165 
	C407.521362,486.581573 406.848083,489.398834 407.021606,492.540649 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M412.859497,524.653992 
	C408.490753,525.294373 403.974304,525.617310 399.455688,525.906677 
	C397.595306,526.025879 395.429993,526.132019 395.287292,523.637939 
	C395.141754,521.093811 397.310272,521.037598 399.177979,521.015930 
	C401.822266,520.985229 404.467163,521.001648 407.576660,520.995300 
	C410.791260,520.429749 412.078430,521.917419 412.859497,524.653992 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M403.339691,479.253662 
	C403.170105,477.626007 402.483459,475.949341 404.681061,475.054840 
	C407.320038,475.431000 407.208984,476.873688 406.261108,478.793396 
	C405.327484,479.644592 404.547180,479.787231 403.339691,479.253662 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M402.879639,479.290466 
	C403.752502,479.010040 404.505676,479.011108 405.635681,479.020508 
	C406.133606,479.588654 406.254608,480.148438 406.211060,480.853210 
	C405.093170,480.195038 402.172150,483.926086 402.879639,479.290466 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M539.181396,779.327942 
	C540.075806,774.503052 541.166870,774.069702 545.816895,776.682617 
	C546.111328,779.980713 547.092102,780.828430 549.729492,779.399780 
	C551.996643,778.171570 553.526489,777.921631 554.941772,780.660522 
	C554.178894,782.291504 552.719482,782.692566 551.197144,783.635376 
	C547.891663,784.887573 545.456421,783.754272 542.860596,781.757690 
	C541.330505,781.178894 540.240906,780.591919 539.181396,779.327942 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M550.759216,783.326782 
	C551.227051,780.935425 552.766235,781.008789 554.629272,780.999634 
	C556.028564,781.472229 556.748474,782.198242 556.973511,783.676636 
	C556.365356,785.435669 555.723328,786.876221 555.040649,788.658386 
	C554.573853,789.369568 554.078552,789.545593 553.123657,789.414856 
	C551.027527,787.796265 549.663208,786.160095 550.759216,783.326782 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M568.780884,784.306091 
	C571.061646,779.805969 573.223083,783.866577 575.929626,783.373108 
	C574.102051,786.240540 571.895752,785.970886 569.400818,786.000122 
	C568.650146,785.602539 568.504883,785.140747 568.780884,784.306091 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M568.684509,784.050964 
	C568.998352,784.442566 568.999146,784.887512 569.000000,785.666260 
	C568.558472,786.540710 567.918762,786.919922 567.461365,786.269775 
	C566.733459,785.234924 567.316223,784.514832 568.684509,784.050964 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M514.719604,682.846863 
	C519.184570,686.751099 519.024719,687.513794 512.958801,690.048096 
	C516.364746,690.927917 518.733032,693.697449 521.816956,689.681152 
	C523.048950,688.076660 525.936584,689.787598 527.546204,691.153564 
	C530.456116,693.622742 533.123718,696.376831 535.901733,699.002258 
	C537.653870,700.658142 536.705750,701.381714 535.004517,702.179077 
	C532.208862,703.489380 530.465515,705.636536 531.093262,708.921814 
	C531.754517,712.381897 535.040527,712.413208 537.400024,712.729004 
	C541.112244,713.225708 544.998962,713.664185 548.639221,711.742737 
	C550.412109,710.807068 553.071106,710.044250 553.889771,712.151306 
	C554.476868,713.662354 552.338989,716.009155 550.547852,716.442810 
	C547.785645,717.111633 544.388550,716.203369 544.128174,720.584961 
	C540.136414,720.204407 538.419861,724.343872 535.297729,725.190002 
	C530.628662,726.455383 528.305603,732.420654 522.561890,731.201965 
	C521.875061,731.056213 521.289429,731.807800 521.337830,732.490662 
	C521.429565,733.785095 522.517334,733.826233 523.528625,733.987854 
	C530.000793,735.022156 536.099854,733.556274 542.172058,731.490112 
	C543.625366,730.995605 546.256470,727.320068 546.686401,732.868042 
	C546.854797,735.040649 549.947571,733.786621 551.764343,733.110962 
	C554.563232,732.069946 559.119812,734.683289 559.595947,738.158020 
	C560.028992,741.317932 556.986694,741.444092 554.822571,742.020935 
	C546.744751,744.173828 539.099609,747.802185 530.756226,749.063049 
	C529.980469,749.180237 529.179871,749.602966 528.544006,750.085632 
	C526.863770,751.360962 526.875366,754.694763 523.567444,754.019653 
	C519.712830,753.233032 516.244690,751.419800 513.817566,748.549988 
	C512.193054,746.629089 510.770691,745.816162 508.451355,746.029541 
	C505.975067,746.257263 504.071320,744.968567 502.020782,743.773315 
	C499.714386,742.429016 497.510712,740.033081 494.098907,741.980835 
	C492.978668,741.789734 492.083374,741.292847 490.999207,740.546875 
	C489.415466,737.896301 487.068970,737.029968 484.232971,736.310669 
	C483.904846,736.197449 483.843414,736.213501 483.815491,736.198364 
	C483.372101,735.114746 482.348663,734.840820 481.168884,734.295776 
	C480.883362,734.193298 480.859192,734.147278 480.835327,734.157593 
	C480.326813,733.096008 479.280212,732.794128 478.025513,732.279297 
	C476.209930,731.951294 475.263214,730.814880 473.950592,729.707520 
	C473.518219,728.089905 472.349182,728.647644 471.259918,728.239441 
	C469.469910,726.869202 467.978485,725.680359 466.229980,724.301819 
	C465.798401,723.794922 465.561432,723.534607 465.006958,723.248840 
	C459.267212,719.387634 453.778107,715.614319 448.970215,710.516602 
	C451.418579,707.843018 453.695251,709.545166 455.465729,711.109985 
	C459.510620,714.684937 463.854858,714.598511 468.512787,712.963196 
	C470.876312,712.133423 473.261292,711.546204 475.793091,711.435059 
	C485.317627,711.017151 493.803589,706.698303 502.903473,704.313354 
	C498.916870,702.532227 494.316010,702.782959 491.239990,699.203003 
	C490.559692,698.411255 489.019409,698.642761 487.869873,698.910156 
	C478.328613,701.129578 468.464508,701.456726 458.954773,703.856384 
	C457.175262,704.305420 455.352936,704.619324 453.528839,704.555420 
	C449.800354,704.424805 448.011169,705.723450 449.097229,709.903625 
	C443.618591,707.473389 439.679810,703.304199 435.683655,698.842163 
	C439.516266,700.315735 443.699615,698.549927 447.706024,699.633118 
	C450.119415,700.285583 452.412689,698.883423 454.686798,697.158325 
	C455.439392,696.613342 455.908508,696.280029 456.812927,695.846924 
	C460.273254,695.077637 463.363403,695.398926 466.841309,695.416077 
	C469.050873,695.567078 470.873566,695.746277 472.778076,694.308105 
	C476.404724,692.988892 479.853760,692.602661 483.605225,691.743225 
	C489.642487,691.489197 495.121277,690.149597 500.667633,689.290344 
	C503.700439,688.820557 506.327148,687.624573 508.230194,685.041748 
	C509.728851,683.007812 511.802917,682.180420 514.719604,682.846863 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M425.082336,689.332581 
	C425.380096,688.783752 426.046295,688.275757 426.147003,688.370239 
	C429.391815,691.415833 433.819519,693.252136 435.598816,698.019653 
	C430.907623,696.799255 428.466156,692.733948 425.082336,689.332581 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M484.075745,736.482422 
	C486.893677,735.907837 489.632568,736.140503 490.836304,739.912354 
	C488.268463,739.779114 486.398346,738.011475 484.075745,736.482422 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M480.998962,734.486633 
	C482.108734,734.039856 483.362579,733.820129 483.803650,735.819031 
	C482.640625,736.203064 481.794861,735.649109 480.998962,734.486633 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M477.889160,732.476440 
	C478.969482,732.053406 480.270203,731.778320 480.810486,733.796387 
	C479.635834,734.197937 478.794556,733.577759 477.889160,732.476440 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M471.135376,728.333557 
	C471.909180,728.002502 472.859375,727.938904 473.646576,729.138550 
	C472.935883,729.187988 472.122620,728.898560 471.135376,728.333557 
z"/>
<path fill="#985F10" opacity="1.000000" stroke="none" 
	d="
M464.885223,723.404846 
	C465.078674,723.314880 465.405365,723.463318 465.852448,723.861938 
	C465.654724,723.955872 465.336578,723.799561 464.885223,723.404846 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M552.689087,788.779541 
	C553.444458,789.000000 553.888916,789.000000 554.666687,789.000000 
	C557.028320,789.150146 557.971069,790.290222 557.993530,792.637756 
	C557.156677,793.557800 557.011658,794.413818 557.001587,795.664551 
	C556.919250,796.627747 557.120789,797.151917 557.791260,797.789917 
	C558.751160,798.001099 559.502319,798.002136 560.629150,798.006470 
	C560.834412,799.410156 559.975342,799.986328 558.636414,799.990967 
	C557.126404,799.996216 556.056091,800.512939 556.016602,802.618530 
	C554.934509,802.998169 553.866516,802.998718 552.398071,802.998413 
	C552.237854,800.666260 551.766541,798.669556 549.217468,797.273804 
	C548.160034,795.494263 549.395325,793.997253 549.018433,792.136719 
	C549.327881,789.861206 550.089844,788.413940 552.689087,788.779541 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M557.972961,797.705566 
	C557.121460,797.840027 555.947693,798.341431 555.581970,797.058716 
	C555.518494,796.836060 556.106384,796.427673 556.695496,796.052490 
	C557.313660,796.470398 557.629761,796.940735 557.972961,797.705566 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M457.240356,696.708862 
	C456.554871,696.993103 456.110931,696.995972 455.334106,696.994568 
	C455.067230,693.286377 455.463318,689.721436 450.209717,688.706909 
	C447.024811,688.091797 447.806213,685.721130 449.723785,683.906189 
	C450.631927,683.046631 451.823151,683.052856 452.998749,682.994568 
	C459.885956,682.653076 459.901855,682.642639 459.988281,675.379456 
	C460.012146,673.372620 459.870728,671.359619 461.636444,669.884949 
	C462.894562,668.834290 464.187439,667.037354 465.073364,670.188293 
	C465.804718,672.789551 467.951874,671.842590 469.633301,671.930054 
	C470.972992,671.999634 471.824127,671.306946 471.926453,669.882324 
	C472.060364,668.017700 471.897400,666.457214 470.202148,665.033508 
	C468.221619,663.370239 463.797241,663.248779 466.261902,658.220154 
	C467.205872,656.294250 462.729401,658.156921 461.573853,655.945190 
	C464.079712,653.672241 466.964722,652.795105 470.413818,652.945618 
	C474.702698,653.132690 479.041382,653.355591 483.673523,651.992676 
	C490.216400,657.948730 496.092468,664.194336 502.545746,669.772278 
	C505.221344,672.084961 501.475220,675.480347 504.393555,676.628601 
	C506.747772,677.554749 509.649353,676.989441 511.606049,679.261658 
	C512.563904,680.374023 513.688538,681.342834 514.919189,682.672729 
	C510.842316,682.997864 509.680511,687.588074 506.547211,688.925598 
	C499.694946,691.850647 492.322632,692.755188 484.444824,692.997070 
	C479.506866,690.574768 474.585297,692.601074 469.939575,691.650635 
	C468.508942,691.357910 466.917389,691.833496 465.525024,691.447083 
	C460.945129,690.176208 458.447876,691.998535 457.240356,696.708862 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M471.874542,639.260010 
	C473.147095,639.622864 474.252258,640.280945 475.680237,640.985352 
	C476.014130,641.746826 476.025146,642.461914 476.036774,643.532288 
	C474.940186,645.309448 473.154602,645.261108 471.349670,645.962952 
	C466.504761,643.338013 469.002197,641.435181 471.874542,639.260010 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M366.763611,532.253906 
	C368.707336,532.586670 370.498047,533.011963 372.092133,533.823547 
	C372.979492,534.275269 374.028595,535.029175 373.259399,536.592712 
	C371.691467,539.779724 371.776184,539.798706 375.516449,540.005188 
	C377.461609,540.112549 379.482605,539.577209 381.290131,540.879395 
	C383.037811,542.138489 382.722015,543.650696 382.033478,545.631287 
	C380.505035,547.801208 378.371643,547.355103 376.450775,547.492676 
	C373.503967,547.703613 372.835541,550.324158 373.904877,551.643616 
	C376.212036,554.490356 376.797485,558.802734 381.122253,560.403320 
	C382.804626,562.021301 384.247864,563.408813 385.517456,564.949036 
	C388.789307,568.918396 388.158691,571.049927 383.337311,572.441162 
	C382.388672,572.714905 381.349396,572.691284 380.211151,573.535706 
	C383.411438,577.838379 385.610809,582.847656 389.983948,586.322388 
	C392.904999,588.643311 393.982117,591.069824 388.944458,593.132324 
	C392.020844,593.441162 394.299194,592.307190 396.621521,592.819763 
	C398.968323,593.337708 400.595001,594.399048 398.318726,596.824829 
	C397.207611,598.009033 397.144501,599.557434 396.423370,600.823547 
	C395.108612,603.131836 395.518707,604.529175 398.137054,605.234558 
	C402.167877,606.320496 402.857971,609.083069 401.575439,612.595215 
	C400.660614,615.100403 401.047546,617.417053 401.707397,619.906616 
	C402.914734,624.461853 401.599762,626.038757 396.934326,626.318848 
	C395.538818,626.402588 393.388885,625.479004 393.010071,627.322815 
	C392.578430,629.423950 395.030334,629.547546 396.416718,630.238281 
	C398.712280,631.381958 401.219879,631.483337 403.738190,631.479309 
	C410.235016,631.468811 416.732056,631.446777 423.228668,631.492310 
	C425.373138,631.507324 427.585236,631.236755 429.778046,632.731384 
	C429.825165,633.854492 429.452240,634.851074 428.566986,634.808228 
	C422.132141,634.496033 415.753326,635.993896 409.304657,635.307251 
	C407.005524,635.062378 404.596344,634.796814 402.362122,635.211975 
	C397.395538,636.134766 392.603638,637.147034 388.223541,633.373352 
	C387.510986,632.759460 385.979401,633.096191 384.420044,632.989441 
	C381.981140,629.178284 383.613647,625.927917 385.767487,622.918396 
	C387.240662,620.859863 386.938690,619.447815 385.213043,617.741272 
	C382.395813,614.955139 382.919708,613.210693 386.729645,612.068604 
	C387.776276,611.754944 389.324493,612.327393 389.655426,610.799683 
	C390.041962,609.015076 388.355560,608.468872 387.224121,608.170593 
	C380.113068,606.296021 380.238342,599.995789 378.777008,594.663330 
	C378.668518,594.267395 378.676697,593.563416 378.914337,593.388733 
	C385.564209,588.502686 378.552216,585.329895 376.561157,583.814209 
	C373.129120,581.201538 372.770966,577.337219 370.590729,574.355530 
	C368.877258,572.012207 372.402008,572.030579 373.765564,571.279358 
	C374.843842,570.685242 376.573639,571.147583 376.856262,569.331543 
	C377.132660,567.555908 376.093781,566.543884 374.654907,565.813293 
	C371.868195,564.398193 369.990875,561.509094 366.687408,560.921631 
	C365.744690,560.753967 365.094055,560.189270 365.605835,559.194458 
	C368.118683,554.309998 363.981262,555.549988 361.401611,554.995483 
	C361.018311,554.993896 361.001495,554.993896 361.003876,554.987183 
	C360.273651,552.201294 361.520691,549.315552 360.779144,546.292969 
	C361.451324,546.026184 361.896545,546.015625 362.675781,546.001221 
	C363.931305,544.776428 365.480774,544.373108 366.849274,543.027039 
	C360.646484,539.698914 364.635162,536.104309 366.763611,532.253906 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M366.666046,531.999634 
	C366.983887,532.983521 367.346710,534.216614 366.889801,534.908142 
	C364.715424,538.199219 362.936035,540.902222 369.135956,541.604614 
	C370.557404,541.765747 369.747437,543.952698 368.448486,544.562683 
	C366.992218,545.246582 365.346283,545.526611 363.396301,545.989868 
	C363.157288,543.239807 363.262573,540.559753 359.186737,540.924072 
	C358.154236,541.016357 357.222290,540.533813 357.273895,539.252747 
	C357.318909,538.135742 358.046753,537.400024 359.072266,537.221497 
	C361.125122,536.864197 362.993561,536.424438 362.990723,533.392944 
	C363.548981,532.828430 364.097961,532.648010 364.824707,532.232544 
	C365.446075,531.998108 365.889679,531.998657 366.666046,531.999634 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M360.584229,546.027039 
	C361.026215,546.368103 360.935059,546.764648 361.084808,547.019897 
	C361.949677,548.494141 361.948914,553.013000 361.020447,554.604370 
	C359.384430,554.832153 357.704346,554.895447 356.158997,554.469604 
	C354.889282,554.119812 352.599121,554.266602 354.273285,551.422363 
	C355.563965,549.229675 352.282349,549.478394 351.327087,547.838074 
	C354.272369,546.778625 357.098297,545.924927 360.584229,546.027039 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M430.465820,633.001221 
	C421.194916,633.000793 412.377838,632.742798 403.587128,633.112366 
	C399.558868,633.281799 396.196381,631.734497 392.639923,630.598511 
	C391.031403,630.084717 389.635956,628.684875 390.336639,626.757690 
	C390.996338,624.943176 392.362885,623.996704 394.635315,624.306030 
	C401.722656,625.270569 401.702911,625.194641 399.387970,618.412109 
	C398.595184,616.089355 399.103149,614.000183 400.355682,611.797119 
	C401.658020,609.506409 401.448395,607.543030 398.046478,606.734375 
	C394.778778,605.957520 389.400238,605.565063 395.286469,600.209839 
	C396.455994,599.145813 393.030151,594.481079 400.099915,595.958130 
	C396.289917,593.332092 394.178986,594.301453 392.003021,594.759155 
	C390.570343,595.060669 389.032867,594.920715 387.544891,594.880859 
	C386.674194,594.857483 385.795105,594.417847 385.613647,593.566040 
	C385.301086,592.098999 386.606262,592.223389 387.504944,591.921143 
	C388.862640,591.464539 390.430542,591.415710 391.605499,589.943726 
	C387.632050,585.925232 382.671295,582.696472 381.112213,576.801819 
	C380.971161,576.268555 380.203766,575.872192 379.679474,575.475281 
	C378.606171,574.662903 376.149261,575.044556 376.676697,572.940002 
	C377.079102,571.334229 379.242371,571.715759 380.575958,571.384033 
	C387.729797,569.604065 387.736511,569.697876 382.683472,564.144897 
	C382.034058,563.431213 381.611755,562.510864 381.043335,561.341919 
	C387.870361,556.540283 396.126404,557.131836 400.591217,562.401184 
	C400.573334,563.067627 401.462189,562.237244 400.841278,562.479980 
	C400.538544,562.598328 400.548187,562.451660 400.475983,562.127930 
	C400.060150,560.263306 401.101746,559.044006 402.614868,558.760803 
	C409.846344,557.407471 417.066559,555.332214 424.784454,557.781860 
	C425.774597,561.215515 429.139771,563.941711 426.739685,567.687988 
	C426.641205,567.841675 426.857574,568.413086 427.062866,568.514099 
	C434.310059,572.077087 433.872467,580.380920 437.809357,586.009460 
	C440.774933,590.249329 441.574768,595.701660 444.700562,599.942383 
	C445.538086,601.078613 446.387451,602.206299 445.069672,603.423584 
	C444.126251,604.295105 443.498444,606.298157 441.516663,604.675659 
	C440.648956,603.965271 439.530212,603.991882 439.389130,605.470032 
	C439.288940,606.519836 440.260529,606.997864 441.067139,606.838806 
	C450.971069,604.885498 454.318939,612.526917 458.214722,618.878296 
	C460.107422,621.963989 460.823578,625.771240 462.094788,629.612000 
	C451.559601,629.312805 441.531097,634.103149 430.465820,633.001221 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M328.361053,400.963959 
	C328.205719,400.162354 328.361603,399.299774 328.770294,398.220215 
	C330.197815,399.126129 330.307434,400.120117 328.361053,400.963959 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M398.661316,474.630280 
	C399.495911,475.949951 401.106171,476.359222 400.682129,477.779236 
	C400.245575,479.241058 398.836121,479.113403 397.755768,478.960754 
	C392.316376,478.192230 386.986481,480.269836 381.523346,479.444214 
	C379.418976,479.126190 377.642181,480.241486 377.082916,482.539948 
	C376.794983,483.723175 376.563171,484.837067 375.024567,484.865295 
	C373.345032,484.896118 372.715668,483.653625 372.311829,482.396881 
	C371.696930,480.483124 370.793427,478.761810 369.759155,477.042908 
	C368.466705,474.894958 368.756866,472.878662 371.753143,473.087402 
	C376.584534,473.424103 381.577454,471.681213 386.286133,474.617096 
	C387.748810,475.529053 390.169708,474.904083 392.595703,474.971252 
	C394.650848,473.542725 396.418915,473.375977 398.661316,474.630280 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M399.195129,474.702881 
	C397.295105,474.965881 395.591034,474.968506 393.463196,474.965424 
	C394.174530,471.979065 398.596924,470.731201 400.380005,472.942963 
	C400.494385,473.084839 399.740814,473.926422 399.195129,474.702881 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M538.616211,779.008484 
	C540.104065,779.307922 541.208130,779.615845 542.656128,779.961914 
	C544.082520,781.588623 544.030518,783.068298 542.288147,784.706665 
	C542.000000,785.000000 541.501221,785.001160 541.251831,785.001831 
	C540.389709,785.732056 539.573486,785.978027 538.329102,785.996033 
	C535.650696,785.386902 533.308899,784.716797 530.418335,784.995911 
	C527.561707,781.317505 527.787537,780.776855 532.614868,779.832153 
	C534.744141,779.672302 536.488342,779.344666 538.616211,779.008484 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M542.375000,785.000000 
	C542.328918,783.578918 542.657837,782.157776 542.993408,780.368347 
	C545.172607,781.558594 547.104492,783.696289 550.607361,783.000000 
	C550.562195,785.151062 552.355835,786.497070 552.954346,788.645386 
	C550.972961,789.149963 550.029236,790.288513 550.006531,792.635498 
	C548.389282,794.388611 546.896301,793.895691 545.221924,792.354065 
	C544.998047,791.246338 544.998596,790.497559 544.999573,789.374390 
	C546.125061,787.959961 546.650757,786.838989 545.237000,785.244141 
	C544.250000,785.000000 543.500000,785.000000 542.375000,785.000000 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M532.985962,779.620850 
	C531.342896,780.990417 528.092163,780.847412 529.964844,784.658142 
	C527.302063,784.996765 524.601074,784.998413 521.450073,785.000000 
	C521.000000,783.611084 521.000000,782.222229 521.000000,780.416626 
	C523.604431,779.089783 527.301331,782.138489 528.980347,777.401672 
	C530.667725,777.138062 532.793579,776.464539 532.985962,779.620850 
z"/>
<path fill="#111110" opacity="1.000000" stroke="none" 
	d="
M544.998779,791.997559 
	C546.419373,792.325195 547.841248,792.655273 549.631531,792.991455 
	C551.780640,794.572388 549.733032,795.342651 549.137573,796.723511 
	C547.799011,796.506653 546.597961,796.013306 545.197632,795.260803 
	C544.420288,794.315063 544.267639,793.542175 544.762634,792.341919 
	C544.996704,791.998352 545.000000,792.000000 544.998779,791.997559 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M541.372803,804.998779 
	C541.000000,803.933289 541.000000,802.866638 541.000000,801.399963 
	C542.200745,801.494385 543.401428,801.988831 544.801086,802.742798 
	C544.771362,805.064087 543.234192,804.991272 541.372803,804.998779 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M545.000000,785.399963 
	C546.173096,785.087036 547.333496,785.422852 547.586304,786.673767 
	C547.858398,788.019714 546.836243,788.616577 545.332764,788.958984 
	C545.000000,787.933289 545.000000,786.866638 545.000000,785.399963 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M544.622192,791.998352 
	C544.997681,792.749146 544.998657,793.500000 544.999023,794.626221 
	C544.248779,795.001282 543.499207,795.000916 542.374817,795.000305 
	C541.424316,794.313171 541.282654,793.539001 541.767090,792.342896 
	C542.749207,791.999451 543.498413,791.998901 544.622192,791.998352 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M541.665283,792.041809 
	C542.000000,792.750000 542.000000,793.500000 542.000000,794.625000 
	C541.081482,794.863281 539.786499,795.307434 539.515442,794.005188 
	C539.259155,792.774353 540.357117,792.387451 541.665283,792.041809 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M538.208191,786.243530 
	C538.556763,785.156616 539.413513,785.011658 540.666504,785.005005 
	C540.763916,786.584778 540.060974,787.356201 538.208191,786.243530 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M550.725830,808.142944 
	C551.000000,808.750000 551.000000,809.500000 551.000000,810.625000 
	C548.596619,810.543884 549.024536,809.515808 550.725830,808.142944 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M547.856323,556.162231 
	C548.118225,558.587769 546.600830,560.001221 544.463684,561.310669 
	C544.155823,558.952271 544.005737,556.390259 547.856323,556.162231 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M543.323975,561.320923 
	C543.410706,563.100037 542.252991,564.213501 540.448486,565.201904 
	C540.000854,563.243347 541.087769,562.013916 543.323975,561.320923 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M541.642944,406.729187 
	C543.740723,407.737946 544.870667,408.689240 545.117371,410.905914 
	C545.450562,413.898132 542.695984,417.940918 543.163696,418.314636 
	C546.221619,420.757751 544.816345,423.269409 544.252869,425.906311 
	C543.582764,429.042023 544.243896,432.094940 545.012146,435.537750 
	C542.003418,435.883423 537.807922,431.504395 538.500793,428.414398 
	C539.094604,425.766327 538.702576,422.878876 537.659546,421.298615 
	C534.443420,416.426025 535.455994,412.202759 537.991333,407.379944 
	C538.957825,406.007050 540.071594,405.963379 541.642944,406.729187 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M531.752380,386.993591 
	C533.261414,386.824341 534.954651,387.095337 535.424988,386.460419 
	C537.811462,383.238739 540.471313,384.384369 543.311401,385.573090 
	C543.301514,387.680939 541.132385,387.056580 540.182861,387.977783 
	C538.890625,389.231415 538.731567,390.555084 540.529114,390.865906 
	C546.490845,391.896790 544.809570,396.395630 544.745239,400.836426 
	C542.852966,402.506927 541.254211,402.504852 539.343323,401.254608 
	C535.748108,400.784637 536.982666,397.414642 535.670532,395.721436 
	C533.841064,393.360596 533.751343,390.075958 529.721619,389.146637 
	C528.249390,388.807129 529.987976,387.233124 531.752380,386.993591 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M542.098389,406.619446 
	C540.940674,406.987854 539.870361,406.993500 538.398804,407.001526 
	C537.997559,407.003876 537.503662,406.996094 537.256714,406.992371 
	C536.422791,406.310120 536.265808,405.538055 536.757690,404.347778 
	C537.451172,404.013336 537.894348,404.010345 538.670044,404.005493 
	C540.391724,402.193054 542.808533,402.616638 544.751099,401.237152 
	C544.997559,401.001221 545.011902,400.988342 545.020996,400.978577 
	C546.687256,401.191193 549.320374,400.381836 549.350830,402.815674 
	C549.377686,404.961823 546.861267,404.873627 545.093994,404.996307 
	C543.969116,405.074432 542.754456,404.909912 542.098389,406.619446 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M546.716736,446.053680 
	C547.820923,446.616180 548.593994,447.238434 549.668457,447.905914 
	C550.635864,448.392578 551.252197,448.893524 551.919067,449.752686 
	C552.033447,450.464386 552.026672,450.902527 552.011108,451.669098 
	C545.191162,450.183533 545.191162,450.183533 546.716736,446.053680 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M536.744690,403.750488 
	C537.007263,404.759430 537.006653,405.502533 537.007935,406.617157 
	C536.687195,406.990417 536.361267,407.023529 536.042664,406.988708 
	C534.414062,406.810852 531.860046,407.545624 532.247314,404.747650 
	C532.600220,402.199036 534.764587,402.961609 536.744690,403.750488 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M544.570007,401.000610 
	C544.347412,403.902618 542.013306,403.871307 539.387207,404.002686 
	C539.004333,403.251007 539.006226,402.498322 539.011353,401.369171 
	C540.723938,400.995117 542.433167,400.997559 544.570007,401.000610 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M545.680725,460.100708 
	C546.018738,460.765717 545.997864,461.490814 545.945679,462.577576 
	C545.457886,463.226349 545.001404,463.513458 544.302612,463.917023 
	C544.168091,462.640747 543.463196,460.983246 545.680725,460.100708 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M545.067322,439.191925 
	C545.365295,439.101105 545.599182,439.294037 545.893616,439.746765 
	C545.162292,440.708435 545.138489,440.006226 545.067322,439.191925 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M551.763062,453.131104 
	C551.888428,453.325714 551.726562,453.582458 551.283081,453.906433 
	C551.150269,453.709808 551.308350,453.449646 551.763062,453.131104 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M594.685669,419.130493 
	C593.669312,422.417358 591.514038,423.704041 588.060669,421.401031 
	C590.357239,421.267700 592.597778,421.076324 594.685669,419.130493 
z"/>
<path fill="#DCAB10" opacity="1.000000" stroke="none" 
	d="
M588.548340,418.470520 
	C588.578125,418.520660 588.518555,418.420380 588.548340,418.470520 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M609.005066,413.184692 
	C608.038879,415.009094 605.583496,414.547455 603.859619,416.147095 
	C603.934753,416.716888 604.077820,416.959930 603.849243,417.000793 
	C600.813049,414.648438 598.523987,417.205414 595.698120,417.412018 
	C598.766907,413.010254 603.549927,412.752777 609.005066,413.184692 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M614.325562,407.002197 
	C616.183838,407.084412 617.541687,407.750336 616.500854,410.208557 
	C615.461060,410.695831 614.583374,410.879822 613.366760,411.044312 
	C613.405457,409.803986 613.783142,408.583191 614.325562,407.002197 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M612.667847,411.002777 
	C613.024536,411.028198 613.084229,411.519165 613.091919,411.766327 
	C612.293884,412.359253 611.488281,412.704987 610.352295,413.054016 
	C610.057251,411.562988 610.944519,411.006317 612.667847,411.002777 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M592.843689,424.058838 
	C592.313965,424.909973 591.416016,425.735138 590.215393,426.598694 
	C589.953857,424.968811 590.612244,423.907990 592.843689,424.058838 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M588.739441,427.030334 
	C588.276978,427.691650 587.454468,428.331055 586.311768,428.989807 
	C586.103760,427.526123 587.009277,426.990021 588.739441,427.030334 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M591.515869,429.074554 
	C591.879272,429.687256 591.935425,430.458038 591.989685,431.614746 
	C589.543030,432.889679 588.037659,436.038757 584.388184,434.986206 
	C584.236328,434.490601 584.485046,434.001648 584.815247,433.251343 
	C584.957764,432.594543 585.018799,432.199097 585.151367,431.514771 
	C587.218140,430.561462 589.213379,429.897064 591.515869,429.074554 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M552.247498,449.852448 
	C551.130676,449.904297 550.332886,449.629425 550.028687,448.274963 
	C551.566040,447.422394 552.789124,447.430328 552.247498,449.852448 
z"/>
<path fill="#E2B410" opacity="1.000000" stroke="none" 
	d="
M550.022034,444.256714 
	C550.020081,444.019928 550.470154,444.018341 550.692566,444.049133 
	C550.762512,444.470642 550.610107,444.861328 550.457642,445.252014 
	C550.313110,444.999176 550.168518,444.746338 550.022034,444.256714 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M577.662537,579.287231 
	C575.990295,579.894043 574.715210,580.219360 573.436157,579.514526 
	C572.663513,579.088806 572.188660,578.406616 572.276550,577.505066 
	C572.372192,576.524780 573.106506,576.127563 573.986755,576.076538 
	C578.405701,575.820190 582.868896,576.349304 587.240967,575.140808 
	C588.205017,574.874268 589.298889,575.566223 589.501953,576.641785 
	C589.774780,578.087158 588.698853,578.793579 587.462708,578.866272 
	C584.338379,579.049683 581.204529,579.072754 577.662537,579.287231 
z"/>
<path fill="#B47712" opacity="1.000000" stroke="none" 
	d="
M587.221680,727.243042 
	C588.188049,727.303528 588.712463,727.642822 588.308411,728.369751 
	C588.163147,728.631042 587.439453,728.807495 587.110535,728.681335 
	C586.392151,728.406067 586.326172,727.850037 587.221680,727.243042 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M451.092712,356.606964 
	C449.939636,355.751465 450.470428,355.098419 451.182404,354.650085 
	C451.468658,354.469879 452.318481,354.588074 452.404816,354.788666 
	C452.811401,355.733398 452.169312,356.251770 451.092712,356.606964 
z"/>
<path fill="#D6A114" opacity="1.000000" stroke="none" 
	d="
M425.058655,557.686890 
	C417.950775,557.815125 410.933563,558.745911 403.936981,559.853333 
	C401.594666,560.223999 399.354065,560.827942 402.864563,563.162109 
	C403.674774,563.700806 404.048462,564.393921 403.513611,565.207153 
	C402.973480,566.028320 401.724487,566.120117 401.378021,565.451599 
	C396.593414,556.221863 388.793854,560.510071 381.424744,560.994568 
	C378.151245,561.191589 375.562378,561.132019 375.891174,557.066956 
	C375.944031,556.413757 375.234680,555.142090 374.770752,555.088989 
	C369.454803,554.479675 372.643433,550.175354 371.648499,547.752014 
	C371.172516,546.592651 372.966156,546.092529 374.142212,546.052307 
	C376.457123,545.973206 378.775787,546.005310 381.541077,545.988403 
	C386.199066,544.993713 390.473907,544.908020 394.772491,544.978455 
	C397.207581,545.018433 399.586792,545.229431 401.855682,543.536255 
	C403.426971,542.363586 406.086731,542.348877 407.575745,543.344116 
	C410.963318,545.608521 414.551727,544.794800 418.551575,544.995605 
	C419.392151,544.651733 419.839600,544.496033 420.662842,544.766235 
	C421.018280,546.071350 421.067230,547.163818 421.110535,548.666443 
	C420.691223,549.110779 420.554260,549.303589 420.999115,549.761353 
	C421.558014,550.298950 421.871490,550.764465 422.235840,551.513184 
	C421.244385,553.875305 419.180023,552.776489 417.641479,553.193115 
	C420.130402,553.353821 422.615753,553.446655 424.703400,555.741821 
	C424.978821,556.434998 425.016144,556.889709 425.058655,557.686890 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M424.926361,555.656372 
	C421.946808,555.588928 419.168762,553.740784 415.946838,554.769653 
	C414.955322,555.086365 413.812256,554.636353 413.644714,553.538940 
	C413.360352,551.676086 414.975250,552.101990 415.975037,552.037354 
	C417.759979,551.921997 419.554901,551.961731 421.816223,551.866455 
	C425.151428,551.498169 424.028412,554.159668 424.926361,555.656372 
z"/>
<path fill="#CF9611" opacity="1.000000" stroke="none" 
	d="
M421.200500,549.645874 
	C420.248047,549.793152 418.670563,550.508423 418.636566,548.922607 
	C418.614716,547.902710 419.841797,548.588074 420.815582,548.943970 
	C421.104950,549.076599 421.156525,549.458313 421.200500,549.645874 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M420.934875,544.665527 
	C420.532898,544.983459 420.096466,544.987976 419.332458,544.992615 
	C419.012665,544.520508 419.020447,544.048279 419.058289,543.220459 
	C419.946625,543.050781 420.692902,543.373291 420.934875,544.665527 
z"/>
<path fill="#D6D6D6" opacity="1.000000" stroke="none" 
	d="
M457.755493,368.506470 
	C456.499603,369.018646 456.176300,372.186890 454.215485,369.551910 
	C455.628021,369.578522 456.058380,366.616577 457.755493,368.506470 
z"/>
<path fill="#080808" opacity="1.000000" stroke="none" 
	d="
M603.698059,416.540375 
	C603.969788,416.666626 604.147766,416.859375 604.325806,417.052124 
	C604.134399,417.073273 603.942993,417.094421 603.614624,416.957123 
	C603.477722,416.798676 603.604370,416.606873 603.698059,416.540375 
z"/>
<path fill="#231706" opacity="1.000000" stroke="none" 
	d="
M617.224976,414.682373 
	C617.203430,413.748322 617.524353,412.948578 618.859863,413.799133 
	C618.515442,414.246704 618.014038,414.485504 617.224976,414.682373 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M448.889160,710.100952 
	C449.233826,707.638794 446.812073,706.935852 445.606049,705.204041 
	C447.276733,703.603943 449.376709,703.279358 451.474457,703.019226 
	C463.431732,701.536438 475.306335,699.579529 487.133240,697.262695 
	C489.734344,696.753113 492.586029,695.869568 494.672119,699.849609 
	C495.884033,702.161865 500.371918,701.508179 503.427673,701.991516 
	C504.657501,702.186035 506.431335,701.566406 506.662720,703.423828 
	C506.869843,705.086304 505.159637,705.565186 503.991364,705.946838 
	C500.253754,707.167603 496.352020,707.926392 492.686005,709.317688 
	C483.973511,712.624084 474.577240,712.718933 465.749237,715.316956 
	C461.359406,716.608826 457.968781,715.757812 454.922058,712.491699 
	C453.533661,711.003357 451.766144,710.049255 449.241180,710.135620 
	C448.861908,710.195923 448.962769,710.057922 448.889160,710.100952 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M457.456909,696.994629 
	C457.014587,695.332214 456.950806,693.668762 457.065369,692.017639 
	C457.224854,689.717957 458.640900,688.768982 460.805267,689.183594 
	C466.987305,690.367798 473.203278,690.411194 479.452759,690.024048 
	C481.294159,689.909973 483.203674,689.907227 483.938354,692.617004 
	C480.708252,694.158508 477.325378,694.849915 473.416595,694.999634 
	C471.831329,696.470764 469.868958,695.592102 468.258392,696.747803 
	C464.637512,696.984863 461.276306,696.991943 457.456909,696.994629 
z"/>
<path fill="#C28914" opacity="1.000000" stroke="none" 
	d="
M468.391602,696.984619 
	C468.467255,694.324768 470.586853,695.186890 472.605225,694.999146 
	C472.539703,697.666138 470.416565,696.809448 468.391602,696.984619 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M482.366638,661.103333 
	C483.278015,664.651306 480.264160,663.164490 478.856110,663.846863 
	C478.423462,664.056641 477.366180,663.643127 477.584381,662.814819 
	C478.235718,660.341980 480.357635,661.207947 482.366638,661.103333 
z"/>
<path fill="#A37226" opacity="1.000000" stroke="none" 
	d="
M438.288086,597.022339 
	C437.069275,598.281738 435.898804,597.939453 434.220001,597.223389 
	C435.738495,595.761536 436.967407,595.889221 438.288086,597.022339 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M391.603455,569.826538 
	C390.735138,569.682922 389.829132,569.588074 389.687286,569.208069 
	C389.375366,568.372192 390.223236,568.113892 390.852875,568.141357 
	C391.814758,568.183105 393.030518,568.248718 391.603455,569.826538 
z"/>
<path fill="#C68F11" opacity="1.000000" stroke="none" 
	d="
M394.430786,588.641846 
	C394.640411,589.389282 394.378845,589.660278 393.798523,589.544739 
	C393.677979,589.520752 393.515686,589.349731 393.497559,589.227966 
	C393.410187,588.641846 393.695251,588.396057 394.430786,588.641846 
z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
